import React from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import {
  deleteNotification,
  editNotification,
} from 'store/actions/notificationActions';

import { NotificationActions } from 'modules/notification/components';

import { dateHelper, config, useTranslation } from 'modules/common/helpers';
import { NOTIFICATION_TYPES as NT } from 'modules/notification/constants';

import { ReactComponent as Cross } from 'images/cross.svg';
import { ReactComponent as IconError } from 'images/error.svg';
import { ReactComponent as IconWarning } from 'images/warning.svg';
import { ReactComponent as IconSuccess } from 'images/check-circle.svg';
import { ReactComponent as IconBell } from 'images/bell.svg';

interface NotificationProps {
  notification: NotificationStructure;
}

export default function Notification({ notification }: NotificationProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['notification']);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const handleReadToggle = async (notification) => {
    await dispatch(
      editNotification({ ...notification, read: !notification.read })
    );
  };

  const handleDelete = async (id) => {
    await dispatch(deleteNotification(id));
  };

  let typeSpecificities = {
    titleColor: 't-text-beta-700',
    icon: null as null | React.ReactNode,
    iconBg: 't-bg-gamma-400',
  };

  switch (notification.type) {
    case NT.ERROR:
      typeSpecificities = {
        titleColor: 't-text-epsilon-600',
        icon: <IconError className="t-text-epsilon-600" />,
        iconBg: 't-bg-epsilon-100',
      };
      break;
    case NT.WARNING:
      typeSpecificities = {
        titleColor: 't-text-zeta-600',
        icon: <IconWarning className="t-text-zeta-600" />,
        iconBg: 't-bg-eta-300',
      };
      break;
    case NT.SUCCESS:
      typeSpecificities = {
        titleColor: 't-text-alpha-600',
        icon: <IconSuccess className="t-text-alpha-600" />,
        iconBg: 't-bg-alpha-150',
      };
      break;
    case NT.REMINDER:
      typeSpecificities = {
        titleColor: 't-text-delta-700',
        icon: <IconBell className="t-text-delta-700" />,
        iconBg: 't-bg-delta-100',
      };
      break;
    default:
      break;
  }

  const formattedDate = dateHelper.convertDateFormat(
    notification.date,
    config.format.uiDate
  );

  return (
    <div className="hover:t-bg-alpha-100 t-group t-py-4">
      {/* head */}
      <div className="t-flex t-flex-nowrap t-items-center t-mb-2.5">
        <button
          onClick={() => handleReadToggle(notification)}
          className="t-p-1 t-outline-none"
        >
          <div
            className="group-hover:t-border-alpha-100 t-border-gamma-400 t-border-4 t-border-solid t-rounded-full"
            data-tip={
              notification.read
                ? t('notification:mark_unread_tooltip')
                : t('notification:mark_read_tooltip')
            }
          >
            <div
              className={`t-rounded-full t-p-1 ${
                notification.read
                  ? 't-border t-border-alpha-600 t-border-solid'
                  : 't-bg-alpha-600'
              }`}
            />
          </div>
        </button>
        <p className="typo-theta t-whitespace-nowrap t-mr-3 t-text-beta-400">
          {formattedDate}
        </p>
        <div
          className="t-w-full t-bg-beta-100 t-mr-2.5"
          style={{ height: '1px' }}
        />
        <button
          onClick={() => handleDelete(notification.id)}
          className="t-mr-4 t-p-2 t-outline-none"
        >
          <Cross className="t-text-beta-400 t-w-2.5" />
        </button>
      </div>
      {/* body */}
      <div className="t-mx-6 t-flex t-flex-nowrap t-justify-between t-items-start">
        <div>
          <h3
            className={`t-mb-1 t-font-secondary t-font-semibold ${typeSpecificities.titleColor}`}
          >
            {t(notification.title)}
          </h3>
          <p className="typo-epsilon">
            {t(notification.description, {
              taskName: t(notification?.extraInfo?.document || ''),
              ...notification.extraInfo,
            })}
          </p>
        </div>
        <div
          className={`t-rounded-full t-p-2 ${typeSpecificities.iconBg} ${
            typeSpecificities.icon ? '' : 'group-hover:t-bg-alpha-100'
          }`}
        >
          {typeSpecificities.icon}
        </div>
      </div>
      <div className="t-mx-6 t-mt-2.5">
        <NotificationActions notification={notification} />
      </div>
    </div>
  );
}
