export const BILLING_OPTIONS = {
  YEARLY: 'yearly',
  LIFETIME: 'lifetime',
};

export const PAYMENT_METHODS = {
  SEPA: 'sepa',
  CREDIT_CARD: 'credit card',
};

export const PAYMENT_STEPS = {
  STEP_CHOOSE_BILLING: 1,
  STEP_PAYMENT_METHOD: 2,
  STEP_SEPA_FORM: 3,
  STEP_SUCCESS: 4,
};

export const PAYMENT_PRICES = {
  [BILLING_OPTIONS.YEARLY]: {
    original: 35,
    price: 29,
    discount: 0.2,
  },
  [BILLING_OPTIONS.LIFETIME]: {
    original: 349,
    price: 349,
  },
};
