import ninebarcLogo from 'images/logo.svg';
import suedversSignupImg from 'images/clientCustomizations/suedversSignupImg.jpg';
import hdhSignupImg from 'images/clientCustomizations/hdhSignupImg.jpg';
import hdhLogo from 'images/clientCustomizations/hdhLogo.png';
import deinepflegeLogo from 'images/clientCustomizations/deinepflegeLogo.png';
import unvergessenLogo from 'images/clientCustomizations/unvergessenLogo.png';
import vbbbLogo from 'images/clientCustomizations/vbbbLogo.png';
import wubvLogo from 'images/clientCustomizations/wubvLogo.png';
import bvagLogo from 'images/clientCustomizations/bvagLogo.png';
import diebayerischeLogo from 'images/clientCustomizations/diebayerischeLogo.png';

export const CLIENT_KEYS = {
  DEFAULT: 'default',
  SUEDVERS: 'suedvers',
  HELVETIA: 'helvetia',
  HDH: 'hdh',
  RUHEBAUM: 'ruhebaum',
  DEINEPFLEGE: 'deinepflege',
  UNVERGESSEN: 'unvergessen',
  VBBB: 'vbbb',
  WUBV: 'wubv',
  AU: 'au-vorsorge',
  BVAG: 'bv-ag',
  BAYERISCHE: 'diebayerische',
  DBK: 'dbk',
  C24: 'c24',
};

const CK = CLIENT_KEYS;

export const CLIENT_CUSTOMIZATIONS = {
  [CK.DEFAULT]: {
    signup: {
      exclusiveOffersText: [
        `auth:${CK.DEFAULT}_signup_offer1`,
        `auth:${CK.DEFAULT}_signup_offer2`,
        `auth:${CK.DEFAULT}_signup_offer3`,
      ],
      customizedHeaderTitle: false,
      customizedHeaderDescription: false,
      customizedFormLabel: false,
      customizedSignupButtonText: false,
      authFooter: true,
      offerSmallText: false,
      headerClassName: '',
      labelClassName: '',
      noLanguageSwitcher: false,
    },
  },
  [CK.SUEDVERS]: {
    signup: {
      btnBg: '#79C08F',
      btnColor: '#FFFFFF',
      customizedHeaderTitle: true,
      customizedHeaderDescription: true,
      bgImg: suedversSignupImg,
      logo: '',
      logoClassName: '',
      customizedPrimaryButton: false,
      bottomNinebarcIcon: false,
    },
  },
  [CK.HELVETIA]: {
    signup: {
      exclusiveOffersText: [
        `auth:${CK.HELVETIA}_signup_offer1`,
        `auth:${CK.HELVETIA}_signup_offer2`,
        `auth:${CK.HELVETIA}_signup_offer3`,
      ],
      customizedHeaderTitle: true,
      customizedHeaderDescription: true,
      headerClassName: 't-pb-10',
      authFooter: true,
    },
    payment: {
      stepBillingTitle: `payment:${CK.HELVETIA}_step_billing_title`,
    },
  },
  [CK.HDH]: {
    setPassword: {
      title: `auth:${CK.HDH}_set_password_title`,
      description: `auth:${CK.HDH}_set_password_description`,
      bgImg: hdhSignupImg,
      logo: hdhLogo,
      exclusiveOffersText: [
        `auth:${CK.HDH}_set_password_offer1`,
        `auth:${CK.HDH}_set_password_offer2`,
        `auth:${CK.HDH}_set_password_offer3`,
      ],
      darkBackground: true,
      logoClassName: 't-w-36',
      labelClassName: 't-pb-2',
      rectangleBgColor: '#D6E5F5',
      mainBgColor: '#054E9D',
      customHeaderTextStyles: true,
      customOfferTextStyles: true,
      cardFormClassName: 't-px-10 t-py-12',
      noLanguageSwitcher: true,
      withoutBgImg: true,
      bottomPartnership: true,
      partnershipLogo: ninebarcLogo,
      formDescription: true,
      customizedPrimaryButton: true,
      customizedSecondaryButton: true,
      customizedRegisterButtonText: true,
      customizedLoginButtonText: true,
      customizedFormLabel: true,
      homePageUrl: 'https://www.sterbegeld-hdh.de',
    },
    signup: {
      customizedHeaderTitle: true,
      customizedHeaderDescription: true,
      logo: hdhLogo,
      exclusiveOffersText: [
        `auth:${CK.HDH}_signup_offer1`,
        `auth:${CK.HDH}_signup_offer2`,
        `auth:${CK.HDH}_signup_offer3`,
      ],
      darkBackground: true,
      logoClassName: 't-w-36',
      labelClassName: 't-pb-2',
      rectangleBgColor: '#D6E5F5',
      mainBgColor: '#054E9D',
      customHeaderTextStyles: true,
      customOfferTextStyles: true,
      cardFormClassName: 't-px-10 t-py-12',
      noLanguageSwitcher: true,
      bottomPartnership: true,
      partnershipLogo: ninebarcLogo,
      customizedPrimaryButton: true,
      customizedSecondaryButton: true,
      customizedRegisterButtonText: true,
      customizedLoginButtonText: true,
      loginButtonInsideFormCard: true,
      customizedFormLabel: true,
      customizedSignupButtonText: true,
      homePageUrl: 'https://www.sterbegeld-hdh.de',
    },
  },
  [CK.RUHEBAUM]: {
    signup: {
      customizedHeaderTitle: true,
      customizedHeaderDescription: true,
      exclusiveOffersText: [
        `auth:${CK.RUHEBAUM}_signup_offer1`,
        `auth:${CK.RUHEBAUM}_signup_offer2`,
        `auth:${CK.RUHEBAUM}_signup_offer3`,
      ],
      offerSmallText: true,
      customizedFormLabel: true,
      customizedSignupButtonText: true,
      customizedLoginButtonText: true,
      noLanguageSwitcher: true,
    },
  },

  [CK.DEINEPFLEGE]: {
    signup: {
      customizedHeaderTitle: true,
      customizedHeaderDescription: true,
      exclusiveOffersText: [
        `auth:${CK.DEINEPFLEGE}_signup_offer1`,
        `auth:${CK.DEINEPFLEGE}_signup_offer2`,
        `auth:${CK.DEINEPFLEGE}_signup_offer3`,
      ],
      noLanguageSwitcher: true,
      customizedFormLabel: true,
      customizedSignupButtonText: true,
      customizedLoginButtonText: true,
      bottomPartnership: true,
      partnershipLogo: deinepflegeLogo,
      partnershipLogoClassName: 't-h-10',
    },
  },
  [CK.UNVERGESSEN]: {
    signup: {
      authFooter: true,
      partnershipLogo: unvergessenLogo,
      partnershipLogoClassName: 't-h-12',
    },
  },
  [CK.VBBB]: {
    signup: {
      authFooter: true,
      loginTextClassName: 'typo-delta',
      exclusiveOffersText: [
        `auth:${CK.VBBB}_signup_offer1`,
        `auth:${CK.VBBB}_signup_offer2`,
        `auth:${CK.VBBB}_signup_offer3`,
      ],
      cardFormClassName: 't-px-10 t-py-12',
      noLanguageSwitcher: true,
      bottomPartnership: true,
      partnershipLogo: vbbbLogo,
      partnershipLogoClassName: 't-w-44',
      homePageUrl: 'https://www.vb-bruchsal-bretten.de/',
    },
  },
  [CK.WUBV]: {
    signup: {
      exclusiveOffersText: [
        `auth:${CK.DEFAULT}_signup_offer1`,
        `auth:${CK.DEFAULT}_signup_offer2`,
        `auth:${CK.WUBV}_signup_offer3`,
      ],
      partnershipLogo: wubvLogo,
      partnershipLogoClassName: 't-h-14',
      authFooter: true,
    },
  },
  [CK.BVAG]: {
    signup: {
      bottomPartnership: true,
      hideBottomPartnershipText: true,
      partnershipLogo: bvagLogo,
      partnershipLogoClassName: 't-h-32',
    },
  },
  [CK.BAYERISCHE]: {
    signup: {
      bottomPartnership: true,
      partnershipLogo: diebayerischeLogo,
      partnershipLogoClassName: 't-w-56',
      exclusiveOffersText: [
        `auth:${CK.DEFAULT}_signup_offer1`,
        `auth:${CK.DEFAULT}_signup_offer2`,
        `auth:${CK.BAYERISCHE}_signup_offer3`,
      ],
    },
  },
  [CK.DBK]: {
    signup: {
      customizedHeaderTitle: true,
      customizedHeaderDescription: true,
      exclusiveOffersText: [
        `auth:${CK.DEFAULT}_signup_offer1`,
        `auth:${CK.DEFAULT}_signup_offer2`,
        `auth:${CK.DBK}_signup_offer3`,
      ],
      customizedSignupButtonText: true,
      authFooter: true,
      loginButtonInsideFormCard: true,
    },
  },
  [CK.C24]: {
    signup: {
      customizedHeaderTitle: true,
      customizedHeaderDescription: true,
      exclusiveOffersText: [
        `auth:${CK.DEFAULT}_signup_offer1`,
        `auth:${CK.DEFAULT}_signup_offer2`,
        `auth:${CK.C24}_signup_offer3`,
      ],
      customizedSignupButtonText: true,
      authFooter: true,
      loginButtonInsideFormCard: true,
    },
  },
};
