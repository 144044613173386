import React from 'react';
import { useDispatch } from 'react-redux';

import {
  getFullName,
  optionsHelper,
  useTranslation,
} from 'modules/common/helpers';

import { Button } from 'modules/common/components';
import { FamilyCardDropdown } from 'modules/familyAccount/components';

import { RELATIONSHIP } from 'modules/common/constants/enums';
import { MODALS } from 'modules/common/constants';
import { setModal } from 'store/actions/modalActions';

interface FamilyAccountCardProps {
  account: FamilyAccount;
  backToMyContactsTab: () => void;
  highlight?: boolean;
}
const FamilyAccountCard = ({
  account,
  backToMyContactsTab,
  highlight,
}: FamilyAccountCardProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'mcontacts']);

  const [switchingAccountId, setSwitchingAccountId] = React.useState('');

  const relations = optionsHelper.getRelationshipOptions();
  const relation = relations.find(
    (rel) => rel.value === (account.relation || RELATIONSHIP.OTHER)
  );

  const handleAccountSwitch = async (id: string) => {
    setSwitchingAccountId(id);
    dispatch(
      setModal({
        name: MODALS.ACCOUNT_SWITCHING_INFO,
        props: {
          cancelAction: () => {
            setSwitchingAccountId('');
          },
          successAction: () => {
            setSwitchingAccountId('');
            backToMyContactsTab();
          },
          data: {
            to: account,
          },
        },
      })
    );
  };

  return (
    <div id={account.id}>
      <div
        className={`card t-py-6 t-px-7 t-mb-4 ${
          highlight ? 't-border t-border-solid t-border-alpha-600' : ''
        }`}
      >
        <div className="t-flex t-flex-col t-items-start sm:t-flex-row sm:t-justify-between">
          <p className="typo-delta t-order-2 sm:t-order-1 t-mt-1 sm:t-mt-0 sm:t-mr-2">
            {getFullName(account, true)}
          </p>
          <div className="t-w-full sm:t-w-auto t-flex t-justify-end t-items-center t-whitespace-nowrap t-order-1 sm:t-order-2">
            <div
              className={`t-bg-delta-100 t-py-1 t-px-2 t-rounded-sm ${
                account.deceased ? '' : 't-mr-4'
              }`}
            >
              <p className="t-text-xs t-text-delta-700">
                {t('familyAccounts:sub_account_badge')}
              </p>
            </div>
            {!account.deceased && <FamilyCardDropdown account={account} />}
          </div>
        </div>

        <p className="typo-epsilon t-text-alpha-600 t-mt-1">
          {relation?.label || ''}
        </p>

        <div className="t-flex t-justify-end t-mt-3">
          {!account.deceased && (
            <Button
              className="t-uppercase t-mt-2 sm:t-mt-0"
              onClick={() => {
                handleAccountSwitch(account.id);
              }}
              loading={switchingAccountId === account.id}
              disabled={!!switchingAccountId || account.active}
            >
              {t('common:switch')}
            </Button>
          )}
          {!!account.deceased && (
            <div className="t-bg-beta-100 t-p-2 t-rounded-sm">
              <p className="typo-epsilon t-text-beta-500">
                {t('familyAccounts:reported_as_dead_text')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FamilyAccountCard;
