import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { useTranslation } from 'modules/common/helpers';

import { Textarea } from 'modules/elements/components';

export interface FreeTextProps {
  currStepData: Record<string, any>;
  previousAnswer?: string;
}

const FreeText = ({ currStepData, previousAnswer }: FreeTextProps) => {
  const { t } = useTranslation(['assistant']);
  const dispatch = useDispatch();
  const temporaryField = useSelector(
    (state: RootState) => state.temporaryField
  );
  const inputValue = temporaryField.value;

  const { customFieldLabel = '', customFieldPlaceholder = '' } = currStepData;

  // show previous input val, if entered before
  useEffect(() => {
    if (previousAnswer) {
      dispatch(temporaryFieldSaver(previousAnswer));
    }
  }, [previousAnswer]);

  const handleChange = (e) => {
    e.preventDefault();
    dispatch(temporaryFieldSaver(e.target.value));
  };

  return (
    <div className="card xs:t-w-full md:t-w-4/5 xs:t-p-4 md:t-p-7">
      <p className="t-text-beta-400 t-mb-3">
        {t(customFieldLabel || 'assistant:free_text_label')}
      </p>
      <Textarea
        rows="5"
        maxLength={1000}
        bg="var(--color-beta-100)"
        name="free-text"
        onChange={(e) => handleChange(e)}
        value={inputValue || ''}
        placeholder={t(
          customFieldPlaceholder || 'assistant:free_text_placeholder'
        )}
      />
    </div>
  );
};

export default FreeText;
