import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { FormRegister, FormWatch } from 'modules/common/types';
import { Button, Modal, RadioCard } from 'modules/common/components';

interface TwoFactorAuthSelectionModalProps {
  onCancel: () => void;
  handleSave2FASelection: () => void;
  register: FormRegister;
  watch: FormWatch;
}

export default function TwoFactorAuthSelectionModal({
  onCancel,
  handleSave2FASelection,
  register,
  watch,
}: TwoFactorAuthSelectionModalProps) {
  const { t } = useTranslation(['plan']);

  const [loading, setLoading] = React.useState(false);
  const twoFaSelection = watch('twoFa');

  const onSave2FASelection = async () => {
    setLoading(true);
    await handleSave2FASelection();
    setLoading(false);
  };

  return (
    <Modal show onHide={onCancel} dataTestid="TwoFactorAuthSelectionModal">
      <h3 className="t-mb-4 typo-alpha">{t('plan:step_2fa')}</h3>
      <p className="t-mb-14 typo-epsilon">{t('plan:step_2fa_description')}</p>
      <div className="t-flex t-flex-col" role="radiogroup">
        <RadioCard
          register={register}
          className="t-mb-5 t-py-5 t-pr-10"
          name="twoFa"
          value="enable"
          id="enable"
        >
          <>
            <h3 className="typo-beta">
              {t('auth:two_auth_switcher_label_enable')}
            </h3>
            <p className="typo-epsilon">
              {t('auth:two_auth_switcher_label_enable_description')}
            </p>
          </>
        </RadioCard>
        <RadioCard
          register={register}
          className="t-py-5 t-pr-10 t-mb-8"
          name="twoFa"
          value="disable"
          id="disable"
        >
          <>
            <h3 className="typo-beta">
              {t('auth:two_auth_switcher_label_disable')}
            </h3>
            <p className="typo-epsilon">
              {t('auth:two_auth_switcher_label_disable_description')}
            </p>
          </>
        </RadioCard>

        <div className="t-flex xs:t-flex-wrap sm:t-flex-nowrap t-justify-end">
          <Button
            category="secondary"
            className="t-mr-6 xs:t-mt-5"
            onClick={() => onCancel()}
            disabled={loading}
          >
            {t('common:cancel')}
          </Button>
          <Button
            disabled={!twoFaSelection}
            loading={loading}
            className="xs:t-mt-5"
            onClick={onSave2FASelection}
          >
            {t('common:save')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
