import helper from 'store/reducers/reducerHelper';
import initialState from 'store/reducers/initialState';

import { LOADING } from 'store/types/commonActionTypes';

const loading = (state = initialState.loading, action) => {
  return helper.handleActions(state, action, {
    [LOADING](state, payload) {
      state.value = payload.isLoading;
    },
  });
};

export default loading;
