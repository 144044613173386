import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Accordion } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { RootState } from 'store/reducers';
import { LayoutSelector } from 'store/selectors/selectors';
import {
  setIsShowEditAssetForm,
  setAssetToEdit,
} from 'store/actions/layoutActions';
import { fetchAssistantProgress } from 'store/actions/assistantActions';
import { zvrRegistration } from 'store/actions/zvrActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { scrollToElementId, useTranslation } from 'modules/common/helpers';

import { Toggle } from 'modules/elements/components';
import { Modal } from 'modules/bootstrap/components';
import {
  AddAssetProcess,
  AssetTypePageFooter,
  AssetTypeRecommendations,
  CreatedAssets,
  PostDocGenerationProcess,
} from 'modules/assetType/components';
import { AssetForm } from 'modules/asset/components';
import {
  Check24Widget,
  InheritanceTaxWidget,
  NotarySearchWidget,
} from 'modules/estatePlan/components';

import { ASSET_CATEGORIES } from 'modules/estatePlan/constants';
import { REDIRECT_ACTIONS } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';
import { URL_PARAMS, URL_SEARCH_CODES } from 'modules/common/constants/enums';

interface AssetTypePageProps {
  assetCategory: string;
  categorizedRecos: RecommendationDTO[];
  possibleParams?: string[];
  isVaultTutorial?: boolean;
}

function AssetTypePage({
  assetCategory,
  categorizedRecos = [],
  possibleParams = [],
  isVaultTutorial,
}: AssetTypePageProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation([assetCategory, 'plan']);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get(URL_PARAMS.PAYMENT_TOKEN);

  const layoutSelector = useSelector((state: RootState) =>
    LayoutSelector(state)
  );

  const { currentAsset, currentAssetType, isShowEditForm } =
    layoutSelector.assetEdit;

  const assets: AssetsDTO = useSelector((state: RootState) => state.assets);
  const categoryAssets: AssetDTO[] = assets[assetCategory] || [];
  const assetTypes: AssetTypesDTO = useSelector(
    (state: RootState) => state.assetTypes
  );
  const categorizedAssetTypes: AssetTypeDTO[] = assetTypes[assetCategory];

  const temporaryField = useSelector(
    (state: RootState) => state.temporaryField?.value
  );
  // filter all assets in the removal queue
  const assetIdsToRemove =
    temporaryField?.type === 'AssetToRemove' ? temporaryField.assetIds : [];

  const displayedAssets = categoryAssets.filter(
    (asset) => !assetIdsToRemove.includes(asset.id)
  );

  // get data in case user gets redirected from other page
  const previousRouteData = (location.state || {}) as Record<string, any>;
  const notifiedAssetId = previousRouteData?.notificationData?.assetId;
  const redirectAssetId = previousRouteData?.assetId || notifiedAssetId;

  // handle the redirect after successful payment of ZVR
  useEffect(() => {
    if (
      token &&
      searchParams.get(URL_PARAMS.STATUS) !==
        URL_SEARCH_CODES.POSTAL_PAYMENT_SUCCESSFUL
    ) {
      handleZvrRegistration(token);
    }

    // if there is assetId from the params, scroll to the corresponding asset card
    if (possibleParams.length === 2) {
      scrollToElementId(possibleParams[1]);
    }
  }, []);

  // Handle opening the asset form when the assetId is in the link
  useEffect(() => {
    if (
      possibleParams?.length === 2 &&
      !!categorizedAssetTypes?.length &&
      !!categoryAssets?.length
    ) {
      openAssetToEdit(possibleParams[1]);
    }
  }, [possibleParams, categorizedAssetTypes, categoryAssets]);

  // Handle opening the asset form when the assetId is attached in the notification
  useEffect(() => {
    if (!!notifiedAssetId) {
      openAssetToEdit(notifiedAssetId);
    }
  }, [notifiedAssetId]);

  // open asset form when the assetId and assetTypeId are provided in the url
  const openAssetToEdit = async (assetId: string) => {
    const assetToEdit =
      categoryAssets.find((asset) => asset.id === assetId) || ({} as AssetDTO);
    const assetType: AssetTypeDTO =
      categorizedAssetTypes?.find(
        (type) => type.id === assetToEdit.assetTypeId
      ) || ({} as AssetTypeDTO);

    if (!!assetToEdit?.id && !!assetType?.id) {
      await dispatch(setAssetToEdit(assetToEdit, assetType));
      await dispatch(setIsShowEditAssetForm(true));
    }
  };

  useEffect(() => {
    // we need the assistant progress for the HCP & PD cards and fetch it here to avoid multiple requests
    if (assetCategory === ASSET_CATEGORIES.HEALTH) {
      dispatch(fetchAssistantProgress());
    }
  }, [assetCategory]);

  // if there is data on redirect, scroll to the corresponding asset card
  useEffect(() => {
    const assetIdToScroll =
      redirectAssetId?.assetId ||
      redirectAssetId ||
      temporaryField?.assetId ||
      temporaryField;

    // Avoid to run the code during asset deletion process
    if (assetIdToScroll && !assetIdsToRemove.length) {
      scrollToElementId(assetIdToScroll);
      dispatch(temporaryFieldSaver(null));
    }
  }, [redirectAssetId, temporaryField]);

  const handleZvrRegistration = async (token: string) => {
    // make sure the valid token is sent
    if (token.length === 40) {
      await dispatch(zvrRegistration(token));
    }

    searchParams.delete(URL_PARAMS.PAYMENT_TOKEN);
    setSearchParams(searchParams);
  };

  const handleCloseEditForm = () => {
    dispatch(setIsShowEditAssetForm(false));
    dispatch(temporaryFieldSaver(null));
  };

  const infoText = t(`plan:info_text_${assetCategory}`);

  const renderWidget = () => {
    switch (assetCategory) {
      case ASSET_CATEGORIES.FINANCIAL:
        return (
          <div id={REDIRECT_ACTIONS.GO_TO_TAX_CALCULATOR} className="t-mt-10">
            <InheritanceTaxWidget />
          </div>
        );

      case ASSET_CATEGORIES.LEGAL:
        return (
          <div className="t-mt-10">
            <NotarySearchWidget />
          </div>
        );

      case ASSET_CATEGORIES.AFTERLIFE:
        return (
          <div className="t-mt-16">
            <Check24Widget />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isShowEditForm && (
        <Modal show className="small" style={{ zIndex: '1040' }}>
          <div className="t-bg-gamma-400 t-w-full t-shadow t-rounded t-p-8">
            <AssetForm
              asset={currentAsset}
              assetType={currentAssetType}
              assetCategory={assetCategory}
              onHideForm={handleCloseEditForm}
            />
          </div>
        </Modal>
      )}

      {!isVaultTutorial && (
        <PostDocGenerationProcess assetCategory={assetCategory} />
      )}

      <div className="t-h-full t-flex t-flex-col t-justify-between">
        <div>
          <h2 className="t-font-secondary t-font-semibold t-text-xl t-pb-3">
            {t(`plan:${assetCategory}_page_title`)}
          </h2>
          <p className="t-hidden md:t-block t-mb-6">
            {t(`plan:${assetCategory}_page_description`)}
          </p>

          <Accordion>
            <Toggle
              eventKey={`${assetCategory}_learn_more`}
              title={t('plan:learn_more')}
              content={infoText}
            />
          </Accordion>

          <div className="t-mb-8" />

          <AssetTypeRecommendations
            category={assetCategory}
            recommendations={categorizedRecos}
          />

          <div className="t-mt-5" data-tour="vault_assets">
            <CreatedAssets
              assetCategory={assetCategory}
              categoryAssets={displayedAssets}
            />
          </div>

          {!!displayedAssets.length && (
            <AddAssetProcess assetCategory={assetCategory} />
          )}
        </div>

        <div>
          <AssetTypePageFooter currentCategory={assetCategory} />
          {renderWidget()}
        </div>
      </div>
    </>
  );
}

export default AssetTypePage;
