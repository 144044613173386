import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

type VerificationEmailWarningModalProps = {
  setIsVisibleModal: (isVisibleModal: boolean) => void;
};

function VerificationEmailWarningModal({
  setIsVisibleModal,
}: VerificationEmailWarningModalProps) {
  const { t } = useTranslation(['common', 'profile']);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsVisibleModal(false);
  };

  const handleCloseWithRedirectToProfile = () => {
    setIsVisibleModal(false);
    navigate('/profile');
  };

  return (
    <Modal show onHide={handleClose} hasCloseCross>
      <div className="row" data-testid="VerificationEmailWarningModal">
        <div className="col-lg-12 d-flex flex-column">
          <h1 className="Typography Typography--title u-weight-600">
            {t('profile:verification_email_warning_modal_title')}
          </h1>

          <p className="Modal-description">
            {t('profile:verification_email_warning_modal_description')}
          </p>

          <Button
            className="align-self-end"
            onClick={handleCloseWithRedirectToProfile}
          >
            {t('common:continue')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default VerificationEmailWarningModal;
