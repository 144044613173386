export enum SECURITY_SETTING_STEPS {
  SELECT_SECURITY_SETTING = 'selectSecuritySetting',
  SELECT_SECURITY_EDIT_TYPE = 'selectSecurityEditType',
  SET_SECURITY_KEY = 'setSecurityKey',
  FINAL = 'final',
}

export enum SECURITY_SETTING_OPTIONS {
  STANDARD = 'standard',
  SET_SECURITY_KEY = 'setSecurityKey',
}

export enum SECURITY_KEY_EDIT_TYPE {
  CHANGE_SECURITY_KEY = 'changeSecurityKey',
  DELETE_SECURITY_KEY = 'deleteSecurityKey',
  MANAGE_KEY_HOLDERS = 'manageKeyHolders',
}

export enum SET_SECURITY_KEY_STEPS {
  SECURITY_KEY_FORM = 'securityKeyForm',
  CONFIRMATION = 'confirmation',
  KEY_HOLDER_SELECTION = 'keyHolderSelection',
  SET_KEY_HOLDERS = 'setKeyHolder',
}

export enum KEY_HOLDER_OPTIONS {
  KEY_HOLDERS = 'keyHolders',
  NO_KEY_HOLDER = 'noKeyHolder',
}
