import { camelCase } from 'lodash';
import {
  CONTENT_QUESTIONNAIRE_KEYS,
  CONTENT_QUESTIONNAIRE_PAGES,
} from 'modules/assistant/constants/contentQuestionnaires';
import {
  QUESTIONNAIRE_PAGES,
  QUESTIONNAIRE_KEYS,
} from 'modules/assistant/constants/questionnaires';
import { QUESTIONNAIRES_CTAS } from 'modules/assistant/constants/questionnaires/questionnairesCTAs';

const isDocGenReco = (questionnaireKey: string): boolean =>
  (Object.values(QUESTIONNAIRE_KEYS) as string[]).includes(questionnaireKey);

const getRecosWithExtraOptions = (
  recos: RecommendationDTO[]
): RecommendationDTO[] => {
  if (!Array.isArray(recos)) return [];

  return recos
    .map((item) => ({ ...item, isAvailable: isAvailable(item.name!) }))
    .sort(
      (reco1, reco2) =>
        Number(isDocGenReco(camelCase(reco2.name))) -
        Number(isDocGenReco(camelCase(reco1.name)))
    )
    .sort((reco1, reco2) => (reco2.priority || 0) - (reco1.priority || 0))
    .sort(
      (reco1, reco2) => Number(reco2.isAvailable) - Number(reco1.isAvailable)
    );
};

const isAvailable = (qKey: string): boolean => {
  const questionnaireKey = camelCase(qKey);

  return !!(
    QUESTIONNAIRE_PAGES[questionnaireKey]?.path ||
    CONTENT_QUESTIONNAIRE_PAGES[questionnaireKey]?.path
  );
};

// this function applies for the document generators and digital estate reco only
const isRecoCompleted = (
  reco: RecommendationDTO,
  planProgress: PlanProgressDTO
): boolean => {
  const questionnaireKey = camelCase(reco.name);

  // is the digital estate recommendation completed?
  const isDEReco =
    questionnaireKey === CONTENT_QUESTIONNAIRE_KEYS.DIGITAL_ESTATE;
  const isDERecoCompleted = planProgress.digitalEstate === 1;

  // consider a content questionnaire to be done if it is not document generator type and have no upload function
  const isReadOnlyContentQuestionnaire =
    !isDocGenReco(questionnaireKey) &&
    !QUESTIONNAIRES_CTAS[questionnaireKey]?.upload;

  if (isDocGenReco(questionnaireKey) || isReadOnlyContentQuestionnaire)
    return reco.status === 1;
  if (isDEReco) return isDERecoCompleted;
  return false;
};

const getFilteredRecosArray = (
  recosArray: RecommendationDTO[],
  onlyCompletedRecos: boolean,
  planProgress: PlanProgressDTO
): RecommendationDTO[] => {
  return recosArray.filter((reco) => {
    const recoCompleted =
      !!reco.relatedAssetId || isRecoCompleted(reco, planProgress);
    return onlyCompletedRecos ? recoCompleted : !recoCompleted;
  });
};

export default {
  isDocGenReco,
  getRecosWithExtraOptions,
  isAvailable,
  getFilteredRecosArray,
  isRecoCompleted,
};
