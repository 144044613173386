import icons from 'modules/assistant/constants/questionnaires/icons';

import { STEP_COMPONENT_TYPE as C } from 'modules/assistant/constants/questionnaires';

export enum STEP_KEYS {
  // ids of steps (questions)
  // NOTE: we should probably make the below keys as descriptive as we did for the answers
  // because we will store "keyStep: keyAnswer" in the backend
  STEP_START = 'start',
  STEP_1 = 'specifyingDonation',
  STEP_1_1 = 'specifyingExceptions',
  STEP_1_2 = 'specifyingOrgans',
  STEP_1_3 = 'specifyingPerson',
  STEP_2 = 'addFinalRemarks',
  STEP_3 = 'finalRemarks',
  STEP_END = 'end',
}
const S = STEP_KEYS;

export enum ORGAN_DONOR_CARD_ANSWER_KEYS {
  YES = 'true',
  NO = 'false',
  ALLORGANS = 'allOrgans',
  NOORGANS = 'noOrgans',
  EXCEPTION = 'exception',
  CERTAINORGANS = 'certainOrgans',
  PERSONTODECIDE = 'personToDecide',
}

const A = ORGAN_DONOR_CARD_ANSWER_KEYS;

export const getOrganDonorCardQuestionnaire = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    [S.STEP_START]: {
      content: t('s_start'),
      type: C.START,
      linksTo: S.STEP_1,
    },
    [S.STEP_1]: {
      content: t('s_1'),
      current: 1,
      total: 4,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.ALLORGANS, linksTo: S.STEP_2 },
        { id: A.NOORGANS, linksTo: S.STEP_2 },
        { id: A.EXCEPTION, linksTo: S.STEP_1_1 },
        { id: A.CERTAINORGANS, linksTo: S.STEP_1_2 },
        { id: A.PERSONTODECIDE, linksTo: S.STEP_1_3 },
      ],
      qa: [
        { question: t('s_1_qa_q_1'), answer: t('s_1_qa_a_1') },
        { question: t('s_1_qa_q_2'), answer: t('s_1_qa_a_2') },
        { question: t('s_1_qa_q_3'), answer: t('s_1_qa_a_3') },
        { question: t('s_1_qa_q_4'), answer: t('s_1_qa_a_4') },
      ],
    },
    [S.STEP_1_1]: {
      content: t('s_1_1'),
      current: 2,
      total: 4,
      type: C.FREE_TEXT,
      linksTo: S.STEP_2,
      qa: [
        { question: t('s_1_1_qa_q_1'), answer: t('s_1_1_qa_a_1') },
        { question: t('s_1_1_qa_q_2'), answer: t('s_1_1_qa_a_2') },
        { question: t('s_1_1_qa_q_3'), answer: t('s_1_1_qa_a_3') },
        { question: t('s_1_1_qa_q_4'), answer: t('s_1_1_qa_a_4') },
        { question: t('s_1_1_qa_q_5'), answer: t('s_1_1_qa_a_5') },
        { question: t('s_1_1_qa_q_6'), answer: t('s_1_1_qa_a_6') },
      ],
    },
    [S.STEP_1_2]: {
      content: t('s_1_2'),
      current: 2,
      total: 4,
      type: C.FREE_TEXT,
      linksTo: S.STEP_2,
      qa: [
        { question: t('s_1_2_qa_q_1'), answer: t('s_1_2_qa_a_1') },
        { question: t('s_1_2_qa_q_2'), answer: t('s_1_2_qa_a_2') },
        { question: t('s_1_2_qa_q_3'), answer: t('s_1_2_qa_a_3') },
        { question: t('s_1_2_qa_q_4'), answer: t('s_1_2_qa_a_4') },
        { question: t('s_1_2_qa_q_5'), answer: t('s_1_2_qa_a_5') },
        { question: t('s_1_2_qa_q_6'), answer: t('s_1_2_qa_a_6') },
      ],
    },
    [S.STEP_1_3]: {
      content: t('s_1_3'),
      current: 2,
      total: 4,
      type: C.CONTACT,
      linksTo: S.STEP_2,
    },
    [S.STEP_2]: {
      content: t('s_2'),
      current: 3,
      total: 4,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_3 },
        { id: A.NO, linksTo: S.STEP_END },
      ],
    },
    [S.STEP_3]: {
      content: t('s_3'),
      current: 4,
      total: 4,
      type: C.FREE_TEXT,
      linksTo: S.STEP_END,
    },
    [S.STEP_END]: {
      content: t('s_end'),
      type: C.END,
    },
  };
};

export const getOrganDonorCardAnswers = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);

  return {
    [A.ALLORGANS]: {
      content: t('a_allOrgans'),
      infoContent: t('info_allOrgans'),
      image: icons.imgYes,
    },
    [A.NOORGANS]: {
      content: t('a_noOrgans'),
      infoContent: t('info_noOrgans'),
      image: icons.imgNo,
    },
    [A.EXCEPTION]: {
      content: t('a_exception'),
      infoContent: t('info_exception'),
      image: icons.imgException,
    },
    [A.CERTAINORGANS]: {
      content: t('a_certainOrgans'),
      infoContent: t('info_certainOrgans'),
      image: icons.imgCertainOrgans,
    },
    [A.PERSONTODECIDE]: {
      content: t('a_personToDecide'),
      infoContent: t('info_personToDecide'),
      image: icons.imgPersonToDecide,
    },
    [A.YES]: {
      content: t('a_true'),
      image: icons.imgYes,
    },
    [A.NO]: {
      content: t('a_false'),
      image: icons.imgNo,
    },
  };
};

export const getOrganDonorCardStartScreen = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    listItems: [
      t('start_screen_list_1'),
      t('start_screen_list_2'),
      t('start_screen_list_3'),
    ],
  };
};
