import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'modules/common/helpers';

import referAFriend from 'images/refer-a-friend.svg';
import { ReactComponent as ArrowRightIcon } from 'images/icon-arrow-right.svg';

export default function ReferralSectionProfile() {
  const { t } = useTranslation(['referral']);

  return (
    <div data-testid="referral-section">
      <h3 className="typo-beta t-mt-16">{t('profile:referral_title')}</h3>

      <div className="card t-py-3 t-pl-6 t-mt-4 t-flex t-justify-between sm:t-items-center xs:t-flex-col sm:t-flex-row t-group">
        <img
          src={referAFriend}
          alt="refer a friend"
          className="t-w-32 t-h-20 t-self-center"
        />
        <div className="t-flex t-justify-between t-items-center t-flex-grow xs:t-mt-5 sm:t-mt-0">
          <div className="xs:t-ml-0 sm:t-ml-7 t-flex-grow">
            <h3 className="typo-beta">
              {t('profile:referral_invite_a_friend_title')}
            </h3>
            <p className="typo-epsilon t-mt-1">
              {t('profile:referral_invite_a_friend_description')}
            </p>
          </div>
          <Link
            className="t-rounded t-mr-5 t-ml-2 t-pl-2 t-whitespace-nowrap t-bg-gamma-400 group-hover:t-bg-alpha-600 t-tracking-widest"
            to="/referral"
          >
            <div className="t-flex t-justify-between t-rounded">
              <div className="t-mx-1 t-self-center t-text-gamma-400 t-opacity-0 group-hover:t-opacity-100 t-hidden sm:t-inline">
                {t('profile:referral_invite_button_text')}
              </div>
              <div className="t-w-10 t-h-10 t-p-0 t-bg-alpha-600 t-rounded t-flex t-items-center t-justify-center">
                <ArrowRightIcon className="t-w-5 t-h-5 t-text-alpha-600" />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
