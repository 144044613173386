import icons from 'modules/assistant/constants/questionnaires/icons';

import { STEP_COMPONENT_TYPE as C } from 'modules/assistant/constants/questionnaires';

export enum STEP_KEYS {
  // ids of steps (questions)
  STEP_START = 'start',
  STEP_2 = 'married',
  STEP_3 = 'createHeir',
  STEP_4 = 'divisionOfInheritance',
  STEP_4_1 = 'inheritanceDistribution',
  STEP_5 = 'substituteHeirs',
  STEP_5_1 = 'distributionSubstituteHeirs',
  STEP_5_2 = 'appointSubstituteHeirs',
  STEP_6 = 'arrangeBequests',
  STEP_6_1 = 'bequestAllocation',
  STEP_6_2 = 'createBequest',
  STEP_7 = 'willExecution',
  STEP_7_1 = 'appointExecuter',
  STEP_7_1_1 = 'selectExecuter',
  STEP_7_2 = 'executerCompensation',
  STEP_8 = 'typeOfWill',
  STEP_9 = 'fullInheritance',
  STEP_9_1 = 'remaindermanNotPossible',
  STEP_10 = 'germanCitizenship',
  STEP_10_1 = 'notPossible',
  STEP_13 = 'appointFinalHeirs',
  STEP_13_1 = 'selectFinalHeirs',
  STEP_13_2 = 'divisionOfInheritanceSpousal',
  STEP_13_2_1 = 'inheritanceDistributionSpousal',
  STEP_13_3 = 'distributionSubstituteHeirsSpousal',
  STEP_13_3_1 = 'appointSubstituteHeirsSpousal',
  STEP_14 = 'partPenalityClause',
  STEP_15 = 'bindingForLast',
  STEP_15_1 = 'bindingForWhat',
  STEP_16 = 'arrangeBequestSpousal',
  STEP_16_1 = 'bequestAllocationSpousal',
  STEP_16_2 = 'createBequestSpousal',
  STEP_17 = 'willExecutionSpousal',
  STEP_17_1 = 'appointExecuterSpousal',
  STEP_17_1_1 = 'selectExecuterSpousal',
  STEP_17_2 = 'executerCompensationSpousal',
  STEP_19 = 'partnerInformation',
  STEP_20 = 'createHeirIndividual',
  STEP_21 = 'divisionOfInheritanceIndividual',
  STEP_21_1 = 'inheritanceDistributionIndividual',
  STEP_22 = 'substituteHeirsIndividual',
  STEP_22_1 = 'distributionSubstituteHeirsIndividual',
  STEP_22_2 = 'appointSubstituteHeirsIndividual',
  STEP_23 = 'arrangeBequestsIndividual',
  STEP_23_1 = 'bequestAllocationIndividual',
  STEP_23_2 = 'createBequestIndividual',
  STEP_24 = 'willExecutionIndividual',
  STEP_24_1 = 'appointExecuterIndividual',
  STEP_24_1_1 = 'selectExecuterIndividual',
  STEP_24_2 = 'executerCompensationIndividual',
  STEP_END = 'end',
}
const S = STEP_KEYS;

export enum WILL_GENERATOR_ANSWER_KEYS {
  YES = 'true',
  NO = 'false',
  MARRIED = 'married',
  NOTMARRIED = 'notMarried',
  REMAINDERMAN = 'remainderman',
  FULLINHERITANCE = 'fullInheritance',
  OWNREGULATION = 'ownRegulation',
  EQUALDISTRIBUTION = 'equalDistribution',
  PROPORTIONATE = 'proportionate',
  THEIRCHILDREN = 'theirChildren',
  LEGALSUCCESSION = 'legalSuccession',
  TRUSTEDTHIRDPARTY = 'trustedThirdParty',
  PROBATECOURT = 'probateCourt',
  SPOUSAL = 'spousal',
  INDIVIDUAL = 'individual',
  APPOINTMENTHEIRS = 'appointmentHeirs',
  ALLARRANGEMENTS = 'allArrangements',
  ADVANCE_BEQUEST = 'advanceBequest',
  DIVISION_ORDER = 'divisionOrder',
}

const A = WILL_GENERATOR_ANSWER_KEYS;

export const getWillQuestionnaire = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    [S.STEP_START]: {
      content: t('s_start'),
      type: C.START,
      linksTo: S.STEP_2,
    },
    [S.STEP_2]: {
      content: t('s_2'),
      current: 1,
      total: 22,
      infoContent: t('st_2'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.MARRIED, linksTo: S.STEP_8 },
        { id: A.NOTMARRIED, linksTo: S.STEP_3 },
      ],
      qa: [{ question: t('s_2_qa_q_1'), answer: t('s_2_qa_a_1') }],
    },
    [S.STEP_3]: {
      content: t('s_3'),
      current: 2,
      total: 14,
      type: C.HEIR_CONTACTS,
      linksTo: S.STEP_4,
      qa: [
        { question: t('s_3_qa_q_1'), answer: t('s_3_qa_a_1') },
        { question: t('s_3_qa_q_2'), answer: t('s_3_qa_a_2') },
        { question: t('s_3_qa_q_3'), answer: t('s_3_qa_a_3') },
      ],
    },
    [S.STEP_4]: {
      content: t('s_4'),
      current: 3,
      total: 14,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.OWNREGULATION, linksTo: S.STEP_4_1 },
        { id: A.EQUALDISTRIBUTION, linksTo: S.STEP_5 },
      ],
    },
    [S.STEP_4_1]: {
      content: t('s_4_1'),
      current: 4,
      total: 14,
      infoContent: t('st_4_1'),
      type: C.INHERITANCE_DISTRIBUTION,
      linksTo: S.STEP_5,
      qa: [
        { question: t('s_4_1_qa_q_1'), answer: t('s_4_1_qa_a_1') },
        { question: t('s_4_1_qa_q_2'), answer: t('s_4_1_qa_a_2') },
      ],
    },
    [S.STEP_5]: {
      content: t('s_5'),
      current: 5,
      total: 14,
      infoContent: t('st_5'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_5_1 },
        { id: A.NO, linksTo: S.STEP_6 },
      ],
      qa: [
        { question: t('s_5_qa_q_1'), answer: t('s_5_qa_a_1') },
        { question: t('s_5_qa_q_2'), answer: t('s_5_qa_a_2') },
      ],
    },
    [S.STEP_5_1]: {
      content: t('s_5_1'),
      current: 6,
      total: 14,
      infoContent: t('st_5_1'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.PROPORTIONATE, linksTo: S.STEP_6 },
        { id: A.THEIRCHILDREN, linksTo: S.STEP_6 },
        { id: A.LEGALSUCCESSION, linksTo: S.STEP_6 },
        { id: A.OWNREGULATION, linksTo: S.STEP_5_2 },
      ],
      qa: [{ question: t('s_5_1_qa_q_1'), answer: t('s_5_1_qa_a_1') }],
    },
    [S.STEP_5_2]: {
      content: t('s_5_2'),
      current: 7,
      total: 14,
      infoContent: t('st_5'),
      type: C.SUBSTITUTE_HEIR,
      linksTo: S.STEP_6,
      qa: [{ question: t('s_5_2_qa_q_1'), answer: t('s_5_2_qa_a_1') }],
    },
    [S.STEP_6]: {
      content: t('s_6'),
      current: 8,
      total: 14,
      infoContent: t('st_6'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_6_1 },
        { id: A.NO, linksTo: S.STEP_7 },
      ],
      qa: [{ question: t('s_6_qa_q_1'), answer: t('s_6_qa_a_1') }],
    },
    [S.STEP_6_1]: {
      content: t('s_6_1'),
      current: 9,
      total: 14,
      // infoContent: t('st_6_1'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.ADVANCE_BEQUEST, linksTo: S.STEP_6_2 },
        { id: A.DIVISION_ORDER, linksTo: S.STEP_6_2 },
      ],
    },
    [S.STEP_6_2]: {
      content: t('s_6_2'),
      current: 10,
      total: 14,
      infoContent: t('st_6_2'),
      type: C.BEQUEST,
      linksTo: S.STEP_7,
      qa: [
        { question: t('s_6_2_qa_q_1'), answer: t('s_6_2_qa_a_1') },
        { question: t('s_6_2_qa_q_2'), answer: t('s_6_2_qa_a_2') },
        { question: t('s_6_2_qa_q_3'), answer: t('s_6_2_qa_a_3') },
      ],
    },
    [S.STEP_7]: {
      content: t('s_7'),
      current: 11,
      total: 14,
      infoContent: t('st_7'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_7_1 },
        { id: A.NO, linksTo: S.STEP_END },
      ],
      qa: [{ question: t('s_7_qa_q_1'), answer: t('s_7_qa_a_1') }],
    },
    [S.STEP_7_1]: {
      content: t('s_7_1'),
      current: 12,
      total: 14,
      infoContent: t('st_7_1'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.TRUSTEDTHIRDPARTY, linksTo: S.STEP_7_1_1 },
        { id: A.PROBATECOURT, linksTo: S.STEP_END },
      ],
      qa: [{ question: t('s_7_1_qa_q_1'), answer: t('s_7_1_qa_a_1') }],
    },
    [S.STEP_7_1_1]: {
      content: t('s_7_1_1'),
      current: 13,
      total: 14,
      type: C.CONTACT,
      linksTo: S.STEP_7_2,
    },
    [S.STEP_7_2]: {
      content: t('s_7_2'),
      current: 14,
      total: 14,
      infoContent: t('st_7_2'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_END },
        { id: A.NO, linksTo: S.STEP_END },
      ],
      qa: [{ question: t('s_7_2_qa_q_1'), answer: t('s_7_2_qa_a_1') }],
    },
    [S.STEP_8]: {
      content: t('s_8'),
      current: 2,
      total: 22,
      infoContent: t('st_8'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.SPOUSAL, linksTo: S.STEP_9 },
        { id: A.INDIVIDUAL, linksTo: S.STEP_20 },
      ],
      qa: [
        { question: t('s_8_qa_q_1'), answer: t('s_8_qa_a_1') },
        { question: t('s_8_qa_q_2'), answer: t('s_8_qa_a_2') },
      ],
    },
    [S.STEP_9]: {
      content: t('s_9'),
      current: 3,
      total: 22,
      infoContent: t('st_9'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.REMAINDERMAN, linksTo: S.STEP_9_1 },
        { id: A.FULLINHERITANCE, linksTo: S.STEP_10 },
      ],
      qa: [
        { question: t('s_9_qa_q_1'), answer: t('s_9_qa_a_1') },
        { question: t('s_9_qa_q_2'), answer: t('s_9_qa_a_2') },
      ],
    },
    [S.STEP_9_1]: {
      content: null,
      type: C.REQUEST_ADVICE,
      linksTo: S.STEP_9,
      customNavigationButtons: true,
    },
    [S.STEP_10]: {
      content: t('s_10'),
      current: 4,
      total: 22,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_13 },
        { id: A.NO, linksTo: S.STEP_10_1 },
      ],
    },
    [S.STEP_10_1]: {
      content: null,
      type: C.REQUEST_ADVICE,
      linksTo: S.STEP_10,
      customNavigationButtons: true,
    },
    [S.STEP_13]: {
      content: t('s_13'),
      current: 5,
      total: 22,
      infoContent: t('st_13'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_13_1 },
        { id: A.NO, linksTo: S.STEP_15 },
      ],
      qa: [
        { question: t('s_13_qa_q_1'), answer: t('s_13_qa_a_1') },
        { question: t('s_13_qa_q_2'), answer: t('s_13_qa_a_2') },
        { question: t('s_13_qa_q_3'), answer: t('s_13_qa_a_3') },
      ],
    },
    [S.STEP_13_1]: {
      content: t('s_13_1'),
      current: 6,
      total: 22,
      infoContent: t('st_13'),
      type: C.HEIR_CONTACTS,
      linksTo: S.STEP_13_2,
    },
    [S.STEP_13_2]: {
      content: t('s_13_2'),
      current: 7,
      total: 22,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.OWNREGULATION, linksTo: S.STEP_13_2_1 },
        { id: A.EQUALDISTRIBUTION, linksTo: S.STEP_13_3 },
      ],
    },
    [S.STEP_13_2_1]: {
      content: t('s_13_2_1'),
      current: 8,
      total: 22,
      type: C.INHERITANCE_DISTRIBUTION,
      linksTo: S.STEP_13_3,
      qa: [
        { question: t('s_13_2_1_qa_q_1'), answer: t('s_13_2_1_qa_a_1') },
        { question: t('s_13_2_1_qa_q_2'), answer: t('s_13_2_1_qa_a_2') },
      ],
    },
    [S.STEP_13_3]: {
      content: t('s_13_3'),
      current: 9,
      total: 22,
      infoContent: t('st_13_3'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.PROPORTIONATE, linksTo: S.STEP_14 },
        { id: A.THEIRCHILDREN, linksTo: S.STEP_14 },
        { id: A.LEGALSUCCESSION, linksTo: S.STEP_14 },
        { id: A.OWNREGULATION, linksTo: S.STEP_13_3_1 },
      ],
      qa: [{ question: t('s_13_3_qa_q_1'), answer: t('s_13_3_qa_a_1') }],
    },
    [S.STEP_13_3_1]: {
      content: t('s_13_3_1'),
      current: 10,
      total: 22,
      infoContent: t('st_13_3_1'),
      type: C.SUBSTITUTE_HEIR,
      linksTo: S.STEP_14,
      qa: [{ question: t('s_13_3_1_qa_q_1'), answer: t('s_13_3_1_qa_a_1') }],
    },
    [S.STEP_14]: {
      content: t('s_14'),
      current: 11,
      total: 22,
      infoContent: t('st_14'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_15 },
        { id: A.NO, linksTo: S.STEP_15 },
      ],
      qa: [
        { question: t('s_14_qa_q_1'), answer: t('s_14_qa_a_1') },
        { question: t('s_14_qa_q_2'), answer: t('s_14_qa_a_2') },
      ],
    },
    [S.STEP_15]: {
      content: t('s_15'),
      current: 12,
      total: 22,
      infoContent: t('st_15'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_15_1 },
        { id: A.NO, linksTo: S.STEP_16 },
      ],
      qa: [{ question: t('s_15_qa_q_1'), answer: t('s_15_qa_a_1') }],
    },
    [S.STEP_15_1]: {
      content: t('s_15_1'),
      current: 13,
      total: 22,
      infoContent: t('st_15_1'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.APPOINTMENTHEIRS, linksTo: S.STEP_16 },
        { id: A.ALLARRANGEMENTS, linksTo: S.STEP_16 },
      ],
      qa: [{ question: t('s_15_1_qa_q_1'), answer: t('s_15_1_qa_a_1') }],
    },
    [S.STEP_16]: {
      content: t('s_16'),
      current: 14,
      total: 22,
      infoContent: t('st_16'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_16_1 },
        { id: A.NO, linksTo: S.STEP_17 },
      ],
      qa: [{ question: t('s_16_qa_q_1'), answer: t('s_16_qa_a_1') }],
    },
    [S.STEP_16_1]: {
      content: t('s_16_1'),
      current: 15,
      total: 22,
      // infoContent: t('st_16_2'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.ADVANCE_BEQUEST, linksTo: S.STEP_16_2 },
        { id: A.DIVISION_ORDER, linksTo: S.STEP_16_2 },
      ],
    },
    [S.STEP_16_2]: {
      content: t('s_16_2'),
      current: 16,
      total: 22,
      infoContent: t('st_16_2'),
      type: C.BEQUEST,
      linksTo: S.STEP_17,
      qa: [
        { question: t('s_16_2_qa_q_1'), answer: t('s_16_2_qa_a_1') },
        { question: t('s_16_2_qa_q_2'), answer: t('s_16_2_qa_a_2') },
        { question: t('s_16_2_qa_q_3'), answer: t('s_16_2_qa_a_3') },
      ],
    },
    [S.STEP_17]: {
      content: t('s_17'),
      current: 17,
      total: 22,
      infoContent: t('st_17'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_17_1 },
        { id: A.NO, linksTo: S.STEP_19 },
      ],
      qa: [{ question: t('s_17_qa_q_1'), answer: t('s_17_qa_a_1') }],
    },
    [S.STEP_17_1]: {
      content: t('s_17_1'),
      current: 18,
      total: 22,
      infoContent: t('st_17_1'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.TRUSTEDTHIRDPARTY, linksTo: S.STEP_17_1_1 },
        { id: A.PROBATECOURT, linksTo: S.STEP_19 },
      ],
      qa: [{ question: t('s_17_1_qa_q_1'), answer: t('s_17_1_qa_a_1') }],
    },
    [S.STEP_17_1_1]: {
      content: t('s_17_1_1'),
      current: 19,
      total: 22,
      type: C.CONTACT,
      linksTo: S.STEP_17_2,
    },
    [S.STEP_17_2]: {
      content: t('s_17_2'),
      current: 20,
      total: 22,
      infoContent: t('st_17_2'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_19 },
        { id: A.NO, linksTo: S.STEP_19 },
      ],
      qa: [{ question: t('s_17_2_qa_q_1'), answer: t('s_17_2_qa_a_1') }],
    },
    [S.STEP_19]: {
      content: t('s_19'),
      current: 21,
      total: 22,
      type: C.PARTNER_PROFILE,
      linksTo: S.STEP_END,
      customNavigationButtons: true,
    },
    [S.STEP_20]: {
      content: t('s_20'),
      current: 3,
      total: 15,
      type: C.HEIR_CONTACTS,
      linksTo: S.STEP_21,
      qa: [
        { question: t('s_20_qa_q_1'), answer: t('s_20_qa_a_1') },
        { question: t('s_20_qa_q_2'), answer: t('s_20_qa_a_2') },
        { question: t('s_20_qa_q_3'), answer: t('s_20_qa_a_3') },
      ],
    },
    [S.STEP_21]: {
      content: t('s_21'),
      current: 4,
      total: 15,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.OWNREGULATION, linksTo: S.STEP_21_1 },
        { id: A.EQUALDISTRIBUTION, linksTo: S.STEP_22 },
      ],
    },
    [S.STEP_21_1]: {
      content: t('s_4_1'),
      current: 5,
      total: 15,
      infoContent: t('st_21_1'),
      type: C.INHERITANCE_DISTRIBUTION,
      linksTo: S.STEP_22,
      qa: [
        { question: t('s_21_1_qa_q_1'), answer: t('s_21_1_qa_a_1') },
        { question: t('s_21_1_qa_q_2'), answer: t('s_21_1_qa_a_2') },
      ],
    },
    [S.STEP_22]: {
      content: t('s_22'),
      current: 6,
      total: 15,
      infoContent: t('st_5'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_22_1 },
        { id: A.NO, linksTo: S.STEP_23 },
      ],
      qa: [
        { question: t('s_22_qa_q_1'), answer: t('s_22_qa_a_1') },
        { question: t('s_22_qa_q_2'), answer: t('s_22_qa_a_2') },
      ],
    },
    [S.STEP_22_1]: {
      content: t('s_22_1'),
      current: 7,
      total: 15,
      infoContent: t('st_5_1'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.PROPORTIONATE, linksTo: S.STEP_23 },
        { id: A.THEIRCHILDREN, linksTo: S.STEP_23 },
        { id: A.LEGALSUCCESSION, linksTo: S.STEP_23 },
        { id: A.OWNREGULATION, linksTo: S.STEP_22_2 },
      ],
      qa: [{ question: t('s_22_1_qa_q_1'), answer: t('s_22_1_qa_a_1') }],
    },
    [S.STEP_22_2]: {
      content: t('s_22_2'),
      current: 8,
      total: 15,
      infoContent: t('st_5'),
      type: C.SUBSTITUTE_HEIR,
      linksTo: S.STEP_23,
      qa: [{ question: t('s_22_2_qa_q_1'), answer: t('s_22_2_qa_a_1') }],
    },
    [S.STEP_23]: {
      content: t('s_23'),
      current: 9,
      total: 15,
      infoContent: t('st_23'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_23_1 },
        { id: A.NO, linksTo: S.STEP_24 },
      ],
      qa: [{ question: t('s_23_qa_q_1'), answer: t('s_23_qa_a_1') }],
    },
    [S.STEP_23_1]: {
      content: t('s_23_1'),
      current: 10,
      total: 14,
      // infoContent: t('st_23_2'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.ADVANCE_BEQUEST, linksTo: S.STEP_23_2 },
        { id: A.DIVISION_ORDER, linksTo: S.STEP_23_2 },
      ],
    },
    [S.STEP_23_2]: {
      content: t('s_23_2'),
      current: 11,
      total: 15,
      infoContent: t('st_23_2'),
      type: C.BEQUEST,
      linksTo: S.STEP_24,
      qa: [
        { question: t('s_23_2_qa_q_1'), answer: t('s_23_2_qa_a_1') },
        { question: t('s_23_2_qa_q_2'), answer: t('s_23_2_qa_a_2') },
        { question: t('s_23_2_qa_q_3'), answer: t('s_23_2_qa_a_3') },
      ],
    },
    [S.STEP_24]: {
      content: t('s_24'),
      current: 12,
      total: 15,
      infoContent: t('st_24'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_24_1 },
        { id: A.NO, linksTo: S.STEP_END },
      ],
      qa: [{ question: t('s_24_qa_q_1'), answer: t('s_24_qa_a_1') }],
    },
    [S.STEP_24_1]: {
      content: t('s_24_1'),
      current: 13,
      total: 15,
      infoContent: t('st_24_1'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.TRUSTEDTHIRDPARTY, linksTo: S.STEP_24_1_1 },
        { id: A.PROBATECOURT, linksTo: S.STEP_END },
      ],
      qa: [{ question: t('s_24_1_qa_q_1'), answer: t('s_24_1_qa_a_1') }],
    },
    [S.STEP_24_1_1]: {
      content: t('s_24_1_1'),
      current: 14,
      total: 15,
      type: C.CONTACT,
      linksTo: S.STEP_24_2,
    },
    [S.STEP_24_2]: {
      content: t('s_24_2'),
      current: 15,
      total: 15,
      infoContent: t('st_24_2'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_END },
        { id: A.NO, linksTo: S.STEP_END },
      ],
      qa: [{ question: t('s_24_2_qa_q_1'), answer: t('s_24_2_qa_a_1') }],
    },
    [S.STEP_END]: {
      content: t('s_end'),
      type: C.END,
    },
  };
};

export const getWillAnswers = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);

  return {
    [A.MARRIED]: {
      content: t('a_married'),
      image: icons.imgMarried,
    },
    [A.NOTMARRIED]: {
      content: t('a_notMarried'),
      image: icons.imgNotMarried,
    },
    [A.REMAINDERMAN]: {
      content: t('a_remainderman'),
      infoContent: t('info_remainderman'),
      image: icons.imgRemainderman,
    },
    [A.FULLINHERITANCE]: {
      content: t('a_fullInheritance'),
      infoContent: t('info_fullInheritance'),
      image: icons.imgFullInheritance,
    },
    [A.OWNREGULATION]: {
      content: t('a_ownRegulation'),
      infoContent: t('info_ownRegulation'),
      image: icons.imgOwnRegulation,
    },
    [A.EQUALDISTRIBUTION]: {
      content: t('a_equalDistribution'),
      infoContent: t('info_equalDistribution'),
      image: icons.imgEqualDistribution,
    },
    [A.PROPORTIONATE]: {
      content: t('a_proportionate'),
      image: icons.imgProportionate,
    },
    [A.THEIRCHILDREN]: {
      content: t('a_theirChildren'),
      image: icons.imgTheirChildren,
    },
    [A.LEGALSUCCESSION]: {
      content: t('a_legalSuccession'),
      image: icons.imgLegalSuccession,
    },
    [A.TRUSTEDTHIRDPARTY]: {
      content: t('a_trustedThirdParty'),
      image: icons.imgTrustedThirdParty,
    },
    [A.PROBATECOURT]: {
      content: t('a_probateCourt'),
      infoContent: t('info_probateCourt'),
      image: icons.imgPropateCourt,
    },
    [A.SPOUSAL]: {
      content: t('a_spousal'),
      infoContent: t('info_spousal'),
      image: icons.imgSpousal,
    },
    [A.INDIVIDUAL]: {
      content: t('a_individual'),
      infoContent: t('info_individual'),
      image: icons.imgIndividual,
    },
    [A.APPOINTMENTHEIRS]: {
      content: t('a_appointmentHeirs'),
      infoContent: t('info_appointmentHeirs'),
      image: icons.imgAppointmentHeirs,
    },
    [A.ALLARRANGEMENTS]: {
      content: t('a_allArrangements'),
      infoContent: t('info_allArrangements'),
      image: icons.imgAllArrangements,
    },
    [A.YES]: {
      content: t('a_true'),
      image: icons.imgYes,
    },
    [A.NO]: {
      content: t('a_false'),
      image: icons.imgNo,
    },
    [A.ADVANCE_BEQUEST]: {
      content: t('a_advance_bequest'),
      infoContent: t('info_advance_bequest'),
      image: icons.imgAdvanceBequest,
    },
    [A.DIVISION_ORDER]: {
      content: t('a_division_order'),
      infoContent: t('info_division_order'),
      image: icons.imgDivisionOrder,
    },
  };
};

export const getWillStartScreen = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    listItems: [
      t('start_screen_list_1'),
      t('start_screen_list_2'),
      t('start_screen_list_3'),
    ],
  };
};
