import imgYes from 'images/patient-decree/yes.svg';
import imgNo from 'images/patient-decree/no.svg';
import imgNothing from 'images/initial-assessment/imgNothing.svg';

// patient decree
import imgPalliative from 'images/patient-decree/palliative.svg';
import imgIncurableDisease from 'images/patient-decree/incurableDisease.svg';
import imgBrainDamage from 'images/patient-decree/brainDamage.svg';
import imgBrainDegeneration from 'images/patient-decree/brainDegeneration.svg';
import imgDescription from 'images/patient-decree/description.svg';
import imgSafeLife from 'images/patient-decree/safeLife.svg';
import imgNotSafeLife from 'images/patient-decree/notSafeLife.svg';
import imgReanimation from 'images/patient-decree/reanimation.svg';
import imgRejectReanimation from 'images/patient-decree/rejectReanimation.svg';
import imgRefuseReanimation from 'images/patient-decree/refuseReanimation.svg';
import imgHome from 'images/patient-decree/home.svg';
import imgHospital from 'images/patient-decree/hospital.svg';
import imgHospice from 'images/patient-decree/hospice.svg';
import imgContact from 'images/patient-decree/contact.svg';
import imgChurch from 'images/patient-decree/church.svg';
import imgRepresentative from 'images/patient-decree/representative.svg';
import imgCaregiver from 'images/patient-decree/caregiver.svg';
import imgDoctor from 'images/patient-decree/doctor.svg';
import imgSomeoneElse from 'images/patient-decree/someoneElse.svg';
import imgOrganDonor from 'images/patient-decree/organDonor.svg';
import imgPatientDecree from 'images/patient-decree/patientDecree.svg';
import imgRevoke from 'images/patient-decree/revoke.svg';
import imgTimelimit from 'images/patient-decree/timelimit.svg';
import imgReference from 'images/patient-decree/reference.svg';
import imgUpload from 'images/patient-decree/upload.svg';
import imgReferenceDocument from 'images/patient-decree/referenceDocument.svg';

// health care
import imgMedicalReport from 'images/health-care-proxy/imgMedicalReport.svg';
import imgMedicalConfirmation from 'images/health-care-proxy/imgMedicalConfirmation.svg';
import imgFullRepresentation from 'images/health-care-proxy/imgFullRepresentation.svg';
import imgOwnSpecification from 'images/health-care-proxy/imgOwnSpecification.svg';
import imgViewDocuments from 'images/health-care-proxy/imgViewDocuments.svg';
import imgHealthIssues from 'images/health-care-proxy/imgHealthIssues.svg';
import imgTowardsNursingStaff from 'images/health-care-proxy/imgTowardsNursingStaff.svg';
import imgLibertyDeprivation from 'images/health-care-proxy/imgLibertyDeprivation.svg';
import imgMedicalMeasures from 'images/health-care-proxy/imgMedicalMeasures.svg';
import imgDeterminateLocation from 'images/health-care-proxy/imgDeterminateLocation.svg';
import imgRentalContracts from 'images/health-care-proxy/imgRentalContracts.svg';
import imgNursingHomeContracts from 'images/health-care-proxy/imgNursingHomeContracts.svg';
import imgAuthorities from 'images/health-care-proxy/imgAuthorities.svg';
import imgCourts from 'images/health-care-proxy/imgCourts.svg';
import imgHireAttorneys from 'images/health-care-proxy/imgHireAttorneys.svg';
import imgDelete from 'images/health-care-proxy/imgDelete.svg';
import imgDispose from 'images/health-care-proxy/imgDispose.svg';
import imgPersonalMail from 'images/health-care-proxy/imgPersonalMail.svg';
import imgTelecommunication from 'images/health-care-proxy/imgTelecommunication.svg';
import imgBankRepresentation from 'images/health-care-proxy/imgBankRepresentation.svg';
import imgInsurances from 'images/health-care-proxy/imgInsurances.svg';
import imgNoDonations from 'images/health-care-proxy/imgNoDonations.svg';
import imgNoDonationsToOneself from 'images/health-care-proxy/imgNoDonationsToOneself.svg';

// will generator
import imgMarried from 'images/will/imgMarried.svg';
import imgNotMarried from 'images/will/imgNotMarried.svg';
import imgRemainderman from 'images/will/imgRemainderman.svg';
import imgFullInheritance from 'images/will/imgFullInheritance.svg';
import imgOwnRegulation from 'images/will/imgOwnRegulation.svg';
import imgEqualDistribution from 'images/will/imgEqualDistribution.svg';
import imgProportionate from 'images/will/imgProportionate.svg';
import imgTheirChildren from 'images/will/imgTheirChildren.svg';
import imgLegalSuccession from 'images/will/imgLegalSuccession.svg';
import imgTrustedThirdParty from 'images/will/imgTrustedThirdParty.svg';
import imgPropateCourt from 'images/will/imgPropateCourt.svg';
import imgSpousal from 'images/will/imgSpousal.svg';
import imgIndividual from 'images/will/imgIndividual.svg';
import imgAppointmentHeirs from 'images/will/imgAppointmentHeirs.svg';
import imgAllArrangements from 'images/will/imgAllArrangements.svg';
import imgAdvanceBequest from 'images/will/imgAdvanceBequest.svg';
import imgDivisionOrder from 'images/will/imgDivisionOrder.svg';

// ODC
import imgException from 'images/organ-donor-card/imgException.svg';
import imgCertainOrgans from 'images/organ-donor-card/imgCertainOrgans.svg';
import imgPersonToDecide from 'images/organ-donor-card/imgPersonToDecide.svg';

// INITIAL ASSESSMENT
import imgAdults from 'images/initial-assessment/imgAdults.svg';
import imgCars from 'images/initial-assessment/imgCars.svg';
import imgCloud from 'images/initial-assessment/imgCloud.svg';
import imgComplicated from 'images/initial-assessment/imgComplicated.svg';
import imgComputer from 'images/initial-assessment/imgComputer.svg';
import imgOnlineBanking from 'images/initial-assessment/imgOnlineBanking.svg';
import imgPartnered from 'images/initial-assessment/imgPartnered.svg';
import imgProperty from 'images/initial-assessment/imgProperty.svg';
import imgRealEstate from 'images/initial-assessment/imgRealEstate.svg';
import imgRent from 'images/initial-assessment/imgRent.svg';
import imgRiskSport from 'images/initial-assessment/imgRiskSport.svg';
import imgSeriousIllness from 'images/initial-assessment/imgSeriousIllness.svg';
import imgSingle from 'images/initial-assessment/imgSingle.svg';
import imgSocialMedia from 'images/initial-assessment/imgSocialMedia.svg';
import imgStocks from 'images/initial-assessment/imgStocks.svg';
import imgSubscriptions from 'images/initial-assessment/imgSubscriptions.svg';
import imgHandy from 'images/initial-assessment/imgHandy.svg';
import imgNeedFor from 'images/initial-assessment/imgNeedFor.svg';
import imgWidowed from 'images/initial-assessment/imgWidowed.svg';
import imgFamilyDoctor from 'images/initial-assessment/imgFamilyDoctor.svg';
import imgAttorney from 'images/initial-assessment/imgAttorney.svg';
import imgFinancialAdvisor from 'images/initial-assessment/imgFinancialAdvisor.svg';
import imgPasswordManager from 'images/initial-assessment/imgPasswordManager.svg';
import imgPaidAppsLicense from 'images/initial-assessment/imgPaidAppsLicense.svg';
import imgLifeInsurance from 'images/initial-assessment/imgLifeInsurance.svg';
import imgDeathBenefitInsurance from 'images/initial-assessment/imgDeathBenefitInsurance.svg';
import imgCareInsurance from 'images/initial-assessment/imgCareInsurance.svg';
import imgOccupationalDisabilityInsurance from 'images/initial-assessment/imgOccupationalDisabilityInsurance.svg';
import imgAdditionalHealthInsurance from 'images/initial-assessment/imgAdditionalHealthInsurance.svg';
import imgLegalProtectionInsurance from 'images/initial-assessment/imgLegalProtectionInsurance.svg';
import imgHouseholdContentInsurance from 'images/initial-assessment/imgHouseholdContentInsurance.svg';
import imgOrganDonorCard from 'images/initial-assessment/imgOrganDonorCard.svg';
import imgHealthCareProxy from 'images/initial-assessment/imgHealthCareProxy.svg';
import imgEmergencyPass from 'images/initial-assessment/imgEmergencyPass.svg';
import imgSafeDeposit from 'images/initial-assessment/imgSafeDeposit.svg';
import imgLastWill from 'images/initial-assessment/imgLastWill.svg';
import imgBankingPowerOfAttorney from 'images/initial-assessment/imgBankingPowerOfAttorney.svg';
import imgOtherPowerOfAttorney from 'images/initial-assessment/imgOtherPowerOfAttorney.svg';
import imgCertificate from 'images/initial-assessment/imgCertificate.svg';
import imgPet from 'images/initial-assessment/imgPet.svg';
import imgLiability from 'images/initial-assessment/imgLiability.svg';
import imgPatientDecreeInit from 'images/initial-assessment/imgPatientDecreeInit.svg';

// Pet watch agreement
import imgSubstituteAuthorizedPerson from 'images/pet-watching-agreement/imgSubstituteAuthorizedPerson.svg';
import imgShelter from 'images/pet-watching-agreement/imgShelter.svg';
import imgLiabilityInsurance from 'images/pet-watching-agreement/imgLiabilityInsurance.svg';
import imgHealthInsurance from 'images/pet-watching-agreement/imgHealthInsurance.svg';
import imgSurgeryCostInsurance from 'images/pet-watching-agreement/imgSurgeryCostInsurance.svg';
import imgOtherInsurance from 'images/pet-watching-agreement/imgOtherInsurance.svg';
import imgFreeRoaming from 'images/pet-watching-agreement/imgFreeRoaming.svg';
import imgLikeChildren from 'images/pet-watching-agreement/imgLikeChildren.svg';
import imgLikeOldPeople from 'images/pet-watching-agreement/imgLikeOldPeople.svg';
import imgLikeToBeInCars from 'images/pet-watching-agreement/imgLikeToBeInCars.svg';
import imgCanBeTemporarilyAtHome from 'images/pet-watching-agreement/imgCanBeTemporarilyAtHome.svg';

// Funeral Provision
import imgEarth from 'images/funeral-provision/imgEarth.svg';
import imgFire from 'images/funeral-provision/imgFire.svg';
import imgTree from 'images/funeral-provision/imgTree.svg';
import imgSea from 'images/funeral-provision/imgSea.svg';
import imgOtherBurial from 'images/funeral-provision/imgOtherBurial.svg';
import imgChoiceGrave from 'images/funeral-provision/imgChoiceGrave.svg';
import imgRowGrave from 'images/funeral-provision/imgRowGrave.svg';
import imgAnonymousGrave from 'images/funeral-provision/imgAnonymousGrave.svg';
import imgEcclesiastical from 'images/funeral-provision/imgEcclesiastical.svg';
import imgSecular from 'images/funeral-provision/imgSecular.svg';
import imgNoFuneralService from 'images/funeral-provision/imgNoFuneralService.svg';
import imgSpiritualPerson from 'images/funeral-provision/imgSpiritualPerson.svg';
import imgSecularPerson from 'images/funeral-provision/imgSecularPerson.svg';
import imgOtherPerson from 'images/funeral-provision/imgOtherPerson.svg';
import imgFamily from 'images/funeral-provision/imgFamily.svg';
import imgFuneralHome from 'images/funeral-provision/imgFuneralHome.svg';

const imgDeathProcess = imgTimelimit;
const imgNobody = imgNo;

const icons = {
  imgYes,
  imgNo,
  imgPalliative,
  imgDeathProcess,
  imgIncurableDisease,
  imgBrainDamage,
  imgBrainDegeneration,
  imgDescription,
  imgSafeLife,
  imgNotSafeLife,
  imgReanimation,
  imgRejectReanimation,
  imgRefuseReanimation,
  imgHome,
  imgHospital,
  imgHospice,
  imgContact,
  imgChurch,
  imgRepresentative,
  imgCaregiver,
  imgDoctor,
  imgSomeoneElse,
  imgNobody,
  imgOrganDonor,
  imgPatientDecree,
  imgRevoke,
  imgTimelimit,
  imgReference,
  imgReferenceDocument,
  imgUpload,
  imgMedicalReport,
  imgMedicalConfirmation,
  imgFullRepresentation,
  imgOwnSpecification,
  imgViewDocuments,
  imgHealthIssues,
  imgTowardsNursingStaff,
  imgNothing,
  imgLibertyDeprivation,
  imgMedicalMeasures,
  imgDeterminateLocation,
  imgRentalContracts,
  imgNursingHomeContracts,
  imgAuthorities,
  imgCourts,
  imgHireAttorneys,
  imgDelete,
  imgDispose,
  imgPersonalMail,
  imgTelecommunication,
  imgBankRepresentation,
  imgInsurances,
  imgNoDonations,
  imgNoDonationsToOneself,
  imgMarried,
  imgWidowed,
  imgNotMarried,
  imgRemainderman,
  imgFullInheritance,
  imgOwnRegulation,
  imgEqualDistribution,
  imgProportionate,
  imgTheirChildren,
  imgLegalSuccession,
  imgTrustedThirdParty,
  imgPropateCourt,
  imgSpousal,
  imgIndividual,
  imgAppointmentHeirs,
  imgAllArrangements,
  imgException,
  imgCertainOrgans,
  imgPersonToDecide,
  imgAdults,
  imgCars,
  imgCloud,
  imgComplicated,
  imgComputer,
  imgOnlineBanking,
  imgPartnered,
  imgProperty,
  imgRealEstate,
  imgRent,
  imgRiskSport,
  imgSeriousIllness,
  imgSingle,
  imgSocialMedia,
  imgStocks,
  imgSubscriptions,
  imgHandy,
  imgNeedFor,
  imgSubstituteAuthorizedPerson,
  imgShelter,
  imgLiabilityInsurance,
  imgHealthInsurance,
  imgSurgeryCostInsurance,
  imgOtherInsurance,
  imgFreeRoaming,
  imgLikeChildren,
  imgLikeOldPeople,
  imgLikeToBeInCars,
  imgCanBeTemporarilyAtHome,
  imgFamilyDoctor,
  imgAttorney,
  imgFinancialAdvisor,
  imgPasswordManager,
  imgPaidAppsLicense,
  imgLifeInsurance,
  imgDeathBenefitInsurance,
  imgCareInsurance,
  imgOccupationalDisabilityInsurance,
  imgAdditionalHealthInsurance,
  imgLegalProtectionInsurance,
  imgHouseholdContentInsurance,
  imgOrganDonorCard,
  imgHealthCareProxy,
  imgEmergencyPass,
  imgSafeDeposit,
  imgLastWill,
  imgBankingPowerOfAttorney,
  imgOtherPowerOfAttorney,
  imgCertificate,
  imgPet,
  imgLiability,
  imgPatientDecreeInit,
  imgEarth,
  imgFire,
  imgTree,
  imgSea,
  imgOtherBurial,
  imgChoiceGrave,
  imgRowGrave,
  imgAnonymousGrave,
  imgEcclesiastical,
  imgSecular,
  imgNoFuneralService,
  imgSpiritualPerson,
  imgSecularPerson,
  imgOtherPerson,
  imgFamily,
  imgFuneralHome,
  imgAdvanceBequest,
  imgDivisionOrder,
};
export default icons;
