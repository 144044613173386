import React from 'react';

import { useTranslation } from 'modules/common/helpers';

export interface ProgressProps {
  currStepData: QuestionnaireStepDTO;
}

export default function Progress({ currStepData }: ProgressProps) {
  const { t } = useTranslation(['assistant']);

  const getProgressText = () => {
    switch (true) {
      case percentDone > 80:
        return t('assistant:progress_almost_done');
      case percentDone > 50:
        return t('assistant:progress_half_done');
      default:
        return null;
    }
  };

  const { current, total } = currStepData;

  const percentDone = ((current - 1) / total) * 100;
  const progressItemWidth = 100 / total || 0;
  const totalNumbersArr = total && [...Array(total)];
  const progressText = getProgressText();

  const progressLabel = t('assistant:progress_label', {
    current: `${current - 1}`,
    total: `${total || ''}`,
  });

  return (
    <>
      <div className="t-flex t-justify-between t-mb-2.5">
        {totalNumbersArr &&
          totalNumbersArr.map((_, idx) => {
            const isDone = idx < current - 1;
            const isLast = idx === totalNumbersArr.length - 1;

            return (
              <div
                className={`${
                  isDone ? 't-bg-delta-500' : 't-bg-delta-200'
                } t-h-1.5 ${!isLast ? 'md:t-mr-2 md:t-rounded-xs' : ''}`}
                style={{ width: `${progressItemWidth}%` }}
                key={idx}
              />
            );
          })}
      </div>
      <div className="sm:t-flex sm:t-justify-between t-mx-5">
        {totalNumbersArr && (
          <p className="t-text-delta-500 ">{progressLabel}</p>
        )}
        {totalNumbersArr && (
          <p className="t-text-beta-400 xs:t-mt-2 sm:t-mt-0 t-uppercase t-text-xxs t-tracking-widest">
            {progressText}
          </p>
        )}
      </div>
    </>
  );
}
