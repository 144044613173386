import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { format, parseISO } from 'date-fns';

import useGetLocalizationForDate from 'localization/utils/useGetLocalizationForDate';
import { getFullName, config, useTranslation } from 'modules/common/helpers';
import { P, Slider } from 'modules/elements/components';

export interface HeirDistributionCardProps {
  heir: ContactDTO;
  onAfterChange?: (val: number) => void;
  value: number;
  onChange: (val: number) => void;
  maxDistribution: number;
  step: number;
}

const Styles = styled.div`
  overflow: visible;
  img {
    border-radius: 50%;
    width: 56px;
    height: 56px;
  }
  .nameCol {
    align-self: center;
    text-align: left;
    font-family: var(--font-family-primary);
  }
  .sliderCol {
    align-self: flex-end;
  }
`;

const HeirDistributionCard = ({
  heir,
  onAfterChange,
  maxDistribution,
  value,
  onChange,
  step,
}: HeirDistributionCardProps) => {
  const { t } = useTranslation(['common', 'will']);
  const locale = useGetLocalizationForDate();

  const fullName = getFullName(heir);

  return (
    <Styles>
      <div className="card xs:t-w-full md:t-w-4/5 xs:t-p-4 md:t-p-7">
        <Row>
          <Col md={4} sm={12} className="nameCol xs:t-mb-10 md:t-mb-0">
            <p className="t-text-lg">{fullName}</p>
            <p>
              {format(
                parseISO(heir.birthday || ''),
                config.format.uiDashboardDate,
                { locale }
              )}
            </p>
          </Col>
          <Col md={8} xs={12} className="sliderCol">
            <Row className="justify-content-md-end">
              <Col sm={10}>
                <Slider
                  step={step}
                  onAfterChange={onAfterChange}
                  marks={{
                    [maxDistribution]: (
                      <div
                        className={`mark-text ${
                          maxDistribution === value ? 't-text-alpha-600' : ''
                        }`}
                      >
                        <p className="text">{t('will:maxDistributionLabel')}</p>
                        <div className="arrow" />
                      </div>
                    ),
                  }}
                  onChange={onChange}
                  value={value}
                />
              </Col>
              <Col
                sm={2}
                style={{ bottom: '2px' }}
                className="xs:t-mt-5 sm:t-mt-0 t-whitespace-nowrap"
              >
                <P
                  fontFamily="var(--font-family-primary)"
                  fontSize="var(--font-size-lg)"
                  semibold
                >{`${value} %`}</P>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Styles>
  );
};

export default HeirDistributionCard;
