import i18n from 'localization/i18n';
import { ASSET_CATEGORIES } from 'modules/estatePlan/constants';
import { GET_ASSETS_BY_CATEGORY } from 'store/types/assetTypes';
import { zvrService } from 'modules/estatePlan/services';
import helper from 'store/actions/actionHelper';
import { reactToast } from 'modules/common/helpers';
import { setModal } from 'store/actions/modalActions';
import { MODALS } from 'modules/common/constants';
import { getCurrentUser } from 'store/actions/userActions';

export const revokeEntireZVRDocument = (actionOptions?: ActionOptions) => {
  return helper.dispatchAction(async (dispatch) => {
    const assetsByCategoryAfterRevoked =
      await zvrService.revokeEntireZVRDocument();
    dispatch(
      helper.successAction(GET_ASSETS_BY_CATEGORY, {
        assets: assetsByCategoryAfterRevoked,
        assetCategory: ASSET_CATEGORIES.HEALTH,
      })
    );
    if (assetsByCategoryAfterRevoked) {
      reactToast.showMessage(
        i18n.t('zvrRegistration:successful_de_registration')
      );
    }
    await dispatch(getCurrentUser());
  }, actionOptions);
};

export const revokeContactFromZVRDocument = (
  contactId: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAction(async (dispatch) => {
    const assetsByCategoryAfterRevoked = await zvrService.revokeTrustedPerson(
      contactId
    );
    dispatch(
      helper.successAction(GET_ASSETS_BY_CATEGORY, {
        assets: assetsByCategoryAfterRevoked,
        assetCategory: ASSET_CATEGORIES.HEALTH,
      })
    );
  }, actionOptions);
};

export const getZvrPaymentSession = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await zvrService.getZvrPaymentSession();

    if (response?.message) {
      reactToast.showMessage(response.message);
    }

    return response.sessionId;
  }, actionOptions);
};

export const zvrRegistration = (
  paymentToken: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const assetsByCategoryAfterRegistered = await zvrService.zvrRegistration(
      paymentToken
    );

    dispatch(
      helper.successAction(GET_ASSETS_BY_CATEGORY, {
        assets: assetsByCategoryAfterRegistered,
        assetCategory: ASSET_CATEGORIES.HEALTH,
      })
    );
    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: i18n.t('zvrRegistration:successful_registration'),
          secondaryButtonText: i18n.t('zvrRegistration:close'),
          noSuccessBtn: true,
        },
      })
    );
    reactToast.showMessage(i18n.t('zvrRegistration:successful_registration'));
    await dispatch(getCurrentUser());
  }, actionOptions);
};

export const zvrAddExistingContact = (
  contactId: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const assetsByCategoryAfterUpdated = await zvrService.zvrAddExistingContact(
      contactId
    );
    dispatch(
      helper.successAction(GET_ASSETS_BY_CATEGORY, {
        assets: assetsByCategoryAfterUpdated,
        assetCategory: ASSET_CATEGORIES.HEALTH,
      })
    );
  }, actionOptions);
};
