import React from 'react';
import { Accordion } from 'react-bootstrap';

import { useGetAnswers } from 'modules/estatePlan/helpers';
import { AnswersAccordionItem } from 'modules/estatePlan/components';

import { PAGES } from 'modules/estatePlan/constants/enums';

type AnswersAccordionProps = {
  page: PAGES;
  itemClassName?: string;
  firstItemActive?: boolean;
};

const AnswersAccordion = ({
  page,
  itemClassName,
  firstItemActive,
}: AnswersAccordionProps) => {
  const answers = useGetAnswers(page);

  return (
    <Accordion
      className="Accordion"
      defaultActiveKey={firstItemActive ? answers[0].question : null}
    >
      {answers.map((item) => (
        <AnswersAccordionItem
          key={item.question}
          item={item}
          itemClassName={itemClassName}
        />
      ))}
    </Accordion>
  );
};

export default AnswersAccordion;
