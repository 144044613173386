import React from 'react';

type StepMarkerProps = {
  step: number;
  setStep: (step: number) => void;
};

function StepMarker({ step, setStep }: StepMarkerProps) {
  return (
    <div className="StepMarker">
      <span
        role="presentation"
        className={`StepMarker-item ${step === 1 ? 'isActive' : ''}`}
        onClick={() => setStep(1)}
      />
      <span
        role="presentation"
        className={`StepMarker-item ${step === 2 ? 'isActive' : ''}`}
        onClick={() => setStep(2)}
      />
      <span
        role="presentation"
        className={`StepMarker-item ${step === 3 ? 'isActive' : ''}`}
        onClick={() => setStep(3)}
      />
    </div>
  );
}

export default StepMarker;
