import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { PostalProcess } from 'modules/assetType/components';
import { Modal } from 'modules/common/components';
import { ContactForm } from 'modules/contact/components';

import {
  LOCAL_STORAGE_VARS,
  POSTAL_STEPS,
  POSTAL_TYPES,
} from 'modules/common/constants/enums';

interface PostalContactFormWrapperProps {
  onClose: () => void;
  onSuccess?: () => void;
  isSuccessfulPayment?: boolean;
  isContactModal?: boolean;
  contactToEdit?: ContactDTO;
  onRemovePostalParams?: () => void;
  isModalOverlaid?: boolean;
  handleModalOverlay?: (overlaid: boolean) => void;
  limited?: boolean;
  reloadPostal?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const PostalContactFormWrapper = ({
  onClose,
  onSuccess,
  isSuccessfulPayment,
  isContactModal,
  onRemovePostalParams,
  isModalOverlaid,
  handleModalOverlay,
  children,
  limited,
  reloadPostal,
  contactToEdit = {} as ContactDTO,
}: PostalContactFormWrapperProps) => {
  const { t } = useTranslation(['common']);

  const [contactToPost, setContactToPost] = React.useState(contactToEdit);
  const [postalModalOpen, setPostalModalOpen] = React.useState(
    !!isSuccessfulPayment || !!reloadPostal
  );

  const handlePostalService = (contact: ContactDTO, postal: boolean) => {
    if (contact?.documentId && !!postal) {
      setContactToPost(contact);
      setPostalModalOpen(true);
      return;
    }

    if (onSuccess) {
      onSuccess();
    }
    onClose();
  };

  const onCancelPostal = () => {
    // remove the dateOnRedirect data from localStorage
    if (reloadPostal || isSuccessfulPayment) {
      localStorage.removeItem(LOCAL_STORAGE_VARS.DATA_ON_REDIRECT);
    }

    setContactToPost({} as ContactDTO);
    if (onRemovePostalParams) {
      onRemovePostalParams();
    }
    onClose();
  };

  const postalData = {
    documentId: contactToPost?.documentId,
    documentTitle:
      contactToPost?.documentTitle || `${t('common:document_label')}.pdf`,
  } as StoredData;

  return (
    <React.Fragment>
      {!postalModalOpen && !isContactModal && (
        <ContactForm
          onClose={onCancelPostal}
          onSuccess={handlePostalService}
          limited={limited}
        />
      )}

      {!postalModalOpen && !!isContactModal && (
        <Modal show onHide={onClose} noPadding={isModalOverlaid}>
          {!!children && children}
          <ContactForm
            onClose={onClose}
            contactItem={contactToEdit}
            onSuccess={handlePostalService}
            onModalOverlaid={handleModalOverlay}
            formHidden={isModalOverlaid}
            limited={limited}
          />
        </Modal>
      )}

      {!!postalModalOpen && (
        <PostalProcess
          onCancel={onCancelPostal}
          isSuccessful={isSuccessfulPayment}
          data={postalData}
          contact={contactToPost}
          type={POSTAL_TYPES.CONTACT}
          initialStep={POSTAL_STEPS.STEP_POSTAL_INFO}
        />
      )}
    </React.Fragment>
  );
};

export default PostalContactFormWrapper;
