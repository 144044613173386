import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { camelCase, isEmpty, last } from 'lodash';

import { RootState } from 'store/reducers';
import {
  temporaryFieldSaver,
  temporaryFieldError,
} from 'store/actions/temporaryFieldSaverActions';
import { addQuestionnaireReminder } from 'store/actions/remindActions';
import {
  updateQuestionnaire,
  populateQuestionnaire,
  navigateToStep,
  eraseAnswersAfterStep,
  addQuestionnaireAnswer,
} from 'store/actions/questionnaireActions';
import { getRecommendations } from 'store/actions/recommendationsActions';

import {
  config,
  dateHelper,
  checkDateInThePast,
  customHooks,
  useTranslation,
} from 'modules/common/helpers';
import {
  getPreviousStep,
  getNextStep,
  getStepsArrayFromStore,
  isAnswerEqual,
  isBreaksExistingAnswerChain,
  isEndStepOfQuestionnaire,
} from 'modules/assistant/helpers/questionnaireHelper';

import {
  getQuestionnaire,
  QUESTIONNAIRE_KEYS,
  STEPS_BREAK_ANSWER_CHAIN,
  STEP_COMPONENT_TYPE,
} from 'modules/assistant/constants/questionnaires';
import { STEP_KEYS } from 'modules/assistant/constants/questionnaires/patientDecree';
import { LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';
import { STEP_KEYS as INIT_STEP_KEYS } from 'modules/assistant/constants/questionnaires/initialAssessment';
import { QUESTIONNAIRES_TO_ASSET_TYPES } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';

import { Fade } from 'modules/elements/components';
import {
  Choice,
  StartScreen,
  SuccessScreen,
  FreeText,
  Contact,
  ExpirationDate,
  RequestAdvice,
  Bequest,
  PartnerProfile,
  MultiContacts,
  SubstituteHeir,
  HeirDistribution,
  QuestionnaireForm,
  AddInformation,
} from 'modules/assistant/components/Answers';
import {
  ExtraInfoFooter,
  PrevNextButtons,
  Progress,
  QASection,
  QuestionnaireContacts,
} from 'modules/assistant/components/Questionnaire';
import { EndScreen } from 'modules/assistant/components/Answers/EndScreen';
import { Check24CTACard, UploadCTACard } from 'modules/assistant/components';
import { SummaryBox } from 'modules/assistant/components/ContentQuestionnaire';
import { ReminderModal } from 'modules/assistant/components/Questionnaire';
import ResetButton from 'modules/assistant/constants/questionnaires/ResetButton';

export interface QuestionnaireProps {
  questionnaire: QUESTIONNAIRE_KEYS;
}

const Questionnaire = ({ questionnaire }: QuestionnaireProps) => {
  const { t } = useTranslation(['common', 'assistant', questionnaire]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [animate, setAnimate] = useState(true);
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [routeToNavigateTo, setRouteToNavigateTo] = useState('');
  const [blockNavigation, setBlockNavigation] = React.useState(false);
  const [isBreakable, setIsBreakable] = React.useState(false);

  const selectedAnswers = useSelector(
    (state: RootState) => state.temporaryField
  );
  const questionnaireData = useSelector(
    (state: RootState) => state.questionnaires[questionnaire]
  );
  const currStepId: STEP_KEYS =
    questionnaireData?.currStepId || STEP_KEYS.STEP_START;

  const stepsCollection = getStepsArrayFromStore(questionnaireData);
  const prevStepId: STEP_KEYS | null = getPreviousStep(
    currStepId,
    stepsCollection
  );

  const QUESTIONNAIRE = getQuestionnaire(questionnaire, t);
  const currStepData =
    QUESTIONNAIRE && currStepId ? QUESTIONNAIRE[currStepId] : null;

  const answerValues = selectedAnswers.value;
  const answerError = selectedAnswers.error;

  const user = useSelector((state: RootState) => state.user.current);
  const isVerifiedUser = user?.verified;

  // this works for both Assistant 1.0 and 2.0
  const questionnaireProgresses = useSelector(
    (state: RootState) => state.assistant.progress
  );
  const questionnaireProgress = questionnaireProgresses[questionnaire];

  // remove GO TO FINAL SCREEN button if the answer is changed
  // note: applied only for STEPS_BREAK_ANSWER_CHAIN
  useEffect(() => {
    if (Object.values(STEPS_BREAK_ANSWER_CHAIN).includes(currStepData?.type)) {
      const formattedAnswer = formattingAnswer(answerValues);
      const previouslySavedAnswer = questionnaireData[currStepId];
      const hasAnswerChanged = !isAnswerEqual(
        formattedAnswer,
        previouslySavedAnswer
      );
      setIsBreakable(hasAnswerChanged);
    } else {
      setIsBreakable(false);
    }
  }, [answerValues]);

  useEffect(() => {
    if (user.initialAssessment === 1) dispatch(getRecommendations());

    // if there is no currStepId inside the current questionnaire,
    // we need to either populate the store from the backend or initialise it with a currStepId
    if (!questionnaireData?.currStepId) {
      dispatch(populateQuestionnaire(questionnaire));
    }
  }, []);

  useEffect(() => {
    if (questionnaireData?.currStepId) {
      dispatch(addQuestionnaireAnswer(questionnaire, questionnaireData));
    }
  }, [questionnaireData, questionnaire]);

  useLayoutEffect(() => {
    setAnimate(true);
  }, [currStepId]);

  const scrollTo = useCallback(
    (ref) => {
      if (ref && currStepId && currStepId !== STEP_KEYS.STEP_START) {
        ref.scrollIntoView(true);
      }
    },
    [currStepId]
  );

  useEffect(() => {
    const hasReminder = questionnaireProgress?.reminder;
    // block only if questionnaire is in progress & user has not set a reminder & user has snoozed the reminder
    const stringifiedReminderLS = localStorage.getItem(
      LOCAL_STORAGE_VARS.SNOOZE_REMIND_QUESTIONNAIRE
    );
    const parsedReminderLS = stringifiedReminderLS
      ? JSON.parse(stringifiedReminderLS)
      : {};
    const snoozeDate = parsedReminderLS[questionnaire];
    const isExpired = snoozeDate
      ? new Date(snoozeDate).getTime() < Date.now()
      : true;
    const isSubscriptionExpired = checkDateInThePast(user.subscriptionEnd!);

    if (
      !!currStepData &&
      questionnaire !== 'initialAssessment' &&
      !routeToNavigateTo &&
      questionnaireProgress?.status !== 1 &&
      !hasReminder &&
      isExpired &&
      !isSubscriptionExpired &&
      isVerifiedUser &&
      currStepId !== STEP_KEYS.STEP_START
    ) {
      setBlockNavigation(true);
    } else {
      setBlockNavigation(false);
    }
  }, [routeToNavigateTo, questionnaireProgress]);

  // prevents the user from leaving the questionnaire before confirming in reminder modal
  customHooks.useBlocker(({ location, retry }) => {
    setRouteToNavigateTo(location?.pathname);
    if (location?.pathname !== '/login') {
      setIsReminderModalOpen(true);
    } else {
      // on logout, "retry" is needed or the redirect will be prevented.
      retry();
      return;
    }
  }, blockNavigation);

  const handleResetAllAnswers = () => {
    dispatch(navigateToStep(questionnaire, STEP_KEYS.STEP_START));
    dispatch(addQuestionnaireAnswer(questionnaire, {}));
    dispatch(eraseAnswersAfterStep(questionnaire, STEP_KEYS.STEP_START));
  };

  // allow the user to escape from a blank page
  if (!currStepData)
    return (
      <ResetButton
        reset={handleResetAllAnswers}
        className="t-flex t-justify-end t-mt-10"
      />
    );

  const formattingAnswer = (answers) => {
    let formattedAnswer = answers;
    if (currStepData.type === STEP_COMPONENT_TYPE.EXPIRATION_DATE) {
      if (typeof answers === 'string')
        formattedAnswer = dateHelper.convertDateFormat(
          answers,
          config.format.serverDate
        );
    }

    return formattedAnswer;
  };

  const handleSubmission = (
    answers: SelectedAnswer[] | SelectedAnswer | string
  ) => {
    setAnimate(false);

    const formattedAnswer = formattingAnswer(answers);

    const linksTo = getNextStep(currStepData, formattedAnswer);

    const previouslySavedAnswer = questionnaireData[currStepId];
    const hasAnswerChanged = !isAnswerEqual(
      formattedAnswer,
      previouslySavedAnswer
    );

    if (hasAnswerChanged) {
      dispatch(
        updateQuestionnaire(
          questionnaire,
          currStepId,
          formattedAnswer,
          linksTo,
          currStepData.type
        )
      );
      if (
        isBreaksExistingAnswerChain(currStepId, linksTo, stepsCollection) ||
        isBreakable
      ) {
        dispatch(eraseAnswersAfterStep(questionnaire, currStepId));
      }
    } else {
      dispatch(navigateToStep(questionnaire, linksTo));
    }
    dispatch(temporaryFieldSaver(null));
    dispatch(temporaryFieldError(null));
  };

  const handleGoToPrevQuestion = () => {
    if (prevStepId) {
      setAnimate(false);
      dispatch(navigateToStep(questionnaire, prevStepId));
      dispatch(temporaryFieldSaver(null));
      dispatch(temporaryFieldError(null));
    }
  };

  const getAnswerComponentByType = () => {
    const {
      type,
      possibleAnswers,
      formElements,
    }: {
      type: STEP_COMPONENT_TYPE;
      possibleAnswers: SelectedAnswer[];
      formElements: QuestionnaireFormType[];
    } = currStepData;
    const previousAnswers = questionnaireData[currStepId];

    const choiceProps = {
      possibleAnswers: possibleAnswers,
      previousAnswers: previousAnswers,
      questionnaire: questionnaire,
      handleSubmit: handleSubmission,
      key: currStepId,
      // undefined because react doesn't send the whole prop instead of sending "isHybrid: false" if just falsy
      isHybrid: type === STEP_COMPONENT_TYPE.SINGLEANDMULTIPLE,
      isMultipleChoice: type === STEP_COMPONENT_TYPE.MULTIPLE,
    };

    switch (type) {
      case STEP_COMPONENT_TYPE.START:
        return <StartScreen questionnaire={questionnaire} />;
      case STEP_COMPONENT_TYPE.SUCCESS:
        return <SuccessScreen />;
      case STEP_COMPONENT_TYPE.END:
        return <EndScreen questionnaire={questionnaire} />;
      case STEP_COMPONENT_TYPE.SINGLE:
      case STEP_COMPONENT_TYPE.MULTIPLE:
      case STEP_COMPONENT_TYPE.SINGLEANDMULTIPLE:
        return <Choice {...choiceProps} />;
      case STEP_COMPONENT_TYPE.FREE_TEXT:
        return (
          <FreeText
            currStepData={currStepData}
            previousAnswer={previousAnswers}
            key={currStepId}
          />
        );
      case STEP_COMPONENT_TYPE.EXPIRATION_DATE:
        return (
          <ExpirationDate previousAnswers={previousAnswers} key={currStepId} />
        );
      case STEP_COMPONENT_TYPE.CONTACT:
        return <Contact previousAnswer={previousAnswers} key={currStepId} />;
      case STEP_COMPONENT_TYPE.HEIR_CONTACTS:
        return (
          <QuestionnaireContacts
            previousAnswer={previousAnswers}
            key={currStepId}
            addNewButtonText={t('common:add_new_heir')}
            birthPlaceRequired={true}
          />
        );
      case STEP_COMPONENT_TYPE.BEQUEST:
        return (
          <Bequest
            previousAnswer={previousAnswers}
            key={currStepId}
            questionnaireData={questionnaireData}
          />
        );
      case STEP_COMPONENT_TYPE.SUBSTITUTE_HEIR:
        return (
          <SubstituteHeir previousAnswer={previousAnswers} key={currStepId} />
        );
      case STEP_COMPONENT_TYPE.INHERITANCE_DISTRIBUTION:
        return (
          <HeirDistribution previousAnswer={previousAnswers} key={currStepId} />
        );
      case STEP_COMPONENT_TYPE.REQUEST_ADVICE:
        return (
          <RequestAdvice handleGoToPrevQuestion={handleGoToPrevQuestion} />
        );
      case STEP_COMPONENT_TYPE.PARTNER_PROFILE:
        return (
          <PartnerProfile
            previousAnswer={previousAnswers}
            handleGoToPrevQuestion={handleGoToPrevQuestion}
            handleSubmission={handleSubmission}
          />
        );
      case STEP_COMPONENT_TYPE.MULTI_CONTACTS:
        return (
          <MultiContacts previousAnswer={previousAnswers} key={currStepId} />
        );
      case STEP_COMPONENT_TYPE.FORM:
        return (
          <QuestionnaireForm
            questionnaire={questionnaire}
            previousAnswer={previousAnswers}
            currStepData={currStepData}
            key={currStepId}
            formTypeData={formElements}
            handleSubmission={handleSubmission}
            handleGoToPrevQuestion={handleGoToPrevQuestion}
            isEndScreenAvailable={isEndScreenAvailable}
          />
        );
      case STEP_COMPONENT_TYPE.ADD_INFORMATION:
        return (
          <AddInformation
            previousAnswer={previousAnswers}
            key={currStepId}
            formTypeData={formElements}
          />
        );
      case STEP_COMPONENT_TYPE.BANK_POA_CONTACT:
        return (
          <QuestionnaireContacts
            previousAnswer={previousAnswers}
            key={currStepId}
            isSingleSelection={true}
            phoneRequired={true}
          />
        );
      default:
        return null;
    }
  };

  const onPrevNextButtonsClick = (): void => {
    handleSubmission(answerValues);
  };

  const handleContinueQuestionnaire = () => {
    setIsReminderModalOpen(false);
    setRouteToNavigateTo('');
  };

  const handleQuitQuestionnaire = async (reminder) => {
    if (reminder) {
      await handleReminderSubmit(reminder);
    }
    const copiedState = routeToNavigateTo.slice();
    setRouteToNavigateTo('');
    navigate(copiedState);
  };

  const handleReminderSubmit = async (reminder) => {
    const formattedQuestionnaire = questionnaire
      ?.replace(/([A-Z])/g, ' $1')
      ?.toLowerCase();
    await dispatch(addQuestionnaireReminder(reminder, formattedQuestionnaire));
    if (user.initialAssessment === 1) await dispatch(getRecommendations());
  };

  const isFirstStep =
    currStepId === STEP_KEYS.STEP_START ||
    (currStepId as INIT_STEP_KEYS) === INIT_STEP_KEYS.STEP_START;
  const isInitialAssessment =
    questionnaire === QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT;

  const isEndStep = isEndStepOfQuestionnaire(questionnaire, currStepId);
  const isChoiceMade = !isEmpty(answerValues) || isFirstStep;
  // next button does not exist in the first step of the questionnaires but it does in the initial assessment
  const disableNextButtonOnInitialAssessment =
    isInitialAssessment && isFirstStep && isEmpty(answerValues);
  const disableNextButton =
    !isChoiceMade || answerError || disableNextButtonOnInitialAssessment;

  // 'Go to End Screen' button is only visible if changing current answer would not break current answer's chain
  const potentialNextStep = getNextStep(currStepData, answerValues);
  const isBreaksExistingChain = isBreaksExistingAnswerChain(
    currStepId,
    potentialNextStep,
    stepsCollection
  );
  const isEndScreenAvailable =
    last(stepsCollection) === STEP_KEYS.STEP_END &&
    !isBreaksExistingChain &&
    !isInitialAssessment &&
    !isBreakable;

  const isCTAUpload = !!QUESTIONNAIRES_TO_ASSET_TYPES[camelCase(questionnaire)];
  const isCheck24 = questionnaire === QUESTIONNAIRE_KEYS.FUNERAL_PROVISION;
  const isStartStep = currStepData.type === STEP_COMPONENT_TYPE.START;

  // Progress bar should not be rendered in the first or last step of the document generators
  const shouldRenderProgress = !(
    isStartStep || currStepId === STEP_KEYS.STEP_END
  );

  return (
    <>
      {isReminderModalOpen && (
        <ReminderModal
          quitQuestionnaire={handleQuitQuestionnaire}
          continueQuestionnaire={handleContinueQuestionnaire}
          questionnaire={questionnaire}
        />
      )}
      {isStartStep && (
        <>
          <SummaryBox questionnaire={questionnaire} />
          {isCTAUpload && !isCheck24 && (
            <UploadCTACard questionnaire={questionnaire} />
          )}
          {isCheck24 && <Check24CTACard />}
        </>
      )}
      <div
        className="QuestionnaireCard t-overflow-hidden t--ml-5 sm:t-ml-0 t-mt-12"
        ref={scrollTo}
      >
        <Fade key={currStepId} animate={animate}>
          {shouldRenderProgress && <Progress currStepData={currStepData} />}
          <div className="QuestionnaireCard-title">
            {currStepData.content && (
              <p className="Typography Typography--questionTitle">
                {currStepData.content}
              </p>
            )}
          </div>
          <div className="QuestionnaireCard-container xl:t-my-2.5 xl:t-mx-12 t-m-2.5">
            {getAnswerComponentByType()}
          </div>
          <QASection qa={currStepData.qa} />
          {!currStepData.customNavigationButtons && (
            <PrevNextButtons
              questionnaireData={questionnaireData}
              questionnaire={questionnaire}
              disableNextButton={disableNextButton}
              isFirstStep={isFirstStep}
              isEndStep={isEndStep}
              prevStepId={prevStepId}
              handleSubmission={onPrevNextButtonsClick}
              handleGoToPrevQuestion={handleGoToPrevQuestion}
              isEndScreenAvailable={isEndScreenAvailable}
              handleResetAllAnswers={handleResetAllAnswers}
            />
          )}
        </Fade>
        <ExtraInfoFooter
          questionnaire={questionnaire}
          currStepId={currStepId}
        />
        {currStepId && config.isDevLocal && (
          <div>Current Step ID: {currStepId}</div>
        )}
      </div>
    </>
  );
};

export default Questionnaire;
