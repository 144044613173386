import React, { useContext } from 'react';
import { BrowserHistory } from 'history';
import { UNSAFE_NavigationContext } from 'react-router-dom';

function ScrollToTop() {
  const navigation = useContext(UNSAFE_NavigationContext)
    .navigator as BrowserHistory;
  React.useEffect(() => {
    const unlisten = navigation?.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default ScrollToTop;
