import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listAssetTemplatesByCategory } from 'store/actions/assetTemplateActions';
import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';
import { AssetTypeTemplateCard } from 'modules/assetType/components';
import { Divider } from 'modules/estatePlan/components';

interface AssetTypeSelectionModalProps {
  assetCategory: string;
  addTemplate: (assetType: AssetTypeDTO) => void;
  closeModal: () => void;
  assetTypes: AssetTypeDTO[];
  handleAddNewAssetTypeClick: () => void;
}

export default function AssetTypeSelectionModal({
  assetCategory,
  closeModal,
  addTemplate,
  assetTypes = [] as AssetTypeDTO[],
  handleAddNewAssetTypeClick,
}: AssetTypeSelectionModalProps) {
  const { t } = useTranslation([assetCategory, 'plan']);
  const dispatch = useDispatch();

  const assetTemplates =
    useSelector((state: RootState) => state.assetTemplates) ||
    ({} as AssetTypesDTO);
  const categoryAssetTypes: AssetTypeDTO[] = [
    ...(assetTemplates[assetCategory] || ([] as AssetTypeDTO[])),
  ];

  assetTypes.forEach((type) => {
    if (!categoryAssetTypes?.find((t) => t.name === type.name)) {
      categoryAssetTypes.push(type);
    }
  });

  // request the users asset types for the current asset category
  useEffect(() => {
    dispatch(listAssetTemplatesByCategory(assetCategory));
  }, [assetCategory]);

  return (
    <Modal show onHide={closeModal} className="medium" hasCloseCross>
      <h1 className="t-text-xl t-font-secondary t-mb-6 t-font-semibold">
        {t('plan:asset_type_selection_title')}
      </h1>
      <div className="t-grid t-grid-cols-1 sm:t-grid-cols-2 t-gap-6">
        {categoryAssetTypes?.map((template) => {
          return (
            <AssetTypeTemplateCard
              key={template.name}
              template={template}
              addTemplate={addTemplate}
            />
          );
        })}
      </div>

      <Divider
        className="t-my-8 t-uppercase t-tracking-widest"
        bgColor="t-bg-beta-50"
        text={t('common:or')}
      />

      <div className="t-flex t-justify-center">
        <Button
          className="t-py-3"
          onClick={() => {
            handleAddNewAssetTypeClick();
            closeModal();
          }}
        >
          {t('plan:add_new_category')}
        </Button>
      </div>
      <div className="t-flex t-justify-center">
        <p className="t-mt-5 t-text-beta-500 t-text-center">
          {t(`plan:${assetCategory}_new_category_examples`)}
        </p>
      </div>
    </Modal>
  );
}
