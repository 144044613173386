import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from 'store/reducers';
import { populateQuestionnaire } from 'store/actions/questionnaireActions';
import { useTranslation } from 'modules/common/helpers';

import {
  RegistrationZVRStartModal,
  ProfileUpdateModal,
  TrustedPersonRegisterModal,
  PaymentCheckoutModal,
} from 'modules/zvr/components';
import { CommonModal } from 'modules/common/components';

import { ZVR_REGISTRATION_STEPS } from 'modules/zvr/constants';
import {
  QUESTIONNAIRE_KEYS,
  QUESTIONNAIRE_PAGES,
} from 'modules/assistant/constants/questionnaires';

const {
  STEP_REGISTRATION_CANCEL,
  STEP_REGISTRATION,
  STEP_NO_PATIENT_DECREE,
  STEP_NO_HCP,
  STEP_PROFILE_NOT_COMPLETE,
  STEP_TRUSTED_PERSON,
  STEP_PAYMENT_CHECKOUT,
} = ZVR_REGISTRATION_STEPS;

interface RegistrationZVRProcessProps {
  onCancelProcess: () => void;
  downloading: boolean;
  handleDocumentDownload: () => void;
  questionnaire: QUESTIONNAIRE_KEYS;
  isFromLegacyPlan?: boolean;
}

export default function RegistrationZVRProcess({
  downloading,
  handleDocumentDownload,
  onCancelProcess,
  questionnaire,
  isFromLegacyPlan = false,
}: RegistrationZVRProcessProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['zvrRegistration']);
  const navigate = useNavigate();

  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const [step, setStep] = React.useState(STEP_REGISTRATION);
  const [stepBeforeCancel, setStepBeforeCancel] =
    React.useState(STEP_REGISTRATION);

  React.useEffect(() => {
    dispatchQuestionnaireInfo();
  }, []);

  const dispatchQuestionnaireInfo = async () => {
    await dispatch(populateQuestionnaire(QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY));
    await dispatch(populateQuestionnaire(QUESTIONNAIRE_KEYS.PATIENT_DECREE));
  };

  const handleNextStep = (step: number) => {
    setStep(step);
    if (step !== STEP_REGISTRATION_CANCEL) {
      setStepBeforeCancel(step);
    }
  };

  const stepComponents = {
    [STEP_REGISTRATION_CANCEL]: (
      <CommonModal
        title={t('zvrRegistration:registration_cancel_title')}
        primaryButtonText={t('zvrRegistration:registration_cancel_back_to')}
        secondaryButtonText={t('zvrRegistration:cancel')}
        onSuccess={() => {
          handleNextStep(stepBeforeCancel);
        }}
        onClose={() => {
          handleNextStep(ZVR_REGISTRATION_STEPS.STEP_REGISTRATION);
        }}
      />
    ),
    [STEP_REGISTRATION]: (
      <RegistrationZVRStartModal
        onCancelProcess={onCancelProcess}
        handleNextStep={handleNextStep}
        downloading={downloading}
        handleDocumentDownload={handleDocumentDownload}
        questionnaire={questionnaire}
        isFromLegacyPlan={isFromLegacyPlan}
      />
    ),
    [STEP_NO_PATIENT_DECREE]: (
      <CommonModal
        title={t('zvrRegistration:pd_recommended_title')}
        description={t('zvrRegistration:pd_recommended_description')}
        withBackdrop={true}
        primaryButtonText={t('zvrRegistration:create_pd')}
        secondaryButtonText={t('zvrRegistration:skip')}
        onSuccess={() => {
          navigate(
            QUESTIONNAIRE_PAGES[QUESTIONNAIRE_KEYS.PATIENT_DECREE]?.path
          );
          onCancelProcess();
        }}
        onClose={() => {
          if (user.profile === 1) {
            handleNextStep(ZVR_REGISTRATION_STEPS.STEP_TRUSTED_PERSON);
          } else {
            handleNextStep(ZVR_REGISTRATION_STEPS.STEP_PROFILE_NOT_COMPLETE);
          }
        }}
      />
    ),
    // this is for double checking if the HCP really exists or completed
    [STEP_NO_HCP]: (
      <CommonModal
        title={t('zvrRegistration:hcp_document_recommended')}
        description={t('zvrRegistration:hcp_document_recommended_description')}
        withBackdrop={true}
        primaryButtonText={t('zvrRegistration:create_hcp')}
        secondaryButtonText={t('zvrRegistration:skip')}
        onSuccess={() => {
          navigate(
            QUESTIONNAIRE_PAGES[QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY]?.path
          );
        }}
        onClose={() => {
          if (user.profile === 1) {
            handleNextStep(ZVR_REGISTRATION_STEPS.STEP_PAYMENT_CHECKOUT);
          } else {
            handleNextStep(ZVR_REGISTRATION_STEPS.STEP_PROFILE_NOT_COMPLETE);
          }
        }}
      />
    ),
    [STEP_PROFILE_NOT_COMPLETE]: (
      <ProfileUpdateModal handleNextStep={handleNextStep} />
    ),
    [STEP_TRUSTED_PERSON]: (
      <TrustedPersonRegisterModal handleNextStep={handleNextStep} />
    ),
    [STEP_PAYMENT_CHECKOUT]: (
      <PaymentCheckoutModal handleNextStep={handleNextStep} />
    ),
  };

  return stepComponents[step];
}
