import React from 'react';
import { format } from 'date-fns';
import { enGB, de } from 'date-fns/locale';
import ReactTooltip from 'react-tooltip';

import { useTranslation } from 'modules/common/helpers';

import { LANGUAGES, LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';
import { ReactComponent as IconBell } from 'images/bell.svg';

export interface ReminderButtonProps {
  handleClick: () => void;
  reminder?: string;
  isReminderOpen?: boolean;
}

export default function ReminderButton({
  handleClick,
  isReminderOpen,
  reminder = '',
}: ReminderButtonProps) {
  const { t } = useTranslation(['assistant']);

  React.useEffect(() => {
    ReactTooltip.rebuild();
    if (isReminderOpen) {
      ReactTooltip.hide();
    }
  }, [isReminderOpen]);

  const userLang = localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE);
  const lang = userLang === LANGUAGES.DE ? de : enGB;
  const desiredFormat = userLang === LANGUAGES.DE ? 'do MMM.' : 'MMM. do';
  const localDate = format(
    reminder ? new Date(reminder?.replace(/-/g, '/')) : new Date(),
    desiredFormat,
    {
      locale: lang,
    }
  );

  return (
    <button
      onClick={handleClick}
      className="button t-text-sm t-tracking-widest t-pl-0 t-text-beta-400"
      data-tip={
        !reminder
          ? t('assistant:reminder_tooltip')
          : t('assistant:reminder_already_set_reminder')
      }
    >
      <IconBell className={`${reminder ? 't-mr-2' : ''}`} />
      {reminder ? localDate : ''}
    </button>
  );
}
