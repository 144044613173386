import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const noStyle = css`
  text-decoration: none;
  color: inherit;
`;

const StyledLink = styled((props) => <Link {...props} />)`
  ${(props) => props.noStyle && noStyle};
`;

export default StyledLink;
