import { LOAD_CONTACT_OF_LIST } from 'store/types/contactOfActionTypes';
import helper from 'store/reducers/reducerHelper';
import initialState from 'store/reducers/initialState';

const contactOfReducer = (state = initialState.contactOf, action) => {
  return helper.handleActions(state, action, {
    [LOAD_CONTACT_OF_LIST](state, payload) {
      state.list = payload.contactOf as ContactDTO[];
    },
  });
};

export default contactOfReducer;
