import React from 'react';
import { useDispatch } from 'react-redux';
import { camelCase } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { getNextSteps, skipStep } from 'store/actions/nextStepActions';
import { setModal } from 'store/actions/modalActions';
import { getCardLink } from 'modules/assistant/helpers/questionnaireHelper';
import { useTranslation } from 'modules/common/helpers';

import { Button, InfoBadge, Modal } from 'modules/common/components';
import { ProfileForm } from 'modules/profile/components';
import {
  AddContactsProcess,
  EmailVerification,
} from 'modules/dashboard/components';
import { TwoFactorAuthProcess } from 'modules/twoAuth/components';
import { SecuritySettingProcess } from 'modules/securityKeyWizard/components';

import {
  NEXT_STEPS,
  NEXT_STEPS_CODES,
} from 'modules/dashboard/constants/nextSteps';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';
import { MODALS } from 'modules/common/constants';

interface NextStepProps {
  nextStep: NextStep;
  isFromAssistant: boolean;
  user: UserProfileDTO;
}

export default function NextStep({
  nextStep,
  isFromAssistant,
  user,
}: NextStepProps) {
  const { t } = useTranslation(['dashboard', 'recommendations']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [typeOfCalledAction, setTypeOfCalledAction] = React.useState('');

  const nextStepData = NEXT_STEPS[nextStep.recommendation];
  const isCompleteProfileStep =
    nextStep.recommendation === NEXT_STEPS_CODES.COMPLETE_PROFILE;

  const title = isFromAssistant
    ? t(`recommendations:${nextStep.recommendation}`)
    : isCompleteProfileStep && !user.verified
    ? t('dashboard:next_steps_widget_unverified_email_title')
    : t(nextStepData.title);

  const description = isFromAssistant
    ? t(`recommendations:${nextStep.recommendation}_descr_short`)
    : isCompleteProfileStep && !user.verified
    ? t('dashboard:next_steps_widget_unverified_email_description')
    : t(nextStepData.description);

  const handleCTAClick = (type, code) => {
    if (type === 'primary') {
      if (isFromAssistant) {
        handleRedirectToContentQuestionnaire(
          camelCase(nextStep.recommendation)
        );
      } else if (code === NEXT_STEPS_CODES.CONTACT_NOW) {
        navigate('/my-contacts');
      } else if (code === NEXT_STEPS_CODES.ADD_BACKUP_CONTACT) {
        navigate('/profile', { state: { elementId: 'BackupContactSection' } });
      } else {
        setTypeOfCalledAction(code);
      }
    } else {
      if (code === NEXT_STEPS_CODES.CONTACT_NOW) {
        dispatch(
          setModal({
            name: MODALS.COMMON_MODAL,
            props: {
              title: t(
                'dashboard:next_step_widget_contact_now_info_modal_title'
              ),
              description: t(
                'dashboard:next_step_widget_contact_now_info_modal_description'
              ),
              primaryButtonText: t('dashboard:next_steps_widget_discard'),
              secondaryButtonText: t('mcontacts:contact_now'),
              successAsyncAction: async () => {
                await dispatch(skipStep(code));
              },
              cancelAction: () => {
                navigate('/my-contacts');
              },
            },
          })
        );
        return;
      }
      dispatch(skipStep(code));
    }
  };

  const handleActionCancel = () => {
    setTypeOfCalledAction('');
  };

  const handleActionSuccess = async () => {
    if (typeOfCalledAction === NEXT_STEPS_CODES.ADD_SECURITY_KEY) {
      await dispatch(skipStep(nextStep.recommendation));
    }
    setTypeOfCalledAction('');
    dispatch(getNextSteps());
  };

  const handleRedirectToContentQuestionnaire = (qKey: string) => {
    // link to content questionnaire of the doc generator
    // handle redirect to content questionnaires of HCP, PD, ODC, and Will
    const {
      HEALTH_CARE_PROXY,
      PATIENT_DECREE,
      ORGAN_DONOR_CARD,
      WILL_GENERATOR,
    } = QUESTIONNAIRE_KEYS;
    const exceptionKeys: string[] = [
      HEALTH_CARE_PROXY,
      PATIENT_DECREE,
      ORGAN_DONOR_CARD,
      WILL_GENERATOR,
    ];
    if (exceptionKeys.includes(qKey)) {
      navigate(getCardLink(`${qKey}Content`), { replace: true });
    } else {
      navigate(getCardLink(qKey), { replace: true });
    }
  };

  const handleSecuritySettingCancel = () => {
    setTypeOfCalledAction('');
  };

  return (
    <React.Fragment>
      {typeOfCalledAction === NEXT_STEPS_CODES.COMPLETE_PROFILE && (
        <Modal show onHide={handleActionCancel}>
          {!user.verified ? (
            <EmailVerification
              onClose={handleActionCancel}
              title={t('dashboard:next_steps_widget_email_verify_modal_title')}
              description={t(
                'dashboard:next_steps_widget_email_verify_modal_description'
              )}
            />
          ) : (
            <>
              <h1 className="typo-alpha t-pb-5">{t('profile:info_title')}</h1>
              <ProfileForm
                isProfilePage={false}
                onClose={handleActionCancel}
                onSuccess={handleActionSuccess}
              />
            </>
          )}
        </Modal>
      )}

      {typeOfCalledAction === NEXT_STEPS_CODES.ADD_CONTACT && (
        <AddContactsProcess
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {typeOfCalledAction === NEXT_STEPS_CODES.ADD_TWO_FACTOR_AUTH && (
        <TwoFactorAuthProcess handleShow2FAProcess={handleActionSuccess} />
      )}

      {typeOfCalledAction === NEXT_STEPS_CODES.ADD_SECURITY_KEY && (
        <SecuritySettingProcess
          onClose={handleActionSuccess}
          onCancel={handleSecuritySettingCancel}
          hasCloseCross
        />
      )}

      <div className="card t-shadow-lg t-bg-gamma-400 t-min-h-44 t-flex t-px-8 t-pb-4 t-pt-6 t-my-4 md:t-mx-3 t-w-full t-flex-col">
        <div className="t-flex t-mb-3.5 t-flex-col xl:t-items-start xl:t-flex-row">
          <div className="t-flex t-mb-1 xl:t-mb-0">
            <InfoBadge priority={nextStep.priority} />
          </div>

          <h1 className="typo-beta xl:t-ml-2">{title}</h1>
        </div>
        <div className="t-flex-grow t-flex t-flex-col t-justify-between">
          <p className="typo-zeta t-flex-grow">{description}</p>
          <div className="t-flex t-justify-end t-mt-2">
            {nextStepData?.secondaryCTA && (
              <Button
                category="secondary"
                className="t-mr-2"
                onClick={() =>
                  handleCTAClick('secondary', nextStep.recommendation)
                }
              >
                {isFromAssistant
                  ? t('dashboard:next_steps_widget_discard')
                  : t(nextStepData.secondaryCTA)}
              </Button>
            )}
            {(nextStepData?.primaryCTA || isFromAssistant) && (
              <Button
                onClick={() =>
                  handleCTAClick('primary', nextStep.recommendation)
                }
              >
                {isFromAssistant
                  ? t('dashboard:next_steps_widget_start')
                  : t(nextStepData?.primaryCTA || '')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
