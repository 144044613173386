import React, { useEffect } from 'react';
import { find } from 'lodash';

import { useGetCountries, useTranslation } from 'modules/common/helpers';

import { FormControl, FormGetValues, FormSetValue } from 'modules/common/types';
import { SelectController } from 'modules/common/components';

interface CountryInputProps {
  control: FormControl;
  refSetValue: FormSetValue;
  refGetValues: FormGetValues;
  error: string;
  defaultValue?: string;
  isRequired?: boolean;
  isDefaultLabelVisible?: boolean;
  name?: string;
}

export default function CountryInput(props: CountryInputProps) {
  const {
    control,
    refGetValues,
    refSetValue,
    error,
    isRequired,
    isDefaultLabelVisible = true,
    defaultValue = '',
    name = '',
  } = props;
  const { t } = useTranslation(['auth']);
  const countries = useGetCountries();

  useEffect(() => {
    getInitialCountryOption();
  }, [countries, refGetValues, refSetValue]);

  const getInitialCountryOption = async () => {
    const initialOption =
      (find(
        countries,
        (item) => item.value === defaultValue
      ) as SelectOption) || '';
    if (initialOption) {
      await setTimeout(() => refSetValue(name || 'country', initialOption));
    }
  };

  return (
    <div className="Form-group" data-testid="CountryInput">
      {isDefaultLabelVisible && (
        <label
          className={`Form-label ${error ? 'isErrored' : ''}`}
          htmlFor={name || 'country'}
        >
          {t('auth:country_label')}
        </label>
      )}

      <SelectController
        name={name || 'country'}
        options={countries}
        control={control}
        className={`Select ${error ? 'isErrored' : ''}`}
        rules={{ required: isRequired }}
      />
      {error && (
        <div className="Form-alert">
          <span className="text-main-sm t-text-epsilon-600">
            {t('auth:country_label')} {t('common:required')}
          </span>
        </div>
      )}
    </div>
  );
}
