import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { camelCase } from 'lodash';

import { RootState } from 'store/reducers';
import { dataProcessingHelper } from 'modules/assistant/helpers';
import { useTranslation } from 'modules/common/helpers';

import { RECO_TYPE } from 'modules/assistant/constants/questionnaires';
import { QUESTIONNAIRE_PAGES } from 'modules/assistant/constants/questionnaires';
import { CONTENT_QUESTIONNAIRE_PAGES } from 'modules/assistant/constants/contentQuestionnaires';

import { ReactComponent as IconRadioChecked } from 'images/radio-checked.svg';
import { ReactComponent as IconRadioUnchecked } from 'images/radio-unchecked.svg';
import { ReactComponent as IconChevron } from 'images/chevrone-reco.svg';

import 'react-circular-progressbar/dist/styles.css';

const { getFilteredRecosArray, isRecoCompleted } = dataProcessingHelper;

const ASSISTANT_PAGES = {
  ...QUESTIONNAIRE_PAGES,
  ...CONTENT_QUESTIONNAIRE_PAGES,
};

export interface RecoTotalCardProps {
  recos: RecommendationDTO[];
  type: RECO_TYPE;
}

const RecoTotalCard = ({ recos, type }: RecoTotalCardProps) => {
  const { t } = useTranslation(['recommendations']);

  const [expandedDetails, setExpandedDetails] = useState(false);
  const planProgress: PlanProgressDTO = useSelector(
    (state: RootState) => state.plan.progress
  );

  const cardTitle =
    type === RECO_TYPE.ESTATE
      ? t('totals_estate_header')
      : t('totals_prevention_header');
  const cardTitleDescr =
    type === RECO_TYPE.ESTATE
      ? t('totals_estate_description')
      : t('totals_prevention_description');
  const totalItems = recos.length;
  const completedItems = getFilteredRecosArray(
    recos,
    true,
    planProgress
  ).length;

  const circle = {
    value: (completedItems / totalItems) * 100,
    text: (
      <tspan dy={2}>
        {completedItems} / {totalItems}
      </tspan>
    ) as unknown as string,
    textColor: 'var(--color-beta-700)',
    trailColor:
      type === RECO_TYPE.ESTATE
        ? 'var(--color-delta-200)'
        : 'var(--color-alpha-150)',
    pathColor:
      type === RECO_TYPE.ESTATE
        ? 'var(--color-delta-900)'
        : 'var(--color-alpha-700)',
  };

  const toggleDetails = () => {
    setExpandedDetails((prevState) => !prevState);
  };

  return (
    <>
      <div className="card t-mt-5 t-shadow-lg">
        <div className="t-min-h-44 t-flex t-pt-4">
          <div className="t-flex t-min-w-24 t-w-24 t-ml-4 t-my-2 t-self-start">
            <CircularProgressbar
              value={circle.value}
              text={circle.text}
              strokeWidth={13}
              styles={buildStyles({
                textColor: circle.textColor,
                trailColor: circle.trailColor,
                pathColor: circle.pathColor,
              })}
            />
          </div>
          <div className="t-ml-6 t-p-2.5">
            <div className="t-flex t-mb-2 t-flex-wrap">
              <h2 className="t-font-secondary t-text-lg t-font-semibold xs:t-w-full sm:t-w-auto xs:t-mt-2 sm:t-mt-0">
                {cardTitle}
              </h2>
            </div>
            <p>{cardTitleDescr}</p>

            <div className="t-mt-4">
              <Accordion className="Accordion" onSelect={toggleDetails}>
                <Accordion.Item eventKey="recoDescription">
                  <Accordion.Body>
                    <div>
                      {recos.map((reco) => {
                        const recoTitle = t(`${reco.name}`);
                        const recoPath =
                          ASSISTANT_PAGES[camelCase(reco?.name)]?.path;
                        return (
                          <div className="t-mb-3" key={reco.name}>
                            <Link to={recoPath} className="hover:t-underline">
                              <div className="d-flex">
                                {!!reco.relatedAssetId ||
                                isRecoCompleted(reco, planProgress) ? (
                                  <IconRadioChecked
                                    className="t-mr-3 t-text-alpha-700"
                                    role="presentation"
                                  />
                                ) : (
                                  <IconRadioUnchecked
                                    className="t-mr-3"
                                    role="presentation"
                                  />
                                )}
                                {recoTitle}
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </Accordion.Body>
                  <Accordion.Button
                    as="div"
                    className="PricingAccordion-title d-flex align-items-center t-py-2"
                  >
                    <span className="typo-iota t-mr-2.5 t-text-beta-700">
                      {expandedDetails ? t('hide_details') : t('show_details')}
                    </span>
                    <IconChevron
                      role="presentation"
                      className={`t-text-beta-700 ${
                        expandedDetails ? '' : 'PricingAccordion--chevronDown'
                      }`}
                    />
                  </Accordion.Button>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoTotalCard;
