export enum LOCAL_STORAGE_VARS {
  SITE_LANGUAGE = 'lang',
  STUBS_FLAG = 'isStubs',
  IS_ALLOWED_TOKEN = 'isAllowedToken',
  NEVER_REMIND_QUESTIONNAIRE = 'neverRemindQuestionnaire',
  SNOOZE_REMIND_QUESTIONNAIRE = 'snoozeRemindQuestionnaire',
  DATA_ON_REDIRECT = 'dataOnRedirect',
}

export enum LANGUAGES {
  EN = 'en',
  DE = 'de',
}

export enum COUNTRIES {
  DE = 'DE',
}

export enum URL_SEARCH_CODES {
  TOKEN_INVALID = '1000',
  EMAIL_VERIFIED = '2000',
  LOGIN_REQUIRED = '3000',
  PAYMENT_SUCCESSFUL = '5000',
  POSTAL_PAYMENT_SUCCESSFUL = '5001',
  PAYMENT_CANCELED = '6000',
  POSTAL_PAYMENT_CANCELED = '6001',
}

export enum CONSTANT_TYPES {
  BANK = 'bank',
  BANK_ACCOUNT_TYPE = 'bank account type',
  ONLINE_WALLET = 'online wallet',
  COUNTRY = 'country',
  DOCTOR = 'doctor',
  ATTORNEY = 'attorney',
  INSURER = 'insurer',
  INSURANCE_TYPE = 'insurance type',
  PASSWORD = 'password',
  SOCIAL_MEDIA = 'social media',
  CLOUD_STORAGE = 'cloud storage',
  FUNERAL_TYPE = 'funeral type',
  ELECTRONIC_DEVICES = 'electronic devices',
}

export enum DASHBOARD_TABLE_COLUMNS {
  DESCRIPTION = 'description',
  FILE = 'file',
  ASSIGNED_TO = 'assigned to',
  PROVIDER = 'provider',
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  LOGIN = 'login',
  BANK = 'bank',
  ACCOUNT = 'account',
  LAST_MODIFIED = 'last modified',
  SUBTYPE = 'subtype',
  AVAILABLE_NOW = 'available now',
}

export enum DATE_FORMATS {
  UI = 'dd.MM.yyyy',
  SERVER = 'yyyy-MM-dd',
  UI_DASHBOARD = 'dd MMM yyyy',
  DATE_PICKER = 'd/m/Y',
}

export enum TWO_AUTH_TYPES {
  SMS = 'sms',
  GOOGLE = 'google',
}

export enum DELETE_PROFILE_TYPES {
  HARD = 'hard',
  SOFT = 'soft',
}

export enum USER_PLAN {
  PROFESSIONAL = 'Professional',
  FREE = 'Free',
}

export enum PAYMENT_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  TRIAL = 'trial',
  CANCELED = 'canceled',
}

export enum ROUTER_STATE_STATUS {
  PAYMENT_REQUIRED = 'paymentRequired',
}

export enum RELATIONSHIP {
  WIFE = 'wife',
  LIFE_PARTNER = 'life partner',
  FATHER = 'father',
  MOTHER = 'mother',
  BROTHERS = 'brothers',
  SISTER = 'sister',
  SON = 'son',
  DAUGHTER = 'daughter',
  GRANDSON = 'grandson',
  GRANDDAUGHTER = 'granddaughter',
  UNCLE = 'uncle',
  AUNT = 'aunt',
  FRIEND = 'friend',
  DOCTOR = 'doctor',
  FINANCIAL_ADVISOR = 'financial advisor',
  INSURANCE_BROKER = 'insurance broker',
  OTHER = 'other',
}

export enum PAYMENT_METHOD {
  PURCHASE_ON_ACCOUNT = 'purchase on account',
  CASH_ON_DELIVERY = 'cash on delivery',
  PAYMENT_BY_DIRECT_DEBIT = 'payment by direct debit',
  CREDIT_CARD = 'credit card',
  PAYPAL = 'paypal',
  AMAZON_PAYMENTS = 'amazon payments',
  KLARNA = 'klarna',
  OTHER = 'other',
}

export enum PAYMENT_FREQUENCY {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
}

export enum INHERITANCE_TYPE {
  INHERITANCE = 'inheritance',
  GIFT = 'gift',
}

export enum INHERITANCE_RELATIONSHIP {
  SPOUSE = 'spouse',
  REGISTERED_PARTNER = 'registered partner',
  PARENT = 'parent',
  SIBLING = 'sibling',
  CHILD = 'child',
  STEP_CHILD = 'step child',
  GRANDCHILD = 'grandchild',
  GRANDCHILD_DISEASED_CHILD = 'grandchild - diseased child',
  GREAT_GRANDCHILD = 'great grandchild',
  GRANDPARENT = 'grandparent',
  NIECE_OR_NEPHEW = 'niece or nephew',
  STEPPARENT = 'stepparent',
  SON_IN_LAW = 'son-in-law or daughter-in-law',
  PARENT_IN_LAW = 'parent-in-law',
  DIVORCED_SPOUSE = 'divorced spouse',
  TERMINATED_PARTNER = 'terminated registered partnership',
  BROTHER_IN_LAW = 'brother-in-law or sister-in-law',
  FIANCE = 'fiance',
  OTHER = 'other',
}

export enum CARD_FIELD_INPUTS {
  NAME_ON_CARD = 'nameOnCard',
  CARD_NUMBER = 'cardNumber',
  CARD_NUMBER_SHORT = 'cardNumberShort',
  CARD_EXPIRATION_DATE = 'cardExpirationDate',
  CODE = 'code',
}

export enum PERSON_FIELD_INPUTS {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PERSON_COMPANY = 'personCompany',
  PERSON_EMAIL = 'personEmail',
  PERSON_PHONE = 'personPhone',
  PERSON_STREET = 'personStreet',
  PERSON_CITY = 'personCity',
  PERSON_ZIP = 'personZip',
  PERSON_COUNTRY = 'personCountry',
}

export enum GENDERS {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export enum FINANCIAL_DATA_FIELD_INPUTS {
  FINANCIAL_VALUE = 'financialValue',
  ESTIMATED_DATE = 'estimatedDate',
  ESTIMATED = 'estimated',
}

// Timeout in milliseconds
export enum TIMERS {
  UNDO = 8000,
}

export enum URL_PARAMS {
  STATUS = 'status',
  TOKEN = 'token',
  REFERRAL = 'referral',
  TUTORIAL = 'tutorial',
  PAYMENT_TOKEN = 'ref',
  LETTER_ID = 'letterId',
  CONTACT_ID = 'contactId',
}

export enum POSTAL_TYPES {
  CONTACT = 'contact',
  UPLOAD = 'upload',
}

export enum POSTAL_STEPS {
  STEP_OPTION = 'stepOption',
  STEP_POSTAL_INFO = 'stepPostalInfo',
  STEP_SUCCESS = 'stepSuccess',
}

export enum EMAIL_ERROR_TYPES {
  REQUIRED = 'required',
  YOURSELF_EMAIL = 'yourselfEmail',
  EXISTING_EMAIL = 'existingEmail',
}
