import React from 'react';
import { IbanElement } from '@stripe/react-stripe-js';

import { useTranslation } from 'modules/common/helpers';

import { COUNTRIES } from 'modules/common/constants/enums';

import 'modules/payment/components/StripeIban/StripeIban.scss';

export const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  placeholderCountry: COUNTRIES.DE,
};

export default function StripeIban() {
  const { t } = useTranslation(['payment']);

  return (
    <label htmlFor="iban" data-testid="StripeIban">
      <span className="t-text-beta-500 t-mb-2">
        {t('payment:step_sepa_iban')}
      </span>
      <IbanElement options={IBAN_ELEMENT_OPTIONS} id="iban" />
    </label>
  );
}
