import React from 'react';
import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

import imgFamilyMember from 'images/img-family-account.png';

interface FamilyAccountAddedModalProps {
  onClose: () => void;
}

const FamilyAccountAddedModal = ({ onClose }: FamilyAccountAddedModalProps) => {
  const { t } = useTranslation(['common', 'familyAccounts']);

  return (
    <Modal show onHide={onClose}>
      <div className="t-flex t-justify-center">
        <img
          src={imgFamilyMember}
          className="t-max-h-36"
          alt="Family accounts added successfully"
        />
      </div>

      <h1 className="typo-alpha t-mt-3">
        {t('familyAccounts:successful_family_members_added_modal_title')}
      </h1>
      <p className="typo-epsilon t-mt-4 t-mb-8">
        {t('familyAccounts:successful_family_members_added_modal_desc')}
      </p>

      <div className="t-mt-4 t-flex t-justify-end">
        <Button
          category="secondary"
          className="t-mr-0 t-py-2 t-mb-3 sm:t-mb-0 sm:t-mr-3"
          onClick={onClose}
        >
          {t('common:close')}
        </Button>
      </div>
    </Modal>
  );
};

export default FamilyAccountAddedModal;
