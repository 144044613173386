import _ from 'lodash';
import PhoneNumberValidator from 'awesome-phonenumber';
import isNumeric from 'validator/lib/isNumeric';

export default {
  isValidPhone,
  isEmptyErrorObject,
  isAllKeysTruthy,
  hasLowerCase,
  hasUpperCase,
  hasNumber,
  hasMinLength,
  hasSpecial,
  isValidNumberNonInteger,
  isValidExpirationDate,
  validateCode,
};

// this method is used for checking a number that is not an integer in the format of xx.xx or xx,xx
function isValidNumberNonInteger(number) {
  return /^\d*(\.|,)?\d*$/.test(number);
}

function isValidPhone(phoneNumber: string) {
  const pn = new PhoneNumberValidator(phoneNumber);
  return pn.isValid();
}

function isEmptyErrorObject(obj): boolean {
  for (const value of Object.values(obj)) {
    if (!_.isEmpty(value)) {
      return false;
    }
  }

  return true;
}

function isAllKeysTruthy(obj): boolean {
  return Object.keys(obj).every((k) => obj[k]);
}

function hasLowerCase(str: string): boolean {
  return /[a-z]/.test(str);
}

function hasUpperCase(str: string): boolean {
  return /[A-Z]/.test(str);
}

function hasNumber(str: string): boolean {
  return /\d/.test(str);
}

function hasMinLength(str: string, length: number): boolean {
  return str.length >= length;
}

function hasSpecial(str: string): boolean {
  return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(str);
}

// this validation is used for checking the expiration date of credit card only (in the format of MM/YY)
// we dont validate the year because this is not for payment
function isValidExpirationDate(date: string): boolean {
  const [month, year] = date.replace(/\s|_/g, '').split('/');
  if (!month || !year || !isNumeric(`${month}${year}`)) return false;
  if (Number(month) > 12 || Number(month) < 1 || Number(year) < 10)
    return false;
  return true;
}

function validateCode(length: number, code?: number | string) {
  const strValue = `${code || ''}`;
  return isNumeric(strValue) && strValue.length === length;
}
