import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { ProfileForm } from 'modules/profile/components';
import { EmailVerification } from 'modules/dashboard/components';
import { Button } from 'modules/common/components';

const STEP_INFO = 'stepInfo';
const STEP_VERIFY = 'stepVerify';
const STEP_PROFILE_FORM = 'stepProfileForm';

interface ProfileIncompleteProcessProps {
  onClose: () => void;
  onSuccess: () => void;
  profile: UserProfileDTO;
  isProfilePage?: boolean;
  profileFormTitle?: string;
}

const ProfileIncompleteProcess = ({
  onClose,
  onSuccess,
  profile,
  isProfilePage,
  profileFormTitle,
}: ProfileIncompleteProcessProps) => {
  const { t } = useTranslation();

  const [step, setStep] = React.useState(
    !profile.verified ? STEP_VERIFY : STEP_INFO
  );

  const toProfileForm = () => {
    setStep(STEP_PROFILE_FORM);
  };

  return (
    <>
      {step === STEP_INFO && (
        <div>
          <h1 className="typo-alpha t-pb-3">
            {t(`mcontacts:profile_not_complete_info_title`)}
          </h1>
          <p className="typo-epsilon t-text-beta-500">
            {t(`mcontacts:profile_not_complete_info_description`)}
          </p>

          <div className="t-flex xs:t-flex-wrap sm:t-flex-nowrap t-justify-end lg:t-pt-6">
            <Button
              category="secondary"
              className="t-mr-2.5 xs:t-mt-5"
              onClick={onClose}
            >
              {t('common:cancel')}
            </Button>
            <Button className="xs:t-mt-5" onClick={toProfileForm}>
              {t('mcontacts:complete_profile')}
            </Button>
          </div>
        </div>
      )}

      {step === STEP_VERIFY && <EmailVerification onClose={onClose} />}

      {step === STEP_PROFILE_FORM && (
        <div>
          {profileFormTitle && (
            <h1 className="typo-alpha t-pb-5">{profileFormTitle}</h1>
          )}
          <div className="t-flex">
            <ProfileForm
              isProfilePage={!!isProfilePage}
              onClose={onClose}
              onSuccess={onSuccess}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileIncompleteProcess;
