import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find, isEmpty } from 'lodash';

import { RootState } from 'store/reducers';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';

import { getFullName, useTranslation } from 'modules/common/helpers';
import {
  getAliveContactsData,
  getSelectOptions,
} from 'modules/contact/helpers';

import { Button, Modal } from 'modules/common/components';
import { ContactCard } from 'modules/myContacts/components';
import { ContactForm } from 'modules/contact/components';

import { SelectOrAddContactCard } from 'modules/assistant/components/Answers';

import { ReactComponent as IconPlus } from 'images/icon-plus.svg';

export interface MultiContactsProps {
  previousAnswer?: string;
}

const MultiContacts = ({ previousAnswer }: MultiContactsProps) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();

  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<any>([]);
  const [showSelectOrAddContactCard, setShowSelectOrAddContactCard] =
    useState(true);
  const [contactToEdit, setContactToEdit] = useState({} as ContactDTO);

  const contactsData: ContactDTO[] =
    useSelector((state: RootState) => state.contacts.list) || [];
  const familyAccounts = useSelector(
    (state: RootState) => state.familyAccounts.list
  );
  const availableContacts = getAliveContactsData(contactsData, familyAccounts);

  const contactOptions = getSelectOptions(availableContacts).filter(
    (option) => {
      return !selectedContacts.find(
        (selectedContact) => selectedContact.value === option.value
      );
    }
  );

  // show previous input val, if entered before
  useEffect(() => {
    if (previousAnswer) {
      const stillExistingContacts = contactsData
        ?.filter((contact) => previousAnswer?.includes(contact?.id))
        ?.map((existingBene) => existingBene?.id);
      if (stillExistingContacts.length > 0) {
        dispatch(temporaryFieldSaver(stillExistingContacts));
        populateDefaultSelections(stillExistingContacts);
        setShowSelectOrAddContactCard(false);
      }
    }
  }, [previousAnswer, contactsData]);

  const populateDefaultSelections = (filteredPrevAnswer) => {
    const defaultSelectedContacts = contactsData.filter((c) =>
      filteredPrevAnswer?.includes(c.id)
    );
    const formattedDefaultSelectedContacts = defaultSelectedContacts.map(
      (c) => {
        return { value: c.id, label: getFullName(c, false) };
      }
    );
    setSelectedContacts(formattedDefaultSelectedContacts);
  };

  const handleCreateNewContact = (newContact: ContactDTO) => {
    if (newContact?.id) {
      const newSelectedContactsState = [
        ...selectedContacts,
        {
          value: newContact.id,
          label: getFullName(newContact, false),
        },
      ];
      updateTempField(newSelectedContactsState);
      setSelectedContacts(newSelectedContactsState);
      setShowSelectOrAddContactCard(false);
      handleCloseEditForm();
    }
  };

  const handleSelection = (selectedOption) => {
    setShowSelectOrAddContactCard(false);
    const newSelectedContactsState = [...selectedContacts, selectedOption];
    updateTempField(newSelectedContactsState);
    setSelectedContacts(newSelectedContactsState);
  };

  const handleRemove = (contactId) => {
    const newSelectedContactsState = [...selectedContacts].filter(
      (c) => c.value !== contactId
    );
    updateTempField(newSelectedContactsState);
    setSelectedContacts(newSelectedContactsState);
    if (isEmpty(newSelectedContactsState)) setShowSelectOrAddContactCard(true);
  };

  const updateTempField = (dataToFormat) => {
    const formattedData = dataToFormat.map((item) => item.value);
    dispatch(temporaryFieldSaver(formattedData));
  };

  const handleCloseEditForm = () => {
    setShowEditForm(false);
    setContactToEdit({} as ContactDTO);
  };

  return (
    <>
      {!isEmpty(selectedContacts) && (
        <div className="t-relative">
          {selectedContacts.map((selectedContact) => {
            const contactData: ContactDTO =
              find(contactsData, (item) => item.id === selectedContact.value) ||
              ({} as ContactDTO);
            return (
              <React.Fragment key={selectedContact.value}>
                <ContactCard
                  contactData={contactData}
                  onDelete={() => handleRemove(selectedContact.value)}
                  className="card xl:t-py-6 xl:t-px-7 xs:t-py-3 xs:t-px-3.5 t-min-w-80 xl:t-min-w-sm"
                />
              </React.Fragment>
            );
          })}
          {!showSelectOrAddContactCard && (
            <Button
              category="secondary"
              className="t-float-right"
              onClick={() => setShowSelectOrAddContactCard(true)}
            >
              <div className="t-flex t-justify-around">
                <IconPlus className="t-text-alpha-600 t-mr-1.5" />
                <p>{t('common:add')}</p>
              </div>
            </Button>
          )}
        </div>
      )}

      {showSelectOrAddContactCard && (
        <SelectOrAddContactCard
          onChange={handleSelection}
          contactOptions={contactOptions}
          onAddNew={() => setShowEditForm(true)}
        />
      )}

      {showEditForm && (
        <Modal show onHide={handleCloseEditForm} hasCloseCross>
          <ContactForm
            onClose={handleCloseEditForm}
            onSuccess={handleCreateNewContact}
            contactItem={contactToEdit || undefined}
            limited
          />
        </Modal>
      )}
    </>
  );
};

export default MultiContacts;
