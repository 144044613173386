import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import i18n from 'localization/i18n';

import { config, dateHelper, useTranslation } from 'modules/common/helpers';
import { FormControl } from 'modules/common/types';

import { Form } from 'modules/bootstrap/components';

export interface DateInputProps {
  error: string;
  label: string;
  name: string;
  control: FormControl;
  defaultValue: string;
  required?: boolean;
  extraValidation?: (value: string) => boolean | string;
}

export default function DateInput({
  error,
  label,
  name,
  control,
  defaultValue,
  required,
  extraValidation,
}: DateInputProps) {
  const { t } = useTranslation(['common']);

  return (
    <div className="t-mb-3.5" data-testid="DateInput">
      <Form.Label
        className={`t-inline-block t-mb-2 t-text-beta-500 ${
          error ? 't-text-epsilon-600' : ''
        }`}
        htmlFor={name}
      >
        {label}
      </Form.Label>
      <Controller
        name={name}
        render={({ field }) => (
          <InputMask
            placeholder={t('common:date_placeholder')}
            mask="99.99.9999"
            className={`Field ${error ? 'isErrored' : ''}`}
            id={name}
            {...field}
          />
        )}
        control={control}
        defaultValue={dateHelper.convertDateFormat(
          defaultValue || '',
          config.format.uiDate
        )}
        rules={{
          required: required,
          validate: (value) => {
            if (!value && !required) return true;
            if (!dateHelper.isValidDate(value))
              return `${i18n.t('common:invalid_date')}`;

            if (extraValidation) {
              return extraValidation(value);
            }
            return true;
          },
        }}
      />
      {error && (
        <div className="t-pt-0.5">
          <span className="text-main-sm t-text-epsilon-600">{error}</span>
        </div>
      )}
    </div>
  );
}
