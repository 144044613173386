import {
  DATE_FORMATS,
  LOCAL_STORAGE_VARS,
} from 'modules/common/constants/enums';

const isDevLocal = process.env.REACT_APP_NODE_ENV === 'local';
const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

export default {
  isDevLocal,
  isProduction,
  isStubsData: localStorage.getItem(LOCAL_STORAGE_VARS.STUBS_FLAG) === '1',
  beUrl: process.env.REACT_APP_BE_URL,
  documentGeneratorUrl: process.env.REACT_APP_DOCUMENT_GENERATOR_URL,
  remoteHost: process.env.REACT_APP_HOST,
  websiteUrl: process.env.REACT_APP_WEBSITE_URL,
  format: {
    uiDate: DATE_FORMATS.UI,
    uiDashboardDate: DATE_FORMATS.UI_DASHBOARD,
    serverDate: DATE_FORMATS.SERVER,
    datePicker: DATE_FORMATS.DATE_PICKER,
  },
  maxPhotoSize: Number(process.env.REACT_APP_MAX_PHOTO_SIZE),
  maxDocumentSize: Number(process.env.REACT_APP_MAX_DOCUMENT_SIZE),
  timeBeforeLogoutCountdown: Number(
    process.env.REACT_APP_TIME_BEFORE_LOGOUT_COUNTDOWN
  ),
  matomoTrackerBaseUrl: process.env.REACT_APP_MATOMO_TRACKER_BASE_URL,
  matomoSiteId: Number(process.env.REACT_APP_MATOMO_SITE_ID || 0),
  nodeEnv: process.env.REACT_APP_NODE_ENV,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  customerServicePortalUrl: process.env.REACT_APP_CUSTOMER_SERVICE_PORTAL_URL,
};
