import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import {
  Recommendations,
  AssistantCTAPage,
} from 'modules/assistant/components/Assistant';

const AssistantManager = () => {
  const userData: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  return userData?.initialAssessment ? (
    <Recommendations />
  ) : (
    <AssistantCTAPage />
  );
};

export default AssistantManager;
