import React from 'react';

import { config, useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

import imgDataProtection from 'images/data-protection.png';
import imgZeroKnowledgePolicy from 'images/zero-knowledge-policy.png';
import imgServerSecurity from 'images/server-security.png';

const securityFeatures = [
  {
    title: 'common:security_info_modal_data_protection_title',
    description: 'common:security_info_modal_data_protection_desc',
    img: imgDataProtection,
    imgClassName: 'xs:t-mr-6',
  },
  {
    title: 'common:security_info_modal_data_zero_kn_policy_title',
    description: 'common:security_info_modal_data_zero_kn_policy_desc',
    img: imgZeroKnowledgePolicy,
  },
  {
    title: 'common:security_info_modal_server_security_title',
    description: 'common:security_info_modal_server_security_desc',
    img: imgServerSecurity,
    imgClassName: 'xs:t-mr-6',
  },
];

interface SecurityInfoProps {
  onClose: () => void;
  isOnBoarding?: boolean;
}

const SecurityInfo = ({ onClose, isOnBoarding }: SecurityInfoProps) => {
  const { t } = useTranslation(['common', 'assistant']);

  return (
    <div data-testid="SecurityInfo">
      <h1 className="typo-alpha">{t('common:security_info_modal_title')}</h1>
      <p className="typo-epsilon t-mt-4">
        {t('common:security_info_modal_description')}
      </p>

      {securityFeatures.map(
        ({ title, description, img, imgClassName }, idx) => (
          <div key={title} className="t-grid t-grid-cols-8 t-mt-8">
            <div className="t-flex t-justify-center t-items-center t-col-span-3 t-pr-6">
              <img
                src={img}
                className={imgClassName}
                alt={`data security ${idx + 1}`}
              />
            </div>
            <div className="t-col-span-5 t-flex t-flex-col t-justify-center">
              <h2 className="typo-beta">{t(title)}</h2>
              <p className="typo-zeta t-mt-0.5">{t(description)}</p>
            </div>
          </div>
        )
      )}

      <div
        className={`t-flex t-justify-end ${
          isOnBoarding ? 't-mt-20' : 't-mt-10'
        }`}
      >
        <Button
          onClick={onClose}
          category={isOnBoarding ? 'primary' : 'secondary'}
          className={`t-mr-2.5 ${isOnBoarding ? 't-order-2' : 't-order-1'}`}
        >
          {isOnBoarding ? t('common:continue') : t('common:close')}
        </Button>
        <a
          href={`${config.websiteUrl}/security`}
          className={`t-mr-2.5 t-uppercase ${
            isOnBoarding
              ? 't-order-1 button-secondary'
              : 't-order-2 button-primary'
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {isOnBoarding ? t('assistant:learn_more') : t('common:see_detail')}
        </a>
      </div>
    </div>
  );
};

export default SecurityInfo;
