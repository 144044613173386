import produce from 'immer';

import initialState from 'store/reducers/initialState';
import {
  GET_ASSETS,
  GET_ASSETS_BY_CATEGORY,
  ADD_ASSET,
  EDIT_ASSET,
  DELETE_ASSET,
  DELETE_DOCUMENT,
} from 'store/types/assetTypes';

const assetReducer = (state = initialState.assets, action: ReducerAction) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_ASSETS: {
        draft = {};
        const {
          payload: { assets },
        } = action;
        Object.keys(assets).forEach((category) => {
          draft[category] = assets[category];
        });

        return draft;
      }

      case ADD_ASSET: {
        const {
          payload: { newAsset, assetCategory },
        } = action;

        // push to asset array or create new if first asset in whole assetCategory
        if (Array.isArray(draft[assetCategory])) {
          draft[assetCategory].push(newAsset);
        } else {
          draft[assetCategory] = [newAsset];
        }

        return draft;
      }

      case GET_ASSETS_BY_CATEGORY: {
        const {
          payload: { assets, assetCategory },
        } = action;
        draft[assetCategory] = assets;

        return draft;
      }

      case EDIT_ASSET: {
        const {
          payload: { alteredAsset, assetCategory },
        } = action;
        const index = draft[assetCategory].findIndex(
          (asset) => asset.id === alteredAsset.id
        );

        if (index !== -1) draft[assetCategory][index] = alteredAsset;

        return draft;
      }

      case DELETE_ASSET: {
        const {
          payload: { id, assetCategory },
        } = action;
        const index = draft[assetCategory].findIndex(
          (asset) => asset.id === id
        );

        if (index !== -1) draft[assetCategory].splice(index, 1);

        return draft;
      }

      case DELETE_DOCUMENT: {
        const {
          payload: { id, assetId, assetCategory },
        } = action;

        const assetIdx = draft[assetCategory].findIndex(
          (asset) => asset.id === assetId
        );
        if (assetIdx !== -1) {
          const asset = draft[assetCategory][assetIdx];

          if (Array.isArray(asset.documents)) {
            const docIdx = asset.documents.findIndex((doc) => doc.id === id);

            if (docIdx !== -1) asset.documents.splice(docIdx, 1);
          }
        }

        return draft;
      }

      default:
        return draft;
    }
  });

export default assetReducer;
