import { SecurityInfo } from 'modules/menu/components';
import React from 'react';

import { STEP_LABELS } from 'modules/onBoardingWizard/constants';

interface OnBoardingWizardDataSecurityProps {
  handleStepChange: (step: number) => void;
}

const OnBoardingWizardDataSecurity = ({
  handleStepChange,
}: OnBoardingWizardDataSecurityProps) => {
  const handleNextStep = () => {
    handleStepChange(STEP_LABELS.requirementCheck.step);
  };
  return (
    <div className="t-mt-8" data-testid="OnBoardingWizardDataSecurity">
      <SecurityInfo onClose={handleNextStep} isOnBoarding />
    </div>
  );
};

export default OnBoardingWizardDataSecurity;
