import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'modules/common/helpers';

import {
  QUESTIONNAIRE_PAGES,
  QUESTIONNAIRE_KEYS,
} from 'modules/assistant/constants/questionnaires';

function AssistantCTAHeader() {
  const { t } = useTranslation(['assistant']);

  return (
    <div className="card t-bg-delta-900 t-mb-5 t-flex t-flex-col t-items-center t-text-center t-p-5 xl:t-px-16 xl:t-py-7">
      <p className="typo-alpha t-text-gamma-400">
        {t('assistant:cta_page_header_title')}
      </p>

      <p className="typo-beta t-text-gamma-400 t-mt-4">
        {t('assistant:cta_page_header_description')}
      </p>

      <div className="t-flex t-mt-7 xl:t-hidden">
        <Link
          to={QUESTIONNAIRE_PAGES[QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT].path}
          className="button-primary md:t-whitespace-nowrap t-w-full xl:t-w-auto"
        >
          {t('assistant:cta_page_header_button')}
        </Link>
      </div>
    </div>
  );
}

export default AssistantCTAHeader;
