import React from 'react';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { TextInputRef, CountryInput, Button } from 'modules/common/components';

type Inputs = {
  name: string;
  surname: string;
  address: string;
  zip: string;
  city: string;
  country: SelectOption;
};

export interface PostalInfoFormProps {
  defaultInfo: PostalInfo;
  onClose: () => void;
  onSave: (data: PostalInfo) => void;
}

function PostalInfoForm({ defaultInfo, onClose, onSave }: PostalInfoFormProps) {
  const { t } = useTranslation(['auth', 'common']);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,

    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = (formData) => {
    onSave({ ...formData, country: formData.country.value });
  };

  const getError = (name, label) => {
    if (errors[name]) {
      return `${label} ${t('common:required')}`;
    }
    return '';
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="Form sm:t-p-2"
      data-testid="PostalInfoForm"
    >
      <h1 className="typo-alpha t-mb-4">
        {t('plan:edit_postal_address_modal_title')}
      </h1>
      <Row>
        <Col xl={6}>
          <TextInputRef
            {...register('name', { required: true })}
            label={t('auth:name_f')}
            type="text"
            defaultValue={defaultInfo.name}
            error={getError('name', t('profile:name_info'))}
          />
        </Col>

        <Col xl={6}>
          <TextInputRef
            {...register('surname', { required: true })}
            label={t('auth:name_l')}
            type="text"
            defaultValue={defaultInfo.surname}
            error={getError('surname', t('auth:name_l'))}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={{ span: 8 }}>
          <TextInputRef
            {...register('address', { required: true })}
            label={t('auth:address_label')}
            type="text"
            error={getError('address', t('auth:address_label'))}
            defaultValue={defaultInfo.address}
          />
        </Col>

        <Col xl={{ span: 4 }}>
          <TextInputRef
            {...register('zip', { required: true })}
            label={t('auth:postal_label')}
            type="text"
            error={getError('zip', t('auth:postal_label'))}
            defaultValue={defaultInfo.zip}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={{ span: 8 }}>
          <TextInputRef
            {...register('city', { required: true })}
            label={t('auth:city_label')}
            type="text"
            error={getError('city', t('auth:city_label'))}
            defaultValue={defaultInfo.city}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={{ span: 8 }}>
          <CountryInput
            control={control}
            error={(errors.country?.type as string) || ''}
            defaultValue={defaultInfo.country || ''}
            isRequired={true}
            refSetValue={setValue}
            refGetValues={getValues}
          />
        </Col>
      </Row>

      <div className="t-flex xs:t-flex-wrap sm:t-flex-nowrap t-justify-end lg:t-pt-10">
        <Button
          category="secondary"
          className="t-mr-2.5 xs:t-mt-5"
          onClick={onClose}
        >
          {t('common:cancel')}
        </Button>
        <Button type="submit" className="xs:t-mt-5">
          {t('common:save_and_continue')}
        </Button>
      </div>
    </form>
  );
}

export default PostalInfoForm;
