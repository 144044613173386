import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useTranslation, validationHelper } from 'modules/common/helpers';
import securePhoneNumber from 'modules/twoAuth/utils/securePhoneNumber';

import { Button, Modal, TextInputRef } from 'modules/common/components';
import { GenerateNewCodeButton } from 'modules/twoAuth/components';

type TwoAuthEnterSMSConfirmationCodeModalProps = {
  phone?: string | null;
  onCancel: () => void;
  onSuccess: () => void;
  requestActionFn: any;
  showGetNewCodeButton?: boolean;
  disableSubmitButton?: boolean;
  loginData?: UserCredentials;
  twoFaToken?: string;
  is2FAChange?: boolean;
  renewAccessToken?: (twoFaToken: string) => void;
};

type Inputs = {
  smsTfaCode: number;
};

const TwoAuthEnterSMSConfirmationCodeModal = ({
  onCancel,
  onSuccess,
  requestActionFn,
  loginData,
  twoFaToken,
  is2FAChange = false,
  disableSubmitButton = false,
  phone = '',
  renewAccessToken,
}: TwoAuthEnterSMSConfirmationCodeModalProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const {
    register,
    handleSubmit,
    setError,

    formState: { errors },
  } = useForm<Inputs>();
  const [sending, setSending] = useState(false);
  const dispatch = useDispatch();
  const securedPhone = phone ? securePhoneNumber(phone) : '';

  const onSubmit = useCallback(
    async ({ smsTfaCode }: Inputs) => {
      setSending(true);
      try {
        const response = await dispatch(
          requestActionFn(smsTfaCode, twoFaToken)
        );

        if (response) {
          onSuccess();
        } else {
          setError('smsTfaCode', {
            message: t('auth:two_auth_sms_invalid_code'),
            type: 'invalidCode',
          });
        }
      } catch {
        // error is caught in httpHelper.ts
      } finally {
        setSending(false);
      }
    },
    [dispatch, onSuccess, requestActionFn, setError, t, twoFaToken]
  );

  let error: string | undefined;
  if (errors?.smsTfaCode?.type === 'required') {
    error = t('common:field_required');
  }
  if (errors?.smsTfaCode?.type === 'invalidCode') {
    error = errors.smsTfaCode.message;
  }
  if (errors?.smsTfaCode?.type === 'validate') {
    error = `${t('auth:validation_code')} ${t('common:invalid')}`;
  }

  return (
    <Modal dataTestid="TwoAuthEnterSMSConfirmationCodeModal" show>
      <h1 className="Typography Typography--title u-weight-600">
        {is2FAChange
          ? t('auth:two_auth_sms_modal_auth_change_title')
          : t('auth:two_auth_sms_modal_auth_title')}
      </h1>

      <p className="Modal-description">
        {t('auth:two_auth_sms_confirmation_modal_description', {
          phone: securedPhone,
        })}
      </p>

      <form onSubmit={handleSubmit(onSubmit)} className="Form">
        <div className="u-pb-25">
          <TextInputRef
            inputClass="Field--confirmCode"
            {...register('smsTfaCode', {
              required: true,
              validate: (value) => validationHelper.validateCode(6, value),
            })}
            label={t('auth:confirmation_code')}
            type="tel"
            maxLength={6}
            placeholder="XXXXXX"
            autoComplete={false}
            error={error}
          />
        </div>

        <div
          className={cn(
            'd-flex',
            phone ? 'justify-content-between' : 'justify-content-end'
          )}
        >
          <div className="t-flex t-flex-wrap t-justify-end">
            {!!phone && (
              <GenerateNewCodeButton
                phone={phone}
                loginData={loginData}
                renewAccessToken={renewAccessToken}
              />
            )}

            <Button
              category="secondary"
              className="t-ml-2.5 xs:t-mt-5"
              onClick={onCancel}
            >
              {t('common:cancel')}
            </Button>
            <Button
              testId="submitCode"
              disabled={disableSubmitButton}
              loading={sending}
              type="submit"
              className="t-ml-2.5 xs:t-mt-5"
            >
              {t('common:submit_code')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default TwoAuthEnterSMSConfirmationCodeModal;
