import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { camelCase, find } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { RootState } from 'store/reducers';
import {
  setIsShowEditAssetForm,
  setAssetToEdit,
} from 'store/actions/layoutActions';
import { getAsset } from 'store/actions/assetActions';
import { setModal } from 'store/actions/modalActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { getProvidersByType } from 'store/actions/providerActions';
import {
  getFullAddress,
  handleResponseError,
  useGetCountries,
  config,
  useTranslation,
} from 'modules/common/helpers';
import { getDashboardTableCols } from 'modules/dashboard/helpers';
import { getAssetProviderValue } from 'modules/asset/helpers';
import { getProviderType } from 'modules/estatePlan/helpers';
import useGetLocalizationForDate from 'localization/utils/useGetLocalizationForDate';

import { AssetViewModal } from 'modules/asset/components';
import { PdfDownloadLink } from 'modules/common/components';

import { DASHBOARD_TABLE_COLUMNS } from 'modules/common/constants/enums';
import {
  ASSET_CATEGORIES,
  FIELD_TYPE_KEYS,
} from 'modules/estatePlan/constants';
import { REDIRECT_ACTIONS } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';
import { ERROR_CODES, MODALS } from 'modules/common/constants';

import { ReactComponent as IconEdit } from 'images/icon-edit.svg';
import { ReactComponent as DeleteIcon } from 'images/icon-delete.svg';
import { ReactComponent as IconHideEye } from 'images/icon-hide-eye.svg';
import { ReactComponent as IconShowEye } from 'images/icon-show-eye-small.svg';

type DocumentsWidgetRowProps = {
  item: AssetDTO;
  assetCategory: ASSET_CATEGORIES;
  assetTypeName: string;
  planRoute: string;
  onDeleteAsset: (
    asset: AssetDTO,
    assetType: AssetTypeDTO,
    providers: ProviderDTO[],
    assetCategory: string
  ) => void;
};

const DocumentsWidgetRow = ({
  item,
  assetCategory,
  assetTypeName,
  planRoute,
  onDeleteAsset,
}: DocumentsWidgetRowProps) => {
  const { t } = useTranslation(['common', 'plan', 'dashboard']);
  const locale = useGetLocalizationForDate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countries = useGetCountries();

  const [showAssetViewModal, setAssetViewModal] = useState(false);

  const assetTypes = useSelector((state: RootState) => state.assetTypes);
  const assetType: AssetTypeDTO = find(
    assetTypes[camelCase(item.category)],
    (el) => el.id === item.assetTypeId
  );

  // get the formatted provider name
  const providerType = getProviderType(assetType);
  const allFetchedProviders: ProvidersDTO = useSelector(
    (state: RootState) => state.providers.current
  );
  const providers: ProviderDTO[] = allFetchedProviders[providerType] || [];

  React.useEffect(() => {
    if (!!item.provider && providerType && !providers.length) {
      dispatch(getProvidersByType(providerType));
    }
  }, []);

  const copiedAsset = {
    ...item,
    address: item?.address
      ? getFullAddress(item?.address, countries)
      : undefined,
    provider: item?.provider
      ? getAssetProviderValue(item.provider, providers)
      : undefined,
    subtype: item?.subtype ? t(`subtypes:${item.subtype}`) : undefined,
  };

  const {
    name: assetName,
    documents: documentList,
    contacts,
    id,
    provider,
    username,
    subtype,
    updatedAt,
    shared,
  } = copiedAsset;

  const formattedContacts =
    contacts.map(
      (contact: ContactDTO) => `${contact.name} ${contact.surname}`
    ) || [];

  const dispatchAssetToEdit = async (asset: AssetDTO) => {
    await dispatch(setAssetToEdit(asset, assetType));
    await dispatch(setIsShowEditAssetForm(true));

    navigate(planRoute, {
      state: { assetId: id, assetTypeId: assetType.id },
    });
  };

  const onEditAsset = async () => {
    const asset = (await dispatch(
      getAsset(id || '', true, { returnError: true })
    )) as unknown as AssetDTO;
    const responseError = handleResponseError(asset);

    if (!responseError) {
      await dispatchAssetToEdit(asset);
      await dispatch(
        temporaryFieldSaver({
          actionType: REDIRECT_ACTIONS.HIGHLIGHT_ASSIGN_TO_FIELD,
          elementId: FIELD_TYPE_KEYS.CONTACTS,
        })
      );
    } else if (responseError === ERROR_CODES.NOT_ALLOWED) {
      dispatch(
        setModal({
          name: MODALS.PASSPHRASE,
          props: {
            successAction: handlePassPhraseSuccess,
          },
        })
      );
    }
  };

  const handlePassPhraseSuccess = async () => {
    const asset = (await dispatch(getAsset(id || ''))) as unknown as AssetDTO;

    if (asset) {
      await dispatchAssetToEdit(asset);
    }
  };

  const onShowAssetViewModal = () => {
    setAssetViewModal(true);
  };

  const handleAssetViewModalClose = () => {
    setAssetViewModal(false);
  };

  const onNoContactAssignedClick = (event) => {
    event.stopPropagation();
    onEditAsset();
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      {showAssetViewModal && (
        <AssetViewModal
          asset={item}
          assetType={assetType}
          onClose={handleAssetViewModalClose}
        />
      )}

      <tr
        className="Documents-row t-cursor-pointer"
        data-tip={true}
        data-for={id}
        onClick={onShowAssetViewModal}
      >
        {getDashboardTableCols(assetCategory, assetTypeName).map((col) => {
          switch (col) {
            case DASHBOARD_TABLE_COLUMNS.DESCRIPTION:
            case DASHBOARD_TABLE_COLUMNS.NAME:
              return (
                <td key={col} className="Documents-col">
                  <div className="t-min-w-20">{assetName}</div>
                </td>
              );

            case DASHBOARD_TABLE_COLUMNS.FILE:
              return (
                <td
                  key={col}
                  className="Documents-col"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="Documents-downloadAsset">
                    {Array.isArray(documentList) &&
                      documentList.map((document) => (
                        <PdfDownloadLink
                          key={document.id}
                          document={document || {}}
                        />
                      ))}
                  </div>
                </td>
              );

            case DASHBOARD_TABLE_COLUMNS.PROVIDER:
            case DASHBOARD_TABLE_COLUMNS.BANK:
              return (
                <td key={col} className="Documents-col">
                  <div className=" t-min-w-20">{provider}</div>
                </td>
              );

            case DASHBOARD_TABLE_COLUMNS.LOGIN:
              return (
                <td key={col} className="Documents-col">
                  <div className=" t-min-w-20">{username}</div>
                </td>
              );

            case DASHBOARD_TABLE_COLUMNS.ACCOUNT:
            case DASHBOARD_TABLE_COLUMNS.SUBTYPE:
              return (
                <td key={col} className="Documents-col">
                  <div className=" t-min-w-20">{subtype}</div>
                </td>
              );

            case DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO:
              return (
                <td key={col} className="Documents-col t-min-w-20">
                  {!!formattedContacts.length ? (
                    <span className="u-color-rolling-stone">
                      {formattedContacts.map((name) => name).join(', ')}
                    </span>
                  ) : (
                    <button
                      className="t-flex t-items-center t-outline-none"
                      onClick={onNoContactAssignedClick}
                    >
                      <div className="Documents-almostReady" />
                      <span className="Documents-assign u-color-rolling-stone">
                        {t('plan:no_assigned')}
                      </span>
                    </button>
                  )}
                </td>
              );

            case DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED:
              return (
                <td key={col} className="Documents-col t-w-28 t-min-w-28">
                  <span className="u-color-rolling-stone">
                    {updatedAt
                      ? format(
                          parseISO(updatedAt),
                          config.format.uiDashboardDate,
                          {
                            locale,
                          }
                        )
                      : ''}
                  </span>
                </td>
              );

            case DASHBOARD_TABLE_COLUMNS.AVAILABLE_NOW:
              return (
                <td
                  key={col}
                  className="Documents-col t-w-24 t-min-w-24 t-flex t-justify-end"
                >
                  {shared ? (
                    <IconShowEye
                      role="presentation"
                      data-tip={t(
                        'dashboard:assets_healthCare_emergency_access_tooltip'
                      )}
                      onClick={(e) => e.stopPropagation()}
                    />
                  ) : (
                    <IconHideEye className="t-text-beta-500" />
                  )}
                </td>
              );

            default:
              return <td key={col} />;
          }
        })}

        <td
          className="Documents-col t-w-48 t-min-w-48"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="d-flex align-items-center justify-content-end t-mr-2.5">
            <button
              type="button"
              className="Documents-action"
              onClick={onEditAsset}
            >
              <IconEdit className="t-text-beta-200" />
              &nbsp;
              <span className="u-color-boston-blue">
                {t('common:edit_label')}
              </span>
            </button>

            <button
              type="button"
              className="Documents-action"
              onClick={() =>
                onDeleteAsset(copiedAsset, assetType, providers, assetCategory)
              }
            >
              <DeleteIcon className="t-text-epsilon-400" />
              <span className="u-color-boston-blue">
                {t('common:delete_label')}
              </span>
            </button>
          </span>
        </td>
      </tr>
    </>
  );
};

export default DocumentsWidgetRow;
