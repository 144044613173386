import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

import { useTranslation } from 'modules/common/helpers';

import { SecurityInfo } from 'modules/menu/components';
import { Modal } from 'modules/common/components';

const links = [
  {
    path: '/help',
    text: 'common:page_contact_us',
  },
  {
    path: '/terms-and-conditions',
    text: 'common:terms_and_conditions',
  },
  {
    path: '/imprint',
    text: 'common:footer_imprint',
  },
  {
    path: '/privacy',
    text: 'common:footer_privacy',
  },
];

export default function SecondaryNavigation() {
  const { t } = useTranslation(['common']);

  const user = useSelector((state: RootState) => state.user.current);
  const customPrivacyUrl = user?.information?.privacyUrl;

  const [securityInfoModalOpen, setSecurityInfoModalOpen] =
    React.useState(false);

  const openSecurityInfoModal = () => {
    setSecurityInfoModalOpen(true);
  };

  const closeSecurityInfoModal = () => {
    setSecurityInfoModalOpen(false);
  };

  return (
    <>
      {securityInfoModalOpen && (
        <Modal show onHide={closeSecurityInfoModal}>
          <SecurityInfo onClose={closeSecurityInfoModal} />
        </Modal>
      )}
      {links.map(({ path, text }) => (
        <Link
          id={path}
          key={path}
          to={customPrivacyUrl && path === '/privacy' ? customPrivacyUrl : path}
          className="SiteNavigation-listLink t-pb-2.5"
        >
          {t(text)}
        </Link>
      ))}
      <button
        onClick={openSecurityInfoModal}
        className="SiteNavigation-listLink t-pb-2.5"
      >
        {t('common:footer_security_info')}
      </button>
    </>
  );
}
