import {
  FIELD_SAVER,
  FIELD_ERROR,
} from 'store/types/temporaryFieldActionTypes';
import initialState from 'store/reducers/initialState';

const temporaryFieldReducer = (state = initialState.temporaryField, action) => {
  switch (action.type) {
    case FIELD_SAVER:
      return { ...state, value: action.payload };
    case FIELD_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default temporaryFieldReducer;
