import React from 'react';
import { ToastContainer } from 'react-toastify';

import { TIMERS } from 'modules/common/constants/enums';

import { ReactComponent as CheckIcon } from 'images/check.svg';
import { ReactComponent as WarningIcon } from 'images/warning.svg';
import { ReactComponent as ErrorIcon } from 'images/icon-error.svg';

const ReactToastContainer = () => {
  const toastIcon = {
    success: (
      <div>
        <CheckIcon className="t-w-5 t-h-5 t-mr-2 t-text-alpha-700" />
      </div>
    ),
    warning: <WarningIcon className="t-w-5 t-h-5 t-mr-2 t-text-zeta-600" />,
    error: <ErrorIcon className="t-w-5 t-h-5 t-mr-2 t-text-transparent" />,
    info: false,
  };

  const toatBodyColor = {
    success: 't-text-alpha-700',
    warning: 't-text-zeta-600',
    error: 't-text-gamma-400',
    info: '',
  };

  return (
    <ToastContainer
      position="bottom-left"
      autoClose={TIMERS.UNDO}
      icon={({ type }) => toastIcon[type]}
      bodyClassName={(context) =>
        `Toastify__toast-body typo-epsilon typo-epsilon ${
          toatBodyColor[context?.type || 'success']
        }`
      }
    />
  );
};

export default ReactToastContainer;
