import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import {
  changePassword,
  generateSMSCode,
  googleTwoAuthAuthenticate,
  smsTwoAuthAuthenticate,
} from 'store/actions/userActions';
import { authHelper } from 'modules/auth/helpers';
import {
  validationHelper,
  reactToast,
  handleResponseError,
  useTranslation,
} from 'modules/common/helpers';

import {
  TwoAuthEnterGoogleValidationCodeModal,
  TwoAuthEnterSMSConfirmationCodeModal,
} from 'modules/twoAuth/components';
import { PasswordEditModal } from 'modules/profile/components';
import { ERROR_CODES } from 'modules/common/constants';

interface PasswordEditProcessProps {
  handleShowPasswordProcess: (isShow: boolean) => void;
}

function PasswordEditProcess({
  handleShowPasswordProcess,
}: PasswordEditProcessProps) {
  const dispatch = useDispatch();

  const [inputChangePassword, setInputChangePassword] = useState({
    oldPassword: '',
    newPassword: '',
  });

  const [showSMSConfirmationCodeModal, setShowSMSConfirmationCodeModal] =
    useState(false);
  const [showGoogleConfirmationCodeModal, setShowGoogleConfirmationCodeModal] =
    useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const currentUser = useSelector((state: RootState) => state.user.current);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const onChange = (field: string, value) => {
    const newData = { ...inputChangePassword };

    newData[field] = value;

    setInputChangePassword(newData);
    setErrors({ ...errors, [field]: '' });
  };

  const [errors, setErrors] = useState({ oldPassword: '', newPassword: '' });
  const [pwValidity, setPwValidity] = useState({} as PasswordValidity);

  useEffect(() => {
    setPwValidity(
      authHelper.getPasswordValidity(inputChangePassword.newPassword)
    );
  }, [inputChangePassword.newPassword]);

  const isValidPasswordForm = () => {
    const formErrors = {
      oldPassword: '',
      newPassword: '',
    } as UpdatePassword;

    formErrors.newPassword = authHelper.getPasswordErrorMessages(
      inputChangePassword.newPassword,
      t
    );
    formErrors.oldPassword = !inputChangePassword.oldPassword
      ? t('auth:password_required')
      : '';

    setErrors(formErrors);

    return validationHelper.isEmptyErrorObject(formErrors);
  };

  const isSameWithOldPassword = () => {
    const samePassword =
      inputChangePassword.newPassword === inputChangePassword.oldPassword;
    if (samePassword) {
      setErrors({
        ...errors,
        newPassword: t('auth:same_input_as_old_password'),
      });
    }

    return !!samePassword;
  };

  const change = async () => {
    const response: any = await dispatch(
      changePassword(inputChangePassword, { returnError: true })
    );
    setInputChangePassword({ oldPassword: '', newPassword: '' });
    const responseError = handleResponseError(response);

    if (responseError) {
      if (responseError === ERROR_CODES.PASSWORD_NOT_MATCHING) {
        setErrors({
          oldPassword: t(`errors:${responseError}`),
          newPassword: '',
        });
      } else {
        reactToast.showError(t(`errors:${responseError}`));
      }
    } else {
      handleCancel();
    }
  };

  const onSubmit = async () => {
    if (!isValidPasswordForm()) return;
    if (isSameWithOldPassword()) return;

    if (currentUser.smsTfaEnabled) {
      setShowSMSConfirmationCodeModal(true);
      await dispatch(generateSMSCode(currentUser.phone));
      setPhoneNumber(currentUser.phone);
    } else if (currentUser.tfaEnabled) {
      setShowGoogleConfirmationCodeModal(true);
    } else {
      setIsChangingPassword(true);
      await change();
      setIsChangingPassword(false);
    }
  };

  const handlePasswordFormCancel = () => {
    setInputChangePassword({ oldPassword: '', newPassword: '' });
    setErrors({ oldPassword: '', newPassword: '' });
    handleShowPasswordProcess(false);
  };

  const handle2FAuthenticate = async () => {
    setIsChangingPassword(true);
    await change();
    setIsChangingPassword(false);
    handleCancel();
  };

  const { t } = useTranslation(['auth', 'common', 'profile']);

  const handleCancel = () => {
    setShowSMSConfirmationCodeModal(false);
    setShowGoogleConfirmationCodeModal(false);
    handleShowPasswordProcess(false);
  };

  return (
    <>
      {showSMSConfirmationCodeModal && (
        <TwoAuthEnterSMSConfirmationCodeModal
          onCancel={() => handleCancel()}
          onSuccess={handle2FAuthenticate}
          requestActionFn={smsTwoAuthAuthenticate}
          disableSubmitButton={isChangingPassword}
          phone={phoneNumber}
          showGetNewCodeButton={true}
        />
      )}

      {showGoogleConfirmationCodeModal && (
        <TwoAuthEnterGoogleValidationCodeModal
          onCancel={() => handleCancel()}
          onSuccess={handle2FAuthenticate}
          disableSubmitButton={isChangingPassword}
          requestActionFn={googleTwoAuthAuthenticate}
        />
      )}

      {!showSMSConfirmationCodeModal && !showGoogleConfirmationCodeModal && (
        <PasswordEditModal
          inputChangePassword={inputChangePassword}
          onChange={onChange}
          errors={errors}
          pwValidity={pwValidity}
          handlePasswordFormCancel={handlePasswordFormCancel}
          isChangePasswordLoading={isChangingPassword}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}

export default PasswordEditProcess;
