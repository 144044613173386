import React from 'react';
import { Accordion } from 'react-bootstrap';

import { useTranslation } from 'modules/common/helpers';

import { Modal } from 'modules/common/components';
import { ContextAwareToggle } from 'modules/common/components';
import { ReactComponent as Cross } from 'images/cross.svg';

const numberOfHCPItems = 4;
const numberOfPDItems = 4;
interface HowDoesItWorkModalProps {
  onClose: () => void;
  isPD?: boolean;
}

export default function HowDoesItWorkModal({
  onClose,
  isPD,
}: HowDoesItWorkModalProps) {
  const { t } = useTranslation(['zvrRegistration']);

  const numberOfItems = isPD ? numberOfPDItems : numberOfHCPItems;

  return (
    <Modal show onHide={onClose}>
      <div className="t-flex t-justify-between">
        <h1 className="typo-alpha">{t('zvrRegistration:how_work_title')}</h1>
        <button
          onClick={onClose}
          className="t-float-right t-outline-none"
          type="button"
        >
          <Cross />
        </button>
      </div>

      <Accordion defaultActiveKey="0" className="t-mt-10">
        {Array.from(Array(numberOfItems)).map((_, idx) => (
          <React.Fragment key={idx}>
            <div className="t-pb-4">
              <div className="t-flex t-items-center t-justify-between">
                <h2 className="typo-beta">
                  {t(
                    `zvrRegistration:${isPD ? 'pd' : 'hcp'}_question_${idx + 1}`
                  )}
                </h2>

                <div className="sm:t-mr-3 ">
                  <ContextAwareToggle hideText eventKey={`${idx}`} />
                </div>
              </div>
              <Accordion.Collapse eventKey={`${idx}`}>
                <div className="sm:t-flex t-mt-3">
                  {t(
                    `zvrRegistration:${isPD ? 'pd' : 'hcp'}_question_${
                      idx + 1
                    }_answer`
                  )}
                </div>
              </Accordion.Collapse>
            </div>
            {idx !== numberOfItems - 1 && (
              <hr className="t-mr-3 t-mb-5 t-border-none t-h-px t-text-beta-200 t-bg-beta-200" />
            )}
          </React.Fragment>
        ))}
      </Accordion>
    </Modal>
  );
}
