import helper from 'store/actions/actionHelper';
import {
  SET_IS_SHOW_EDIT_ASSET_FORM,
  SET_ASSET_TO_EDIT,
  SET_IS_SHOW_SUBSCRIPTION_WIDGET,
  TOGGLE_ACCOUNT_PANEL,
} from 'store/types/layoutActionTypes';

export const setIsShowEditAssetForm = (isShowEditForm: boolean) =>
  helper.getAction(SET_IS_SHOW_EDIT_ASSET_FORM, { isShowEditForm });

export const setAssetToEdit = (
  currentAsset: AssetDTO,
  currentAssetType: AssetTypeDTO
) => helper.getAction(SET_ASSET_TO_EDIT, { currentAsset, currentAssetType });

export const setIsShowSubscriptionWidget = (
  isShowSubscriptionWidget: boolean
) =>
  helper.getAction(SET_IS_SHOW_SUBSCRIPTION_WIDGET, {
    isShowSubscriptionWidget,
  });

export const toggleAccountPanel = () => ({
  type: TOGGLE_ACCOUNT_PANEL,
});
