import {
  ASYNC_ACTION_START,
  ASYNC_ACTION_WITH_SPINNER_START,
  ASYNC_ACTION_END,
  LOADING,
  ADD_PATHNAME,
} from 'store/types/commonActionTypes';

import helper from 'store/actions/actionHelper';

export const asyncActionStart = (showOverlay: boolean) =>
  helper.getAction(ASYNC_ACTION_START, { showOverlay });
export const asyncActionWithSpinnerStart = (showOverlayWithSpinner: boolean) =>
  helper.getAction(ASYNC_ACTION_WITH_SPINNER_START, { showOverlayWithSpinner });

export const asyncActionEnd = () => helper.getAction(ASYNC_ACTION_END, {});

export const setLoading = (isLoading: boolean) =>
  helper.getAction(LOADING, { isLoading });

export const setCurrentPathname = (pathname: string) =>
  helper.getAction(ADD_PATHNAME, { pathname });
