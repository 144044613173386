import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useIdleTimer } from 'react-idle-timer';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';

import { RootState } from 'store/reducers';
import { setCurrentPathname } from 'store/actions/commonActions';
import { setModal } from 'store/actions/modalActions';
import { toggleNotificationPanel } from 'store/actions/notificationActions';

import {
  getCurrentBeneficiary,
  logOut,
  updateEntireStore,
} from 'store/actions/userActions';
import {
  loginUsingRefreshToken,
  refreshToken,
} from 'store/actions/tokenActions';
import { toggleAccountPanel } from 'store/actions/layoutActions';
import { authService } from 'modules/auth/services';
import { config, reactToast, useTranslation } from 'modules/common/helpers';

import { NotificationPanel } from 'modules/notification/components';
import { Menu } from 'modules/menu/components';
import {
  SiteLanguage,
  LogoutButton,
  OutsideClicker,
  ReleaseNoteModal,
} from 'modules/common/components';
import { NotificationButton } from 'modules/notification/components';
import {
  AccountMenuDropdown,
  AccountManagementPanel,
} from 'modules/familyAccount/components';

import {
  CLICK_OUTSIDE_ELEMENT_TO_EXCLUDE,
  MODALS,
} from 'modules/common/constants';
import { URL_PARAMS, URL_SEARCH_CODES } from 'modules/common/constants/enums';

const STATUSES_TO_KEEP = [
  URL_SEARCH_CODES.POSTAL_PAYMENT_SUCCESSFUL,
  URL_SEARCH_CODES.PAYMENT_CANCELED,
] as string[];

export interface AppPageProps extends AppPageProp {
  children?: Record<string, unknown>;
}

function AppPage(props: AppPageProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['common']);

  const [searchParams, setSearchParams] = useSearchParams();
  const qryReferral = searchParams.get(URL_PARAMS.REFERRAL);
  const qryLoginToken = searchParams.get(URL_PARAMS.TOKEN);
  const qryStatus = searchParams.get(URL_PARAMS.STATUS);

  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const plan: PlanProgressDTO = useSelector(
    (state: RootState) => state.plan.progress
  );
  const globalModal = useSelector((state: RootState) => state.modal.name);
  const isNotificationPanelOpen = useSelector(
    (state: RootState) => state.notifications.isPanelOpen
  );
  const prevPath = useSelector((state: RootState) => state.common.pathname);
  const isBeneficiaryPage = location.pathname === '/beneficiary';

  const isAccountPanelOpen = useSelector(
    (state: RootState) => state.layout.isAccountPanelOpen
  );

  useEffect(() => {
    handleAuthentication();
  }, [isBeneficiaryPage]);

  const handleAuthentication = async () => {
    if (qryLoginToken) {
      await dispatch(loginUsingRefreshToken(qryLoginToken));
      searchParams.delete(URL_PARAMS.TOKEN);
      setSearchParams(searchParams);
    }

    const authenticated = wasAuthenticated();
    if (authenticated) {
      if (_.isEmpty(plan) && !isBeneficiaryPage && !user?.beneficiary) {
        await dispatch(updateEntireStore());

        // if user comes from verification mail with a falsy or expired token, show an error message
        if (qryStatus === URL_SEARCH_CODES.TOKEN_INVALID) {
          reactToast.showError(t('errors:emailTokenExpired'));
        }

        // if the payment is successful, render the corresponding modal
        if (qryStatus === URL_SEARCH_CODES.PAYMENT_SUCCESSFUL) {
          dispatch(setModal(MODALS.PAYMENT_SUCCESSFUL));
        }
        // remove the status from the URL if not necessary to keep for further uses
        if (!STATUSES_TO_KEEP.includes(qryStatus || '')) {
          searchParams.delete(URL_PARAMS.STATUS);
          setSearchParams(searchParams);
        }
      }

      if (isBeneficiaryPage) {
        await dispatch(getCurrentBeneficiary());
      }

      if (prevPath !== '/') {
        navigate(prevPath);
      }
    } else {
      let redirectPath = '';
      switch (true) {
        case !!qryReferral:
          redirectPath = `/register?referral=${qryReferral}`;
          break;
        case isBeneficiaryPage:
          redirectPath = '/access';
          break;
        case location.pathname === '/register-wizard' ||
          ['1000', '2000'].includes(qryStatus || ''):
          redirectPath = `/email-verification${location.search}`;
          break;
        default:
          redirectPath = '/login';
          break;
      }
      await dispatch(setCurrentPathname(location.pathname + location.search));
      navigate(redirectPath);
    }
  };

  // toggles model if user is inactive & tab leadership negotiation
  // 3min in miliseconds
  const timeout = config.timeBeforeLogoutCountdown || 180000;
  const [isLeadTab, setIsLeadTab] = React.useState(true);
  const [startTokenRefreshing, setStartTokenRefreshing] = React.useState(true);

  const idleTimer = useIdleTimer({
    timeout,
    onMessage: async (message) => {
      // Another window is leader, disable this window
      if (message.leader && isLeadTab) {
        setIsLeadTab(false);
      }
    },
    onIdle: () => {
      setStartTokenRefreshing(false);
      return dispatch(setModal(MODALS.LOGOUT_WARNING_MODAL));
    },
    onActive: () => {
      // if the user is active again refresh the token immediately
      if (isLeadTab) {
        setStartTokenRefreshing(true);
      }
      if (globalModal === MODALS.LOGOUT_WARNING_MODAL) {
        dispatch(setModal(null));
      }
    },
    startOnMount: true,
    crossTab: true,
  });

  useEffect(() => {
    localStorage.setItem('leader', '1');
    idleTimer.message({ leader: true });
  }, []);

  // manage responsive footer (2nd navbar in xl screen)
  const [rightContentHeight, setRightContentHeight] = React.useState(0);
  const rightContentRef = React.useRef<HTMLDivElement>(null);
  const getRightContentHeight = () => {
    if (rightContentRef?.current?.clientHeight) {
      setRightContentHeight(rightContentRef.current?.clientHeight);
    }
  };

  window.onresize = getRightContentHeight;
  window.onload = getRightContentHeight;

  React.useLayoutEffect(() => {
    getRightContentHeight();
  }, [props.children]);

  useEffect(() => {
    if (isLeadTab) {
      setStartTokenRefreshing(true);
    } else {
      setStartTokenRefreshing(false);
    }
  }, [isLeadTab]);

  // Determines the leader of all tabs
  // Sets the leader to the the visible window
  // Tab leadership negotiation
  useEffect(() => {
    const onPageShow = () => {
      const hasLeader = localStorage.getItem('leader') === '1';
      if (!isLeadTab && !hasLeader) {
        setIsLeadTab(true);
        idleTimer.message({ leader: true });
        localStorage.setItem('leader', '1');
      }
    };

    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        onPageShow();
      }
    };

    const onGivingUpLeadership = async () => {
      if (isLeadTab) {
        setIsLeadTab(false);
        localStorage.removeItem('leader');
        await idleTimer.message({ leader: false });
      }
    };

    window.addEventListener('visibilitychange', onVisibilityChange);
    window.addEventListener('beforeunload', onGivingUpLeadership);

    return () => {
      window.addEventListener('visibilitychange', onVisibilityChange);
      window.addEventListener('beforeunload', onGivingUpLeadership);
    };
  });

  // refreshes the tokens 45 seconds if user is active
  const refreshTime = 45000;
  useEffect(() => {
    const refreshingInterval = setInterval(() => {
      const leaderExists = localStorage.getItem('leader') === '1';
      const currentRefreshToken = authService.getRefreshToken();
      if (startTokenRefreshing && currentRefreshToken) {
        dispatch(refreshToken(currentRefreshToken));
      }

      if (!currentRefreshToken) {
        handleLogOut();
      }

      // in case if a tab gave up leadership -> take over leadership if no other tab has taken over
      if (!leaderExists && !isLeadTab) {
        setIsLeadTab(true);
        localStorage.setItem('leader', '1');
        idleTimer.message({ leader: true });
      }
    }, refreshTime);

    return () => clearInterval(refreshingInterval);
  }, [startTokenRefreshing]);

  const isAuthenticated = (): boolean => !_.isEmpty(user);
  const wasAuthenticated = (): boolean => !!authService.getToken();

  if (!isAuthenticated()) return null;

  const handleOutsideClick = () => {
    if (isNotificationPanelOpen) {
      dispatch(toggleNotificationPanel());
    }

    if (isAccountPanelOpen) {
      dispatch(toggleAccountPanel());
    }
  };

  async function handleLogOut() {
    await dispatch(logOut());

    if (isBeneficiaryPage || user?.beneficiary) navigate('/access');
    else {
      dispatch(setCurrentPathname(location.pathname));
      navigate('/login');
    }
  }

  return (
    <>
      {props.isNoWrapper && props.children}

      {!props.isNoWrapper && (
        <>
          <Helmet title={t('common:ninebarc_legacy_plan')} />
          <ReactTooltip
            effect="solid"
            place="bottom"
            multiline={true}
            className="u-zindex-max"
          />
          <div className="t-bg-alpha-100 sm:t-max-w-sm xl:t-max-w-xl t-mx-auto t-relative">
            <Menu rightContentHeight={rightContentHeight} />
            {(isNotificationPanelOpen || isAccountPanelOpen) && (
              <OutsideClicker
                onOutsideClick={handleOutsideClick}
                excludedElementId={
                  isNotificationPanelOpen
                    ? CLICK_OUTSIDE_ELEMENT_TO_EXCLUDE.NOTI_BELL_BUTTON
                    : CLICK_OUTSIDE_ELEMENT_TO_EXCLUDE.ACCOUNT_NAV_BUTTON
                }
              >
                {isNotificationPanelOpen ? (
                  <NotificationPanel />
                ) : (
                  <AccountManagementPanel />
                )}
              </OutsideClicker>
            )}
            <div className="t-hidden xl:t-flex t-justify-end t-pt-2.5 xl:t-pb-2.5">
              <div
                className="t-mr-5"
                id={CLICK_OUTSIDE_ELEMENT_TO_EXCLUDE.NOTI_BELL_BUTTON}
              >
                <NotificationButton />
              </div>
              <SiteLanguage additionalClass="u-pb-0 u-mr-20" />
              {!!user.regular ? <AccountMenuDropdown /> : <LogoutButton />}
            </div>
            {!!user.release && !user.secondary && !globalModal && (
              <ReleaseNoteModal />
            )}
            <div ref={rightContentRef}>{props.children}</div>
          </div>
        </>
      )}
    </>
  );
}

export default AppPage;
