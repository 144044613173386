export enum QUESTIONNAIRE_FORM_FIELD_TYPES {
  TEXT = 'text',
  DATE = 'date',
  BIRTHDAY = 'birthday',
  BIRTHPLACE = 'birthplace',
  SINGLE_SELECT = 'singleSelect',
  NUMBERS = 'numbers',
  EMAIL = 'email',
  PHONE = 'phone',
  ADDRESS = 'address',
  GENDER = 'gender',
  NAME = 'name',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  SURNAME = 'surname',
  // type is always text input field
  TYPE = 'type',
  REGISTRATION_NO = 'registrationNumber',
  CHIP_NO = 'chipNumber',
  CONTRACT_NO = 'contractNumber',
  CEMETERY = 'cemetery',
  PROVIDER = 'provider',
  INSURANCE_NUMBER = 'insuranceNumber',
  DESCRIPTION = 'description',
  INSURANCE_TYPE = 'insuranceType',
}
