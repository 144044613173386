import produce from 'immer';

import initialState from 'store/reducers/initialState';
import { FETCH_PROVIDER_BY_TYPE } from 'store/types/assetTypes';

const assetReducer = (state = initialState.providers, action: ReducerAction) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_PROVIDER_BY_TYPE: {
        const {
          payload: { providers, providerType },
        } = action;
        draft.current[providerType] = providers || [];

        return draft;
      }
      default:
        return draft;
    }
  });

export default assetReducer;
