import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { find, endsWith } from 'lodash';

import { RootState } from 'store/reducers';
import {
  getCurrentUser,
  updateCurrentUser,
  validateEmail,
} from 'store/actions/userActions';
import { getFamilyAccounts } from 'store/actions/familyAccountActions';
import {
  optionsHelper,
  config,
  dateHelper,
  useTranslation,
} from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import {
  TextInputRef,
  CountryInput,
  BirthdayInput,
  SelectController,
  VerificationEmailWarningModal,
  Button,
} from 'modules/common/components';

type Inputs = {
  name: string;
  email: string;
  surname: string;
  birthday: string;
  phone: string;
  placeOfBirth: string;
  address: string;
  zip: string;
  city: string;
  country: SelectOption;
  gender: SelectOption;
};

export interface ProfileFormProps {
  onClose?: () => void;
  onSuccess?: () => void;
  isProfilePage: boolean;
  isVerificationEmailWarning?: boolean;
  setIsProfileFormVisible?: (isProfileFormVisible: boolean) => void;
  isOnBoarding?: boolean;
}

function ProfileForm({
  onClose,
  onSuccess,
  isProfilePage,
  setIsProfileFormVisible,
  isVerificationEmailWarning,
  isOnBoarding,
}: ProfileFormProps) {
  const { t } = useTranslation(['auth', 'common']);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,

    formState: { errors },
  } = useForm<Inputs>();
  const dispatch = useDispatch();

  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const planProgress: PlanProgressDTO = useSelector(
    (state: RootState) => state.plan.progress
  );

  const isEmailVerified = !!user.verified;

  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isOnTransmortalAuthorization = endsWith(
    window.location.href,
    '/plan/transmortal'
  );

  useEffect(() => {
    if (setIsProfileFormVisible && wasSubmitted && !!planProgress.profile)
      setIsProfileFormVisible(true);
  }, [setIsProfileFormVisible, wasSubmitted, user, planProgress.profile]);

  const handleVisibleModal = () => {
    if (!user?.verified) setIsVisibleModal(true);
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const submitData = {
      ...formData,
      birthday:
        formData.birthday &&
        dateHelper.convertDateFormat(
          formData.birthday,
          config.format.serverDate
        ),
      country: (formData.country && (formData.country.value as string)) || '',
      gender: (formData.gender && (formData.gender.value as string)) || '',
    } as UserProfileDTO;

    await dispatch(updateCurrentUser(submitData));
    await dispatch(getCurrentUser());
    await dispatch(getFamilyAccounts());
    if (onSuccess) onSuccess();
    setWasSubmitted(true);
    setIsLoading(false);
    if (typeof onClose === 'function') onClose();
  };

  let errName, errSurname, errPlaceOfBirth, errAddress, errZip, errCity;

  if (errors.name) errName = `${t('auth:name_f')} ${t('common:required')}`;
  if (errors.surname)
    errSurname = `${t('auth:name_l')} ${t('common:required')}`;
  if (errors.address)
    errAddress = `${t('auth:address_label')} ${t('common:required')}`;
  if (errors.zip) errZip = `${t('auth:postal_label')} ${t('common:required')}`;
  if (errors.city) errCity = `${t('auth:city_label')} ${t('common:required')}`;
  if (errors.placeOfBirth)
    errPlaceOfBirth = `${t('auth:birthplace_label')} ${t('common:required')}`;

  const genderOptions = optionsHelper.getGenderOptions();

  const onValidateEmail = async () => {
    await dispatch(validateEmail());
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="Form sm:t-p-2"
      data-testid="profile-form"
    >
      <fieldset disabled={isLoading}>
        <Row>
          <Col xl={4}>
            <div className="Form-group">
              <label
                className={`Form-label ${errors.gender ? 'isErrored' : ''}`}
                htmlFor="gender"
              >
                {t('auth:title')}
              </label>

              <SelectController
                name="gender"
                options={genderOptions}
                control={control}
                rules={{ required: true }}
                defaultValue={
                  (find(
                    genderOptions,
                    (item) => item.value === user.gender
                  ) as SelectOption) || ''
                }
                className={`Select ${errors.gender ? 'isErrored' : ''}`}
              />
              {errors.gender && (
                <div className="Form-alert">
                  <span className="text-main-sm t-text-epsilon-600">{`${t(
                    'auth:title'
                  )} ${t('common:required')}`}</span>
                </div>
              )}
            </div>
          </Col>
          <Col xl={4}>
            <TextInputRef
              {...register('name', { required: true })}
              info={t('profile:name_info')}
              label={t('auth:name_f')}
              type="text"
              defaultValue={user.name}
              error={errName}
            />
          </Col>

          <Col xl={4}>
            <TextInputRef
              {...register('surname', { required: true })}
              label={t('auth:name_l')}
              type="text"
              defaultValue={user.surname}
              error={errSurname}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={{ span: 4 }}>
            <BirthdayInput
              control={control}
              error={errors?.birthday?.message || ''}
              defaultValue={dateHelper.convertDateFormat(
                user.birthday || '',
                config.format.uiDate
              )}
              isRequired={true}
              adultOnly
            />
          </Col>

          <Col xl={{ span: 8 }}>
            <TextInputRef
              {...register('placeOfBirth', { required: true })}
              label={t('auth:birthplace_label')}
              type="text"
              error={errPlaceOfBirth}
              defaultValue={user.placeOfBirth}
            />
          </Col>
        </Row>

        {!isProfilePage && !isOnBoarding && !user.secondary && (
          <Row>
            <Col xl={{ span: 12 }} className="t-flex t-items-center">
              <div className="t-flex-grow">
                <TextInputRef
                  {...register('email')}
                  label={t('auth:email_label')}
                  placeholder={t('auth:email_placeholder')}
                  type="text"
                  disabled={true}
                  error=""
                  defaultValue={user.email}
                  inputClass={!isEmailVerified ? 't-border-epsilon-600' : ''}
                />
              </div>

              {!isEmailVerified && (
                <button
                  type="button"
                  onClick={onValidateEmail}
                  className="t-ml-3 t-mt-2 t-py-2 t-px-3 t-bg-beta-100 t-rounded-sm typo-eta t-text-zeta-600 t-whitespace-nowrap t-outline-none"
                >
                  {t('common:email_verify_button_label')}
                </button>
              )}
            </Col>
          </Row>
        )}

        <Row className={isOnBoarding ? 't-mt-8' : ''}>
          <Col xl={{ span: 8 }}>
            <TextInputRef
              {...register('address', { required: true })}
              info={t('profile:address_info')}
              label={t('auth:address_label')}
              type="text"
              error={errAddress}
              defaultValue={user.address}
            />
          </Col>

          <Col xl={{ span: 4 }}>
            <TextInputRef
              {...register('zip', { required: true })}
              label={t('auth:postal_label')}
              type="text"
              error={errZip}
              defaultValue={user.zip}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={{ span: 8 }}>
            <TextInputRef
              {...register('city', { required: true })}
              label={t('auth:city_label')}
              type="text"
              error={errCity}
              defaultValue={user.city}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={{ span: 8 }}>
            <CountryInput
              control={control}
              error={(errors.country?.type as string) || ''}
              defaultValue={user.country || ''}
              isRequired={true}
              refSetValue={setValue}
              refGetValues={getValues}
            />
          </Col>
        </Row>
      </fieldset>

      {isVisibleModal && (
        <VerificationEmailWarningModal setIsVisibleModal={setIsVisibleModal} />
      )}

      <div className="t-flex xs:t-flex-wrap sm:t-flex-nowrap t-justify-end lg:t-pt-10">
        {!isVerificationEmailWarning ? (
          <>
            <Button
              category="secondary"
              className="t-mr-2.5 xs:t-mt-5"
              onClick={onClose}
              disabled={isLoading}
            >
              {isOnBoarding ? t('common:skip_for_now') : t('common:cancel')}
            </Button>
            <Button type="submit" className="xs:t-mt-5" loading={isLoading}>
              {t('common:save_and_continue')}
            </Button>
          </>
        ) : (
          <Button
            type="submit"
            onClick={handleVisibleModal}
            loading={isLoading}
          >
            {isOnTransmortalAuthorization
              ? t('common:sign')
              : t('common:continue')}
          </Button>
        )}
      </div>
    </form>
  );
}

export default ProfileForm;
