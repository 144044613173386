import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconStorage } from 'images/icon-safety-box.svg';

interface BeneAccessLinkProps {
  extraClassName?: string;
}

const BeneAccessLink = ({ extraClassName }: BeneAccessLinkProps) => {
  const { t } = useTranslation(['beneficiary']);
  const navigate = useNavigate();

  const goToBeneficiarySite = () => {
    navigate('/access');
  };

  return (
    <div
      className={`card t-p-3 t-flex t-border-beta-200 t-cursor-pointer ${extraClassName}`}
      onClick={goToBeneficiarySite}
    >
      <IconStorage />
      <p className="typo-epsilon t-ml-3 t-text-delta-700">
        {t('beneficiary:access_link_text')}
      </p>
    </div>
  );
};

export default BeneAccessLink;
