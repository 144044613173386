import { getAssetsList } from 'store/actions/assetActions';
import { questionnaireService } from 'modules/assistant/services';
import { reactToast } from 'modules/common/helpers';
import helper from 'store/actions/actionHelper';

import {
  UPDATE_QUESTIONNAIRE,
  POPULATE_QUESTIONNAIRE,
  POPULATE_QUESTIONNAIRE_FROM_BACKEND,
  NAVIGATE_TO_STEP,
  ERASE_ANSWERS_AFTER_STEP,
} from 'store/types/questionnaireActionTypes';
import { STEP_KEYS } from 'modules/assistant/constants/questionnaires/patientDecree';
import {
  QUESTIONNAIRE_KEYS,
  STEP_COMPONENT_TYPE,
} from 'modules/assistant/constants/questionnaires';
import { CONTENT_QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/contentQuestionnaires';
import { getRecommendations } from 'store/actions/recommendationsActions';

const populateQuestionnaireWithoutBackend = (questionnaire) =>
  helper.getAction(POPULATE_QUESTIONNAIRE, { questionnaire });

const populateQuestionnaireFromBackend = (
  userQuestionnaireData,
  questionnaire
) =>
  helper.getAction(POPULATE_QUESTIONNAIRE_FROM_BACKEND, {
    userQuestionnaireData,
    questionnaire,
  });

export const updateQuestionnaire = (
  questionnaire: QUESTIONNAIRE_KEYS | CONTENT_QUESTIONNAIRE_KEYS,
  step: string,
  answers: SelectedAnswer[] | string | boolean | SelectedAnswer,
  linksTo: string,
  stepType: STEP_COMPONENT_TYPE
) => {
  return helper.getAction(UPDATE_QUESTIONNAIRE, {
    questionnaire,
    step,
    answers,
    linksTo,
    stepType,
  });
};

export const populateQuestionnaire = (
  questionnaire,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const userQuestionnaireData =
      await questionnaireService.getQuestionnaireAnswers(questionnaire);
    if (userQuestionnaireData?.error)
      reactToast.showError(userQuestionnaireData?.error);
    // if no currStepId saved in questionnaire = user has never entered an answer of this questionnaire before
    if (!userQuestionnaireData?.currStepId) {
      dispatch(populateQuestionnaireWithoutBackend(questionnaire));
    } else {
      dispatch(
        populateQuestionnaireFromBackend(userQuestionnaireData, questionnaire)
      );
    }
  }, actionOptions);
};

export const navigateToStep = (
  questionnaire: QUESTIONNAIRE_KEYS,
  stepId: string
) => helper.getAction(NAVIGATE_TO_STEP, { questionnaire, stepId });

export const eraseAnswersAfterStep = (
  questionnaire: QUESTIONNAIRE_KEYS,
  stepId: STEP_KEYS
) => helper.getAction(ERASE_ANSWERS_AFTER_STEP, { questionnaire, stepId });

export const addQuestionnaireAnswer = (
  questionnaire: QUESTIONNAIRE_KEYS | CONTENT_QUESTIONNAIRE_KEYS,
  questionnaireData: QuestionnaireDTO,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async () => {
    await questionnaireService.addQuestionnaireAnswer(
      questionnaire,
      questionnaireData
    );
  }, actionOptions);
};

export const completeQuestionnaire = (
  questionnaire: CONTENT_QUESTIONNAIRE_KEYS,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    await questionnaireService.completeQuestionnaire(questionnaire);

    dispatch(getRecommendations());
  }, actionOptions);
};

export const generateQuestionnairePDF = (
  questionnaire: QUESTIONNAIRE_KEYS,
  category: string,
  location: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const res: any = await questionnaireService.generateQuestionnairePDF(
      questionnaire,
      location
    );
    await dispatch(getAssetsList());

    return res;
  }, actionOptions);
};
