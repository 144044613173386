import React from 'react';
import { ReactComponent as Arrow } from 'images/chevron-next.svg';

interface ArrowButtonProps {
  onClick: () => void;
  disabled: boolean;
  extraClass: string;
  isRight?: boolean;
}

export default function ArrowButton({
  onClick,
  disabled,
  isRight = true,
  extraClass,
}: ArrowButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${extraClass} t-bg-alpha-150 hover:t-bg-alpha-200 t-w-9 t-h-9 t-rounded t-shadow`}
      style={{
        top: 'calc(50% - 18px)',
        right: isRight ? '18px' : '',
        left: !isRight ? '18px' : '',
      }}
    >
      <div className="t-h-4 t-w-4 t-bg-alpha-600 t-rounded-full t-m-auto t-text-center t-relative">
        <Arrow
          className="t-self-center t-text-gamma-400 t-absolute t-w-2 t-top-2 t-left-1"
          style={{
            transform: !isRight
              ? 'translate(0, -50%) rotate(180deg)'
              : 'translate(0, -50%)',
          }}
        />
      </div>
    </button>
  );
}
