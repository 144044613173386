import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { hideRelease } from 'store/actions/userActions';
import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

const ReleasePanel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);

  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const onRefresh = async () => {
    await dispatch(hideRelease());
    window.location.reload();
  };

  if (!user.release) return null;

  return (
    <div
      className="t-w-full t-max-w-xl xl:t-fixed xl:t-flex xl:t-justify-end"
      style={{ top: '58px', zIndex: 9999 }}
      data-testid="ReleasePanel"
    >
      <div className="t-bg-gamma-400 t-rounded t-border t-border-solid t-border-l-0 t-border-delta-700 t-overflow-hidden t-mb-5 xl:t-w-112 xl:t-mb-0">
        <div className="t-flex t-h-full">
          <div className="t-w-2.5 t-h-auto t-bg-delta-700" />
          <div className="t-mt-4 t-mx-5 t-mb-5">
            <p className="typo-beta t-text-delta-700">
              {t('common:release_panel_title')}
            </p>
            <p className="typo-epsilon t-text-beta-500 t-mt-2">
              {t('common:release_panel_description')}
            </p>
            <div className="t-flex t-mt-5">
              <Button className="t-mr-2.5" onClick={onRefresh}>
                {t('common:refresh')}
              </Button>
              <a
                href="https://ninebarc.de/release-notes"
                className="button-secondary"
                target="_blank"
                rel="nofollow noreferrer noopener"
              >
                {t('common:release_notes')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReleasePanel;
