import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'modules/common/helpers';

function EmptyDocumentsWidget() {
  const { t } = useTranslation(['common', 'dashboard']);

  return (
    <div className="t-p-5">
      <h1 className="typo-alpha">{t('dashboard:assets_title')}</h1>
      <p className="typo-epsilon t-text-beta-500 t-my-3">
        {t('dashboard:empty_assets')}
      </p>
      <div className="t-flex">
        <Link to="/plan/introduction" className="button-secondary">
          {t('common:go_to_estate_plan')}
        </Link>
      </div>
    </div>
  );
}

export default EmptyDocumentsWidget;
