import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { enGB, de } from 'date-fns/locale';
import ReactTooltip from 'react-tooltip';

import { useTranslation } from 'modules/common/helpers';

import { LANGUAGES, LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';
import { ReactComponent as IconBell } from 'images/bell.svg';

interface AssetCardReminderButtonProps {
  handleClick: () => void;
  reminder?: string;
  isReminderOpen?: boolean;
}

function AssetCardReminderButton({
  handleClick,
  reminder = '',
  isReminderOpen,
}: AssetCardReminderButtonProps) {
  const { t } = useTranslation(['plan']);

  useEffect(() => {
    ReactTooltip.rebuild();
    if (isReminderOpen) {
      ReactTooltip.hide();
    }
  }, [isReminderOpen]);

  const userLang = localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE);
  const lang = userLang === LANGUAGES.DE ? de : enGB;
  const desiredFormat = userLang === LANGUAGES.DE ? 'do MMM.' : 'MMM. do';
  const localDate = format(
    reminder ? new Date(reminder?.replace(/-/g, '/')) : new Date(),
    desiredFormat,
    {
      locale: lang,
    }
  );

  return (
    <button
      onClick={handleClick}
      className="relative button t-text-sm t-tracking-widest t-p-0 t-text-beta-400"
      data-tip={
        !reminder
          ? t('plan:password_reminder_tooltip')
          : t('plan:password_reminder_already_set_reminder')
      }
    >
      <IconBell className={`${reminder ? 't-mr-2' : ''}`} />
      {reminder ? (
        <span className="typo-kappa t-w-14 t-absolute t-top-8 -t-right-5">
          {localDate}
        </span>
      ) : null}
    </button>
  );
}

export default AssetCardReminderButton;
