import React from 'react';
import validator from 'validator';

import { optionsHelper, useTranslation } from 'modules/common/helpers';

import {
  CommonFormInputProps,
  FormErrors,
  FormGetValues,
} from 'modules/common/types';

import {
  DateInput,
  FullAddressInputs,
  GenderSelect,
  PhoneInput,
  TextInputRef,
} from 'modules/common/components';
import { SingleSelectWithOtherOption } from 'modules/assistant/components/Answers';

import { QUESTIONNAIRE_FORM_FIELD_TYPES as F } from 'modules/assistant/constants/questionnaires/questionnaireFormFieldTypes';
import { VALIDATE } from 'modules/common/constants/generic';

interface QuestionnaireFormFieldSelectorProps extends CommonFormInputProps {
  formTypeElement: QuestionnaireFormType;
  getValues: FormGetValues;
  errors: FormErrors;
  defaultValue?: string;
  preDefinedFieldName?: string;
}

const QuestionnaireFormFieldSelector = ({
  formTypeElement,
  defaultValue,
  register,
  errors,
  control,
  setValue,
  getValues,
  watch,
  preDefinedFieldName,
}: QuestionnaireFormFieldSelectorProps) => {
  const { t } = useTranslation(['common']);

  const {
    type,
    label,
    required,
    placeholder,
    labelHidden,
    options,
    other,
    extraValidation,
  } = formTypeElement;

  const genderOptions = optionsHelper.getGenderOptions2();

  const fieldErr =
    errors[type]?.type === VALIDATE && type === F.NUMBERS
      ? t('common:invalid_number')
      : errors[type]?.message
      ? (errors[type]?.message as string)
      : errors[type]
      ? `${label} ${t('common:required')}`
      : '';

  const commonProps = {
    error: fieldErr,
    defaultValue,
    name: preDefinedFieldName || type,
    autoComplete: false,
    placeholder,
    label,
    labelHidden,
  };

  switch (type) {
    case F.NAME:
    case F.FIRST_NAME:
    case F.LAST_NAME:
    case F.TYPE:
    case F.TEXT:
    case F.REGISTRATION_NO:
    case F.CHIP_NO:
    case F.CONTRACT_NO:
    case F.CEMETERY:
    case F.PROVIDER:
    case F.INSURANCE_NUMBER:
    case F.BIRTHPLACE:
    case F.DESCRIPTION:
      return (
        <TextInputRef
          {...register(commonProps.name, { required: required })}
          type="text"
          {...commonProps}
        />
      );
    case F.BIRTHDAY:
    case F.DATE:
      return (
        <DateInput
          error={fieldErr}
          label={label}
          required={required}
          control={control}
          name={preDefinedFieldName || type}
          defaultValue={defaultValue || ''}
          extraValidation={extraValidation}
        />
      );
    case F.GENDER:
      return (
        <GenderSelect
          label={label}
          fieldErr={fieldErr}
          options={genderOptions}
          control={control}
          defaultValue={defaultValue || ''}
        />
      );
    case F.ADDRESS:
      return (
        <FullAddressInputs
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          required={!!required}
          stringifiedAddress={defaultValue}
          label={label}
        />
      );
    case F.EMAIL:
      return (
        <TextInputRef
          {...register(commonProps.name, {
            required: required,
            validate: (value) => {
              if (!value) return true;
              return validator.isEmail(value.trim());
            },
          })}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue('email', e.target.value.trim())
          }
          type="email"
          {...commonProps}
        />
      );
    case F.PHONE:
      return (
        <PhoneInput
          control={control}
          error={fieldErr}
          defaultValue={defaultValue}
          isRequired={required}
          name={preDefinedFieldName || ''}
        />
      );
    case F.NUMBERS:
      return (
        <TextInputRef
          {...register(commonProps.name, {
            required: required,
            validate: (value) => {
              if (!value) return true;
              return validator.isInt(value);
            },
          })}
          type="number"
          maxLength={300}
          {...commonProps}
        />
      );
    case F.INSURANCE_TYPE:
      return (
        <SingleSelectWithOtherOption
          label={label}
          control={control}
          watch={watch}
          register={register}
          errors={errors}
          setValue={setValue}
          name={type}
          selectOptions={options || []}
          otherOptionFieldData={other}
          defaultValue={defaultValue}
          fieldErr={fieldErr}
          required={required}
        />
      );
    default:
      return null;
  }
};

export default QuestionnaireFormFieldSelector;
