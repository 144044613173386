import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';
import { STEP_KEYS } from 'modules/assistant/constants/questionnaires/healthCareProxy';

interface ExtraInfoFooterProps {
  questionnaire: QUESTIONNAIRE_KEYS;
  currStepId: string;
}

const ExtraInfoFooter = ({
  questionnaire,
  currStepId = '',
}: ExtraInfoFooterProps) => {
  switch (true) {
    case questionnaire === QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY &&
      currStepId === STEP_KEYS.STEP_1:
      return (
        <Wrapper
          transKey="assistant:health_care_proxy_authorized_person_disclaimer"
          className="t-bg-eta-100"
          textClassName="t-text-zeta-600"
        />
      );
    default:
      return null;
  }
};

interface WrapperProps {
  transKey: string;
  className?: string;
  textClassName?: string;
}

const Wrapper = ({
  transKey,
  className = '',
  textClassName = '',
}: WrapperProps) => {
  const { t } = useTranslation();

  return (
    <div className="t-w-full t-mb-4">
      <div className={`t-mx-3 md:t-mx-7 t-p-2.5 t-rounded-sm ${className}`}>
        <p className={`typo-epsilon ${textClassName}`}>{t(transKey)}</p>
      </div>
    </div>
  );
};

export default ExtraInfoFooter;
