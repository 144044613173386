import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

import { DELETE_PROFILE_TYPES } from 'modules/common/constants/enums';

import iconWarning from 'images/icon-warning.svg';

export interface ProfileDeleteModalProps {
  onCancel: () => void;
  handleDeleteType: (deleteType: DELETE_PROFILE_TYPES) => void;
}

function ProfileDeleteModal({
  onCancel,
  handleDeleteType,
}: ProfileDeleteModalProps) {
  const { t } = useTranslation(['profile', 'common']);

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const softDeletionShown = currentUser?.viewer && currentUser?.regular;

  const [loading, setLoading] = React.useState(false);
  const [deleteType, setDeleteType] = React.useState('');

  const onDelete = async (deleteType: DELETE_PROFILE_TYPES) => {
    setDeleteType(deleteType);
    setLoading(true);
    await handleDeleteType(deleteType);
  };

  return (
    <Modal show onHide={onCancel} className="small">
      <div
        className="Modal Modal--topBorderRadius t-bg-beta-100"
        data-testid="ProfileDeleteModal"
      >
        <h1 className="typo-alpha">
          {t('profile:delete_profile_modal_title')}
        </h1>

        <p className="typo-epsilon t-mt-4 t-mb-6">
          {!softDeletionShown
            ? t('profile:delete_profile_modal_description_hard_only')
            : t('profile:delete_profile_modal_description')}
        </p>

        <div className="ProfileDeleteModal">
          <div className="ProfileDeleteModal-warning">
            <h3 className="Typography u-weight-600 t-flex t-items-center t-pb-4">
              <img
                className="ProfileDeleteModal-iconWarning t-mr-2.5"
                src={iconWarning}
                alt="dangerous action warning"
              />
              <span>{t('profile:delete_profile_modal_attention_title')}</span>
            </h3>
            <p className="typo-epsilon">
              {t('profile:delete_profile_modal_attention_description')}
            </p>
          </div>
        </div>
      </div>

      <div className="Modal Modal--bottomBorderRadius t-bg-gamma-400 t-pt-6">
        <p className="t-text-center typo-epsilon">
          {t('profile:delete_profile_modal_last_question')}
        </p>

        <div className="sm:t-flex sm:t-justify-between t-flex-nowrap">
          <div className="sm:t-flex">
            {softDeletionShown && (
              <Button
                category="danger"
                onClick={() => onDelete(DELETE_PROFILE_TYPES.SOFT)}
                className="xs:t-mt-5 t-mr-2.5"
                disabled={loading}
                loading={loading && deleteType === DELETE_PROFILE_TYPES.SOFT}
              >
                {t('common:delete_estate_plan')}
              </Button>
            )}
            <Button
              category="dangerous"
              onClick={() => onDelete(DELETE_PROFILE_TYPES.HARD)}
              className="t-mr-2.5 xs:t-mt-5"
              loading={loading && deleteType === DELETE_PROFILE_TYPES.HARD}
              disabled={loading}
            >
              {t('common:delete')}
            </Button>
          </div>

          <div className="t-flex sm:t-justify-end">
            <Button
              category="secondary"
              onClick={onCancel}
              className="t-mr-2.5 xs:t-mt-5"
              disabled={loading}
            >
              {t('common:cancel')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ProfileDeleteModal;
