import React from 'react';
import { Accordion } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import { useTranslation } from 'modules/common/helpers';

import { BeneficiaryAssetCard } from 'modules/afterDeathManagement/components';
import { ContextAwareToggle } from 'modules/common/components';
import { AssetViewModal } from 'modules/asset/components';
import { Col, Row } from 'modules/bootstrap/components';

interface BeneficiaryAssetsAccordionProps {
  typeLabels: string[];
  assetsGroupedByType: Record<string, AssetDTO[]>;
  assetTypesUncategorized: AssetTypeDTO[];
}

export default function BeneficiaryAssetsAccordion({
  typeLabels,
  assetsGroupedByType,
  assetTypesUncategorized,
}: BeneficiaryAssetsAccordionProps) {
  const { t } = useTranslation();

  const [viewedAsset, setViewedAsset] = React.useState({} as AssetDTO);
  const [viewedAssetType, setViewedAssetType] = React.useState(
    {} as AssetTypeDTO
  );

  const handleViewAsset = (asset: AssetDTO, assetType: AssetTypeDTO) => {
    setViewedAsset(asset);
    setViewedAssetType(assetType);
  };

  const handleAssetViewModalClose = () => {
    setViewedAsset({} as AssetDTO);
    setViewedAssetType({} as AssetTypeDTO);
  };

  return (
    <>
      {!isEmpty(viewedAsset) && !isEmpty(viewedAssetType) && (
        <AssetViewModal
          asset={viewedAsset}
          assetType={viewedAssetType}
          onClose={handleAssetViewModalClose}
        />
      )}
      <Accordion defaultActiveKey="0" className="t-mt-4">
        {typeLabels.map((typeLabel, idx) => {
          const assetsByType = assetsGroupedByType[typeLabel];
          const assetType = assetTypesUncategorized.filter(
            (assetType) => assetType.id === assetsByType[0].assetTypeId
          )[0];
          return (
            <div className="t-pb-4" key={idx}>
              <div className="t-flex t-items-center t-justify-between">
                <h2 className="typo-beta">{t(typeLabel)}</h2>

                <div className="sm:t-mr-3">
                  <ContextAwareToggle eventKey={`${idx}`} />
                </div>
              </div>
              <Accordion.Collapse eventKey={`${idx}`}>
                <Row className="t-mt-3">
                  {assetsByType.map((asset) => (
                    <Col
                      key={asset.id}
                      sm={{ span: 6 }}
                      className="t-cursor-pointer"
                      onClick={() => handleViewAsset(asset, assetType)}
                    >
                      <BeneficiaryAssetCard
                        asset={asset}
                        assetType={assetType}
                        handleViewAsset={handleViewAsset}
                      />
                    </Col>
                  ))}
                </Row>
              </Accordion.Collapse>
            </div>
          );
        })}
      </Accordion>
    </>
  );
}
