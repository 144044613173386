import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'modules/common/helpers';

import documents from 'images/documents.png';
import iconPlus from 'images/icon-plus.svg';

type NoDocumentsHereProps = {
  planRoute: string;
  searching?: boolean;
};

const NoDocumentsHere = ({
  planRoute,
  searching = false,
}: NoDocumentsHereProps) => {
  const { t } = useTranslation(['dashboard']);

  return (
    <div className="t-w-full t-flex t-justify-center">
      <div className="Documents-empty">
        <img
          className="u-w-100"
          src={documents}
          alt="Empty documents"
          role="presentation"
        />
        <p className="u-pt-30 u-pb-30">
          {t(
            `dashboard:${searching ? 'no_documents_found' : 'empty_documents'}`
          )}
        </p>
        <Link to={planRoute} className="button-primary">
          <img
            src={iconPlus}
            className="button-icon-white"
            alt="Add documents"
            role="presentation"
          />
          {t('common:add_new')}
        </Link>
      </div>
    </div>
  );
};

export default NoDocumentsHere;
