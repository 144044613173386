import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { RootState } from 'store/reducers';
import {
  temporaryFieldSaver,
  temporaryFieldError,
} from 'store/actions/temporaryFieldSaverActions';
import { SubstituteHeirCard } from 'modules/assistant/components/Answers/SubstituteHeir';

export interface SubstituteHeirProps {
  previousAnswer?: string;
}

const Styles = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const SubstituteHeir = ({ previousAnswer }: SubstituteHeirProps) => {
  const dispatch = useDispatch();

  const selectedAnswers = useSelector(
    (state: RootState) => state.temporaryField
  );
  const subHeirs = selectedAnswers.value;

  const heirs: ContactDTO[] =
    useSelector((state: RootState) => state.heirs.list) || [];

  useEffect(() => {
    if (previousAnswer) {
      dispatch(temporaryFieldSaver(previousAnswer));
    } else {
      const initialSubHeirs = createInitialSubHeirs();
      dispatch(temporaryFieldSaver(initialSubHeirs));
    }
  }, []);

  const createInitialSubHeirs = () => {
    const initialTempField: Record<string, string>[] = [];
    heirs.forEach((h: ContactDTO) =>
      initialTempField.push({ heirId: h.id, substituteHeir: '' })
    );
    return initialTempField;
  };

  const findCurrSubHeir = (heirId) =>
    subHeirs && subHeirs.find((subHeir) => subHeir.heirId === heirId);

  const handleInputChange = (e, heirId) => {
    e.preventDefault();
    const {
      target: { value },
    } = e;
    const subHeirToChange = findCurrSubHeir(heirId);
    const subHeirToChangeIdx = subHeirs.indexOf(subHeirToChange);
    subHeirs[subHeirToChangeIdx].substituteHeir = value;
    dispatch(temporaryFieldSaver(subHeirs));
    // check if both substituteHeir fields have a value
    const isFieldEmpty = subHeirs.find((subHeir) => !subHeir.substituteHeir);
    // if yes, remove error
    if (isFieldEmpty) {
      dispatch(temporaryFieldError(true));
      // if not, dispatch an error
    } else {
      dispatch(temporaryFieldError(false));
    }
  };

  const isTemporaryFieldInitialised = subHeirs && subHeirs[0]?.heirId;

  if (!isTemporaryFieldInitialised) return null;

  return (
    <>
      {heirs.map((heir) => {
        const previousValue = findCurrSubHeir(heir.id)?.substituteHeir;
        return (
          <Styles key={heir.id}>
            <SubstituteHeirCard
              heir={heir}
              handleInputChange={handleInputChange}
              previousValue={previousValue}
            />
          </Styles>
        );
      })}
    </>
  );
};

export default SubstituteHeir;
