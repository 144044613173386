import React, { FormEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import isEmail from 'validator/lib/isEmail';

import { RootState } from 'store/reducers';
import {
  addContact,
  editContact,
  generateContactAccessDocument,
  sendContactNotification,
} from 'store/actions/contactActions';
import { getCurrentUser } from 'store/actions/userActions';
import { setModal } from 'store/actions/modalActions';
import { getFamilyAccounts } from 'store/actions/familyAccountActions';
import {
  config,
  dateHelper,
  optionsHelper,
  handleResponseError,
  reactToast,
  useTranslation,
} from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import {
  TextAreaRef,
  TextInputRef,
  SelectController,
  PhoneInput,
  BirthdayInput,
  CountryInput,
  Modal,
  GreyLinedContainer,
  Button,
} from 'modules/common/components';
import { Checkbox } from 'modules/elements/components';
import { ProfileForm } from 'modules/profile/components';
import {
  ContactSubstituteSelection,
  ContactMethodSummary,
  ContactFormWarningModal,
} from 'modules/contact/components';
import { SingleSelectWithOtherOption } from 'modules/assistant/components/Answers';

import {
  LOCAL_STORAGE_VARS,
  RELATIONSHIP,
} from 'modules/common/constants/enums';
import { ERROR_CODES, MODALS } from 'modules/common/constants';
import { REDIRECT_ACTIONS } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';

type Inputs = {
  id?: string;
  gender: SelectOption;
  name: string;
  surname: string;
  birthday: string;
  contactNow: SelectOption;
  email: string;
  phone: string;
  personalMessage?: string;
  address?: string;
  country?: SelectOption;
  city?: string;
  zip?: string;
  relation?: SelectOption;
  substituteContactId: SelectOption;
  otherRelation?: string;
};

interface SubmitFields extends Inputs {
  passphrase?: string;
}

export interface ContactFormProps {
  contactItem?: ContactDTO;
  onClose: () => void;
  onSuccess?: (newContact: ContactDTO, ...arg) => void;
  scrollToId?: string;
  onModalOverlaid?: (overlaid: boolean) => void;
  formHidden?: boolean;
  limited?: boolean;
}

const ContactForm = ({
  onClose,
  onSuccess,
  scrollToId,
  onModalOverlaid,
  formHidden,
  limited,
  contactItem = {} as ContactDTO,
}: ContactFormProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const {
    register,
    handleSubmit,
    control,
    setError,
    getValues,
    setValue,
    watch,

    formState: { errors },
  } = useForm<Inputs>();
  const dispatch = useDispatch();

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const isSecondary = !!currentUser.secondary;
  const familyAccounts = useSelector(
    (state: RootState) => state.familyAccounts.list
  );

  const existingContacts = useSelector(
    (state: RootState) => state.contacts?.list
  );

  const [, contactByPhone, contactByPost] = contactItem?.contactBy
    ?.split('')
    .map((strNum) => strNum === '1') || [true, false, false];

  const [isPhoneChecked, setIsPhoneChecked] = useState(!!contactByPhone);
  const [isPostChecked, setIsPostChecked] = useState(!!contactByPost);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHideWhenPassPhraseModal, setIsHideWhenPassPhraseModal] =
    useState(false);
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [showDfContactSettingsModal, setShowDfContactSettingsModal] =
    useState(false);
  const [isDfContactSettingsConfirmed, setIsDfContactSettingsConfirmed] =
    useState(false);

  const isNewContact = !contactItem.id;

  const [passPhraseRequired, setPassPhraseRequired] = useState(false);

  React.useEffect(() => {
    if (onModalOverlaid) {
      onModalOverlaid(passPhraseRequired || showProfileForm);
    }
  }, [passPhraseRequired, showProfileForm]);

  const onSubmit = async (formData: SubmitFields) => {
    if (!currentUser.profile) {
      setShowProfileForm(true);
      return;
    }

    const contactBy = `1${Number(!!isPhoneChecked)}${Number(!!isPostChecked)}`;
    // Disable contact-now for secondary users
    const contactNow = isSecondary
      ? false
      : formData?.contactNow?.value === 'true';

    // if the contact has been notified before, and now the user wants to contact by phone or/and email (not used before)
    // then we should notify the contact via all checked methods
    // check if the user adds any new contacting method to the contacted contact
    const shouldNotifyContactAgain =
      !!contactItem?.contactedDate &&
      !!contactNow &&
      ((!contactByPhone && isPhoneChecked) ||
        (!contactByPost && isPostChecked));

    const submitData = {
      ...contactItem,
      ...formData,
      gender: (formData?.gender?.value as string) || '',
      relation: formData[RELATIONSHIP.OTHER]
        ? formData[RELATIONSHIP.OTHER]
        : formData?.relation
        ? (formData?.relation?.value as string)
        : '',
      birthday:
        formData.birthday &&
        dateHelper.convertDateFormat(
          formData.birthday,
          config.format.serverDate
        ),
      country: (formData.country && (formData.country.value as string)) || '',
      contactBy: contactNow ? contactItem?.contactBy || '100' : contactBy,
      contactNow,
      email: formData.email.toLowerCase(),
      substituteContactId: formData?.substituteContactId?.value,
    } as ContactDTO;

    setIsSubmitting(true);

    if (isNewContact) {
      const newContact: any = await dispatch(
        addContact(submitData, true, { returnError: true })
      );

      const isError = actionErrorCheck(newContact);
      if (isError) {
        setIsSubmitting(false);
        return;
      }

      await handlePostContactSubmission(
        submitData,
        newContact,
        isPostChecked,
        true,
        contactBy
      );
    } else {
      const editedContact: any = await dispatch(
        editContact(submitData, true, { returnError: true })
      );

      const isError = actionErrorCheck(editedContact);
      if (isError) {
        setIsSubmitting(false);
        return;
      }

      // update family-account lists if needed
      const familyAccountExist = !!familyAccounts.find(
        (account) => account.id === editedContact?.secondaryUserId
      );

      if (familyAccountExist) {
        await dispatch(getFamilyAccounts());
      }

      await handlePostContactSubmission(
        submitData,
        editedContact,
        isPostChecked,
        false,
        contactBy,
        shouldNotifyContactAgain
      );
    }

    setIsSubmitting(false);
    if (!onSuccess) {
      onClose();
    }
  };

  const actionErrorCheck = (response) => {
    const error = handleResponseError(response);
    if (error === ERROR_CODES.NOT_ALLOWED) {
      setPassPhraseRequired(true);
      setIsHideWhenPassPhraseModal(true);
      return true;
    } else if (error) {
      reactToast.showError(t(`errors:${error}`));
      return true;
    } else return false;
  };

  const handlePostContactSubmission = async (
    preSubmittedData: ContactDTO,
    submittedContact: ContactDTO,
    isPostChecked: boolean,
    isNewContact: boolean,
    contactBy: string,
    shouldNotifyContactAgain = false
  ) => {
    let copiedContact: any = { ...submittedContact };

    const isDocumentGenerationNeeded =
      preSubmittedData.gender !== contactItem?.gender ||
      preSubmittedData.name !== contactItem?.name ||
      preSubmittedData.surname !== contactItem?.surname;

    const isChangeToContactNow =
      !!preSubmittedData.contactNow !== !!contactItem?.contactedDate &&
      preSubmittedData.contactNow;

    const shouldDocumentGenerate = isNewContact
      ? true
      : isDocumentGenerationNeeded;

    if (submittedContact?.id && shouldDocumentGenerate) {
      copiedContact = await dispatch(
        generateContactAccessDocument(submittedContact.id, !!isNewContact)
      );
    }

    if (
      (isChangeToContactNow || shouldNotifyContactAgain) &&
      copiedContact?.id
    ) {
      await handleNotifyContacts(
        copiedContact.id,
        isPostChecked,
        contactBy,
        isNewContact
      );
    }

    // The postal service should be rendered when contactNow is selected only
    const postalServiceRequired =
      (isChangeToContactNow || shouldNotifyContactAgain) && isPostChecked;

    if (copiedContact?.id && !!onSuccess) {
      onSuccess(copiedContact, postalServiceRequired);
    }
  };

  const submit = (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();

    handleSubmit(async (formData: Inputs) => {
      if (currentUser.email === formData.email.trim()) {
        setError('email', {
          message: t('auth:email_cannot_assign_yourself'),
          type: 'yourselfEmail',
        });
        return;
      }

      await onSubmit(getValues());
    })();
  };

  const handleNotifyContacts = async (
    contactId,
    isPostChecked,
    contactBy,
    isNewContact
  ) => {
    if (!isPostChecked) {
      await dispatch(sendContactNotification(contactId, '', '', contactBy));
    } else {
      // save contactBy to localStorage before render the post service flow
      const dataToSave = {
        actionType: REDIRECT_ACTIONS.NOTIFY_CONTACT,
        contactId,
        contactBy,
        isNewContact,
      };
      localStorage.setItem(
        LOCAL_STORAGE_VARS.DATA_ON_REDIRECT,
        JSON.stringify(dataToSave)
      );
    }
  };

  // handle passphrase requirement
  React.useEffect(() => {
    if (!!passPhraseRequired) {
      dispatch(
        setModal({
          name: MODALS.PASSPHRASE,
          props: {
            cancelAction: handlePassPhraseCancel,
            successAction: handlePassPhraseSuccess,
          },
        })
      );
    }
  }, [passPhraseRequired]);

  const handlePassPhraseSuccess = async () => {
    setPassPhraseRequired(false);
    const submitData = getValues() as SubmitFields;

    setIsHideWhenPassPhraseModal(false);
    await onSubmit(submitData);
  };

  const handlePassPhraseCancel = () => {
    setPassPhraseRequired(false);
    setIsSubmitting(false);
    setIsHideWhenPassPhraseModal(false);
  };

  const fieldRequired = t('common:field_required');
  const genderOptions = optionsHelper.getGenderOptions();
  const contactMethodOptions = optionsHelper.getContactMethodOptions();
  const defaultGenderOptions =
    genderOptions.find((item) => item.value === contactItem.gender) ||
    genderOptions[0];
  const defaultContactMethodOptions = contactMethodOptions.find(
    (item) => item.value === (contactItem?.contactedDate ? 'false' : 'true')
  );
  const relationshipOptions = optionsHelper.getRelationshipOptions();

  const errEmail = () => {
    if (errors.email) {
      if (errors.email.type === 'required')
        return `${t('auth:email_required')}`;
      if (errors.email.type === 'yourselfEmail') return errors.email.message;
      return `${t('auth:email_not_valid')}`;
    }
    return null;
  };

  const errPhone = () => {
    if (errors.phone) {
      return (
        errors.phone.type === 'required'
          ? `${t('auth:phone_label')} ${t('common:required')}`
          : `${t('auth:phone_label')} ${t('common:not_valid')}`
      ) as string;
    }
    return null;
  };

  const handlePhoneCheckboxClick = () => {
    setIsPhoneChecked(!isPhoneChecked);
  };

  const handlePostCheckboxClick = () => {
    setIsPostChecked(!isPostChecked);
  };

  const textFieldRequired = t('common:field_required');

  const getOptionalLabel = (transKey: string) =>
    `${t(transKey)} ${t('common:optional_label')}`;

  const addressRequired =
    !!errors.address || !!errors.zip || !!errors.city || !!errors.country;

  const handleSuccessfulProfileUpdate = async () => {
    await dispatch(getCurrentUser());
    handleHideProfileModal();
  };

  const handleHideProfileModal = () => {
    setShowProfileForm(false);
  };

  const handleHideDefaultSettingsModal = () => {
    if (!isDfContactSettingsConfirmed) {
      setValue('contactNow', contactMethodOptions[0]);
      setShowDfContactSettingsModal(false);
    }
  };

  const handleSelectAfterDeath = () => {
    setValue('contactNow', contactMethodOptions[1]);
    setShowDfContactSettingsModal(false);
    setIsDfContactSettingsConfirmed(true);
  };

  const isContactNow =
    (watch('contactNow')?.value || defaultContactMethodOptions?.value) ===
    'true';

  React.useEffect(() => {
    if (!isContactNow && !isDfContactSettingsConfirmed && isNewContact) {
      if (!isDfContactSettingsConfirmed) {
        setShowDfContactSettingsModal(true);
      }
    }
    return () => {
      setShowDfContactSettingsModal(false);
    };
  }, [isContactNow]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, [currentUser.verified]);

  return (
    <>
      {showProfileForm && (
        <Modal show onHide={handleHideProfileModal} hasCloseCross>
          <h1 className="typo-alpha t-pb-5">
            {t('mcontacts:profile_not_complete_contact_form_title')}
          </h1>
          <div className="t-flex">
            <ProfileForm
              isProfilePage={false}
              onClose={handleHideProfileModal}
              onSuccess={handleSuccessfulProfileUpdate}
            />
          </div>
        </Modal>
      )}

      {showDfContactSettingsModal && (
        <ContactFormWarningModal
          onContinue={handleSelectAfterDeath}
          onHide={handleHideDefaultSettingsModal}
        />
      )}

      <form
        onSubmit={submit}
        className={`Form xl:t-mb-0 ${
          isHideWhenPassPhraseModal ||
          formHidden ||
          showProfileForm ||
          showDfContactSettingsModal
            ? 't-hidden'
            : ''
        }`}
        data-testid="ContactForm"
      >
        {!isNewContact && (
          <input
            type="hidden"
            id="id"
            {...register('id')}
            value={contactItem.id}
          />
        )}
        <fieldset disabled={isSubmitting}>
          <Row>
            <Col xs={6} xl={3}>
              <div className="Form-group">
                <label className="Form-label" htmlFor="gender">
                  {t('auth:title')}
                </label>

                <SelectController
                  id="gender"
                  name="gender"
                  options={genderOptions}
                  control={control}
                  defaultValue={defaultGenderOptions}
                />
              </div>
            </Col>
            <Col xl={4}>
              <TextInputRef
                label={t('auth:name_f')}
                type="text"
                autoComplete={false}
                defaultValue={contactItem.name}
                error={errors.name && fieldRequired}
                {...register('name', { required: true })}
              />
            </Col>

            <Col xl={5}>
              <TextInputRef
                {...register('surname', { required: true })}
                label={t('auth:name_l')}
                type="text"
                autoComplete={false}
                defaultValue={contactItem.surname}
                error={errors.surname && fieldRequired}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={6} xl={4}>
              <BirthdayInput
                control={control}
                error={errors?.birthday?.message || ''}
                defaultValue={dateHelper.convertDateFormat(
                  contactItem.birthday || '',
                  config.format.uiDate
                )}
                isRequired={true}
                tooltipText={t('mcontacts:contact_birthday_tooltip')}
              />
            </Col>
            <Col xl={8}>
              <SingleSelectWithOtherOption
                label={getOptionalLabel('mcontacts:rel_field_label')}
                control={control}
                watch={watch}
                register={register}
                errors={errors}
                setValue={setValue}
                name="relation"
                selectOptions={relationshipOptions}
                defaultValue={contactItem?.relation}
                otherOptionFieldData={{
                  name: RELATIONSHIP.OTHER,
                  label: t('plan:subtype_other_label'),
                }}
              />
            </Col>
          </Row>

          <label
            className={`Form-label ${addressRequired ? 'isErrored' : ''}`}
            htmlFor="address"
          >
            {isPostChecked
              ? t('fieldLibrary:label_address')
              : getOptionalLabel('fieldLibrary:label_address')}
          </label>
          <Row className="">
            <Col xl={8}>
              <TextInputRef
                {...register('address', { required: isPostChecked })}
                placeholder={t('auth:address_label')}
                type="text"
                error={errors.address && textFieldRequired}
                defaultValue={contactItem?.address}
                inputClass="text-main"
              />
            </Col>
            <Col xl={4}>
              <TextInputRef
                {...register('zip', { required: isPostChecked })}
                placeholder={t('auth:postal_label')}
                type="text"
                error={errors.zip && textFieldRequired}
                defaultValue={contactItem?.zip}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <TextInputRef
                {...register('city', { required: isPostChecked })}
                placeholder={t('auth:city_label')}
                type="text"
                error={errors.city && textFieldRequired}
                defaultValue={contactItem?.city}
              />
            </Col>
            <Col xl={6}>
              <CountryInput
                control={control}
                error={(errors.country?.type as string) || ''}
                defaultValue={contactItem?.country}
                isRequired={isPostChecked}
                refSetValue={setValue}
                refGetValues={getValues}
                isDefaultLabelVisible={false}
              />
            </Col>
          </Row>

          <p className="t-pt-4 t-pb-2 t-text-beta-500">
            {t('common:contact_method_label')}
          </p>
          <Row>
            {!isSecondary && (
              <Col xl={6}>
                <div className="Form-group" data-testid="contactNow">
                  <SelectController
                    id="contactNow"
                    name="contactNow"
                    options={contactMethodOptions}
                    control={control}
                    defaultValue={defaultContactMethodOptions}
                    rules={{ required: true }}
                    className={`Select ${errors.contactNow ? 'isErrored' : ''}`}
                  />
                  {errors.contactNow && (
                    <div className="t-mt-0.5">
                      <span className="t-text-sm t-text-epsilon-600">
                        {textFieldRequired}
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            )}
            <Col className="t-pb-2">
              <p className="t-mt-1.5">{`${
                isSecondary ? contactMethodOptions[1].label + ' ' : ''
              }${t('common:via')} ${t('common:email_required_label')}`}</p>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <TextInputRef
                {...register('email', {
                  required: true,
                  validate: (value) => isEmail(value),
                })}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue('email', e.target.value.trim())
                }
                placeholder={t('fieldLibrary:label_email')}
                type="email"
                autoComplete={false}
                defaultValue={contactItem.email}
                error={errEmail()}
                dataTestId="email-input"
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6} className="t-pb-2 xl:t-h-12 t-mt-2">
              <GreyLinedContainer isLineVisible={isPhoneChecked}>
                <Checkbox
                  text={t('common:sms_label')}
                  checked={isPhoneChecked}
                  id="phoneCheckbox"
                  onChange={handlePhoneCheckboxClick}
                  textClassName="text-main"
                  isBlueBg
                  tooltipText={t('mcontacts:contact_phone_tooltip')}
                  testId="phoneCheckbox"
                />
              </GreyLinedContainer>
            </Col>
            <Col xl={6}>
              {(isPhoneChecked || !!contactItem?.phone) && (
                <PhoneInput
                  control={control}
                  error={errPhone() || ''}
                  defaultValue={contactItem.phone}
                  isRequired={isPhoneChecked}
                  isDefaultLabelVisible={false}
                />
              )}
            </Col>
          </Row>

          {!isSecondary && (
            <Row className="t-mt-4 xl:t-mt-0">
              <Col className="t-flex">
                <Checkbox
                  text={t('common:post_label')}
                  checked={isPostChecked}
                  id="postCheckbox"
                  disabled={!!isContactNow && !!limited}
                  onChange={handlePostCheckboxClick}
                  textClassName="text-main "
                  isBlueBg
                  testId="postCheckbox"
                  tooltipText={
                    !!isContactNow && !!limited
                      ? t('mcontacts:feature_only_be_shown_on_contacts_page')
                      : ''
                  }
                />
              </Col>
            </Row>
          )}

          <Row className="t-pt-8">
            <Col>
              <TextAreaRef
                {...register('personalMessage')}
                label={t('common:user_message')}
                defaultValue={contactItem?.personalMessage}
                maxLength={2000}
                rows={3}
                placeholder={t('common:user_message_placeholder')}
                textareaClass="text-main"
                info={t('common:input_personal_message_info')}
              />
            </Col>
          </Row>
          <ReactTooltip
            id="submitBtn"
            effect="solid"
            place="top"
            multiline
            className="t-bg-eta-100 typo-epsilon t-text-zeta-600"
          />
          {existingContacts?.length ? (
            <ContactSubstituteSelection
              control={control}
              contactItem={contactItem}
              scrollToId={scrollToId}
            />
          ) : null}

          <ContactMethodSummary
            watch={watch}
            phone={isPhoneChecked}
            post={isPostChecked}
          />

          <div className="t-flex t-flex-wrap t-justify-end xs:t-mt-5 sm:t-mt-0 t-z-0 t-relative">
            <Button
              category="secondary"
              className="xs:t-mt-5 xs:t-order-2 sm:t-order-1 xs:t-ml-4"
              onClick={onClose}
              disabled={isSubmitting}
            >
              {t('common:cancel')}
            </Button>
            <div
              className="t-ml-5 t-self-end sm:t-mb-0 xs:t-order-1 sm:t-order-2"
              data-tip={t('notification:verifyEmailAddress_description')}
              data-tip-disable={!!currentUser.verified}
              data-for="submitBtn"
            >
              <Button
                type="submit"
                disabled={!currentUser.verified}
                loading={isSubmitting}
              >
                {t('common:save_and_continue')}
              </Button>
            </div>
          </div>
        </fieldset>
      </form>
    </>
  );
};

export default ContactForm;
