import React from 'react';
import { useSelector } from 'react-redux';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import ReactTooltip from 'react-tooltip';

import { RootState } from 'store/reducers';
import usePortalUrl from 'modules/subscription/utils/usePortalUrl';
import { useTranslation } from 'modules/common/helpers';

import { Payment } from 'modules/payment/components';
import { Modal } from 'modules/common/components';

import { ReactComponent as Cross } from 'images/cross.svg';
import authBg from 'images/two-column-page-bg-1.png';

interface ManageSubscriptionButtonProps {
  extraClassName?: string;
  isFromSubscriptionModal?: boolean;
  showModalOnly?: boolean;
  onClose?: () => void;
  info?: string;
}

const ManageSubscriptionButton: React.FC<ManageSubscriptionButtonProps> = ({
  extraClassName = '',
  isFromSubscriptionModal = false,
  showModalOnly,
  onClose,
  info,
}) => {
  const { t } = useTranslation(['subscription', 'profile']);
  const currentUser = useSelector((state: RootState) => state.user.current);
  const portalUrl = usePortalUrl();
  const { trackEvent } = useMatomo();

  const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] =
    React.useState<boolean>(isFromSubscriptionModal || !!showModalOnly);

  const handlePaymentToggle = () => {
    setIsSubscriptionModalVisible((oldState) => {
      if (onClose && !!oldState) {
        onClose();
      }
      return !oldState;
    });
    trackEvent({
      category: 'subscription',
      action: 'upgrade-click-event',
    });
  };

  if (!!currentUser.exemptFromPaying) return null;

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      {!showModalOnly && (
        <>
          {portalUrl && currentUser.checkoutDone && (
            <a
              href={portalUrl}
              className={`button t-bg-delta-700 t-text-gamma-400 hover:t-bg-delta-900 hover:t-shadow-none ${extraClassName}`}
              rel="noopener noreferrer"
            >
              <span className="t-pl-2.5 t-pr-2.5">
                {t('profile:pricing_plan_manage_subscription')}
              </span>
            </a>
          )}
          {!currentUser.checkoutDone && (
            <div className="t-flex t-justify-end t-w-full">
              <button
                type="button"
                className={`button t-bg-delta-700 t-text-gamma-400 hover:t-bg-delta-900 hover:t-shadow-none ${extraClassName}`}
                onClick={handlePaymentToggle}
                data-tip={info}
                data-tip-disable={!info}
              >
                {t('subscription:trial_period_upgrade_button')}
              </button>
            </div>
          )}
        </>
      )}
      {isSubscriptionModalVisible && (
        <Modal
          show
          onHide={handlePaymentToggle}
          className="full-screen"
          onFullScreen
          dataTestid="SubscriptionModal"
        >
          <section className="t-bg-gamma-400 t-flex t-min-h-full t-m-0 t-relative t-animate-fade-in">
            <div className="t-min-h-full t-flex-grow">
              <button
                onClick={handlePaymentToggle}
                className="t-absolute t-top-8 t-right-8"
                type="button"
              >
                <Cross />
              </button>
              <div className="t-flex t-h-full">
                <img
                  src={authBg}
                  className="xs:t-hidden md:t-block md:t-w-1/2 md:t-h-screen t-object-cover t-sticky t-top-0"
                  alt="Payment background"
                />
                <Payment handlePaymentToggle={handlePaymentToggle} />
              </div>
            </div>
          </section>
        </Modal>
      )}
    </>
  );
};

export default ManageSubscriptionButton;
