import React from 'react';
import { FieldError, useForm } from 'react-hook-form';

import { generateSMSCode } from 'store/actions/userActions';
import { useAsyncRequest, useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { PhoneInput, Modal, Button } from 'modules/common/components';

export type TwoAuthSMSModalProps = {
  defaultPhone?: string;
  onCancel: () => void;
  onCodeGenerated: (phone: string) => void;
};

type Inputs = {
  phone: string;
};

const getPhoneNumberError = (
  phoneNumberErr: FieldError | undefined,
  t: (key: string) => string
) => {
  let error = '';

  if (phoneNumberErr && phoneNumberErr.type === 'required') {
    error = t('common:field_required');
  }

  return error;
};

const TwoAuthSMSModal = ({
  onCancel,
  onCodeGenerated,
  defaultPhone = '',
}: TwoAuthSMSModalProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<Inputs>();
  const { request: generateSMSCodeRequest, loading: generateLoading } =
    useAsyncRequest(generateSMSCode);
  const phoneError = getPhoneNumberError(errors.phone, t);

  const onSubmit = async ({ phone }) => {
    const response = await generateSMSCodeRequest(phone);

    if (response) {
      onCodeGenerated(phone);
    }
  };

  return (
    <Modal show>
      <h1 className="Typography Typography--title u-weight-600">
        {t('auth:two_auth_title')}
      </h1>

      <p className="Modal-description">{t('auth:two_auth_sms_description')}</p>

      <form onSubmit={handleSubmit(onSubmit)} className="Form">
        <Row className="u-pb-25">
          <Col xl={{ span: 6 }}>
            <PhoneInput
              control={control}
              error={phoneError}
              defaultValue={defaultPhone}
              isRequired
            />
          </Col>
        </Row>

        <div className="t-flex t-flex-wrap t-justify-end">
          <Button
            testId="cancelButton"
            category="secondary"
            className="t-mr-2.5 t-self-end"
            onClick={onCancel}
            disabled={generateLoading}
          >
            {t('common:cancel')}
          </Button>
          <Button
            testId="getCode"
            loading={generateLoading}
            type="submit"
            className="xs:t-mt-5"
          >
            {t('auth:two_auth_sms_get_code')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default TwoAuthSMSModal;
