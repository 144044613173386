import React from 'react';
import { camelCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { getOrCreateAssetTypes } from 'store/actions/assetTypeActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { getCardLink } from 'modules/assistant/helpers/questionnaireHelper';
import { useTranslation } from 'modules/common/helpers';

import { Button, Modal, RadioCard } from 'modules/common/components';

import {
  QUESTIONNAIRES_TO_ASSET_TYPES,
  REDIRECT_ACTIONS,
} from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';

const STARTING_OPTION = 'startingOption';
const CREATE = 'create';
const UPLOAD = 'upload';

interface RecoStartModalProps {
  recoName: string;
  onClose?: () => void;
  create?: boolean;
  upload?: boolean;
}

const RecoStartModal = ({
  recoName,
  onClose,
  create,
  upload,
}: RecoStartModalProps) => {
  const { t } = useTranslation(['recommendations']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, watch } = useForm();

  const recommendation = camelCase(recoName);
  const cardTitle = t(`${recoName}`);

  const startingOption = watch(STARTING_OPTION);

  const {
    path,
    category,
    assetType,
    subtype = '',
  } = QUESTIONNAIRES_TO_ASSET_TYPES[recommendation] || {};

  const handleCreateDocument = () => {
    navigate(getCardLink(recoName));
  };

  const handleUploadRedirect = async () => {
    if (assetType && path) {
      await dispatch(getOrCreateAssetTypes(assetType));

      dispatch(
        temporaryFieldSaver({
          actionType: REDIRECT_ACTIONS.UPLOAD_DOCUMENT,
          category,
          assetType,
          subtype,
        })
      );
      navigate(path);
    } else if (!assetType && path) {
      navigate(path);
    }
  };

  const onNextStep = async (formData) => {
    const startingOption = formData[STARTING_OPTION];
    if (startingOption === CREATE) {
      handleCreateDocument();
    } else {
      handleUploadRedirect();
    }
    return;
  };

  const onRedirect = () => {
    if (create && upload) return;
    if (create) handleCreateDocument();
    if (upload) handleUploadRedirect();
  };

  return (
    <Modal show onHide={onClose} className="">
      <form onSubmit={handleSubmit(onNextStep)} className="t-p-0">
        <h1 className="typo-alpha">{cardTitle}</h1>
        <p className="typo-epsilon t-mt-4">
          {t(`recommendations:${recoName}_starting_modal_description`)}
        </p>

        {create && upload && (
          <div className="t-flex t-flex-col sm:t-flex-row">
            <RadioCard
              register={register}
              name={STARTING_OPTION}
              id={UPLOAD}
              value={UPLOAD}
              className={`t-items-start t-mt-5 sm:t-w-1/2 sm:t-mr-2 ${
                startingOption === UPLOAD ? 't-bg-delta-100' : ''
              }`}
              noFlex
              classNameRadioBtn="t-mb-3"
            >
              <>
                <h2 className="typo-beta">
                  {t('recommendations:starting_modal_upload_option', {
                    name:
                      recommendation !== QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD
                        ? cardTitle
                        : t('plan:organ donor card_name'),
                  })}
                </h2>
              </>
            </RadioCard>
            <RadioCard
              register={register}
              name={STARTING_OPTION}
              id={CREATE}
              value={CREATE}
              className={`t-items-start t-mt-5 sm:t-w-1/2 sm:t-ml-2 ${
                startingOption === CREATE ? 't-bg-delta-100' : ''
              }`}
              noFlex
              classNameRadioBtn="t-mb-3"
            >
              <>
                <h2 className="typo-beta">
                  {t('recommendations:starting_modal_create_option', {
                    name:
                      recommendation !== QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD
                        ? cardTitle
                        : t('plan:organ donor card_name'),
                  })}
                </h2>
              </>
            </RadioCard>
          </div>
        )}

        <div className="t-flex t-justify-end t-items-end t-mt-8">
          <div>
            <Button
              category="secondary"
              className="t-mr-3 t-py-2 t-mb-0"
              onClick={onClose}
            >
              {t('common:cancel')}
            </Button>
          </div>

          <div>
            <Button
              type={create && upload ? 'submit' : 'button'}
              onClick={onRedirect}
              className="t-py-2"
              disabled={upload && create ? !startingOption : false}
            >
              {create && upload
                ? t('common:continue')
                : create
                ? t('common:create')
                : t('assistant:upload')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default RecoStartModal;
