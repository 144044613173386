import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconLogin } from 'images/icon_login.svg';

interface LoginLinkProps {
  extraClassName?: string;
}

const LoginLink = ({ extraClassName }: LoginLinkProps) => {
  const { t } = useTranslation(['auth']);
  const navigate = useNavigate();

  const goToBeneficiarySite = () => {
    navigate('/login');
  };

  return (
    <div
      className={`card t-p-3 t-flex t-border-beta-200 t-cursor-pointer ${extraClassName}`}
      onClick={goToBeneficiarySite}
    >
      <IconLogin className="t-text-alpha-600 t-w-5 t-h-5" />
      <p className="typo-epsilon t-ml-3 t-text-alpha-600">
        {t('auth:login_link_text')}
      </p>
    </div>
  );
};

export default LoginLink;
