import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import { RootState } from 'store/reducers';
import {
  signUp,
  loginUser,
  getCurrentUser,
  setUserLanguage,
} from 'store/actions/userActions';
import { setLoading } from 'store/actions/commonActions';
import {
  validationHelper,
  reactToast,
  useQuery,
  handleResponseError,
  config,
  useTranslation,
} from 'modules/common/helpers';
import { authHelper } from 'modules/auth/helpers';

import { Row, Col } from 'modules/bootstrap/components';
import { FormConditions } from 'modules/profile/components';
import { TextInput, Spinner, SiteLanguage } from 'modules/common/components';
import { ClickableLogo } from 'modules/auth/components';

import { LOCAL_STORAGE_VARS, URL_PARAMS } from 'modules/common/constants/enums';
import {
  CLIENT_CUSTOMIZATIONS,
  CLIENT_KEYS,
  ERROR_CODES,
} from 'modules/common/constants';

import authBg from 'images/two-column-page-bg-1.png';
import logoNinebarc from 'images/logo_ninebarc.svg';

interface SignUpPageProps {
  client?: string;
  token?: string;
}

function SignUpPage({ client, token }: SignUpPageProps) {
  const { t } = useTranslation(['common', 'auth']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const qryReferral = useQuery().get(URL_PARAMS.REFERRAL);

  const customization: ClientCustomization =
    CLIENT_CUSTOMIZATIONS[client || 'default']?.signup || {};

  const [user, setUser] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [pwValidity, setPwValidity] = useState({} as PasswordValidity);
  const isSignupSending = useSelector(
    (state: RootState) => state.loading.value
  );

  useEffect(() => {
    setPwValidity(authHelper.getPasswordValidity(user.password));
  }, [user.password]);

  const onChange = (field: string, value) => {
    const newUser = { ...user };

    newUser[field] = value;

    setUser(newUser);
  };

  const isValidRegisterForm = () => {
    const formErrors = {
      email: '',
      password: '',
    } as UserCredentials;

    if (!user.email) {
      formErrors.email = t('auth:email_required');
    } else if (!isEmail(user.email)) {
      formErrors.email = t('auth:email_not_valid');
    }

    formErrors.password = authHelper.getPasswordErrorMessages(user.password, t);

    setErrors(formErrors);

    return validationHelper.isEmptyErrorObject(formErrors);
  };

  const onSignUp = async (e) => {
    if (e) e.preventDefault();

    if (!isValidRegisterForm()) return;

    dispatch(setLoading(true));

    try {
      const browserLang = localStorage.getItem(
        LOCAL_STORAGE_VARS.SITE_LANGUAGE
      );
      const signUpData: Record<string, string> = {
        ...user,
        email: user.email.toLowerCase(),
      };

      if (browserLang) signUpData.language = browserLang;
      if (qryReferral) signUpData.referral = qryReferral;
      if (token) signUpData.token = token;

      const response: any = await dispatch(
        signUp(signUpData, { returnError: true })
      );
      const responseError = handleResponseError(response);

      if (responseError) {
        if (responseError === ERROR_CODES.USER_ALREADY_EXISTS) {
          setErrors({ ...errors, email: t(`errors:${responseError}`) });
        } else {
          setUser({ email: '', password: '' });
          reactToast.showError(t(`errors:${responseError}`));
        }
      } else {
        await dispatch(loginUser(user));

        const userProfile: any = await dispatch(getCurrentUser());
        if (browserLang && userProfile?.language !== browserLang) {
          await dispatch(setUserLanguage(browserLang));
        }
        if (window.location.pathname !== 'verify-email') {
          navigate('/verify-email');
        }
      }
    } catch (error) {
      reactToast.showError(error as string);
      setUser({ email: '', password: '' });
    }
    dispatch(setLoading(false));
  };

  return (
    <>
      <img
        src={customization?.bgImg || authBg}
        className="Decoration Decoration--authentication"
        alt="Authentication background"
      />
      <main className="t-bg-gamma-400 t-min-h-screen xs:t-p-3 lg:t-p-0">
        <Row className="t-p-3 lg:t-p-0 t-w-full">
          <Col lg={{ span: 6, offset: 6 }} md={{ span: 8, offset: 2 }}>
            <form
              onSubmit={onSignUp}
              className="Form t-pb-12 t-pt-5 md:t-py-12 md:t-px-16 md:t-max-w-sm"
            >
              <div
                className={`Form-logo ${customization?.headerClassName || ''}`}
              >
                <ClickableLogo customization={customization} />

                <SiteLanguage additionalClass="t-pb-0" />
              </div>

              <h1 className="Typography Typography--title u-pb-30 u-weight-600">
                {t(
                  `auth:${
                    customization?.customizedHeaderTitle
                      ? client
                      : CLIENT_KEYS.DEFAULT
                  }_signup_title`
                )}
              </h1>

              <p className="t-mb-8 typo-lato">
                {t(
                  `auth:${
                    customization?.customizedHeaderDescription
                      ? client
                      : CLIENT_KEYS.DEFAULT
                  }_signup_description`
                )}
              </p>

              <fieldset disabled={isSignupSending}>
                <TextInput
                  name="email"
                  label={t('auth:email_label_long')}
                  type="email"
                  value={user.email}
                  onChange={onChange}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange('email', e.target.value.trim())
                  }
                  placeholder={t('auth:email_placeholder')}
                  error={errors.email}
                  maxLength={300}
                />

                <TextInput
                  name="password"
                  label={t('common:password_label')}
                  type="password"
                  value={user.password}
                  onChange={onChange}
                  placeholder={t('auth:password_placeholder_registration')}
                  error={errors.password}
                />
              </fieldset>

              <FormConditions pwValidity={pwValidity} />

              <div className="Form-policy">
                <p className="text-main-sm">
                  {t('auth:signup_click_text')}&nbsp;
                  <a
                    href={`${config.websiteUrl}/terms`}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                    className="u-noUnderline u-color-jelly-bean"
                  >
                    {t('auth:signup_agreement')}
                  </a>
                  <span>{` ${t('common:and')} `}</span>
                  <a
                    href={
                      client === 'vbbb'
                        ? `${config.websiteUrl}/privacy-vbbb`
                        : `${config.websiteUrl}/privacy`
                    }
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                    className="u-noUnderline u-color-jelly-bean"
                  >
                    {t('auth:signup_privacy')}
                  </a>
                  .
                </p>
              </div>

              <button
                type="submit"
                disabled={isSignupSending}
                onSubmit={onSignUp}
                className={`button u-w-100 ${
                  customization?.customizedPrimaryButton
                    ? `${client}-button-primary`
                    : 'button-primary'
                }`}
                data-testid="signUpButton"
              >
                {isSignupSending && <Spinner className="t-h-5 t-w-5 t-mr-2" />}
                {t('register')}
              </button>

              <div className="u-pt-40 u-pb-40">
                <div className="Separator" />
              </div>
              <p className="Typography Typography--subTitle u-weight-600 t-pb-5">
                {t('auth:signup_have_acc')}
              </p>
              <div className="t-flex t-justify-between t-items-center">
                <Link
                  className={`button d-inline-flex ${
                    customization?.customizedPrimaryButton
                      ? `${client}-button-secondary`
                      : 'button-secondary'
                  }`}
                  to="/login"
                >
                  {t('sign_in')}
                </Link>
                {customization?.bottomNinebarcIcon && (
                  <div>
                    <p className="text-main-sm">{t('common:powered')}</p>
                    <div className="t-pb-1.5">
                      <img
                        className="t-h-6"
                        src={logoNinebarc}
                        alt="Ninebarc"
                      />
                    </div>
                  </div>
                )}
              </div>
            </form>
          </Col>
        </Row>
      </main>
    </>
  );
}

export default SignUpPage;
