import React from 'react';

import { config } from 'modules/common/helpers';

import logo from 'images/logo.svg';

interface ClickableLogoProps {
  linkTo?: string;
  logoClassName?: string;
  customization?: ClientCustomization;
}

export default function ClickableLogo({
  linkTo,
  logoClassName,
  customization,
}: ClickableLogoProps) {
  const url = linkTo || customization?.homePageUrl || config.websiteUrl;

  const navigateToHomePage = () => {
    window.open(url, '_blank');
  };

  return (
    <img
      src={customization?.logo || logo}
      alt="logo"
      className={`t-cursor-pointer ${customization?.logoClassName || ''} ${
        logoClassName || ''
      }`}
      onClick={navigateToHomePage}
    />
  );
}
