import React from 'react';
import { Accordion } from 'react-bootstrap';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconChevroneDown } from 'images/v-shaped-down.svg';
import { ReactComponent as IconChevroneUp } from 'images/v-shaped-up.svg';
import check24Logo from 'images/clientCustomizations/check24Logo.png';

const Check24CTACard = () => {
  const { t } = useTranslation(['common', 'assistant']);

  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const toggleShowCard = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  return (
    <div className="card t-overflow-hidden t-mt-3">
      <Accordion onSelect={toggleShowCard} defaultActiveKey="check24">
        <Accordion.Item eventKey="check24">
          <Accordion.Button as="div" className="t-cursor-pointer">
            <div className="t-w-full t-flex t-justify-between t-bg-delta-900 t-px-6 t-py-4">
              <h3 className="typo-gamma t-text-gamma-400">
                {t(`assistant:check24_card_title`)}
              </h3>

              <div className="t-outline-none t-p-1 t-flex t-items-center">
                {isCollapsed ? (
                  <IconChevroneDown className="t-text-gamma-400" />
                ) : (
                  <IconChevroneUp className="t-text-gamma-400" />
                )}
              </div>
            </div>
          </Accordion.Button>
          <Accordion.Body>
            <div className="t-bg-beta-50 t-flex t-justify-between t-items-stretch">
              <div className="t-hidden sm:t-flex sm:t-items-start xl:t-w-44">
                <img
                  className="t-my-5 t-pl-7"
                  src={check24Logo}
                  alt="Check24 Logo"
                />
              </div>
              <div className="t-p-3 t-flex-grow t-flex t-flex-col xl:t-flex-row xl:t-items-center xl:t-justify-between">
                <p className="typo-delta t-mb-2 xl:t-mb-0">
                  {t('assistant:check24_cta_text')}
                </p>
                <div className="t-flex t-justify-end t-items-center t-mr-6 xl:t-ml-2">
                  <a
                    href="https://www.check24.de/sterbegeldversicherung/?wpset=ninebarc_sgv"
                    className="button-primary t-bg-delta-700"
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    {t('plan:check24_widget_btn')}
                  </a>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Check24CTACard;
