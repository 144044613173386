import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { ReactComponent as IconRoundCheck } from 'images/icon-round-check.svg';

type FormConditionsNewProps = {
  pwValidity: PasswordValidity;
  customFont?: string;
};

const validConditions = [
  {
    name: 'oneLowercase',
    text: 'auth:password_validate_lower',
  },
  {
    name: 'oneSpecial',
    text: 'auth:password_validate_special',
  },
  {
    name: 'oneUppercase',
    text: 'auth:password_validate_upper',
  },
  {
    name: 'minLength',
    text: 'auth:password_validate_min',
  },
  {
    name: 'oneNumber',
    text: 'auth:password_validate_number',
  },
];

const FormConditionsNew = ({
  pwValidity,
  customFont = '',
}: FormConditionsNewProps) => {
  const { t } = useTranslation(['auth']);

  return (
    <div>
      <Row>
        {validConditions.map(({ name, text }) => (
          <Col xs="6" key={name}>
            <p
              className={`typo-theta t-flex t-mt-1 ${
                pwValidity[name] ? 't-text-beta-700' : ''
              } ${customFont}`}
            >
              <span>
                <IconRoundCheck
                  className={`t-mr-2 t-mt-0.5 ${
                    pwValidity[name] ? 't-text-alpha-600' : 't-text-beta-500'
                  }`}
                />
              </span>
              {t(text)}
            </p>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FormConditionsNew;
