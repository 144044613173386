import React from 'react';

import { useTranslation } from 'modules/common/helpers';
import { FormWatch } from 'modules/common/types';

interface ContactMethodSummaryProps {
  watch: FormWatch;
  phone: boolean;
  post: boolean;
}

const ContactMethodSummary = ({
  watch,
  phone,
  post,
}: ContactMethodSummaryProps) => {
  const { t } = useTranslation(['common', 'mcontacts']);
  const [gender, contactNow] = watch(['gender', 'contactNow']);

  const contactNowAfter = contactNow?.value === 'true' ? 'now_' : '';

  const contactGender = t(
    `mcontacts:contact_gender_${gender?.value || 'male'}`
  );
  let contactMethodText = contactGender;

  switch (true) {
    case phone && post:
      contactMethodText += t(
        `mcontacts:contacted_by_email_phone_post_${contactNowAfter}after_death`
      );
      break;
    case phone:
      contactMethodText += t(
        `mcontacts:contacted_by_email_phone_${contactNowAfter}after_death`
      );
      break;
    case post:
      contactMethodText += t(
        `mcontacts:contacted_by_email_post_${contactNowAfter}after_death`
      );
      break;
    default:
      contactMethodText += t(
        `mcontacts:contacted_by_email_${contactNowAfter}after_death`
      );
  }

  return (
    <div className="t-mt-2" data-testid="ContactMethodSummary">
      <p className="typo-zeta t-text-delta-700">{contactMethodText}</p>
    </div>
  );
};

export default ContactMethodSummary;
