import { DASHBOARD_TABLE_COLUMNS } from 'modules/common/constants/enums';

import { ASSET_CATEGORIES } from 'modules/estatePlan/constants';

import {
  DIGITAL_SUBTYPES,
  FINANCIAL_SUBTYPES,
  DEVICE_SUBTYPES,
  HEALTH_SUBTYPES,
  LEGAL_SUBTYPES,
  AFTERLIFE_SUBTYPES,
  INSURANCES_SUBTYPES,
} from 'modules/estatePlan/constants/enums';

export * from 'modules/dashboard/constants/dashboardTourSteps';

export const DEFAULT_FILE_ASSET_SIZE = 30;

export const DEFAULT_FILE_PHOTO_SIZE = 3;

/*
  This constant is being used for configuring the columns appearing in the Dashboars 'My Assets' table.
  By default the 'COMMON' configuration is applied (contains 3 columns: Description, File and Assigned to).
  However a custom configuration might be applied for:
    a) every asset's Category (Devices, Afterlife, etc) -
    please use a ASSET_CATEGORIES value as a key, e.g. 'healthCare'
    b) or even for each asset's Type of specific Category (like Afterlife > Last Messages)
      - please use [`${ASSET_CATEGORIES}_${SUBTYPES}`] format, e.g. 'afterLife_last message'
*/
export const DASHBOARD_TABLE_ROW_TYPES = {
  COMMON: [
    DASHBOARD_TABLE_COLUMNS.DESCRIPTION,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
  ],

  [ASSET_CATEGORIES.DEVICES]: [
    DASHBOARD_TABLE_COLUMNS.DESCRIPTION,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
  ],

  [`${ASSET_CATEGORIES.DEVICES}_${DEVICE_SUBTYPES.PHONE}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.DEVICES}_${DEVICE_SUBTYPES.COMPUTER}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.DEVICES}_${DEVICE_SUBTYPES.TABLET}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.DEVICES}_${DEVICE_SUBTYPES.OTHER}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],

  [`${ASSET_CATEGORIES.HEALTH}_${HEALTH_SUBTYPES.HEALTH_INSURANCE}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.SUBTYPE,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.HEALTH}_${HEALTH_SUBTYPES.CARE_INSURANCE}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.HEALTH}_${HEALTH_SUBTYPES.OTHER_HEALTH_INSURANCE}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.SUBTYPE,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.HEALTH}_${HEALTH_SUBTYPES.DONOR}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
    DASHBOARD_TABLE_COLUMNS.AVAILABLE_NOW,
  ],
  [`${ASSET_CATEGORIES.HEALTH}_${HEALTH_SUBTYPES.DECREE}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
    DASHBOARD_TABLE_COLUMNS.AVAILABLE_NOW,
  ],
  [`${ASSET_CATEGORIES.HEALTH}_${HEALTH_SUBTYPES.HCP}`]: [
    DASHBOARD_TABLE_COLUMNS.DESCRIPTION,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.AVAILABLE_NOW,
  ],
  [`${ASSET_CATEGORIES.LEGAL}_${LEGAL_SUBTYPES.POWER_OF_ATTORNEY}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.LEGAL}_${LEGAL_SUBTYPES.WILL}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.INSURANCE}_${INSURANCES_SUBTYPES.OTHER_INSURANCES}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.SUBTYPE,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.LEGAL}_${LEGAL_SUBTYPES.PERSONAL_DOCUMENTS}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.SUBTYPE,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.LEGAL}_${LEGAL_SUBTYPES.OTHER_LEGAL_DOCUMENT}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],

  [`${ASSET_CATEGORIES.DIGITAL}_${DIGITAL_SUBTYPES.PASSWORD}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.SUBTYPE,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.DIGITAL}_${DIGITAL_SUBTYPES.EMAIL}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.LOGIN,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.DIGITAL}_${DIGITAL_SUBTYPES.SOCIAL}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.LOGIN,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.DIGITAL}_${DIGITAL_SUBTYPES.CLOUD}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.LOGIN,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.DIGITAL}_${DIGITAL_SUBTYPES.SUBSCRIPTION}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.LOGIN,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.DIGITAL}_${DIGITAL_SUBTYPES.OTHER}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.LOGIN,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],

  [`${ASSET_CATEGORIES.FINANCIAL}_${FINANCIAL_SUBTYPES.BANK}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.SUBTYPE,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.FINANCIAL}_${FINANCIAL_SUBTYPES.WALLETS}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.LOGIN,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.FINANCIAL}_${FINANCIAL_SUBTYPES.LIFE_INSURANCE}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.PROVIDER,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.FINANCIAL}_${FINANCIAL_SUBTYPES.TRADING_PORTFOLIO}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.FINANCIAL}_${FINANCIAL_SUBTYPES.SAFE_DEPOSIT}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.FINANCIAL}_${FINANCIAL_SUBTYPES.LONG_TERM_ASSETS}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.SUBTYPE,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.FINANCIAL}_${FINANCIAL_SUBTYPES.ONGOING_CONTRACTS}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.SUBTYPE,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.FINANCIAL}_${FINANCIAL_SUBTYPES.OTHER}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],

  [`${ASSET_CATEGORIES.AFTERLIFE}_${AFTERLIFE_SUBTYPES.FUNERAL}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.AFTERLIFE}_${AFTERLIFE_SUBTYPES.MESSAGE}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.AFTERLIFE}_${AFTERLIFE_SUBTYPES.LIST_OF_CONTACTS}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
  [`${ASSET_CATEGORIES.AFTERLIFE}_${AFTERLIFE_SUBTYPES.DEATH_BENEFIT_INSURANCE}`]:
    [
      DASHBOARD_TABLE_COLUMNS.NAME,
      DASHBOARD_TABLE_COLUMNS.PROVIDER,
      DASHBOARD_TABLE_COLUMNS.FILE,
      DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
      DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
    ],
  [`${ASSET_CATEGORIES.AFTERLIFE}_${AFTERLIFE_SUBTYPES.OTHER_WISHES}`]: [
    DASHBOARD_TABLE_COLUMNS.NAME,
    DASHBOARD_TABLE_COLUMNS.FILE,
    DASHBOARD_TABLE_COLUMNS.ASSIGNED_TO,
    DASHBOARD_TABLE_COLUMNS.LAST_MODIFIED,
  ],
};
