import ASSET_CATEGORIES from 'modules/estatePlan/constants/assetCategories';
import * as icons from 'modules/estatePlan/constants/fieldIcons';

const A = ASSET_CATEGORIES;

export enum FIELD_TYPE_KEYS {
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  ADDRESS = 'address',
  NUMBERS = 'numbers',
  PASSWORD = 'password',
  PROVIDER = 'provider',
  COMMENTS = 'comments',
  SUBTYPE = 'subtype',
  USERNAME = 'username',
  IBAN = 'iban',
  ONLINE = 'online',
  TWO_AUTH = 'twoFactorAuth',
  DATE = 'date',
  LINK = 'link',
  CONTACTS = 'contactIds',
  DOCUMENT = 'document',
  PUK = 'puk',
  PIN = 'pin',
  INSURANCE_NUMBER = 'insuranceNumber',
  LINK_TO = 'linkTo',
  ACTION = 'action',
  LOCATION = 'location',
  LICENSE = 'license',
  OTHER_ADDRESS = 'otherAddress',
  REGULATION = 'regulation',
  STREET_ADDRESS = 'streetAddress',
  ZIP = 'zip',
  CITY = 'city',
  COUNTRY = 'country',
  FINANCIAL_DATA = 'financialData',
  SHARE = 'share',
  PAYMENT_METHOD = 'paymentMethod',
  PAYMENT_FREQUENCY = 'paymentFrequency',
  CARD_NUMBER = 'cardNumber',
  PERSON = 'person',
  CREDIT_CARD_INFO = 'cardInfo',
  EXPIRATION_DATE = 'expirationDate',
  RELATED_2FA_ASSET_ID = 'related2FAAssetId',
}

const FK = FIELD_TYPE_KEYS;

export const FIELDS: AssetFields = {
  [FK.NAME]: {
    type: FK.NAME,
    label: `fieldLibrary:label_${FK.NAME}`,
    description: `fieldLibrary:description_${FK.NAME}`,
    example: `fieldLibrary:example_${FK.NAME}`,
    image: icons.imgName,
    required: true,
  },
  [FK.CONTACTS]: {
    type: FK.CONTACTS,
    label: `fieldLibrary:label_${FK.CONTACTS}`,
    description: `fieldLibrary:description_${FK.CONTACTS}`,
    example: `fieldLibrary:example_${FK.CONTACTS}`,
    image: icons.imgAssignTo,
    required: true,
  },
  [FK.EMAIL]: {
    type: FK.EMAIL,
    label: `fieldLibrary:label_${FK.EMAIL}`,
    description: `fieldLibrary:description_${FK.EMAIL}`,
    example: `fieldLibrary:example_${FK.EMAIL}`,
    image: icons.imgPersonalMail,
    required: true,
  },
  [FK.PHONE]: {
    type: FK.PHONE,
    label: `fieldLibrary:label_${FK.PHONE}`,
    description: `fieldLibrary:description_${FK.PHONE}`,
    example: `fieldLibrary:example_${FK.PHONE}`,
    image: icons.imgPhone,
  },
  [FK.ADDRESS]: {
    type: FK.ADDRESS,
    label: `fieldLibrary:label_${FK.ADDRESS}`,
    description: `fieldLibrary:description_${FK.ADDRESS}`,
    example: `fieldLibrary:example_${FK.ADDRESS}`,
    image: icons.imgAddress,
  },
  [FK.NUMBERS]: {
    type: FK.NUMBERS,
    label: `fieldLibrary:label_${FK.NUMBERS}`,
    description: `fieldLibrary:description_${FK.NUMBERS}`,
    example: `fieldLibrary:example_${FK.NUMBERS}`,
    image: icons.imgNumbers,
  },
  [FK.PASSWORD]: {
    type: FK.PASSWORD,
    label: `fieldLibrary:label_${FK.PASSWORD}`,
    description: `fieldLibrary:description_${FK.PASSWORD}`,
    example: `fieldLibrary:example_${FK.PASSWORD}`,
    image: icons.imgPassword,
  },
  [FK.PROVIDER]: {
    type: FK.PROVIDER,
    label: `fieldLibrary:label_${FK.PROVIDER}`,
    description: `fieldLibrary:description_${FK.PROVIDER}`,
    example: `fieldLibrary:example_${FK.PROVIDER}`,
    image: icons.imgProviders,
  },
  [FK.COMMENTS]: {
    type: FK.COMMENTS,
    label: `fieldLibrary:label_${FK.COMMENTS}`,
    description: `fieldLibrary:description_${FK.COMMENTS}`,
    example: `fieldLibrary:example_${FK.COMMENTS}`,
    image: icons.imgComment,
  },
  [FK.SUBTYPE]: {
    type: FK.SUBTYPE,
    label: `fieldLibrary:label_${FK.SUBTYPE}`,
    description: `fieldLibrary:description_${FK.SUBTYPE}`,
    example: `fieldLibrary:example_${FK.SUBTYPE}`,
    image: icons.imgSubtype,
  },
  [FK.DOCUMENT]: {
    type: FK.DOCUMENT,
    label: `fieldLibrary:label_${FK.DOCUMENT}`,
    description: `fieldLibrary:description_${FK.DOCUMENT}`,
    example: `fieldLibrary:example_${FK.DOCUMENT}`,
    image: icons.imgFile,
  },
  [FK.USERNAME]: {
    type: FK.USERNAME,
    label: `fieldLibrary:label_${FK.USERNAME}`,
    description: `fieldLibrary:description_${FK.USERNAME}`,
    example: `fieldLibrary:example_${FK.USERNAME}`,
    image: icons.imgUserName,
  },
  [FK.IBAN]: {
    type: FK.IBAN,
    label: `fieldLibrary:label_${FK.IBAN}`,
    description: `fieldLibrary:description_${FK.IBAN}`,
    example: `fieldLibrary:example_${FK.IBAN}`,
    image: icons.imgIBAN,
  },
  [FK.ONLINE]: {
    type: FK.ONLINE,
    label: `fieldLibrary:label_${FK.ONLINE}`,
    description: `fieldLibrary:description_${FK.ONLINE}`,
    example: `fieldLibrary:example_${FK.ONLINE}`,
    image: icons.imgOnline,
  },
  [FK.TWO_AUTH]: {
    type: FK.TWO_AUTH,
    label: `fieldLibrary:label_${FK.TWO_AUTH}`,
    description: `fieldLibrary:description_${FK.TWO_AUTH}`,
    example: `fieldLibrary:example_${FK.TWO_AUTH}`,
    image: icons.img2FA,
  },
  [FK.DATE]: {
    type: FK.DATE,
    label: `fieldLibrary:label_${FK.DATE}`,
    description: `fieldLibrary:description_${FK.DATE}`,
    example: `fieldLibrary:example_${FK.DATE}`,
    image: icons.imgDate,
  },
  [FK.LINK]: {
    type: FK.LINK,
    label: `fieldLibrary:label_${FK.LINK}`,
    description: `fieldLibrary:description_${FK.LINK}`,
    example: `fieldLibrary:example_${FK.LINK}`,
    image: icons.imgLink,
  },
  [FK.INSURANCE_NUMBER]: {
    type: FK.INSURANCE_NUMBER,
    label: `fieldLibrary:label_${FK.INSURANCE_NUMBER}`,
    description: `fieldLibrary:description_${FK.INSURANCE_NUMBER}`,
    example: `fieldLibrary:example_${FK.INSURANCE_NUMBER}`,
    image: icons.imgInsurance,
  },
  [FK.PUK]: {
    type: FK.PUK,
    label: `fieldLibrary:label_${FK.PUK}`,
    description: `fieldLibrary:description_${FK.PUK}`,
    example: `fieldLibrary:example_${FK.PUK}`,
    image: icons.imgPassword, // TODO: change me
  },
  [FK.PIN]: {
    type: FK.PIN,
    label: `fieldLibrary:label_${FK.PIN}`,
    description: `fieldLibrary:description_${FK.PIN}`,
    example: `fieldLibrary:example_${FK.PIN}`,
    image: icons.imgPassword, // TODO: change me
  },
  [FK.LOCATION]: {
    type: FK.LOCATION,
    label: `fieldLibrary:label_${FK.LOCATION}`,
    description: `fieldLibrary:description_${FK.LOCATION}`,
    example: `fieldLibrary:example_${FK.LOCATION}`,
    image: icons.imgLocation,
  },
  [FK.LICENSE]: {
    type: FK.LICENSE,
    label: `fieldLibrary:label_${FK.LICENSE}`,
    description: `fieldLibrary:description_${FK.LICENSE}`,
    example: `fieldLibrary:example_${FK.LICENSE}`,
    image: icons.imgLicensePlate,
  },
  [FK.OTHER_ADDRESS]: {
    type: FK.OTHER_ADDRESS,
    label: `fieldLibrary:label_${FK.OTHER_ADDRESS}`,
    description: `fieldLibrary:description_${FK.OTHER_ADDRESS}`,
    example: `fieldLibrary:example_${FK.OTHER_ADDRESS}`,
    image: icons.imgOtherAddress,
  },
  [FK.REGULATION]: {
    type: FK.REGULATION,
    label: `fieldLibrary:label_${FK.REGULATION}`,
    description: `fieldLibrary:description_${FK.REGULATION}`,
    example: `fieldLibrary:example_${FK.REGULATION}`,
    image: icons.imgRemainder,
  },
  [FK.PERSON]: {
    type: FK.PERSON,
    label: `fieldLibrary:label_${FK.PERSON}`,
    description: `fieldLibrary:description_${FK.PERSON}`,
    example: `fieldLibrary:example_${FK.PERSON}`,
    image: icons.imgPerson,
  },
  [FK.FINANCIAL_DATA]: {
    type: FK.FINANCIAL_DATA,
    label: `fieldLibrary:label_${FK.FINANCIAL_DATA}`,
    description: `fieldLibrary:description_${FK.FINANCIAL_DATA}`,
    example: `fieldLibrary:example_${FK.FINANCIAL_DATA}`,
    image: icons.imgFinancial,
  },
};

const F = FIELDS;

export const FIELD_LIBRARY = {
  [A.DEVICES]: [
    F[FK.EMAIL],
    F[FK.PHONE],
    F[FK.ADDRESS],
    F[FK.NUMBERS],
    F[FK.PASSWORD],
    F[FK.PROVIDER],
    F[FK.COMMENTS],
    F[FK.DOCUMENT],
    F[FK.USERNAME],
    F[FK.DATE],
    F[FK.LINK],
    F[FK.PUK],
    F[FK.PIN],
  ],
  [A.DIGITAL]: [
    F[FK.EMAIL],
    F[FK.PHONE],
    F[FK.ADDRESS],
    F[FK.NUMBERS],
    F[FK.PASSWORD],
    F[FK.PROVIDER],
    F[FK.COMMENTS],
    F[FK.DOCUMENT],
    F[FK.USERNAME],
    F[FK.DATE],
    F[FK.LINK],
  ],
  [A.FINANCIAL]: [
    F[FK.EMAIL],
    F[FK.PHONE],
    F[FK.ADDRESS],
    F[FK.NUMBERS],
    F[FK.PASSWORD],
    F[FK.PROVIDER],
    F[FK.COMMENTS],
    F[FK.DOCUMENT],
    F[FK.USERNAME],
    F[FK.DATE],
    F[FK.LINK],
    F[FK.IBAN],
    F[FK.ONLINE],
    F[FK.TWO_AUTH],
    F[FK.FINANCIAL_DATA],
  ],
  [A.HEALTH]: [
    F[FK.EMAIL],
    F[FK.PHONE],
    F[FK.ADDRESS],
    F[FK.NUMBERS],
    F[FK.PASSWORD],
    F[FK.PROVIDER],
    F[FK.INSURANCE_NUMBER],
    F[FK.COMMENTS],
    F[FK.DOCUMENT],
    F[FK.DATE],
    F[FK.LINK],
  ],
  [A.AFTERLIFE]: [
    F[FK.EMAIL],
    F[FK.PHONE],
    F[FK.ADDRESS],
    F[FK.NUMBERS],
    F[FK.PASSWORD],
    F[FK.COMMENTS],
    F[FK.DOCUMENT],
    F[FK.DATE],
    F[FK.LINK],
  ],
  [A.LEGAL]: [
    F[FK.EMAIL],
    F[FK.PHONE],
    F[FK.ADDRESS],
    F[FK.NUMBERS],
    F[FK.PASSWORD],
    F[FK.PROVIDER],
    F[FK.COMMENTS],
    F[FK.DOCUMENT],
    F[FK.DATE],
    F[FK.LINK],
    F[FK.PERSON],
  ],
  [A.INSURANCE]: [
    F[FK.PROVIDER],
    F[FK.INSURANCE_NUMBER],
    F[FK.COMMENTS],
    F[FK.DOCUMENT],
    F[FK.NAME],
    F[FK.ADDRESS],
    F[FK.PHONE],
    F[FK.EMAIL],
    F[FK.NUMBERS],
    F[FK.ONLINE],
  ],
  [A.CONTACTS]: [
    F[FK.NAME],
    F[FK.ADDRESS],
    F[FK.PHONE],
    F[FK.EMAIL],
    F[FK.COMMENTS],
    F[FK.DOCUMENT],
    F[FK.PERSON],
    F[FK.NUMBERS],
  ],
};
