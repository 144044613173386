export const SUCCESS_CODES = {
  ASSET_DELETED: 'assetDeleted',
  CONTACT_DELETED: 'contactDeleted',
  SUCCESSFUL_SIGNUP: 'successfulSignup',
  VERIFICATION_EMAIL: 'verificationEmail',
  RESET_PASSWORD_REQUEST: 'resetPasswordRequest',
  TFA_ENABLED: 'tfaEnabled',
  TFA_DISABLED: 'tfaDisabled',
  CODE_SENT: 'codeSent',
};

export const ERROR_CODES = {
  ASSET_ALREADY_EXISTS: 'assetAlreadyExists',
  ASSET_NOT_FOUND: 'assetNotFound',
  ASSET_TYPE_ALREADY_EXISTS: 'assetTypeAlreadyExists',
  BENEFICIARY_WRONG_CREDENTIALS: 'beneficiaryWrongCredentials',
  CLIENT_ALREADY_EXISTS: 'clientAlreadyExists',
  CONTACT_ALREADY_EXISTS: 'contactAlreadyExists',
  CONTACT_MAX_LIMIT: 'contactMaxLimit',
  CONTACT_NOT_FOUND: 'contactNotFound',
  ENCRYPTED_ASSET: 'encryptedAsset',
  EXPIRED_TOKEN: 'expiredToken',
  FILE_NOT_FOUND: 'fileNotFound',
  INTERNAL_ERROR: 'internalError',
  INVALID_EMAIL: 'invalidEmail',
  INVALID_FILE: 'invalidFile',
  INVALID_TOKEN: 'invalidToken',
  KEY_HOLDER_ALREADY_EXISTS: 'keyHolderAlreadyExists',
  KEY_HOLDER_NOT_NEEDED: 'keyHolderNotNeeded',
  MISSING_INPUT: 'missingInput',
  NOT_ALLOWED: 'notAllowed',
  NOT_AUTHORIZED: 'notAuthorized',
  NOT_VERIFIED: 'notVerified',
  PASSWORD_NOT_MATCHING: 'passwordNotMatching',
  PAYMENT_NEEDED: 'paymentNeeded',
  PRODUCT_ALREADY_EXISTS: 'productAlreadyExists',
  PROFILE_NOT_COMPLETE: 'profileNotComplete',
  RESOURCE_CONFLICT: 'resourceConflict',
  USER_ALREADY_EXISTS: 'userAlreadyExists',
  WRONG_CREDENTIALS: 'wrongCredentials',
  WRONG_TFA: 'wrongTfa',
  PARSING_ERROR: 'parsingError',
};
