import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { noop } from 'lodash';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as MenuIcon } from 'images/icon-menu.svg';
import { ReactComponent as IconEdit } from 'images/icon-edit.svg';
import { ReactComponent as DeleteIcon } from 'images/icon-delete.svg';
import iconUpload from 'images/upload.svg';

export type DotsDropdownProps = {
  onEdit?: () => void;
  onDelete?: () => void;
  onUploadNewReport?: () => void;
};

export default function DotsDropdown({
  onEdit,
  onDelete,
  onUploadNewReport,
}: DotsDropdownProps) {
  const { t } = useTranslation(['common', 'plan']);

  const dropdownItemProps = (onClickFn) => {
    return {
      onClick: onClickFn || noop,
      disabled: !onClickFn,
    };
  };

  const editItemProps = dropdownItemProps(onEdit);
  const deleteItemProps = dropdownItemProps(onDelete);

  return (
    <div className="Character-menu" data-testid="DotsDropdown">
      <Dropdown
        onToggle={(_, e) => {
          if (
            e?.originalEvent?.stopPropagation &&
            typeof e.originalEvent.stopPropagation === 'function'
          )
            e.originalEvent.stopPropagation();
        }}
        className="dropdown--settings"
      >
        <Dropdown.Toggle
          variant="success"
          id="dropdown-settings"
          className="u-pointer t-outline-none"
          data-testid="DotsDropdown-toggle"
        >
          <MenuIcon className="t-text-beta-200" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="t-m-0">
          <Dropdown.Item {...editItemProps}>
            <IconEdit className="dropdown-icon t-text-beta-200" />
            {t('common:edit_label')}
          </Dropdown.Item>

          <Dropdown.Item {...deleteItemProps}>
            <DeleteIcon className="dropdown-icon dropdown-icon--delete t-text-epsilon-400" />
            {t('common:delete_label')}
          </Dropdown.Item>

          {onUploadNewReport && (
            <>
              <Dropdown.Item onClick={onUploadNewReport}>
                <img
                  src={iconUpload}
                  className="dropdown-icon button-icon-amazon"
                  alt=""
                  role="presentation"
                />
                {t('common:upload_new_report_label')}
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
