import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import isEmail from 'validator/lib/isEmail';

import { RootState } from 'store/reducers';
import { setLoading } from 'store/actions/commonActions';
import {
  loginUser,
  getCurrentUser,
  smsTwoAuthAuthenticate,
  googleTwoAuthAuthenticate,
  setUserLanguage,
} from 'store/actions/userActions';
import { getFamilyAccounts } from 'store/actions/familyAccountActions';
import { validationHelper, useTranslation } from 'modules/common/helpers';

import {
  TwoAuthEnterGoogleValidationCodeModal,
  TwoAuthEnterSMSConfirmationCodeModal,
} from 'modules/twoAuth/components';
import {
  TextInput,
  SiteLanguage,
  Button,
  PlatformFeatureInfoSection,
} from 'modules/common/components';
import { Col, Row } from 'modules/bootstrap/components';
import {
  AuthFooter,
  BeneAccessLink,
  ClickableLogo,
  LoginFooter,
} from 'modules/auth/components';

import { LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';
import { commonFeatures } from 'modules/auth/constants';

import imgRectangle from 'images/img-auth-rectangle.png';
import { setModal } from 'store/actions/modalActions';
import { MODALS } from 'modules/common/constants';

const LoginPage = () => {
  const { t } = useTranslation(['common', 'auth']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user, setUser] = useState<UserCredentials>({
    email: '',
    password: '',
  });
  const isLoginSending = useSelector((state: RootState) => state.loading.value);
  const [showSMSConfirmationCodeModal, setShowSMSConfirmationCodeModal] =
    useState(false);
  const [showGoogleConfirmationCodeModal, setShowGoogleConfirmationCodeModal] =
    useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [twoFaToken, setTwoFaToken] = useState('');
  const prevPath = useSelector((state: RootState) => state.common.pathname);

  const onChange = (field: string, value) => {
    const newUser = { ...user };

    newUser[field] = value;

    setUser(newUser);
  };

  const loginFormIsValid = () => {
    const formErrors = {
      email: '',
      password: '',
    };

    if (!user.email) {
      formErrors.email = t('auth:email_required');
    } else if (!isEmail(user.email)) {
      formErrors.email = t('auth:email_not_valid');
    }

    if (!user.password) {
      formErrors.password = t('auth:password_required');
    }

    setErrors(formErrors);

    return validationHelper.isEmptyErrorObject(formErrors);
  };

  const goToSite = async () => {
    const userProfile: any = await dispatch(getCurrentUser());
    const browserLang = localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE);

    if (browserLang && userProfile?.language !== browserLang) {
      await dispatch(setUserLanguage(browserLang));
    }

    const familyAccounts = (await dispatch(getFamilyAccounts())) || [];

    if (familyAccounts.length > 1) {
      await dispatch(
        setModal({
          name: MODALS.SUB_ACCOUNT_SELECTION,
          props: {
            familyAccounts: familyAccounts as any as FamilyAccount[],
          },
        })
      );
    }

    navigate(prevPath || '/');
  };

  const handle2FAuthenticate = async () => {
    await goToSite();
  };

  const login = async (e) => {
    if (e) e.preventDefault();

    if (!loginFormIsValid()) return;

    dispatch(setLoading(true));
    const response: any = await dispatch(
      loginUser({ ...user, email: user.email.toLowerCase() })
    );

    if (response?.smsTfaEnabled) {
      setTwoFaToken(response?.accessToken || '');
      setShowSMSConfirmationCodeModal(true);
      setPhoneNumber(response.phone!);
    } else if (response?.tfaEnabled) {
      setTwoFaToken(response?.accessToken || '');
      setShowGoogleConfirmationCodeModal(true);
    } else if (!response) {
      setUser({ ...user, password: '' });
    } else {
      await goToSite();
    }
    dispatch(setLoading(false));
  };

  const handleRenewAccessToken = (accessToken: string) => {
    setTwoFaToken(accessToken);
  };

  return (
    <>
      <ReactTooltip effect="solid" place="top" />

      {showSMSConfirmationCodeModal && (
        <TwoAuthEnterSMSConfirmationCodeModal
          onCancel={() => setShowSMSConfirmationCodeModal(false)}
          onSuccess={handle2FAuthenticate}
          requestActionFn={smsTwoAuthAuthenticate}
          phone={phoneNumber}
          showGetNewCodeButton={false}
          loginData={user}
          twoFaToken={twoFaToken}
          renewAccessToken={handleRenewAccessToken}
        />
      )}

      {showGoogleConfirmationCodeModal && (
        <TwoAuthEnterGoogleValidationCodeModal
          onCancel={() => setShowGoogleConfirmationCodeModal(false)}
          onSuccess={handle2FAuthenticate}
          requestActionFn={googleTwoAuthAuthenticate}
          twoFaToken={twoFaToken}
        />
      )}
      <Row className="lg:t-p-0 t-min-h-screen t-w-full t-m-0 t-relative">
        <div className="t-absolute t-right-0 t-top-36 t-z-50 t-hidden lg:t-inline-block">
          <BeneAccessLink extraClassName="t-rounded-r-none" />
        </div>
        <Col
          md={{ span: 6, offset: 3 }}
          lg={{ span: 10, offset: 1 }}
          className="t-p-0 t-z-40"
        >
          <div className="t-hidden md:t-flex t-justify-between t-mt-6 lg:t-mt-10 t-px-3">
            <ClickableLogo />
            <div className="t-flex t-items-center">
              <div className="t-hidden lg:t-inline-block">
                <AuthFooter />
              </div>
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>
          </div>

          <div className="md:t-flex t-flex-col t-items-center t-mt-4 lg:t-mt-16 t-px-3 t-mb-16">
            <div className="md:t-hidden t-flex t-justify-between">
              <ClickableLogo />
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>

            <BeneAccessLink extraClassName="lg:t-hidden t-my-5 lg:t-mt-16 t-w-full" />

            <div className="card t-shadow-lg t-bg-beta-50 t-p-6 lg:t-px-12 lg:t-pt-12 t-w-full lg:t-w-auto">
              <h2 className="typo-beta t-mb-5">{t('auth:login_form_label')}</h2>
              <form
                onSubmit={login}
                className="Form t-w-full lg:t-w-96 t-bg-transparent"
              >
                <fieldset disabled={isLoginSending}>
                  <TextInput
                    name="email"
                    label={t('auth:email_label_long')}
                    type="email"
                    value={user.email}
                    onChange={onChange}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChange('email', e.target.value.trim())
                    }
                    placeholder={t('auth:email_placeholder')}
                    error={errors.email}
                    maxLength={300}
                  />

                  <TextInput
                    name="password"
                    label={t('common:password_label')}
                    type="password"
                    value={user.password}
                    onChange={onChange}
                    placeholder={t('auth:password_placeholder')}
                    error={errors.password}
                  />
                </fieldset>

                <div className="t-flex t-justify-end t-pt-5">
                  <Button
                    testId="loginButton"
                    loading={isLoginSending}
                    type="submit"
                    className="t-whitespace-nowrap"
                    onClick={login}
                  >
                    <span className="u-pl-10 u-pr-10">
                      {t('auth:login_button_text')}
                    </span>
                  </Button>
                </div>
                <div className="t-flex t-justify-end t-mt-5">
                  <Link
                    className="typo-epsilon t-text-alpha-600 t-whitespace-nowrap"
                    to="/password-forgot"
                  >
                    {t('auth:forgot_link')}
                  </Link>
                </div>
              </form>
            </div>

            <p className="typo-theta t-mt-3 t-pb-4 t-text-beta-700 t-flex t-justify-center md:t-inline">
              {t('auth:no_account')}
              <Link
                className="t-text-alpha-600 t-ml-1 t-font-semibold"
                to="/register"
              >
                {t('auth:register_link_text')}
              </Link>
            </p>

            <div className="t-my-5 lg:t-mt-16 t-flex t-justify-center md:t-inline">
              <PlatformFeatureInfoSection features={commonFeatures} />
            </div>

            <div className="lg:t-hidden t-mt-16">
              <AuthFooter />
            </div>
          </div>

          <div className="t-absolute t-bottom-0 t-w-full t-flex t-justify-center md:t-inline">
            <LoginFooter />
          </div>
        </Col>
      </Row>
      <div className="t-fixed t-w-screen t-object-cover t-h-3/4 t-bottom-0">
        <img
          src={imgRectangle}
          className="t-w-full t-h-full"
          alt="Rectangle background"
        />
      </div>
    </>
  );
};

export default LoginPage;
