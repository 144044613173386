import { Col, Row } from 'modules/bootstrap/components';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { AgentInfo } from 'modules/agent/components';

import { GENDERS } from 'modules/common/constants/enums';

import { ReactComponent as IconRoundCheck } from 'images/icon-round-check.svg';
import imgAgent from 'images/agents/avatar_agent_1.svg';
import imgCompany from 'images/agents/avatar_agent_company.svg';

export default function AgentWidgetDashboard() {
  const { t } = useTranslation(['common', 'dashboard']);

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const agentInfo = currentUser.agent;

  if (!agentInfo) return null;

  const defaultAvatar =
    !agentInfo.name && !agentInfo.surname ? imgCompany : imgAgent;

  return (
    <div
      className="card t-pl-2.5 t-overflow-hidden t-mb-16 md:t-mb-0"
      data-tour="dashboard_agentWidget"
      data-testid="AgentWidgetDashboard"
    >
      <Row className="t-w-full">
        <Col xl={6} className="t-p-0">
          <div className="t-p-5 t-bg-alpha-200">
            <h1 className="typo-alpha t-whitespace-nowrap t-mr-2 t-w-full sm:t-w-auto">
              {t(
                `agents:my_agent_widget_title_${
                  agentInfo.gender || GENDERS.OTHER
                }`
              )}
            </h1>

            <p className="typo-epsilon t-text-beta-500 t-mt-3 t-flex">
              {t(
                `agents:my_agent_widget_info_1_${
                  agentInfo.gender || GENDERS.OTHER
                }`
              )}
            </p>
            <p className="typo-epsilon t-text-beta-500 t-mt-3 t-flex">
              <span>
                <IconRoundCheck className="t-mr-2 t-mt-0.5 t-w-3.5 t-h-3.5" />
              </span>
              <span>
                {t(
                  `agents:my_agent_widget_info_2_${
                    agentInfo.gender || GENDERS.OTHER
                  }`
                )}
              </span>
            </p>
            <p className="typo-epsilon t-text-beta-500 t-mt-3 t-flex">
              <span>
                <IconRoundCheck className="t-mr-2 t-mt-0.5 t-w-3.5 t-h-3.5" />
              </span>
              <span>
                {t(
                  `agents:my_agent_widget_info_3_${
                    agentInfo.gender || GENDERS.OTHER
                  }`
                )}
              </span>
            </p>
            <p className="typo-epsilon t-text-beta-500 t-mt-3 t-flex">
              <span>
                <IconRoundCheck className="t-mr-2 t-mt-0.5 t-w-3.5 t-h-3.5" />
              </span>
              <span>
                {t(
                  `agents:my_agent_widget_info_4_${
                    agentInfo.gender || GENDERS.OTHER
                  }`
                )}
              </span>
            </p>
          </div>
        </Col>
        <Col xl={6} className="t-p-5">
          <div className="t-flex t-justify-center">
            <div className="t-flex t-items-center t-w-full">
              <img
                src={agentInfo.profilePicture || defaultAvatar}
                alt="Agent"
                className="t-mr-2 t-w-20 t-h-20 t-rounded-full t-object-cover"
              />
              <AgentInfo
                agentInfo={agentInfo}
                className="t-ml-4 md:t-truncate"
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
