import React from 'react';

import { Spinner } from 'modules/common/components';

interface ButtonProps {
  children: string | React.ReactNode | React.ReactNode[];
  category?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'ghost'
    | 'link'
    | 'gray'
    | 'dangerous'
    | 'danger';
  className?: string;
  type?: 'button' | 'submit';
  onClick?: (...arg) => Promise<void> | void;
  disabled?: boolean;
  loading?: boolean;
  testId?: string;
  spinnerClassName?: string;
  dataTip?: string;
  dataTipDisable?: boolean;
  ref?: React.LegacyRef<HTMLButtonElement>;
  dataFor?: string;
}

const CommonButton = ({
  children,
  disabled,
  loading,
  onClick,
  testId,
  spinnerClassName,
  dataTip,
  dataTipDisable,
  dataFor,
  ref,
  category = 'primary',
  type = 'button',
  className = '',
}: ButtonProps) => {
  return (
    <button
      ref={ref}
      type={type}
      className={`button-${category} ${className}`}
      disabled={disabled || loading}
      onClick={onClick}
      data-testid={testId || 'CommonButton'}
      data-tip={dataTip}
      data-tip-disable={dataTipDisable}
      data-for={dataFor}
    >
      {loading && (
        <Spinner className={spinnerClassName || 't-h-5 t-w-5 t-mr-2'} />
      )}
      {children}
    </button>
  );
};

export default CommonButton;
