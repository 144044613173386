import {
  LOAD_PLAN_PROGRESS,
  SET_INTRODUCTION_DONE,
} from 'store/types/planActionTypes';
import helper from 'store/reducers/reducerHelper';
import initialState from 'store/reducers/initialState';

const userReducer = (state = initialState.plan, action) => {
  return helper.handleActions(state, action, {
    [LOAD_PLAN_PROGRESS](state, payload) {
      state.progress = payload.progress as PlanProgressDTO;
    },
    [SET_INTRODUCTION_DONE](state) {
      state.progress = {
        ...state.progress,
        overall: state.progress.overall + 0.1,
        introduction: 1,
      };
    },
  });
};

export default userReducer;
