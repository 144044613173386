import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';

export interface InfoTooltipProps {
  tipText: string;
  title?: string;
  className?: string;
}

const InfoTooltip = ({ tipText, title, className }: InfoTooltipProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div className={`Tooltip ${className ? className : ''}`} data-tip={tipText}>
      <IconInfo role="presentation" className="t-inline t-text-delta-700" />
      {title && <span className="tooltipTitle">&nbsp;{title}</span>}
    </div>
  );
};

export default InfoTooltip;
