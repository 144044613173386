import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { camelCase, isEmpty } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from 'store/reducers';
import {
  addAsset,
  editAsset,
  uploadToAsset,
  uploadToNewAsset,
} from 'store/actions/assetActions';
import { addAssetType } from 'store/actions/assetTypeActions';
import { setModal } from 'store/actions/modalActions';
import { updateRecommendation } from 'store/actions/recommendationsActions';
import { setIsShowEditAssetForm } from 'store/actions/layoutActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import {
  config,
  reactToast,
  isAllowedToken,
  handleResponseError,
  customHooks,
  useTranslation,
} from 'modules/common/helpers';
import {
  checkAllContactedContacts,
  checkContactListChanged,
  getAllMissingInfoContacts,
  makeFormDataObj,
  makeSubmitData,
  getAssetFormGlobalModalProps,
  MODAL_TYPES,
} from 'modules/asset/helpers';
import { getSelectOptionsContacts } from 'modules/contact/helpers';

import {
  AssetFormContactInfoWarning,
  AssetFormFieldSelector,
  AssetFormOnGoingCosts,
} from 'modules/asset/components';
import { Button } from 'modules/common/components';
import { Checkbox } from 'modules/elements/components';
import { SelectContactInput } from 'modules/myContacts/components';

import {
  ASSET_CATEGORIES,
  ASSET_TYPE_KEYS,
} from 'modules/estatePlan/constants';
import {
  FINANCIAL_SUBTYPES,
  LEGAL_SUBTYPES,
} from 'modules/estatePlan/constants/enums';
import { FIELD_TYPE_KEYS } from 'modules/estatePlan/constants/fieldLibrary';
import { HEALTH_CARE_ASSET_TYPES } from 'modules/estatePlan/constants/assetTypeKeys';
import { ASSET_TYPES_DOC_GEN } from 'modules/estatePlan/constants/assetTypeWithDocGen';
import { ERROR_CODES, MODALS } from 'modules/common/constants';
import { REDIRECT_ACTIONS } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';
import { ReactComponent as IconShowEye } from 'images/icon-show-eye.svg';
import { ReactComponent as IconArrowRight } from 'images/chevron-next.svg';
import { ReactComponent as IconPOA } from 'images/initial-assessment/imgBankingPowerOfAttorney.svg';

export interface AssetFormProps {
  assetType: AssetTypeDTO;
  asset?: AssetDTO;
  assetCategory: string;
  onHideForm: () => void;
  preselectedSubtype?: string;
  recommendation?: RecommendationDTO;
}

export default function AssetForm({
  assetType,
  assetCategory,
  preselectedSubtype,
  onHideForm,
  asset = {} as AssetDTO,
  recommendation,
}: AssetFormProps) {
  const { t } = useTranslation(['common', 'mcontacts', 'plan']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,

    formState: { isDirty, errors },
  } = useForm();

  const category = camelCase(assetCategory);
  // for highlight a field on redirect
  const dataOnRedirect =
    useSelector((state: RootState) => state.temporaryField.value) || {};
  const assistantProgress = useSelector(
    (state: RootState) => state.assistant.progress
  );
  const contactsData: ContactDTO[] = useSelector(
    (state: RootState) => state.contacts.list
  );
  const isGlobalModal = useSelector((state: RootState) => !!state.modal.name);

  const [unshared, setUnshared] = useState(
    isEmpty(asset) ? false : !asset.shared
  );
  const [isShowShareField, setIsShowShareField] = useState(
    category === ASSET_CATEGORIES.HEALTH && !!asset?.contacts?.length
  );

  const [blockNavigation, setBlockNavigation] = React.useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [formHidden, setFormHidden] = useState(false);

  // ONLY USED FOR UPLOADING DOCUMENTS
  const [documentFileName, setDocumentFileName] = useState('');
  const [formDataObj, setFormDataObj] = useState(new FormData());
  // ---

  const selectedContactIds = watch(FIELD_TYPE_KEYS.CONTACTS);
  const [selectedContactsMissingInfo, setSelectedContactsMissingInfo] =
    useState<any>([
      ...getAllMissingInfoContacts(getSelectOptionsContacts(asset.contacts)),
    ]);
  const isNewAsset = !asset.id;
  const isDocumentFieldInAssetTypeStructure = assetType.structure
    .map((field) => field.type)
    .includes('document');
  const isDocumentUploadRecommended =
    ([ASSET_CATEGORIES.HEALTH, ASSET_CATEGORIES.INSURANCE].includes(
      category as ASSET_CATEGORIES
    ) ||
      (
        [LEGAL_SUBTYPES.WILL, LEGAL_SUBTYPES.POWER_OF_ATTORNEY] as string[]
      ).includes(assetType.name)) &&
    isDocumentFieldInAssetTypeStructure;

  const questionnaireStatus =
    assistantProgress[camelCase(assetType.name)]?.status;

  // check if the asset type exists in the asset types list
  const assetTypes: AssetTypesDTO = useSelector(
    (state: RootState) => state.assetTypes
  );
  const categoryAssetTypes: AssetTypeDTO[] = assetTypes[category];
  const existingAssetType = categoryAssetTypes.find(
    (item) => item.name === assetType.name
  );

  // Used for on-going costs
  const assets: AssetsDTO = useSelector((state: RootState) => state.assets);
  const assetsToLink: AssetDTO[] =
    assets[ASSET_CATEGORIES.FINANCIAL]?.filter((asset) =>
      [ASSET_TYPE_KEYS.BANK_ACCOUNT, ASSET_TYPE_KEYS.ONLINE_WALLET].includes(
        asset.assetTypeName
      )
    ) || [];
  const relatedAsset = assetsToLink.find(
    (linkAsset) => linkAsset.id === asset.relatedAssetId
  );
  const [linkedAsset, setLinkedAsset] = React.useState(relatedAsset);
  const onGoingCostsIncluded =
    category === ASSET_CATEGORIES.DIGITAL ||
    assetType.name === ASSET_TYPE_KEYS.ONGOING_CONTRACT;

  // Handle submit form data
  const onSubmit = async (formData) => {
    const file = formDataObj.get(FIELD_TYPE_KEYS.DOCUMENT);
    // remove duplications when re-proceed/submit the FormData Object
    if (file) {
      setFormDataObj(() => {
        const newFormData = new FormData();
        newFormData.set(FIELD_TYPE_KEYS.DOCUMENT, file);
        return newFormData;
      });
    }

    formData.share =
      !unshared &&
      !!formData.contactIds?.length &&
      category === ASSET_CATEGORIES.HEALTH;

    const documentAttached =
      submitWithoutDocument ||
      !!file ||
      asset?.documents?.length ||
      !isDocumentUploadRecommended;

    if (!documentAttached && !submitWithoutDocument) {
      handleOpenGlobalModal(MODAL_TYPES.UPLOAD_WARNING);
      return;
    }

    const isContactListChanged = checkContactListChanged(formData, asset);
    const shouldEmerNoticeModalOpen =
      !notifyContacts &&
      !!formData.share &&
      (!unshared !== !!asset.shared || isContactListChanged);

    if (shouldEmerNoticeModalOpen) {
      const isAllContactsContacted = checkAllContactedContacts(
        formData,
        contactsData
      );
      handleOpenGlobalModal(MODAL_TYPES.CONTACT_NOTICE, isAllContactsContacted);
      return;
    }

    // this if block populates the name field for certain asset types if left empty
    const hasDocumentGenerator = Object.values(ASSET_TYPES_DOC_GEN).includes(
      assetType.name
    );
    if (hasDocumentGenerator) {
      const nameField = assetType?.structure?.find(
        (field) => field?.type === 'name'
      );
      if (nameField?.placeholder && !formData?.name) {
        formData.name = t(nameField?.placeholder);
      }
    }

    if (formData.email) {
      formData.email = formData.email.toLowerCase();
    }

    if (linkedAsset?.id) {
      formData.paymentMethod = '';
      formData.relatedAssetId = linkedAsset?.id;
    }

    // These lines of code need to be here right before posting the form to the backend
    if (!existingAssetType?.id && !assetType?.id) {
      setIsFormSubmit(true);
      const createdAssetTypeWithId: any = await dispatch(
        addAssetType(assetType, category)
      );
      if (createdAssetTypeWithId.id) {
        formData.assetTypeId = createdAssetTypeWithId.id;
      }
    } else {
      formData.assetTypeId = existingAssetType?.id || assetType?.id;
    }

    setIsFormSubmit(true);

    if (isNewAsset && file) {
      const processedFormDataObj = makeFormDataObj(formData, formDataObj);

      await new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file as File);
        fileReader.onload = async () => {
          const response: any = await dispatch(
            uploadToNewAsset(
              processedFormDataObj,
              category,
              !isAllowedToken(),
              {
                returnError: true,
              }
            )
          );
          handleActionAfterSubmit(response, onHideForm);
          resolve(undefined);
        };
      });
    } else {
      const submitData = makeSubmitData(formData);

      if (isNewAsset) {
        const response: any = await dispatch(
          addAsset(submitData, category, { returnError: true })
        );

        handleActionAfterSubmit(response, onHideForm);
      } else {
        const response: any = await dispatch(
          editAsset(submitData, category, { returnError: true })
        );

        handleActionAfterSubmit(response, onHideForm, undefined, false);
      }
    }
  };

  const onDocumentUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target || !event.target.files || !event.target.files[0]) return;
    const file = event.target.files[0];

    setIsFormSubmit(true);

    if (file.size > config.maxDocumentSize) {
      reactToast.showWarning(t('common:warning_file_too_large'));
      setDocumentFileName('');
    } else {
      await new Promise((resolve) => {
        const fileReader = new FileReader();

        if (isNewAsset) {
          formDataObj.set(FIELD_TYPE_KEYS.DOCUMENT, file);
          setFormDataObj(formDataObj);
          setDocumentFileName(file.name);
          resolve(undefined);
        } else {
          const documentUpload = new FormData();

          documentUpload.set('id', asset.id || '');
          documentUpload.append(FIELD_TYPE_KEYS.DOCUMENT, file);

          setFormDataObj(documentUpload);

          fileReader.readAsDataURL(file);
          fileReader.onload = async () => {
            const res = await dispatch(
              uploadToAsset(documentUpload, category, true, {
                returnError: true,
              })
            );
            handleActionAfterSubmit(res, undefined, documentUpload);
            resolve(undefined);
          };
        }
      });
    }

    setIsFormSubmit(false);
  };

  // USED FOR HANDLING MODAL AFTER NEW ASSET SUCCESSFULLY ADDED
  const handleActionAfterNewAssetAdded = () => {
    if (assetType.name !== FINANCIAL_SUBTYPES.BANK) {
      return;
    }

    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: t('plan:asset_from_bank_power_of_attorney_modal_title'),
          description: [
            <div className="t-flex t-items-center t-mt-4">
              <IconPOA className="t-mr-7" />
              <p className="typo-epsilon">
                {t('plan:asset_from_bank_power_of_attorney_modal_desc_1')}
              </p>
            </div>,
            t('plan:asset_from_bank_power_of_attorney_modal_desc_2'),
            t('plan:asset_from_bank_power_of_attorney_modal_desc_3'),
          ],
          primaryButtonText: t('common:close'),
          secondaryButtonText: t('common:learn_more'),
          cancelAction: () => {
            navigate('/assistant/bank-authorization-letter');
          },
          disableClickOutside: true,
        },
      })
    );
  };

  // USED FOR HANDLING WARNING ON REDIRECTED
  const [isNoSaveWarnModalOpen, setIsNoSaveWarnModalOpen] = useState(false);
  const [routeToNavigateTo, setRouteToNavigateTo] = useState('');
  useEffect(() => {
    if (!routeToNavigateTo && isDirty) {
      setBlockNavigation(true);
    } else {
      setBlockNavigation(false);
    }
  }, [routeToNavigateTo, isDirty]);

  customHooks.useBlocker(({ location, retry }) => {
    setRouteToNavigateTo(location?.pathname);
    if (location?.pathname !== '/login') {
      setIsNoSaveWarnModalOpen(true);
    } else {
      // on logout, "retry" is needed or the redirect will be prevented.
      retry();
      return;
    }
  }, blockNavigation);

  // handle redirect
  useEffect(() => {
    if (isNoSaveWarnModalOpen) {
      handleOpenGlobalModal(MODAL_TYPES.REDIRECT);
    }
  }, [isNoSaveWarnModalOpen]);
  // - END OF WARNING ON REDIRECTED

  // Handle showing missing contact info
  useEffect(() => {
    if (selectedContactIds?.length && category === ASSET_CATEGORIES.HEALTH) {
      setIsShowShareField(true);

      const contactMissingInfo = getAllMissingInfoContacts(selectedContactIds);
      setSelectedContactsMissingInfo(contactMissingInfo);
    } else if (!selectedContactIds?.length) {
      setIsShowShareField(false);
      setSelectedContactsMissingInfo([]);
    }
  }, [selectedContactIds]);
  // -END

  // handle open warning modals (+ passphrase)
  const [passphraseAllowed, setPassphraseAllowed] = React.useState(false);
  const [submitWithoutDocument, setSubmitWithoutDocument] =
    React.useState(false);
  const [notifyContacts, setNotifyContacts] = React.useState(false);

  React.useEffect(() => {
    if (passphraseAllowed || submitWithoutDocument || notifyContacts) {
      dispatch(handleSubmit(onSubmit));
    }
  }, [passphraseAllowed, submitWithoutDocument, notifyContacts]);

  // handle on passphrase required when submitting
  const handleActionAfterSubmit = (
    response: any,
    successAction?: (...arg) => void,
    assetToEditWithUpload?: FormData,
    isNewAsset = true
  ) => {
    const responseError = handleResponseError(response);

    setIsFormSubmit(false);

    if (responseError === ERROR_CODES.NOT_ALLOWED) {
      dispatch(
        setModal({
          name: MODALS.PASSPHRASE,
          props: {
            successAction: async () => {
              if (assetToEditWithUpload) {
                await dispatch(uploadToAsset(assetToEditWithUpload, category));
              } else {
                setPassphraseAllowed(true);
              }
            },
            cancelAction: () => {
              handleCloseModals();
            },
          },
        })
      );
    } else if (!responseError) {
      if (recommendation?.id) {
        dispatch(updateRecommendation({ ...recommendation, visible: false }));
      }
      if (isNewAsset) {
        handleActionAfterNewAssetAdded();
      }
      dispatch(
        temporaryFieldSaver({
          actionType: REDIRECT_ACTIONS.GO_TO_ASSET_CARD,
          assetId: response?.id,
        })
      );

      if (successAction) {
        successAction();
      }
    }
  };

  // Handle dispatching global modals - redirect, ContactNotice, uploadWarning
  const handleOpenGlobalModal = (type: string, notifyingAll?: boolean) => {
    const basicProps = getAssetFormGlobalModalProps(type, t, notifyingAll);

    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          ...basicProps,
          hasCross: true,
          successAction: () => {
            type === MODAL_TYPES.REDIRECT
              ? handleQuitAssetForm()
              : type === MODAL_TYPES.CONTACT_NOTICE
              ? handleSubmitWithNotifications()
              : type == MODAL_TYPES.CANCEL
              ? onHideForm()
              : null;
          },
          cancelAction: () => {
            type === MODAL_TYPES.UPLOAD_WARNING
              ? handleSubmitWithoutDocument()
              : handleCloseModals();
          },
        },
      })
    );
  };

  const handleEmerAccessCheckboxClick = () => {
    setUnshared(!unshared);
  };

  const handleSubmitWithoutDocument = () => {
    setSubmitWithoutDocument(true);
  };

  const handleSubmitWithNotifications = () => {
    setNotifyContacts(true);
  };

  const handleCloseModals = () => {
    setRouteToNavigateTo('');
    setIsNoSaveWarnModalOpen(false);
    setPassphraseAllowed(false);
    setSubmitWithoutDocument(false);
    setNotifyContacts(false);
  };

  const handleSelectedAssetSave = (assetToLink: AssetDTO | undefined) => {
    setLinkedAsset(assetToLink);
  };

  const handleQuitAssetForm = () => {
    const copiedState = routeToNavigateTo.slice();
    onHideForm();
    navigate(copiedState);
  };

  const handleToggleAssetForm = (value: boolean) => {
    setFormHidden(value);
  };

  const zvrHCPRegistered =
    asset?.zvr === 1 &&
    asset?.assetTypeName === HEALTH_CARE_ASSET_TYPES.HEALTH_CARE_PROXY;
  const showRedirectToDocButton =
    Object.values(ASSET_TYPES_DOC_GEN)?.includes(assetType?.name) &&
    !zvrHCPRegistered;
  const documentLink = assetType?.structure?.find(
    (field) => field?.type === FIELD_TYPE_KEYS.LINK_TO
  )?.link;

  const handleClose = () => {
    if (isDirty) {
      handleOpenGlobalModal(MODAL_TYPES.CANCEL);
    } else {
      onHideForm();
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`${formHidden || isGlobalModal ? 't-hidden' : ''}`}
      data-testid="AssetForm"
    >
      {!isNewAsset && (
        <input type="hidden" id="id" {...register('id')} value={asset.id} />
      )}
      <input
        type="hidden"
        id="assetTypeId"
        {...register('assetTypeId')}
        value={assetType?.id || existingAssetType?.id || ''}
      />
      <fieldset disabled={isFormSubmit}>
        {showRedirectToDocButton && (
          <Link
            type="button"
            to={`/${documentLink}`}
            state={{ prevPath: location.pathname }}
            className="t-mb-4 button-secondary"
            style={{ width: 'fit-content' }}
            onClick={() => {
              dispatch(setIsShowEditAssetForm(false));
            }}
          >
            {t(
              `common:${
                questionnaireStatus === 1
                  ? 'redo'
                  : questionnaireStatus === 0.5
                  ? 'continue'
                  : 'create'
              }_document_button`
            )}
            <div className="t-rounded-full t-bg-alpha-600 t-p-1 t-ml-3">
              <IconArrowRight className="t-text-gamma-400 t-w-3" />
            </div>
          </Link>
        )}
        {assetType.structure.map((field) => {
          return (
            <AssetFormFieldSelector
              key={field.type}
              field={field}
              assetCategory={category}
              assetType={assetType}
              onDocumentUpload={async (e) => {
                await onDocumentUpload(e);
              }}
              documentFileName={documentFileName}
              asset={asset}
              register={register}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              control={control}
              watch={watch}
              questionnaireStatus={questionnaireStatus}
              preselectedSubtype={preselectedSubtype}
              handleToggleAssetForm={handleToggleAssetForm}
            />
          );
        })}
        <SelectContactInput
          control={control}
          errors={errors}
          defaultValue={asset?.contacts}
          assetType={assetType.name}
          dataOnRedirect={dataOnRedirect}
          watch={watch}
          handleToggleAssetForm={handleToggleAssetForm}
        />

        {onGoingCostsIncluded && (
          <AssetFormOnGoingCosts
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
            onRelatedAssetSave={handleSelectedAssetSave}
            relatedAsset={linkedAsset}
            currentAsset={asset}
            assetsToLink={assetsToLink}
            handleToggleAssetForm={handleToggleAssetForm}
          />
        )}
      </fieldset>

      {isShowShareField && (
        <div className="t-mt-6">
          <Checkbox
            text={t('plan:healthCare_emergency_access_checkbox_text')}
            checked={unshared}
            id="share"
            onChange={handleEmerAccessCheckboxClick}
            textClassName="typo-epsilon t-text-beta-500"
            isBlueBg
            testId="unsharedCheckbox"
          />
          <IconInfo
            role="presentation"
            className="t-inline t-text-delta-700 t-ml-7"
            data-tip={t('plan:healthCare_emergency_access_checkbox_tooltip')}
          />
          {unshared && (
            <p className="t-flex typo-zeta t-mt-7 t-p-3 t-bg-delta-100 t-rounded">
              <span className="t-mt-1">
                <IconShowEye className="t-text-beta-700" />
              </span>
              <span className="t-ml-2 t-text-delta-700">
                {t('plan:healthCare_emergency_access_info')}
              </span>
            </p>
          )}
        </div>
      )}
      {!!selectedContactsMissingInfo.length && (
        <AssetFormContactInfoWarning
          selectedContactsMissingInfo={selectedContactsMissingInfo}
        />
      )}
      <div className="t-flex t-flex-wrap t-justify-end t-mt-8">
        <Button
          category="secondary"
          onClick={handleClose}
          className="t-self-end"
          disabled={isFormSubmit}
        >
          {t('common:cancel')}
        </Button>
        <Button
          type="submit"
          className="t-ml-2 xs:t-mt-3"
          loading={isFormSubmit}
        >
          {t('common:save')}
        </Button>
      </div>
    </form>
  );
}
