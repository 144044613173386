import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import {
  getCardLink,
  getRelatedDocQuestionnaire,
} from 'modules/assistant/helpers/questionnaireHelper';
import { useTranslation } from 'modules/common/helpers';

import { UploadButton } from 'modules/assistant/components';
import { Button } from 'modules/common/components';

import {
  CONTENT_QUESTIONNAIRE_KEYS,
  CONTENT_QUESTIONNAIRE_KEYS_WITH_DOC_GEN,
} from 'modules/assistant/constants/contentQuestionnaires';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';

import { ReactComponent as IconChevroneDown } from 'images/v-shaped-down.svg';
import { ReactComponent as IconChevroneUp } from 'images/v-shaped-up.svg';
import imgDocument from 'images/document_img.png';

interface UploadCTACardProps {
  questionnaire: CONTENT_QUESTIONNAIRE_KEYS | QUESTIONNAIRE_KEYS;
}

const UploadCTACard = ({ questionnaire }: UploadCTACardProps) => {
  const { t } = useTranslation(['common', 'assistant']);
  const navigate = useNavigate();

  const [isCollapsed, setIsCollapsed] = React.useState(true);

  const isDocGenReco = (
    Object.values(CONTENT_QUESTIONNAIRE_KEYS_WITH_DOC_GEN) as string[]
  ).includes(questionnaire);

  const relatedDocGenerator = isDocGenReco
    ? getRelatedDocQuestionnaire(questionnaire as CONTENT_QUESTIONNAIRE_KEYS)
    : '';

  const handleCreateDocument = () => {
    navigate(getCardLink(relatedDocGenerator));
  };

  const toggleShowUpload = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  return (
    <div className="card t-overflow-hidden t-mt-6">
      <Accordion onSelect={toggleShowUpload}>
        <Accordion.Item eventKey="upload">
          <Accordion.Button as="div" className="t-cursor-pointer">
            <div className="t-w-full t-flex t-justify-between t-bg-delta-900 t-px-6 t-py-4">
              <h3 className="typo-gamma t-text-gamma-400">
                {t(`assistant:${questionnaire}_upload_card_title`)}
              </h3>

              <div className="t-outline-none t-p-1 t-flex t-items-center">
                {isCollapsed ? (
                  <IconChevroneDown className="t-text-gamma-400" />
                ) : (
                  <IconChevroneUp className="t-text-gamma-400" />
                )}
              </div>
            </div>
          </Accordion.Button>
          <Accordion.Body>
            <div className="t-bg-beta-50 t-flex t-justify-between t-items-stretch">
              <div className="t-hidden sm:t-flex sm:t-items-start xl:t-w-44">
                <img
                  className="t-h-full"
                  src={imgDocument}
                  alt="Upload your document"
                />
              </div>
              <div className="t-p-3 t-flex-grow t-flex t-flex-col xl:t-flex-row xl:t-items-center xl:t-justify-between">
                <p className="typo-delta t-mb-2 xl:t-mb-0">
                  {t('assistant:upload_cta_text')}
                </p>
                <div className="t-flex t-justify-end t-items-center t-mr-6 xl:t-ml-2">
                  {isDocGenReco && (
                    <Button
                      category="secondary"
                      className="t-whitespace-nowrap t-mr-3"
                      onClick={handleCreateDocument}
                    >
                      {t('assistant:create')}
                    </Button>
                  )}
                  <UploadButton questionnaire={questionnaire} />
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default UploadCTACard;
