import React, { useState } from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';

import { Form } from 'modules/bootstrap/components';

interface TextInputProps {
  name: string;
  label: string;
  onChange: (name: string, value: string) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  error?: string;
  type?: string;
  disabled?: boolean;
  labelClass?: string;
  inputClass?: string;
  mask?: string;
  maxLength?: number;
  onFocus?: () => void;
}

function TextInput({
  name,
  label,
  onChange,
  placeholder,
  value = '',
  error = '',
  type = 'text',
  disabled = false,
  labelClass = '',
  inputClass = '',
  mask = '',
  maxLength = 40,
  onBlur,
  onFocus,
}: TextInputProps) {
  const [isPwVisible, setIsPwVisible] = useState(false);

  const wrapperClass = classnames({
    'Form-group': true,
    isErrored: error && error.length > 0,
  });

  const isInputPassword = type === 'password';
  const inputType = isInputPassword && !isPwVisible ? 'password' : 'text';

  const inputOnChange = (event) => {
    onChange(event.target.name, event.target.value);
  };

  const compProps = {
    id: name,
    type: inputType,
    name,
    className: `Field ${inputClass}${error && ' isErrored'}${
      isInputPassword ? ' Field--password' : ''
    }`,
    placeholder,
    disabled,
    value: value || '',
    onChange: inputOnChange,
    onBlur,
    onFocus,
  };

  return (
    <div className={wrapperClass}>
      <Form.Label
        htmlFor={name}
        className={`Form-label ${error && ' isErrored'} ${labelClass}`}
      >
        {label}
      </Form.Label>

      {mask ? (
        <InputMask {...compProps} mask={mask} />
      ) : (
        <input maxLength={maxLength} {...compProps} />
      )}

      {isInputPassword && (
        <div className="Form-passwordIcon">
          <button
            type="button"
            className="Form-passwordIconButton"
            onClick={() => setIsPwVisible(!isPwVisible)}
          >
            <span className="ScreenReaderOnly">Show password</span>
          </button>
        </div>
      )}

      {error && (
        <div className="Form-alert">
          <span className="text-main-sm t-text-epsilon-600">{error}</span>
        </div>
      )}
    </div>
  );
}

export default TextInput;
