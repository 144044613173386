import React from 'react';
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import { StripeIbanElement } from '@stripe/stripe-js';

import {
  getSepaClientSecret,
  addSepaSubscription,
} from 'store/actions/userActions';
import { reactToast, useTranslation } from 'modules/common/helpers';

import { Button, TextInputRef } from 'modules/common/components';
import { StripeIban, SubscriptionDetail } from 'modules/payment/components';
import { PricesInfo } from 'modules/payment/components/Payment';

export interface StepSepaProps {
  handleGoToSuccessScreen: () => void;
  billingOption: string;
  pricesInfo: PricesInfo;
}

export default function StepSepa({
  handleGoToSuccessScreen,
  billingOption,
  pricesInfo,
}: StepSepaProps) {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const { t } = useTranslation(['payment', 'common']);
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,

    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async () => {
    // Stripe.js has not yet loaded. We need to make sure to disable form submission until it's loaded.
    if (!stripe || !elements) return;
    setIsLoading(true);
    const res: any = await dispatch(getSepaClientSecret());
    const iban = elements.getElement(IbanElement);

    const { email, accountholderName, coupon } = getValues();

    const stripeResult = await stripe.confirmSepaDebitSetup(res.clientSecret, {
      payment_method: {
        sepa_debit: iban as StripeIbanElement | { iban: string },
        billing_details: {
          name: accountholderName,
          email: email,
        },
      },
    });

    if (stripeResult.error) {
      reactToast.showError(t('payment:sepa_payment_error_message'));
    } else {
      const subscriptionResponse: any = await dispatch(
        addSepaSubscription(billingOption, coupon)
      );
      if (subscriptionResponse) {
        handleGoToSuccessScreen();
      } else {
        reactToast.showError(t('payment:sepa_payment_error_message'));
      }
    }
    setIsLoading(false);
  };

  return (
    <div data-testid="StepSepa">
      <h2 className="typo-alpha t-mb-4">{t('payment:step_sepa_title')}</h2>
      <h3 className="typo-gamma t-mb-5">
        {t('payment:step_sepa_selection_subtitle')}
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isLoading}>
          <TextInputRef
            {...register('accountholderName', { required: true })}
            label={t('payment:step_sepa_accountholder_name')}
            placeholder={t('payment:step_sepa_accountholder_name')}
            type="text"
            autoComplete={false}
            error={
              errors.accountholderName &&
              `${t('payment:step_sepa_accountholder_name')} ${t(
                'common:required'
              )}`
            }
          />

          <TextInputRef
            {...register('email', {
              required: true,
              validate: (value) => {
                if (!value) return true;
                return isEmail(value);
              },
            })}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValue('email', e.target.value.trim())
            }
            label={t('payment:step_sepa_accountholder_email')}
            placeholder={t('payment:step_sepa_accountholder_email')}
            type="text"
            autoComplete={false}
            error={
              errors.email && errors.email.type === 'required'
                ? `${t('auth:email_required')}`
                : errors.email
                ? `${t('auth:email_not_valid')}`
                : false
            }
          />

          <StripeIban />

          <SubscriptionDetail
            register={register}
            billingOption={billingOption}
            pricesInfo={pricesInfo}
            watch={watch}
            setError={setError}
            clearErrors={clearErrors}
          />
        </fieldset>

        <Button
          type="submit"
          className="t-mt-5"
          loading={isLoading}
          disabled={!stripe}
        >
          {t('payment:step_sepa_submit_button')}
        </Button>

        <div className="mandate-acceptance t-mt-5">
          {t('payment:step_sepa_mandate_acceptance')}
        </div>
      </form>
    </div>
  );
}
