import React from 'react';
import { isEmpty, keys, values, groupBy, find } from 'lodash';

import {
  AssetsByTypeWidget,
  NoDocumentsHere,
} from 'modules/dashboard/components';

import {
  ASSET_CATEGORIES,
  PLAN_CATEGORY_PAGES,
} from 'modules/estatePlan/constants';

interface SearchedAssetsWidgetProps {
  categorizedAssets: AssetsDTO;
  assetIdsToRemove: string[];
}

export default function SearchedAssetsWidget({
  categorizedAssets,
  assetIdsToRemove,
}: SearchedAssetsWidgetProps) {
  return (
    <>
      {isEmpty(categorizedAssets) && (
        <div className="t-flex t-justify-center">
          <NoDocumentsHere planRoute="/plan" searching />
        </div>
      )}
      {values(ASSET_CATEGORIES).map((assetCategory) => {
        const assetData = categorizedAssets[assetCategory];
        const assetDataByType = groupBy(
          assetData,
          (item) => item.assetTypeName
        );
        const planPage =
          find(
            PLAN_CATEGORY_PAGES,
            (page) => page.category === assetCategory
          ) || ({} as PlanCategoryPage);
        const planRoute = planPage.path || '';

        if (isEmpty(assetData)) return null;

        return (
          assetCategory === planPage.category &&
          keys(assetDataByType).map((assetTypeName) => {
            const assetTypeAssets = assetDataByType[assetTypeName];

            const displayedAssets = assetTypeAssets.filter(
              (asset) => !assetIdsToRemove.includes(asset.id)
            );
            return (
              <AssetsByTypeWidget
                key={assetTypeName}
                assetCategory={assetCategory}
                assetTypeName={assetTypeName}
                assetTypeLabel={assetTypeAssets[0].assetTypeLabel}
                assetTypeAssets={displayedAssets}
                planRoute={planRoute}
                assetIdsToRemove={assetIdsToRemove}
              />
            );
          })
        );
      })}
    </>
  );
}
