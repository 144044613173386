import helper from 'store/actions/actionHelper';
import { remindService } from 'modules/assistant/services';

export const addQuestionnaireReminder = (
  date,
  id,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    await remindService.addQuestionnaireReminder({ date, id });
  }, actionOptions);
};

export const addAssetReminder = (
  date: string,
  id: string,
  type: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    await remindService.addAssetReminder({ date, id, type });
  }, actionOptions);
};
