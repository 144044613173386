import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { reactToast, useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';
import { Col, Row } from 'modules/bootstrap/components';

import { ReactComponent as ArrowLeftIcon } from 'images/icon-arrow-left.svg';
import referralCardImg from 'images/referral_card_image.png';

export default function ReferralPage() {
  const { t } = useTranslation(['referral']);

  const userData: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const referralLink = userData.referral;

  const copyToClipboard = () => {
    if (referralLink) {
      navigator.clipboard.writeText(referralLink);
      reactToast.showMessage(t('toastr:referral_successfully_copied'));
    }
  };

  return (
    <Row className="t-h-100" data-testid="ReferralPage">
      <Col
        md={{ span: 12 }}
        xl={{ span: 10, offset: 2 }}
        style={{ paddingRight: 0 }}
      >
        <div className="t-bg-beta-50 t-rounded t-shadow-lg xl:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7">
          <div>
            <Link to="/profile" className="button-secondary t-w-52">
              <ArrowLeftIcon className="t-mr-4 t-ml-2 t-text-alpha-600" />
              {t('referral:back_to_profile')}
            </Link>
          </div>
          <div className="xl:t-w-3/5 t-mt-6">
            <h1 className="typo-alpha">{t('referral:referral_page_title')}</h1>
            <p className="typo-gamma t-mt-2">
              {t('referral:referral_page_description')}
            </p>
          </div>
          <div className="card t-mt-9 t-flex t-flex-col t-items-center xl:t-flex-row xl:t-items-start">
            <img
              src={referralCardImg}
              alt="referral card"
              className="t-w-80 t-mt-10"
            />
            <div className="xl:t-mt-9 t-ml-7 xs:t-mx-5 xl:t-mr-10 xl:t-ml-0">
              <h2 className="typo-beta">{t('referral:referral_card_title')}</h2>
              <p className="typo-epsilon t-mt-2">
                {t('referral:referral_card_description')}
              </p>
              <p className="typo-zeta t-mt-9">
                {t('referral:referral_link_title')}
              </p>
              <div className="t-flex t-mt-2">
                <div className="t-bg-beta-100 t-rounded-sm t-px-3 t-py-1 t-flex-grow">
                  {referralLink}
                </div>
                <Button
                  className="xs:t-px-5 t-px-3 t-ml-3 t-whitespace-nowrap focus:t-outline-none"
                  onClick={copyToClipboard}
                >
                  <p className="typo-eta t-text-gamma-400">
                    {t('referral:referral_card_copy_button')}
                  </p>
                </Button>
              </div>
              <p className="typo-epsilon t-mt-6 xs:t-mb-12 xl:t-mb-0">
                {t('referral:referral_redeem_description')}
              </p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
