import React from 'react';
import { find, isEmpty } from 'lodash';
import isPostalCode from 'validator/lib/isPostalCode';

import {
  getFullName,
  useGetCountries,
  useTranslation,
} from 'modules/common/helpers';

import { PostalInfoForm } from 'modules/assetType/components';

import { Button, Modal, PdfDownloadLink } from 'modules/common/components';

import { COUNTRIES, POSTAL_TYPES } from 'modules/common/constants/enums';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';
import { ReactComponent as IconEdit } from 'images/icon-edit.svg';

interface PostalInfoModalProps {
  onCancel: () => void;
  onPayment: (postalInfo: PostalInfo) => void;
  loading: boolean;
  document: DocumentDTO;
  contact: ContactDTO | UserProfileDTO;
  type: POSTAL_TYPES;
}

const PostalInfoModal = ({
  onCancel,
  onPayment,
  loading,
  document,
  contact,
  type,
}: PostalInfoModalProps) => {
  const { t } = useTranslation(['plan', 'common']);
  const countries = useGetCountries();

  const [postalInfo, setPostalInfo] = React.useState({} as PostalInfo);

  React.useEffect(() => {
    if (!postalInfo?.name) {
      setPostalInfo(contact as PostalInfo);
    }
  }, [contact]);

  const [showPostalInfoForm, setShowPostalInfoForm] = React.useState(false);

  const name = getFullName(postalInfo);
  const { address, zip, city } = postalInfo;

  const country =
    find(countries, (item) => item.value === postalInfo.country)?.label || '';

  const openPostalInfoChange = () => {
    setShowPostalInfoForm(true);
  };

  const closePostalInfoChange = () => {
    setShowPostalInfoForm(false);
  };

  const submitPostalInfoChange = (data: PostalInfo) => {
    setPostalInfo(data);
    setShowPostalInfoForm(false);
  };

  const handleNextStep = () => {
    onPayment(postalInfo);
  };

  const isAddressOutsideDE = postalInfo?.country !== COUNTRIES.DE;
  const isValidPostalCode = isPostalCode(postalInfo?.zip || '', COUNTRIES.DE);

  return (
    <>
      {showPostalInfoForm && (
        <Modal show onHide={closePostalInfoChange}>
          <PostalInfoForm
            defaultInfo={postalInfo}
            onClose={closePostalInfoChange}
            onSave={submitPostalInfoChange}
          />
        </Modal>
      )}
      {!showPostalInfoForm && (
        <Modal show>
          <h1 className="typo-alpha">
            {t(`plan:${type}_postal_info_modal_title`)}
          </h1>
          <p className="typo-epsilon t-mt-4">
            {t(`plan:${type}_postal_info_modal_desc`)}
          </p>

          <h2 className="typo-beta t-text-delta-700 t-mt-5">
            {t('plan:postal_info_modal_send_to')}
          </h2>

          <div className="t-mt-5 t-flex t-justify-between t-items-center">
            <div>
              <p className="typo-epsilon t-font-semibold">{name}</p>
              <p className="typo-epsilon">{address}</p>
              <p className="typo-epsilon">{`${zip} ${city}`}</p>
              <p className="typo-epsilon">{country}</p>
            </div>

            <button
              className="typo-eta t-text-delta-700 t-flex t-items-center"
              onClick={openPostalInfoChange}
            >
              <IconEdit className="t-mr-3" />
              <span>{t('common:change')}</span>
            </button>
          </div>

          {isAddressOutsideDE && (
            <p className="typo-epsilon t-text-zeta-600 t-mt-2">
              {t('common:address_outside_of_germany_warning')}
            </p>
          )}

          {!isValidPostalCode && (
            <p className="typo-epsilon t-text-zeta-600 t-mt-1">
              {t('common:wrong_german_postal_code_format_text')}
            </p>
          )}

          <hr className="t-w-full t-border-none t-h-px t-text-beta-200 t-bg-beta-200 t-my-5" />

          <div className="t-flex t-justify-between t-items-center t-mt-4">
            <div>
              <h2 className="typo-beta">
                {t('plan:postal_info_modal_service_fee')}
              </h2>
              <p className="typo-delta t-text-beta-500 t-mt-1">
                {t('plan:postal_info_modal_service_fee_vat')}
              </p>
            </div>
            <div className="t-flex t-items-center">
              <h1 className="typo-alpha t-text-delta-700">
                {t('plan:postal_service_doc_gen_price')}
              </h1>
              <IconInfo
                role="presentation"
                data-tip={t('plan:postal_service_doc_gen_price_tooltip')}
                className="t-inline t-text-delta-700 t-ml-3"
              />
            </div>
          </div>

          <div className="t-mt-5">
            <p className="typo-epsilon">
              {t('plan:postal_service_download_document_text')}
            </p>
            {!isEmpty(document) && (
              <PdfDownloadLink
                document={document || {}}
                tooltip={document.documentTitle || ''}
              />
            )}
          </div>

          <div className="t-flex t-justify-end t-items-end t-mt-6 t-flex-col sm:t-flex-row">
            <div>
              <Button
                category="secondary"
                className="t-mr-0 sm:t-mr-3 t-py-2 t-mb-3 sm:t-mb-0"
                onClick={onCancel}
                disabled={loading}
              >
                {t('common:cancel')}
              </Button>
            </div>

            <div>
              <Button
                className="t-py-2"
                onClick={handleNextStep}
                loading={loading}
                disabled={isAddressOutsideDE || !isValidPostalCode}
              >
                {t('plan:payment_continue_button')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PostalInfoModal;
