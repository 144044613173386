enum ASSET_CATEGORIES {
  HEALTH = 'healthCare',
  CONTACTS = 'contacts',
  DEVICES = 'devices',
  LEGAL = 'legal',
  DIGITAL = 'digitalEstate',
  FINANCIAL = 'financial',
  AFTERLIFE = 'afterLife',
  INSURANCE = 'insurance',
}

export default ASSET_CATEGORIES;
