import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { RootState } from 'store/reducers';
import { setModal } from 'store/actions/modalActions';
import dataService from 'modules/common/services/dataService';
import { downloadDocument } from 'store/actions/assetActions';
import { setLoading } from 'store/actions/commonActions';
import {
  reactToast,
  isAllowedToken,
  checkDateInThePast,
  useTranslation,
} from 'modules/common/helpers';

import { Button } from 'modules/common/components';

import { MODALS } from 'modules/common/constants';

export default function EstateExportButton() {
  const { t } = useTranslation(['profile', 'plan']);
  const dispatch = useDispatch();
  const userData: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const isFileFetching = useSelector((state: RootState) => state.loading.value);

  const isSubscriptionExpired = checkDateInThePast(userData.subscriptionEnd!);

  const handleExportButton = async () => {
    if (!userData.profile)
      reactToast.showError(
        t('profile:export_incomplete_profile_error_message')
      );
    else {
      dispatch(setLoading(true));
      if (!userData.passphraseOptOut && !isAllowedToken()) {
        dispatch(
          setModal({
            name: MODALS.PASSPHRASE,
            props: {
              successAction: handleExportButton,
            },
          })
        );
      } else {
        const estatePlanInfo = await dataService.getEstatePlanInfo();
        if (estatePlanInfo.id) {
          await dispatch(downloadDocument(estatePlanInfo));
        }
      }
      dispatch(setLoading(false));
    }
  };

  const exportButtonTooltip = !userData.profile
    ? t('profile:export_incomplete_profile')
    : isSubscriptionExpired
    ? t('plan:export_button_subscription_expired_tooltip')
    : '';

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      <div
        className="t-flex t-justify-center t-mt-16"
        data-tip={exportButtonTooltip}
        data-tip-disable={userData.profile && !isSubscriptionExpired}
        data-for="export"
      >
        <Button
          category={
            !userData.profile || isSubscriptionExpired ? 'gray' : 'primary'
          }
          className={`t-whitespace-nowrap ${
            isFileFetching ? 't-bg-beta-200' : ''
          }`}
          onClick={handleExportButton}
          loading={isFileFetching}
          disabled={!userData.profile || isSubscriptionExpired}
        >
          <span
            className={`${
              userData.profile && !isSubscriptionExpired
                ? 't-text-gamma-400'
                : 't-text-beta-500'
            }`}
          >
            {t('profile:export_button_label')}
          </span>
        </Button>
      </div>
      <ReactTooltip
        id="export"
        effect="solid"
        place="top"
        multiline
        className="t-bg-eta-100 typo-epsilon t-text-zeta-600"
      />
    </>
  );
}
