import React from 'react';
import classnames from 'classnames';

import { ESCALATED_BUTTON_STATUS as EBS } from 'modules/common/constants/generic';

import { ReactComponent as IconChevronNext } from 'images/chevron-next.svg';

interface EscalatedButtonProps {
  status: EBS;
  text: string;
  isEscalated?: boolean;
  isDisabled?: boolean;
}

const EscalatedButton = ({
  status,
  text,
  isEscalated = true,
  isDisabled,
}: EscalatedButtonProps) => {
  const btnClass = classnames({
    't-rounded': true,
    't-tracking-widest': true,
    't-bg-alpha-600': status === EBS.REGULAR,
    't-bg-delta-900': status === EBS.GOOFY,
    't-bg-beta-100': status === EBS.DISABLED,
    't-cursor-not-allowed': isDisabled,
    't-cursor-pointer': !isDisabled,
  });

  const textClass = classnames({
    't-mx-1 t-self-center': true,
    't-text-gamma-400': status === EBS.REGULAR || status === EBS.GOOFY,
    't-text-beta-400': status === EBS.DISABLED,
    't-hidden': !isEscalated,
  });

  const circleClass = classnames({
    't-rounded-full t-h-5 t-w-5 t-mx-1 t-flex t-flex-col t-justify-center':
      true,
    't-bg-gamma-400': !(isEscalated && status === EBS.DISABLED),
    't-bg-beta-400': isEscalated && status === EBS.DISABLED,
  });

  const iconClass = classnames({
    't-mx-auto t-self-center': true,
    't-text-alpha-600': status === EBS.REGULAR,
    't-text-delta-900': status === EBS.GOOFY,
    't-text-gamma-400': status === EBS.DISABLED && isEscalated,
  });

  return (
    <div className={btnClass}>
      <div className="t-flex t-justify-between t-m-2">
        <div className={textClass}>{isEscalated ? text : ''}</div>

        <div className={circleClass}>
          <div className={iconClass}>
            <IconChevronNext className={iconClass} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EscalatedButton;
