import React from 'react';
import { useDispatch } from 'react-redux';

import { setModal } from 'store/actions/modalActions';
import { deleteAsset } from 'store/actions/assetActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { revokeEntireZVRDocument } from 'store/actions/zvrActions';
import { getDashboardTableCols } from 'modules/dashboard/helpers';
import { getDisplayedName } from 'modules/asset/helpers';
import { reactToast, useTranslation } from 'modules/common/helpers';

import { DocumentsWidgetRow } from 'modules/dashboard/components';
import { ToastUndo } from 'modules/common/components';

import {
  ASSET_CATEGORIES,
  ASSET_TYPE_KEYS,
} from 'modules/estatePlan/constants';
import { ASSET_TYPES_DOC_GEN } from 'modules/estatePlan/constants/assetTypeWithDocGen';
import { MODALS } from 'modules/common/constants';

interface AssetsByTypeWidgetProps {
  assetCategory: ASSET_CATEGORIES;
  assetTypeName: string;
  assetTypeLabel: string;
  assetTypeAssets: AssetDTO[];
  planRoute: string;
  assetIdsToRemove: string[];
}

const AssetsByTypeWidget = ({
  assetCategory,
  assetTypeName,
  assetTypeLabel,
  assetTypeAssets,
  planRoute,
  assetIdsToRemove,
}: AssetsByTypeWidgetProps) => {
  const { t } = useTranslation(['common', 'plan', 'dashboard']);
  const dispatch = useDispatch();

  const onDeleteAsset = async (asset, assetType, providers, assetCategory) => {
    const hasDocumentGenerator = Object.values(ASSET_TYPES_DOC_GEN).includes(
      assetType?.name
    );
    const displayedNameObj = getDisplayedName(asset, assetType, providers);
    const shownNameOnDelete =
      (hasDocumentGenerator || asset?.generated) && asset.name
        ? asset.name
        : t(`${displayedNameObj.value}`);
    const title = t('plan:delete_asset_confirm_title', {
      assetName: shownNameOnDelete,
    });

    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: title,
          description: t('plan:delete_asset_confirm_text'),
          primaryButtonText: t('common:confirm'),
          successAsyncAction: async () => {
            let deletionConfirmed = true;
            dispatch(
              temporaryFieldSaver({
                type: 'AssetToRemove',
                assetIds: [...(assetIdsToRemove || []), asset.id],
              })
            );

            const updatedAssetIdsToRemove = assetIdsToRemove.filter(
              (assetId) => assetId !== asset.id
            );
            const updatedTempField = updatedAssetIdsToRemove.length
              ? {
                  type: 'AssetToRemove',
                  assetIds: updatedAssetIdsToRemove,
                }
              : null;

            reactToast.showInfo({
              text: (
                <ToastUndo
                  onUndo={() => {
                    deletionConfirmed = false;
                    return dispatch(temporaryFieldSaver(updatedTempField));
                  }}
                  text={t('plan:delete_asset_undo_text')}
                />
              ),
              options: {
                onClose: async () => {
                  if (deletionConfirmed) {
                    await dispatch(deleteAsset(asset.id, assetCategory));
                    if (
                      asset.zvr &&
                      asset.assetTypeName === ASSET_TYPE_KEYS.HEALTH_CARE_PROXY
                    ) {
                      await dispatch(revokeEntireZVRDocument());
                    }
                    return dispatch(temporaryFieldSaver(updatedTempField));
                  }
                },
              },
            });
          },
        },
      })
    );
  };

  const getTableHeaderRow = (
    assetCategory: ASSET_CATEGORIES,
    assetTypeName: string
  ): Array<React.ReactNode> => {
    const tableCols = getDashboardTableCols(assetCategory, assetTypeName);

    return tableCols.map((colType) => (
      <th key={colType} className="Documents-col Documents-col--head">
        <span className="text-main-sm t-text-beta-400">
          {t(`dashboard:assets_column_${colType}`)}
        </span>
      </th>
    ));
  };

  return (
    <>
      {assetTypeAssets.length ? (
        <div className="Documents-assetCategoryTable">
          <div className="Documents-labelWrapper">
            <p className="Documents-label">{t(assetTypeLabel)}</p>
          </div>

          <table className="u-w-100">
            <thead>
              <tr>
                {getTableHeaderRow(assetCategory, assetTypeName)}
                <th>
                  <span className="ScreenReaderOnly">
                    {t('dashboard:assets_column_actions')}
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              {assetTypeAssets.map((item) => (
                <DocumentsWidgetRow
                  key={item.id}
                  item={item}
                  assetCategory={assetCategory}
                  assetTypeName={assetTypeName}
                  onDeleteAsset={onDeleteAsset}
                  planRoute={planRoute}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </>
  );
};

export default AssetsByTypeWidget;
