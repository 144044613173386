import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { getContactsList } from 'store/actions/contactActions';
import { optionsHelper, useTranslation } from 'modules/common/helpers';

import {
  AddExistingContactAsFamilyMembersModal,
  FamilyAccountAddedModal,
} from 'modules/familyAccount/components';
import { Modal } from 'modules/common/components';
import { ContactForm } from 'modules/contact/components';

interface AddFamilyAccountProcessProps {
  onClose: () => void;
}

const AddFamilyAccountProcess = ({ onClose }: AddFamilyAccountProcessProps) => {
  const { t } = useTranslation(['dashboard', 'familyAccounts']);
  const dispatch = useDispatch();

  const familyAccounts = useSelector(
    (state: RootState) => state.familyAccounts.list
  );

  const relations = optionsHelper.getRelationshipOptions();

  const contactsData =
    useSelector((state: RootState) => state.contacts.list) || [];

  const familyAccountIds = familyAccounts.map((account) => account.id);
  const contactsToAddAsFamilyMembers = contactsData.filter(
    (contact) => !familyAccountIds.includes(contact.secondaryUserId)
  );
  const withExistingContacts = !!contactsToAddAsFamilyMembers.length;

  const [contactAdded, setContactAdded] = React.useState(false);
  const [shouldNotShowContactForm, setShouldNotShowContactForm] =
    React.useState(false);

  React.useEffect(() => {
    if (withExistingContacts) {
      setShouldNotShowContactForm(true);
    }
  }, [withExistingContacts]);

  const onSuccessAddingNewContact = async () => {
    await dispatch(getContactsList());
  };

  const onFamilyAccountAdded = (successful) => {
    if (successful) {
      setContactAdded(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      {withExistingContacts && !contactAdded && (
        <AddExistingContactAsFamilyMembersModal
          familyAccounts={familyAccounts}
          relations={relations}
          onClose={onFamilyAccountAdded}
        />
      )}

      {!withExistingContacts && !shouldNotShowContactForm && (
        <Modal show onHide={onClose}>
          <h1 className="typo-alpha">
            {t('dashboard:my_contacts_widget_contact_modal_title')}
          </h1>
          <p className="typo-epsilon t-mt-4 t-mb-8">
            {t('familyAccounts:add_new_contact_as_family_modal_desc')}
          </p>

          <ContactForm
            onClose={onClose}
            onSuccess={onSuccessAddingNewContact}
            limited={true}
          />
        </Modal>
      )}

      {contactAdded && <FamilyAccountAddedModal onClose={onClose} />}
    </>
  );
};

export default AddFamilyAccountProcess;
