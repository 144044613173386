import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { RootState } from 'store/reducers';
import { getBeneficiaryAssetsList } from 'store/actions/assetActions';
import { getBeneficiaryAssetTypesList } from 'store/actions/assetTypeActions';
import { getCurrentBeneficiary } from 'store/actions/userActions';
import { getFullName, useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { BeneficiaryAssetsAccordion } from 'modules/afterDeathManagement/components';

export default function BeneficiaryAssetsPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['beneficiary']);
  const user = useSelector((state: RootState) => state.user.current) || {};
  const assets: AssetsDTO =
    useSelector((state: RootState) => state.assets) || {};
  const assetTypes: AssetTypesDTO =
    useSelector((state: RootState) => state.assetTypes) || {};
  const listOfCategories = Object.keys(assets) || [];

  const {
    assetsGroupedByType = {} as Record<string, AssetDTO[]>,
    assetTypesUncategorized = [] as AssetTypeDTO[],
    typeLabels = [] as string[],
  } = !_.isEmpty(assets) && !_.isEmpty(assetTypes) ? dataProcess() : {};

  const fullName = getFullName(user);

  React.useEffect(() => {
    dispatch(getCurrentBeneficiary());
    dispatch(getBeneficiaryAssetsList());
    dispatch(getBeneficiaryAssetTypesList());
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(assets) && !_.isEmpty(assetTypes)) {
      dataProcess();
    }
  }, [assets, assetTypes]);

  function dataProcess() {
    const assetsUncategorized: Array<AssetDTO> = listOfCategories.reduce(
      (assetsArr, category) => {
        assetsArr.push(...assets[category]);
        return assetsArr;
      },
      [] as Array<AssetDTO>
    );

    const assetTypesUncategorized: Array<AssetTypeDTO> =
      listOfCategories.reduce((typesArr, category) => {
        typesArr.push(...assetTypes[category]);
        return typesArr;
      }, [] as Array<AssetTypeDTO>);

    const assetsGroupedByType: Record<string, AssetDTO[]> = _.groupBy(
      assetsUncategorized,
      (asset) => asset.assetTypeLabel
    );
    const typeLabels = Object.keys(assetsGroupedByType);

    return { assetsGroupedByType, assetTypesUncategorized, typeLabels };
  }

  return (
    <Row>
      <Col
        md={{ span: 12 }}
        xl={{ span: 10, offset: 2 }}
        style={{ paddingRight: 0 }}
      >
        <div className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7">
          <div className="xl:t-w-3/5">
            <h1 className="typo-alpha t-mb-3">{`${fullName}${t(
              'beneficiary:beneficiary_page_title'
            )}`}</h1>
            <h3 className="typo-gamma">
              {t('beneficiary:beneficiary_page_description')}
            </h3>
            <BeneficiaryAssetsAccordion
              typeLabels={typeLabels}
              assetsGroupedByType={assetsGroupedByType}
              assetTypesUncategorized={assetTypesUncategorized}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
