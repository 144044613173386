import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';

import { checkDateInThePast, useTranslation } from 'modules/common/helpers';
import { useCountdownTimer, securePhoneNumber } from 'modules/twoAuth/utils';

import {
  ChangingEmailModal,
  PasswordEditProcess,
} from 'modules/profile/components';
import { TwoFactorAuthProcess } from 'modules/twoAuth/components';
import { SecuritySettingProcess } from 'modules/securityKeyWizard/components';
import {
  Button,
  PdfDownloadLink,
  TextWithHyphenSpan,
  WarningTooltipRefresher,
} from 'modules/common/components';

import { ReactComponent as IconDot } from 'images/icon-dot.svg';
import { ReactComponent as IconInfo } from 'images/icon-info.svg';
import { ReactComponent as IconPlus } from 'images/icon-plus.svg';
import { ReactComponent as IconTick } from 'images/tick.svg';
import { generateConfirmationToken } from 'store/actions/userActions';

interface AccountCardProps {
  userData: UserProfileDTO;
  onValidateEmail: () => void;
}

function AccountCard({ userData, onValidateEmail }: AccountCardProps) {
  const { t } = useTranslation(['auth', 'common', 'profile']);
  const dispatch = useDispatch();

  const subscriptionExpired = checkDateInThePast(userData.subscriptionEnd!);
  const addSecurityKeyDisabled =
    subscriptionExpired && userData.passphraseOptOut;

  const [showPasswordProcess, setShowPasswordProcess] = React.useState(false);
  const [show2FAProcess, setShow2FAProcess] = React.useState(false);
  const [showSecuritySettingProcess, setShowSecuritySettingProcess] =
    React.useState(false);
  const [emailChangingModal, setEmailChangingModal] = React.useState(false);

  const { start: startTimer, started: timerStarted } = useCountdownTimer(10);

  const isEmailVerified = !!userData.verified;
  const isRegularUser = !!userData.regular;

  const generatedDots = [...Array(11)].map((_, idx) => (
    <IconDot key={idx} className="t-text-alpha-600 t-mr-1" />
  ));

  const sendVerificationEmail = () => {
    startTimer();
    onValidateEmail();
  };

  const onEditEmail = async () => {
    await dispatch(generateConfirmationToken());
    setEmailChangingModal(true);
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      {showPasswordProcess && (
        <PasswordEditProcess
          handleShowPasswordProcess={setShowPasswordProcess}
        />
      )}

      {show2FAProcess && (
        <TwoFactorAuthProcess handleShow2FAProcess={setShow2FAProcess} />
      )}

      {showSecuritySettingProcess && (
        <SecuritySettingProcess
          onClose={() => {
            setShowSecuritySettingProcess(false);
          }}
        />
      )}

      {emailChangingModal && (
        <ChangingEmailModal
          onClose={() => {
            setEmailChangingModal(false);
          }}
        />
      )}

      <div
        className="t-shadow-lg t-rounded t-pt-5 t-pl-6 t-pb-6 t-pr-7 t-bg-gamma-400 t-mb-20"
        data-testid="account-card"
      >
        {isEmailVerified && (
          <div className="t-flex t-items-center t-mb-4">
            <IconTick className="t-w-4 t-h-5 t-mr-2 t-text-delta-700" />
            <p className="typo-epsilon t-text-delta-700">
              {t('profile:email_verified')}
            </p>
          </div>
        )}
        <div className="t-flex t-justify-between t-items-center t-mb-6">
          <p className="typo-delta">{`${t('common:email')}: ${
            userData.email ? userData.email : ''
          }`}</p>
          {isEmailVerified ? (
            <Button
              category="tertiary"
              className="t-tracking-widest t-whitespace-nowrap t-ml-3"
              onClick={onEditEmail}
            >
              {t('common:edit')}
            </Button>
          ) : (
            <Button
              category="secondary"
              onClick={sendVerificationEmail}
              className={!timerStarted ? 't-text-zeta-600 t-bg-beta-100' : ''}
              disabled={timerStarted}
            >
              {t('common:email_verify_button_label')}
            </Button>
          )}
        </div>

        <div
          className={`t-flex t-justify-between ${
            isRegularUser ? 't-mb-6' : ''
          }`}
        >
          <p className="typo-delta t-w-full t-flex t-flex-col sm:t-flex-row t-items-start sm:t-items-center sm:t-justify-between">
            <span className="t-mr-1">{`${t('common:password_label')}:`}</span>
            <span className="t-flex t-items-center t-mt-2 sm:t-mt-0">
              {generatedDots}
            </span>
          </p>
          <div>
            <Button
              category="tertiary"
              className="t-tracking-widest t-whitespace-nowrap t-ml-3"
              onClick={() => setShowPasswordProcess(true)}
              testId="password-edit-button"
            >
              {t('common:edit')}
            </Button>
          </div>
        </div>

        {isRegularUser && (
          <div className="t-flex t-justify-between t-items-center t-mb-6">
            <p
              className={`typo-delta t-flex t-w-full ${
                userData.tfaEnabled || userData.smsTfaEnabled
                  ? 't-flex-col sm:t-flex-row t-items-start sm:t-items-center sm:t-justify-between'
                  : ''
              }`}
            >
              <TextWithHyphenSpan className="t-mr-1">
                {t('profile:two_factor_auth_label')}
                {userData.tfaEnabled || userData.smsTfaEnabled ? (
                  ':'
                ) : (
                  <IconInfo
                    role="presentation"
                    data-tip={t('profile:two_factor_auth_tooltip')}
                    className="t-inline t-text-delta-700 t-ml-1 t-mb-1 sm:t-mb-0"
                  />
                )}
              </TextWithHyphenSpan>
              <span className="t-whitespace-nowrap">
                {userData.smsTfaEnabled && userData.phone
                  ? securePhoneNumber(userData.phone)
                  : userData.tfaEnabled
                  ? t('profile:via_google')
                  : ''}
              </span>
            </p>

            <div>
              {userData.tfaEnabled || userData.smsTfaEnabled ? (
                <Button
                  category="tertiary"
                  className="t-whitespace-nowrap t-ml-3 t-mt-0.5"
                  onClick={() => setShow2FAProcess(true)}
                >
                  {t('common:edit')}
                </Button>
              ) : (
                <Button
                  category="tertiary"
                  className="t-whitespace-nowrap t-ml-3 t-mt-0.5 t-items-center"
                  onClick={() => setShow2FAProcess(true)}
                  testId="add-new-tfa-button"
                >
                  <IconPlus className="t-mr-2" />
                  <span>{t('common:add')}</span>
                </Button>
              )}
            </div>
          </div>
        )}

        {isRegularUser && (
          <div>
            <div className="t-flex t-justify-between">
              <p
                className={`typo-delta t-flex t-w-full ${
                  !userData.passphraseOptOut
                    ? 't-flex-col sm:t-flex-row t-items-start sm:t-items-center sm:t-justify-between'
                    : ''
                }`}
              >
                <TextWithHyphenSpan className="t-mr-1">
                  {!userData.passphraseOptOut
                    ? t('profile:security_key_label_word_break')
                    : t('profile:security_key_label')}
                  {!userData.passphraseOptOut ? (
                    ':'
                  ) : (
                    <IconInfo
                      role="presentation"
                      data-tip={t('profile:security_key_tooltip')}
                      className="t-inline t-text-delta-700 t-ml-1 t-mb-1 sm:t-mb-0"
                    />
                  )}
                </TextWithHyphenSpan>

                {!userData.passphraseOptOut && (
                  <span
                    className="t-flex t-items-center t-mt-2 sm:t-mt-0"
                    data-testid="masked-passphrase"
                  >
                    {generatedDots}
                  </span>
                )}
              </p>

              <WarningTooltipRefresher id="security-button">
                <div
                  data-tip={
                    addSecurityKeyDisabled
                      ? t('common:feature_use_subscription_expired_tooltip')
                      : t('profile:security_key_setting_disabled_tooltip')
                  }
                  data-tip-disable={
                    userData?.profile === 1 && !addSecurityKeyDisabled
                  }
                  data-for="security-button"
                >
                  <Button
                    category="tertiary"
                    className="t-whitespace-nowrap t-ml-3"
                    onClick={() => {
                      setShowSecuritySettingProcess(true);
                    }}
                    testId="securitySettingsButton"
                    disabled={userData?.profile !== 1 || addSecurityKeyDisabled}
                  >
                    {!userData.passphraseOptOut ? (
                      t('common:edit')
                    ) : (
                      <>
                        <IconPlus className="t-mr-2" />
                        <span>{t('common:add')}</span>
                      </>
                    )}
                  </Button>
                </div>
              </WarningTooltipRefresher>
            </div>
            <div>
              {userData.document?.documentTitle && !userData.passphraseOptOut && (
                <div className="t-mb-2 5">
                  <PdfDownloadLink
                    document={userData.document}
                    tooltip={t('profile:after_death_key_document_tooltip')}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AccountCard;
