import { UPDATE_CONTACT, DELETE_CONTACT } from 'store/types/contactActionTypes';
import helper from 'store/reducers/reducerHelper';
import initialState from 'store/reducers/initialState';
import { LOAD_OVERVIEW_CONTACT } from 'store/types/overviewContactTypes';
import {
  DELETE_ASSET,
  DELETE_DOCUMENT,
  EDIT_ASSET,
} from 'store/types/documentActionTypes';

const overviewContactReducer = (
  state = initialState.overviewContact,
  action
) => {
  return helper.handleActions(state, action, {
    [LOAD_OVERVIEW_CONTACT](state, payload) {
      state.item = payload.overviewContact as ContactDTO;
    },

    [UPDATE_CONTACT]: updateOverviewContact,

    [DELETE_CONTACT]: deleteOverviewContact,

    [EDIT_ASSET]: editAsset,

    [DELETE_DOCUMENT]: deleteDocument,

    [DELETE_ASSET]: deleteAsset,
  });
};

export default overviewContactReducer;

function deleteOverviewContact(state, { id }) {
  if (state.item && state.item.id === id) {
    state.item = null;
  }
}

function updateOverviewContact(state, { contactData }) {
  const { id } = contactData;

  if (state.item && state.item.id === id) {
    state.item = { ...state.item, ...contactData };
  }
}

const getAssetEntries = (
  assetId: string,
  assets: { [asset in ContactAssetKey]: AssetDTO[] }
) => {
  let result: null | [string, number] = null;

  Object.entries<AssetDTO[]>(assets).forEach(([key, items]) => {
    items.forEach((asset, i) => {
      if (asset.id === assetId) {
        result = [key, i];
      }
    });
  });

  return result;
};

function editAsset(state, { assetData }) {
  if (state.item) {
    const { id } = assetData;
    const entries = getAssetEntries(id, state.item.assets);

    if (entries) {
      const [key, i]: [string, number] = entries;
      state.item.assets[key][i] = assetData;
    }
  }
}

function deleteAsset(state, payload) {
  if (state.item) {
    const { id } = payload;

    const entries = getAssetEntries(id, state.item.assets);

    if (entries) {
      const [key, i]: [string, number] = entries;
      delete state.item.assets[key][i];
    }
  }
}

function deleteDocument(state, payload) {
  if (state.item) {
    const { id, assetId } = payload;

    const entries = getAssetEntries(assetId, state.item.assets);

    if (entries) {
      const [key, i]: [string, number] = entries;
      state.item.assets[key][i].documents = state.item.assets[key][
        i
      ].documents.filter((doc) => doc.id !== id);
    }
  }
}
