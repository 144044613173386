import { dateHelper } from 'modules/common/helpers';

import icons from 'modules/assistant/constants/questionnaires/icons';

import { STEP_COMPONENT_TYPE as C } from 'modules/assistant/constants/questionnaires';
import { QUESTIONNAIRE_FORM_FIELD_TYPES as F } from 'modules/assistant/constants/questionnaires/questionnaireFormFieldTypes';

export enum STEP_KEYS {
  STEP_START = 'start',
  STEP_1 = 'isAddCustodialPerson',
  STEP_1_1 = 'addCustodialPerson',
  STEP_2 = 'addAuthorizedPerson',
  STEP_3 = 'isAddSubstitute',
  STEP_3_1 = 'addSubstitute',
  STEP_4 = 'isExcludePerson',
  STEP_4_1 = 'addExcludedPerson',
  STEP_4_2 = 'addExcludedReason',
  STEP_END = 'end',
}
const S = STEP_KEYS;

export enum CUSTODY_ORDER_ANSWER_KEYS {
  YES = 'true',
  NO = 'false',
}

const A = CUSTODY_ORDER_ANSWER_KEYS;

export const getCustodyOrderQuestionnaire = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    [S.STEP_START]: {
      content: t('s_start'),
      type: C.START,
      linksTo: S.STEP_1,
    },
    [S.STEP_1]: {
      content: t('s_1'),
      current: 1,
      total: 8,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_1_1 },
        { id: A.NO, linksTo: S.STEP_2 },
      ],
      qa: [
        { question: t('s_1_qa_q_1'), answer: t('s_1_qa_a_1') },
        { question: t('s_1_qa_q_2'), answer: t('s_1_qa_a_2') },
        { question: t('s_1_qa_q_3'), answer: t('s_1_qa_a_3') },
      ],
    },
    [S.STEP_1_1]: {
      content: t('s_1_1'),
      current: 2,
      total: 8,
      type: C.ADD_INFORMATION,
      linksTo: S.STEP_2,
      formElements: [
        {
          type: F.FIRST_NAME,
          label: t('s_1_1_form_field_label_1'),
          required: true,
          span: 6,
        },
        {
          type: F.LAST_NAME,
          label: t('s_1_1_form_field_label_2'),
          required: true,
          span: 6,
        },
        {
          type: F.ADDRESS,
          label: t('s_1_1_form_field_label_3'),
          required: false,
          span: 12,
        },
        {
          type: F.BIRTHDAY,
          label: t('s_1_1_form_field_label_4'),
          required: false,
          span: 6,
          extraValidation: (value) => {
            if (!value) return true;
            if (dateHelper.checkIsNotPastDate(value))
              return dateHelper.checkIsNotPastDate(value);
            return true;
          },
        },
        {
          type: F.BIRTHPLACE,
          label: t('s_1_1_form_field_label_5'),
          required: false,
          span: 6,
        },
        {
          type: F.TEXT,
          label: t('s_1_1_form_field_label_6'),
          required: false,
          span: 6,
        },
      ],
      qa: [
        { question: t('s_1_qa_q_1'), answer: t('s_1_qa_a_1') },
        { question: t('s_1_qa_q_2'), answer: t('s_1_qa_a_2') },
        { question: t('s_1_qa_q_3'), answer: t('s_1_qa_a_3') },
      ],
    },
    [S.STEP_2]: {
      content: t('s_2'),
      current: 3,
      total: 8,
      type: C.MULTI_CONTACTS,
      linksTo: S.STEP_3,
      qa: [
        { question: t('s_2_qa_q_1'), answer: t('s_2_qa_a_1') },
        { question: t('s_2_qa_q_2'), answer: t('s_2_qa_a_2') },
        { question: t('s_2_qa_q_3'), answer: t('s_2_qa_a_3') },
        { question: t('s_2_qa_q_4'), answer: t('s_2_qa_a_4') },
      ],
    },
    [S.STEP_3]: {
      content: t('s_3'),
      current: 4,
      total: 8,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_3_1 },
        { id: A.NO, linksTo: S.STEP_4 },
      ],
      qa: [{ question: t('s_3_qa_q_1'), answer: t('s_3_qa_a_1') }],
    },
    [S.STEP_3_1]: {
      content: t('s_3_1'),
      current: 5,
      total: 8,
      type: C.MULTI_CONTACTS,
      linksTo: S.STEP_4,
      qa: [{ question: t('s_3_qa_q_1'), answer: t('s_3_qa_a_1') }],
    },
    [S.STEP_4]: {
      content: t('s_4'),
      current: 6,
      total: 8,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_4_1 },
        { id: A.NO, linksTo: S.STEP_END },
      ],
      qa: [{ question: t('s_4_qa_q_1'), answer: t('s_4_qa_a_1') }],
    },
    [S.STEP_4_1]: {
      content: t('s_4_1'),
      current: 7,
      total: 8,
      type: C.MULTI_CONTACTS,
      linksTo: S.STEP_4_2,
      qa: [{ question: t('s_4_qa_q_1'), answer: t('s_4_qa_a_1') }],
    },
    [S.STEP_4_2]: {
      content: t('s_4_2'),
      current: 8,
      total: 8,
      type: C.FREE_TEXT,
      linksTo: S.STEP_END,
      qa: [{ question: t('s_4_2_qa_q_1'), answer: t('s_4_2_qa_a_1') }],
    },
    [S.STEP_END]: {
      content: t('s_end'),
      type: C.END,
    },
  };
};

export const getCustodyOrderAnswers = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);

  return {
    [A.YES]: {
      content: t('a_true'),
      image: icons.imgYes,
    },
    [A.NO]: {
      content: t('a_false'),
      image: icons.imgNo,
    },
  };
};

export const getCustodyOrderStartScreen = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    listItems: [
      t('start_screen_list_1'),
      t('start_screen_list_2'),
      t('start_screen_list_3'),
    ],
  };
};
