import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { reactToast, useTranslation } from 'modules/common/helpers';

import imgReferral from 'images/img-referral.png';
import { Button } from 'modules/common/components';

export default function MyContactsReferralSection() {
  const { t } = useTranslation(['referral', 'toastr']);

  const userData: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const referralLink = userData.referral;

  const copyToClipboard = () => {
    if (referralLink) {
      navigator.clipboard.writeText(referralLink);
      reactToast.showMessage(t('toastr:referral_successfully_copied'));
    }
  };

  return (
    <div className="card t-py-7 t-px-10 t-mt-8 xl:t-mt-0 xl:t-ml-4 t-mr-2">
      <img src={imgReferral} className="t-max-w-56" alt="Referral program" />
      <h1 className="typo-alpha t-mt-8">
        {t('referral:referral_my_contacts_page_title')}
      </h1>
      <p className="typo-epsilon t-mt-3">
        {t('referral:referral_my_contacts_page_description')}
      </p>
      <div className="t-bg-beta-100 t-rounded-sm t-px-3 t-py-2 t-mt-3">
        {referralLink}
      </div>

      <Button className="t-mt-3" onClick={copyToClipboard}>
        {t('referral:referral_my_contacts_page_copy_button')}
      </Button>
    </div>
  );
}
