import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { scrollToElementId, useTranslation } from 'modules/common/helpers';
import { getSelectOptions } from 'modules/contact/helpers';

import { FormControl } from 'modules/common/types';
import { Col, Row } from 'modules/bootstrap/components';
import {
  GreyLinedContainer,
  InfoTooltip,
  SelectController,
} from 'modules/common/components';
import { Checkbox } from 'modules/elements/components';

import { SUBSTITUTE_CONTACT } from 'modules/contact/constants';

interface ContactSubstituteSelectionProps {
  control: FormControl;
  contactItem?: ContactDTO;
  scrollToId?: string;
}

const ContactSubstituteSelection = ({
  contactItem,
  control,
  scrollToId,
}: ContactSubstituteSelectionProps) => {
  const { t } = useTranslation(['common']);

  const [isSubstituteChecked, setIsSubstituteChecked] = React.useState(
    !!contactItem?.substituteContactId
  );
  const [isHightLighted, setIsHighlighted] = React.useState(
    scrollToId === SUBSTITUTE_CONTACT
  );

  React.useEffect(() => {
    if (scrollToId === SUBSTITUTE_CONTACT) {
      scrollToElementId(scrollToId);
    }
  }, []);

  const contactsData: ContactDTO[] = useSelector(
    (state: RootState) => state.contacts.list
  );
  const contactsOptions = getSelectOptions(contactsData).filter(
    (b) => b.value !== contactItem?.id
  );
  const defaultContactOption = contactsOptions.find(
    (item) => item?.value === contactItem?.substituteContactId
  );

  const handleSubstituteCheckboxClick = () => {
    setIsSubstituteChecked((prevState) => !prevState);
    setIsHighlighted(false);
  };

  return (
    <Row data-testid="ContactSubstituteSelection">
      <Col
        xl={6}
        className={`xl:t-h-12 ${!isHightLighted ? 't-pb-2 t-mt-1.5' : ''}`}
        id={SUBSTITUTE_CONTACT}
      >
        <GreyLinedContainer isLineVisible={isSubstituteChecked}>
          <div
            className={`t-flex t-items-center ${
              isHightLighted
                ? 't-rounded-sm t-border t-border-solid t-border-epsilon-600 t-p-2'
                : ''
            }`}
          >
            <Checkbox
              text={t('common:substitute_contact_label')}
              checked={isSubstituteChecked}
              onChange={handleSubstituteCheckboxClick}
              id="substituteCheckbox"
              textClassName="text-main"
              isBlueBg
            />
            <div className="t-ml-1">
              <InfoTooltip tipText={t('common:substitute_contact_info')} />
            </div>
          </div>
        </GreyLinedContainer>
      </Col>
      <Col xl={6}>
        {isSubstituteChecked && (
          <SelectController
            id="substituteContactId"
            name="substituteContactId"
            options={contactsOptions}
            control={control}
            defaultValue={defaultContactOption}
            rules={{ required: true }}
            noOptionsMessage={t('common:no_contact_options_message')}
          />
        )}
      </Col>
    </Row>
  );
};

export default ContactSubstituteSelection;
