import { useMemo } from 'react';

const useGetCountryOptionByCode = (
  code?: string,
  countries?: SelectOption[]
): SelectOption => {
  return useMemo(() => {
    const defaultOption = {
      label: '',
      value: '',
    };

    if (!code || !Array.isArray(countries)) {
      return defaultOption;
    }

    const result = countries.find((country) => code === country.value);

    return result || defaultOption;
  }, [code, countries]);
};

export default useGetCountryOptionByCode;
