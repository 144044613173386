import produce from 'immer';

import initialState from 'store/reducers/initialState';
import { LOAD_RECOMMENDATIONS } from 'store/types/recommendationsActionTypes';

const recommendationsReducer = (
  state = initialState.recommendations,
  action: ReducerAction
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_RECOMMENDATIONS: {
        const {
          payload: { recos },
        } = action;

        Object.keys(recos).forEach((reco) => {
          draft[reco] = recos[reco];
        });

        return draft;
      }

      default:
        return draft;
    }
  });

export default recommendationsReducer;
