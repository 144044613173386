import styled from 'styled-components';

const Textarea = styled.textarea.attrs((props) => ({
  placeholder: props.placeholder || '',
}))`
  outline: none;
  resize: none;
  background-color: ${({ bg }) => bg || 'var(--color-gamma-400)'};
  overflow: auto;
  margin: ${({ m }) => m || '0'};
  padding: ${({ p }) => p || '10px'};
  width: ${({ width }) => width || '100%'};
  border-radius: ${({ borderRadius }) =>
    borderRadius || 'var(--border-radius-sm)'};
  border: ${({ border }) => border || `1px solid var(--color-beta-100);`};
  font-size: ${({ fontSize }) => fontSize || 'var(--font-size-md)'};
  font-family: inherit;
  &:focus {
    border: 1px solid var(--color-delta-300);
    outline: none;
  }
`;

export default Textarea;
