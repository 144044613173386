import i18n from 'i18next';
import { saveAs } from 'file-saver';

import { assetService } from 'modules/estatePlan/services';
import { setAssetToEdit } from 'store/actions/layoutActions';
import { getAssetType } from 'store/actions/assetTypeActions';
import { getContactsList } from 'store/actions/contactActions';
import { getRecommendations } from 'store/actions/recommendationsActions';
import {
  GET_ASSETS,
  GET_ASSETS_BY_CATEGORY,
  ADD_ASSET,
  EDIT_ASSET,
  DELETE_ASSET,
  DELETE_DOCUMENT,
  GET_SHARED_ASSETS,
} from 'store/types/assetTypes';

import helper from 'store/actions/actionHelper';
import {
  getBlobObj,
  getSuccessMessage,
  reactToast,
} from 'modules/common/helpers';

const updateRelatedStoreData = (dispatch) => {
  // Update contact assignedAssets field in contact data
  dispatch(getContactsList());
  // update the recommendations if the action (add/delete) of the asset changes status of a recommendation
  dispatch(getRecommendations());
};

export const getAssetsList = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const assets = await assetService.getAssetsList();

    dispatch(helper.successAction(GET_ASSETS, { assets }));
  }, actionOptions);
};

export const getBeneficiaryAssetsList = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const assets = await assetService.getBeneficiaryAssetsList();

    dispatch(helper.successAction(GET_ASSETS, { assets }));
  }, actionOptions);
};

export const getAsset = (
  id: string,
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await assetService.getAsset(id, hideErrors);

    return response;
  }, actionOptions);
};

export const addAsset = (
  assetData: AssetDTO,
  category: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const newAsset = await assetService.addAsset(assetData, true);
    if (!newAsset?.errorCode) {
      dispatch(
        helper.successAction(ADD_ASSET, { newAsset, assetCategory: category })
      );
      reactToast.showMessage(i18n.t('toastr:asset_added_success'));
    }

    updateRelatedStoreData(dispatch);

    return newAsset;
  }, actionOptions);
};

export const editAsset = (
  assetData: AssetDTO,
  category: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const alteredAsset = await assetService.editAsset(assetData, true);
    dispatch(
      helper.successAction(EDIT_ASSET, {
        alteredAsset,
        assetCategory: category,
      })
    );
    reactToast.showMessage(i18n.t('toastr:asset_updated_success'));

    // Update contact assignedAssets field in contact data
    dispatch(getContactsList());

    return alteredAsset;
  }, actionOptions);
};

export const listAssetsByCategory = (
  category: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const assetsByCategory = await assetService.getAssetsByCategory(category);
    dispatch(
      helper.successAction(GET_ASSETS_BY_CATEGORY, {
        assets: assetsByCategory,
        assetCategory: category,
      })
    );
  }, actionOptions);
};

export const deleteAsset = (
  id: string,
  category: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    await assetService.deleteAsset(id);
    dispatch(
      helper.successAction(DELETE_ASSET, { id, assetCategory: category })
    );

    updateRelatedStoreData(dispatch);

    return true;
  }, actionOptions);
};

export const uploadToNewAsset = (
  data: FormData,
  category: string,
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const newAsset = await assetService.uploadToNewAsset(data, hideErrors);
    dispatch(
      helper.successAction(ADD_ASSET, { newAsset, assetCategory: category })
    );

    const assetType = (await dispatch(
      getAssetType(newAsset.assetTypeId)
    )) as unknown as AssetTypeDTO;
    dispatch(setAssetToEdit(newAsset, assetType));
    reactToast.showMessage(i18n.t('toastr:asset_added_success'));

    updateRelatedStoreData(dispatch);

    return newAsset;
  }, actionOptions);
};

export const uploadToAsset = (
  data: FormData,
  category: string,
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const alteredAsset = await assetService.uploadToAsset(data, hideErrors);
    dispatch(
      helper.successAction(EDIT_ASSET, {
        alteredAsset,
        assetCategory: category,
      })
    );

    const assetType = (await dispatch(
      getAssetType(alteredAsset.assetTypeId)
    )) as unknown as AssetTypeDTO;
    dispatch(setAssetToEdit(alteredAsset, assetType));
    reactToast.showMessage(
      i18n.t('toastr:entity_attached_success', {
        entity:
          alteredAsset.documents[alteredAsset.documents.length - 1]
            .documentTitle,
      })
    );

    return alteredAsset;
  }, actionOptions);
};

export const downloadDocument = (
  document: DocumentDTO,
  actionOptions?: ActionOptions
) => {
  const { id, documentTitle } = document;

  if (!id) return false;

  return helper.dispatchAsyncAction(async () => {
    const data: { id: string; passphrase?: string } = { id };

    const response: any = await assetService.downloadAsset(data);

    reactToast.showMessage(`${documentTitle} has been downloaded`);

    const blobObj = getBlobObj(response);
    const fileUrl = URL.createObjectURL(blobObj);

    saveAs(blobObj, documentTitle);

    window.open(fileUrl);

    return response;
  }, actionOptions);
};

export const deleteDocument = (
  id: string,
  assetId,
  assetCategory: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const response = await assetService.deleteDocument(id);

    dispatch(
      helper.successAction(DELETE_DOCUMENT, { id, assetId, assetCategory })
    );
    reactToast.showMessage(getSuccessMessage(response.message));

    return true;
  }, actionOptions);
};

export const getSharedAssets = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const sharedAssets = await assetService.getSharedAssets();
    dispatch(helper.successAction(GET_SHARED_ASSETS, { sharedAssets }));

    return sharedAssets;
  }, actionOptions);
};
