import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { format, isSameDay, subDays } from 'date-fns';

import { addAssetReminder } from 'store/actions/remindActions';
import { getAssetsList } from 'store/actions/assetActions';

import { AssetCardReminderButton } from 'modules/asset/components/AssetCard';
import { DatePicker } from 'modules/common/components';

import { NOTIFICATION_CODES as NC } from 'modules/notification/constants';
interface AssetReminderProps {
  assetId: string;
  reminder: string;
}

const AssetReminder = ({ assetId, reminder }: AssetReminderProps) => {
  const dispatch = useDispatch();
  const [isReminderOpen, setIsReminderOpen] = useState(false);
  const [newReminder, setNewReminder] = useState(reminder || new Date());
  const [dateToSubmit, setDateToSubmit] = useState('');

  const handleReminderToggle = () => {
    setIsReminderOpen((prevState) => !prevState);
  };

  const type = NC.UPDATE_PASSWORD_REMINDER;

  const handleReminderChange = (val) => {
    setNewReminder(val);
    const formattedDate = format(new Date(val), 'yyyy-MM-dd');
    setDateToSubmit(formattedDate);
  };

  const handleReminderSubmit = async () => {
    await dispatch(addAssetReminder(dateToSubmit, assetId, type));
    await dispatch(getAssetsList());
    handleReminderToggle();
  };

  const handleReminderDelete = async () => {
    await dispatch(addAssetReminder('', assetId, type));
    await dispatch(getAssetsList());
    setNewReminder(new Date());
    handleReminderToggle();
  };

  useEffect(() => {
    setNewReminder(reminder || format(new Date(), 'yyyy-MM-dd'));
  }, [reminder]);

  return (
    <>
      <AssetCardReminderButton
        reminder={reminder}
        isReminderOpen={isReminderOpen}
        handleClick={handleReminderToggle}
      />
      {isReminderOpen && (
        <DatePicker
          date={newReminder}
          minDate={subDays(new Date(), -1)}
          onSubmit={handleReminderSubmit}
          onChange={(val) => handleReminderChange(val)}
          onClose={handleReminderToggle}
          disabled={isSameDay(new Date(newReminder), new Date())}
          onDelete={handleReminderDelete}
          showTrash={!!reminder}
        />
      )}
    </>
  );
};

export default AssetReminder;
