import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import {
  changePassphrase,
  getCurrentUser,
  passphraseOptOut,
} from 'store/actions/userActions';
import { getKeyHolders } from 'store/actions/keyHolderActions';
import { checkDateInThePast } from 'modules/common/helpers';

import {
  SecurityKeyEditSelection,
  SecurityKeyFinal,
  SecuritySettingSelection,
  SecurityKeyForm,
  SetSecurityKeyProcess,
  SetKeyHoldersModal,
} from 'modules/securityKeyWizard/components';
import { Modal } from 'modules/common/components';

import {
  SECURITY_KEY_EDIT_TYPE,
  SECURITY_SETTING_OPTIONS,
  SECURITY_SETTING_STEPS,
  SET_SECURITY_KEY_STEPS,
} from 'modules/securityKeyWizard/constants';

export interface SecuritySettingProcessProps {
  onClose: () => void;
  onCancel?: () => void;
  startingStep?: string;
  hasCloseCross?: boolean;
}

const SecuritySettingProcess = ({
  onClose,
  onCancel,
  startingStep,
  hasCloseCross,
}: SecuritySettingProcessProps) => {
  const dispatch = useDispatch();

  const userData: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const keyHolders: KeyHolder[] = useSelector(
    (state: RootState) => state.keyHolders.value
  );

  const [currentKeyHolders, setCurrentKeyHolder] = React.useState(
    [] as KeyHolder[]
  );

  const subscriptionExpired = checkDateInThePast(userData.subscriptionEnd!);

  React.useEffect(() => {
    dispatch(getKeyHolders());
  }, []);

  React.useEffect(() => {
    setCurrentKeyHolder(keyHolders);
  }, [keyHolders]);

  const initialStep = userData.passphraseOptOut
    ? SECURITY_SETTING_STEPS.SELECT_SECURITY_SETTING
    : SECURITY_SETTING_STEPS.SELECT_SECURITY_EDIT_TYPE;

  const [step, setStep] = React.useState(startingStep || initialStep);

  const [keyHolderProcessStep, setKeyHolderProcessStep] = React.useState(
    '' as SET_SECURITY_KEY_STEPS
  );

  // distinguish the current process - modifying or setting
  const [modifying, setModifying] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  React.useEffect(() => {
    if (step === SECURITY_SETTING_STEPS.SELECT_SECURITY_EDIT_TYPE) {
      setModifying(true);
    } else if (step === SECURITY_SETTING_OPTIONS.SET_SECURITY_KEY) {
      setModifying(false);
    }
  }, [step]);

  const onSelectSettingType = (settingType: string) => {
    if (settingType === SECURITY_SETTING_OPTIONS.STANDARD) {
      // set the keyHolders to an empty [] when the user chooses standard setting
      // this avoid the keyHolders (not empty) being transferred to the final step
      setCurrentKeyHolder([]);

      // hide security information when the user chooses standard setting = delete security key
      setDeleting(true);

      setStep(SECURITY_SETTING_STEPS.FINAL);
    } else {
      setStep(SECURITY_SETTING_STEPS.SET_SECURITY_KEY);
    }
  };

  const onSelectEditType = (editType: string) => {
    if (editType === SECURITY_KEY_EDIT_TYPE.DELETE_SECURITY_KEY) {
      setDeleting(true);
    }
    setStep(editType);
  };

  const onProcessComplete = async () => {
    await dispatch(getCurrentUser());
    onClose();
  };

  const handleProcessComplete = async () => {
    if (onCancel) onCancel();
    else await onProcessComplete();
  };

  const onSecurityKeySubmit = async ({
    passphrase,
    oldPassphrase,
  }: Record<string, string>) => {
    let response: any;
    switch (step) {
      case SECURITY_KEY_EDIT_TYPE.CHANGE_SECURITY_KEY: {
        response = await dispatch(changePassphrase(oldPassphrase, passphrase));
        break;
      }
      case SECURITY_KEY_EDIT_TYPE.DELETE_SECURITY_KEY: {
        response = await dispatch(passphraseOptOut(oldPassphrase));
        await dispatch(getKeyHolders());
        break;
      }
      case SECURITY_SETTING_OPTIONS.STANDARD:
      default:
        break;
    }
    if (!!response) setStep(SECURITY_SETTING_STEPS.FINAL);
  };

  const goToAddKeyHolders = () => {
    setKeyHolderProcessStep(SET_SECURITY_KEY_STEPS.SET_KEY_HOLDERS);
    setStep(SECURITY_SETTING_OPTIONS.SET_SECURITY_KEY);
  };

  const onSetSecurityProcessComplete = (noKeyHolder?: boolean) => {
    if (noKeyHolder) {
      setCurrentKeyHolder([]);
    } else {
      setCurrentKeyHolder(keyHolders);
    }
    setStep(SECURITY_SETTING_STEPS.FINAL);
  };

  const renderStepComponent = () => {
    switch (step) {
      case SECURITY_SETTING_OPTIONS.SET_SECURITY_KEY:
        return (
          <SetSecurityKeyProcess
            handleProcessComplete={onSetSecurityProcessComplete}
            onCancelSetKeysStep={() => {
              setStep(SECURITY_SETTING_STEPS.SELECT_SECURITY_SETTING);
            }}
            keyHolders={keyHolders}
            chosenStep={keyHolderProcessStep}
          />
        );

      case SECURITY_SETTING_STEPS.SELECT_SECURITY_EDIT_TYPE:
        return (
          <SecurityKeyEditSelection
            onClose={onClose}
            onSelectEditType={(editType: string) => {
              onSelectEditType(editType);
            }}
            keyHolders={keyHolders}
            subscriptionExpired={subscriptionExpired}
          />
        );

      case SECURITY_KEY_EDIT_TYPE.CHANGE_SECURITY_KEY:
        return (
          <SecurityKeyForm
            onSubmit={onSecurityKeySubmit}
            onCancel={() => {
              setStep(SECURITY_SETTING_STEPS.SELECT_SECURITY_EDIT_TYPE);
            }}
            editing={true}
          />
        );

      case SECURITY_KEY_EDIT_TYPE.DELETE_SECURITY_KEY:
        return (
          <SecurityKeyForm
            onSubmit={onSecurityKeySubmit}
            onCancel={onClose}
            deleting={true}
          />
        );

      case SECURITY_KEY_EDIT_TYPE.MANAGE_KEY_HOLDERS:
        return (
          <SetKeyHoldersModal
            onSubmit={onClose}
            onBack={onClose}
            managing={true}
            keyHolders={keyHolders}
            subscriptionExpired={subscriptionExpired}
          />
        );

      case SECURITY_SETTING_STEPS.FINAL:
        return (
          <Modal show dataTestid="SetKeyHoldersModal">
            <SecurityKeyFinal
              onCancel={onProcessComplete}
              withKeyHolders={!!currentKeyHolders.length}
              handleAddKeyHolders={goToAddKeyHolders}
              modifying={modifying}
              deleting={deleting}
            />
          </Modal>
        );
      default:
        return (
          <SecuritySettingSelection onSelectSettingType={onSelectSettingType} />
        );
    }
  };

  return (
    [
      SECURITY_SETTING_OPTIONS.SET_SECURITY_KEY,
      SECURITY_SETTING_STEPS.FINAL,
      SECURITY_KEY_EDIT_TYPE.MANAGE_KEY_HOLDERS,
    ] as string[]
  ).includes(step) ? (
    renderStepComponent()
  ) : (
    <Modal
      show
      onHide={handleProcessComplete}
      hasCloseCross={hasCloseCross}
      dataTestid="SecuritySettingProcess"
    >
      {renderStepComponent()}
    </Modal>
  );
};

export default SecuritySettingProcess;
