import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { WarningMessage } from 'modules/common/components';

interface AssetFormContactInfoWarningProps {
  selectedContactsMissingInfo: SelectOption[];
}

export default function AssetFormContactInfoWarning({
  selectedContactsMissingInfo,
}: AssetFormContactInfoWarningProps) {
  const { t } = useTranslation(['mcontacts']);
  if (!selectedContactsMissingInfo.length) return null;
  return (
    <div className="t-mt-3 t-bg-eta-100 t-rounded-sm t-py-1">
      {selectedContactsMissingInfo.map(({ value, label, missingInfo }) => (
        <WarningMessage key={value}>
          <span>{`${label}: `}</span>
          <span>
            {missingInfo?.birthday
              ? t('mcontacts:asset_form_date_of_birth')
              : ''}
          </span>
          <span>
            {missingInfo?.birthday && missingInfo.gender
              ? t('mcontacts:form_and_text')
              : ''}
          </span>
          <span>
            {missingInfo?.gender ? t('mcontacts:asset_form_gender') : ''}
          </span>
          <span>{t('mcontacts:asset_form_is_missing')}</span>
        </WarningMessage>
      ))}
    </div>
  );
}
