import { toast } from 'react-toastify';

export const showMessage = (message, id = '') => {
  toast.success(message, {
    progressClassName: 't-bg-alpha-200',
    style: { opacity: 0.8 },
    toastId: id || message,
  });
};

export const showWarning = (warning) => {
  toast.warning(warning, {
    progressClassName: 't-bg-zeta-200',
    style: { opacity: 0.8 },
    toastId: warning,
  });
};

export const showError = (error) => {
  toast.error(error, {
    progressClassName: 't-bg-epsilon-600',
    style: { opacity: 0.8 },
    toastId: error,
  });
};

export const showInfo = ({ text, options = {} }) => {
  toast.info(text, {
    progressClassName: 't-bg-alpha-600',
    style: { opacity: 0.8 },
    ...options,
  });
};
