import { kebabCase } from 'lodash';

import { httpHelper, config } from 'modules/common/helpers';

import { CONTENT_QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/contentQuestionnaires';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';

const BASE_URL = config.beUrl;
const GENERATOR_URL = config.documentGeneratorUrl;

export default {
  getQuestionnaireAnswers,
  addQuestionnaireAnswer,
  generateQuestionnairePDF,
  completeQuestionnaire,
};

function getQuestionnaireAnswers(questionnaire: QUESTIONNAIRE_KEYS) {
  return httpHelper.get(
    `${BASE_URL}/api/answers/${kebabCase(questionnaire)}`,
    {}
  );
}

// sends the entire current questionnaire object, instead of adding to it
function addQuestionnaireAnswer(
  questionnaire: QUESTIONNAIRE_KEYS | CONTENT_QUESTIONNAIRE_KEYS,
  questionnaireData: QuestionnaireDTO
) {
  return httpHelper.post(
    `${BASE_URL}/api/answers/${kebabCase(questionnaire)}`,
    { ...questionnaireData }
  );
}

function completeQuestionnaire(questionnaire: CONTENT_QUESTIONNAIRE_KEYS) {
  return httpHelper.post(
    `${BASE_URL}/api/answers/${kebabCase(questionnaire)}/done`,
    {}
  );
}

function generateQuestionnairePDF(
  questionnaire: QUESTIONNAIRE_KEYS,
  location: string
) {
  return httpHelper.post(
    `${GENERATOR_URL}/generator/api/${kebabCase(questionnaire)}`,
    { location }
  );
}
