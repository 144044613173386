import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from 'react-router-dom';

import { RootState } from 'store/reducers';
import { downloadDocument } from 'store/actions/assetActions';
import { generateContactAccessDocument } from 'store/actions/contactActions';
import { setModal } from 'store/actions/modalActions';
import { generateConfirmationToken } from 'store/actions/userActions';
import {
  isAllowedToken,
  dateHelper,
  config,
  scrollToElementId,
  optionsHelper,
  getFullName,
  useGetCountries,
  useGetCountryOptionByCode,
  useTranslation,
} from 'modules/common/helpers';

import { ContactInfoWarning } from 'modules/myContacts/components';
import { Spinner, Modal } from 'modules/common/components';
import { ContactForm } from 'modules/contact/components';
import {
  AddContactAsFamilyMemberConfirmModal,
  RemoveFamilyContactConfirmModal,
} from 'modules/familyAccount/components';

import { SUBSTITUTE_CONTACT } from 'modules/contact/constants';
import { MODALS } from 'modules/common/constants';

import { ReactComponent as DeleteIcon } from 'images/icon-delete.svg';
import { ReactComponent as MenuIcon } from 'images/icon-menu.svg';
import { ReactComponent as IconEdit } from 'images/icon-edit.svg';
import { ReactComponent as IconEye } from 'images/eye.svg';
import { ReactComponent as IconDownload } from 'images/icon-download.svg';
import { ReactComponent as EmailIcon } from 'images/mail-icon.svg';
import { ReactComponent as PhoneIcon } from 'images/phone-icon.svg';
import { ReactComponent as LocationIcon } from 'images/location-icon.svg';
import { ReactComponent as CheckIcon } from 'images/check.svg';
import { ReactComponent as IconDownloadDot } from 'images/download-icon-with-dot.svg';
import { ReactComponent as IconWarningTriangle } from 'images/icon-warning.svg';
import { ReactComponent as IconDocuments } from 'images/icon-documents.svg';
import { ReactComponent as IconPeople } from 'images/icon-people.svg';
import { ReactComponent as IconBirthday } from 'images/icon-birthday-cake.svg';
import { ReactComponent as IconPlus } from 'images/icon-plus.svg';

interface ContactCardProps {
  contactData: ContactDTO;
  onDelete?: (id: string, title: string) => void;
  onEdit?: (assetItem: ContactDTO) => void;
  switchToMyFamilyTab?: () => void;
  isViewAssetsAllowed?: boolean;
  className?: string;
  isDropdownMenuHidden?: boolean;
  isDocumentsGenerating?: boolean;
  isMyContactsPage?: boolean;
  highlight?: boolean;
}

function ContactCard({
  onDelete,
  onEdit,
  switchToMyFamilyTab,
  contactData,
  className,
  isDropdownMenuHidden,
  isViewAssetsAllowed,
  isDocumentsGenerating = false,
  isMyContactsPage = false,
  highlight = false,
}: ContactCardProps) {
  const { t } = useTranslation(['common', 'mcontacts']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countries = useGetCountries();

  const {
    gender,
    birthday,
    contactBy,
    id,
    contactedDate,
    assignedAssets,
    email,
    relation,
    phone,
    address,
    zip,
    city,
    country,
    substituteContactId,
    documentId = '',
    documentTitle = '',
  } = contactData;
  const relationshipOptions = optionsHelper.getRelationshipOptions();
  const isOtherRelation = !relationshipOptions.find(
    (option) => option.value === relation
  );
  const formattedCountry =
    useGetCountryOptionByCode(country, countries).label || '';

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const contactsData = useSelector((state: RootState) => state.contacts.list);
  const familyAccounts = useSelector(
    (state: RootState) => state.familyAccounts.list
  );

  const relatedFamilyAccount = familyAccounts.find(
    (account) => account.id === contactData?.secondaryUserId
  );
  const familyAccountExist = !!relatedFamilyAccount;
  const isDeceasedFamilyContact = !!relatedFamilyAccount?.deceased;

  const [isDownloading, setIsDownloading] = React.useState(false);
  const isRequiredInfoComplete = gender && birthday;
  const [, contactByPhone, contactByPost] = contactBy
    ?.split('')
    .map((strNum) => strNum === '1') || [true, false, false];
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [isFamilyAccountConfirmation, setIsFamilyAccountConfirmation] =
    React.useState(false);

  const contactName = getFullName(contactData);

  const title = t('mcontacts:delete_contact_confirm_title', { contactName });

  const substituteContact = substituteContactId
    ? contactsData.find((contact) => contact.id === substituteContactId)
    : null;

  const handleDocumentDownload = async () => {
    setIsDownloading(true);
    const document: DocumentDTO = {
      id: documentId,
      documentTitle,
    };
    await dispatch(downloadDocument(document));
    setIsDownloading(false);
  };

  const handleDocumentGenerate = async () => {
    setIsDownloading(true);
    if (!currentUser.passphraseOptOut && !isAllowedToken()) {
      dispatch(
        setModal({
          name: MODALS.PASSPHRASE,
          props: {
            successAction: handleDocumentGenerate,
          },
        })
      );
    } else {
      if (id) {
        await dispatch(generateContactAccessDocument(id, false));
      }
    }
    setIsDownloading(false);
  };

  const handleViewAssetsClick = () => navigate(`/contact/${id}`);

  const getAssignedAssetsLabel = (numOfAssets: number) => {
    if (numOfAssets > 1) {
      return `${numOfAssets} ${t('mcontacts:assigned_assets')}`;
    } else if (numOfAssets === 1) {
      return `${numOfAssets} ${t('mcontacts:assigned_asset')}`;
    } else {
      return t('mcontacts:no_assets_assigned');
    }
  };

  const handleAssignedAssetsClick = (event) => {
    event.stopPropagation();
    if (contactData?.assignedAssets) {
      navigate(`/contact/${id}`);
    }
  };

  const handleScrollToSubstituteCard = () => {
    if (substituteContact?.id) {
      scrollToElementId(substituteContact?.id);
    } else {
      setShowEditForm(true);
    }
  };

  const [familyAccountDeleting, setFamilyAccountDeleting] =
    React.useState(false);
  const handleDeleteContact = async () => {
    if (isMyContactsPage && familyAccountExist) {
      await dispatch(generateConfirmationToken());
      setFamilyAccountDeleting(true);
    } else if (onDelete) {
      onDelete(id || '', title);
    }
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  const contactGender = t(`mcontacts:contact_gender_${gender}`);
  let contactMethodText = contactGender;
  const date = dateHelper.convertDateFormat(
    contactedDate || '',
    config.format.uiDate
  );
  const prefix = contactedDate ? 'has_' : '';

  switch (true) {
    case contactByPhone && contactByPost:
      contactMethodText += t(
        `mcontacts:${prefix}contacted_by_email_phone_post_after_death`,
        { date }
      );
      break;
    case contactByPhone:
      contactMethodText += t(
        `mcontacts:${prefix}contacted_by_email_phone_after_death`,
        { date }
      );
      break;
    case contactByPost:
      contactMethodText += t(
        `mcontacts:${prefix}contacted_by_email_post_after_death`,
        { date }
      );
      break;
    default:
      contactMethodText += t(
        `mcontacts:${prefix}contacted_by_email_after_death`,
        { date }
      );
  }

  const handleCloseEditForm = () => {
    setShowEditForm(false);
  };

  const substituteContactFullName = getFullName(substituteContact);

  return (
    <>
      {showEditForm && (
        <Modal show onHide={handleCloseEditForm}>
          <ContactForm
            onClose={handleCloseEditForm}
            onSuccess={handleCloseEditForm}
            scrollToId={SUBSTITUTE_CONTACT}
            contactItem={contactData}
          />
        </Modal>
      )}

      {isFamilyAccountConfirmation && (
        <AddContactAsFamilyMemberConfirmModal
          contact={contactData}
          onClose={() => {
            setIsFamilyAccountConfirmation(false);
          }}
          switchToMyFamilyTab={switchToMyFamilyTab}
        />
      )}

      {familyAccountDeleting && (
        <RemoveFamilyContactConfirmModal
          onClose={() => {
            setFamilyAccountDeleting(false);
          }}
          id={contactData.id}
          isContactDeleting={true}
        />
      )}

      <div
        className={`${
          !contactData?.id || highlight
            ? `t-border t-border-solid ${
                highlight ? 't-border-alpha-600' : 't-border-epsilon-600'
              }`
            : ''
        } ${className || 'card t-bg-gamma-400 t-pt-6 t-pb-6'}`}
        data-testid="ContactCard"
      >
        {!contactData?.id ? (
          <p className="typo-gamma">{t('mcontacts:contact_non_existent')}</p>
        ) : (
          <div className="Character Character--human t-outline-none t-pr-7">
            <div className="Character-menu">
              <Dropdown
                className={`dropdown--settings ${
                  contactedDate ? 't-top-1' : ''
                }`}
                data-testid="contact-card-dropdown"
              >
                {!isDropdownMenuHidden && (
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-settings"
                    data-testid="dropdown-button"
                  >
                    <MenuIcon className="t-text-beta-200" />
                  </Dropdown.Toggle>
                )}

                <Dropdown.Menu className="t-m-0">
                  {isViewAssetsAllowed && (
                    <Dropdown.Item onClick={handleViewAssetsClick}>
                      <IconEye className="t-text-beta-200 dropdown-icon" />
                      {t('mcontacts:view_contact_assets')}
                    </Dropdown.Item>
                  )}
                  {onEdit && (
                    <Dropdown.Item onClick={() => onEdit(contactData)}>
                      <IconEdit className="dropdown-icon t-text-beta-200" />
                      {t('common:edit_label')}
                    </Dropdown.Item>
                  )}
                  {!currentUser?.secondary &&
                    !familyAccountExist &&
                    !!isMyContactsPage && (
                      <Dropdown.Item
                        onClick={() => {
                          setIsFamilyAccountConfirmation(true);
                        }}
                        className="t-text-delta-500"
                      >
                        <IconPlus className="dropdown-icon dropdown-icon--delete" />
                        {t('familyAccounts:add_as_family_account')}
                      </Dropdown.Item>
                    )}
                  {onDelete && (
                    <Dropdown.Item onClick={handleDeleteContact}>
                      <DeleteIcon className="dropdown-icon dropdown-icon--delete t-text-epsilon-400" />
                      {t('common:delete_label')}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="Character-footer t-flex t-justify-between t-flex-col xl:t-flex-row t-pl-7">
              <div className="t-order-2 xl:t-order-1">
                <p className="typo-gamma">{contactName}</p>
                <div className="t-flex t-mt-1 t-mb-1">
                  {relation && (
                    <p className="typo-zeta t-text-alpha-600 t-flex">
                      <IconPeople className="t-mr-1" />
                      <span>
                        {!isOtherRelation
                          ? t(`mcontacts:rel_${relation}`)
                          : relation}
                      </span>
                    </p>
                  )}
                  {birthday && (
                    <p
                      className={`typo-zeta t-text-alpha-600 t-flex ${
                        relation ? 't-ml-5' : ''
                      }`}
                    >
                      <IconBirthday className="t-mr-1" />
                      {dateHelper.convertDateFormat(
                        birthday || '',
                        config.format.uiDate
                      )}
                    </p>
                  )}
                </div>
                {isMyContactsPage && (
                  <p className="typo-zeta t-mt-3 t-max-w-xs">
                    {contactMethodText}
                  </p>
                )}
                <div className="t-mt-2">
                  {email && (
                    <div className="t-flex t-items-center t-mb-1">
                      <EmailIcon className="t-mr-2 t-text-beta-500" />
                      <a
                        href={`mailto:${email}`}
                        className="u-color-jelly-bean u-noUnderline"
                      >
                        {email}
                      </a>
                    </div>
                  )}

                  {phone && (
                    <div className="t-flex t-items-center t-mb-1">
                      <PhoneIcon className="t-mr-3 t-text-beta-500" />
                      <p className="typo-epsilon">+{phone.replace('+', '')}</p>
                    </div>
                  )}

                  {address && (
                    <div className="t-flex t-items-center t-mb-1">
                      <LocationIcon className="t-mr-3 t-text-beta-500" />
                      <p className="typo-epsilon">
                        {address}, {zip} {city}, {formattedCountry}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="t-w-full xl:t-w-auto t-flex t-whitespace-nowrap t-order-1 xl:t-order-2 t-justify-end">
                {(contactedDate || isDeceasedFamilyContact) &&
                  isMyContactsPage && (
                    <div
                      className={`t-mr-5 t-flex t-items-center t-p-1 t-rounded-sm ${
                        isDeceasedFamilyContact
                          ? 't-bg-beta-100'
                          : 't-bg-alpha-100'
                      }`}
                    >
                      {isDeceasedFamilyContact ? (
                        <p className="typo-kappa">
                          {t('mcontacts:reported_as_death_badge_label')}
                        </p>
                      ) : (
                        <>
                          <CheckIcon className="t-w-3 t-h-3 t-mr-1 t-text-alpha-600" />
                          <p className="typo-kappa">
                            {t('mcontacts:contacted_label')}
                          </p>
                        </>
                      )}
                    </div>
                  )}

                {isMyContactsPage && (
                  <>
                    {documentId ? (
                      <button
                        type="button"
                        disabled={isDownloading || !isRequiredInfoComplete}
                        className="t-outline-none t-mr-5"
                        onClick={handleDocumentDownload}
                        data-testid="contact-download-button"
                      >
                        {isDownloading ? (
                          <Spinner className="t-w-5 t-h-5 t-text-beta-200" />
                        ) : (
                          <IconDownload
                            role="presentation"
                            data-tip={t('mcontacts:contact_download_tip')}
                            className="t-inline t-text-beta-200"
                          />
                        )}
                      </button>
                    ) : !isRequiredInfoComplete ? (
                      <IconDownloadDot className="t-w-5 t-h-5 t-mr-5 t-text-beta-200" />
                    ) : isDocumentsGenerating ? (
                      <Spinner className="t-w-5 t-h-5 t-mr-5 t-text-beta-200" />
                    ) : (
                      <button
                        type="button"
                        disabled={isDownloading || !isRequiredInfoComplete}
                        className="t-outline-none t-mr-5"
                        onClick={handleDocumentGenerate}
                        data-testid="document-generate-button"
                      >
                        {isDownloading ? (
                          <Spinner className="t-w-5 t-h-5 t-text-beta-200" />
                        ) : (
                          <>
                            <IconWarningTriangle
                              className="t-w-4.5 t-h-4.5 t-text-zeta-600"
                              role="presentation"
                              data-tip={t(
                                'mcontacts:contact_document_generation_tip'
                              )}
                            />
                          </>
                        )}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            {isMyContactsPage && (
              <div className="t-flex t-justify-between t-mt-6 t-pl-7">
                {!assignedAssets ? (
                  <div
                    className="t-flex"
                    data-tip={t('mcontacts:no_assigned_assets_tooltip')}
                  >
                    <div className="t-w-2.5 t-h-2.5 t-rounded-full t-bg-epsilon-600 t-mr-2 t-mt-1" />
                    <p className="t-text-beta-400">
                      {getAssignedAssetsLabel(0)}
                    </p>
                  </div>
                ) : (
                  <button
                    className="t-flex t-text-left t-outline-none"
                    onClick={handleAssignedAssetsClick}
                  >
                    <IconDocuments className="t-mr-2 t-mt-0.5" />
                    <span>{getAssignedAssetsLabel(assignedAssets)}</span>
                  </button>
                )}

                <button
                  className="typo-zeta t-text-beta-400 t-ml-3 t-text-right t-outline-none"
                  onClick={handleScrollToSubstituteCard}
                >
                  {!substituteContact
                    ? t('mcontacts:no_substitute_contact')
                    : `${t(
                        'mcontacts:contact_substitute'
                      )} ${substituteContactFullName}`}
                </button>
              </div>
            )}
          </div>
        )}
        {!isRequiredInfoComplete && onEdit && (
          <ContactInfoWarning onEdit={onEdit} contactData={contactData} />
        )}
      </div>

      <div className="t-pb-3" />
    </>
  );
}

export default ContactCard;
