import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { RootState } from 'store/reducers';
import { setModal } from 'store/actions/modalActions';
import { deleteDocument, getAssetsList } from 'store/actions/assetActions';
import { setAssetToEdit } from 'store/actions/layoutActions';
import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

import { HEALTH_CARE_ASSET_TYPES } from 'modules/estatePlan/constants/assetTypeKeys';
import { MODALS } from 'modules/common/constants';

import { ReactComponent as DeleteIcon } from 'images/icon-delete.svg';

export interface DeleteDocumentButtonProps {
  assetId: string;
  assetCategory: string;
  document: DocumentDTO;
}

function DeleteDocumentButton({
  assetId,
  assetCategory,
  document: { id, documentTitle },
}: DeleteDocumentButtonProps) {
  const { t } = useTranslation(['common', 'plan', 'zvrRegistration']);
  const dispatch = useDispatch();

  const assetsData: AssetDTO[] = useSelector(
    (state: RootState) => state.assets[assetCategory]
  );
  const currentAsset: AssetDTO = (find(
    assetsData,
    (item) => item.id === assetId
  ) || {}) as AssetDTO;

  const zvrHCPRegistered =
    currentAsset?.zvr === 1 &&
    currentAsset?.assetTypeName === HEALTH_CARE_ASSET_TYPES.HEALTH_CARE_PROXY;

  const assetTypesData: AssetTypeDTO[] = useSelector(
    (state: RootState) => state.assetTypes[assetCategory]
  );

  const currentAssetType: AssetTypeDTO = (find(
    assetTypesData,
    (item) => item.id === currentAsset.assetTypeId
  ) || {}) as AssetTypeDTO;

  const onDeleteDocument = async () => {
    // if we let the button to be disabled={zvrHCPRegistered}
    // the ReactTooltip does not work because the button is overlaid by its parent element
    if (zvrHCPRegistered) return;

    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: documentTitle,
          description: t('plan:delete_asset_confirm_text'),
          primaryButtonText: t('common:confirm'),
          successAsyncAction: async () => {
            await dispatch(deleteDocument(id, assetId, assetCategory));
            const currentAssetUpdated = {
              ...currentAsset,
              documents: currentAsset.documents?.filter(
                (item) => item.id !== id
              ),
            };

            await Promise.all([
              dispatch(setAssetToEdit(currentAssetUpdated, currentAssetType)),
              dispatch(getAssetsList()),
            ]);
          },
        },
      })
    );
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      <Button
        category="link"
        onClick={onDeleteDocument}
        className={`t-mr-5 t-bg-transparent ${
          zvrHCPRegistered ? 't-text-beta-500 t-no-underline' : ''
        }`}
        dataTip={t('zvrRegistration:cannot_delete_zvr_document_tooltip')}
        dataTipDisable={!zvrHCPRegistered}
        testId="DeleteDocumentButton"
      >
        <DeleteIcon className="t-text-epsilon-400" />
        <span className="xs:t-hidden sm:t-inline">
          {t('common:delete_label')}
        </span>
      </Button>
    </>
  );
}

export default DeleteDocumentButton;
