import { LANGUAGES } from 'modules/common/constants/enums';

export function localizeNumber(
  number: string | number,
  lngCode: string
): string {
  const stringifiedNumber = String(number);
  switch (lngCode) {
    case LANGUAGES.DE:
      return stringifiedNumber.replace('.', ',');
    default:
      return stringifiedNumber.replace(',', '.');
  }
}
