import assistantService from 'modules/assistant/services/assistantService';

import { LOAD_RECOMMENDATIONS } from 'store/types/recommendationsActionTypes';
import helper from 'store/actions/actionHelper';

export const getRecommendationsSuccess = (recos: RecommendationsDTO) =>
  helper.getAction(LOAD_RECOMMENDATIONS, { recos });

export const getRecommendations = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const recommendations = await assistantService.getRecommendations();

    dispatch(getRecommendationsSuccess(recommendations));
    return recommendations;
  }, actionOptions);
};

export const updateRecommendation = (
  recommendation: RecommendationDTO,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    await assistantService.updateRecommendation(recommendation);
    await dispatch(getRecommendations());

    return true;
  }, actionOptions);
};
