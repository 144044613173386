import React from 'react';
import Select, { SingleValue } from 'react-select';

import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';
import { StyledOr } from 'modules/elements/components';

import { ReactComponent as IconPlus } from 'images/icon-plus.svg';

export interface SelectOrAddContactCardProps {
  onChange: (selectedOption: SingleValue<SelectOption>) => void;
  contactOptions: SelectOption[];
  onAddNew: () => void;
}

const SelectOrAddContactCard = ({
  onChange,
  contactOptions,
  onAddNew,
}: SelectOrAddContactCardProps) => {
  const { t } = useTranslation(['common']);

  return (
    <div className="card xs:t-w-full md:t-w-4/5 xs:t-p-4 md:t-p-7">
      <div className="Form-group">
        <label className="Form-label" htmlFor="gender">
          {t('common:existing_contact')}
        </label>

        <Select
          id="contact"
          name="contact"
          onChange={onChange}
          options={contactOptions}
          className="Select"
          classNamePrefix="Select"
        />
      </div>

      <div className="d-flex flex-column align-items-center">
        <StyledOr>{t('common:or')}</StyledOr>

        <Button category="secondary" onClick={onAddNew}>
          <div className="t-flex t-justify-around">
            <IconPlus className="t-text-alpha-600 t-mr-1.5" />
            <p>{t('common:add_new_contact')}</p>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default SelectOrAddContactCard;
