import React from 'react';
import isEmail from 'validator/lib/isEmail';
import { isEqual } from 'lodash';

import {
  checkEmptyValueObject,
  isJsonString,
  useTranslation,
} from 'modules/common/helpers';
import {
  CommonFormInputProps,
  FormErrors,
  FormGetValues,
} from 'modules/common/types';

import {
  CountryInput,
  PhoneInput,
  TextInputRef,
} from 'modules/common/components';

import { PERSON_FIELD_INPUTS as PFI } from 'modules/common/constants/enums';
import { FIELD_TYPE_KEYS as F } from 'modules/estatePlan/constants/fieldLibrary';
import { VALIDATE } from 'modules/common/constants/generic';

interface PersonInputPros extends CommonFormInputProps {
  getValues: FormGetValues;
  errors: FormErrors;
  required: boolean;
  stringifiedPersonInput?: string;
  label?: string;
}

const PersonInput = ({
  register,
  control,
  errors,
  setValue,
  getValues,
  watch,
  required,
  stringifiedPersonInput = '',
  label = '',
}: PersonInputPros) => {
  const { t } = useTranslation(['auth', 'common']);

  const defaultValues = !stringifiedPersonInput
    ? {}
    : isJsonString(stringifiedPersonInput)
    ? JSON.parse(stringifiedPersonInput)
    : {};

  const {
    firstName = '',
    lastName = '',
    personCompany = '',
    personEmail = '',
    personPhone = '',
    personStreet = '',
    personCity = '',
    personZip = '',
    personCountry = '',
  } = defaultValues;

  // this will be updated when value of a field is changed
  // to update the field value in the parent component
  // also avoid react-hook-form save and object as string '[object Object]'
  const [updatedInputs, setUpdatedInputs] = React.useState(defaultValues);

  const currentInputs = Object.keys(PFI).reduce((inputs, key) => {
    const inputValue = watch(PFI[key]);
    if (PFI[key] === PFI.PERSON_COUNTRY) {
      inputs[PFI[key]] = inputValue?.value || '';
    } else {
      inputs[PFI[key]] = inputValue || '';
    }
    return inputs;
  }, {});

  React.useEffect(() => {
    const valuesDifferent = !isEqual(updatedInputs, currentInputs);
    if (valuesDifferent) {
      setUpdatedInputs(currentInputs);
    }
  }, [currentInputs]);

  React.useEffect(() => {
    setValue(
      F.PERSON,
      checkEmptyValueObject(updatedInputs) ? '' : JSON.stringify(updatedInputs)
    );
  }, [updatedInputs]);

  const errFirstName = errors[PFI.FIRST_NAME]
    ? `${t(`common:${PFI.FIRST_NAME}_label`)} ${t('common:required')}`
    : '';
  const errLastName = errors[PFI.LAST_NAME]
    ? `${t(`common:${PFI.LAST_NAME}_label`)} ${t('common:required')}`
    : '';
  const errPersonCompany = errors[PFI.PERSON_COMPANY]
    ? `${t(`common:${PFI.PERSON_COMPANY}_label`)} ${t('common:required')}`
    : '';
  const errPersonEmail = errors[PFI.PERSON_EMAIL]
    ? `${t(`common:${PFI.PERSON_EMAIL}_label`)} ${t('common:required')}`
    : '';
  const errPersonPhone =
    errors[PFI.PERSON_PHONE]?.type === VALIDATE
      ? `${t(`common:${PFI.PERSON_PHONE}_label`)} ${t('common:invalid')}`
      : errors[PFI.PERSON_PHONE]
      ? `${t(`common:${PFI.PERSON_PHONE}_label`)} ${t('common:required')}`
      : '';

  const errPersonStreet = errors[PFI.PERSON_STREET]
    ? `${t(`common:${PFI.PERSON_STREET}_label`)} ${t('common:required')}`
    : '';
  const errPersonCity = errors[PFI.PERSON_CITY]
    ? `${t(`common:${PFI.PERSON_CITY}_label`)} ${t('common:required')}`
    : '';
  const errPersonZip = errors[PFI.PERSON_ZIP]
    ? `${t(`common:${PFI.PERSON_ZIP}_label`)} ${t('common:required')}`
    : '';
  const errPersonCountry = !!errors[PFI.PERSON_COUNTRY] || '';

  const isError = !checkEmptyValueObject({
    errFirstName,
    errLastName,
    errPersonCompany,
    errPersonEmail,
    errPersonPhone,
    errPersonStreet,
    errPersonCity,
    errPersonZip,
    errPersonCountry,
  });

  return (
    <div
      className={`t-border t-border-solid t-border-beta-200 t-rounded t-relative t-pt-1.5 t-px-2 t-mt-5 t-mb-3 ${
        isError ? 't-border-epsilon-600' : ''
      }`}
    >
      {label && (
        <p
          className={`Form-label t-absolute t--top-3 t-left-5 t-px-2 t-bg-gamma-400 ${
            isError ? 'isErrored' : ''
          }`}
        >
          {t(label)}
        </p>
      )}

      <div className="xl:t-flex t-mt-3">
        <div className="t-w-full xl:t-w-1/2 t-inline-block xl:t-pr-5">
          <TextInputRef
            {...register(PFI.FIRST_NAME, { required: required })}
            type="text"
            name={PFI.FIRST_NAME}
            label={t(`common:${PFI.FIRST_NAME}_label`)}
            defaultValue={firstName}
            error={errFirstName}
          />
        </div>

        <div className="t-w-full xl:t-w-1/2 t-inline-block t-self-start">
          <TextInputRef
            {...register(PFI.LAST_NAME, { required: required })}
            type="text"
            name={PFI.LAST_NAME}
            label={t(`common:${PFI.LAST_NAME}_label`)}
            defaultValue={lastName}
            error={errLastName}
          />
        </div>
      </div>

      <TextInputRef
        {...register(PFI.PERSON_COMPANY, { required: required })}
        type="text"
        name={PFI.PERSON_COMPANY}
        label={t(`common:${PFI.PERSON_COMPANY}_label`)}
        defaultValue={personCompany}
        error={errPersonCompany}
      />

      <TextInputRef
        {...register(PFI.PERSON_EMAIL, {
          required: required,
          validate: (value) => {
            if (!value) return true;
            return isEmail(value);
          },
        })}
        type="email"
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
          setValue('email', e.target.value.trim())
        }
        name={PFI.PERSON_EMAIL}
        label={t(`common:${PFI.PERSON_EMAIL}_label`)}
        defaultValue={personEmail}
        error={errPersonEmail}
      />

      <PhoneInput
        control={control}
        error={errPersonPhone}
        defaultValue={personPhone}
        isRequired={required}
        name={PFI.PERSON_PHONE}
        label={t(`common:${PFI.PERSON_PHONE}_label`)}
      />

      <div className="xl:t-flex">
        <div className="t-w-full xl:t-w-8/12 t-inline-block xl:t-pr-5">
          <TextInputRef
            {...register(PFI.PERSON_STREET, { required: required })}
            name={PFI.PERSON_STREET}
            label={t('auth:address_label')}
            type="text"
            error={errPersonStreet}
            defaultValue={personStreet}
          />
        </div>
        <div className="t-w-full xl:t-w-4/12 t-inline-block t-self-start">
          <TextInputRef
            {...register(PFI.PERSON_ZIP, { required: required })}
            name={PFI.PERSON_ZIP}
            label={t('auth:postal_label')}
            type="text"
            error={errPersonZip}
            defaultValue={personZip}
          />
        </div>
      </div>

      <div className="xl:t-flex">
        <div className="t-w-full xl:t-w-7/12 t-inline-block xl:t-pr-5">
          <TextInputRef
            {...register(PFI.PERSON_CITY, { required: required })}
            name={PFI.PERSON_CITY}
            label={t('auth:city_label')}
            type="text"
            error={errPersonCity}
            defaultValue={personCity}
          />
        </div>

        <div className="t-w-full xl:t-w-5/12 t-inline-block">
          <CountryInput
            control={control}
            error={(errors[PFI.PERSON_COUNTRY]?.type as string) || ''}
            defaultValue={personCountry}
            isRequired={required}
            refSetValue={setValue}
            refGetValues={getValues}
            name={PFI.PERSON_COUNTRY}
          />
        </div>

        <input {...register(F.PERSON)} name={F.PERSON} type="hidden" />
      </div>
    </div>
  );
};

export default PersonInput;
