import { dateHelper, getParsedAddress, config } from 'modules/common/helpers';
import { getProviderValue } from 'modules/estatePlan/helpers';

import { OTHER_PROVIDERS } from 'modules/estatePlan/constants/enums';
import { SUBTYPE_OTHER } from 'modules/common/constants/subtypes';
import { FIELD_TYPE_KEYS } from 'modules/estatePlan/constants';
import { isEqual } from 'lodash';

export const makeSubmitData = (formData) => ({
  ...formData,
  provider:
    formData?.provider?.value === OTHER_PROVIDERS.OTHER
      ? formData?.otherProvider || ''
      : getProviderValue(formData.provider),
  subtype: [SUBTYPE_OTHER.OTHERS, SUBTYPE_OTHER.OTHER].includes(
    formData?.subtype?.value
  )
    ? formData?.otherSubtype
    : formData?.subtype?.value,
  action: (formData.action && (formData.action.value as string)) || '',
  date:
    formData.date &&
    dateHelper.convertDateFormat(formData.date, config.format.serverDate),
  expirationDate:
    formData.expirationDate &&
    dateHelper.convertDateFormat(
      formData.expirationDate,
      config.format.serverDate
    ),
  contactIds: formData.contactIds
    ? formData.contactIds.map((item) => item.value)
    : [],
  address: getParsedAddress(formData),
  paymentMethod:
    (formData.paymentMethod && formData.paymentMethod?.value) || '',
  paymentFrequency:
    (formData.paymentFrequency && formData.paymentFrequency?.value) || '',
});

export const makeFormDataObj = (formData, formDataObj) => {
  Object.entries(formData).forEach(([key, value]) => {
    let resValue = value;
    let resKey = key;

    switch (true) {
      case key === FIELD_TYPE_KEYS.SUBTYPE:
        resValue = [SUBTYPE_OTHER.OTHERS, SUBTYPE_OTHER.OTHER].includes(
          formData?.subtype?.value
        )
          ? formData?.otherSubtype
          : formData?.subtype?.value;
        break;
      case key === FIELD_TYPE_KEYS.DATE:
        resValue =
          formData.date &&
          dateHelper.convertDateFormat(formData.date, config.format.serverDate);
        break;
      case key === FIELD_TYPE_KEYS.EXPIRATION_DATE:
        resValue =
          formData.expirationDate &&
          dateHelper.convertDateFormat(
            formData.expirationDate,
            config.format.serverDate
          );
        break;
      case key === FIELD_TYPE_KEYS.PROVIDER:
        resValue =
          formData?.provider?.value === OTHER_PROVIDERS.OTHER
            ? formData?.otherProvider || ''
            : getProviderValue(formData.provider);
        break;
      case key === FIELD_TYPE_KEYS.CONTACTS: {
        const contacts = value
          ? (value as SelectOption[]).map((item) => item.value)
          : [];
        contacts.forEach((contact, i) =>
          formDataObj.append(`${FIELD_TYPE_KEYS.CONTACTS}[${i}]`, contact)
        );
        return;
      }
      case key === FIELD_TYPE_KEYS.FINANCIAL_DATA:
        resValue =
          formData.financialValue || formData.estimatedDate
            ? JSON.stringify({
                value: formData?.financialValue || '',
                estimated: !!formData?.estimatedDate,
                date: formData?.estimatedDate || '',
              })
            : '';
        break;
      case key === FIELD_TYPE_KEYS.SHARE:
        resValue = value ? 1 : '';
        break;

      case key === FIELD_TYPE_KEYS.PAYMENT_METHOD:
        resValue = formData.paymentMethod && formData.paymentMethod.value;
        break;
      case key === FIELD_TYPE_KEYS.PAYMENT_FREQUENCY:
        resValue = formData.paymentFrequency && formData.paymentFrequency.value;
        break;
      case (
        [
          FIELD_TYPE_KEYS.STREET_ADDRESS,
          FIELD_TYPE_KEYS.CITY,
          FIELD_TYPE_KEYS.ZIP,
          FIELD_TYPE_KEYS.COUNTRY,
        ] as string[]
      ).includes(key):
        resValue = getParsedAddress(formData);
        resKey = FIELD_TYPE_KEYS.ADDRESS;
        break;
      default:
        break;
    }

    // make sure we don't convert undefined to 'undefined' string;
    if (!!resValue) {
      formDataObj.set(resKey, resValue as string);
    }
  });

  return formDataObj;
};

export const getAllMissingInfoContacts = (contacts) => {
  return contacts.filter(
    ({ missingInfo }) => missingInfo?.birthday || missingInfo?.gender
  );
};

export const checkAllContactedContacts = (
  formData: Record<string, any>,
  contactsData: ContactDTO[]
) => {
  const listOfContactIds =
    formData.contactIds?.map((contact) => contact.value) || [];
  for (const id of listOfContactIds) {
    const selectedContact = contactsData.find((contact) => contact.id === id);
    if (!selectedContact?.contactedDate) {
      return false;
    }
  }
  return true;
};

export const checkContactListChanged = (
  formData: Record<string, any>,
  asset: AssetDTO
) => {
  const contactIds = makeSubmitData(formData).contactIds;
  const existingContactIds = asset.contacts?.map((contact) => contact.id) || [];
  return !!contactIds.length && !isEqual(contactIds, existingContactIds);
};
