import { config, reactToast } from 'modules/common/helpers';
import {
  DEFAULT_FILE_PHOTO_SIZE,
  DEFAULT_FILE_ASSET_SIZE,
} from 'modules/dashboard/constants';

export default {
  convertBytes,
  getMaxPhotoSize,
  getMaxFileAssetSize,
  removeLastEntryFromString,
  getImportedImage,
  generateId,
};

function convertBytes(x) {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n /= 1024;
  }

  return `${n.toFixed(0)} ${units[l]}`;
}

function getMaxPhotoSize() {
  return config.maxPhotoSize
    ? convertBytes(config.maxPhotoSize)
    : `${DEFAULT_FILE_PHOTO_SIZE} MB`;
}

function getMaxFileAssetSize() {
  return config.maxDocumentSize
    ? convertBytes(config.maxDocumentSize)
    : `${DEFAULT_FILE_ASSET_SIZE} MB`;
}

function removeLastEntryFromString(
  str: string,
  searchValue: string,
  replaceValue: string
): string {
  const re = new RegExp(`${searchValue}(?!.*${searchValue})`, 'g');

  return str.replace(re, replaceValue);
}

function getImportedImage(url: string) {
  try {
    const images = require['context']('images', false);
    const imgUrl = url ? url.substring(url.lastIndexOf('/') + 1) : '';

    return images(`./${imgUrl}`);
  } catch (error) {
    reactToast.showError(error as string);
  }

  return null;
}

// Generates random id, e.g.: 'ChIJXcB-la2Yj4ARmb77148UWdI',
function generateId() {
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  let id = `${new Date().getTime()}_`;

  for (let x = 0; x < 16; x++) {
    const i = Math.floor(Math.random() * 62);
    id += chars.charAt(i);
  }

  return id;
}
