import React from 'react';

import { Skeleton } from 'modules/skeleton/components';

export const AssistantSkeleton = () => {
  return (
    <Skeleton width="100%" height="100%" viewBox="0 0 100 100">
      {/* desktop */}
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width="45"
        height="20"
        className="xs:t-hidden md:t-block"
      />
      <rect
        x="48"
        y="0"
        rx="2"
        ry="2"
        width="45"
        height="20"
        className="xs:t-hidden md:t-block"
      />
      <rect
        x="0"
        y="23"
        rx="2"
        ry="2"
        width="45"
        height="20"
        className="xs:t-hidden md:t-block"
      />
      <rect
        x="48"
        y="23"
        rx="2"
        ry="2"
        width="45"
        height="20"
        className="xs:t-hidden md:t-block"
      />
      <rect
        x="0"
        y="46"
        rx="2"
        ry="2"
        width="45"
        height="20"
        className="xs:t-hidden md:t-block"
      />
      <rect
        x="48"
        y="46"
        rx="2"
        ry="2"
        width="45"
        height="20"
        className="xs:t-hidden md:t-block"
      />
      {/* mobile */}
      <rect
        x="0"
        y="0"
        rx="2"
        ry="2"
        width="100"
        height="45"
        className="md:t-hidden"
      />
      <rect
        x="0"
        y="50"
        rx="2"
        ry="2"
        width="100"
        height="45"
        className="md:t-hidden"
      />
    </Skeleton>
  );
};
