import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { getZvrPaymentSession } from 'store/actions/zvrActions';
import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';
import { ZVR_REGISTRATION_STEPS } from 'modules/zvr/constants';
import { EMAIL } from 'modules/common/constants/generic';

import { ReactComponent as BundesNotarKammerLogo } from 'images/logo_bundesnotarkammer.svg';

interface PaymentCheckoutModalProps {
  handleNextStep: (step: number) => void;
}

export default function PaymentCheckoutModal({
  handleNextStep,
}: PaymentCheckoutModalProps) {
  const { t } = useTranslation(['zvrRegistration']);
  const dispatch = useDispatch();

  const userEmail = useSelector((state: RootState) => state.user.current.email);
  const [loading, setLoading] = React.useState(false);

  const onPayment = async () => {
    setLoading(true);
    const paymentUrl: any = await dispatch(getZvrPaymentSession());
    if (paymentUrl) {
      localStorage.setItem(EMAIL, userEmail);
      window.location.href = paymentUrl;
    }
    setLoading(false);
  };

  const nextStep = () => {
    handleNextStep(ZVR_REGISTRATION_STEPS.STEP_REGISTRATION_CANCEL);
  };

  return (
    <Modal show onHide={nextStep}>
      <h1 className="typo-alpha">
        {t('zvrRegistration:payment_section_title')}
      </h1>
      <div className="typo-epsilon t-mt-4">
        <p>{t('zvrRegistration:payment_section_question_1')}</p>
        <p>{t('zvrRegistration:payment_section_question_2')}</p>
        <p>{t('zvrRegistration:payment_section_question_3')}</p>
      </div>

      <div className="card t-mt-7 t-px-8 t-py-5">
        <div className="t-flex t-justify-between typo-beta">
          <p>{t('zvrRegistration:payment_checkout_price_title')}</p>
          <p className="t-whitespace-nowrap">
            {t('zvrRegistration:zvr_registration_price_discount')}
          </p>
        </div>
        <div className="t-flex t-justify-between t-mt-1">
          <p className="typo-delta">
            {t('zvrRegistration:payment_checkout_vat')}
          </p>
          <p className="typo-zeta">
            {t('zvrRegistration:payment_checkout_one_time_payment')}
          </p>
        </div>
      </div>

      <div className="t-flex t-justify-end t-mt-7">
        <BundesNotarKammerLogo className="t-w-1/2" />
      </div>

      <div className="t-flex t-justify-end t-mt-7 t-flex-col sm:t-flex-row">
        <div>
          <Button
            category="secondary"
            className="t-mr-0 sm:t-mr-3 t-py-2 t-mb-3 sm:t-mb-0"
            onClick={nextStep}
            disabled={loading}
          >
            {t('zvrRegistration:cancel')}
          </Button>
        </div>

        <div>
          <Button
            type="submit"
            className="t-py-2"
            onClick={onPayment}
            loading={loading}
          >
            {t('zvrRegistration:payment_checkout_pay_now')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
