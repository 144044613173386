import { format, parse, parseISO, isFuture, isBefore } from 'date-fns';
import i18n from 'i18next';

import { config } from 'modules/common/helpers';
import { calculateAge } from 'modules/common/helpers';

import { DATE_FORMATS } from 'modules/common/constants/enums';
import { MAX_BIRTHDAY_YEAR, MIN_ADULT_AGE } from 'modules/common/constants';

export default {
  displayDate,
  isFutureDate,
  isValidBirthdayDate,
  convertDateFormat,
  isValidDate,
  checkNotValidDate,
  checkIsNotPastDate,
  checkIsNotFutureDate,
  checkIsNotAdult,
  checkIsNotChild,
  checkIsNotTooEarly,
  getFutureDate,
  getDaysInMonth,
};

type Digits = 0 | 1 | 2 | undefined;
function displayDate(date) {
  const additionalDigits: Digits = 1;
  const options = { additionalDigits };
  return format(parseISO(date, options), config.format.uiDate);
}

function isFutureDate(date: string, dateFormat: string): boolean {
  const parsedDate = parse(date, dateFormat, new Date()) as Date | string;
  if (parsedDate === 'Invalid Date') return false;

  return isFuture(parsedDate as Date);
}

function isValidDate(date: string): boolean {
  const parsedDate = parse(date, config.format.uiDate, new Date()) as
    | Date
    | string;

  if (parsedDate == 'Invalid Date') {
    return false;
  }
  return true;
}

function isValidBirthdayDate(date: string): boolean {
  const parsedDate = parse(date, config.format.uiDate, new Date()) as
    | Date
    | string;

  if (parsedDate == 'Invalid Date') {
    return false;
  }

  return !isFuture(parsedDate as Date);
}

function convertDateFormat(date: string, dateFormat: DATE_FORMATS): string {
  const FAILED_DATE_RETURN = 'Invalid Date';
  const parsedServerFormat = parse(
    date,
    config.format.serverDate,
    new Date()
  ) as Date | string;
  const parsedUiFormat = parse(date, config.format.uiDate, new Date()) as
    | Date
    | string;

  if (
    [parsedServerFormat, parsedUiFormat].every(
      (item) => item == FAILED_DATE_RETURN
    )
  ) {
    return '';
  }

  const parsedDate =
    parsedServerFormat == FAILED_DATE_RETURN
      ? parsedUiFormat
      : parsedServerFormat;

  return format(parsedDate as Date, dateFormat);
}

// return with error, return false if date validation is passed
function checkNotValidDate(
  value: string,
  errMessage?: string
): string | undefined {
  if (!isValidDate(value))
    return errMessage || `${i18n.t('common:invalid_date')}`;
}

function checkIsNotPastDate(
  value: string,
  errMessage?: string
): string | undefined {
  if (!isValidDate(value)) return `${i18n.t('common:invalid_date')}`;

  const date = new Date(convertDateFormat(value, config.format.serverDate));
  if (!isBefore(date, new Date()))
    return errMessage || `${i18n.t('common:date_cannot_be_in_the_future')}`;
}

function checkIsNotFutureDate(
  value: string,
  errMessage?: string
): string | undefined {
  if (!isValidDate(value)) return `${i18n.t('common:invalid_date')}`;

  const date = new Date(convertDateFormat(value, config.format.serverDate));
  if (isBefore(date, new Date()))
    return errMessage || `${i18n.t('common:date_cannot_be_in_the_past')}`;
}

function checkIsNotAdult(
  value: string,
  errMessage?: string
): string | undefined {
  if (!isValidDate(value)) return `${i18n.t('common:invalid_date')}`;

  const date = new Date(convertDateFormat(value, config.format.serverDate));
  const age = calculateAge(date);

  if (age < MIN_ADULT_AGE)
    return errMessage || `${i18n.t('auth:birthday_too_young')}`;
}

function checkIsNotChild(
  value: string,
  errMessage?: string
): string | undefined {
  if (!isValidDate(value)) return `${i18n.t('common:invalid_date')}`;

  const date = new Date(convertDateFormat(value, config.format.serverDate));
  const age = calculateAge(date);
  if (age >= MIN_ADULT_AGE)
    return errMessage || `${i18n.t('common:age_must_be_less_than_18')}`;
}

function checkIsNotTooEarly(
  value: string,
  errMessage?: string
): string | undefined {
  if (!isValidDate(value)) return `${i18n.t('common:invalid_date')}`;

  const date = new Date(convertDateFormat(value, config.format.serverDate));
  if (isBefore(date, new Date(MAX_BIRTHDAY_YEAR)))
    return errMessage || `${i18n.t('auth:birthday_too_early')}`;
}

function getFutureDate(days: number) {
  const today = new Date();
  return format(
    new Date(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate() + days
    ),
    'yyyy-MM-dd'
  );
}

function getDaysInMonth() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}
