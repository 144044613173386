import React, { useState } from 'react';

import { useTranslation } from 'modules/common/helpers';

import { QAToggle } from 'modules/assistant/components/Questionnaire';

import { ReactComponent as CircleCross } from 'images/circle-cross.svg';
import { ReactComponent as ArrowDown } from 'images/arrow-down.svg';

interface QA {
  question: string;
  answer: string;
}

export interface QASectionProps {
  qa: QA[];
}

export default function QASection({ qa }: QASectionProps) {
  const { t } = useTranslation(['assistant']);
  const [isVisible, toggleVisible] = useState(false);

  return (
    <>
      {qa && (
        <div className="t-flex t-justify-center">
          <button
            className="t-outline-none t-text-delta-700 t-p-2 t-mb-3 t-mt-6 t-font-xs"
            type="button"
            onClick={() => toggleVisible((oldState) => !oldState)}
          >
            {isVisible ? (
              <CircleCross className="t-text-delta-700 t-inline t-mr-2" />
            ) : (
              <ArrowDown className="t-text-delta-700 t-inline t-mr-2 t-w-3.5" />
            )}
            <span className="t-align-middle">
              {isVisible
                ? t('assistant:close_information')
                : t('assistant:show_information')}
            </span>
          </button>
        </div>
      )}
      <div className="sm:t-my-2.5 sm:t-mx-12 xs:t-m-2.5">
        {isVisible &&
          qa &&
          qa.map(({ question, answer }) => (
            <div className="t-w-full t-mb-3" key={question + answer}>
              <QAToggle
                key={question + answer}
                title={question}
                content={answer}
              />
            </div>
          ))}
      </div>
    </>
  );
}
