import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { FormConditions } from 'modules/profile/components';
import { Button, Modal, TextInput } from 'modules/common/components';

export interface PasswordEditModalProps {
  inputChangePassword: ChangePasswordInputsDTO;
  onChange: (field: string, value) => void;
  errors: Record<string, string>;
  pwValidity: PasswordValidity;
  handlePasswordFormCancel: () => void;
  isChangePasswordLoading: boolean;
  onSubmit: () => void;
}

function PasswordEditModal({
  inputChangePassword,
  onChange,
  errors,
  pwValidity,
  handlePasswordFormCancel,
  isChangePasswordLoading,
  onSubmit,
}: PasswordEditModalProps) {
  const { t } = useTranslation(['auth', 'common', 'profile']);

  const onHide = () => {
    handlePasswordFormCancel();
  };

  return (
    <Modal show onHide={onHide} dataTestid="password-edit-modal">
      <div role="radiogroup">
        <form className="t-bg-gamma-400 t-w-full">
          <Row className="t-pb-8">
            <Col xs="12">
              <TextInput
                name="oldPassword"
                label={t('auth:old_password')}
                type="password"
                value={inputChangePassword.oldPassword}
                onChange={onChange}
                placeholder={t('auth:password_placeholder')}
                error={errors.oldPassword || ''}
              />
            </Col>

            <Col xs="12">
              <TextInput
                name="newPassword"
                label={t('auth:new_password')}
                type="password"
                value={inputChangePassword.newPassword}
                onChange={onChange}
                placeholder={t('auth:password_placeholder')}
                error={errors.newPassword || ''}
              />

              <FormConditions pwValidity={pwValidity} />
            </Col>
          </Row>

          <div className="t-flex xs:t-flex-wrap sm:t-flex-nowrap t-justify-end">
            <Button
              category="secondary"
              className="t-mr-2.5 xs:t-mt-5"
              onClick={handlePasswordFormCancel}
              testId="cancel"
            >
              {t('common:cancel')}
            </Button>
            <Button
              loading={isChangePasswordLoading}
              className="xs:t-mt-5"
              onClick={onSubmit}
              testId="change-password-submit-button"
            >
              {t('common:submit_password')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default PasswordEditModal;
