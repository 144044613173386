import React, { useState } from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import ReactTooltip from 'react-tooltip';

import { Form } from 'modules/bootstrap/components';

import { ReactComponent as LockIcon } from 'images/lock-icon.svg';
import { ReactComponent as IconInfo } from 'images/icon-info.svg';

interface TextInputRefProps {
  name: string;
  label?: string;
  defaultValue?: string;
  error?: string | false | null;
  type?: string;
  disabled?: boolean;
  inputClass?: string;
  maxLength?: number;
  mask?: string;
  placeholder?: string;
  autoComplete?: boolean;
  showLockIcon?: boolean;
  id?: string;
  info?: string;
  iconRight?: string | React.ReactNode;
  iconLeft?: string | React.ReactNode;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  labelHidden?: boolean;
  dataTestId?: string;
  wrapperClassName?: string;
}

const TextInputRef = React.forwardRef<HTMLInputElement, TextInputRefProps>(
  (
    {
      name,
      label,
      defaultValue,
      error,
      type,
      disabled,
      inputClass,
      maxLength,
      mask,
      placeholder,
      autoComplete,
      showLockIcon,
      id,
      info,
      iconRight,
      iconLeft,
      onBlur,
      labelHidden,
      wrapperClassName,
      dataTestId,
      ...restProps
    },
    ref
  ) => {
    const [isPwVisible, setIsPwVisible] = useState(false);
    const wrapperClass = classnames({
      'Form-group': true,
      isErrored: error && error.length > 0,
    });

    const isInputPassword = type === 'password';
    const inputType = isInputPassword && !isPwVisible ? 'password' : 'text';
    const autocompleteOffProp = isInputPassword ? 'new-password' : 'off';

    const compProps = {
      id: id || name,
      type: inputType,
      name,
      defaultValue,
      placeholder,
      onBlur,
      'data-testid': dataTestId || name,
      className: `Field ${inputClass} ${error ? 'isErrored' : ''} ${
        isInputPassword ? 'Field--password' : ''
      } ${iconRight ? 't-pr-8' : ''} ${iconLeft ? 't-pl-8' : ''}`,
      readOnly: disabled,
      autoComplete: autoComplete ? '' : autocompleteOffProp,
      ...restProps,
    };

    return (
      <div className={`${wrapperClass} ${wrapperClassName || ''}`}>
        <div className="t-flex">
          {label && !labelHidden && (
            <Form.Label
              htmlFor={name}
              className={`Form-label ${error ? 'isErrored' : ''}`}
            >
              {label}
            </Form.Label>
          )}

          {!!info && (
            <div>
              <IconInfo
                role="presentation"
                className="t-inline t-text-delta-700 t-ml-2"
                data-tip={info}
                data-for={label || 'textInputRef'}
              />
              <ReactTooltip
                id={label || 'textInputRef'}
                effect="solid"
                place="bottom"
                multiline
              />
            </div>
          )}
        </div>

        {mask ? (
          <InputMask inputRef={ref} {...compProps} mask={mask} />
        ) : (
          <input ref={ref} {...compProps} maxLength={maxLength} />
        )}

        {isInputPassword && (
          <div className="Form-passwordIcon">
            <button
              type="button"
              className="Form-passwordIconButton"
              onClick={() => setIsPwVisible(!isPwVisible)}
            >
              <span className="ScreenReaderOnly">Show password</span>
            </button>
          </div>
        )}

        {showLockIcon && (
          <div className="t-relative">
            <div className="t-w-11 t-absolute t-right-0 t--top-9 t-h-9 t-flex t-justify-center t-items-center">
              <LockIcon className="t-text-beta-200" />
            </div>
          </div>
        )}

        {iconRight && (
          <div className="t-relative">
            <div className="t-w-11 t-absolute t-right-0 t--top-9 t-h-9 t-flex t-justify-center t-items-center">
              {iconRight}
            </div>
          </div>
        )}

        {iconLeft && (
          <div className="t-relative">
            <div className="typo-epsilon t-w-11 t-absolute t-left-0 t--top-9 t-h-9 t-flex t-justify-center t-items-center">
              {iconLeft}
            </div>
          </div>
        )}

        {error && (
          <div className="Form-alert">
            <span className="text-main-sm t-text-epsilon-600">{error}</span>
          </div>
        )}
      </div>
    );
  }
);

TextInputRef.displayName = 'TextInputRef';

export default TextInputRef;
