import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

import { POSTAL_TYPES } from 'modules/common/constants/enums';

import successfulPaymentImg from 'images/successful-postal-payment.png';

interface PostalPaymentSuccessModalProps {
  onClose: () => void;
  type: POSTAL_TYPES;
}

const PostalPaymentSuccessModal = ({
  onClose,
  type,
}: PostalPaymentSuccessModalProps) => {
  const { t } = useTranslation(['common', 'subscription']);

  return (
    <Modal show onHide={onClose} dataTestid="PostalPaymentSuccessModal">
      <img
        src={successfulPaymentImg}
        alt="Successful Payment"
        className="t-text-alpha-600 t-mb-6 t-block t-mx-auto t-max-w-64"
      />
      <h1 className="typo-alpha t-text-center">
        {t(`plan:${type}_postal_payment_successful_modal_title`)}
      </h1>
      <p className="typo-epsilon t-text-center t-mt-4">
        {t(`plan:${type}_postal_payment_successful_modal_description`)}
      </p>
      <Button className="t-block t-mx-auto t-mt-6" onClick={onClose}>
        {t('common:close')}
      </Button>
    </Modal>
  );
};

export default PostalPaymentSuccessModal;
