import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTranslation, validationHelper } from 'modules/common/helpers';
import { deleteProfile, logOut } from 'store/actions/userActions';

import { Button, Modal, TextInputRef } from 'modules/common/components';

import { ERROR_CODES } from 'modules/common/constants';
import { DELETE_PROFILE_TYPES } from 'modules/common/constants/enums';

import imgSecurity from 'images/zero-knowledge-policy.png';

export interface ProfileDeleteConfirmationModalProps {
  deleteType: DELETE_PROFILE_TYPES;
  onCancel: () => void;
}

type Input = {
  code: number;
};

function ProfileDeleteConfirmationModal({
  deleteType,
  onCancel,
}: ProfileDeleteConfirmationModalProps) {
  const { t } = useTranslation(['profile', 'common']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Input>();

  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (formData: Input) => {
    setLoading(true);
    const response: any = await dispatch(
      deleteProfile(deleteType === DELETE_PROFILE_TYPES.HARD, formData.code, {
        returnError: true,
      })
    );
    setLoading(false);

    if (response?.errorCode === ERROR_CODES.INVALID_TOKEN) {
      setError('code', { type: 'invalidToken' });
    } else {
      if (deleteType === DELETE_PROFILE_TYPES.HARD) {
        await dispatch(logOut());

        navigate('/login');
      } else {
        onCancel();
        window.location.reload();
      }
    }
  };

  const error =
    errors['code']?.type === 'invalidToken'
      ? t('common:invalid_code')
      : errors['code']?.type === 'validate'
      ? `${t('auth:confirmation_code')} ${t('common:invalid')}`
      : errors['code']
      ? `${t('auth:confirmation_code')} ${t('common:required')}`
      : '';

  return (
    <Modal show dataTestid="ProfileDeleteConfirmationModal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="typo-alpha">
          {t(
            `profile:delete_account_confirmation_${
              deleteType === DELETE_PROFILE_TYPES.HARD ? 'hard' : 'soft'
            }_title`
          )}
        </h1>

        <p className="typo-epsilon t-mt-4">
          {t(
            `profile:delete_account_confirmation_${
              deleteType === DELETE_PROFILE_TYPES.HARD ? 'hard' : 'soft'
            }_description`
          )}
        </p>

        <div className="t-mt-6 t-flex t-items-center">
          <TextInputRef
            inputClass="t-w-1/2"
            {...register('code', {
              required: true,
              validate: (value) => validationHelper.validateCode(4, value),
            })}
            label={t('auth:confirmation_code')}
            type="tel"
            placeholder="XXXX"
            maxLength={4}
            autoComplete={false}
            error={error}
          />

          <img
            src={imgSecurity}
            alt="security"
            className="t-hidden sm:t-inline-block"
          />
        </div>
        <div className="t-flex t-justify-end t-mt-7 t-flex-col sm:t-flex-row">
          <div>
            <Button
              type="submit"
              category="dangerous"
              className="t-mr-3 t-mb-3 sm:t-mb-0"
              loading={loading}
            >
              {t('profile:delete_account_confirmation_button')}
            </Button>
          </div>

          <div>
            <Button
              onClick={onCancel}
              category="secondary"
              className="t-py-2"
              disabled={loading}
            >
              {t('common:cancel')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ProfileDeleteConfirmationModal;
