import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconCheck } from 'images/icon_check.svg';

interface SetPasswordHeaderProps {
  customization: ClientCustomization;
  client?: string;
  fullName?: string;
}

export default function SetPasswordHeader({
  customization,
  client,
  fullName = '',
}: SetPasswordHeaderProps) {
  const { t } = useTranslation(['common', 'auth']);

  const customTextStyle = {
    ...(customization?.customTextColor
      ? { color: customization.customTextColor }
      : {}),
  };

  return (
    <>
      <h1
        className={`typo-alpha t-pb-2 ${customization?.labelClassName || ''}`}
        style={{ ...customTextStyle }}
      >
        {customization?.title
          ? t(customization?.title, { fullName })
          : t('auth:set_password_title')}
      </h1>

      {customization?.description && (
        <p className="t-mb-4 typo-gamma" style={{ ...customTextStyle }}>
          {t(customization?.description)}
        </p>
      )}

      {customization?.exclusiveOffersText?.length &&
        customization?.exclusiveOffersText.map((offer) => (
          <div className="t-flex t-mt-1" key={offer}>
            <div className="t-pt-1">
              <IconCheck
                className="t-text-alpha-600 t-mr-2"
                style={{ ...customTextStyle }}
              />
            </div>

            <p
              className="typo-delta t-font-semibold t-text-alpha-600"
              style={{ ...customTextStyle }}
            >
              {t(offer)}
            </p>
          </div>
        ))}

      {customization?.offerSmallText && (
        <p
          className="typo-theta t-text-alpha-600 t-font-semibold t-ml-6 t-mt-2"
          style={{ ...customTextStyle }}
        >
          {t(`auth:${client}_signup_small_text`)}
        </p>
      )}
    </>
  );
}
