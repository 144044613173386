import React, { Fragment, useMemo, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';

type Item = {
  title: string;
  description: string;
};

const PrivacyPage = () => {
  const [customPrivacy, setCustomPrivacy] = useState(false);
  useEffect(() => {
    const currentURL = window.location.href;

    if (currentURL.endsWith('/privacy-vbbb')) {
      setCustomPrivacy(true);
    }
    return () => {
      setCustomPrivacy(false);
    };
  }, []);

  const { t } = useTranslation([customPrivacy ? 'privacyCustom' : 'privacy']);
  const items = useMemo(
    () =>
      t(
        customPrivacy ? 'privacyCustom:privacy_items' : 'privacy:privacy_items',
        { returnObjects: true }
      ) as any as Item[],
    [t]
  );

  return (
    <Row className="t-h-100">
      <Col
        md={{ span: 12 }}
        xl={{ span: 10, offset: 2 }}
        style={{ paddingRight: 0 }}
      >
        <div className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7">
          <h1 className="Typography Typography--title u-weight-600 u-pb-25">
            {t(
              customPrivacy ? 'privacyCustom:page_title' : 'privacy:page_title'
            )}
          </h1>

          {items.map(({ title, description }) => {
            return (
              <Fragment key={title}>
                <p className="Typography Typography--subTitle u-weight-600">
                  {title}
                </p>
                <p
                  className="Typography Typography--main u-pb-25"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(description),
                  }}
                />
              </Fragment>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default PrivacyPage;
