import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import { RootState } from 'store/reducers';
import { forgotPassword } from 'store/actions/userActions';
import { setLoading } from 'store/actions/commonActions';
import { useTranslation, validationHelper } from 'modules/common/helpers';

import { Row, Col } from 'modules/bootstrap/components';
import {
  TextInput,
  SiteLanguage,
  Button,
  PlatformFeatureInfoSection,
} from 'modules/common/components';
import {
  AuthFooter,
  ClickableLogo,
  LoginFooter,
} from 'modules/auth/components';

import { commonFeatures } from 'modules/auth/constants';

import imgRectangle from 'images/img-auth-rectangle.png';

const PasswordForgotPage = () => {
  const { t } = useTranslation(['common', 'auth']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: '' });
  const isRequestSending = useSelector(
    (state: RootState) => state.loading.value
  );

  const onChange = (field: string, value: string) => {
    setEmail(value);
  };

  const forgotFormIsValid = () => {
    const newErrors = {
      email: '',
    };

    if (!email) {
      newErrors.email = t('auth:email_required');
    } else if (!isEmail(email)) {
      newErrors.email = t('auth:email_not_valid');
    }

    setErrors(newErrors);

    return validationHelper.isEmptyErrorObject(newErrors);
  };

  const resetPassword = async (e) => {
    e.preventDefault();

    if (!forgotFormIsValid()) return;

    dispatch(setLoading(true));
    const res = await dispatch(forgotPassword(email));

    if (!!res) {
      navigate('/login');
    }
    dispatch(setLoading(false));
  };

  return (
    <>
      <Row className="lg:t-p-0 t-min-h-screen t-w-full t-m-0 t-relative">
        <Col
          md={{ span: 6, offset: 3 }}
          lg={{ span: 10, offset: 1 }}
          className="t-p-0 t-z-50"
        >
          <div className="t-hidden md:t-flex t-justify-between t-mt-6 lg:t-mt-10 t-px-3">
            <ClickableLogo />
            <div className="t-flex t-items-center">
              <div className="t-hidden lg:t-inline-block">
                <AuthFooter />
              </div>
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>
          </div>

          <div className="md:t-flex t-flex-col t-items-center t-mt-4 lg:t-mt-16 t-px-3 t-mb-16">
            <div className="t-flex t-justify-between md:t-hidden">
              <ClickableLogo />
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>

            <div className="card t-shadow-lg t-bg-beta-50 t-p-6 lg:t-px-12 lg:t-pt-12 t-w-full lg:t-w-auto t-mt-5">
              <form
                onSubmit={resetPassword}
                className="Form t-w-full lg:t-w-96 t-bg-transparent"
              >
                <h2 className="typo-beta t-mb-5">
                  {t('auth:reset_page_title')}
                </h2>

                <p className="typo-epsilon t-mb-5 t-text-beta-400">
                  {t('auth:reset_page_description')}
                </p>

                <fieldset disabled={isRequestSending}>
                  <TextInput
                    name="email"
                    label={t('auth:email_label_long')}
                    type="email"
                    value={email}
                    onChange={onChange}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChange('email', e.target.value.trim())
                    }
                    placeholder={t('auth:forget_password_email_placeholder')}
                    error={errors.email}
                    disabled={isRequestSending}
                  />
                </fieldset>

                <div className="t-flex t-justify-end t-mt-3">
                  <Button
                    type="submit"
                    loading={isRequestSending}
                    testId="reset-password-button"
                  >
                    <span className="t-px-3">
                      {t('auth:reset_page_button')}
                    </span>
                  </Button>
                </div>
              </form>
            </div>

            <div className="t-my-5 lg:t-mt-16 t-flex t-justify-center md:t-inline">
              <PlatformFeatureInfoSection features={commonFeatures} />
            </div>

            <div className="lg:t-hidden t-mt-16">
              <AuthFooter />
            </div>
          </div>

          <div className="t-absolute t-bottom-0 t-flex t-justify-center md:t-inline t-w-full">
            <LoginFooter />
          </div>
        </Col>
      </Row>
      <div className="t-fixed t-w-screen t-object-cover t-h-3/4 t-bottom-0">
        <img
          src={imgRectangle}
          className="t-w-full t-h-full"
          alt="Rectangle background"
        />
      </div>
    </>
  );
};

export default PasswordForgotPage;
