import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'store/reducers';
import { setModal } from 'store/actions/modalActions';

import { LogoutWarningModal } from 'modules/auth/components';
import {
  ManageSubscriptionButton,
  PaymentSuccessfulModal,
} from 'modules/subscription/components';
import { CommonModal, PassPhraseModal } from 'modules/common/components';
import {
  AccountSwitchingInfoModal,
  AddFamilyAccountProcess,
  LoginAccountSelectionModal,
} from 'modules/familyAccount/components';

import { MODALS } from 'modules/common/constants';
import { FamilyAccountLearnMoreModal } from 'modules/myContacts/components';

const ModalHandler = () => {
  const dispatch = useDispatch();
  const { name, props } = useSelector((state: RootState) => state.modal);

  const {
    successAction,
    cancelAction,
    successAsyncAction,
    cancelAsyncAction,
    title,
    description,
    secondaryButtonText,
    primaryButtonText,
    hasCross,
    withBackdrop,
    noCloseBtn,
    noSuccessBtn,
    disableClickOutside,
    familyAccounts,
    data,
  } = props as ModalProps;

  const handleClose = async () => {
    if (cancelAction) {
      cancelAction();
    }
    if (cancelAsyncAction) {
      await cancelAsyncAction();
    }
    dispatch(setModal(null));
  };

  const handleSuccess = async () => {
    if (successAction) {
      successAction();
    }
    if (successAsyncAction) {
      await successAsyncAction();
    }
    dispatch(setModal(null));
  };

  const getModal = () => {
    switch (name) {
      case MODALS.LOGOUT_WARNING_MODAL:
        return <LogoutWarningModal onClose={handleClose} />;
      case MODALS.PAYMENT_MODAL:
        return <ManageSubscriptionButton showModalOnly onClose={handleClose} />;
      case MODALS.PAYMENT_SUCCESSFUL:
        return <PaymentSuccessfulModal onClose={handleClose} />;
      case MODALS.PASSPHRASE:
        return (
          <PassPhraseModal
            show
            onCancel={handleClose}
            onSuccess={handleSuccess}
          />
        );
      case MODALS.COMMON_MODAL:
        return (
          <CommonModal
            title={title}
            description={description}
            secondaryButtonText={secondaryButtonText}
            primaryButtonText={primaryButtonText}
            onClose={handleClose}
            onSuccess={handleSuccess}
            hasCross={hasCross}
            withBackdrop={withBackdrop}
            noCloseBtn={noCloseBtn}
            noSuccessBtn={noSuccessBtn}
            disableClickOutside={disableClickOutside}
          />
        );
      case MODALS.SUB_ACCOUNT_SELECTION:
        return (
          <LoginAccountSelectionModal
            familyAccounts={familyAccounts || []}
            onClose={handleClose}
          />
        );
      case MODALS.ACCOUNT_SWITCHING_INFO:
        return (
          <AccountSwitchingInfoModal
            onClose={handleClose}
            onSuccess={handleSuccess}
            data={data as { to: FamilyAccount }}
          />
        );
      case MODALS.FAMILY_ACCOUNT_LEARN_MORE:
        return <FamilyAccountLearnMoreModal onClose={handleClose} />;
      case MODALS.ADD_FAMILY_ACCOUNT_PROCESS:
        return <AddFamilyAccountProcess onClose={handleClose} />;
      default:
        return null;
    }
  };

  return getModal();
};

export default ModalHandler;
