import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { getFamilyAccounts } from 'store/actions/familyAccountActions';
import { generateConfirmationToken } from 'store/actions/userActions';
import { useTranslation } from 'modules/common/helpers';

import { Modal } from 'modules/common/components';
import {
  DeathReportConfirmModal,
  RemoveFamilyContactConfirmModal,
} from 'modules/familyAccount/components';
import { ContactForm } from 'modules/contact/components';

import { ReactComponent as MenuIcon } from 'images/icon-menu.svg';
import { ReactComponent as IconEdit } from 'images/icon-edit.svg';
import { ReactComponent as DeleteIcon } from 'images/cross.svg';
import { ReactComponent as IconProfile } from 'images/icon-profile.svg';

interface FamilyCardDropdownProps {
  account: FamilyAccount;
}

const FamilyCardDropdown = ({ account }: FamilyCardDropdownProps) => {
  const { t } = useTranslation(['common', 'mcontacts']);
  const dispatch = useDispatch();

  const contactsData =
    useSelector((state: RootState) => state.contacts.list) || [];
  const relatedContact =
    contactsData.find(
      (contact: ContactDTO) => contact?.secondaryUserId === account.id
    ) || ({} as ContactDTO);

  const [contactEditFormOpen, setContactEditFormOpen] = React.useState(false);
  const [deathReporting, setDeathReporting] = React.useState(false);
  const [accountDeleting, setAccountDeleting] = React.useState(false);

  const onSuccessContactEdit = async () => {
    await dispatch(getFamilyAccounts());
    handleClose();
  };

  const onAccountDelete = async () => {
    await dispatch(generateConfirmationToken());
    setAccountDeleting(true);
  };

  const handleReportAsDeath = async () => {
    await dispatch(generateConfirmationToken());
    setDeathReporting(true);
  };

  const handleClose = () => {
    setContactEditFormOpen(false);
    setDeathReporting(false);
    setAccountDeleting(false);
  };

  return (
    <div>
      {!!contactEditFormOpen && (
        <Modal show onHide={handleClose}>
          <ContactForm
            onClose={handleClose}
            contactItem={relatedContact}
            onSuccess={onSuccessContactEdit}
            limited={true}
          />
        </Modal>
      )}

      {deathReporting && (
        <DeathReportConfirmModal account={account} onClose={handleClose} />
      )}

      {accountDeleting && (
        <RemoveFamilyContactConfirmModal
          onClose={handleClose}
          id={account.id}
        />
      )}

      <Dropdown className="dropdown--settings t-mb-5">
        <Dropdown.Toggle variant="success">
          <MenuIcon className="t-text-beta-200" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="t-m-0">
          <Dropdown.Item
            onClick={() => {
              setContactEditFormOpen(true);
            }}
          >
            <IconEdit className="dropdown-icon t-text-beta-200" />
            {t('common:edit_label')}
          </Dropdown.Item>

          <Dropdown.Item onClick={handleReportAsDeath}>
            <IconProfile className="dropdown-icon t-text-beta-200" />
            {t('familyAccounts:death_report_dropdown_label')}
          </Dropdown.Item>

          <Dropdown.Item onClick={onAccountDelete}>
            <DeleteIcon className="dropdown-icon dropdown-icon--delete t-text-epsilon-400 t-ml-0.5" />
            {t('familyAccounts:remove_sub_account_dropdown_label')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default FamilyCardDropdown;
