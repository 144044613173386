export enum HEALTH_INSURANCE {
  PUBLIC = 'public health insurance',
  PRIVATE = 'private health insurance',
}

export enum OTHER_HEALTH_INSURANCE {
  DAILY_BENEFIT = 'daily benefit insurance',
  TRAVEL = 'health insurance for travel abroad',
  HOSPITAL_DAILY = 'hospital daily benefits insurance',
  SUPPLEMENTARY_OUTPATIENT = 'outpatient supplementary insurance',
  SUPPLEMENTARY_CARE = 'supplementary care insurance',
  SUPPLEMENTARY_DENTAL = 'supplementary dental insurance',
  SUPPLEMENTARY_HOSPITAL = 'supplementary hospital insurance',
  OTHER_SUPPLEMENTARY = 'other supplementary insurance policies',
}

export enum REPRESENTATIVES {
  DIVORCE_ATTORNEY = 'divorce attorney',
  FAMILY_LAW_ATTORNEY = 'family law attorney',
  FINANCIAL_ADVISOR = 'financial advisor',
  GENERAL_LAW_ATTORNEY = 'general law attorney',
  INSURANCE_ADVISOR = 'insurance advisor',
  TAX_ADVISOR = 'tax advisor',
  OTHERS = 'others',
}

export enum PERSONAL_DOCUMENTS {
  BIRTH_CERTIFICATE = 'birth certificate',
  DIPLOMAS = 'diplomas',
  DRIVING_LICENCE = 'driving licence',
  DUNNING_NOTICES = 'dunning notices',
  EXTERNAL_ASSESSMENTS = 'external assessments',
  IDENTIFICATION_DOCUMENTS = 'identification documents',
  MARRIAGE_CERTIFICATE = 'marriage certificate',
  REGISTRATION_CERTIFICATE = 'registration certificate',
  OTHERS = 'others',
}

export enum LONG_TERM_ASSETS {
  ART_OBJECT = 'art object',
  COLLECTORS_ITEMS = "collector's items",
  PROPERTY = 'property',
  REAL_ESTATE = 'real estate',
  VEHICLE = 'vehicle',
  OTHERS = 'others',
}

export enum ONGOING_CONTRACTS {
  ASSOCIATION_MEMBERSHIPS = 'association memberships',
  ELECTRICITY_SUPPLIER = 'electricity supplier',
  EMPLOYMENT_CONTRACT = 'employment contract',
  GAS_SUPPLIERS = 'gas suppliers',
  INTERNET_SERVICE_PROVIDER = 'internet service provider',
  MAGAZINES = 'magazines',
  MOBILE_PHONE_CONTRACT = 'mobile phone contract',
  SPORTS_STUDIOS = 'sports studios',
  OTHER = 'other',
}

export enum PASSWORD {
  DIGITAL_PASSWORD_MANAGER = 'digital password manager',
  PHSYICAL_PASSWORD_ORGANIZATION = 'physical password organization',
}

export enum BANK_ACCOUNT {
  CREDIT = 'credit card account',
  CURRENT = 'current account',
  FIXED = 'fixed deposit account',
  OVERNIGHT = 'overnight account',
  SAVING = 'savings account',
  OTHERS = 'others',
}

export enum FAMILY_DOCTOR {
  ANESTHESIOLOGY = 'anesthesiology',
  DERMATOLOGIST_AND_VENEREOLOGIST = 'dermatologist and venereologist',
  GENERAL_MEDICINE = 'general medicine',
  GYNECOLOGIST = 'gynecologist',
  INTERNIST = 'internist',
  NEUROLOGY = 'neurology',
  OPHTHALMOLOGY = 'ophthalmology',
  OTOLARYNGOLOGY = 'otolaryngology',
  PEDIATRICS = 'pediatrics',
  PSYCHOLOGY_AND_PSYCHIATRY = 'psychology and psychiatry',
  RADIOTHERAPY = 'radiotherapy',
  SURGERY = 'surgery',
  UROLOGY = 'urology',
  WORKPLACE_MEDICINE = 'workplace medicine',
  OTHERS = 'others',
}

export enum INSURANCES {
  ACCIDENT = 'accident',
  ANIMAL_HEALTH = 'animal health',
  BURGLARY = 'burglary',
  BUSINESS_INTERRUPTION = 'business interruption',
  FIRE = 'fire',
  GLASS = 'glass',
  HOUSEHOLD_CONTENTS = 'household contents',
  LEGAL_PROTECTION = 'legal protection',
  LIABILITY = 'liability',
  MOTOR_VEHICLE = 'motor vehicle',
  OTHERS = 'others',
}

export enum LIST_OF_CONTACTS {
  DIGITAL_CONTACT_LIST = 'digital contact list',
  PHYSICAL_CONTACT_LIST = 'physical contact list',
}

export enum CREDIT_CARD_PROVIDERS {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  AMERICAN_EXPRESS = 'american express',
  DISCOVER = 'discover',
  OTHER = 'other',
}

export enum ADVISOR_TYPES {
  ACCOUNTANT = 'accountant',
  FINANCIAL_PLANNER = 'financial planner',
  INSURANCE_ADVISOR = 'insurance advisor',
  INVESTMENT_MANAGER = 'investment manager',
  TAX_PREPARER = 'tax preparer',
  OTHER = 'other',
}

export enum ATTORNEY_TYPES {
  CORPORATE_LAW_ATTORNEY = 'corporate law attorney',
  CRIMINAL_DEFENSE_ATTORNEY = 'criminal defense attorney',
  ESTATE_PLANNING_ATTORNEY = 'estate planning attorney',
  EMPLOYMENT_LAWYER = 'employment lawyer',
  FAMILY_DIVORCE_LAWYER = 'family divorce lawyer',
  PERSONAL_INJURY_LAWYER = 'personal injury lawyer',
  PROPERTY_LAW_ATTORNEY = 'property law attorney',
  OTHER = 'other',
}

export enum SUBTYPE_OTHER {
  OTHERS = 'others',
  OTHER = 'other',
}
