import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { camelCase } from 'lodash';

import { RootState } from 'store/reducers';
import { completeQuestionnaire } from 'store/actions/questionnaireActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { fetchAssistantProgress } from 'store/actions/assistantActions';
import { useTranslation } from 'modules/common/helpers';

import { Row, Col } from 'modules/bootstrap/components';
import {
  InheritanceTaxCTACard,
  UploadCTACard,
  UploadButton,
  Check24CTACard,
} from 'modules/assistant/components';
import {
  SummaryBox,
  Topics,
  Suggestions,
} from 'modules/assistant/components/ContentQuestionnaire';
import { Button } from 'modules/common/components';

import { CONTENT_QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/contentQuestionnaires';
import { QUESTIONNAIRES_TO_ASSET_TYPES } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';
import { REDIRECT_ACTIONS } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';

export interface ContentQuestionnaireProps {
  contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS;
}

const ContentQuestionnaire = ({
  contentQuestionnaire,
}: ContentQuestionnaireProps) => {
  const { t } = useTranslation([contentQuestionnaire]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const questionnaireToCard =
    QUESTIONNAIRES_TO_ASSET_TYPES[camelCase(contentQuestionnaire)];

  const questionnaireProgresses = useSelector(
    (state: RootState) => state.assistant.progress
  );
  const isComplete = questionnaireProgresses[contentQuestionnaire] === 1;

  const [loading, setLoading] = React.useState(false);

  // only display the CTA Upload Card if assetType available
  const isCTAUpload = !!questionnaireToCard?.assetType;
  const isCheck24 =
    contentQuestionnaire === CONTENT_QUESTIONNAIRE_KEYS.FUNERAL_PROVISION;
  const isCTAInheritance =
    contentQuestionnaire === CONTENT_QUESTIONNAIRE_KEYS.INHERITANCE_TAX;

  const handleGoBack = () => {
    navigate('/assistant');
  };

  const handleFinishTopicClick = async () => {
    await dispatch(completeQuestionnaire(contentQuestionnaire));
    await fetchAssistantProgress();
  };

  // for contentQuestionnaires that have no upload function
  const markTopicAsComplete = async () => {
    setLoading(true);
    await handleFinishTopicClick();
    if (isCTAInheritance) {
      await dispatch(
        temporaryFieldSaver(REDIRECT_ACTIONS.GO_TO_TAX_CALCULATOR)
      );
      navigate('/plan/financial');
    } else {
      handleGoBack();
    }
  };

  return (
    <>
      <Row className="t-h-100">
        <Col
          md={{ span: 12 }}
          xl={{ span: 10, offset: 2 }}
          style={{ paddingRight: 0 }}
        >
          <div className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7">
            <h1 className="t-text-xl t-font-secondary t-font-semibold t-mb-6">
              {t(`${contentQuestionnaire}:page_title`)}
            </h1>
            <SummaryBox questionnaire={contentQuestionnaire} />
            {isCTAUpload && (
              <UploadCTACard questionnaire={contentQuestionnaire} />
            )}
            {isCheck24 && <Check24CTACard />}
            {isCTAInheritance && <InheritanceTaxCTACard />}
            <Topics contentQuestionnaire={contentQuestionnaire} />
            <div className="t-flex t-justify-between t-my-9">
              <Button onClick={handleGoBack} category="secondary">
                {t('common:back')}
              </Button>
              {isCTAUpload && (
                <div onClick={handleFinishTopicClick}>
                  <UploadButton questionnaire={contentQuestionnaire} />
                </div>
              )}

              {!isCTAUpload && isCTAInheritance && (
                <Button onClick={markTopicAsComplete}>
                  {t('assistant:inheritance_tax_cal_calculate_now')}
                </Button>
              )}

              {!isCTAUpload && !isCTAInheritance && !isComplete && (
                <Button onClick={markTopicAsComplete} loading={loading}>
                  {t('common:mark_done_label')}
                </Button>
              )}
            </div>

            <Suggestions contentQuestionnaire={contentQuestionnaire} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ContentQuestionnaire;
