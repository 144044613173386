import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

import { toggleNotificationPanel } from 'store/actions/notificationActions';

import { ReactComponent as IconBell } from 'images/bell.svg';

export default function NotificationButton() {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications
  );
  const unreadNotificationCount = notifications?.filter(
    (notification) => !notification.read || notification.deleted
  )?.length;

  const openNotificationPanel = () => {
    dispatch(toggleNotificationPanel());
  };

  return (
    <button
      className="t-bg-alpha-150 t-px-2.5 t-py-2.5 t-rounded t-outline-none t-relative"
      onClick={openNotificationPanel}
    >
      <IconBell
        className="t-text-alpha-600"
        style={{ height: '20px', width: '18px' }}
      />
      {unreadNotificationCount > 0 && (
        <div
          className="t-w-4 t-h-4 t-rounded-full t-bg-epsilon-600 t-absolute t-border t-border-solid t-border-alpha-150"
          style={{ left: '18px', top: '5px' }}
        >
          <p
            className="t-text-gamma-400 t-text-xxs t-whitespace-nowrap"
            style={{ lineHeight: '16px', fontSize: '8px' }}
          >
            {unreadNotificationCount}
          </p>
        </div>
      )}
    </button>
  );
}
