import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Modal, Button } from 'modules/common/components';

import contactWarning from 'images/icon_contact_warning.svg';

interface ContactFormWarningModalProps {
  onContinue: () => void;
  onHide?: () => void;
}

function ContactFormWarningModal({
  onContinue,
  onHide,
}: ContactFormWarningModalProps) {
  const { t } = useTranslation(['mcontacts', 'common']);

  return (
    <Modal show onHide={onHide}>
      <img
        src={contactWarning}
        className="t-block t-mx-auto t-mb-4"
        alt={t('mcontacts:death_notification_modal_warning_img_alt')}
      />

      <h1 className="typo-alpha t-pb-8">
        {t('mcontacts:death_notification_modal_warning_title')}
      </h1>

      <p className="typo-epsilon t-pb-4">
        {t('mcontacts:death_notification_modal_warning_description')}
      </p>

      <div className="t-flex t-flex-wrap t-justify-end xs:t-mt-5 sm:t-mt-0 t-z-0 t-relative">
        <Button
          category="secondary"
          className="xs:t-mt-5 xs:t-order-2 sm:t-order-1 xs:t-ml-4"
          onClick={onHide}
        >
          {t('common:cancel')}
        </Button>
        <Button
          category="dangerous"
          className="xs:t-mt-5 xs:t-order-2 sm:t-order-1 xs:t-ml-4"
          onClick={onContinue}
        >
          {t('common:confirm')}
        </Button>
      </div>
    </Modal>
  );
}

export default ContactFormWarningModal;
