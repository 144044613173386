import React from 'react';
import { useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';

import { getAllowedToken } from 'store/actions/userActions';
import { useTranslation } from 'modules/common/helpers';

import { Modal, Row, Col } from 'modules/bootstrap/components';
import { Button, TextInputRef } from 'modules/common/components';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';

type PassPhraseModalProps = {
  show: boolean;
  onCancel: () => void;
  onSuccess: () => void;
};

type Inputs = {
  passphrase: string;
};

const PassPhraseModal: React.FC<PassPhraseModalProps> = ({
  show,
  onCancel,
  onSuccess,
}) => {
  const { t } = useTranslation(['passPhraseModal', 'common', 'errors']);
  const {
    register,
    handleSubmit,
    setError,

    formState: { errors },
  } = useForm<Inputs>();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async ({ passphrase }: Inputs) => {
    setLoading(true);
    const result: any = await dispatch(getAllowedToken(passphrase));
    if (result) {
      await onSuccess();
    } else {
      setError('passphrase', { type: 'custom' });
      setLoading(false);
    }
  };

  const error =
    errors.passphrase?.type === 'custom'
      ? t('errors:notAllowed')
      : errors.passphrase
      ? t('common:field_required')
      : '';

  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={onCancel}
      className="small"
      data-testid="PassPhraseModal"
    >
      <ReactTooltip
        id="tooltip-info"
        effect="solid"
        place="bottom"
        multiline
        className="u-zindex-max u-w-50"
      />
      <section className="Card u-bg-white">
        <div className="Modal">
          <h1 className="Typography Typography--title u-weight-600 u-pb-15">
            {t('passPhraseModal:title')}
          </h1>

          <p className="Modal-description">
            {t('passPhraseModal:description')}
          </p>

          <p className="Typography u-weight-600 u-pb-25 d-flex align-items-center">
            <span className="t-mr-2.5">
              {t('passPhraseModal:info_message')}
            </span>
            <IconInfo
              data-for="tooltip-info"
              role="presentation"
              data-tip={t('passPhraseModal:info_tooltip')}
              className="t-inline t-text-delta-700"
            />
          </p>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="Form"
            data-testid="PassPhraseForm"
          >
            <Row className="u-pb-25">
              <Col xs={{ span: 6 }}>
                <TextInputRef
                  {...register('passphrase', {
                    required: true,
                  })}
                  label={t('passPhraseModal:input_label')}
                  type="password"
                  autoComplete={false}
                  error={error}
                />
              </Col>
            </Row>

            <div className="t-flex t-justify-end">
              <Button
                testId="cancel"
                category="secondary"
                className="t-mr-2.5"
                onClick={onCancel}
                disabled={loading}
              >
                {t('common:cancel')}
              </Button>
              <Button type="submit" loading={loading}>
                {t('common:submit')}
              </Button>
            </div>
          </form>
        </div>
      </section>
    </Modal>
  );
};

export default PassPhraseModal;
