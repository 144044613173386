import { getBankAuthorizationLetterQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/bankAuthorizationLetter';
import { getCustodyOrderQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/custodyOrder';
import { getDigitalEstateQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/digitalEstate';
import { getFinancialSupplyAgedQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/financialSupplyAged';
import { getFinancialSupplyCareQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/financialSupplyCare';
import { getFuneralProvisionQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/funeralProvision';
import { getInheritanceTaxQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/inheritanceTax';
import { getCareOccupationalDisabilityQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/occupationalDisabilityCare';
import { getEmergencyPassQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/emergencyPass';
import { getPostalPowerOfAttorneyQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/postalPowerOfAttorney';
import { getHealthCareProxyQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/healthCareProxy';
import { getPatientDecreeQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/patientDecree';
import { getOrganDonorCardQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/organDonorCard';
import { getWillQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/will';
import { getVehicleDocumentsQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/vehicleDocuments';
import { getPurchaseLoansRightsQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/purchaseLoansRights';
import { getContactLandlordRentalQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/landlordRentalAgreement';
import { getCareDirectiveQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/careDirective';
import { getCareQuestionnaire } from 'modules/assistant/constants/contentQuestionnaires/care';

export enum CONTENT_QUESTIONNAIRE_KEYS {
  FUNERAL_PROVISION = 'funeralProvisionContent',
  FINANCIAL_SUPPLY_CARE = 'financialSupplyCare',
  FINANCIAL_SUPPLY_AGED = 'financialSupplyAged',
  CARE_OCCUPATIONAL_DISABILITY = 'occupationalDisabilityCare',
  EMERGENCY_PASS = 'emergencyPass',
  EMERGENCY_PASS_KIDS = 'emergencyPassKids',
  BANK_AUTHORIZATION_LETTER = 'bankAuthorizationLetterContent',
  CARE_DIRECTIVE = 'careDirective',
  POSTAL_POWER_OF_ATTORNEY = 'postalPowerOfAttorney',
  CUSTODY_ORDER = 'custodyOrderContent',
  DIGITAL_ESTATE = 'digitalEstate',
  INHERITANCE_TAX = 'inheritanceTax',
  PURCHASE_LOANS_RIGHTS = 'purchaseLoansRights',
  LANDLORD_RENTAL_AGREEMENT = 'landlordRentalAgreement',
  VEHICLE_DOCUMENTS = 'vehicleDocuments',
  HEALTH_CARE_PROXY = 'healthCareProxyContent',
  ORGAN_DONOR_CARD = 'organDonorCardContent',
  PATIENT_DECREE = 'patientDecreeContent',
  WILL = 'willContent',
  CARE = 'careContent',
}

export const CONTENT_QUESTIONNAIRE_KEYS_WITH_DOC_GEN = {
  FUNERAL_PROVISION: CONTENT_QUESTIONNAIRE_KEYS.FUNERAL_PROVISION,
  CUSTODY_ORDER: CONTENT_QUESTIONNAIRE_KEYS.CUSTODY_ORDER,
  HEALTH_CARE_PROXY: CONTENT_QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY,
  ORGAN_DONOR_CARD: CONTENT_QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD,
  PATIENT_DECREE: CONTENT_QUESTIONNAIRE_KEYS.PATIENT_DECREE,
  WILL: CONTENT_QUESTIONNAIRE_KEYS.WILL,
  BANK_AUTHORIZATION_LETTER:
    CONTENT_QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER,
};

export const CONTENT_QUESTIONNAIRE_PAGES = {
  [CONTENT_QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER]: {
    path: '/assistant/bank-authorization-letter-content',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CUSTODY_ORDER]: {
    path: '/assistant/custody-order-content',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.CUSTODY_ORDER,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.DIGITAL_ESTATE]: {
    path: '/assistant/digital-estate',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.DIGITAL_ESTATE,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_AGED]: {
    path: '/assistant/financial-supply-aged',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_AGED,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_CARE]: {
    path: '/assistant/financial-supply-care',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_CARE,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.FUNERAL_PROVISION]: {
    path: '/assistant/funeral-provision-content',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.FUNERAL_PROVISION,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.INHERITANCE_TAX]: {
    path: '/assistant/inheritance-tax',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.INHERITANCE_TAX,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CARE_OCCUPATIONAL_DISABILITY]: {
    path: '/assistant/occupational-disability-care',
    contentQuestionnaire:
      CONTENT_QUESTIONNAIRE_KEYS.CARE_OCCUPATIONAL_DISABILITY,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.EMERGENCY_PASS]: {
    path: '/assistant/emergency-pass',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.EMERGENCY_PASS,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.POSTAL_POWER_OF_ATTORNEY]: {
    path: '/assistant/postal-power-of-attorney',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.POSTAL_POWER_OF_ATTORNEY,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY]: {
    path: '/assistant/health-care-proxy-content',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.PATIENT_DECREE]: {
    path: '/assistant/patient-decree-content',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.PATIENT_DECREE,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD]: {
    path: '/assistant/organ-donor-card-content',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.WILL]: {
    path: '/assistant/will-content',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.WILL,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.VEHICLE_DOCUMENTS]: {
    path: '/assistant/vehicle-documents',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.VEHICLE_DOCUMENTS,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.PURCHASE_LOANS_RIGHTS]: {
    path: '/assistant/purchase-loans-rights',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.PURCHASE_LOANS_RIGHTS,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.LANDLORD_RENTAL_AGREEMENT]: {
    path: '/assistant/landlord-rental-agreement',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.LANDLORD_RENTAL_AGREEMENT,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CARE_DIRECTIVE]: {
    path: '/assistant/care-directive',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.CARE_DIRECTIVE,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CARE]: {
    path: '/assistant/care-content',
    contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS.CARE,
  },
};

export const getContentQuestionnaire = (contentQuestionnaire, t) => {
  switch (contentQuestionnaire) {
    case CONTENT_QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER:
      return getBankAuthorizationLetterQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.CUSTODY_ORDER:
      return getCustodyOrderQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.DIGITAL_ESTATE:
      return getDigitalEstateQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_AGED:
      return getFinancialSupplyAgedQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_CARE:
      return getFinancialSupplyCareQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.FUNERAL_PROVISION:
      return getFuneralProvisionQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.INHERITANCE_TAX:
      return getInheritanceTaxQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.CARE_OCCUPATIONAL_DISABILITY:
      return getCareOccupationalDisabilityQuestionnaire(
        contentQuestionnaire,
        t
      );
    case CONTENT_QUESTIONNAIRE_KEYS.EMERGENCY_PASS:
      return getEmergencyPassQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.POSTAL_POWER_OF_ATTORNEY:
      return getPostalPowerOfAttorneyQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY:
      return getHealthCareProxyQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.PATIENT_DECREE:
      return getPatientDecreeQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD:
      return getOrganDonorCardQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.WILL:
      return getWillQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.VEHICLE_DOCUMENTS:
      return getVehicleDocumentsQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.PURCHASE_LOANS_RIGHTS:
      return getPurchaseLoansRightsQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.LANDLORD_RENTAL_AGREEMENT:
      return getContactLandlordRentalQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.CARE_DIRECTIVE:
      return getCareDirectiveQuestionnaire(contentQuestionnaire, t);
    case CONTENT_QUESTIONNAIRE_KEYS.CARE:
      return getCareQuestionnaire(contentQuestionnaire, t);
    default:
      return null;
  }
};
