import assistantService from 'modules/assistant/services/assistantService';
import { LOAD_ASSISTANT_PROGRESS } from 'store/types/assistantActionTypes';
import helper from 'store/actions/actionHelper';

export const loadAssistantProgressSuccess = (progress: AssistantProgressDTO) =>
  helper.getAction(LOAD_ASSISTANT_PROGRESS, { progress });

export const fetchAssistantProgress = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const progress = await assistantService.getAssistantProgress();
    dispatch(loadAssistantProgressSuccess(progress));
  }, actionOptions);
};

export const getInitialAssessment = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    return assistantService.getInitialAssessment();
  }, actionOptions);
};
