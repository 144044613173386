import React from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { QuestionnaireFormFieldSelector } from 'modules/assistant/components/Answers';
import {
  dateHelper,
  getParsedAddress,
  config,
  useTranslation,
} from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { Button } from 'modules/common/components';

import { QUESTIONNAIRE_FORM_FIELD_TYPES as F } from 'modules/assistant/constants/questionnaires/questionnaireFormFieldTypes';

export interface AddInformationFormProps {
  formTypeData: QuestionnaireFormType[];
  previousAnswer?: Record<string, any>;
  onSuccess: (type: string, submitData: Record<string, any>) => void;
  onCancel: () => void;
}

const AddInformationForm = ({
  formTypeData,
  previousAnswer,
  onSuccess,
  onCancel,
}: AddInformationFormProps) => {
  const { t } = useTranslation(['petWatchingAgreement']);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,

    formState: { errors },
  } = useForm();

  const onSubmit = (formData) => {
    const submitData = {
      ...formData,
      [F.INSURANCE_TYPE]: formData?.other
        ? (formData[F.INSURANCE_TYPE] = formData.other)
        : formData[F.INSURANCE_TYPE]?.value,
      [F.ADDRESS]: getParsedAddress(formData),
      ...(formData?.gender
        ? { gender: (formData?.gender?.value as string) || '' }
        : {}),
      ...(formData[F.BIRTHDAY]
        ? {
            [F.BIRTHDAY]: dateHelper.convertDateFormat(
              formData[F.BIRTHDAY],
              config.format.serverDate
            ),
          }
        : {}),
      ...(formData[F.DATE]
        ? {
            [F.DATE]: dateHelper.convertDateFormat(
              formData[F.DATE],
              config.format.serverDate
            ),
          }
        : {}),
      ...(formData[F.GENDER]
        ? {
            [F.GENDER]: formData?.gender?.value as string,
          }
        : {}),
    };

    delete submitData.other;

    onSuccess(isEmpty(previousAnswer) ? 'add' : 'edit', submitData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        {formTypeData.map((formTypeElement) => (
          <Col
            key={formTypeElement.type}
            xl={{ span: formTypeElement?.span || 12 }}
          >
            <QuestionnaireFormFieldSelector
              formTypeElement={formTypeElement}
              control={control}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              defaultValue={
                previousAnswer ? previousAnswer[formTypeElement.type] : ''
              }
            />
          </Col>
        ))}
      </Row>
      <div className={`t-flex t-justify-between t-mt-5`}>
        <div>
          <Button category="secondary" className="t-mr-2.5" onClick={onCancel}>
            {t('common:cancel')}
          </Button>
        </div>

        <div className={`t-flex t-ml-5`}>
          <Button type="submit">{t('common:submit')}</Button>
        </div>
      </div>
    </form>
  );
};

export default AddInformationForm;
