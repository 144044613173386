import React from 'react';
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from 'react-joyride';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { TutorialTooltip } from 'modules/common/components';

interface TutorialProps {
  onClose: () => void;
  steps: TutorialDTO[];
  extraInfo?: TutorialAction[];
}

export default function Tutorial({ onClose, steps, extraInfo }: TutorialProps) {
  const [run, setRun] = React.useState(true);
  const [stepIndex, setStepIndex] = React.useState(0);

  const body = document.body;
  disableBodyScroll(body);

  React.useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    const currentActionData = extraInfo?.find((info) => info.step === index);

    if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      if (!!currentActionData) {
        currentActionData.action();
      }
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if (
      ([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)
    ) {
      setRun(false);
      clearAllBodyScrollLocks();
      onClose();
    }
  };

  return (
    <Joyride
      steps={steps as Step[]}
      run={run}
      stepIndex={stepIndex}
      continuous
      showSkipButton
      callback={handleJoyrideCallback}
      tooltipComponent={TutorialTooltip}
      disableOverlay={false}
      disableOverlayClose
    />
  );
}
