import React from 'react';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';

import { useTranslation } from 'modules/common/helpers';

import { REDIRECT_ACTIONS } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';

import { ReactComponent as IconChevroneDown } from 'images/v-shaped-down.svg';
import { ReactComponent as IconChevroneUp } from 'images/v-shaped-up.svg';
import imgCalculator from 'images/img_inheritance_tax.png';

const InheritanceTaxCTACard = () => {
  const { t } = useTranslation(['common', 'assistant']);

  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const toggleShowCard = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  return (
    <div className="card t-overflow-hidden t-mt-6">
      <Accordion defaultActiveKey="calculate" onSelect={toggleShowCard}>
        <Accordion.Item eventKey="calculate">
          <Accordion.Button as="div">
            <div className="t-flex t-justify-between t-bg-delta-900 t-px-6 t-py-4">
              <h3 className="typo-gamma t-text-gamma-400">
                {t(`assistant:inheritance_tax_cal_cta_title`)}
              </h3>

              <div className="t-outline-none t-p-1 t-flex t-items-center">
                {isCollapsed ? (
                  <IconChevroneDown className="t-text-gamma-400" />
                ) : (
                  <IconChevroneUp className="t-text-gamma-400" />
                )}
              </div>
            </div>
          </Accordion.Button>
          <Accordion.Collapse eventKey="calculate">
            <div className="t-bg-beta-50 t-flex t-justify-between t-items-stretch">
              <div className="t-hidden sm:t-flex sm:t-items-start xl:t-w-44">
                <img
                  className="t-h-full"
                  src={imgCalculator}
                  alt="Inheritance tax calculator"
                />
              </div>
              <div className="t-p-3 t-flex-grow t-flex t-flex-col xl:t-flex-row xl:t-items-center xl:t-justify-between">
                <p className="typo-delta t-mb-2 xl:t-mr-6 xl:t-mb-0">
                  {t('assistant:inheritance_tax_cal_cta_description')}
                </p>
                <div className="t-flex t-justify-end t-items-center t-mr-6 xl:t-ml-2">
                  <Link
                    to={'/plan/financial'}
                    state={{ elementId: REDIRECT_ACTIONS.GO_TO_TAX_CALCULATOR }}
                    className="button-primary t-whitespace-nowrap"
                  >
                    {t('assistant:inheritance_tax_cal_calculate_now')}
                  </Link>
                </div>
              </div>
            </div>
          </Accordion.Collapse>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default InheritanceTaxCTACard;
