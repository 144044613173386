import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconWarning } from 'images/icon-warning.svg';

const WarningBox = () => {
  const { t } = useTranslation(['securityKey', 'common']);

  return (
    <div
      className="t-border t-border-solid t-border-epsilon-600 t-box-border t-rounded-sm t-mb-6"
      data-testid="WarningBox"
    >
      <div className="t-p-5">
        <div className="t-flex t-pb-4">
          <IconWarning className="t-text-epsilon-600 t-mr-2.5" />
          <div className="text-main t-font-semibold">
            {t('securityKey:security_key_warn_title')}
          </div>
        </div>
        <p>{t('securityKey:security_key_warn_description')}</p>
      </div>
    </div>
  );
};

export default WarningBox;
