import React from 'react';
import { useDispatch } from 'react-redux';

import { updateEntireStore, upgradeProfile } from 'store/actions/userActions';
import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

import imgUpgradeProfile1 from 'images/icon-upgrade-profile-benefits-1.svg';
import imgUpgradeProfile2 from 'images/icon-upgrade-profile-benefits-2.svg';
import imgUpgradeProfile3 from 'images/icon-upgrade-profile-benefits-3.svg';

interface UpgradeProfileInfoModalProps {
  onClose: () => void;
}

const upgradeProfileBenefits = [
  {
    id: 'upgrade_profile_benefits_1',
    icon: imgUpgradeProfile1,
    description: 'profile:upgrade_profile_benefits_1',
  },
  {
    id: 'upgrade_profile_benefits_2',
    icon: imgUpgradeProfile2,
    description: 'profile:upgrade_profile_benefits_2',
  },
  {
    id: 'upgrade_profile_benefits_3',
    icon: imgUpgradeProfile3,
    description: 'profile:upgrade_profile_benefits_3',
  },
];

const UpgradeProfileInfoModal = ({ onClose }: UpgradeProfileInfoModalProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['profile', 'common']);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleUpgradeProfile = async () => {
    setIsLoading(true);
    await dispatch(upgradeProfile());
    await dispatch(updateEntireStore());
    setIsLoading(false);
  };

  return (
    <Modal show onHide={onClose} dataTestid="UpgradeProfileInfoModal">
      <h1 className="typo-alpha t-text-alpha-700">
        {t('profile:upgrade_profile_modal_title')}
      </h1>
      <p className="typo-epsilon t-mt-5">
        {t('profile:upgrade_profile_modal_description')}
      </p>

      {upgradeProfileBenefits.map(({ id, icon, description }) => (
        <div className="t-flex t-mt-5" key={id}>
          <span className="t-mr-3">
            <img src={icon} alt="Unlocked benefits" />
          </span>
          <p className="typo-epsilon">{t(description)}</p>
        </div>
      ))}

      <div className="t-flex t-justify-end t-mt-8">
        <Button category="secondary" onClick={onClose} disabled={isLoading}>
          {t('common:close')}
        </Button>
        <Button
          onClick={handleUpgradeProfile}
          className="t-ml-2.5"
          loading={isLoading}
        >
          {t('common:continue')}
        </Button>
      </div>
    </Modal>
  );
};

export default UpgradeProfileInfoModal;
