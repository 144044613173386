import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { Modal } from 'modules/common/components';
import { AgentInfo } from 'modules/agent/components';

import { GENDERS } from 'modules/common/constants/enums';

import imgAgent from 'images/agents/avatar_agent_1.svg';
import imgCompany from 'images/agents/avatar_agent_company.svg';

interface AgentContactModalProps {
  onClose: () => void;
}

export default function AgentContactModal({ onClose }: AgentContactModalProps) {
  const { t } = useTranslation(['agents']);

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const agentInfo = currentUser.agent;

  if (!agentInfo) return null;

  const defaultAvatar =
    !agentInfo.name && !agentInfo.surname ? imgCompany : imgAgent;

  return (
    <Modal show onHide={onClose} hasCloseCross dataTestid="AgentContactModal">
      <div className="t-flex t-flex-col t-items-center">
        <h1 className="typo-alpha">
          {t(
            `agents:assistant_suggestion_agent_title_${
              agentInfo.gender || GENDERS.OTHER
            }`
          )}
        </h1>
        <img
          src={agentInfo.profilePicture || defaultAvatar}
          alt="Agent"
          className="t-mt-6 t-w-20 t-h-20 t-rounded-full t-object-cover"
        />
        <AgentInfo
          agentInfo={agentInfo}
          className="t-mt-4 t-flex t-flex-col t-items-center"
        />
      </div>
    </Modal>
  );
}
