import helper from 'store/reducers/reducerHelper';
import initialState from 'store/reducers/initialState';

import {
  ASYNC_ACTION_START,
  ASYNC_ACTION_WITH_SPINNER_START,
  ASYNC_ACTION_END,
  ADD_PATHNAME,
} from 'store/types/commonActionTypes';

const commonReducer = (state = initialState.common, action) => {
  return helper.handleActions(state, action, {
    [ASYNC_ACTION_START](state, payload) {
      state.asyncAction = { showOverlay: payload.showOverlay };
    },
    [ASYNC_ACTION_WITH_SPINNER_START](state, payload) {
      state.asyncAction = {
        showOverlayWithSpinner: payload.showOverlayWithSpinner,
      };
    },
    [ASYNC_ACTION_END](state) {
      state.asyncAction = null;
    },
    [ADD_PATHNAME](state, payload) {
      state.pathname = payload.pathname || '/';
    },
  });
};

export default commonReducer;
