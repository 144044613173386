import { useTranslation } from 'react-i18next';
import { de, enGB } from 'date-fns/locale';
import { LANGUAGES } from 'modules/common/constants/enums';

const useGetLocalizationForDate = () => {
  const { i18n } = useTranslation();
  return i18n.language === LANGUAGES.DE ? de : enGB;
};

export default useGetLocalizationForDate;
