import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconSuccess } from 'images/success.svg';

const SuccessScreen = () => {
  const { t } = useTranslation(['initialAssessment']);

  return (
    <div className="QuestionnaireCard t-overflow-hidden xs:t--ml-5 sm:t-ml-0 t-mt-12">
      <div className="t-flex t-flex-col t-items-center t-mx-5 t-mt-8 t-mb-12">
        <IconSuccess
          role="presentation"
          className="t-text-alpha-700 t-w-20 t-h-20"
        />
        <p className="title t-font-semibold t-my-7">
          {t('page_title_success')}
        </p>
        <div className="QuestionnaireCard-container t-my-7 sm:t-mx-12 t-text-center">
          {t('page_description_success')}
        </div>
        <Link to="/dashboard" className="button-primary">
          {t('assistant:initial_questionnaire_done')}
        </Link>
      </div>
    </div>
  );
};

export default SuccessScreen;
