import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import { RootState } from 'store/reducers';
import {
  signUp,
  loginUser,
  getCurrentUser,
  setUserLanguage,
} from 'store/actions/userActions';
import { setLoading } from 'store/actions/commonActions';
import {
  validationHelper,
  reactToast,
  useQuery,
  handleResponseError,
  config,
  useTranslation,
} from 'modules/common/helpers';
import { authHelper } from 'modules/auth/helpers';

import { FormConditionsNew } from 'modules/profile/components';
import { TextInput, Spinner } from 'modules/common/components';
import {
  AuthFooter,
  SignUpHeader,
  SignUpLogoAndLanguage,
} from 'modules/auth/components';

import { LOCAL_STORAGE_VARS, URL_PARAMS } from 'modules/common/constants/enums';
import { CLIENT_KEYS, ERROR_CODES } from 'modules/common/constants';

interface SignUpFormProps {
  customization: ClientCustomization;
  token?: string;
  client?: string;
  data?: Record<string, string>;
  fullName?: string;
  customFont?: string;
  loginTextClassName?: string;
}

export default function SignUpForm({
  customization,
  token,
  client,
  data,
  fullName,
  customFont = '',
  loginTextClassName = '',
}: SignUpFormProps) {
  const { t } = useTranslation(['common', 'auth']);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const qryReferral = useQuery().get(URL_PARAMS.REFERRAL);

  const [user, setUser] = useState({ email: data?.email || '', password: '' });
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [pwValidity, setPwValidity] = useState({} as PasswordValidity);
  const [isPasswordTyping, setIsPasswordTyping] = useState(false);
  const isSignupSending = useSelector(
    (state: RootState) => state.loading.value
  );

  useEffect(() => {
    setPwValidity(authHelper.getPasswordValidity(user.password));
  }, [user.password]);

  useEffect(() => {
    if (data?.email) {
      setUser((user) => ({ ...user, email: data.email }));
    }
  }, [data]);

  const onChange = (field: string, value) => {
    const newUser = { ...user };

    setErrors((errors) => ({ ...errors, [field]: '' }));

    if (field === 'password' && value) {
      setIsPasswordTyping(true);
    }

    newUser[field] = value;

    setUser(newUser);
  };

  const isValidRegisterForm = () => {
    const formErrors = {
      email: '',
      password: '',
    } as UserCredentials;

    if (!user.email) {
      formErrors.email = t('auth:email_required');
    } else if (!isEmail(user.email)) {
      formErrors.email = t('auth:email_not_valid');
    }

    formErrors.password = authHelper.getPasswordErrorMessages(user.password, t);

    setErrors(formErrors);

    return validationHelper.isEmptyErrorObject(formErrors);
  };

  const onSignUp = async (e) => {
    if (e) e.preventDefault();

    if (!isValidRegisterForm()) return;

    dispatch(setLoading(true));

    try {
      const browserLang = localStorage.getItem(
        LOCAL_STORAGE_VARS.SITE_LANGUAGE
      );
      const signUpData: Record<string, string> = {
        ...user,
        ...(data || {}),
        email: user.email.toLowerCase(),
      };

      if (browserLang) signUpData.language = browserLang;
      if (qryReferral) signUpData.referral = qryReferral;
      if (token) signUpData.token = token;

      const response: any = await dispatch(
        signUp(signUpData, { returnError: true })
      );
      const responseError = handleResponseError(response);

      if (responseError) {
        if (responseError === ERROR_CODES.USER_ALREADY_EXISTS) {
          setErrors({ ...errors, email: t(`errors:${responseError}`) });
        } else {
          setUser({ email: '', password: '' });
          reactToast.showError(t(`errors:${responseError}`));
        }
      } else {
        await dispatch(loginUser(user));

        const userProfile: any = await dispatch(getCurrentUser());
        if (browserLang && userProfile?.language !== browserLang) {
          await dispatch(setUserLanguage(browserLang));
        }
        if (window.location.pathname !== 'verify-email' && !data?.email) {
          navigate('/verify-email');
        }
        if (!!data?.email) {
          navigate('/register-wizard');
        }
      }
    } catch (error) {
      reactToast.showError(error as string);
      setUser({ email: '', password: '' });
    }
    dispatch(setLoading(false));
  };

  const onPasswordInputBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setIsPasswordTyping(false);
    }
  };

  const isPasswordInvalid = Object.values(pwValidity).some((el) => !el);

  const {
    partnershipLogo,
    cardFormClassName,
    customizedFormLabel,
    customInputStyles,
    customizedPrimaryButton,
    customizedSignupButtonText,
    customizedLoginButtonText,
    loginButtonInsideFormCard,
    authFooter,
    partnershipLogoClassName,
    hideBottomPartnershipText,
  } = customization || {};

  const customizedInputStyles = customInputStyles
    ? `${client}-input-styles`
    : '';

  return (
    <div
      className={`t-min-h-screen t-flex ${
        partnershipLogo ? 't-items-end' : ' t-items-center'
      }`}
    >
      <form
        onSubmit={onSignUp}
        className="Form t-pb-12 lg:t-pt-8 md:t-pb-12 md:t-px-16 md:t-max-w-sm t-bg-transparent"
      >
        <div>
          {partnershipLogo && <div className="t-mt-12 t-hidden lg:t-block" />}
          <div className="lg:t-hidden">
            <SignUpLogoAndLanguage customization={customization} />
            <SignUpHeader
              customization={customization}
              client={client}
              fullName={fullName}
              customFont={customFont}
            />
          </div>

          <div
            className={`card t-shadow-lg t-bg-beta-50 t-p-6 t-mt-6 lg:t-mt-14 ${
              cardFormClassName || ''
            }`}
          >
            <h2 className={`typo-beta t-mb-5 ${customFont}`}>
              {t(
                `auth:${
                  customizedFormLabel ? client : CLIENT_KEYS.DEFAULT
                }_signup_form_label`
              )}
            </h2>

            <fieldset disabled={isSignupSending}>
              <TextInput
                name="email"
                label={t('auth:email_label_long')}
                type="email"
                value={user.email}
                onChange={onChange}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange('email', e.target.value.trim())
                }
                placeholder={t('auth:email_placeholder')}
                error={errors.email}
                maxLength={300}
                labelClass={customFont}
                inputClass={`${customizedInputStyles} ${customFont}`}
              />

              <TextInput
                name="password"
                label={t('common:password_label')}
                type="password"
                value={user.password}
                onChange={onChange}
                onBlur={onPasswordInputBlur}
                onFocus={() => {
                  setIsPasswordTyping(true);
                }}
                placeholder={t('auth:password_placeholder_registration')}
                error={errors.password}
                labelClass={customFont}
                inputClass={`${customizedInputStyles} ${customFont}`}
              />
            </fieldset>

            <div className="t-relative">
              {isPasswordTyping && isPasswordInvalid && (
                <div className="t-absolute -t-top-2 t-bg-beta-50 t-animate-fade-in-100">
                  <FormConditionsNew
                    pwValidity={pwValidity}
                    customFont={customFont}
                  />
                </div>
              )}
            </div>

            <div className="t-mt-5">
              <p className={`typo-theta t-text-beta-700 ${customFont}`}>
                {t('auth:signup_click_text')}&nbsp;
                <a
                  href={`${config.websiteUrl}/terms`}
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                  className={`t-no-underline t-text-alpha-600 ${
                    customizedPrimaryButton ? `${client}-link-color` : ''
                  } ${customFont}`}
                >
                  {t('auth:signup_agreement')}
                </a>
                <span>{` ${t('common:and')} `}</span>
                <a
                  href={
                    client === 'vbbb'
                      ? `${config.websiteUrl}/privacy-vbbb`
                      : `${config.websiteUrl}/privacy`
                  }
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                  className={`t-no-underline t-text-alpha-600 ${
                    customizedPrimaryButton ? `${client}-link-color` : ''
                  } ${customFont}`}
                >
                  {t('auth:signup_privacy')}
                </a>
                .
              </p>
            </div>

            <button
              type="submit"
              disabled={isSignupSending}
              onClick={onSignUp}
              className={`button t-mt-6 ${
                customizedPrimaryButton
                  ? `${client}-button-primary`
                  : 'button-primary'
              } ${customFont}`}
            >
              {isSignupSending && <Spinner className="t-h-5 t-w-5 t-mr-2" />}
              {t(
                `auth:${
                  customizedSignupButtonText ? client : CLIENT_KEYS.DEFAULT
                }_signup_button_text`
              )}
            </button>

            {loginButtonInsideFormCard && (
              <p className={`typo-theta t-mt-8 ${customFont}`}>
                {t('auth:signup_have_acc')}
                <Link
                  className={`t-font-semibold t-ml-1 ${
                    customizedPrimaryButton
                      ? `${client}-link-color-dark-bg`
                      : 't-text-alpha-600'
                  } ${customFont}`}
                  to="/login"
                >
                  {t(
                    `auth:${
                      customizedLoginButtonText ? client : CLIENT_KEYS.DEFAULT
                    }_login_button_text`
                  )}
                </Link>
              </p>
            )}
          </div>

          <div className="t-mt-4">
            {!loginButtonInsideFormCard && (
              <p
                className={`${
                  loginTextClassName || 'typo-theta'
                } t-pb-4 ${customFont}`}
              >
                {t('auth:signup_have_acc')}
                <Link
                  className={`t-font-semibold t-ml-1 ${
                    customizedPrimaryButton
                      ? `${client}-link-color-dark-bg`
                      : 't-text-alpha-600'
                  } ${customFont}`}
                  to="/login"
                >
                  {t(
                    `auth:${
                      customizedLoginButtonText ? client : CLIENT_KEYS.DEFAULT
                    }_login_button_text`
                  )}
                </Link>
              </p>
            )}
            <div className="sm:t-flex t-justify-end t-items-end t-flex-col sm:t-flex-row">
              {partnershipLogo && (
                <div className="t-mt-2">
                  {!hideBottomPartnershipText && (
                    <p
                      className={`typo-eta t-text-beta-700 t-font-semibold sm:t-text-end sm:t-mt-0 ${customFont}`}
                    >
                      {t('common:in_partnership_with')}
                    </p>
                  )}
                  <div className="t-mt-2 t-min-h-32">
                    <img
                      className={partnershipLogoClassName || 't-h-'}
                      src={partnershipLogo}
                      alt="partnership"
                    />
                  </div>
                </div>
              )}
            </div>

            {authFooter && (
              <div className="lg:t-hidden t-mt-11">
                <AuthFooter customFont={customFont} />
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
