import React from 'react';

import { ReactComponent as SpinnerIcon } from 'images/loading-spinner.svg';

interface SpinnerProps {
  className?: string;
}

export default function Spinner({ className }: SpinnerProps) {
  return (
    <SpinnerIcon
      className={`t-animate-spin ${className}`}
      data-testid="Spinner"
    />
  );
}
