import { httpHelper, config } from 'modules/common/helpers';

const BASE_URL = config.beUrl;

export default {
  getAssistantProgress,
  getInitialAssessment,
  getRecommendations,
  updateRecommendation,
};

export function getAssistantProgress() {
  return httpHelper.get(`${BASE_URL}/api/answers/progress`, {});
}

export function getInitialAssessment() {
  return httpHelper.get(`${BASE_URL}/api/answers/initial-assessment`, {});
}

export function getRecommendations() {
  return httpHelper.get(`${BASE_URL}/api/recommendation`, {});
}

function updateRecommendation(recommendation: RecommendationDTO) {
  return httpHelper.put(`${BASE_URL}/api/recommendation`, recommendation);
}
