import i18n from 'i18next';
import routerHistory from 'routerHistory';

import { getSuccessMessage, reactToast } from 'modules/common/helpers';
import { dataService } from 'modules/common/services';
import authService from 'modules/auth/services/authService';
import { LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';

import helper from 'store/actions/actionHelper';
import {
  LOAD_CURRENT_USER,
  UPDATE_CURRENT_USER,
  SET_USER_LANGUAGE,
  USER_LOGOUT,
} from 'store/types/userActionTypes';
import { getPlanProgress } from 'store/actions/planActions';
import {
  getBackupContacts,
  getContactsList,
} from 'store/actions/contactActions';
import { getAssetsList } from 'store/actions/assetActions';
import { getAssetTypesList } from 'store/actions/assetTypeActions';
import { fetchAssistantProgress } from 'store/actions/assistantActions';
import feedbackService from 'modules/common/services/feedbackService';
import { getNotifications } from 'store/actions/notificationActions';
import { getContactOfList } from 'store/actions/contactOfActions';
import { getRecommendations } from 'store/actions/recommendationsActions';
import { getNextSteps } from 'store/actions/nextStepActions';
import { updateAuthTokens } from 'store/actions/tokenActions';
import { getFamilyAccounts } from 'store/actions/familyAccountActions';

export const getCurrentUserSuccess = (user) =>
  helper.getAction(LOAD_CURRENT_USER, { user });
export const updateCurrentUserSuccess = (userData) =>
  helper.getAction(UPDATE_CURRENT_USER, { userData });
export const setUserLanguageSuccess = (language: string) =>
  helper.getAction(SET_USER_LANGUAGE, { language });
export const logOutSuccess = () => helper.getAction(USER_LOGOUT, {});

export const getCurrentUser = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const user = await dataService.getCurrentUser();
    dispatch(getCurrentUserSuccess(user));

    return user;
  }, actionOptions);
};

export const updateCurrentUser = (
  userData: UserProfileDTO,
  withoutToast?: boolean,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const response = await dataService.updateCurrentUser(userData);

    if (response.error) {
      reactToast.showError(response.error);
    } else {
      dispatch(updateCurrentUserSuccess(response));
      if (!withoutToast)
        reactToast.showMessage(i18n.t('toastr:profile_update_success'));
    }

    return true;
  }, actionOptions);
};

export const setUserLanguage = (
  language: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    await dataService.setUserLanguage(language);
    dispatch(setUserLanguageSuccess(language));
    reactToast.showMessage(i18n.t('toastr:language_set_success'));

    return true;
  }, actionOptions);
};

export const createTransmortalAuthorization = (
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await dataService.createTransmortalAuthorization();
    reactToast.showMessage(i18n.t('toastr:transmortal_sign_success'));

    return response;
  }, actionOptions);
};

export const loginUser = (
  userCredentials: UserCredentials,
  actionOptions?: ActionOptions
) => {
  const loginData = { ...userCredentials };

  return helper.dispatchAsyncAction(async (): Promise<SuccessAuthResponse> => {
    const response = await authService.login(loginData);

    if (response?.accessToken) {
      if (!response.tfaEnabled && !response.smsTfaEnabled) {
        updateAuthTokens(response.accessToken, response.refreshToken);
      }

      localStorage.setItem(LOCAL_STORAGE_VARS.IS_ALLOWED_TOKEN, '');
    }

    if (response.smsTfaEnabled) {
      reactToast.showMessage(i18n.t('toastr:sms_tfa_code_sent'));
    }

    if (
      window.location.pathname.includes('/register') &&
      response?.accessToken
    ) {
      routerHistory.push('/verify-email');
    }

    return response;
  }, actionOptions);
};

export const accessBeneficiary = (
  beneficiaryCredentials: BeneficiaryCredentials,
  actionOptions?: ActionOptions
) => {
  const accessData = { ...beneficiaryCredentials };

  return helper.dispatchAsyncAction(
    async (dispatch): Promise<SuccessAuthResponse> => {
      const response = await authService.access(accessData);

      if (response?.accessToken) {
        updateAuthTokens(response.accessToken, response.refreshToken);
        dispatch(getCurrentBeneficiary());
      }
      return response;
    },
    actionOptions
  );
};

export const logOut = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncActionWithSpinner(async (dispatch) => {
    await authService.logout();
    updateAuthTokens('', '');
    localStorage.setItem(LOCAL_STORAGE_VARS.IS_ALLOWED_TOKEN, '');
    dispatch(logOutSuccess());
  }, actionOptions);
};

export const forgotPassword = (
  email: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.passwordForgot(email);

    if (response?.message) {
      reactToast.showMessage(getSuccessMessage(response.message));
      return true;
    }

    if (response.error) reactToast.showError(response.error);

    return false;
  }, actionOptions);
};

export const resetPassword = (
  userData,
  token: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.resetPassword(userData, token);

    if (response?.accessToken) {
      updateAuthTokens(response.accessToken, response.refreshToken);
    }

    return response;
  }, actionOptions);
};

export const validateEmail = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.validateEmail();
    reactToast.showMessage(getSuccessMessage(response.message));

    return response;
  }, actionOptions);
};

export const signUp = (user, actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.signUp(user);
    reactToast.showMessage(getSuccessMessage(response.message));

    return response;
  }, actionOptions);
};

export const changePassword = (passwordData, actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.changePassword(passwordData);
    reactToast.showMessage(i18n.t('toastr:password_upgrade_success'));

    return response;
  }, actionOptions);
};

export const checkResetToken = (token, actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.resetPasswordTokenCheck(token);
    return response;
  }, actionOptions);
};

export const upgradeProfile = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.upgradeProfile();
    reactToast.showMessage(i18n.t('toastr:profile_upgrade_success'));

    if (response?.accessToken) {
      updateAuthTokens(response.accessToken, response.refreshToken);
    }

    return response;
  }, actionOptions);
};

export const deleteProfile = (
  hard: boolean,
  code: number,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.deleteProfile(hard, code);
    reactToast.showMessage(i18n.t('toastr:profile_delete_success'));

    return response;
  }, actionOptions);
};

export const sendFeedback = (data, actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await feedbackService.sendFeedback(data);

    reactToast.showMessage(i18n.t('toastr:contact_message_send_success'));

    return response;
  }, actionOptions);
};

export const generateSMSCode = (
  phone: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.generateSMSCode(phone);

    if (response?.message) {
      reactToast.showMessage(getSuccessMessage(response.message));
    }

    return response;
  }, actionOptions);
};

export const turnOnSmsTwoAuth = (
  smsTfaCode: number | string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const response = await authService.turnOnSmsTwoAuth(smsTfaCode);

    if (response) {
      updateAuthTokens(response.accessToken, response.refreshToken);
      reactToast.showMessage(i18n.t('toastr:two_fa_success'));
      await dispatch(getPlanProgress());
    }

    return response;
  }, actionOptions);
};

export const turnOffSmsTwoAuth = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.turnOffSmsTwoAuth();

    if (response?.message)
      reactToast.showMessage(getSuccessMessage(response.message));

    return response;
  }, actionOptions);
};

export const optOutTwoAuth = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const response = await authService.optOutTwoAuth();
    if (response) {
      await dispatch(getPlanProgress());
      if (response.message)
        reactToast.showMessage(getSuccessMessage(response.message));
    }
    return response;
  }, actionOptions);
};

export const turnOffSmsTwoAuthConfirm = (
  smsTfaCode: number | string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.turnOffSmsTwoAuthConfirm(smsTfaCode);

    if (response?.message) {
      reactToast.showMessage(getSuccessMessage(response.message));
    }

    return response;
  }, actionOptions);
};

export const smsTwoAuthAuthenticate = (
  smsTfaCode: number | string,
  token: string | undefined,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.smsTwoAuthAuthenticate(
      smsTfaCode,
      token
    );

    if (response) {
      updateAuthTokens(response.accessToken, response.refreshToken);
    }

    return response;
  }, actionOptions);
};

export const generateQRCode = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.generateQRCode();

    if (response?.message) {
      reactToast.showMessage(getSuccessMessage(response.message));
    }

    return response;
  }, actionOptions);
};

export const turnOnGoogleTwoAuth = (
  tfaCode: number | string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const response = await authService.turnOnGoogleTwoAuth(tfaCode);

    if (response) {
      updateAuthTokens(response.accessToken, response.refreshToken);
      reactToast.showMessage(i18n.t('toastr:two_fa_success'));
      await dispatch(getPlanProgress());
    }

    return response;
  }, actionOptions);
};

export const turnOffGoogleTwoAuth = (
  tfaCode: number | string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.turnOffGoogleTwoAuth(tfaCode);

    if (response?.message) {
      reactToast.showMessage(getSuccessMessage(response.message));
    }

    return response;
  }, actionOptions);
};

export const googleTwoAuthAuthenticate = (
  tfaCode: number | string,
  token: string | undefined,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.googleTwoAuthAuthenticate(
      tfaCode,
      token
    );

    if (response) {
      updateAuthTokens(response.accessToken, response.refreshToken);
    }

    return response;
  }, actionOptions);
};

export const updateTutorial = (
  tutorial: TutorialField,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const response = await dataService.updateTutorial(tutorial);

    if (response?.message) {
      reactToast.showMessage(getSuccessMessage(response.message));
    }

    await dispatch(getCurrentUser());

    return response;
  }, actionOptions);
};

export const hideRelease = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const response = await dataService.hideRelease();

    if (response?.message) {
      reactToast.showMessage(getSuccessMessage(response.message));
    }

    await dispatch(getCurrentUser());

    return response;
  }, actionOptions);
};

export const getPaymentSession = (
  plan: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await dataService.getPaymentSession(plan);

    if (response?.message) {
      reactToast.showMessage(getSuccessMessage(response.message));
    }

    return response;
  }, actionOptions);
};

export const addSepaSubscription = (
  plan: string,
  coupon = '',
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await dataService.addSepaSubscription(plan, coupon);
    return response;
  }, actionOptions);
};

export const getSepaClientSecret = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await dataService.getSepaClientSecret();
    return response;
  }, actionOptions);
};

export const getBillingPortalUrl = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await dataService.getBillingPortalUrl();

    if (response?.message) {
      reactToast.showMessage(response.message);
    }

    return response;
  }, actionOptions);
};

export const getAllowedToken = (
  passphrase: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await authService.getAllowedToken(passphrase);

    if (response?.accessToken) {
      localStorage.setItem(LOCAL_STORAGE_VARS.IS_ALLOWED_TOKEN, 'true');
      updateAuthTokens(response.accessToken, response.refreshToken);
    }

    return response;
  }, actionOptions);
};

export const changePassphrase = (
  oldPassphrase: string,
  newPassphrase: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    await authService.changePassphrase(oldPassphrase, newPassphrase);

    return true;
  }, actionOptions);
};

export const passphraseOptOut = (
  passphrase: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await dataService.passphraseOptOut(passphrase);

    if (response?.accessToken) {
      updateAuthTokens(response.accessToken, response.refreshToken);
    }

    return true;
  }, actionOptions);
};

export const passphraseOptIn = (
  passphrase: string,
  code: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    await dataService.passphraseOptIn(passphrase, code);

    return true;
  }, actionOptions);
};

export const generateSelfKeyDocument = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    await dataService.generateSelfKeyDocument();
    await dispatch(getCurrentUser());

    return true;
  }, actionOptions);
};

export const getCurrentBeneficiary = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const user = await dataService.getCurrentBeneficiary();
    dispatch(getCurrentUserSuccess(user));

    return user;
  }, actionOptions);
};

export const generateConfirmationToken = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    await dataService.generateConfirmationToken();

    reactToast.showMessage(i18n.t('toastr:confirmation_token_generated'));

    return true;
  }, actionOptions);
};

export const getCouponInfo = (
  coupon: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await dataService.getCouponInfo(coupon);

    return response;
  }, actionOptions);
};

export const switchAccount = (id: string, actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(
    async (dispatch): Promise<SuccessAuthResponse> => {
      const response = await authService.switchAccount(id);

      if (response?.accessToken) {
        updateAuthTokens(response.accessToken, response.refreshToken);
        await dispatch(updateEntireStore());
      }

      return response;
    },
    actionOptions
  );
};

export const checkEmailAvailability = (
  email: string,
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    await dataService.checkEmailAvailability(email, hideErrors);

    return true;
  }, actionOptions);
};

export const changePrimaryEmail = (
  email,
  code = '',
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const response = await dataService.changePrimaryEmail(email, code);

    await dispatch(getCurrentUser());

    return response;
  }, actionOptions);
};

export const updateEntireStore = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    await Promise.all([
      dispatch(getCurrentUser()),
      dispatch(getPlanProgress()),
      dispatch(getNextSteps()),
      dispatch(getAssetsList()),
      dispatch(getAssetTypesList()),
      dispatch(getContactsList()),
      dispatch(fetchAssistantProgress()),
      dispatch(getNotifications()),
      dispatch(getContactOfList()),
      dispatch(getRecommendations()),
      dispatch(getBackupContacts()),
      dispatch(getFamilyAccounts()),
    ]);

    return true;
  }, actionOptions);
};
