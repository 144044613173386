import produce from 'immer';
import initialState from 'store/reducers/initialState';
import {
  GET_NOTIFICATIONS,
  TOGGLE_NOTIFICATION_PANEL,
  DELETE_NOTIFICATION,
  EDIT_NOTIFICATION,
  MARK_ALL_AS_READ,
} from 'store/types/notificationTypes';

const notificationReducer = (
  state = initialState.notifications,
  action: ReducerAction
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOGGLE_NOTIFICATION_PANEL: {
        draft.isPanelOpen = !draft.isPanelOpen;
        return draft;
      }
      case GET_NOTIFICATIONS: {
        const {
          payload: { notifications },
        } = action;

        draft.notifications = notifications;
        return draft;
      }
      case EDIT_NOTIFICATION: {
        const {
          payload: { modifiedNotification },
        } = action;
        const index = draft.notifications.findIndex(
          (notification) => notification.id === modifiedNotification.id
        );
        if (index !== -1) draft.notifications[index] = modifiedNotification;
        return draft;
      }
      case MARK_ALL_AS_READ: {
        const {
          payload: { modifiedNotifications },
        } = action;

        draft.notifications = modifiedNotifications;
        return draft;
      }
      case DELETE_NOTIFICATION: {
        const {
          payload: { id },
        } = action;
        const index = draft.notifications.findIndex(
          (notification) => notification.id === id
        );
        if (index !== -1) draft.notifications.splice(index, 1);
        return draft;
      }
      default:
        return draft;
    }
  });

export default notificationReducer;
