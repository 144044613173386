import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import ReactTooltip from 'react-tooltip';

import {
  getFamilyAccounts,
  reportDeathOfFamilyAccount,
} from 'store/actions/familyAccountActions';
import {
  reactToast,
  useTranslation,
  validationHelper,
} from 'modules/common/helpers';

import { Button, Modal, TextInputRef } from 'modules/common/components';
import { Checkbox } from 'modules/elements/components';

import { ERROR_CODES } from 'modules/common/constants';

import imgHeadstone from 'images/img-headstone.png';
import { ReactComponent as IconInfo } from 'images/icon-info.svg';

interface DeathReportConfirmModalProps {
  account: FamilyAccount;
  onClose: () => void;
}

const DeathReportConfirmModal = ({
  account,
  onClose,
}: DeathReportConfirmModalProps) => {
  const { t } = useTranslation(['plan']);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setError,

    formState: { errors },
  } = useForm();

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const onReportAsDeath = async (formData) => {
    if (!account.id) return;
    setLoading(true);
    const response: any = await dispatch(
      reportDeathOfFamilyAccount(account.id, formData.code, {
        returnError: true,
      })
    );

    setLoading(false);
    if (response?.errorCode === ERROR_CODES.INVALID_TOKEN) {
      setError('code', { type: 'invalidToken' });
    } else {
      await dispatch(getFamilyAccounts());
      reactToast.showMessage(t('familyAccounts:death_reported_successfully'));
      onClose();
    }
  };

  const handleCheckboxToggle = () => {
    setChecked((prev) => !prev);
  };

  const error =
    errors['code']?.type === 'invalidToken'
      ? t('common:invalid_code')
      : errors['code']?.type === 'validate'
      ? `${t('auth:confirmation_code')} ${t('common:invalid')}`
      : errors['code']
      ? `${t('auth:confirmation_code')} ${t('common:required')}`
      : '';

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Modal show onHide={onClose}>
      <div className="t-flex t-justify-center">
        <img
          src={imgHeadstone}
          className="t-max-h-36"
          alt="Headstone with flower"
        />
      </div>

      <h1 className="typo-alpha t-mt-6">
        {t('familyAccounts:death_report_modal_title')}
      </h1>
      <p className="typo-epsilon t-mt-4">
        {t('familyAccounts:death_report_modal_desc')}
      </p>

      <form onSubmit={handleSubmit(onReportAsDeath)}>
        <fieldset disabled={loading}>
          <div className="t-my-6 t-flex t-items-center">
            <TextInputRef
              inputClass="t-w-1/2"
              {...register('code', {
                required: true,
                validate: (value) => validationHelper.validateCode(4, value),
              })}
              label={t('auth:confirmation_code')}
              type="tel"
              placeholder="XXXX"
              maxLength={4}
              autoComplete={false}
              error={error}
            />
          </div>
        </fieldset>

        <div>
          <Checkbox
            text={t('familyAccounts:death_confirm_checkbox_label')}
            checked={checked}
            onChange={handleCheckboxToggle}
            textClassName="typo-delta"
            isBlueBg
          />
          <IconInfo
            role="presentation"
            className="t-inline t-text-delta-700 t-ml-2"
            data-tip={t('familyAccounts:death_confirm_checkbox_tooltip')}
          />
        </div>

        <div className="t-flex t-justify-end t-items-end t-mt-8 t-flex-col sm:t-flex-row">
          <Button
            category="secondary"
            className="t-mr-0 t-py-2 t-mb-3 sm:t-mb-0 sm:t-mr-3"
            onClick={onClose}
            disabled={loading}
          >
            {t('common:cancel')}
          </Button>

          <Button
            className="t-py-2"
            type="submit"
            loading={loading}
            disabled={!checked}
          >
            {t('familyAccounts:report_death_confirm_btn')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DeathReportConfirmModal;
