import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { RootState } from 'store/reducers';
import { getFullName, useTranslation } from 'modules/common/helpers';
import { Col, Row } from 'modules/bootstrap/components';

interface HeirDistributionEndProps {
  distributionHeirs: Record<string, any>;
}

function HeirDistributionEnd({ distributionHeirs }: HeirDistributionEndProps) {
  const { t } = useTranslation(['common', 'will']);
  const heirs =
    useSelector((state: RootState) => state.heirs.list) || ([] as ContactDTO[]);

  return (
    <Row style={{ marginTop: 10 }}>
      {distributionHeirs.map((disHeir) => {
        const heir = _.find(heirs, (h) => h.id === disHeir.heirId);
        const contactName = getFullName(heir);
        return (
          <Col sm={10} md={5} key={disHeir.heirId}>
            <div className="Card Card--shadow u-bg-white u-pt-25 u-pr-30 u-pb-25 u-pl-30">
              <div className="Character Character--human">
                <div className="Character-footer">
                  <div className="u-w-100">
                    <div className="Character-labelWrapper">
                      <p className="Typography Typography--subTitleH">
                        {contactName}
                      </p>
                    </div>
                    <p style={{ fontSize: 12, marginBottom: 5 }}>
                      {t('will:assignedDistributionEnd')}
                    </p>
                    <p className="Typography Typography--subTitleH">
                      {`${disHeir.distribution}% ${t(
                        'will:amountOfAssignedDistribution'
                      )}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="u-pb-10" />
          </Col>
        );
      })}
    </Row>
  );
}

export default HeirDistributionEnd;
