import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { verifyAndLogin } from 'store/actions/tokenActions';
import { updateEntireStore } from 'store/actions/userActions';
import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { Button, SiteLanguage } from 'modules/common/components';
import {
  AuthFooter,
  ClickableLogo,
  LoginFooter,
} from 'modules/auth/components';

import imgRectangle from 'images/img-auth-rectangle.png';
import imgSuccess from 'images/img_successful_verification.png';
import imgError from 'images/img_unsuccessful_verification.png';

const VerifyAndLogin = () => {
  const { t } = useTranslation(['auth']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token = '' } = useParams();

  const [isSuccessPage, setIsSuccessPage] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!!token) {
      setIsSuccessPage(true);
    } else {
      setIsSuccessPage(false);
    }
  }, []);

  React.useEffect(() => {
    if (!!token) {
      handleVerifyAndLogin();
    }
  }, [token]);

  const handleVerifyAndLogin = async () => {
    setLoading(true);
    const res: any = await dispatch(verifyAndLogin(token));
    if (res?.accessToken) {
      await dispatch(updateEntireStore());
      setLoading(false);
      navigate('/register-wizard');
    }
    setLoading(false);
    setIsSuccessPage(false);
  };

  const handleNavigate = () => {
    navigate('/login');
  };

  return (
    <>
      <Row className="lg:t-p-0 t-min-h-screen t-w-full t-m-0 t-relative t-bg-alpha-200">
        <Col
          md={{ span: 6, offset: 3 }}
          lg={{ span: 10, offset: 1 }}
          className="t-p-0 t-z-40"
        >
          <div className="t-hidden md:t-flex t-justify-between t-mt-6 lg:t-mt-10 t-px-3">
            <ClickableLogo />
            <div className="t-flex t-items-center">
              <div className="t-hidden lg:t-inline-block">
                <AuthFooter />
              </div>
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>
          </div>

          <div className="md:t-flex t-flex-col t-items-center t-mt-4 lg:t-mt-16 t-px-3 t-mb-16">
            <div className="md:t-hidden t-flex t-justify-between">
              <ClickableLogo />
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>

            <div className="card t-shadow-lg t-bg-beta-50 t-p-6 lg:t-px-12 lg:t-pt-12 t-w-full lg:t-w-120">
              <div className="t-flex t-justify-center t-items-start">
                <img
                  src={isSuccessPage ? imgSuccess : imgError}
                  alt="Email Verification Page"
                  className="t-max-w-40"
                />
              </div>
              <h2 className="typo-beta t-my-5 t-text-center">
                {isSuccessPage
                  ? t('auth:email_has_been_verified_title')
                  : t('auth:invalid_email_token_title')}
              </h2>
              {!isSuccessPage && (
                <p className="typo-epsilon t-mt-5 t-text-center">
                  {t('auth:invalid_email_token_description')}
                </p>
              )}

              <div className="t-flex t-justify-center t-mt-5">
                <Button
                  className="button-primary"
                  loading={loading}
                  onClick={handleNavigate}
                >
                  {t(loading ? 'auth:logging_in' : 'auth:go_to_login')}
                </Button>
              </div>
            </div>

            <div className="lg:t-hidden t-mt-16">
              <AuthFooter />
            </div>
          </div>

          <div className="t-absolute t-bottom-0 t-w-full t-flex t-justify-center md:t-inline">
            <LoginFooter />
          </div>
        </Col>
      </Row>
      <div className="t-fixed t-w-screen t-object-cover t-h-3/4 t-bottom-0">
        <img
          src={imgRectangle}
          className="t-w-full t-h-full"
          alt="Rectangle background"
        />
      </div>
    </>
  );
};

export default VerifyAndLogin;
