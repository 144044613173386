// TODO: discuss on more optimized method - selected fields
export const getStringifiedAssetsValues = (assets: AssetDTO[]) =>
  assets.map((asset) => {
    const copiedAsset = { ...asset } as any;
    Object.keys(copiedAsset).map((fieldKey) => {
      // no undefined or null value
      if (!copiedAsset[fieldKey]) {
        delete copiedAsset[fieldKey];
        return;
      }

      // no boolean value
      if (typeof copiedAsset[fieldKey] === 'boolean') {
        delete copiedAsset[fieldKey];
        return;
      }

      // no empty array
      if (
        Array.isArray(copiedAsset[fieldKey]) &&
        !copiedAsset[fieldKey].length
      ) {
        delete copiedAsset[fieldKey];
        return;
      }

      // remove unnecessary fields
      if (
        ['updatedAt', 'createdAt', 'assetTypeId', 'assetTypeLabel'].includes(
          fieldKey
        )
      ) {
        delete copiedAsset[fieldKey];
        return;
      }

      // get the document filenames only
      if (fieldKey === 'documents') {
        copiedAsset[fieldKey] = copiedAsset[fieldKey]
          .map((doc) => doc.documentTitle)
          .join(' ');
        return;
      }

      // search for contact name
      if (fieldKey === 'contacts') {
        copiedAsset[fieldKey] = copiedAsset[fieldKey]
          .map((contact) => `${contact.name} ${contact.surname}`)
          .join(' ');
        return;
      }
    });

    // stringify all values to a single string
    return Object.values(copiedAsset)
      .map((value) => String(value).toLowerCase())
      .join(' ');
  });
