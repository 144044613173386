import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconBulb } from 'images/icon_bulb.svg';
import check24Logo from 'images/clientCustomizations/check24Logo.png';

export default function Check24Widget() {
  const { t } = useTranslation(['plan']);

  return (
    <div className="card t-p-5 t-relative">
      <div className="t-flex t-absolute -t-top-7 t-px-2.5 t-py-2 t-bg-delta-700 t-rounded">
        <IconBulb className="t-text-delta-700" />
        <p className="typo-delta t-text-gamma-400 t-ml-2 t-truncate">
          {t('plan:check24_widget_badge')}
        </p>
      </div>
      <div className="t-flex">
        <img src={check24Logo} className="t-h-5" alt="Check24 Logo" />
        <p className="typo-epsilon t-ml-5">{t('plan:check24_widget_text')}</p>
      </div>

      <div className="t-flex t-justify-end t-mt-8">
        <a
          href="https://www.check24.de/sterbegeldversicherung/?wpset=ninebarc_sgv"
          className="button-primary t-bg-delta-700"
          target="_blank"
          rel="nofollow noreferrer noopener"
        >
          {t('plan:check24_widget_btn')}
        </a>
      </div>
    </div>
  );
}
