import { httpHelper, config } from 'modules/common/helpers';

const BASE_URL = config.beUrl;

function revokeEntireZVRDocument() {
  return httpHelper.post(`${BASE_URL}/api/zvr/revoke`, {});
}

function revokeTrustedPerson(contactId: string) {
  return httpHelper.post(`${BASE_URL}/api/zvr/revoke`, { contactId });
}

function getZvrPaymentSession() {
  return httpHelper.get(`${BASE_URL}/api/payment/zvr/checkout`, {});
}

function zvrRegistration(paymentToken: string) {
  return httpHelper.post(`${BASE_URL}/api/zvr/register`, { paymentToken });
}

function zvrAddExistingContact(contactId: string) {
  return httpHelper.post(`${BASE_URL}/api/zvr/contact`, { contactId });
}

export default {
  revokeEntireZVRDocument,
  revokeTrustedPerson,
  getZvrPaymentSession,
  zvrRegistration,
  zvrAddExistingContact,
};
