import produce from 'immer';

import initialState from 'store/reducers/initialState';
import {
  GET_ALL_KEY_HOLDERS,
  ADD_KEY_HOLDER,
  DELETE_KEY_HOLDER,
} from 'store/types/keyHolderTypes';

const keyHolderReducer = (
  state = initialState.keyHolders,
  action: ReducerAction
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_ALL_KEY_HOLDERS: {
        const {
          payload: { keyHolders },
        } = action;
        draft.value = keyHolders || [];

        return draft;
      }

      case ADD_KEY_HOLDER: {
        const {
          payload: { keyHolder },
        } = action;

        draft.value.push(keyHolder);

        return draft;
      }

      case DELETE_KEY_HOLDER: {
        const {
          payload: { id },
        } = action;
        const index = draft.value.findIndex((keyHolder) => keyHolder.id === id);

        if (index !== -1) draft.value.splice(index, 1);

        return draft;
      }
      default:
        return draft;
    }
  });

export default keyHolderReducer;
