import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  TrustedPersonsOverviewModal,
  RegisteredZVREditTrustedPersonModal,
  ZVRAddExistingContactModal,
} from 'modules/zvr/components';
import { ZVR_EDIT_STEPS } from 'modules/zvr/constants';
import { zvrAddExistingContact } from 'store/actions/zvrActions';
import { RootState } from 'store/reducers';
import { ASSET_CATEGORIES } from 'modules/estatePlan/constants';
import { ASSET_TYPE_KEYS } from 'modules/estatePlan/constants';
const {
  STEP_TRUSTED_OVERVIEW,
  STEP_EDIT_REGISTERED_TRUSTED_PERSON,
  STEP_ADD_TRUSTED_PERSON,
} = ZVR_EDIT_STEPS;

interface EditZVRProcessProps {
  onCancelProcess: () => void;
  isManageRegistration?: boolean;
}

export default function EditZVRProcess({
  onCancelProcess,
  isManageRegistration,
}: EditZVRProcessProps) {
  const dispatch = useDispatch();

  const categoryAssets = useSelector(
    (state: RootState) => state.assets[ASSET_CATEGORIES.HEALTH]
  );
  const contactsData: ContactDTO[] =
    useSelector((state: RootState) => state.contacts.list) || [];
  const zvrDocument = categoryAssets.find(
    (asset) =>
      asset.assetTypeName === ASSET_TYPE_KEYS.HEALTH_CARE_PROXY &&
      asset.zvr === 1
  );

  const addedContacts =
    zvrDocument?.contacts?.map((zvrContact) =>
      contactsData.find((contact) => contact.id === zvrContact.id)
    ) || [];

  const [step, setStep] = React.useState(STEP_TRUSTED_OVERVIEW);
  const [editContact, setEditContact] = React.useState({} as ContactDTO);

  const handleNextStep = (step: number) => {
    setStep(step);
  };

  const handleAddExistingContactModal = async (contactId: string) => {
    await dispatch(zvrAddExistingContact(contactId));
    handleNextStep(ZVR_EDIT_STEPS.STEP_TRUSTED_OVERVIEW);
  };

  const stepComponents = {
    [STEP_TRUSTED_OVERVIEW]: (
      <TrustedPersonsOverviewModal
        handleNextStep={handleNextStep}
        setEditContact={setEditContact}
        onCancelProcess={onCancelProcess}
        addedContacts={addedContacts}
        isManageRegistration={isManageRegistration}
      />
    ),
    [STEP_EDIT_REGISTERED_TRUSTED_PERSON]: (
      <RegisteredZVREditTrustedPersonModal
        handleNextStep={handleNextStep}
        editContact={editContact}
        handleAddExistingContactModal={handleAddExistingContactModal}
      />
    ),
    [STEP_ADD_TRUSTED_PERSON]: (
      <ZVRAddExistingContactModal
        handleAddExistingContactModal={handleAddExistingContactModal}
        handleNextStep={handleNextStep}
        setEditContact={setEditContact}
        addedContacts={addedContacts}
      />
    ),
  };

  return <>{stepComponents[step]}</>;
}
