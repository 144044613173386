import { groupBy } from 'lodash';

import { getAssetName, getAssetTypeProvides } from 'modules/asset/helpers';
import { getFullAddress } from 'modules/common/helpers';

export function sortByLastAdded(assets: AssetDTO[]) {
  const copyAssets = [...assets];
  return copyAssets.sort((item1, item2) => {
    const date1 = new Date(item1.createdAt || '');
    const date2 = new Date(item2.createdAt || '');
    return date2.getTime() - date1.getTime();
  });
}

export function sortByCategory(assets: AssetDTO[]) {
  const copyAssets = [...assets];
  return Object.values(
    groupBy(copyAssets, (asset) => asset.assetTypeId)
  ).flat();
}

export function sortByLastModified(assets: AssetDTO[]) {
  const copyAssets = [...assets];
  return copyAssets.sort((item1, item2) => {
    const date1 = new Date(item1.updatedAt || '');
    const date2 = new Date(item2.updatedAt || '');
    return date2.getTime() - date1.getTime();
  });
}

export function sortByAlphabeticOrder(
  assets: AssetDTO[],
  categorizedAssetTypes: AssetTypeDTO[],
  allAvailableProviders: ProvidersDTO,
  countries: SelectOption[],
  t: (key: string) => string
) {
  const copiedAssets = [...assets];
  const formattedAssets = copiedAssets.map((asset) => {
    const copiedAsset = {
      ...asset,
      address: asset?.address
        ? getFullAddress(asset?.address, countries)
        : undefined,
    };
    const providers = getAssetTypeProvides(
      allAvailableProviders,
      asset,
      categorizedAssetTypes
    );
    const assetType: AssetTypeDTO =
      categorizedAssetTypes?.find(
        (type) => type.id === copiedAsset.assetTypeId
      ) || ({} as AssetTypeDTO);

    const assetName = getAssetName(assetType, copiedAsset, providers, t);
    return { ...copiedAsset, assetName };
  });

  return formattedAssets.sort((item1, item2) =>
    item1.assetName.toLowerCase().localeCompare(item2.assetName.toLowerCase())
  );
}
