import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { Modal, SiteLanguage } from 'modules/common/components';
import { Row, Col } from 'modules/bootstrap/components';
import { ContactUsForm } from 'modules/contactUs/components';
import { ClickableLogo } from 'modules/auth/components';

import flower from 'images/flower.jpg';
import authBg from 'images/two-column-page-bg-1.png';

export default function VerifyEmail() {
  const { t } = useTranslation(['common', 'auth']);
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user.current) || {};
  const { email, verified } = user;
  const [contactUsModalOpen, isContactUsModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (verified) navigate('/');
  }, [verified]);

  const handleContactUsModalClose = () => {
    isContactUsModalOpen(false);
  };

  const handleContactUsModalOpen = () => {
    isContactUsModalOpen(true);
  };

  return (
    <>
      {contactUsModalOpen && (
        <Modal show onHide={handleContactUsModalClose}>
          <h1 className="typo-alpha t-pb-6">{t('help:title')}</h1>
          <ContactUsForm onSuccess={handleContactUsModalClose} />
        </Modal>
      )}
      <img
        src={authBg}
        className="Decoration Decoration--authentication"
        alt="Many green leaves"
      />
      <main className="t-bg-gamma-400 t-min-h-screen t-p-3 lg:t-p-0">
        <Row className="t-p-3 lg:t-p-0 t-w-full">
          <Col
            lg={{ span: 6, offset: 6 }}
            xl={{ span: 5, offset: 6 }}
            md={{ span: 8, offset: 2 }}
          >
            <div className="Form t-pb-12 t-pt-5 md:t-py-12 md:t-px-16">
              <div className="Form-logo">
                <ClickableLogo />

                <SiteLanguage additionalClass="u-pb-0" />
              </div>
              <div className="t-text-center">
                <h1 className="Typography Typography--title u-pb-30 u-weight-600">
                  {t('auth:final_title')}
                </h1>
                <p className="Typography Typography--subTitle">
                  {t('auth:final_description', { email })}
                </p>
                <div className="d-flex justify-content-center u-pt-50 t-pb-5">
                  <img src={flower} alt="Small sprout in the pot" />
                </div>

                <p className="typo-epsilon">{t('auth:final_description2')}</p>
                <p className="t-pb-7">
                  <button
                    onClick={handleContactUsModalOpen}
                    className="typo-epsilon t-no-underline t-text-delta-700"
                  >
                    {t('auth:final_help')}
                  </button>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </main>
    </>
  );
}
