import {
  getStepsAfter,
  getStepsArrayFromStore,
} from 'modules/assistant/helpers/questionnaireHelper';
import initialState from 'store/reducers/initialState';
import helper from 'store/reducers/reducerHelper';

import {
  POPULATE_QUESTIONNAIRE,
  POPULATE_QUESTIONNAIRE_FROM_BACKEND,
  UPDATE_QUESTIONNAIRE,
  NAVIGATE_TO_STEP,
  ERASE_ANSWERS_AFTER_STEP,
} from 'store/types/questionnaireActionTypes';
import {
  QUESTIONNAIRE_KEYS,
  STEP_COMPONENT_TYPE,
  getStepKeys,
} from 'modules/assistant/constants/questionnaires';

const questionnaireReducer = (state = initialState.questionnaires, action) => {
  interface UQ {
    questionnaire: QUESTIONNAIRE_KEYS;
    step: string;
    answers: SelectedAnswer[] | string;
    linksTo: string;
    stepType: STEP_COMPONENT_TYPE;
  }

  const getAnswerValue = (
    answer: SelectedAnswer[] | SelectedAnswer | string,
    stepType: STEP_COMPONENT_TYPE
  ) => {
    const getSingleChoiceAnswer = () =>
      (answer as SelectedAnswer).id || (answer as string);
    const getMultipleChoiceAnswer = () =>
      (answer as SelectedAnswer[]).map((item) => item.id);

    switch (stepType) {
      case STEP_COMPONENT_TYPE.MULTIPLE:
        return getMultipleChoiceAnswer();
      case STEP_COMPONENT_TYPE.SINGLE:
        return getSingleChoiceAnswer();
      case STEP_COMPONENT_TYPE.SINGLEANDMULTIPLE:
        if (Array.isArray(answer)) {
          return getMultipleChoiceAnswer();
        }
        return getSingleChoiceAnswer();
      case STEP_COMPONENT_TYPE.FORM:
      case STEP_COMPONENT_TYPE.EXPIRATION_DATE:
      case STEP_COMPONENT_TYPE.FREE_TEXT:
      case STEP_COMPONENT_TYPE.CONTACT:
      case STEP_COMPONENT_TYPE.SUBSTITUTE_HEIR:
      case STEP_COMPONENT_TYPE.HEIR_CONTACTS:
      case STEP_COMPONENT_TYPE.BEQUEST:
      case STEP_COMPONENT_TYPE.INHERITANCE_DISTRIBUTION:
      case STEP_COMPONENT_TYPE.PARTNER_PROFILE:
      case STEP_COMPONENT_TYPE.CONTENT:
      case STEP_COMPONENT_TYPE.MULTI_CONTACTS:
      case STEP_COMPONENT_TYPE.ADD_INFORMATION:
      case STEP_COMPONENT_TYPE.BANK_POA_CONTACT:
        return answer;
      default:
        return null;
    }
  };

  return helper.handleActions(state, action, {
    [UPDATE_QUESTIONNAIRE](state, payload: UQ) {
      const { questionnaire, step, answers, linksTo, stepType } = payload;
      const answerVal = getAnswerValue(answers, stepType);
      state[questionnaire] = {
        ...state[questionnaire],
        currStepId: linksTo,
        [step]: answerVal,
      };
    },

    [POPULATE_QUESTIONNAIRE](state, payload) {
      const { questionnaire } = payload;
      const STEP_KEYS = getStepKeys(questionnaire);
      state[questionnaire] = {
        ...state[questionnaire],
        currStepId: STEP_KEYS?.STEP_START,
      };
    },

    [POPULATE_QUESTIONNAIRE_FROM_BACKEND](state, payload) {
      const { questionnaire, userQuestionnaireData } = payload;
      state[questionnaire] = {
        ...state[questionnaire],
        ...userQuestionnaireData,
      };
    },

    [NAVIGATE_TO_STEP](state, payload) {
      const { questionnaire, stepId } = payload;
      state[questionnaire] = { ...state[questionnaire], currStepId: stepId };
    },

    [ERASE_ANSWERS_AFTER_STEP](state, payload) {
      const { questionnaire, stepId } = payload;
      const stepsCollection = getStepsArrayFromStore(state[questionnaire]);
      const stepsAfter = getStepsAfter(stepId, stepsCollection) as string[];

      Object.keys(state[questionnaire]).forEach((key) => {
        if (stepsAfter.includes(key)) {
          delete state[questionnaire][key];
        }
      });
    },
  });
};

export default questionnaireReducer;
