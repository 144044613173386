import React from 'react';
import { find, isEmpty } from 'lodash';

import {
  isJsonString,
  useGetCountries,
  useTranslation,
} from 'modules/common/helpers';

import {
  CARD_FIELD_INPUTS as CFI,
  PERSON_FIELD_INPUTS as PFI,
} from 'modules/common/constants/enums';

import { ReactComponent as IconEye } from 'images/eye.svg';

interface AssetViewModalCombinedFieldPros {
  stringifiedField?: string;
  label: string;
}

const AssetViewModalCombinedField = ({
  stringifiedField = '',
  label,
}: AssetViewModalCombinedFieldPros) => {
  const { t } = useTranslation(['common']);
  const countries = useGetCountries();

  const [viewHiddenInputs, setViewHiddenInputs] = React.useState({
    cardNumber: false,
    code: false,
  });

  const parsedField =
    stringifiedField && isJsonString(stringifiedField)
      ? JSON.parse(stringifiedField)
      : {};

  if (isEmpty(parsedField)) return <></>;

  const existingInputs = Object.fromEntries(
    Object.entries(parsedField).filter(([, value]) => !!value)
  );

  const keys = Object.keys(existingInputs);

  const getExtraClassName = (key: string) => {
    switch (key) {
      case PFI.PERSON_CITY:
      case PFI.FIRST_NAME:
        return 't-w-full xl:t-w-1/2 t-inline-block xl:t-pr-5';
      case PFI.PERSON_ZIP:
      case PFI.LAST_NAME:
        return 't-w-full xl:t-w-1/2 t-inline-block';
      case CFI.CARD_EXPIRATION_DATE:
        return 't-w-8/12 t-inline-block t-pr-3';
      case CFI.CODE:
        return 't-w-4/12 t-inline-block';
      default:
        return '';
    }
  };

  const getFieldValue = (key: string) => {
    switch (key) {
      case PFI.PERSON_COUNTRY:
        return (
          find(countries, (item) => item.value === parsedField[key])?.label ||
          parsedField[key]
        );
      case CFI.CARD_NUMBER:
        return (
          <span>
            {viewHiddenInputs[key]
              ? parsedField[key]
              : parsedField[key].replace(/\d(?=.{4})/g, '*')}
          </span>
        );
      case CFI.CARD_NUMBER_SHORT:
        return `**** **** **** ${parsedField[key]}`;
      case CFI.CODE:
        return (
          <span>
            {viewHiddenInputs[key]
              ? parsedField[key]
              : parsedField[key].replace(/\d|_/g, '*')}
          </span>
        );
      default:
        return parsedField[key];
    }
  };

  const onViewHiddenField = (inputType) => {
    const hiddenFields = { ...viewHiddenInputs };
    hiddenFields[inputType] = !hiddenFields[inputType];
    setViewHiddenInputs(hiddenFields);
  };

  return (
    <div className="t-border t-border-solid t-border-beta-200 t-rounded t-relative t-pt-1.5 t-px-2 t-mt-2 t-mb-3">
      <p className="Form-label t-absolute t--top-3 t-left-5 t-px-2 t-bg-gamma-400">
        {t(label)}
      </p>

      {keys.map((key, idx) => {
        return (
          <div
            key={`${key}_${idx}`}
            className={`t-mb-4 ${getExtraClassName(key)}`}
          >
            <p className={`typo-zeta t-pb-2 ${idx === 0 ? 't-mt-3' : ''}`}>
              {t(`common:${key}_label`)}
            </p>
            <p className="typo-delta t-p-2 t-bg-beta-100 t-rounded-sm t-flex t-justify-between">
              <span>{getFieldValue(key)}</span>
              {([CFI.CARD_NUMBER, CFI.CODE] as string[]).includes(key) && (
                <button type="button" onClick={() => onViewHiddenField(key)}>
                  <IconEye
                    className={
                      viewHiddenInputs[key]
                        ? 't-text-alpha-600'
                        : 't-text-beta-200'
                    }
                  />
                </button>
              )}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default AssetViewModalCombinedField;
