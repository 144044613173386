import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { AssistantCTAHeader } from 'modules/assistant/components/Assistant';
import {
  QUESTIONNAIRE_PAGES,
  QUESTIONNAIRE_KEYS,
} from 'modules/assistant/constants/questionnaires';

import iconBenefit1 from 'images/icon-assistant-cta-page-1.svg';
import iconBenefit2 from 'images/icon-assistant-cta-page-2.svg';
import iconBenefit3 from 'images/icon-assistant-cta-page-3.svg';

const benefitsOfCompleting = [
  {
    id: 'benefit-1',
    img: iconBenefit1,
    description: 'assistant:cta_page_benefit_1',
  },
  {
    id: 'benefit-2',
    img: iconBenefit2,
    description: 'assistant:cta_page_benefit_2',
  },
  {
    id: 'benefit-3',
    img: iconBenefit3,
    description: 'assistant:cta_page_benefit_3',
  },
];

const AssistantCTAPage = () => {
  const { t } = useTranslation(['assistant']);

  return (
    <Row className="t-h-100">
      <Col
        md={{ span: 12 }}
        xl={{ span: 10, offset: 2 }}
        style={{ paddingRight: 0 }}
      >
        <AssistantCTAHeader />
      </Col>
      <Col
        md={{ span: 12 }}
        xl={{ span: 10, offset: 2 }}
        style={{ paddingRight: 0 }}
      >
        <div className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7">
          <h1 className="typo-alpha t-mb-3 t-text-center">
            {t('assistant:cta_page_benefit_title')}
          </h1>
          <div className="t-flex t-flex-col t-items-center xl:t-flex-row xl:t-justify-around t-mt-7">
            {benefitsOfCompleting.map((benefit, index) => (
              <div
                key={`${benefit.id}_${index}`}
                className="xl:t-max-w-48 t-flex t-flex-col t-items-center t-text-center t-mx-6 t-mb-6 xl:t-mt-0"
              >
                <div className="t-h-28">
                  <img src={benefit.img} alt={benefit.id} />
                </div>
                <p className="typo-gamma t-mt-4">{t(benefit.description)}</p>
              </div>
            ))}
          </div>
          <div className="t-hidden xl:t-flex t-justify-center t-mt-9">
            <Link
              to={
                QUESTIONNAIRE_PAGES[QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT].path
              }
              className="button-primary md:t-whitespace-nowrap t-w-full xl:t-w-auto"
            >
              {t('assistant:cta_page_header_button')}
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AssistantCTAPage;
