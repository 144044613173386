import React from 'react';
import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

interface FamilyAccountDisclaimerModalProps {
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

const FamilyAccountDisclaimerModal = ({
  onClose,
  onConfirm,
}: FamilyAccountDisclaimerModalProps) => {
  const { t } = useTranslation(['common', 'familyAccounts']);
  const [loading, setLoading] = React.useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm();
  };

  return (
    <Modal show>
      <h1 className="typo-alpha">
        {t('familyAccounts:disclaimer_modal_title')}
      </h1>
      <p className="typo-epsilon t-mt-4 t-mb-8">
        {t('familyAccounts:disclaimer_modal_desc')}
      </p>

      <div className="t-flex t-justify-end t-items-end t-mt-8 t-flex-col sm:t-flex-row">
        <Button
          category="secondary"
          className="t-mr-0 t-py-2 t-mb-3 sm:t-mb-0 sm:t-mr-3"
          onClick={onClose}
          disabled={loading}
        >
          {t('common:previous')}
        </Button>

        <Button className="t-py-2" loading={loading} onClick={handleConfirm}>
          {t('common:continue')}
        </Button>
      </div>
    </Modal>
  );
};

export default FamilyAccountDisclaimerModal;
