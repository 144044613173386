import React from 'react';

import { FormRegister } from 'modules/common/types';

interface RadioCardProps {
  register: FormRegister;
  children: React.ReactNode | React.ReactNode[];
  name: string;
  value: string;
  id: string;
  className?: string;
  classNameRadioBtn?: string;
  noFlex?: boolean;
  disabled?: boolean;
  dataTip?: string;
  dataTipDisable?: boolean;
  dataFor?: string;
  withBorder?: boolean;
}

export default function RadioCard({
  register,
  children,
  name,
  value,
  id,
  disabled,
  dataTip,
  dataTipDisable,
  dataFor,
  className = '',
  classNameRadioBtn = '',
  noFlex = false,
  withBorder = false,
}: RadioCardProps) {
  return (
    <label
      className={`${
        noFlex ? '' : 'Radiobutton t-flex'
      } card t-p-4 t-select-none ${className} ${
        withBorder ? 't-border t-border-solid t-border-delta-500' : ''
      }`}
      tabIndex={0}
      role="radio"
      aria-checked="false"
      aria-labelledby="radioLabel"
      htmlFor={id}
      data-tip={dataTip}
      data-tip-disable={dataTipDisable}
      data-for={dataFor}
    >
      <input
        {...register(name)}
        id={id}
        value={value}
        name={name}
        type="radio"
        className="jsCeckboxSource Radiobutton-source"
        disabled={disabled}
      />
      <span className={`Radiobutton-result t-mr-8 ${classNameRadioBtn}`} />
      <div id="radioLabel" className="t-w-full">
        {children}
      </div>
    </label>
  );
}
