import config from 'modules/common/helpers/configHelper';
import httpHelper from 'modules/common/helpers/httpHelper';

const BASE_URL = config.beUrl;

function getNotifications() {
  return httpHelper.get(`${BASE_URL}/api/notifications`, {});
}

function editNotification(notification) {
  return httpHelper.put(`${BASE_URL}/api/notification`, { ...notification });
}

function markAllAsRead() {
  return httpHelper.put(`${BASE_URL}/api/notifications`, {});
}

function deleteNotification(id: string) {
  return httpHelper.delete(`${BASE_URL}/api/notification`, { id });
}

export default {
  getNotifications,
  deleteNotification,
  editNotification,
  markAllAsRead,
};
