import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { RootState } from 'store/reducers';
import { setUserLanguage } from 'store/actions/userActions';
import { useTranslation } from 'modules/common/helpers';

import { LOCAL_STORAGE_VARS, LANGUAGES } from 'modules/common/constants/enums';

import en from 'images/en.svg';
import de from 'images/de.svg';

SiteLanguage.propTypes = {
  additionalClass: PropTypes.string,
};

SiteLanguage.defaultProps = {
  additionalClass: '',
};

function SiteLanguage(props) {
  const { i18n } = useTranslation();
  const { t } = useTranslation(['auth', 'common']);
  const dispatch = useDispatch();

  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const isAuthenticated = (): boolean => !isEmpty(user);

  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    const langValue = localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE);

    if (
      langValue &&
      langValue !== lang &&
      [LANGUAGES.DE as string, LANGUAGES.EN as string].includes(langValue)
    ) {
      setLang(langValue);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const changeLang = async (lng: LANGUAGES) => {
    localStorage.setItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE, lng);
    setLang(lng);

    if (isAuthenticated() && !user.beneficiary)
      await dispatch(setUserLanguage(lng));
  };

  return (
    <ul className={`SiteLanguage ${props.additionalClass}`}>
      <li className="SiteLanguage-item">
        <button
          onClick={() => changeLang(LANGUAGES.EN)}
          className={`SiteLanguage-button ${
            lang === LANGUAGES.EN && 'isActive'
          }`}
          type="button"
        >
          <img
            className="SiteLanguage-image"
            src={en}
            alt={t('common:alt_set_english')}
          />
        </button>
      </li>

      <li className="SiteLanguage-item">
        <button
          onClick={() => changeLang(LANGUAGES.DE)}
          className={`SiteLanguage-button ${
            lang === LANGUAGES.DE && 'isActive'
          }`}
          type="button"
        >
          <img
            className="SiteLanguage-image"
            src={de}
            alt={t('common:alt_set_german')}
          />
        </button>
      </li>
    </ul>
  );
}

export default SiteLanguage;
