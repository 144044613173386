import React from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { find } from 'lodash';

import { RootState } from 'store/reducers';
import {
  useGetCountries,
  config,
  useTranslation,
} from 'modules/common/helpers';

function EpTransmortalContract() {
  const { t } = useTranslation(['transmortalAuth']);
  const countries = useGetCountries();

  const userData: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const { name, surname, birthday, placeOfBirth, address, zip, city } =
    userData;
  const birthdayFormated = birthday
    ? format(parseISO(birthday), config.format.uiDate)
    : '';
  const country =
    find(countries, (item) => item.value === userData.country)?.label || '';

  return (
    <div className="t-pr-5">
      <h2 className="typo-alpha t-pb-6">
        {t('transmortalAuth:transmortal_auth_header')}
      </h2>
      <p className="typo-epsilon t-pb-2.5">
        {t('transmortalAuth:transmortal_description1', {
          name,
          surname,
          birthdayFormated,
          placeOfBirth,
          address,
          zip,
          city,
          country,
        })}
      </p>
      <p className="typo-epsilon t-pb-2.5">
        {t('transmortalAuth:transmortal_description2')}
      </p>
      <p className="typo-epsilon t-pb-2.5">
        {t('transmortalAuth:transmortal_description3')}
      </p>
      <p className="typo-epsilon t-pb-2.5">
        {t('transmortalAuth:transmortal_description4')}
      </p>
    </div>
  );
}

export default EpTransmortalContract;
