import React from 'react';
import { useDispatch } from 'react-redux';

import { deleteFamilyAccount } from 'store/actions/familyAccountActions';
import { useTranslation, validationHelper } from 'modules/common/helpers';

import { Button, Modal, TextInputRef } from 'modules/common/components';

import imgWarning from 'images/img-deletion-warning.png';
import { useForm } from 'react-hook-form';
import { ERROR_CODES } from 'modules/common/constants';
import { deleteContact } from 'store/actions/contactActions';

interface RemoveFamilyContactConfirmModalProps {
  id: string;
  onClose: () => void;
  isContactDeleting?: boolean;
}

const RemoveFamilyContactConfirmModal = ({
  id,
  onClose,
  isContactDeleting,
}: RemoveFamilyContactConfirmModalProps) => {
  const { t } = useTranslation(['plan']);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const [loading, setLoading] = React.useState(false);
  const isMounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onDelete = async (formData) => {
    if (!id) return;
    setLoading(true);
    let response;
    if (isContactDeleting) {
      response = await dispatch(
        deleteContact(id, formData.code, {
          returnError: true,
        })
      );
    } else {
      response = await dispatch(
        deleteFamilyAccount(id, formData.code, {
          returnError: true,
        })
      );
    }
    if (isMounted.current) {
      setLoading(false);
      if (response?.errorCode === ERROR_CODES.INVALID_TOKEN) {
        setError('code', { type: 'invalidToken' });
      } else {
        onClose();
      }
    }
  };

  const error =
    errors['code']?.type === 'invalidToken'
      ? t('common:invalid_code')
      : errors['code']?.type === 'validate'
      ? `${t('auth:confirmation_code')} ${t('common:invalid')}`
      : errors['code']
      ? `${t('auth:confirmation_code')} ${t('common:required')}`
      : '';

  return (
    <Modal show onHide={onClose}>
      <div className="t-flex t-justify-center">
        <img
          src={imgWarning}
          className="t-max-h-36"
          alt="Deleting with caution"
        />
      </div>

      <h1 className="typo-alpha t-mt-6">
        {isContactDeleting
          ? t('familyAccounts:family_contact_deletion_modal_title')
          : t('familyAccounts:account_deletion_modal_title')}
      </h1>
      <p className="typo-epsilon t-mt-4 t-mb-8">
        {isContactDeleting
          ? t('familyAccounts:family_contact_deletion_modal_desc')
          : t('familyAccounts:account_deletion_modal_desc')}
      </p>

      <form onSubmit={handleSubmit(onDelete)}>
        <p className="typo-epsilon t-mt-4">
          {t('mcontacts:confirmation_code_modal_description')}
        </p>

        <fieldset disabled={loading}>
          <div className="t-mt-6 t-flex t-items-center">
            <TextInputRef
              inputClass="t-w-1/2"
              {...register('code', {
                required: true,
                validate: (value) => validationHelper.validateCode(4, value),
              })}
              label={t('auth:confirmation_code')}
              type="tel"
              placeholder="XXXX"
              maxLength={4}
              autoComplete={false}
              error={error}
            />
          </div>
        </fieldset>

        <div className="t-flex t-justify-end t-items-end t-mt-8 t-flex-col sm:t-flex-row">
          <Button
            category="secondary"
            className="t-mr-0 t-py-2 t-mb-3 sm:t-mb-0 sm:t-mr-3"
            onClick={onClose}
            disabled={loading}
          >
            {t('common:cancel')}
          </Button>

          <Button
            type="submit"
            category="dangerous"
            className="t-py-2"
            loading={loading}
          >
            {t('familyAccounts:confirm_removal')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default RemoveFamilyContactConfirmModal;
