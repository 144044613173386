import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { RootState } from 'store/reducers';
import { logOut } from 'store/actions/userActions';
import { setCurrentPathname } from 'store/actions/commonActions';
import { authService } from 'modules/auth/services';
import { useTranslation } from 'modules/common/helpers';

import { Modal } from 'modules/common/components';

interface LogoutWarningModalProps {
  onClose: () => void;
}

const LogoutWarningModal = ({ onClose }: LogoutWarningModalProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation(['auth']);
  const [seconds, setSeconds] = useState(59);
  const isBeneficiary: boolean = useSelector(
    (state: RootState) => state.user.current.beneficiary
  );

  const handleLogOut = async () => {
    await dispatch(logOut());

    if (isBeneficiary) navigate('/access');
    else {
      dispatch(setCurrentPathname(location.pathname));
      navigate('/login');
    }
  };

  useEffect(() => {
    let isMounted = true;
    const refreshToken = authService.getRefreshToken();
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        if (seconds > 3 && !refreshToken) {
          handleLogOut();
          onClose();
        }
        if (isMounted) {
          setSeconds(seconds - 1);
        }
      } else {
        handleLogOut();
        onClose();
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
      isMounted = false;
    };
  }, [seconds]);

  return (
    <Modal show withBackdrop dataTestid="LogoutWarningModal">
      <h1 className="Typography Typography--title u-weight-600 u-pb-15">
        {t('auth:logout_modal_title')}
      </h1>
      <h2 className="Typography Typography--title u-weight-600 u-pb-15">
        {seconds}
      </h2>
      <p className="Typography Typography--subTitle">
        {t('auth:logout_modal_text')}
      </p>
    </Modal>
  );
};

export default LogoutWarningModal;
