import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import { useTranslation } from 'modules/common/helpers';

import { AssetCard } from 'modules/asset/components';

import imgNoResults from 'images/img_no_result.png';

interface SearchResultPageProps {
  searchedResult: AssetsDTO | undefined;
}

export default function SearchResultPage({
  searchedResult,
}: SearchResultPageProps) {
  const { t } = useTranslation(['plan']);

  const resultsFound = searchedResult && !isEmpty(searchedResult);

  return (
    <>
      <h2 className="t-font-secondary t-font-semibold t-text-xl t-pb-3">
        {t('plan:search_result_page_title')}
      </h2>
      <p className="t-mb-6">
        {t(
          resultsFound
            ? 'plan:search_result_page_description'
            : 'plan:no_search_result_page_description'
        )}
      </p>

      {!resultsFound && (
        <div className="t-flex t-justify-center t-mt-5">
          <img src={imgNoResults} alt="No results found" />
        </div>
      )}

      {resultsFound &&
        Object.keys(searchedResult).map((assetCategory: string) => {
          const categorizedAssets = searchedResult[assetCategory];
          return (
            <div className="t-mt-5" key={assetCategory}>
              <h2 className="typo-beta t-mb-3">
                {t(`plan:step_${assetCategory}`)}
              </h2>
              <Row>
                {categorizedAssets.map((asset) => (
                  <Col
                    key={asset.id}
                    xl={{ span: 6 }}
                    className="t-pb-5"
                    id={asset.id}
                  >
                    <AssetCard asset={asset} assetCategory={assetCategory} />
                  </Col>
                ))}
              </Row>
            </div>
          );
        })}
    </>
  );
}
