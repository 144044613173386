import styled, { css } from 'styled-components';

const boldStyle = css`
  font-weight: 700;
`;

const semiBoldStyle = css`
  font-weight: var(--font-weight-semibold);
`;

const lightStyle = css`
  font-weight: 300;
`;

const commonStyle = css`
  margin-bottom: ${({ mb }) => mb || '10px'};
  margin-top: ${({ mt }) => mt || '0'};
  margin-right: ${({ mr }) => mr || '0'};
  margin-left: ${({ ml }) => ml || '0'};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-align: ${({ align }) => align || 'inherit'};
  ${(props) => props.semibold && semiBoldStyle};
  ${(props) => props.bold && boldStyle};
  ${(props) => props.light && lightStyle};
`;

const P = styled.p`
  ${commonStyle}
  color: ${({ color }) => color || 'inherit'};
  font-family: ${({ fontFamily }) =>
    fontFamily || 'var(--font-family-secondary)'};
  line-height: var(--line-height-lg);
  font-size: ${({ small, fontSize }) =>
    small ? 'var(--font-size-md)' : fontSize || 'var(--font-size-lg)'};
`;

const H1 = styled.h1`
  ${commonStyle}
  ${semiBoldStyle}
  font-family: ${({ fontFamily }) =>
    fontFamily || 'var(--font-family-secondary)'};
  line-height: var(--line-height-xl);
  font-size: ${({ fontSize }) => fontSize || 'var(--font-size-xl)'};
`;

const H2 = styled.h2`
  ${commonStyle}
  ${semiBoldStyle}
  line-height: var(--line-height-lg);
  font-family: ${({ fontFamily }) =>
    fontFamily || 'var(--font-family-secondary)'};
  font-size: ${({ fontSize }) => fontSize || 'var(--font-size-lg)'};
`;

const StyledAnswerText = styled.p`
  font-family: var(--font-family-primary);
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
`;

export { P, H1, H2, StyledAnswerText };
