export const MODAL_TYPES = {
  REDIRECT: 'redirect',
  CONTACT_NOTICE: 'contactNotice',
  UPLOAD_WARNING: 'uploadWarning',
  CANCEL: 'cancelForm',
};

export const getAssetFormGlobalModalProps = (
  type: string,
  t: (key: string) => string,
  isAllSelectedBeneContacted?: boolean
) => {
  let title,
    description,
    primaryButtonText,
    secondaryButtonText = '';
  switch (type) {
    case MODAL_TYPES.CANCEL:
    case MODAL_TYPES.REDIRECT:
      title = t('plan:redirection_modal_warning');
      primaryButtonText = t('common:continue');
      break;
    case MODAL_TYPES.CONTACT_NOTICE:
      title = t(
        `plan:${
          isAllSelectedBeneContacted
            ? 'bene_noticed_already_modal_title'
            : 'bene_will_notice_modal_title'
        }`
      );
      description = t(
        `plan:${
          isAllSelectedBeneContacted
            ? 'bene_noticed_already_modal_description'
            : 'bene_will_notice_modal_description'
        }`
      );
      primaryButtonText = t('common:yes_cap');
      break;
    default:
      title = t('plan:upload_warning_modal_title');
      description = [
        t('plan:upload_warning_modal_description_1'),
        t('plan:upload_warning_modal_description_2'),
      ];
      primaryButtonText = t('plan:upload_warning_modal_upload_btn');
      secondaryButtonText = t('plan:upload_warning_modal_save_anyway_btn');
  }
  return { title, description, primaryButtonText, secondaryButtonText };
};
