import React, { useEffect, useState } from 'react';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import { sortBy } from 'lodash';
import { RootState } from 'store/reducers';
import { useDispatch, useSelector } from 'react-redux';

import { getProvidersByType } from 'store/actions/providerActions';
import { useTranslation } from 'modules/common/helpers';
import { FormRegister, FormSetValue, FormWatch } from 'modules/common/types';

import { SelectController, TextInputRef } from 'modules/common/components';

import {
  PROVIDER_CONSTANT_TYPES,
  OTHER_PROVIDERS,
} from 'modules/estatePlan/constants/enums';
import { FIELD_TYPE_KEYS as F } from 'modules/estatePlan/constants/fieldLibrary';

interface ProviderSelectProps extends Partial<ControllerProps<any>> {
  type: PROVIDER_CONSTANT_TYPES;
  name: string;
  label: string;
  register: FormRegister;
  watch: FormWatch;
  error: string;
  setValue?: FormSetValue;
  defaultValue?: string;
}

const makeOptions = (providers: ProviderDTO[]) => {
  if (providers.length === 0) return [];

  const formattedProviders = providers.map((provider) => ({
    label: provider.value,
    value: provider.key,
  }));

  // move the "other" option to the end of the list
  return sortBy(formattedProviders, ({ value }) =>
    value === OTHER_PROVIDERS.OTHER ? 1 : 0
  );
};

const ProviderSelect: React.FC<ProviderSelectProps> = ({
  type,
  defaultValue,
  label,
  error,
  control,
  name,
  setValue,
  watch,
  register,
  ...props
}) => {
  const { t } = useTranslation(['common', 'plan']);
  const dispatch = useDispatch();

  const allFetchedProviders: ProvidersDTO = useSelector(
    (state: RootState) => state.providers.current
  );
  const providers: ProviderDTO[] = allFetchedProviders[type] || [];

  const [formattedDefaultValue, setFormattedDefaultValue] = useState<
    string | SelectOption | undefined
  >(defaultValue);
  const [otherProviderDefaultValue, setOtherProviderDefaultValue] =
    useState('');
  const [isOtherProvider, setIsOtherProvider] = useState(false);
  const [options, setOptions] = useState<null | SelectOption[]>(null);

  useEffect(() => {
    if (!providers.length) {
      dispatch(getProvidersByType(type));
    }
  }, []);

  useEffect(() => {
    if (!!providers.length) {
      setOptions(makeOptions(providers));
    }
  }, [providers]);

  useEffect(() => {
    if (options) {
      let option = options.find(({ value }) => value === defaultValue);
      const isDefaultValueTakenFromOtherProvider = defaultValue && !option;

      if (isDefaultValueTakenFromOtherProvider) {
        option = options.find(({ value }) => value === OTHER_PROVIDERS.OTHER);
        setOtherProviderDefaultValue(defaultValue || '');
      }

      const formattedValue = option || defaultValue;
      setFormattedDefaultValue(formattedValue);
      if (setValue) {
        setValue(F.PROVIDER, formattedValue);
        setIsOtherProvider(
          (formattedValue as SelectOption)?.value === OTHER_PROVIDERS.OTHER
        );
      }
    }
  }, [defaultValue, options]);

  useEffect(() => {
    setIsOtherProvider(watch(name)?.value === OTHER_PROVIDERS.OTHER);
  }, [watch(name)?.value]);

  if (Array.isArray(options) && options.length > 0) {
    return (
      <>
        <div className="Form-group">
          <label
            className={`Form-label ${error ? 'isErrored' : ''}`}
            htmlFor="provider"
          >
            {label}
          </label>

          <SelectController
            id="provider"
            name={name}
            options={options}
            control={control}
            className={`Select ${error ? 'isErrored' : ''}`}
            rules={{ required: true }}
            defaultValue={formattedDefaultValue as SelectOption}
            {...props}
          />
          {error && (
            <div className="Form-alert">
              <span className="text-main-sm t-text-epsilon-600">{`${label} ${t(
                'common:required'
              )}`}</span>
            </div>
          )}
        </div>

        {isOtherProvider && (
          <div className="Form-group">
            <TextInputRef
              {...register('otherProvider', { required: true })}
              label={t('plan:provider_other_label')}
              type="text"
              autoComplete={false}
              error={error}
              defaultValue={otherProviderDefaultValue}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <TextInputRef
      {...register(name, { required: true })}
      name={name}
      label={label}
      type="text"
      error={error}
      autoComplete={false}
      defaultValue={formattedDefaultValue as string}
    />
  );
};

export default ProviderSelect;
