import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import imgSuccess from 'images/img-key-holder-page-success.png';

const IncidentReportSuccess = () => {
  const { t } = useTranslation(['afterDeath']);
  return (
    <div
      role="alert"
      aria-live="polite"
      className="card t-shadow-lg t-bg-beta-50 t-p-6 lg:t-px-12 lg:t-pt-12 t-w-full lg:t-w-120"
    >
      <div className="t-flex t-justify-center t-items-start">
        <img
          src={imgSuccess}
          alt="accepted security key"
          className="t-max-w-40"
        />
      </div>
      <h2 className="typo-beta t-text-center t-my-5">
        {t('afterDeath:report_incident_success_header')}
      </h2>
      <p className="typo-epsilon t-text-center t-text-beta-500 t-mb-5">
        {t('afterDeath:report_incident_success_description')}
      </p>
    </div>
  );
};

export default IncidentReportSuccess;
