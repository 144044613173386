import icons from 'modules/assistant/constants/questionnaires/icons';

import { STEP_COMPONENT_TYPE as C } from 'modules/assistant/constants/questionnaires';

export enum STEP_KEYS {
  // ids of steps (questions)
  // NOTE: we should probably make the below keys as descriptive as we did for the answers
  // because we will store "keyStep: keyAnswer" in the backend
  STEP_START = 'maritalStatus',
  STEP_2 = 'childrenSituation',
  STEP_3 = 'livingSituation',
  STEP_4 = 'advisorsAttorneys',
  STEP_5 = 'digitalMedia',
  STEP_6 = 'insurances',
  STEP_7 = 'healthDocuments',
  STEP_8 = 'ownedAssets',
  STEP_9 = 'legalDocuments',
  STEP_10 = 'riskFactors',
  STEP_END = 'successScreen',
}
const S = STEP_KEYS;

export enum INITIAL_ASSESSMENT_ANSWER_KEYS {
  YES = 'yes',
  NO = 'no',
  NONE = 'none',
  NOTHING_OF_ABOVE = 'nothingOfTheAbove',
  SINGLE = 'single',
  PARTNER_UNMARRIED = 'partner unmarried',
  MARRIED = 'married',
  WIDOWED = 'widowed',
  ADULTS = 'adults',
  COMPLEX = 'complex',
  RENT = 'rent',
  OWNER = 'owner',
  FAMILY_DOCTOR = 'family doctor',
  ATTORNEY = 'attorney',
  FINANCIAL_ADVISOR = 'financial advisor',
  MOBILE_TABLET = 'mobile tablet',
  COMPUTER = 'computer',
  CLOUD_STORAGE = 'cloud storage',
  SOCIAL_MEDIA = 'social media',
  SUBSCRIPTIONS = 'subscriptions',
  ONLINE_BANKING = 'online banking',
  PASSWORD_MANAGER = 'password manager',
  PAID_APPS_OR_LICENSES = 'paid apps or licenses',
  LIFE_INSURANCE = 'life insurance',
  DEATH_BENEFIT_INSURANCE = 'death benefit insurance',
  CARE_INSURANCE = 'care insurance',
  OCCUPATIONAL_DISABILITY_INSURANCE = 'occupational disability insurance',
  ADDITIONAL_HEALTH_INSURANCE = 'additional health insurance',
  LIABILITY_INSURANCE = 'liability insurance',
  LEGAL_PROTECTION_INSURANCE = 'legal protection insurance',
  HOUSEHOLD_CONTENT_INSURANCE = 'household content insurance',
  ORGAN_DONOR_CARD = 'organ donor card',
  PATIENT_DECREE = 'patient decree',
  HEALTH_CARE_PROXY = 'health care proxy',
  EMERGENCY_PASS = 'emergency pass',
  REAL_ESTATE = 'real estate',
  STOCKS = 'stocks',
  VEHICLES = 'vehicles',
  SAFE_DEPOSIT = 'safe deposit',
  LAST_WILL = 'last will',
  BANKING_POWER_OF_ATTORNEY = 'banking power of attorney',
  OTHER_POWER_OF_ATTORNEY = 'other power of attorney',
  CERTIFICATES = 'certificates',
  PETS = 'pets',
  SERIOUS_ILLNESS = 'serious illness',
  RISK_SPORT_OR_WORK = 'risk sport or work',
  NEED_FOR_CARE = 'need for care',
}

const A = INITIAL_ASSESSMENT_ANSWER_KEYS;

export const getInitialAssessmentQuestionnaire = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    [S.STEP_START]: {
      content: t('s_1'),
      current: 1,
      total: 10,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.SINGLE, linksTo: S.STEP_2 },
        { id: A.PARTNER_UNMARRIED, linksTo: S.STEP_2 },
        { id: A.MARRIED, linksTo: S.STEP_2 },
        { id: A.WIDOWED, linksTo: S.STEP_2 },
      ],
    },
    [S.STEP_2]: {
      content: t('s_2'),
      current: 2,
      total: 10,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_3 },
        { id: A.ADULTS, linksTo: S.STEP_3 },
        { id: A.NO, linksTo: S.STEP_3 },
        { id: A.COMPLEX, linksTo: S.STEP_3 },
      ],
    },
    [S.STEP_3]: {
      content: t('s_3'),
      current: 3,
      total: 10,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.RENT, linksTo: S.STEP_4 },
        { id: A.OWNER, linksTo: S.STEP_4 },
      ],
    },
    [S.STEP_4]: {
      content: t('s_4'),
      current: 4,
      total: 10,
      type: C.SINGLEANDMULTIPLE,
      linksTo: S.STEP_5,
      possibleAnswers: [
        { id: A.FAMILY_DOCTOR },
        { id: A.ATTORNEY },
        { id: A.FINANCIAL_ADVISOR },
        { id: A.NOTHING_OF_ABOVE, isNormalChoice: true, isFamilyFailure: true },
      ],
      qa: [{ question: t('s_4_qa_q_1'), answer: t('s_4_qa_a_1') }],
    },
    [S.STEP_5]: {
      content: t('s_5'),
      current: 5,
      total: 10,
      type: C.SINGLEANDMULTIPLE,
      linksTo: S.STEP_6,
      possibleAnswers: [
        { id: A.MOBILE_TABLET },
        { id: A.COMPUTER },
        { id: A.CLOUD_STORAGE },
        { id: A.SOCIAL_MEDIA },
        { id: A.SUBSCRIPTIONS },
        { id: A.ONLINE_BANKING },
        { id: A.PASSWORD_MANAGER },
        { id: A.PAID_APPS_OR_LICENSES },
        { id: A.NOTHING_OF_ABOVE, isNormalChoice: true, isFamilyFailure: true },
      ],
    },
    [S.STEP_6]: {
      content: t('s_6'),
      current: 6,
      total: 10,
      type: C.SINGLEANDMULTIPLE,
      linksTo: S.STEP_7,
      possibleAnswers: [
        { id: A.LIFE_INSURANCE },
        { id: A.DEATH_BENEFIT_INSURANCE },
        { id: A.CARE_INSURANCE },
        { id: A.OCCUPATIONAL_DISABILITY_INSURANCE },
        { id: A.ADDITIONAL_HEALTH_INSURANCE },
        { id: A.LIABILITY_INSURANCE },
        { id: A.LEGAL_PROTECTION_INSURANCE },
        { id: A.HOUSEHOLD_CONTENT_INSURANCE },
        { id: A.NOTHING_OF_ABOVE, isNormalChoice: true, isFamilyFailure: true },
      ],
      qa: [{ question: t('s_6_qa_q_1'), answer: t('s_6_qa_a_1') }],
    },
    [S.STEP_7]: {
      content: t('s_7'),
      current: 7,
      total: 10,
      type: C.SINGLEANDMULTIPLE,
      linksTo: S.STEP_8,
      possibleAnswers: [
        { id: A.ORGAN_DONOR_CARD },
        { id: A.PATIENT_DECREE },
        { id: A.HEALTH_CARE_PROXY },
        { id: A.EMERGENCY_PASS },
        { id: A.NOTHING_OF_ABOVE, isNormalChoice: true, isFamilyFailure: true },
      ],
    },
    [S.STEP_8]: {
      content: t('s_8'),
      current: 8,
      total: 10,
      type: C.SINGLEANDMULTIPLE,
      linksTo: S.STEP_9,
      possibleAnswers: [
        { id: A.REAL_ESTATE },
        { id: A.STOCKS },
        { id: A.VEHICLES },
        { id: A.SAFE_DEPOSIT },
        { id: A.NOTHING_OF_ABOVE, isNormalChoice: true, isFamilyFailure: true },
      ],
    },
    [S.STEP_9]: {
      content: t('s_9'),
      current: 9,
      total: 10,
      type: C.SINGLEANDMULTIPLE,
      linksTo: S.STEP_10,
      possibleAnswers: [
        { id: A.LAST_WILL },
        { id: A.BANKING_POWER_OF_ATTORNEY },
        { id: A.OTHER_POWER_OF_ATTORNEY },
        { id: A.CERTIFICATES },
        { id: A.NOTHING_OF_ABOVE, isNormalChoice: true, isFamilyFailure: true },
      ],
    },
    [S.STEP_10]: {
      content: t('s_10'),
      current: 10,
      total: 10,
      type: C.SINGLEANDMULTIPLE,
      linksTo: S.STEP_END,
      possibleAnswers: [
        { id: A.PETS },
        { id: A.SERIOUS_ILLNESS },
        { id: A.RISK_SPORT_OR_WORK },
        { id: A.NEED_FOR_CARE },
        { id: A.NOTHING_OF_ABOVE, isNormalChoice: true, isFamilyFailure: true },
      ],
    },
    [S.STEP_END]: {
      type: C.SUCCESS,
      customNavigationButtons: true,
    },
  };
};

export const getInitialAssessmentAnswers = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);

  return {
    [A.YES]: { content: t('a_true'), image: icons.imgYes },
    [A.NO]: { content: t('a_false'), image: icons.imgNo },
    [A.NONE]: { content: t('a_none'), image: icons.imgNo },
    [A.NOTHING_OF_ABOVE]: {
      content: t('a_nothingOfTheAbove'),
      image: icons.imgNothing,
    },
    [A.SINGLE]: { content: t('a_single'), image: icons.imgSingle },
    [A.PARTNER_UNMARRIED]: {
      content: t('a_partnerUnmarried'),
      image: icons.imgPartnered,
    },
    [A.MARRIED]: { content: t('a_married'), image: icons.imgMarried },
    [A.WIDOWED]: { content: t('a_widowed'), image: icons.imgWidowed },
    [A.ADULTS]: { content: t('a_adults'), image: icons.imgAdults },
    [A.COMPLEX]: {
      content: t('a_complex'),
      infoContent: t('info_complex'),
      image: icons.imgComplicated,
    },
    [A.RENT]: { content: t('a_rent'), image: icons.imgRent },
    [A.OWNER]: { content: t('a_owner'), image: icons.imgProperty },
    [A.FAMILY_DOCTOR]: {
      content: t('a_familyDoctor'),
      image: icons.imgFamilyDoctor,
    },
    [A.ATTORNEY]: { content: t('a_attorney'), image: icons.imgAttorney },
    [A.FINANCIAL_ADVISOR]: {
      content: t('a_financialAdvisor'),
      image: icons.imgFinancialAdvisor,
    },
    [A.MOBILE_TABLET]: { content: t('a_mobileTablet'), image: icons.imgHandy },
    [A.COMPUTER]: { content: t('a_computer'), image: icons.imgComputer },
    [A.CLOUD_STORAGE]: { content: t('a_cloudStorage'), image: icons.imgCloud },
    [A.SOCIAL_MEDIA]: {
      content: t('a_socialMedia'),
      image: icons.imgSocialMedia,
    },
    [A.SUBSCRIPTIONS]: {
      content: t('a_subscriptions'),
      infoContent: t('info_subscriptions'),
      image: icons.imgSubscriptions,
    },
    [A.ONLINE_BANKING]: {
      content: t('a_onlineBanking'),
      image: icons.imgOnlineBanking,
    },
    [A.PASSWORD_MANAGER]: {
      content: t('a_passwordManager'),
      image: icons.imgPasswordManager,
    },
    [A.PAID_APPS_OR_LICENSES]: {
      content: t('a_paidAppsOrLicense'),
      infoContent: t('info_paid_apps_or_licenses'),
      image: icons.imgPaidAppsLicense,
    },
    [A.LIFE_INSURANCE]: {
      content: t('a_lifeInsurance'),
      image: icons.imgLifeInsurance,
    },
    [A.DEATH_BENEFIT_INSURANCE]: {
      content: t('a_deathBenefitInsurance'),
      image: icons.imgDeathBenefitInsurance,
    },
    [A.CARE_INSURANCE]: {
      content: t('a_careInsurance'),
      image: icons.imgCareInsurance,
    },
    [A.OCCUPATIONAL_DISABILITY_INSURANCE]: {
      content: t('a_occupationalDisabilityInsurance'),
      image: icons.imgOccupationalDisabilityInsurance,
    },
    [A.ADDITIONAL_HEALTH_INSURANCE]: {
      content: t('a_additionalHealthInsurance'),
      infoContent: t('info_additional_health_insurance'),
      image: icons.imgAdditionalHealthInsurance,
    },
    [A.LIABILITY_INSURANCE]: {
      content: t('a_liabilityInsurance'),
      image: icons.imgLiability,
    },
    [A.LEGAL_PROTECTION_INSURANCE]: {
      content: t('a_legalProtectionInsurance'),
      image: icons.imgLegalProtectionInsurance,
    },
    [A.HOUSEHOLD_CONTENT_INSURANCE]: {
      content: t('a_householdContentInsurance'),
      image: icons.imgHouseholdContentInsurance,
    },
    [A.ORGAN_DONOR_CARD]: {
      content: t('a_organDonorCard'),
      image: icons.imgOrganDonorCard,
    },
    [A.PATIENT_DECREE]: {
      content: t('a_patientDecree'),
      infoContent: t('info_patient_decree'),
      image: icons.imgPatientDecreeInit,
    },
    [A.HEALTH_CARE_PROXY]: {
      content: t('a_healthCareProxy'),
      infoContent: t('info_health_care_proxy'),
      image: icons.imgHealthCareProxy,
    },
    [A.EMERGENCY_PASS]: {
      content: t('a_emergencyPass'),
      infoContent: t('info_emergency_pass'),
      image: icons.imgEmergencyPass,
    },
    [A.REAL_ESTATE]: { content: t('a_realEstate'), image: icons.imgRealEstate },
    [A.STOCKS]: { content: t('a_tradedAssets'), image: icons.imgStocks },
    [A.VEHICLES]: {
      content: t('a_vehicles'),
      infoContent: t('info_vehicles'),
      image: icons.imgCars,
    },
    [A.SAFE_DEPOSIT]: {
      content: t('a_safeDeposit'),
      image: icons.imgSafeDeposit,
    },
    [A.LAST_WILL]: { content: t('a_lastWill'), image: icons.imgLastWill },
    [A.BANKING_POWER_OF_ATTORNEY]: {
      content: t('a_bankingPowerOfAttorney'),
      image: icons.imgBankingPowerOfAttorney,
    },
    [A.OTHER_POWER_OF_ATTORNEY]: {
      content: t('a_otherPowerOfAttorney'),
      infoContent: t('info_other_power_of_attorney'),
      image: icons.imgOtherPowerOfAttorney,
    },
    [A.CERTIFICATES]: {
      content: t('a_certificate'),
      infoContent: t('info_certificate'),
      image: icons.imgCertificate,
    },
    [A.PETS]: { content: t('a_pet'), image: icons.imgPet },
    [A.SERIOUS_ILLNESS]: {
      content: t('a_majorIllness'),
      image: icons.imgSeriousIllness,
    },
    [A.RISK_SPORT_OR_WORK]: {
      content: t('a_riskSportWork'),
      image: icons.imgRiskSport,
    },
    [A.NEED_FOR_CARE]: { content: t('a_dependency'), image: icons.imgNeedFor },
  };
};

export const getInitialAssessmentStartScreen = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    listItems: [
      t('start_screen_list_1'),
      t('start_screen_list_2'),
      t('start_screen_list_3'),
    ],
  };
};
