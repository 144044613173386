import { httpHelper, config } from 'modules/common/helpers';

const BASE_URL = config.beUrl;

export default {
  getNextSteps,
  skipStep,
};

function getNextSteps() {
  return httpHelper.get(`${BASE_URL}/api/next-steps`, {});
}

function skipStep(step: string) {
  return httpHelper.post(`${BASE_URL}/api/skip-step`, { step });
}
