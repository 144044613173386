import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { accessBeneficiary } from 'store/actions/userActions';
import { dateHelper, config, useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import {
  SiteLanguage,
  BirthdayInput,
  TextInputRef,
  Button,
  PlatformFeatureInfoSection,
} from 'modules/common/components';
import {
  AuthFooter,
  ClickableLogo,
  LoginFooter,
} from 'modules/auth/components';
import { LoginLink } from 'modules/afterDeathManagement/components';

import { VALIDATE } from 'modules/common/constants/generic';
import { beneFeatures } from 'modules/auth/constants';

import { ReactComponent as IconLink } from 'images/icon-outside-link.svg';
import imgRectangle from 'images/img-auth-rectangle.png';

type Inputs = {
  birthday: string;
  token: string;
};

function BeneficiaryAccessPage() {
  const { t } = useTranslation(['auth']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<Inputs>();

  const [isAccessLoading, setIsAccessLoading] = React.useState(false);

  const handleBeneficiaryLogin = async (formData) => {
    setIsAccessLoading(true);

    const submitData = {
      token: formData.token.replace(/(-|_)/g, ''),
      birthday:
        formData.birthday &&
        dateHelper.convertDateFormat(
          formData.birthday,
          config.format.serverDate
        ),
    };

    const response: any = await dispatch(accessBeneficiary(submitData));

    if (response) await navigate('/beneficiary');

    setIsAccessLoading(false);
  };

  const fieldRequired =
    errors.token?.type === VALIDATE
      ? t('beneficiary:access_page_security_code_invalid_error')
      : t('beneficiary:access_page_security_code_require_error');

  return (
    <>
      <Row className="lg:t-p-0 t-min-h-screen t-w-full t-m-0 t-relative t-bg-delta-200">
        <div className="t-absolute t-right-0 t-top-36 t-z-50 t-hidden lg:t-inline-block">
          <LoginLink extraClassName="t-rounded-r-none" />
        </div>
        <Col
          md={{ span: 6, offset: 3 }}
          lg={{ span: 10, offset: 1 }}
          className="t-p-0 t-z-40"
        >
          <div className="t-hidden md:t-flex t-justify-between t-mt-6 lg:t-mt-10 t-px-3">
            <ClickableLogo />
            <div className="t-flex t-items-center">
              <div className="t-hidden lg:t-inline-block">
                <AuthFooter />
              </div>
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>
          </div>

          <div className="md:t-flex t-flex-col t-items-center t-mt-4 lg:t-mt-16 t-px-3 t-mb-16">
            <div className="md:t-hidden t-flex t-justify-between">
              <ClickableLogo />
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>

            <LoginLink extraClassName="lg:t-hidden t-my-5 lg:t-mt-16 t-w-full" />

            <div className="card t-shadow-lg t-bg-beta-50 t-p-6 lg:t-px-12 lg:t-pt-12 t-w-full lg:t-w-auto lg:t-max-w-112">
              <h2 className="typo-beta t-mb-5">
                {t('beneficiary:access_page_title')}
              </h2>
              <p className="typo-epsilon t-mt-5">
                {t('beneficiary:access_page_description')}
              </p>
              <form
                onSubmit={handleSubmit(handleBeneficiaryLogin)}
                className="Form t-mt-8 t-w-full lg:t-w-auto t-bg-transparent"
              >
                <fieldset disabled={isAccessLoading}>
                  <BirthdayInput
                    control={control}
                    error={errors?.birthday?.message || ''}
                    isRequired={true}
                    defaultValue=""
                    label={t('beneficiary:birthday_label')}
                  />

                  <TextInputRef
                    {...register('token', {
                      required: true,
                      validate: (value) => {
                        if (value.replace(/(-|_)/g, '').length !== 16)
                          return false;
                        return true;
                      },
                    })}
                    label={t('beneficiary:access_page_security_code_label')}
                    placeholder={t(
                      'beneficiary:access_page_security_code_placeholder'
                    )}
                    mask="****-****-****-****"
                    maxLength={19}
                    type="text"
                    error={errors.token && fieldRequired}
                    defaultValue=""
                    showLockIcon
                  />
                </fieldset>

                <div className="t-flex t-justify-end t-mb-5">
                  <Button
                    type="submit"
                    loading={isAccessLoading}
                    className={`t-mt-4 ${
                      isAccessLoading
                        ? ''
                        : 't-bg-delta-700 hover:t-shadow-delta-700'
                    }`}
                  >
                    {t('beneficiary:access_page_submit_button')}
                  </Button>
                </div>

                <div className="t-flex t-justify-end">
                  <a
                    className="t-flex"
                    href={`${config.websiteUrl}/beneficiary`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="typo-epsilon t-text-delta-700">
                      {t(
                        'beneficiary:access_page_link_to_beneficiary_website_page'
                      )}
                    </span>
                    <IconLink className="t-ml-2.5 t-text-delta-700" />
                  </a>
                </div>
              </form>
            </div>

            <div className="t-w-full t-flex t-justify-center">
              <p className="typo-theta t-mt-3 t-pb-4 t-text-beta-700 t-inline t-text-center">
                {t('beneficiary:report_incident')}
                <Link
                  className="t-text-delta-700 t-ml-1 t-font-semibold"
                  to="/incident-report"
                >
                  {t('beneficiary:report_incident_link_text')}
                </Link>
                ?
              </p>
            </div>

            <div className="t-my-5 lg:t-mt-16 t-flex t-justify-center md:t-inline">
              <PlatformFeatureInfoSection
                features={beneFeatures}
                titleClassName="lg:t-min-h-10"
              />
            </div>

            <div className="lg:t-hidden t-mt-16">
              <AuthFooter />
            </div>
          </div>

          <div className="t-absolute t-bottom-0 t-w-full t-flex t-justify-center md:t-inline">
            <LoginFooter />
          </div>
        </Col>
      </Row>
      <div className="t-fixed t-w-screen t-object-cover t-h-3/4 t-bottom-0">
        <img
          src={imgRectangle}
          className="t-w-full t-h-full"
          alt="Rectangle background"
        />
      </div>
    </>
  );
}

export default BeneficiaryAccessPage;
