import React from 'react';
import ReactTooltip from 'react-tooltip';

import {
  getFullName,
  reactToast,
  useTranslation,
} from 'modules/common/helpers';

import { ReactComponent as PhoneIcon } from 'images/phone-icon.svg';
import { ReactComponent as EmailIcon } from 'images/mail-icon.svg';
import { ReactComponent as CompanyIcon } from 'images/icon-company.svg';
import { ReactComponent as CopyIcon } from 'images/icon_copy.svg';

export interface AgentInfoProps {
  agentInfo: AgentDTO;
  className?: string;
}

const AgentInfo = ({ agentInfo, className = '' }: AgentInfoProps) => {
  const { t } = useTranslation(['agents']);

  const agentFullName = getFullName(agentInfo);

  const notAPerson = !agentInfo.name && !agentInfo.surname;

  const copyEmailToClipboard = () => {
    navigator.clipboard.writeText(agentInfo.email);
    reactToast.showMessage(t('toastr:copied'));
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div className={className} data-testid="AgentInfo">
      {!notAPerson && (
        <p className="typo-delta t-whitespace-normal">{agentFullName}</p>
      )}
      {agentInfo.company && (
        <p
          className={`t-flex t-items-center t-whitespace-normal ${
            !notAPerson ? 'typo-epsilon t-mt-2' : 'typo-delta'
          }`}
        >
          {!notAPerson && (
            <span>
              <CompanyIcon className="t-text-beta-700 t-mr-2" />
            </span>
          )}
          <span>{agentInfo.company}</span>
        </p>
      )}
      {agentInfo?.phone && (
        <div className="t-flex t-items-center t-mt-2">
          <span>
            <PhoneIcon className="t-mr-3" />
          </span>
          <a
            className="t-text-delta-700 t-outline-none t-no-underline t-whitespace-normal"
            href={`tel:${agentInfo.phone}`}
          >
            {agentInfo.phone}
          </a>
        </div>
      )}
      <div className="t-flex t-mt-2">
        <span>
          <EmailIcon className="t-mr-3 t-mt-1" />
        </span>
        <a
          className="md:t-truncate t-text-delta-700 t-outline-none t-no-underline"
          href={`mailto:${agentInfo.email}?subject=${t(
            'agents:mail_to_subject'
          )}`}
          data-tip={agentInfo.email}
          data-tip-disable={agentInfo.email.length < 45}
        >
          {agentInfo.email}
        </a>
        <button
          onClick={copyEmailToClipboard}
          className="t-ml-2 t-outline-none"
          data-testid="copyEmail"
        >
          <CopyIcon className="t-text-beta-400 t-w-5 t-h-5" />
        </button>
      </div>

      {agentInfo.link && (
        <div className="t-flex t-mt-5 ">
          <a
            href={agentInfo.link}
            target="_blank"
            rel="nofollow noreferrer noopener"
            className="button-secondary"
          >
            {t('agents:agent_website_button')}
          </a>
        </div>
      )}
    </div>
  );
};

export default AgentInfo;
