import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'modules/common/helpers';

/**
 * @param {Function} requestFn - some redux action creator
 */
const useAsyncRequest = <T = any>(requestFn: (...args) => any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [response, setResponse] = useState<null | T>(null);

  const request = useCallback(
    async (...args) => {
      setError('');
      setLoading(true);
      const res = await dispatch(requestFn(...args));

      if (res) {
        setResponse(res);
      } else {
        setError(t('common:something_went_wrong'));
        setResponse(null);
      }

      setLoading(false);

      return res;
    },
    [dispatch, requestFn, t]
  );

  return { loading, error, response, request };
};

export default useAsyncRequest;
