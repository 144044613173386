import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { kebabCase } from 'lodash';

import { RootState } from 'store/reducers';
import { createLetter } from 'store/actions/planActions';

import {
  PostalInfoModal,
  PostalOptionModal,
  PostalPaymentSuccessModal,
} from 'modules/assetType/components';
import { EMAIL } from 'modules/common/constants/generic';
import { POSTAL_STEPS, POSTAL_TYPES } from 'modules/common/constants/enums';

interface PostalProcessProps {
  onCancel: () => void;
  data: StoredData;
  category?: string;
  contact: ContactDTO | UserProfileDTO;
  type: POSTAL_TYPES;
  initialStep?: string;
  isSuccessful?: boolean;
}

const PostalProcess = ({
  onCancel,
  data,
  category,
  contact,
  type,
  initialStep,
  isSuccessful,
}: PostalProcessProps) => {
  const dispatch = useDispatch();

  const userEmail = useSelector((state: RootState) => state.user.current.email);
  const assets: AssetsDTO = useSelector((state: RootState) => state.assets);
  const categoryAssets: AssetDTO[] = assets[category || ''] || [];

  const [step, setStep] = React.useState(
    isSuccessful
      ? POSTAL_STEPS.STEP_SUCCESS
      : initialStep || POSTAL_STEPS.STEP_OPTION
  );
  const [loading, setLoading] = React.useState(false);

  // In some browsers, the state of the loading is kept when the user click the browser "Return" arrow
  // we should clean the state when component unmounted to avoid the situation
  React.useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const { assetId, documentId = '', documentTitle = '' } = data;

  const relatedAsset = categoryAssets.find((asset) => asset.id === assetId);
  const generatedDocument = relatedAsset?.documents[0] || ({} as DocumentDTO);
  const contactDocument = !!documentId
    ? { id: documentId, documentTitle }
    : ({} as DocumentDTO);
  const isContactType = type === POSTAL_TYPES.CONTACT;
  const document = isContactType ? contactDocument : generatedDocument;

  const onPayment = async (postalInfo: PostalInfo) => {
    setLoading(true);
    if (!!documentId) {
      const paymentUrl: any = await dispatch(
        createLetter({
          ...postalInfo,
          documentId,
          category: !isContactType ? kebabCase(category) : '',
          contactId: !!isContactType && contact?.id ? contact.id : '',
        })
      );
      if (paymentUrl) {
        localStorage.setItem(EMAIL, userEmail);
        window.location.href = paymentUrl;
      } else {
        setLoading(false);
      }
    } else {
      return;
    }
  };

  switch (step) {
    case POSTAL_STEPS.STEP_OPTION:
      return (
        <PostalOptionModal
          onCancel={onCancel}
          nextStep={() => {
            setStep(POSTAL_STEPS.STEP_POSTAL_INFO);
          }}
        />
      );
    case POSTAL_STEPS.STEP_POSTAL_INFO:
      return (
        <PostalInfoModal
          onCancel={onCancel}
          onPayment={onPayment}
          loading={loading}
          document={document}
          contact={contact}
          type={type}
        />
      );
    case POSTAL_STEPS.STEP_SUCCESS:
      return <PostalPaymentSuccessModal onClose={onCancel} type={type} />;
    default:
      return null;
  }
};

export default PostalProcess;
