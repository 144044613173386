import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { logOut } from 'store/actions/userActions';

import { Button } from 'modules/common/components';

interface LogoutButtonProps {
  className?: string;
}

function LogoutButton({ className = '' }: LogoutButtonProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();

  const isBeneficiary: boolean = useSelector(
    (state: RootState) => state.user.current.beneficiary
  );

  const onLogOut = async () => {
    await dispatch(logOut());
    if (isBeneficiary) navigate('/access');
    navigate('/login');
  };

  return (
    <Button onClick={onLogOut} className={className}>
      {t('common:sign_out')}
    </Button>
  );
}

export default LogoutButton;
