import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import {
  dateHelper,
  getFullAddress,
  optionsHelper,
  useGetCountries,
  config,
  useTranslation,
} from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';
import { AddInformationForm } from 'modules/assistant/components/Answers';

import { QUESTIONNAIRE_FORM_FIELD_TYPES as F } from 'modules/assistant/constants/questionnaires/questionnaireFormFieldTypes';

import { ReactComponent as DeleteIcon } from 'images/icon-delete.svg';
import { ReactComponent as MenuIcon } from 'images/icon-menu.svg';
import { ReactComponent as IconEdit } from 'images/icon-edit.svg';
import iconPlus from 'images/icon-plus.svg';

export interface AddInformationProps {
  previousAnswer?: SelectedAnswer[];
  formTypeData: QuestionnaireFormType[];
}

const AddInformation = ({
  previousAnswer,
  formTypeData,
}: AddInformationProps) => {
  const { t } = useTranslation(['common', 'assistant', 'will']);
  const dispatch = useDispatch();
  const countries = useGetCountries();

  const [showForm, setShowForm] = useState(false);
  const [data, setData] = useState(previousAnswer || []);
  const [dataToEdit, setDataToEdit] = useState({});
  const [dataToEditIdx, setDataToEditIdx] = useState(0);

  React.useEffect(() => {
    dispatch(temporaryFieldSaver(data));
  }, [data]);

  useEffect(() => {
    if (previousAnswer) {
      dispatch(temporaryFieldSaver(previousAnswer));
    }
  }, [previousAnswer]);

  const handleAddOrEditInfoSubmit = (type, submitData) => {
    if (type === 'add') {
      setData([...data, submitData]);
    } else {
      setData((prev) => {
        const copiedState = [...prev];
        copiedState[dataToEditIdx] = submitData;
        return copiedState;
      });
    }
    handleFormModalClose();
  };

  const handleFormModalClose = () => {
    setShowForm(false);
    setDataToEdit({});
  };

  const genderOptions = optionsHelper.getGenderOptions2();

  const getFieldValue = (fieldsData, value) => {
    switch (fieldsData.type) {
      case F.INSURANCE_TYPE:
        return (
          fieldsData?.options?.find((option) => option?.value === value)
            ?.label || value
        );
      case F.ADDRESS:
        return getFullAddress(value, countries);
      case F.GENDER:
        return (
          genderOptions.find((gender) => gender.value === value)?.label || ''
        );
      case F.DATE:
      case F.BIRTHDAY:
        return dateHelper.convertDateFormat(
          value,
          config.format.uiDashboardDate
        );
      default:
        return value;
    }
  };

  const onEditItem = (item, idx) => {
    setDataToEdit(item);
    setDataToEditIdx(idx);
    setShowForm(true);
  };

  const onDeleteItem = (idx) => {
    const copiedData = [...data];
    copiedData.splice(idx, 1);
    setData(copiedData);
  };

  return (
    <>
      {showForm && (
        <Modal show onHide={handleFormModalClose}>
          <AddInformationForm
            formTypeData={formTypeData}
            previousAnswer={dataToEdit}
            onSuccess={handleAddOrEditInfoSubmit}
            onCancel={handleFormModalClose}
          />
        </Modal>
      )}

      {!data.length ? (
        <div className="d-flex flex-column align-items-center">
          <Button category="secondary" onClick={() => setShowForm(true)}>
            <img
              src={iconPlus}
              className="button-icon-amazon"
              alt={t('assistant:add_information')}
              role="presentation"
            />
            {t('assistant:add_information')}
          </Button>
        </div>
      ) : (
        <div className="t-flex t-flex-col t-items-center t-w-full">
          <div className="t-w-full t-flex t-flex-col t-justify-center t-items-center">
            {data.map((item, idx) => {
              return (
                <div
                  className="card t-p-6 t-w-full xl:t-w-3/5 t-mb-5"
                  key={`${Object.keys(item)[0]}_${idx}`}
                >
                  <div className="Character-menu">
                    <Dropdown className="dropdown--settings">
                      <Dropdown.Toggle variant="success" id="dropdown-settings">
                        <MenuIcon className="t-text-beta-200" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="t-m-0">
                        <Dropdown.Item onClick={() => onEditItem(item, idx)}>
                          <IconEdit className="dropdown-icon t-text-beta-200" />
                          {t('common:edit_label')}
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => onDeleteItem(idx)}>
                          <DeleteIcon className="dropdown-icon dropdown-icon--delete t-text-epsilon-400" />
                          {t('common:delete_label')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {formTypeData.map((field, idx) => {
                    if (!item[field.type]) return null;
                    return (
                      <React.Fragment
                        key={`${field.type}_${idx}_${Object.keys(item)[0]}`}
                      >
                        <p className="typo-zeta t-pb-1 t-mt-2">{field.label}</p>
                        <p className="typo-delta t-p-2 t-bg-beta-100 t-rounded-sm t-text-alpha-600">
                          {getFieldValue(field, item[field.type])}
                        </p>
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            })}
          </div>

          <Button category="secondary" onClick={() => setShowForm(true)}>
            <img
              src={iconPlus}
              className="button-icon-amazon"
              alt={t('common:add_new')}
              role="presentation"
            />
            {t('common:add_new')}
          </Button>
        </div>
      )}
    </>
  );
};

export default AddInformation;
