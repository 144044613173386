import React from 'react';
import { isEmpty } from 'lodash';

import { useTranslation } from 'modules/common/helpers';

import { CommonFormInputProps, FormErrors } from 'modules/common/types';

import { SelectController, TextInputRef } from 'modules/common/components';

export interface SingleSelectWithOtherOptionProps extends CommonFormInputProps {
  label: string;
  errors: FormErrors;
  name: string;
  selectOptions: SelectOption[];
  otherOptionFieldData?: Record<string, string>;
  defaultValue?: string;
  fieldErr?: string;
  required?: boolean;
}

const OTHER = 'other';

export default function SingleSelectWithOtherOption({
  label,
  control,
  watch,
  errors,
  register,
  name,
  selectOptions,
  otherOptionFieldData,
  defaultValue,
  fieldErr,
  required,
}: SingleSelectWithOtherOptionProps) {
  const { t } = useTranslation(['common', 'subtypes']);

  const defaultOption =
    selectOptions?.find((option) => option?.value === defaultValue) || '';
  const otherOption =
    selectOptions?.find((option) => option?.value?.toLowerCase() === OTHER) ||
    '';
  const otherFieldName = otherOptionFieldData?.name || OTHER;

  const [isOtherSubtype, setIsOtherSubtype] = React.useState(false);

  React.useEffect(() => {
    const selectedValue = watch(`${name}`)?.value;
    setIsOtherSubtype(selectedValue === otherFieldName);
  }, [watch(`${name}`)?.value]);

  React.useEffect(() => {
    defaultValue && setIsOtherSubtype(true);

    return () => {
      setIsOtherSubtype(false);
    };
  }, [defaultValue]);

  return (
    <>
      <div className="t-mb-3.5">
        <label
          htmlFor={name}
          className={`t-inline-block t-mb-2 t-text-beta-500 ${
            fieldErr ? 't-text-epsilon-600' : ''
          }`}
        >
          {label}
        </label>
        <SelectController
          name={name}
          options={selectOptions}
          control={control}
          defaultValue={
            !defaultValue
              ? ''
              : isEmpty(defaultOption)
              ? otherOption
              : defaultOption
          }
          className={`Select ${fieldErr ? 'isErrored' : ''}`}
          rules={{ required: required }}
        />
        {fieldErr && (
          <div className="t-pt-0.5">
            <span className="text-main-sm t-text-epsilon-600">{fieldErr}</span>
          </div>
        )}
      </div>

      {isOtherSubtype && !isEmpty(otherOption) ? (
        <div className="Form-group">
          <TextInputRef
            {...register(otherFieldName, { required: true })}
            label={otherOptionFieldData?.label || ''}
            type="text"
            autoComplete={false}
            error={
              errors[otherFieldName]?.type
                ? `${otherOptionFieldData?.label} ${t('common:required')}`
                : ''
            }
            defaultValue={!defaultOption ? defaultValue : ''}
          />
        </div>
      ) : null}
    </>
  );
}
