import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconDocument } from 'images/icon_auth_footer_doc.svg';
import { ReactComponent as IconDE } from 'images/icon_auth_footer_de_dots.svg';
import { ReactComponent as IconShield } from 'images/icon_shield.svg';

interface AuthFooterProps {
  customFont?: string;
}

export default function AuthFooter({ customFont = '' }: AuthFooterProps) {
  const { t } = useTranslation(['common', 'auth']);

  return (
    <div
      className="t-flex t-w-full t-flex-col t-items-center lg:t-flex-row"
      data-testid="AuthFooter"
    >
      <div className="card t-shadow-lg t-flex t-items-center t-px-4 t-max-w-56 lg:t-mr-4">
        <div>
          <IconDocument className="t-mr-3" />
        </div>
        <p className={`typo-kappa t-text-delta-900 t-py-2 ${customFont}`}>
          {t('auth:footer_text_1')}
        </p>
      </div>
      <div className="card t-shadow-lg t-flex t-items-center t-px-4 t-max-w-64 t-mt-5 lg:t-mt-0">
        <div>
          <IconDE className="t-mr-3" />
        </div>
        <p className={`typo-kappa t-text-delta-900 t-py-2 ${customFont}`}>
          {t('auth:footer_text_2')}
        </p>
        <div className="t-ml-2">
          <IconShield />
        </div>
      </div>
    </div>
  );
}
