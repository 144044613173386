import { combineReducers } from 'redux';

import { USER_LOGOUT } from 'store/types/userActionTypes';

import assetTypes from 'store/reducers/assetTypeReducer';
import assetTemplates from 'store/reducers/assetTemplateReducer';
import assets from 'store/reducers/assetReducer';
import common from 'store/reducers/commonReducer';
import contacts from 'store/reducers/contactReducer';
import heirs from 'store/reducers/heirReducer';
import documents from 'store/reducers/documentReducer';
import layout from 'store/reducers/layoutReducer';
import modal from 'store/reducers/modalReducer';
import overviewContact from 'store/reducers/overviewContactReducer';
import plan from 'store/reducers/planReducer';
import questionnaires from 'store/reducers/questionnaireReducer';
import user from 'store/reducers/userReducer';
import assistant from 'store/reducers/assistantReducer';
import recommendations from 'store/reducers/recommendationsReducer';
import temporaryField from 'store/reducers/temporaryFieldReducer';
import loading from 'store/reducers/loadingReducer';
import immediateAssets from 'store/reducers/immediateAssetsReducer';
import notifications from 'store/reducers/notificationReducer';
import nextSteps from 'store/reducers/nextStepReducer';
import contactOf from 'store/reducers/contactOfReducer';
import providers from 'store/reducers/providerReducer';
import assetSearch from 'store/reducers/assetSearchReducer';
import keyHolders from 'store/reducers/keyHolderReducer';
import familyAccounts from 'store/reducers/familyAccountReducer';

const appReducer = combineReducers({
  assets,
  assetTemplates,
  assetTypes,
  assistant,
  common,
  contacts,
  documents,
  heirs,
  immediateAssets,
  layout,
  loading,
  modal,
  notifications,
  overviewContact,
  plan,
  questionnaires,
  recommendations,
  temporaryField,
  user,
  nextSteps,
  contactOf,
  providers,
  assetSearch,
  keyHolders,
  familyAccounts,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
