import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { setPlanIntroDone } from 'store/actions/planActions';
import { updateTutorial } from 'store/actions/userActions';
import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

import illustration1 from 'images/illustration-intro-page-1.png';
import illustration2 from 'images/illustration-intro-page-2.png';
import illustration3 from 'images/illustration-intro-page-3.png';
import { ReactComponent as IconArrow } from 'images/icon-arrow-down.svg';

const planIntroPlain = [
  {
    image: illustration1,
    transKey: 'plan_intro_plain_1',
    className: 't-w-28',
  },
  {
    image: illustration2,
    transKey: 'plan_intro_plain_2',
    className: 't-w-40',
  },
  {
    image: illustration3,
    transKey: 'plan_intro_plain_3',
    className: 't-w-28',
  },
];

interface EpIntroductionProps {
  nextPlanStep: PlanStep;
}

function EpIntroduction({ nextPlanStep }: EpIntroductionProps) {
  const { t } = useTranslation(['plan']);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const tutorial = userData?.tutorial || ({} as TutorialField);

  const [loading, setLoading] = React.useState(false);

  const handleSave = async () => {
    setLoading(true);
    await dispatch(setPlanIntroDone());
    navigate(nextPlanStep.path || '');
    setLoading(false);
  };

  const startVaultTutorial = async () => {
    await dispatch(updateTutorial({ ...tutorial, plan: true }));
  };

  return (
    <div className="t-h-full t-flex t-flex-col t-justify-between">
      <div>
        <h2 className="typo-alpha t-pb-2.5">{t('plan:plan_intro_title')}</h2>
        <p className="t-mb-6 typo-gamma">{t('plan:plan_intro_description')}</p>
        <h3 className="t-mb-2 typo-beta">{t('plan:plan_intro_subtitle')}</h3>

        <div className="t-hidden sm:t-inline">
          {planIntroPlain.map(({ image, transKey, className }, idx) => (
            <div className="t-flex t-items-center t-mt-8" key={idx}>
              <div>
                <div className="t-w-40 t-flex t-justify-center t-relative t-flex-grow">
                  <img
                    src={image}
                    alt={`Vault intro ${idx}`}
                    className={`${className} t-max-w-40 t-py-0.5 t-z-50`}
                  />

                  {idx !== planIntroPlain.length - 1 && (
                    <IconArrow className="t-text-alpha-600 t-absolute -t-bottom-4" />
                  )}
                </div>
              </div>

              <p className="typo-delta t-ml-6">{t(`plan:${transKey}`)}</p>
            </div>
          ))}
        </div>

        <div className="sm:t-hidden t-mb-12 t-mt-8">
          {planIntroPlain.map(({ transKey }, idx) => (
            <p className="t-mb-2 typo-delta" key={idx}>
              {`${idx + 1}. ${t(`plan:${transKey}`)}`}
            </p>
          ))}
        </div>
      </div>

      <div className="t-flex t-justify-end t-flex-wrap t-mt-8 t-mb-12 xs:t-mt-5 ">
        {!tutorial.plan && !userData.secondary && (
          <Button category="secondary" onClick={startVaultTutorial}>
            {t('tutorial:vault_tour_reset_button')}
          </Button>
        )}
        <Button onClick={handleSave} className="t-ml-9" loading={loading}>
          {t('common:continue')}
        </Button>
      </div>
    </div>
  );
}

export default EpIntroduction;
