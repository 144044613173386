import React from 'react';

import { Row, Col } from 'modules/bootstrap/components';
import { ProgressBar, SiteLanguage } from 'modules/common/components';
import {
  OnBoardingWizardDataSecurity,
  OnBoardingWizardPersonalInfo,
  OnBoardingWizardReqCheck,
} from 'modules/onBoardingWizard/components';

import { STEP_LABELS } from 'modules/onBoardingWizard/constants';

import wizardBg from 'images/two-column-page-bg-1.png';
import logo from 'images/logo.svg';

const { personalInfo, dataSecurity, requirementCheck } = STEP_LABELS;

const OnBoardingWizardProcess = () => {
  const [step, setStep] = React.useState<number>(personalInfo.step);

  const stepComponents = {
    [personalInfo.step]: (
      <OnBoardingWizardPersonalInfo handleStepChange={setStep} />
    ),
    [dataSecurity.step]: (
      <OnBoardingWizardDataSecurity handleStepChange={setStep} />
    ),
    [requirementCheck.step]: <OnBoardingWizardReqCheck />,
  };

  return (
    <div data-testid="OnBoardingWizard">
      <img
        src={wizardBg}
        className="Decoration Decoration--authentication"
        alt="Authentication background"
      />
      <main className="t-bg-gamma-400 t-min-h-screen t-p-3 lg:t-p-0">
        <Row className="t-p-3 lg:t-p-0 t-w-full">
          <Col lg={{ span: 6, offset: 6 }} md={{ span: 8, offset: 2 }}>
            <div className="Form t-pb-12 t-pt-5 md:t-py-12 md:t-px-8 md:t-max-w-sm xl:t-px-16">
              <div className="t-flex t-justify-between t-items-center t-mb-12">
                <img src={logo} alt="logo" />
                <SiteLanguage additionalClass="u-pb-0 u-mr-20" />
              </div>

              <ProgressBar
                currentStep={step}
                handleStepChange={setStep}
                stepsData={STEP_LABELS}
              />
              <div className="t-mb-10">{stepComponents[step]}</div>
            </div>
          </Col>
        </Row>
      </main>
    </div>
  );
};

export default OnBoardingWizardProcess;
