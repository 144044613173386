import { useTranslation as useOriginalTranslation } from 'react-i18next';

export default function useTranslation(modules = ['common'] as string[]) {
  const { t: originalT, ...rest } = useOriginalTranslation(modules);

  // The brand variable can be accessed by all translations
  const brandName = originalT('common:brand_name');
  const t = (key: string, variables = {} as TransVariables) =>
    originalT(key, { ...variables, brand: brandName });

  return { t, ...rest };
}
