import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import {
  MyContacts,
  MyContactsReferralSection,
} from 'modules/myContacts/components';
import { AnswersAccordion } from 'modules/estatePlan/components';
import { MyFamilyAccounts } from 'modules/familyAccount/components';

import { PAGES } from 'modules/estatePlan/constants/enums';

function MyContactsPage() {
  const { t } = useTranslation(['mcontacts']);

  const familyAccounts = useSelector(
    (state: RootState) => state.familyAccounts.list
  );

  const isPrimaryUserActive = !!familyAccounts.find(
    (account) => !!account.primary && !!account.active
  );

  React.useEffect(() => {
    if (!isPrimaryUserActive) {
      setIsFamilyTab(false);
    }
  }, [isPrimaryUserActive]);

  const [isFamilyTab, setIsFamilyTab] = React.useState(false);

  const selectedTabClassName = 't-bg-alpha-600 t-text-gamma-400';
  const unselectedTabClassName = 't-bg-alpha-150 t-text-alpha-600';

  const infoAccordionTab = isFamilyTab ? PAGES.MY_FAMILY : PAGES.MY_CONTACTS;

  return (
    <Row className="t-h-100">
      <Col
        md={{ span: 12 }}
        xl={{ span: 10, offset: 2 }}
        style={{ paddingRight: 0 }}
      >
        <Row className="t-m-0 t-p-0 t-mb-7 t-bg-alpha-100">
          <Col
            md={{ span: 12 }}
            xl={{ span: 7 }}
            className="card t-bg-beta-50 md:t-p-12 xs:t-px-8 xs:t-py-10"
          >
            {isPrimaryUserActive && (
              <div className="t-p-1.5 t-bg-alpha-150 t-rounded t-flex t-mb-9">
                <div className="t-w-1/2 t-pr-1">
                  <p
                    className={`t-w-full button typo-eta t-uppercase t-px-3.5 ${
                      !isFamilyTab
                        ? selectedTabClassName
                        : unselectedTabClassName
                    }`}
                    onClick={() => {
                      setIsFamilyTab(false);
                    }}
                  >
                    {t('mcontacts:contacts_tab_label')}
                  </p>
                </div>
                <div className="t-w-1/2 t-pl-1">
                  <p
                    className={`t-w-full button typo-eta t-uppercase t-px-3.5 ${
                      !!isFamilyTab
                        ? selectedTabClassName
                        : unselectedTabClassName
                    }`}
                    onClick={() => {
                      setIsFamilyTab(true);
                    }}
                  >
                    {t('mcontacts:family_accounts_tab_label')}
                  </p>
                </div>
              </div>
            )}

            {isFamilyTab ? (
              <MyFamilyAccounts
                backToMyContactsTab={() => {
                  setIsFamilyTab(false);
                }}
              />
            ) : (
              <MyContacts
                switchToMyFamilyTab={() => {
                  setIsFamilyTab(true);
                }}
              />
            )}
          </Col>
          <Col md={{ span: 12 }} xl={{ span: 5 }} className="t-m-0 t-p-0">
            {!!isPrimaryUserActive && <MyContactsReferralSection />}
            <div
              className={`card t-py-7 t-px-10 xl:t-ml-4 t-mr-2 ${
                isPrimaryUserActive ? 't-mt-4' : ''
              }`}
            >
              <h1 className="typo-alpha t-mb-6">
                {isFamilyTab
                  ? t('familyAccounts:how_does_this_work_info_section_title')
                  : t('mcontacts:how_does_this_work_title')}
              </h1>
              <AnswersAccordion
                page={infoAccordionTab}
                itemClassName="t-pb-2"
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default MyContactsPage;
