import React from 'react';

import {
  dateHelper,
  getFullAddress,
  optionsHelper,
  useGetCountries,
  config,
  useTranslation,
} from 'modules/common/helpers';

import {
  getQuestionnaire,
  QUESTIONNAIRE_KEYS,
} from 'modules/assistant/constants/questionnaires';
import { QUESTIONNAIRE_FORM_FIELD_TYPES as F } from 'modules/assistant/constants/questionnaires/questionnaireFormFieldTypes';

interface QuestionnaireFormEndProps {
  answerData: { [key: string]: string };
  questionnaire: QUESTIONNAIRE_KEYS;
  stepKey: string;
}

const QuestionnaireFormEnd = ({
  answerData,
  questionnaire,
  stepKey,
}: QuestionnaireFormEndProps) => {
  const { t } = useTranslation(['common', 'assistant', questionnaire]);
  const countries = useGetCountries();

  const QUESTIONNAIRE = getQuestionnaire(questionnaire, t);
  const stepData = QUESTIONNAIRE[stepKey]?.formElements || [];
  const getLabel = (type: string) =>
    stepData.find((item) => item.type === type)?.label || type;
  const hideLabel = (type: string) =>
    stepData.find((item) => item.type === type)?.labelHidden || false;

  const genderOptions = optionsHelper.getGenderOptions2();

  const getValue = (type) => {
    const value = answerData[type];
    switch (type) {
      case F.BIRTHDAY:
      case F.DATE:
        return dateHelper.convertDateFormat(
          value,
          config.format.uiDashboardDate
        );
      case F.ADDRESS:
        return getFullAddress(value, countries);
      case F.GENDER:
        return genderOptions.find((option) => option.value === value)?.label;
      case F.PHONE:
        return `+${value}`;
      default:
        return value;
    }
  };

  return (
    <div className="t-rounded t-p-3 t-w-full xl:t-w-2/3 t-bg-alpha-100">
      {Object.keys(answerData).map(
        (type) =>
          !!answerData[type] && (
            <React.Fragment key={type}>
              {!hideLabel(type) && (
                <p className="typo-zeta t-pb-1 t-mt-2">{getLabel(type)}</p>
              )}
              <p className="typo-delta t-p-2 t-bg-beta-50 t-rounded-sm t-text-alpha-600">
                {getValue(type)}
              </p>
            </React.Fragment>
          )
      )}
    </div>
  );
};

export default QuestionnaireFormEnd;
