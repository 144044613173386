import React from 'react';
import { format, subDays } from 'date-fns';
import Select from 'react-select';
import { enGB, de } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { useDispatch } from 'react-redux';

import { addAssetReminder } from 'store/actions/remindActions';
import { config, dateHelper, useTranslation } from 'modules/common/helpers';

import { Modal, DatePicker, Button } from 'modules/common/components';

import { LANGUAGES, LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';

import { ReactComponent as ReminderImg } from 'images/sign_document.svg';

import { NOTIFICATION_CODES as NC } from 'modules/notification/constants';
interface UploadReminderModalProps {
  onCancel: () => void;
  data: StoredData;
}

const daysInMonth = dateHelper.getDaysInMonth();
const twoDays = dateHelper.getFutureDate(2);
const oneWeek = dateHelper.getFutureDate(7);
const twoWeeks = dateHelper.getFutureDate(14);
const oneMonth = dateHelper.getFutureDate(daysInMonth);

export default function UploadReminderModal({
  onCancel,
  data,
}: UploadReminderModalProps) {
  const { t } = useTranslation(['plan', 'common']);
  const dispatch = useDispatch();

  const userLang =
    localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE) || LANGUAGES.DE;
  const lang = userLang === LANGUAGES.DE ? de : enGB;
  registerLocale('enGB', enGB);
  registerLocale('de', de);

  const [showCalendar, setShowCalendar] = React.useState(false);
  const [reminderToSubmit, setReminderToSubmit] = React.useState(twoDays);

  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value === 'custom') {
      handleReminderToggle();
    } else {
      setReminderToSubmit(selectedOption.value);
    }
  };

  const handleReminderSubmit = async () => {
    if (data?.assetId && !!reminderToSubmit) {
      await dispatch(
        addAssetReminder(
          reminderToSubmit,
          data.assetId,
          NC.UPLOAD_SIGN_DOCUMENT
        )
      );
    }
    onCancel();
  };

  const handleCustomDateSubmit = () => {
    setShowCalendar(false);
  };

  const handleCustomDateChange = (val) => {
    const formattedVal = format(val, 'MM-dd-yyyy');
    const formattedDate = format(
      new Date(formattedVal?.replace(/-/g, '/')),
      'yyyy-MM-dd'
    );
    setReminderToSubmit(formattedDate);
  };

  const handleReminderToggle = () => {
    setShowCalendar((prevState) => !prevState);
  };

  const makeReminderOptions = () => {
    return [
      { value: twoDays, label: t('assistant:reminder_option_two_days') },
      { value: oneWeek, label: t('assistant:reminder_option_week') },
      { value: twoWeeks, label: t('assistant:reminder_option_two_weeks') },
      { value: oneMonth, label: t('assistant:reminder_option_month') },
      { value: 'custom', label: t('assistant:reminder_option_custom') },
    ];
  };

  const reminderOptions = makeReminderOptions();

  const customDateValue = reminderToSubmit || new Date();
  const localDate = format(new Date(customDateValue), config.format.uiDate, {
    locale: lang,
  });

  return (
    <Modal show>
      <div className="t-flex t-justify-center">
        <ReminderImg className="t-h-44" />
      </div>
      <h1 className="typo-alpha t-my-6">
        {t('plan:upload_reminder_modal_title')}
      </h1>
      <p className="typo-delta t-mb-2.5">
        {t('plan:upload_reminder_modal_description')}
      </p>
      <div className="t-flex t-items-baseline t-flex-wrap">
        <p className="typo-delta t-mb-2.5 t-mr-2">
          {t('assistant:reminder_modal_select_label')}
        </p>
        <Select
          id="reminder"
          name="reminder"
          options={reminderOptions}
          defaultValue={reminderOptions[0]}
          onChange={handleSelectChange}
          className="Select t-w-1/2 xl:t-w-1/3"
          classNamePrefix="Select"
        />
      </div>
      {showCalendar && (
        <DatePicker
          date={customDateValue}
          minDate={subDays(new Date(), -1)}
          onSubmit={handleCustomDateSubmit}
          onChange={(val) => handleCustomDateChange(val)}
          onClose={handleReminderToggle}
          disabled={!reminderToSubmit}
          showTrash={false}
        />
      )}

      {!!localDate && (
        <p className="t-mt-4 t-text-beta-400">
          {t('common:reminder_info_text', {
            date: localDate,
          })}
        </p>
      )}
      <div className="t-flex t-justify-end t-mt-9 t-mb-14 sm:t-mb-0">
        <Button category="secondary" onClick={onCancel} className="t-mr-2.5">
          {t('plan:upload_reminder_modal_unsigned_btn')}
        </Button>
        <Button onClick={handleReminderSubmit}>
          {t('plan:upload_reminder_modal_remind_btn')}
        </Button>
      </div>
    </Modal>
  );
}
