import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { SiteLanguage, LogoutButton } from 'modules/common/components';
import { Col } from 'modules/bootstrap/components';
import {
  MobileMenu,
  PrimaryNavigation,
  SecondaryNavigation,
  UpgradeProfile,
} from 'modules/menu/components';
import { SubscriptionWidget } from 'modules/subscription/components';
import { NotificationButton } from 'modules/notification/components';
import { AccountMenuDropdown } from 'modules/familyAccount/components';

import { PAYMENT_STATUS } from 'modules/common/constants/enums';
import logo from 'images/logo.svg';
import logoNinebarc from 'images/logo_ninebarc.svg';

const marginTop = 80;

interface MenuProps {
  rightContentHeight: number;
}

function Menu({ rightContentHeight }: MenuProps) {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.current);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const isUnregisteredViewer = !user?.regular && user?.viewer;

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const firstNavRef = React.useRef<HTMLDivElement>(null);
  const secondNavRef = React.useRef<HTMLDivElement>(null);
  const [is2ndNavFixed, setIs2ndNavFixed] = React.useState(false);

  const getHeights = () => {
    if (
      firstNavRef?.current?.clientHeight &&
      secondNavRef?.current?.clientHeight
    ) {
      const totalMenuHeight =
        firstNavRef.current?.clientHeight +
        secondNavRef.current?.clientHeight +
        marginTop;

      // if total height of the menu is smaller than the screen (inner height)
      // + the current document scroll top (only when the right content greater than the total menu height),
      // => fix the 2nd nav to the bottom
      if (
        totalMenuHeight <
        window.innerHeight +
          (rightContentHeight > totalMenuHeight
            ? document.documentElement.scrollTop
            : 0)
      ) {
        setIs2ndNavFixed(true);
      } else {
        setIs2ndNavFixed(false); // else float it down
      }
    }
  };

  window.onresize = getHeights;
  window.onload = getHeights;
  window.onscroll = getHeights;

  React.useLayoutEffect(() => {
    getHeights();
  }, []);

  const unsubscribed = [PAYMENT_STATUS.TRIAL, PAYMENT_STATUS.CANCELED].includes(
    user.paymentStatus
  );

  return (
    <Col
      xl="2"
      className="t-mx-0 t-h-full t-w-full t-static xl:t-absolute xl:t-mt-20"
    >
      <div className="xl:t-min-h-screen">
        <div ref={firstNavRef}>
          <div className="SiteNavigation-logo t-outline-none">
            <button
              onClick={() => navigate('/')}
              disabled={!user.regular}
              className="t-outline-none"
            >
              <img
                className="SiteNavigation-logoPartner"
                src={logo}
                alt="Ninebarc"
              />
            </button>
          </div>
          <div className="t-flex t-justify-between">
            <MobileMenu
              toggleMenu={toggleMenu}
              showMobileMenu={showMobileMenu}
            />
            <div className="t-flex xl:t-hidden t-mt-3.5">
              <div className="t-mr-4">
                <NotificationButton />
              </div>
              <SiteLanguage additionalClass="t-pb-0 md:t-mr-5 xs:t-mr-2.5" />
              {!!user.regular ? (
                <AccountMenuDropdown />
              ) : (
                <LogoutButton className="t-self-start" />
              )}
            </div>
          </div>
          <nav
            className={`SiteNavigation-mobileNav ${
              showMobileMenu ? ' isActive' : ''
            }`}
          >
            <ul className="t-py-5">
              <PrimaryNavigation user={user} toggleMenu={toggleMenu} />
              {isUnregisteredViewer && <UpgradeProfile />}
            </ul>
          </nav>
          {!!unsubscribed && user.regular && !user.secondary && (
            <SubscriptionWidget />
          )}
        </div>
        <div
          className={`t-hidden xl:t-block xl:t-py-5 ${
            is2ndNavFixed ? 'xl:t-fixed xl:t-bottom-0' : ''
          }`}
          ref={secondNavRef}
        >
          <p className="SiteNavigation-bottomLogoText">
            <span className="text-main-sm">{t('common:powered')}</span>
          </p>
          <div className="SiteNavigation-bottomLogoText t-pb-2.5">
            <img
              className="SiteNavigation-bottomLogoImage"
              src={logoNinebarc}
              alt="Ninebarc"
            />
          </div>
          <SecondaryNavigation />
        </div>
      </div>
    </Col>
  );
}

export default Menu;
