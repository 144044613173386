import React, { useContext } from 'react';
import {
  Accordion,
  AccordionContext,
  useAccordionButton,
} from 'react-bootstrap';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconChevrone } from 'images/chevrone-down.svg';

type ContextAwareToggleProps = {
  eventKey: string;
  classBtnPrefix?: string;
  hideText?: boolean;
  onClick?: () => void;
};

const ContextAwareToggle = ({
  eventKey,
  classBtnPrefix,
  hideText = false,
  onClick,
}: ContextAwareToggleProps) => {
  const { t } = useTranslation();

  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    ...(onClick ? [onClick] : [])
  );

  const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;

  return (
    <Accordion.Button
      as="div"
      className={`button-secondary ${classBtnPrefix}`}
      onClick={decoratedOnClick}
      data-testid="AccordionButton"
    >
      {isCurrentEventKey ? (
        <div className="t-flex t-items-center">
          <div className={`t-mb-0.5 ${!hideText ? 't-mr-2' : 't-py-1'}`}>
            <IconChevrone className="t-transform t-rotate-180" />
          </div>
          {!hideText && t('hide')}
        </div>
      ) : (
        <>
          <div className="t-flex t-items-center">
            <div className={!hideText ? 't-mr-2' : 't-py-1'}>
              <IconChevrone />
            </div>
            {!hideText && t('show')}
          </div>
        </>
      )}
    </Accordion.Button>
  );
};

ContextAwareToggle.defaultProps = {
  classBtnPrefix: '',
};

export default ContextAwareToggle;
