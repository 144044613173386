import React from 'react';
import { find } from 'lodash';

import { FormControl } from 'modules/common/types';
import { SelectController } from 'modules/common/components';

interface GenderSelectProps {
  label: string;
  fieldErr: string;
  options: SelectOption[];
  defaultValue: string;
  control: FormControl;
  required?: boolean;
}

const GenderSelect = ({
  label,
  fieldErr,
  options,
  control,
  required,
  defaultValue,
}: GenderSelectProps) => {
  return (
    <div className="Form-group">
      <label
        className={`Form-label ${fieldErr ? 'isErrored' : ''}`}
        htmlFor="gender"
      >
        {label}
      </label>

      <SelectController
        id="gender"
        name="gender"
        options={options}
        control={control}
        rules={{ required: required }}
        defaultValue={
          find(options, (item) => item.value === defaultValue) as SelectOption
        }
        className={`Select ${fieldErr ? 'isErrored' : ''}`}
      />
      {fieldErr && (
        <div className="Form-alert">
          <span className="text-main-sm t-text-epsilon-600">{fieldErr}</span>
        </div>
      )}
    </div>
  );
};

export default GenderSelect;
