import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { groupBy, isEmpty } from 'lodash';

import { RootState } from 'store/reducers';
import { getSharedAssets } from 'store/actions/assetActions';
import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { BeneficiaryAssetsAccordion } from 'modules/afterDeathManagement/components';
import { KeepInTouch } from 'modules/emergencyAccess/components';

import { ReactComponent as IconPerson } from 'images/icon_person.svg';
import imgEmptyDocument from 'images/img-empty-documents.png';

export default function ImmediateAccessPage() {
  const { t } = useTranslation(['shareAssets']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfile: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const sharedAssetsByOwner = useSelector(
    (state: RootState) => state.immediateAssets
  );

  const [currentOwnerIdx, setCurrentOwnerIdx] = React.useState(0);

  const listOfOwners = Object.keys(sharedAssetsByOwner || {});

  React.useEffect(() => {
    fetchSharedAssets();
  }, []);

  const fetchSharedAssets = async () => {
    if (userProfile.viewer) {
      await dispatch(getSharedAssets());
    } else {
      navigate('/');
    }
  };

  const dataProcess = (sharedAssets: AssetDTO[]) => {
    const assetTypesUncategorized: Array<AssetTypeDTO> = sharedAssets.reduce(
      (typesArr, asset) => {
        const isAssetTypeExist = !isEmpty(
          typesArr.find((type) => type.id === asset.assetType!.id)
        );
        if (!isAssetTypeExist) {
          typesArr.push(asset.assetType!);
        }
        return typesArr;
      },
      [] as Array<AssetTypeDTO>
    );

    const assetsGroupedByType: Record<string, AssetDTO[]> = groupBy(
      sharedAssets,
      (asset) => asset.assetTypeLabel
    );
    const typeLabels = Object.keys(assetsGroupedByType);

    return { assetsGroupedByType, assetTypesUncategorized, typeLabels };
  };

  const renderAssetsByOwners = () => {
    const ownerAssets = sharedAssetsByOwner[listOfOwners[currentOwnerIdx]];
    const {
      assetsGroupedByType = {} as Record<string, AssetDTO[]>,
      assetTypesUncategorized = [] as AssetTypeDTO[],
      typeLabels = [] as string[],
    } = !isEmpty(ownerAssets) ? dataProcess(ownerAssets) : {};
    return (
      <div className="t-mt-14">
        <BeneficiaryAssetsAccordion
          typeLabels={typeLabels}
          assetsGroupedByType={assetsGroupedByType}
          assetTypesUncategorized={assetTypesUncategorized}
        />
      </div>
    );
  };

  return (
    <Row>
      <Col xl={{ span: 7, offset: 2 }}>
        {!isEmpty(sharedAssetsByOwner) && (
          <div className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7">
            <h1 className="typo-alpha">
              {t('shareAssets:share_assets_page_title')}
            </h1>
            <p className="typo-gamma t-mt-2">
              {t('shareAssets:share_assets_page_description')}
            </p>
            <div className="t-flex t-flex-wrap t-mt-6">
              {listOfOwners?.map((owner, idx) => {
                return (
                  <button
                    type="button"
                    key={owner}
                    className={`t-flex t-items-center t-p-2 t-mr-2 t-outline-none ${
                      idx === currentOwnerIdx
                        ? 't-border t-border-solid t-rounded t-text-beta-400'
                        : 't-text-alpha-600'
                    } `}
                    onClick={() => setCurrentOwnerIdx(idx)}
                  >
                    <IconPerson className="t-mr-2" />
                    <span
                      className={`t-uppercase typo-eta ${
                        idx === currentOwnerIdx
                          ? 't-text-beta-400'
                          : 't-text-alpha-600'
                      }`}
                    >
                      {`${sharedAssetsByOwner[owner][0]?.ownerName} ${sharedAssetsByOwner[owner][0]?.ownerSurname}`}
                    </span>
                  </button>
                );
              })}
            </div>
            {renderAssetsByOwners()}
          </div>
        )}
        {isEmpty(sharedAssetsByOwner) && (
          <div className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7">
            <div className="t-flex t-flex-col t-items-center">
              <img
                src={imgEmptyDocument}
                className="t-max-w-48"
                alt="Empty document"
              />
              <h1 className="typo-alpha lg:t-mt-12">
                {t('shareAssets:share_assets_empty_page_title')}
              </h1>
              <p className="typo-gamma t-mt-3">
                {t('shareAssets:share_assets_empty_page_description')}
              </p>
            </div>
          </div>
        )}
      </Col>
      {!userProfile.regular && userProfile.viewer && (
        <Col xl={{ span: 3 }} className="sm:t-py-0">
          <div className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7">
            <KeepInTouch />
          </div>
        </Col>
      )}
    </Row>
  );
}
