import React, { useContext } from 'react';
import {
  Accordion,
  AccordionContext,
  useAccordionButton,
} from 'react-bootstrap';
import DOMPurify from 'dompurify';

import { ReactComponent as IconChevroneDown } from 'images/v-shaped-down.svg';
import { ReactComponent as IconChevroneUp } from 'images/v-shaped-up.svg';

type ContextAwareToggleProps = {
  eventKey: string;
  onClick?: () => void;
  topic: Record<string, string>;
};

const TopicsContextAwareToggle = ({
  eventKey,
  onClick,
  topic,
}: ContextAwareToggleProps) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    ...(onClick ? [onClick] : [])
  );

  const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;

  return (
    <div
      className="card t-w-full t-p-6 t-mb-5 t-cursor-pointer "
      onClick={decoratedOnClick}
    >
      <Accordion.Button
        as="div"
        className="t-flex t-justify-between"
        key={eventKey}
      >
        <h3 className="t-font-secondary t-text-lg t-text-beta-700 t-font-semibold t-self-center">
          {topic?.title}
        </h3>

        <div className="t-outline-none t-p-1 t-flex t-items-center">
          {!isCurrentEventKey ? (
            <IconChevroneDown className="" />
          ) : (
            <IconChevroneUp className="" />
          )}
        </div>
      </Accordion.Button>
      <Accordion.Collapse eventKey={eventKey}>
        <p
          className="t-text-left t-mt-2"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(topic.content),
          }}
        />
      </Accordion.Collapse>
    </div>
  );
};

export default TopicsContextAwareToggle;
