import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { camelCase } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { getCardLink } from 'modules/assistant/helpers/questionnaireHelper';
import { useTranslation } from 'modules/common/helpers';

import { Button, InfoBadge } from 'modules/common/components';

import {
  QUESTIONNAIRE_KEYS,
  RECO_TYPE,
} from 'modules/assistant/constants/questionnaires';
import { CONTENT_QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/contentQuestionnaires';
import {
  QUESTIONNAIRES_TO_ASSET_TYPES,
  REDIRECT_ACTIONS,
} from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';
import { QUESTIONNAIRES_CTAS } from 'modules/assistant/constants/questionnaires/questionnairesCTAs';

import { ReactComponent as SuccessIcon } from 'images/success.svg';

export interface RecoCardProps {
  card: RecommendationDTO;
  type: RECO_TYPE;
}

const CompletedRecoCard = ({ card, type }: RecoCardProps) => {
  const { name, priority, relatedAssetId } = card;
  const { t } = useTranslation(['recommendations', 'common']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDigitalEstateReco =
    camelCase(card.name) === CONTENT_QUESTIONNAIRE_KEYS.DIGITAL_ESTATE;
  const { viewDetail } = QUESTIONNAIRES_CTAS[camelCase(name)];

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const bgColorMain =
    type === RECO_TYPE.CARE ? 't-bg-alpha-100' : 't-bg-delta-100';
  const textColorStatus =
    type === RECO_TYPE.CARE ? 't-text-alpha-700' : 't-text-delta-900';
  const colorButton =
    type !== RECO_TYPE.CARE ? 't-bg-delta-900 t-shadow-delta-900' : '';
  const viewDetailBtnClassName =
    type === RECO_TYPE.CARE ? 't-text-alpha-600' : 't-text-delta-900';

  const handleRedirection = async () => {
    const { path, category, assetType } =
      QUESTIONNAIRES_TO_ASSET_TYPES[camelCase(name)] || {};
    if (relatedAssetId) {
      await dispatch(
        temporaryFieldSaver({
          actionType: REDIRECT_ACTIONS.GO_TO_ASSET_CARD,
          assetId: relatedAssetId,
          category,
          assetType,
        })
      );
      navigate(path);
    } else if (isDigitalEstateReco) {
      navigate(path);
    } else {
      navigate(getCardLink(name!));
    }
  };

  const handleViewDetail = () => {
    const recoKey = camelCase(name);
    // handle redirect to content questionnaires of HCP, PD, ODC, and Will
    const {
      HEALTH_CARE_PROXY,
      PATIENT_DECREE,
      ORGAN_DONOR_CARD,
      WILL_GENERATOR,
      CUSTODY_ORDER,
    } = QUESTIONNAIRE_KEYS;
    const exceptionKeys: string[] = [
      HEALTH_CARE_PROXY,
      PATIENT_DECREE,
      ORGAN_DONOR_CARD,
      WILL_GENERATOR,
      CUSTODY_ORDER,
    ];
    if (exceptionKeys.includes(recoKey)) {
      navigate(getCardLink(`${recoKey}Content`));
    } else {
      navigate(getCardLink(name!));
    }
  };

  return (
    <div className="card t-flex t-mt-5 t-min-h-56 t-shadow-lg t-overflow-hidden">
      <div
        className={`t-w-full t-flex t-flex-col t-justify-between t-px-8 t-py-4 ${bgColorMain}`}
      >
        <div className="t-flex t-justify-between t-mr-3">
          <div className="t-hidden sm:t-inline">
            <InfoBadge type={type} priority={priority} />
          </div>

          <p
            className={`t-flex t-items-center t-flex-nowrap ${textColorStatus}`}
          >
            <div className="t-mr-1">
              <SuccessIcon className="t-w-4 t-h-4" />
            </div>
            <span>{t('recommendations:reco_card_task_completed')}</span>
          </p>
        </div>
        <h2 className="typo-beta xs:t-w-full sm:t-w-auto t-mt-3">
          {t(`${name}`)}
        </h2>

        <p className="t-mt-2">{t(`${name}_descr`)}</p>

        <div
          className={`t-flex ${
            viewDetail ? 't-justify-between' : 't-justify-end'
          } t-mt-5`}
        >
          {viewDetail && (
            <Button
              category="tertiary"
              className={viewDetailBtnClassName}
              onClick={handleViewDetail}
            >
              {t('assistant:detail')}
            </Button>
          )}
          <Button className={colorButton} onClick={handleRedirection}>
            {t('common:open')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompletedRecoCard;
