import React from 'react';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'modules/common/helpers';

import {
  Button,
  RadioCard,
  WarningTooltipRefresher,
} from 'modules/common/components';

import {
  SECURITY_KEY_EDIT_TYPE,
  SECURITY_SETTING_STEPS,
} from 'modules/securityKeyWizard/constants';

export interface SecurityKeyEditSelectionProps {
  onClose: () => void;
  onSelectEditType: (editType: string) => void;
  keyHolders: KeyHolder[];
  subscriptionExpired?: boolean;
}

const SecurityKeyEditSelection = ({
  onClose,
  onSelectEditType,
  keyHolders,
  subscriptionExpired,
}: SecurityKeyEditSelectionProps) => {
  const { t } = useTranslation(['common', 'auth', 'securityKey']);

  const keyHolderOptionDisabled = subscriptionExpired && !keyHolders.length;
  const { register, handleSubmit } = useForm({
    defaultValues: {
      selectSecurityEditType: keyHolderOptionDisabled
        ? SECURITY_KEY_EDIT_TYPE.CHANGE_SECURITY_KEY
        : SECURITY_KEY_EDIT_TYPE.MANAGE_KEY_HOLDERS,
    },
  });

  const onSubmit = (formData) => {
    onSelectEditType(
      formData[SECURITY_SETTING_STEPS.SELECT_SECURITY_EDIT_TYPE]
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="t-p-0"
      data-testid="SecurityKeyEditSelection"
    >
      <h1 className="title t-font-semibold t-pb-7">
        {t('securityKey:security_edit_selection_modal_title')}
      </h1>

      <p>{t('securityKey:security_edit_selection_modal_description1')}</p>
      <br />
      <p className="t-pb-5">
        {t('securityKey:security_edit_selection_modal_description2')}
        <a
          href="mailto:info@ninebarc.com"
          className="hover:t-underline t-text-delta-700"
        >
          info@ninebarc.com
        </a>
      </p>

      <div>
        <WarningTooltipRefresher id="set-key-holder">
          <RadioCard
            register={register}
            name={SECURITY_SETTING_STEPS.SELECT_SECURITY_EDIT_TYPE}
            value={SECURITY_KEY_EDIT_TYPE.MANAGE_KEY_HOLDERS}
            id={SECURITY_KEY_EDIT_TYPE.MANAGE_KEY_HOLDERS}
            className="t-px-8 t-py-6 t-mb-5"
            disabled={keyHolderOptionDisabled}
            dataTip={t('common:feature_use_subscription_expired_tooltip')}
            dataTipDisable={!keyHolderOptionDisabled}
            dataFor="set-key-holder"
          >
            <div className="t-flex t-flex-col sm:t-flex-row sm:t-justify-between">
              <div className="t-order-2 sm:t-order-1">
                <p
                  className={`typo-beta ${
                    keyHolderOptionDisabled ? 't-text-beta-400' : ''
                  }`}
                >
                  {!keyHolders.length
                    ? t('securityKey:set_key_holder_option_title')
                    : t('securityKey:manage_key_holders_choice_title')}
                </p>
                <p
                  className={`typo-epsilon t-mt-2 ${
                    keyHolderOptionDisabled
                      ? 't-text-beta-400'
                      : 't-text-beta-500'
                  }`}
                >
                  {!keyHolders.length
                    ? t('securityKey:set_key_holder_option_description')
                    : t('securityKey:manage_key_holders_choice_description')}
                </p>
              </div>
              {!keyHolders.length && (
                <div className="t-flex t-justify-end t-order-1 sm:t-order-2 sm:t-ml-2">
                  <span
                    className={`Character-label Character-label--accepted ${
                      keyHolderOptionDisabled ? 't-text-beta-400' : ''
                    }`}
                  >
                    {t('common:recommended')}
                  </span>
                </div>
              )}
            </div>
          </RadioCard>
        </WarningTooltipRefresher>

        <RadioCard
          register={register}
          name={SECURITY_SETTING_STEPS.SELECT_SECURITY_EDIT_TYPE}
          value={SECURITY_KEY_EDIT_TYPE.CHANGE_SECURITY_KEY}
          id={SECURITY_KEY_EDIT_TYPE.CHANGE_SECURITY_KEY}
          className="t-px-8 t-py-6 t-mb-5"
        >
          <div>
            <p className="typo-beta">
              {t('securityKey:change_security_key_choice_title')}
            </p>
            <p className="typo-epsilon t-text-beta-500 t-mt-2">
              {t('securityKey:change_security_key_choice_description')}
            </p>
          </div>
        </RadioCard>

        <RadioCard
          register={register}
          name={SECURITY_SETTING_STEPS.SELECT_SECURITY_EDIT_TYPE}
          value={SECURITY_KEY_EDIT_TYPE.DELETE_SECURITY_KEY}
          id={SECURITY_KEY_EDIT_TYPE.DELETE_SECURITY_KEY}
          className="t-px-8 t-py-6 t-mb-5"
        >
          <div>
            <p className="typo-beta">
              {t('securityKey:delete_security_key_choice_title')}
            </p>
            <p className="typo-epsilon t-text-beta-500 t-mt-2">
              {t('securityKey:delete_security_key_choice_description')}
            </p>
          </div>
        </RadioCard>
      </div>

      <div className="t-flex t-flex-col t-items-end sm:t-flex-row sm:t-justify-end t-pt-5 t-pb-16 sm:t-pb-0">
        <div>
          <Button
            testId="cancel"
            category="secondary"
            className="t-mb-3 sm:t-mb-0 sm:t-mr-2.5"
            onClick={onClose}
          >
            {t('common:cancel')}
          </Button>
        </div>
        <div>
          <Button type="submit">
            <span className="t-px-3">{t('save_and_continue')}</span>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SecurityKeyEditSelection;
