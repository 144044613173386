import React from 'react';
import { Accordion } from 'react-bootstrap';

import { FaqTab } from 'modules/contactUs/components';

const Faq = () => {
  return (
    <div className="u-pt-60 u-pt-md-0" data-testid="Faq">
      <Accordion defaultActiveKey="0" className="Accordion">
        <FaqTab tabNumber="1" faqCount={3} />
        <FaqTab tabNumber="2" faqCount={1} />
        <FaqTab tabNumber="3" faqCount={3} />
        <FaqTab tabNumber="5" faqCount={3} />
        <FaqTab tabNumber="4" faqCount={10} />
      </Accordion>
    </div>
  );
};

export default Faq;
