import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { getStartScreenContent } from 'modules/assistant/constants/questionnaires';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';

import checkMark from 'images/bigCheckmark.svg';

interface CheckItemProps {
  item: string;
}

const CheckItem = ({ item }: CheckItemProps) => (
  <div className="t-mb-10 t-flex-row t-flex t-items-center">
    <div className="t-mr-5">
      <img src={checkMark} alt="checkmark" className="t-min-w-14" />
    </div>
    <div>
      <p className="t-text-lg t-font-secondary">{item}</p>
    </div>
  </div>
);

export interface StartScreenProps {
  questionnaire: QUESTIONNAIRE_KEYS;
}

const StartScreen = ({ questionnaire }: StartScreenProps) => {
  const { t } = useTranslation([questionnaire]);
  const content = getStartScreenContent(questionnaire, t);
  return (
    <>
      <div>
        {content?.listItems.map((item) => (
          <div key={item} className="t-flex-col">
            <CheckItem item={item} />
          </div>
        ))}
      </div>
    </>
  );
};

export default StartScreen;
