import i18n from 'i18next';

import { getFamilyAccounts } from 'store/actions/familyAccountActions';
import dataService from 'modules/common/services/dataService';
import {
  LOAD_CONTACTS_LIST,
  ADD_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  UPDATE_MULTIPLE_CONTACTS,
  GET_BACKUP_CONTACTS,
} from 'store/types/contactActionTypes';
import { getSuccessMessage, reactToast } from 'modules/common/helpers';
import helper from 'store/actions/actionHelper';

export const getContactsListSuccess = (contacts: ContactDTO[]) =>
  helper.getAction(LOAD_CONTACTS_LIST, { contacts });
const addContactSuccess = (contactData: ContactDTO) =>
  helper.getAction(ADD_CONTACT, { contactData });
const editContactSuccess = (contactData: ContactDTO) =>
  helper.getAction(UPDATE_CONTACT, { contactData });
const deleteContactSuccess = (id: string) =>
  helper.getAction(DELETE_CONTACT, { id });
const updateMultipleContactsSuccess = (contactsData: ContactDTO[]) =>
  helper.getAction(UPDATE_MULTIPLE_CONTACTS, { contactsData });

const getBackupContactsSuccess = (backupContacts: ContactDTO[]) =>
  helper.getAction(GET_BACKUP_CONTACTS, { backupContacts });

export const getContactsList = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const contacts = await dataService.getContactsList();

    dispatch(getContactsListSuccess(contacts));
  }, actionOptions);
};

export const addContact = (
  contactData: ContactDTO,
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async () => {
    const newContact = await dataService.addContact(contactData, hideErrors);

    return newContact;
  }, actionOptions);
};

export const editContact = (
  contactData: ContactDTO,
  hideErrors?: boolean,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const alteredContact = await dataService.editContact(
      contactData,
      hideErrors
    );
    dispatch(editContactSuccess(alteredContact));
    reactToast.showMessage(i18n.t(`toastr:contact_updated_success`));

    return alteredContact;
  }, actionOptions);
};

export const deleteContact = (
  id: string,
  code = '',
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const response = await dataService.deleteContact(id, code);
    dispatch(deleteContactSuccess(id));
    reactToast.showMessage(getSuccessMessage(response.message));
    await dispatch(getFamilyAccounts());

    return true;
  }, actionOptions);
};

export const generateContactAccessDocument = (
  id: string,
  isNewContact = true,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const contactWithDocumentId =
      await dataService.generateContactAccessDocument(id);

    if (isNewContact) {
      reactToast.showMessage(i18n.t(`toastr:contact_added_success`));
      dispatch(addContactSuccess(contactWithDocumentId));
    } else {
      dispatch(editContactSuccess(contactWithDocumentId));
    }
    reactToast.showMessage(
      i18n.t(`toastr:access_successfully_document_generated`)
    );

    return contactWithDocumentId;
  }, actionOptions);
};

export const sendContactNotification = (
  id: string,
  letterId = '',
  paymentToken = '',
  contactBy = '',
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const updatedContactWithContactDate =
      await dataService.sendContactNotification(
        id,
        letterId,
        paymentToken,
        contactBy,
        hideErrors
      );

    if (updatedContactWithContactDate?.id) {
      reactToast.showMessage(i18n.t(`toastr:contact_has_been_contacted`));
      await dispatch(editContactSuccess(updatedContactWithContactDate));
    }

    return true;
  }, actionOptions);
};

export const generateMultipleContactAccessDocument = (
  contactsWithoutDocument: ContactDTO[],
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const updatedContacts: ContactDTO[] = [];
    for await (const contact of contactsWithoutDocument) {
      const { id } = contact;
      const updatedContact = await dataService.generateContactAccessDocument(
        id
      );
      updatedContacts.push(updatedContact);
    }

    await dispatch(updateMultipleContactsSuccess(updatedContacts));
    reactToast.showMessage(
      i18n.t(`toastr:access_successfully_document_generated`)
    );

    return true;
  }, actionOptions);
};

// Backup contacts
export const getBackupContacts = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const backupContacts = await dataService.getBackupContacts();

    dispatch(getBackupContactsSuccess(backupContacts));
  }, actionOptions);
};

export const createOrUpdateMultipleBackupContacts = (
  ids: string[],
  period: number,
  isUpdating,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    await Promise.all(
      ids.map((id) => dataService.updateBackupContact(id, period))
    );

    await dispatch(getBackupContacts());

    if (isUpdating) {
      reactToast.showMessage(
        i18n.t('toastr:backup_contacts_updated_successfully')
      );
    } else {
      reactToast.showMessage(
        i18n.t('toastr:backup_contacts_added_successfully')
      );
    }

    return true;
  }, actionOptions);
};

export const addBackupContact = (
  id: string,
  period,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const response = await dataService.updateBackupContact(id, period);

    await dispatch(getBackupContacts());

    reactToast.showMessage(i18n.t('toastr:backup_contact_added_successfully'));

    return response;
  }, actionOptions);
};

export const removeBackupContact = (
  id: string,
  showMessage = true,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    await dataService.removeBackupContact(id);

    await dispatch(getBackupContacts());

    if (showMessage) {
      reactToast.showMessage(
        i18n.t('toastr:backup_contact_removed_successfully')
      );
    }

    return true;
  }, actionOptions);
};

export const removeBackupContacts = (
  ids: string[],
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    await Promise.all(ids.map((id) => dataService.removeBackupContact(id)));

    await dispatch(getBackupContacts());

    reactToast.showMessage(
      i18n.t('toastr:backup_contact_feature_disabled_successfully')
    );

    return true;
  }, actionOptions);
};
