import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { camelCase } from 'lodash';

import { RootState } from 'store/reducers';
import { dataProcessingHelper } from 'modules/assistant/helpers';
import { useTranslation } from 'modules/common/helpers';

import { Button, Carousel } from 'modules/common/components';
import { NextStep } from 'modules/dashboard/components';

import {
  QUESTIONNAIRE_KEYS,
  QUESTIONNAIRE_PAGES,
} from 'modules/assistant/constants/questionnaires';
import { CONTENT_QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/contentQuestionnaires';
import {
  NEXT_STEPS,
  NEXT_STEPS_CODES,
  SECONDARY_USER_UNAVAILABLE_STEPS,
} from 'modules/dashboard/constants/nextSteps';

import stepsSectionImg from 'images/steps-section-leaves.png';

const questionnaireKeys = Object.values(QUESTIONNAIRE_KEYS) as string[];
const contentQuestionnaireKeys = Object.values(
  CONTENT_QUESTIONNAIRE_KEYS
) as string[];

const carouselBreakPoints = [
  {
    width: 1,
    itemsToShow: 1,
    itemsToScroll: 1,
    initialActiveIndex: 0,
    pagination: false,
  },
  {
    width: 600,
    itemsToShow: 1.3,
    itemsToScroll: 1,
    initialActiveIndex: 0,
    pagination: false,
  },
];

export default function NextStepsWidget() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const nextSteps = useSelector((state: RootState) => state.nextSteps.list);

  const contactsData: ContactDTO[] = useSelector(
    (state: RootState) => state.contacts.list
  );
  const backupContacts = contactsData.filter((contact) => !!contact.backup);
  const hideBackupContactStep = !!backupContacts.length || !contactsData.length;

  const initialAssessmentCompleted = currentUser.initialAssessment;

  const handleRedirectToInitialAssessment = () => {
    navigate(QUESTIONNAIRE_PAGES[QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT].path);
  };

  const checkIfFromAssistant = (recommendation: string) => {
    return [...questionnaireKeys, ...contentQuestionnaireKeys].includes(
      camelCase(recommendation)
    );
  };

  const filteredNextSteps = nextSteps
    ?.filter((item) => {
      const isFromAssistant = checkIfFromAssistant(item.recommendation);
      if (!NEXT_STEPS[item.recommendation]?.title && !isFromAssistant) {
        return false;
      }

      if (
        isFromAssistant &&
        !dataProcessingHelper.isAvailable(item.recommendation)
      ) {
        return false;
      }

      // make sure to only display the step if the no contact exists
      if (
        item.recommendation === NEXT_STEPS_CODES.ADD_CONTACT &&
        !!contactsData?.length
      ) {
        return false;
      }

      // make sure to only display the step if the profile is not complete
      if (
        item.recommendation === NEXT_STEPS_CODES.COMPLETE_PROFILE &&
        currentUser.profile === 1
      ) {
        return false;
      }
      // make sure to display the are-you-still-there-contact step
      if (
        hideBackupContactStep &&
        item.recommendation === NEXT_STEPS_CODES.ADD_BACKUP_CONTACT
      ) {
        return false;
      }

      // unavailable for secondary user - family account
      if (
        !!currentUser.secondary &&
        SECONDARY_USER_UNAVAILABLE_STEPS.includes(item.recommendation)
      ) {
        return false;
      }

      return true;
    })
    .sort((item1, item2) => Number(item2.priority) - Number(item1.priority));

  return (
    <div
      className="card t-rounded t-bg-gamma-400 t-overflow-hidden t-mb-3 xl:t-mb-0"
      data-tour="my-first-step"
    >
      <div className="t-rounded-t t-bg-gradient-to-r t-from-alpha-700 t-to-alpha-600 t-relative">
        <h1 className="typo-alpha t-pl-7 t-py-6 t-text-gamma-400">
          {t('dashboard:next_steps_widget_title')}
        </h1>
        <img
          src={stepsSectionImg}
          alt="steps section background"
          className="t-absolute t-top-0 t-right-0 t-h-full"
        />
      </div>
      <div>
        {initialAssessmentCompleted ? (
          !filteredNextSteps?.length ? (
            <div className="t-flex t-flex-col t-justify-center t-py-5 t-px-7 sm:t-px-8 t-h-28">
              <h2 className="typo-beta">
                {t('dashboard:next_steps_widget_complete_all_steps_title')}
              </h2>
              <p className="typo-epsilon t-text-beta-500 t-mb-5 sm:t-mb-0 t-mt-3">
                {t('dashboard:next_steps_widget_complete_all_steps_desc')}
              </p>
            </div>
          ) : (
            <Carousel breakPoints={carouselBreakPoints}>
              {filteredNextSteps.map((nextStep) => {
                const isFromAssistant = checkIfFromAssistant(
                  nextStep.recommendation
                );
                return (
                  <NextStep
                    nextStep={nextStep}
                    isFromAssistant={isFromAssistant}
                    key={nextStep.recommendation}
                    user={currentUser}
                  />
                );
              })}
            </Carousel>
          )
        ) : (
          <div className="t-flex t-flex-col t-justify-center t-py-5 t-px-7 sm:t-px-8">
            <h2 className="typo-beta">
              {t('dashboard:next_steps_widget_initial_assessment_title')}
            </h2>
            <div className="t-mt-3 t-flex t-flex-col sm:t-flex-row t-items-center">
              <p className="typo-epsilon t-text-beta-500 t-mb-5 sm:t-mb-0">
                {t('dashboard:next_steps_widget_initial_assessment_desc')}
              </p>
              <div className="t-flex-grow sm:t-ml-9 t-w-full sm:t-w-auto">
                <Button
                  className="t-whitespace-nowrap t-w-full t-tracking-widest"
                  onClick={handleRedirectToInitialAssessment}
                >
                  {t('dashboard:next_steps_widget_initial_assessment_button')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
