import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { RootState } from 'store/reducers';
import { navigateToStep } from 'store/actions/questionnaireActions';
import { getCurrentUser } from 'store/actions/userActions';
import { getRecommendations } from 'store/actions/recommendationsActions';
import { getAssetsList } from 'store/actions/assetActions';
import { fetchAssistantProgress } from 'store/actions/assistantActions';
import { getNextSteps } from 'store/actions/nextStepActions';
import { setModal } from 'store/actions/modalActions';
import { getPotentialContactDeletionStep } from 'modules/assistant/helpers/questionnaireHelper';
import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';
import { Link } from 'modules/elements/components';
import { FinalizeStepSwitcher } from 'modules/assistant/components/Questionnaire';

import { MODALS } from 'modules/common/constants';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';
import { STEP_KEYS } from 'modules/assistant/constants/questionnaires/patientDecree';
import { COUNTRIES } from 'modules/common/constants/enums';

export interface QuestionnaireProps {
  disableNextButton: boolean;
  isFirstStep: boolean;
  isEndStep?: boolean;
  prevStepId: STEP_KEYS | null;
  handleSubmission: () => void;
  handleGoToPrevQuestion: () => void;
  handleResetAllAnswers: () => void;
  questionnaire: QUESTIONNAIRE_KEYS;
  isEndScreenAvailable?: boolean;
  questionnaireData: QuestionnaireDTO;
}

const PrevNextButtons = ({
  disableNextButton,
  isFirstStep,
  isEndStep,
  prevStepId,
  handleSubmission,
  handleGoToPrevQuestion,
  handleResetAllAnswers,
  questionnaire,
  questionnaireData,
  isEndScreenAvailable,
}: QuestionnaireProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'assistant', questionnaire]);

  const [loading, setLoading] = React.useState(false);
  const [isUserResidenceAbroadStep, setIsUserResidenceAbroadStep] =
    React.useState(false);
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [isFinalizeStep, setIsFinalizeStep] = React.useState(false);

  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const contacts = useSelector((state: RootState) => state.contacts.list);

  const isDownloadAllowed = user.verified && user?.profile === 1;
  const isUserEmailVerified = user.verified;
  const isUserResidenceAbroad = user.country !== COUNTRIES.DE;

  useEffect(() => {
    dispatch(getAssetsList());
    dispatch(fetchAssistantProgress());
  }, []);

  useEffect(() => {
    if (isEndStep) ReactTooltip.rebuild();
  });

  const handleInitialAssessmentSubmit = async () => {
    await handleSubmission();
    await dispatch(getRecommendations());
    await dispatch(getCurrentUser());
    await dispatch(getNextSteps());
  };

  const handleResetAll = () => {
    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: t('assistant:reset_all_warning_modal_title'),
          secondaryButtonText: t('assistant:reset_all'),
          primaryButtonText: t('common:cancel'),
          cancelAction: handleResetAllAnswers,
        },
      })
    );
  };

  const handleMissingContacts = (step: string) => {
    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: t('assistant:contact_deleted_modal_title'),
          primaryButtonText: t('assistant:contact_deleted_modal_button'),
          hasCross: true,
          successAction: () => {
            dispatch(navigateToStep(questionnaire, step));
          },
        },
      })
    );
  };

  const handleFinalizeStep = (germanLawWarningPassed?: boolean) => {
    if (isUserResidenceAbroad && !germanLawWarningPassed) {
      setIsUserResidenceAbroadStep(true);
      return;
    }

    if (!isDownloadAllowed) {
      setShowProfileForm(true);
    } else {
      const missingContactStep = getPotentialContactDeletionStep(
        questionnaire,
        questionnaireData,
        contacts
      );
      if (missingContactStep) {
        handleMissingContacts(missingContactStep);
      } else {
        setIsFinalizeStep(true);
      }
    }
  };

  const handleFinalizeStepCancel = () => {
    setIsFinalizeStep(false);
  };

  const isInitialAssessment =
    questionnaire === QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT;
  const showPreviousButton =
    !isFirstStep && ((prevStepId && !isEndStep) || isInitialAssessment);

  return (
    <>
      <FinalizeStepSwitcher
        questionnaire={questionnaire}
        isFinalizeStep={isFinalizeStep}
        handleFinalizeStep={handleFinalizeStep}
        handleFinalizeStepCancel={handleFinalizeStepCancel}
        isUserResidenceAbroadStep={isUserResidenceAbroadStep}
        setUserResidenceAbroadStep={(warning: boolean) => {
          setIsUserResidenceAbroadStep(warning);
        }}
        showProfileForm={showProfileForm}
        setShowProfileForm={(show: boolean) => {
          setShowProfileForm(show);
        }}
        loading={loading}
        setLoading={(loading: boolean) => {
          setLoading(loading);
        }}
      />

      <div className="t-flex xs:t-flex-wrap t-justify-end xs:t-mx-5 xl:t-mx-10 t-my-12">
        {showPreviousButton ? (
          <div className="sm:t-mr-auto xs:t-mb-5 sm:t-mb-0">
            <Button category="secondary" onClick={handleGoToPrevQuestion}>
              {t('common:previous')}
            </Button>
          </div>
        ) : isEndStep && !isInitialAssessment ? (
          <div className="sm:t-mr-auto xs:t-mb-5 sm:t-mb-0">
            <Button onClick={handleResetAll} category="secondary">
              {t('assistant:reset_all')}
            </Button>
          </div>
        ) : (
          <Link
            to="/assistant"
            className="button-secondary sm:t-mr-auto xs:t-mb-5 sm:t-mb-0"
          >
            {t('common:back')}
          </Link>
        )}

        {isInitialAssessment && isEndStep && (
          <Button
            onClick={handleInitialAssessmentSubmit}
            disabled={disableNextButton}
            loading={loading}
          >
            {t('common:save_and_continue')}
          </Button>
        )}

        {isEndStep && !isInitialAssessment ? (
          <div
            data-tip={
              isUserEmailVerified ? '' : t('assistant:email_not_verified')
            }
          >
            <Button
              className="t-ml-5"
              onClick={() => handleFinalizeStep()}
              disabled={!isUserEmailVerified}
            >
              {t('assistant:prevNextButtons_finalize_btn')}
            </Button>
          </div>
        ) : (
          !isEndStep && (
            <Button
              onClick={handleSubmission}
              disabled={disableNextButton}
              className="xs:t-mb-5 sm:t-mb-0 xs:t-ml-5"
            >
              {isFirstStep && !isInitialAssessment ? (
                <>
                  <span className="xs:t-hidden xl:t-inline">
                    {t(`${questionnaire}:start_screen_button`)}
                  </span>
                  <span className="xs:t-inline xl:t-hidden">
                    {t('common:start')}
                  </span>
                </>
              ) : (
                <p>{t('common:next')}</p>
              )}
            </Button>
          )
        )}

        {!isEndStep && isEndScreenAvailable && (
          <div className="t-self-start">
            <Button
              className="t-ml-5"
              onClick={() => {
                handleSubmission();
                dispatch(navigateToStep(questionnaire, STEP_KEYS.STEP_END));
              }}
              disabled={disableNextButton}
            >
              {t('assistant:go_to_end_screen')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default PrevNextButtons;
