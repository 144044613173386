import i18n from 'i18next';

import dataService from 'modules/common/services/dataService';

import { reactToast } from 'modules/common/helpers';
import helper from 'store/actions/actionHelper';

export const reportDeath = (
  data: FormData,
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await dataService.reportDeath(data, hideErrors);

    reactToast.showMessage(i18n.t('toastr:report_incident_success'));

    return response;
  }, actionOptions);
};

export const validateKeyHolder = (
  data: { token: string; key: string },
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await dataService.validateKeyHolder(data, hideErrors);

    return response;
  }, actionOptions);
};
