import React from 'react';
import { useDispatch } from 'react-redux';

import { updateCurrentUserSuccess } from 'store/actions/userActions';
import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

import { PAYMENT_STATUS } from 'modules/common/constants/enums';
import { ReactComponent as SuccessIcon } from 'images/success.svg';

interface StepSuccessProps {
  handleCloseModal: () => void;
}

export default function StepSuccess({ handleCloseModal }: StepSuccessProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['payment', 'common']);

  const closeModal = () => {
    dispatch(
      updateCurrentUserSuccess({
        paymentStatus: PAYMENT_STATUS.ACTIVE,
        checkoutDone: true,
      })
    );
    handleCloseModal();
  };

  return (
    <div data-testid="StepSuccess">
      <SuccessIcon className="t-text-alpha-600 t-mb-6 t-mt-32 t-block t-mx-auto" />
      <h1 className="typo-alpha t-text-center">
        {t('payment:step_success_title')}
      </h1>
      <Button className="t-block t-mx-auto t-mt-6" onClick={closeModal}>
        {t('common:close')}
      </Button>
    </div>
  );
}
