import React, { ReactNode } from 'react';

type AnswersAccordionAnswerProps = {
  children: string | ReactNode;
};

const AnswersAccordionAnswer = ({ children }: AnswersAccordionAnswerProps) => {
  return <p className="AnswersAccordion-answer">{children}</p>;
};

export default AnswersAccordionAnswer;
