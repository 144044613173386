import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { CONTENT_QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/contentQuestionnaires';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';

import { ReactComponent as Anna } from 'images/anna-profile-pic.svg';

export interface SummaryBoxProps {
  questionnaire: CONTENT_QUESTIONNAIRE_KEYS | QUESTIONNAIRE_KEYS;
}

const SummaryBox = ({ questionnaire }: SummaryBoxProps) => {
  const { t } = useTranslation([questionnaire, 'common', 'assistant']);
  return (
    <div className="card t-bg-alpha-100 t-flex xs:t-flex-col md:t-flex-row xs:t-pl-0 md:t-pl-7 t-p-7">
      <div>
        <Anna className="t-text-center t-mx-auto md:t-w-full xs:t-w-1/4" />
        <p className="t-text-beta-500 t-text-center xs:t-mt-1 xs:t-mb-3 md:t-mb-0 md:t-mt-3.5">
          {t('common:anna_from_ninebarc')}
        </p>
      </div>
      <div className="t-ml-11">
        <h2 className="t-text-beta-700 t-text-lg t-mb-3.5 t-font-secondary t-font-semibold">
          {t('assistant:content_questionnaire_summary_box_title')}
        </h2>
        <ul className="t-list-disc t-text-beta-700">
          <li className="t-mb-2.5">{t(`${questionnaire}:summary_bullet_1`)}</li>
          <li className="t-mb-2.5">{t(`${questionnaire}:summary_bullet_2`)}</li>
          <li>{t(`${questionnaire}:summary_bullet_3`)}</li>
        </ul>
      </div>
    </div>
  );
};

export default SummaryBox;
