import axios from 'axios';
import _ from 'lodash';
import i18n from 'i18next';

import {
  LOCAL_STORAGE_VARS,
  ROUTER_STATE_STATUS,
} from 'modules/common/constants/enums';
import authService from 'modules/auth/services/authService';
import config from 'modules/common/helpers/configHelper';
import { reactToast } from 'modules/common/helpers';
import routerHistory from 'routerHistory';
import { CustomResponseException } from 'modules/common/helpers/CustomResponseException';
import { updateAuthTokens } from 'store/actions/tokenActions';

export default {
  get: httpGet,
  post: httpPost,
  patch: httpPatch,
  put: httpPut,
  delete: httpDelete,
};

function httpGet(
  url: string,
  queryParams,
  headerModifier = {},
  hideErrors = false
) {
  const headers = getDefaultRequestOptions(headerModifier);
  const axiosData = axios.get(`${url}${getQueryString(queryParams)}`, headers);

  return processRequest(axiosData, hideErrors);
}

function httpPost<T = any>(
  url: string,
  data,
  headerModifier = {},
  hideErrors = false
): Promise<T> {
  const headers = getDefaultRequestOptions(headerModifier);
  const reqData =
    headers.headers['Content-Type'] === 'multipart/form-data'
      ? data
      : JSON.stringify(data);
  const request = axios.post(url, reqData, headers);

  return processRequest(request, hideErrors);
}

function httpPut(url: string, data, headerModifier = {}, hideErrors = false) {
  const headers = getDefaultRequestOptions(headerModifier);
  const reqData =
    headers.headers['Content-Type'] === 'multipart/form-data'
      ? data
      : JSON.stringify(data);
  const request = axios.put(url, reqData, headers);

  return processRequest(request, hideErrors);
}

function httpPatch(url: string, data, hideErrors = false) {
  const request = axios.patch(
    url,
    JSON.stringify(data),
    getDefaultRequestOptions()
  );

  return processRequest(request, hideErrors);
}

async function httpDelete(url: string, data, hideErrors = false) {
  const defaultOptions = getDefaultRequestOptions();
  const options = { ...defaultOptions, data: { ...data } };

  const request = axios.delete(url, options);

  return processRequest(request, hideErrors);
}

async function processRequest(axiosRequest, hideErrors = false) {
  let response;
  try {
    response = await axiosRequest;

    // if OK return
    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }

    const { status, statusText } = response;

    if (
      status === 401 &&
      !_.endsWith(window.location.href, '/login') &&
      !_.endsWith(window.location.href, '/access') &&
      !_.endsWith(window.location.href, '/zugang')
    ) {
      await authService.logout();
      updateAuthTokens('', '');
      localStorage.setItem(LOCAL_STORAGE_VARS.IS_ALLOWED_TOKEN, '');
      if (_.endsWith(window.location.href, '/beneficiary')) {
        routerHistory.push('/access');
      } else {
        routerHistory.push('/login');
      }
      reactToast.showError(i18n.t('auth:session_is_expired_error_message'));
      return null;
    }

    // if subscription expired and payment required
    if (status === 402) {
      if (!_.endsWith(routerHistory.location.pathname, '/profile')) {
        routerHistory.push('/profile', {
          status: ROUTER_STATE_STATUS.PAYMENT_REQUIRED,
        });
      }
    }

    if (status >= 400 && status <= 599) {
      const errorCode =
        response.data instanceof Blob
          ? JSON.parse(await response.data.text())?.errorCode
          : response.data?.errorCode;

      if (errorCode) {
        throw new CustomResponseException(statusText, status, errorCode);
      }
    }

    throw new Error(`Invalid HTTP response status ${status} (${statusText})`);
  } catch (err) {
    if (!hideErrors && response.data.errorCode) {
      reactToast.showError(i18n.t(`errors:${response.data.errorCode}`));
    }
    throw err;
  }
}

function getQueryString(params) {
  if (!params || !Object.keys(params).length) return '';

  const esc = encodeURIComponent;

  let query = '?';

  query += Object.keys(params)
    .map((k) => `${esc(k)}=${esc(params[k])}`)
    .join('&');

  return query;
}

function getDefaultRequestOptions(modifier: Record<string, any> = {}) {
  const headerModifier = modifier.headers ? { ...modifier.headers } : {};

  const reqOptions = {
    ...modifier,
    headers: {
      pragma: 'no-cache',
      'Content-Type': 'application/json',
      Authorization: getAuthHeader(),
      'Remote-Host': config.remoteHost,
      ...headerModifier,
    },
    validateStatus: () => true,
    credentials: 'same-origin',
  };

  return reqOptions;
}

function getAuthHeader() {
  const jwt = authService.getToken();

  return `Bearer ${jwt}`;
}
