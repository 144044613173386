import React from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { useTranslation } from 'modules/common/helpers';

import { Row, Col } from 'modules/bootstrap/components';
import {
  TextInputRef,
  AdditionalInfoBox,
  Button,
} from 'modules/common/components';
import { WarningBox } from 'modules/securityKeyWizard/components';

type Inputs = {
  oldPassphrase?: string;
  passphrase: string;
  passphraseConfirm?: string;
};

export interface SecurityKeyFormProps {
  onSubmit: (formData: Record<string, string>) => Promise<void>;
  onCancel: () => void;
  editing?: boolean;
  deleting?: boolean;
  setting?: boolean;
}

const SecurityKeyForm = ({
  onSubmit,
  onCancel,
  editing,
  deleting,
  setting,
}: SecurityKeyFormProps) => {
  const { t } = useTranslation(['securityKey', 'common']);
  const {
    register,
    handleSubmit,
    watch,

    formState: { errors },
  } = useForm<Inputs>();

  const [loading, setLoading] = React.useState(false);

  let errPassphraseConfirm;
  if (errors.passphraseConfirm) {
    errPassphraseConfirm = (
      errors.passphraseConfirm.type === 'required'
        ? `${t('common:field_required')}`
        : `${t('securityKey:security_key_not_match_error')}`
    ) as string;
  }

  const handleSecurityKeySubmit = async (formData) => {
    setLoading(true);
    await onSubmit(formData);
    setLoading(false);
  };

  return (
    <div data-testid="SecurityKeyForm">
      <h1 className="typo-alpha t-pb-4">
        {deleting
          ? t('securityKey:security_key_delete_modal_title')
          : t('securityKey:security_key_title')}
      </h1>

      <p className="Modal-description">
        {deleting
          ? t('securityKey:security_key_delete_modal_description')
          : t('securityKey:security_key_description')}
      </p>

      {!editing && !deleting && <WarningBox />}

      <form onSubmit={handleSubmit(handleSecurityKeySubmit)}>
        <fieldset disabled={loading}>
          {(editing || deleting) && (
            <Row className="t-pb-2.5">
              <Col>
                <TextInputRef
                  {...register('oldPassphrase', { required: true })}
                  label={t('securityKey:label_enter_old_security_key')}
                  type="text"
                  autoComplete={false}
                  error={
                    errors.oldPassphrase &&
                    (t('common:field_required') as string)
                  }
                />
              </Col>
            </Row>
          )}
          {!deleting && (
            <>
              <Row className="t-pb-2.5">
                <Col>
                  <TextInputRef
                    {...register('passphrase', { required: true })}
                    label={
                      !editing
                        ? t('securityKey:label_enter_security_key')
                        : t('securityKey:label_enter_new_security_key')
                    }
                    type="password"
                    autoComplete={false}
                    error={
                      errors.passphrase &&
                      (t('common:field_required') as string)
                    }
                  />
                </Col>
              </Row>

              <Row className="t-pb-6">
                <Col>
                  <TextInputRef
                    {...register('passphraseConfirm', {
                      required: true,
                      validate: (value) => value === watch('passphrase'),
                    })}
                    label={
                      !editing
                        ? t('securityKey:label_confirm_security_key')
                        : t('securityKey:label_confirm_new_security_key')
                    }
                    type="password"
                    autoComplete={false}
                    error={errPassphraseConfirm}
                  />
                </Col>
              </Row>
            </>
          )}
        </fieldset>

        {!deleting && (
          <AdditionalInfoBox>
            <p>{t('securityKey:security_key_additional_info')}</p>
          </AdditionalInfoBox>
        )}
        <div className="t-mb-6" />

        <div className="t-flex t-flex-col t-items-end sm:t-flex-row sm:t-justify-end t-pb-16 sm:t-pb-0">
          <div>
            <Button
              testId="cancel"
              category="secondary"
              className="t-mb-3 sm:t-mb-0 sm:t-mr-2.5"
              onClick={onCancel}
              disabled={loading}
            >
              {!setting && !editing
                ? t('common:cancel')
                : t('securityKey:button_change_security_settings')}
            </Button>
          </div>
          <div>
            <Button type="submit" disabled={!isEmpty(errors)} loading={loading}>
              {t('common:submit_and_continue')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SecurityKeyForm;
