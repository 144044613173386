import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

import {
  config,
  dateHelper,
  optionsHelper,
  useTranslation,
} from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import {
  Button,
  TextInputRef,
  BirthdayInput,
  SelectController,
} from 'modules/common/components';

import { WILL_GENERATOR_ANSWER_KEYS } from 'modules/assistant/constants/questionnaires/will';

type BequestInputs = Omit<BequestDTO, 'from' | 'gender'> & {
  from?: SelectOption;
  gender: SelectOption;
};

interface BequestFormProps {
  bequestItem?: BequestDTO;
  onCancel: () => void;
  onSuccess: (newBequest: BequestDTO) => void;
  isSpousalWill?: boolean;
}

function BequestForm(props: BequestFormProps) {
  const { onCancel, onSuccess, isSpousalWill } = props;
  const bequestItem = (props.bequestItem || {}) as BequestDTO;

  const { t } = useTranslation(['auth', 'common', 'help', 'will']);
  const {
    register,
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<BequestInputs>();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const isNewBequestItem = !bequestItem.id;
  // end default option process

  const onSubmit = (formData: BequestInputs) => {
    const from = isSpousalWill
      ? (formData?.from?.value as string) || ''
      : WILL_GENERATOR_ANSWER_KEYS.INDIVIDUAL;

    const gender = (formData?.gender?.value as string) || '';

    const submitData = {
      ...formData,
      birthday:
        formData.birthday &&
        dateHelper.convertDateFormat(
          formData.birthday,
          config.format.serverDate
        ),
      from,
      gender,
    } as BequestDTO;

    onSuccess(submitData);
    onCancel();
  };

  const contactsOptions = optionsHelper.getBequestFromOptions();
  const defaultContactsOptions = contactsOptions.find(
    (item) => item.value === bequestItem.from
  );
  const genderOptions = optionsHelper.getGenderOptions();
  const defaultGenderOptions =
    genderOptions.find((item) => item.value === bequestItem.gender) ||
    genderOptions[0];

  const textFieldRequired = t('common:field_required');

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="Form">
      {!isNewBequestItem && (
        <input
          type="hidden"
          id="id"
          {...register('id')}
          value={bequestItem.id}
        />
      )}

      <h1 className="typo-alpha t-mb-2">{t('will:bequest_form_title')}</h1>

      <Row>
        <Col>
          <TextInputRef
            {...register('description', { required: true })}
            label={t('will:bequest_description_label')}
            type="text"
            error={errors.description && textFieldRequired}
            defaultValue={bequestItem?.description}
          />
        </Col>
      </Row>

      {isSpousalWill && (
        <Row>
          <Col>
            <div className="Form-group">
              <label
                className={`Form-label ${errors.from ? 'isErrored' : ''}`}
                htmlFor="subtype"
              >
                {t('will:bequest_from_label')}
              </label>

              <SelectController
                id="from"
                name="from"
                options={contactsOptions}
                control={control}
                defaultValue={defaultContactsOptions}
                className={`Select ${errors.from ? 'isErrored' : ''}`}
                rules={{ required: true }}
              />
              {errors.from && (
                <div className="Form-alert">
                  <span className="text-main-sm t-text-epsilon-600">
                    {textFieldRequired}
                  </span>
                </div>
              )}
            </div>
          </Col>
        </Row>
      )}

      <p className="typo-beta t-text-delta-700 t-mb-2">
        {t('will:bequest_receiver')}
      </p>

      <Row>
        <Col xs={6} xl={3}>
          <div className="Form-group">
            <label className="Form-label" htmlFor="gender">
              {t('auth:title')}
            </label>

            <SelectController
              id="gender"
              name="gender"
              options={genderOptions}
              control={control}
              defaultValue={defaultGenderOptions}
            />
          </div>
        </Col>
        <Col xl={{ span: 4 }}>
          <TextInputRef
            {...register('name', { required: true })}
            label={t('auth:name_f')}
            type="text"
            autoComplete={false}
            defaultValue={bequestItem.name}
            error={errors.name && textFieldRequired}
          />
        </Col>

        <Col xl={{ span: 5 }}>
          <TextInputRef
            {...register('surname', { required: true })}
            label={t('auth:name_l')}
            type="text"
            autoComplete={false}
            defaultValue={bequestItem.surname}
            error={errors.surname && textFieldRequired}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={{ span: 6 }}>
          <BirthdayInput
            control={control}
            error={errors?.birthday?.message || ''}
            defaultValue={dateHelper.convertDateFormat(
              bequestItem.birthday || '',
              config.format.uiDate
            )}
            isRequired={true}
          />
        </Col>

        <Col xl={{ span: 6 }}>
          <TextInputRef
            {...register('placeOfBirth', { required: true })}
            label={t('auth:birthplace_label')}
            type="text"
            defaultValue={bequestItem.placeOfBirth}
            error={errors.placeOfBirth && textFieldRequired}
          />
        </Col>
      </Row>

      <div className="t-flex t-flex-wrap t-justify-end">
        <Button
          category="secondary"
          className="t-whitespace-nowrap t-self-end"
          onClick={onCancel}
        >
          {t('common:cancel')}
        </Button>
        <Button type="submit" className="t-ml-5 xs:t-mt-5">
          {t('common:save_and_continue')}
        </Button>
      </div>
    </form>
  );
}

export default BequestForm;
