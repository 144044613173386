import React, { useState } from 'react';

import { ReactComponent as ArrowDown } from 'images/arrow-down.svg';
import { ReactComponent as Cross } from 'images/cross.svg';

export interface QAToggleProps {
  title: string;
  content: string;
}

export default function QAToggle({ title, content }: QAToggleProps) {
  const [isOpen, setToggle] = useState(false);

  return (
    <>
      {!isOpen ? (
        <button
          className="t-bg-delta-100 t-w-full t-flex t-justify-between t-px-7 t-py-6 t-rounded t-outline-none"
          type="button"
          onClick={() => setToggle((oldState) => !oldState)}
        >
          <p className="t-text-lg t-font-secondary t-text-left">{title}</p>
          <ArrowDown className="t-text-delta-700 t-self-center t-min-w-5" />
        </button>
      ) : (
        <div className="card t-w-full t-flex t-justify-between t-px-7 t-py-6">
          <div>
            <p className="t-text-lg t-font-secondary t-mb-3 t-text-left">
              {title}
            </p>
            <p className="t-text-beta-500 t-text-left">{content}</p>
          </div>
          <button
            type="button"
            onClick={() => setToggle((oldState) => !oldState)}
            className="t-self-start t-pb-2 t-pl-2 t-outline-none"
          >
            <Cross className="t-text-beta-500 t-min-w-5" />
          </button>
        </div>
      )}
    </>
  );
}
