import React from 'react';

import { useTranslation } from 'modules/common/helpers';

interface ToastUndoProps {
  onUndo: () => void;
  text: string;
  textClassName?: string;
}

const ToastUndo = ({ onUndo, text, textClassName }: ToastUndoProps) => {
  const { t } = useTranslation(['plan']);

  return (
    <div className="t-rounded t-flex t-justify-between t-px-3">
      <p className={textClassName || 'typo-epsilon t-text-beta-700'}>{text}</p>
      <button
        className="t-font-semibold t-text-beta-700 t-underline t-outline-none t-whitespace-nowrap t-ml-2"
        onClick={onUndo}
      >
        {t('common:undo')}
      </button>
    </div>
  );
};

export default ToastUndo;
