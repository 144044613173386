import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';

import { RootState } from 'store/reducers';
import { setIsShowSubscriptionWidget } from 'store/actions/layoutActions';
import useGetLocalizationForDate from 'localization/utils/useGetLocalizationForDate';
import {
  dateHelper,
  config,
  checkDateInThePast,
  useTranslation,
} from 'modules/common/helpers';

import { ManageSubscriptionButton } from 'modules/subscription/components';
import { Button } from 'modules/common/components';

import { PAYMENT_STATUS } from 'modules/common/constants/enums';

import iconCross from 'images/cross.svg';

const SubscriptionWidget = () => {
  const { t } = useTranslation(['subscription', 'referral']);
  const dispatch = useDispatch();
  const locale = useGetLocalizationForDate();

  const currentUser = useSelector((state: RootState) => state.user.current);

  const { subscriptionEnd, referralOrigin } = currentUser;

  const isWidgetVisible = useSelector(
    (state: RootState) => state.layout.isShowSubscriptionWidget
  );
  const unparsedSubscriptionEnd = new Date(subscriptionEnd);
  const correctedSubscriptionEnd = format(
    unparsedSubscriptionEnd.setDate(unparsedSubscriptionEnd.getDate() - 1),
    config.format.serverDate
  );
  const canceledSubscription =
    currentUser.paymentStatus === PAYMENT_STATUS.CANCELED;

  const parsedDate = dateHelper.convertDateFormat(
    correctedSubscriptionEnd,
    config.format.uiDate
  );
  const formattedDate = format(
    parse(parsedDate, config.format.uiDate, new Date()),
    config.format.uiDashboardDate,
    {
      locale,
    }
  );

  const isDateInThePast = checkDateInThePast(correctedSubscriptionEnd);
  const titleText = referralOrigin
    ? t('referral:menu_referral_benefit_title')
    : isDateInThePast
    ? t('trial_period_inactive_title')
    : canceledSubscription
    ? t('subscription:subscription_canceled_title')
    : t('trial_period_active_title');

  const descriptionText = referralOrigin
    ? t('referral:menu_referral_benefit_description')
    : isDateInThePast
    ? t('trial_period_ended')
    : canceledSubscription
    ? t('subscription:subscription_canceled_description')
    : t('trial_period_description', {
        date: formattedDate,
      });

  if (!isWidgetVisible) return null;

  return (
    <div className="card t-bg-gamma-400 t-pt-5 t-px-4 t-pb-4 t-mb-4">
      <div className="t-flex t-justify-between">
        <h2 className="Typography u-weight-600 u-pb-10">{titleText}</h2>
        <Button
          category="secondary"
          className="t-ml-2.5 xl:t-hidden"
          onClick={() => {
            dispatch(setIsShowSubscriptionWidget(false));
          }}
        >
          <img src={iconCross} alt={t('common:close')} />
        </Button>
      </div>
      <p className="u-pb-15">{descriptionText}</p>

      <div className="d-flex justify-content-center align-items-center">
        <ManageSubscriptionButton extraClassName="flex-grow-1" />
      </div>
    </div>
  );
};

export default SubscriptionWidget;
