import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { AssetAssignmentsSelector } from 'store/selectors/selectors';
import { handleAssetsSearching } from 'store/actions/assetSearchActions';
import { useTranslation } from 'modules/common/helpers';

import {
  PLAN_STEPS,
  PLAN_STEP_KEYS,
} from 'modules/estatePlan/constants/constants';

import plant2 from 'images/plant2.svg';
import { ReactComponent as IconIsReady } from 'images/icon-check-square.svg';
import { ReactComponent as IconIsAlmostReady } from 'images/icon-check-with-dot.svg';

interface LinkIconProps {
  planStep: PlanStep;
}

const LinkIcon = ({ planStep }: LinkIconProps) => {
  const planProgress: PlanProgressDTO = useSelector(
    (state: RootState) => state.plan.progress
  );
  const assetAssignments = useSelector((state: RootState) =>
    AssetAssignmentsSelector(state)
  );
  const currentPlanProgress = planProgress[planStep.dtoKey];

  const linkClass = 't-absolute t-left-2.5 t-bottom-2';

  switch (true) {
    case currentPlanProgress === 1:
      return <IconIsReady className={`${linkClass} t-text-alpha-600`} />;
    case (currentPlanProgress > 0 && currentPlanProgress < 1) ||
      assetAssignments[planStep.dtoKey] === false:
      return <IconIsAlmostReady className={`${linkClass} t-text-alpha-600`} />;
    default:
      return null;
  }
};

function EstatePlanNavigation() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'plan']);
  const location = useLocation();
  const curPath = location.pathname;

  const getActive = (planStepPath) =>
    (curPath === '/plan' &&
      planStepPath === PLAN_STEPS[PLAN_STEP_KEYS.INTRODUCTION].path) ||
    curPath.includes(planStepPath)
      ? 'isActive'
      : '';

  const handleNavigationClick = () => {
    dispatch(handleAssetsSearching({} as AssetsSearchDTO));
  };

  return (
    <div className="Card Card--bottom u-bg-black-squeeze">
      <div className="StepsNavigation t-pr-2.5">
        <ul className="StepsNavigation-list">
          {Object.values(PLAN_STEPS).map((step, idx) => (
            <li className="StepsNavigation-item" key={step.dtoKey}>
              <Link
                className={`StepsNavigation-link t-rounded ${getActive(
                  step.path
                )}`}
                to={step.path}
                onClick={handleNavigationClick}
              >
                <LinkIcon planStep={step} />
                <span className="t-text-delta-700 t-text-md t-block t-leading-md">
                  {idx + 1}.&nbsp;{t(`plan:step_${step.dtoKey}`)}
                </span>
              </Link>
            </li>
          ))}
        </ul>
        <img
          className="StepsNavigation-plant"
          src={plant2}
          alt=""
          role="presentation"
        />
      </div>
    </div>
  );
}

export default EstatePlanNavigation;
