import i18n from 'i18next';
import {
  INHERITANCE_RELATIONSHIP,
  INHERITANCE_TYPE,
  RELATIONSHIP,
  PAYMENT_FREQUENCY,
  PAYMENT_METHOD,
} from 'modules/common/constants/enums';

export default {
  getGenderOptions,
  getGenderOptions2,
  getContactMethodOptions,
  getRelationshipOptions,
  getInheritanceTypeOptions,
  getInheritanceRelationOptions,
  getInheritanceChildrenAgeOptions,
  getPaymentMethodOptions,
  getPaymentFrequencyOptions,
  getInactivityPeriodOptions,
  getBequestFromOptions,
};

function getGenderOptions(): SelectOption[] {
  return [
    { value: 'male', label: i18n.t('common:male') },
    { value: 'female', label: i18n.t('common:female') },
  ];
}

function getGenderOptions2(): SelectOption[] {
  return [
    { value: 'male', label: i18n.t('common:gender_male') },
    { value: 'female', label: i18n.t('common:gender_female') },
  ];
}

function getContactMethodOptions(): SelectOption[] {
  return [
    { value: 'true', label: i18n.t('common:contact_method_now_and_after') },
    { value: 'false', label: i18n.t('common:contact_method_after') },
  ];
}

function getRelationshipOptions(): SelectOption[] {
  return Object.values(RELATIONSHIP).map((rel) => ({
    value: rel,
    label: i18n.t(`mcontacts:rel_${rel}`),
  }));
}

function getPaymentMethodOptions(): SelectOption[] {
  return Object.values(PAYMENT_METHOD).map((rel) => ({
    value: rel,
    label: i18n.t(`plan:on_going_costs_method_${rel}`),
  }));
}

function getPaymentFrequencyOptions(): SelectOption[] {
  return Object.values(PAYMENT_FREQUENCY).map((rel) => ({
    value: rel,
    label: i18n.t(`plan:on_going_costs_frequency_${rel}`),
  }));
}

function getInheritanceTypeOptions(): SelectOption[] {
  return Object.values(INHERITANCE_TYPE).map((type) => ({
    value: type,
    label: i18n.t(`inheritanceTaxCal:type_${type}`),
  }));
}

function getInheritanceRelationOptions(): SelectOption[] {
  return Object.values(INHERITANCE_RELATIONSHIP).map((rel) => ({
    value: rel,
    label: i18n.t(`inheritanceTaxCal:rel_${rel}`),
  }));
}

// the value is the age of the child within the range
function getInheritanceChildrenAgeOptions(): SelectOption[] {
  return [
    { value: '3', label: i18n.t('inheritanceTaxCal:children_age_up_to_5') },
    {
      value: '8',
      label: i18n.t('inheritanceTaxCal:children_age_from_6_to_10'),
    },
    {
      value: '13',
      label: i18n.t('inheritanceTaxCal:children_age_from_11_to_15'),
    },
    {
      value: '18',
      label: i18n.t('inheritanceTaxCal:children_age_from_16_to_20'),
    },
    {
      value: '23',
      label: i18n.t('inheritanceTaxCal:children_age_from_21_to_27'),
    },
    { value: '50', label: i18n.t('inheritanceTaxCal:children_age_above_27') },
  ];
}

function getInactivityPeriodOptions(): SelectOption[] {
  return [
    {
      value: '3',
      label: i18n.t('profile:backup_contact_inactivity_period_3_months'),
    },
    {
      value: '6',
      label: i18n.t('profile:backup_contact_inactivity_period_6_months'),
    },
    {
      value: '12',
      label: i18n.t('profile:backup_contact_inactivity_period_12_months'),
    },
  ];
}

function getBequestFromOptions(): SelectOption[] {
  return [
    { value: 'partner', label: i18n.t('common:partner') },
    { value: 'myself', label: i18n.t('common:myself') },
  ];
}
