import { ASSET_CATEGORIES } from 'modules/estatePlan/constants';
import {
  AFTER_LIFE_ASSET_TYPES,
  FINANCIAL_ASSET_TYPES,
  HEALTH_CARE_ASSET_TYPES,
  INSURANCE_ASSET_TYPES,
  LEGAL_ASSET_TYPES,
} from 'modules/estatePlan/constants/assetTypeKeys';
import {
  PLAN_STEPS,
  PLAN_STEP_KEYS,
} from 'modules/estatePlan/constants/constants';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';
import { CONTENT_QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/contentQuestionnaires';
import {
  LONG_TERM_ASSETS,
  POWER_OF_ATTORNEY,
} from 'modules/estatePlan/constants/subtypes';

// Questionnaires that relate to estate plan
export const QUESTIONNAIRES_TO_ASSET_TYPES = {
  [QUESTIONNAIRE_KEYS.PATIENT_DECREE]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.HEALTH].path,
    category: ASSET_CATEGORIES.HEALTH,
    assetType: HEALTH_CARE_ASSET_TYPES.PATIENT_DECREE,
  },
  [QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.HEALTH].path,
    category: ASSET_CATEGORIES.HEALTH,
    assetType: HEALTH_CARE_ASSET_TYPES.ORGAN_DONOR_CARD,
  },
  [QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.HEALTH].path,
    category: ASSET_CATEGORIES.HEALTH,
    assetType: HEALTH_CARE_ASSET_TYPES.HEALTH_CARE_PROXY,
  },
  [QUESTIONNAIRE_KEYS.WILL_GENERATOR]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.LEGAL].path,
    category: ASSET_CATEGORIES.LEGAL,
    assetType: LEGAL_ASSET_TYPES.WILL,
  },
  [QUESTIONNAIRE_KEYS.PET_WATCHING_AGREEMENT]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.LEGAL].path,
    category: ASSET_CATEGORIES.LEGAL,
    assetType: LEGAL_ASSET_TYPES.PET_WATCHING_AGREEMENT,
  },
  [QUESTIONNAIRE_KEYS.CUSTODY_ORDER]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.LEGAL].path,
    category: ASSET_CATEGORIES.LEGAL,
    assetType: LEGAL_ASSET_TYPES.POWER_OF_ATTORNEY,
    subtype: POWER_OF_ATTORNEY.CUSTODY_ORDER,
  },
  [QUESTIONNAIRE_KEYS.FUNERAL_PROVISION]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.AFTERLIFE].path,
    category: ASSET_CATEGORIES.AFTERLIFE,
    assetType: AFTER_LIFE_ASSET_TYPES.FUNERAL,
  },
  [QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.LEGAL].path,
    category: ASSET_CATEGORIES.LEGAL,
    assetType: LEGAL_ASSET_TYPES.POWER_OF_ATTORNEY,
    subtype: POWER_OF_ATTORNEY.BANK,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.EMERGENCY_PASS]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.HEALTH].path,
    category: ASSET_CATEGORIES.HEALTH,
    assetType: HEALTH_CARE_ASSET_TYPES.EMERGENCY_PASS,
  },
  // No asset type - all asset types in digital estate
  [CONTENT_QUESTIONNAIRE_KEYS.DIGITAL_ESTATE]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.DIGITAL].path,
    category: ASSET_CATEGORIES.DIGITAL,
    assetType: '',
  },
  [CONTENT_QUESTIONNAIRE_KEYS.POSTAL_POWER_OF_ATTORNEY]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.LEGAL].path,
    category: ASSET_CATEGORIES.LEGAL,
    assetType: LEGAL_ASSET_TYPES.FORWARDING_ORDER,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CARE_OCCUPATIONAL_DISABILITY]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.INSURANCE].path,
    category: ASSET_CATEGORIES.INSURANCE,
    assetType: INSURANCE_ASSET_TYPES.OCCUPATIONAL_DISABILITY_INSURANCE,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_CARE]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.INSURANCE].path,
    category: ASSET_CATEGORIES.INSURANCE,
    assetType: INSURANCE_ASSET_TYPES.CARE_INSURANCE,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CARE_DIRECTIVE]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.LEGAL].path,
    category: ASSET_CATEGORIES.LEGAL,
    assetType: LEGAL_ASSET_TYPES.POWER_OF_ATTORNEY,
    subtype: POWER_OF_ATTORNEY.CARE_DIRECTIVE,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CARE]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.INSURANCE].path,
    category: ASSET_CATEGORIES.INSURANCE,
    assetType: '',
  },
  [CONTENT_QUESTIONNAIRE_KEYS.FUNERAL_PROVISION]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.AFTERLIFE].path,
    category: ASSET_CATEGORIES.AFTERLIFE,
    assetType: AFTER_LIFE_ASSET_TYPES.FUNERAL,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.VEHICLE_DOCUMENTS]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.LEGAL].path,
    category: ASSET_CATEGORIES.LEGAL,
    assetType: LEGAL_ASSET_TYPES.VEHICLE_DOCUMENT,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.PURCHASE_LOANS_RIGHTS]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.FINANCIAL].path,
    category: ASSET_CATEGORIES.FINANCIAL,
    assetType: FINANCIAL_ASSET_TYPES.LONG_TERM_ASSETS,
    subtype: LONG_TERM_ASSETS.PROPERTY,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.LANDLORD_RENTAL_AGREEMENT]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.LEGAL].path,
    category: ASSET_CATEGORIES.LEGAL,
    assetType: LEGAL_ASSET_TYPES.RENTAL_CONTACT,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CUSTODY_ORDER]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.LEGAL].path,
    category: ASSET_CATEGORIES.LEGAL,
    assetType: LEGAL_ASSET_TYPES.POWER_OF_ATTORNEY,
    subtype: POWER_OF_ATTORNEY.CUSTODY_ORDER,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.PATIENT_DECREE]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.HEALTH].path,
    category: ASSET_CATEGORIES.HEALTH,
    assetType: HEALTH_CARE_ASSET_TYPES.PATIENT_DECREE,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.HEALTH].path,
    category: ASSET_CATEGORIES.HEALTH,
    assetType: HEALTH_CARE_ASSET_TYPES.ORGAN_DONOR_CARD,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.HEALTH].path,
    category: ASSET_CATEGORIES.HEALTH,
    assetType: HEALTH_CARE_ASSET_TYPES.HEALTH_CARE_PROXY,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.WILL]: {
    path: PLAN_STEPS[PLAN_STEP_KEYS.LEGAL].path,
    category: ASSET_CATEGORIES.LEGAL,
    assetType: LEGAL_ASSET_TYPES.WILL,
  },
};

export const REDIRECT_ACTIONS = {
  GO_TO_ASSET_CARD: 'redirect/assetCard',
  UPLOAD_DOCUMENT: 'redirect/upload',
  GO_TO_TAX_CALCULATOR: 'redirect/taxCalculator',
  HIGHLIGHT_ASSIGN_TO_FIELD: 'redirect/highlightAssignToField',
  NOTIFY_CONTACT: 'redirect/notifyContact',
  GO_TO_CONTACT_CARD: 'redirect/contactCard',
  GO_TO_FAMILY_ACCOUNT_CARD: 'redirect/familyAccountCard',
};
