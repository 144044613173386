import React from 'react';
import styled, { css } from 'styled-components';

export interface EndScreenAnswerElementProps {
  isSelected?: boolean;
  children: React.ReactNode | React.ReactNode[];
  isNoHover?: boolean;
}

const EndScreenAnswerElement = ({
  children,
  isSelected,
  isNoHover,
}: EndScreenAnswerElementProps) => {
  return (
    <StyledChoiceAnswer
      className={`${!isSelected ? 'xs:t-hidden sm:t-flex' : ''}`}
      selected={!!isSelected}
      isNoHover={isNoHover}
    >
      <StyledChoiceAnswerText selected={!!isSelected}>
        {children}
      </StyledChoiceAnswerText>
    </StyledChoiceAnswer>
  );
};

export default EndScreenAnswerElement;

const selectedStyle = css`
  color: var(--color-alpha-600);
`;
const selectedBorderStyle = css`
  border: 1px solid var(--color-alpha-600);
`;

const StyledChoiceAnswer = styled.div`
  align-items: center;
  background: var(--color-alpha-100);
  box-sizing: border-box;
  border-radius: var(--border-radius-sm);
  display: flex;
  margin: 8px 10px 0px 0px;
  ${(props) => props.selected && selectedStyle};
  ${(props) => props.selected && selectedBorderStyle};
  :hover {
    cursor: ${({ isNoHover }) => (isNoHover ? 'inherit' : 'pointer')};
  }
`;

const StyledChoiceAnswerText = styled.span`
  font-family: var(--font-family-primary);
  font-size: var(--font-size-md);
  line-height: var(--line-height-sm);
  color: var(--color-beta-500);
  margin: 10px 23px;
  ${(props) => props.selected && selectedStyle};
`;
