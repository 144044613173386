import React from 'react';
import { values } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { FormControl } from 'modules/common/types';
import { useTranslation } from 'modules/common/helpers';

import { ACTIONS } from 'modules/estatePlan/constants/subtypes';
import { DIGITAL_SUBTYPES } from 'modules/estatePlan/constants/enums';
import { SelectController } from 'modules/common/components';
import { ASSET_CATEGORIES } from 'modules/estatePlan/constants';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';

export interface ActionSelectProps {
  error: string;
  label: string;
  control: FormControl;
  field: AssetFieldDTO;
  asset: AssetDTO;
  assetCategory: string;
  assetType: AssetTypeDTO;
}

export default function ActionSelect({
  error,
  label,
  control,
  field,
  asset,
  assetCategory,
  assetType,
}: ActionSelectProps) {
  const { t } = useTranslation(['common', 'subtypes']);

  const getActionOptions = (fieldAction) => {
    const formatOptions = (action) =>
      values(action).map((item) => ({
        value: item,
        label: t(`subtypes:${item}`),
      }));
    switch (fieldAction) {
      case DIGITAL_SUBTYPES.ACTION:
        return formatOptions(ACTIONS);
      default:
        return null;
    }
  };

  const getDefaultOption = (
    options: SelectOption[] | null
  ): SelectOption | undefined =>
    options?.find((item) => item.value === asset?.action);

  const subtypeOptions = getActionOptions(field.subtype);
  const defaultSubtypeOptions = getDefaultOption(subtypeOptions) || '';
  const chosenLabel = assetType.custom
    ? t(`subtypes:${assetCategory}_${assetType.name}_label`)
    : label;

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div className="t-mb-3.5">
      <label
        htmlFor="subtype"
        className={`t-inline-block t-mb-2 t-text-beta-500 ${
          error ? 't-text-epsilon-600' : ''
        }`}
      >
        {chosenLabel}
        {assetCategory === ASSET_CATEGORIES.DIGITAL && (
          <IconInfo
            role="presentation"
            data-tip={t(`plan:action_account_remaining_tooltip`)}
            className="t-inline t-text-delta-700 t-ml-2"
          />
        )}
      </label>
      <SelectController
        id="action"
        name="action"
        options={subtypeOptions || ([] as SelectOption[])}
        control={control}
        defaultValue={defaultSubtypeOptions}
        className={`Select ${error ? 'isErrored' : ''}`}
        rules={{ required: field.required }}
      />
      {error && (
        <div className="t-pt-0.5">
          <span className="text-main-sm t-text-epsilon-600">
            {`${t(`subtypes:${assetCategory}_${field.type}_label`)} ${t(
              'common:required'
            )}`}
          </span>
        </div>
      )}
    </div>
  );
}
