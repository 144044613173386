import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { RootState } from 'store/reducers';
import {
  temporaryFieldSaver,
  temporaryFieldError,
} from 'store/actions/temporaryFieldSaverActions';
import { useTranslation } from 'modules/common/helpers';

import { HeirDistributionCard } from 'modules/assistant/components/Answers/HeirDistribution';
import { P } from 'modules/elements/components';

const SLIDER_STEP = 1;

export interface HeirDistributionProps {
  previousAnswer?: Record<string, any>[];
}

const Styles = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const HeirDistribution = ({ previousAnswer }: HeirDistributionProps) => {
  const { t } = useTranslation(['will']);
  const dispatch = useDispatch();
  const [maxDistributions, setMaxDistributions] = useState<any>({});
  const [heirSliderValues, setHeirSliderValues] = useState<any[]>([]);

  const tempField = useSelector((state: RootState) => state.temporaryField);
  const tempFieldDistributionHeirs = tempField.value;

  const heirs = useSelector((state: RootState) => state.heirs.list) || [];

  // initialize the temporaryField & the state (which controls the sliders)
  // with either the previous slider value (if step has been entered before) or with 0
  useEffect(() => {
    const initialArray: Record<string, string | number>[] = [];
    heirs.forEach((heir) => {
      const previousHeirAnswer = previousAnswer?.find(
        (prevAns) => prevAns?.heirId === heir.id
      );
      if (previousHeirAnswer) {
        initialArray.push(previousHeirAnswer);
      } else {
        initialArray.push({ heirId: heir.id, distribution: 0 });
      }
    });
    setHeirSliderValues(initialArray);
    dispatch(temporaryFieldSaver(initialArray));
    initializeMaxDistribution(initialArray);
  }, [dispatch, heirs, previousAnswer]);

  // 3. total distribution must equal exactly 100% in order to proceed
  useEffect(() => {
    let total = 0;
    heirSliderValues.forEach((h) => {
      total += h.distribution;
    });
    if (total === 100) {
      dispatch(temporaryFieldError(false));
    } else {
      dispatch(temporaryFieldError(true));
    }
  }, [maxDistributions]);

  const initializeMaxDistribution = (initialHeirs) => {
    // we initialise the maxDistributions with the same value as the previous distribution of the heir or with 100
    // because if there is a previous distribution, maxDistribution has to have the same value as the previous distribut
    // because the total of all, previously in the questionnaire saved, distributions has to be exactly 100.
    // -> therefore, maxDistribution can't be bigger or smaller than the distribution of the
    // previously, in the questionnaire saved, distribution value
    // if the total of all distributions is 0, the maxDistribution needs to be 100
    // if the total of all distributions is bigger than 0, it means that the total of all distributions is 100,
    // and therefore the maxDistributions should be exactly the same as the distributions
    const initialMaxDistributions = {};
    let total = 0;
    initialHeirs.forEach((h) => {
      total += h.distribution;
    });
    initialHeirs.forEach((h) => {
      if (total === 100) {
        initialMaxDistributions[h.heirId] = h.distribution;
      } else {
        initialMaxDistributions[h.heirId] = 100;
      }
    });
    setMaxDistributions(initialMaxDistributions);
  };

  const handleAfterChange = (val, heirId) => {
    changeMaxDistributions(val, heirId);
    updateTemporaryField(val, heirId);
  };

  const changeMaxDistributions = (val, heirId) => {
    // when changing a slider, we need to adjust the maxDistribution of the other sliders
    // depending on if the user slides up or down
    let previousHeirValue = tempFieldDistributionHeirs?.find(
      (h) => h.heirId === heirId
    )?.distribution;
    if (!Array.isArray(previousHeirValue))
      previousHeirValue = [previousHeirValue];
    // get difference between previousValue & val
    const difference = val - previousHeirValue[0];
    // then subtract or add this difference to both maxDistribution
    const newMaxDistributions = { ...maxDistributions };
    heirSliderValues.forEach((h) => {
      // modify only other maxDistributions
      if (h.heirId !== heirId) {
        const oldMax = newMaxDistributions[h.heirId];
        if (difference > 0) {
          // slider is going up
          newMaxDistributions[h.heirId] = oldMax - difference;
        } else if (difference === 0) {
          newMaxDistributions[h.heirId] = maxDistributions[h.heirId];
        } else {
          // slider is going down
          newMaxDistributions[h.heirId] = oldMax - difference;
        }
      }
    });
    setMaxDistributions(newMaxDistributions);
  };

  const updateTemporaryField = (val, heirId) => {
    // make copy of current temporary field
    const newTempFieldDistHeirs = [...tempFieldDistributionHeirs];
    // find currently being changed heir
    const tempFieldDistHeirToChangeIdx = newTempFieldDistHeirs.findIndex(
      (h) => h.heirId === heirId
    );
    // change distribution of that heir in copied temporary fields
    newTempFieldDistHeirs[tempFieldDistHeirToChangeIdx].distribution = val;
    // change temporary field with that copy
    dispatch(temporaryFieldSaver(newTempFieldDistHeirs));
  };

  const handleChange = (val, heirId) => {
    // only change value if its not bigger than maxDistributions allowed
    if (!(val > maxDistributions[heirId])) {
      // make copy of current state
      const newState = [...heirSliderValues];
      // find currently being changed heir
      const currentlyBeingChangedHeirIdx = newState.findIndex(
        (heir) => heir.heirId === heirId
      );
      // change distribution of that heir in copied state
      newState[currentlyBeingChangedHeirIdx] = {
        ...newState[currentlyBeingChangedHeirIdx],
        distribution: val,
      };
      // set new state with the modified copy
      setHeirSliderValues(newState);
    }
  };

  const isInitialized =
    tempFieldDistributionHeirs &&
    tempFieldDistributionHeirs[0]?.heirId &&
    heirSliderValues &&
    heirSliderValues[0]?.heirId;

  const getStillAssignable = () => {
    let totalValuesAssigned = 0;
    heirSliderValues.forEach((heir) => {
      totalValuesAssigned += heir.distribution;
    });
    return 100 - totalValuesAssigned;
  };

  return (
    <>
      {isInitialized &&
        heirs.map((heir) => {
          const heirSliderValue = heirSliderValues.find(
            (h) => h.heirId === heir.id
          );
          return (
            <Styles key={heir.id}>
              <HeirDistributionCard
                heir={heir}
                step={SLIDER_STEP}
                maxDistribution={maxDistributions[heir.id]}
                onAfterChange={(val) => handleAfterChange(val, heir.id)}
                onChange={(val) => handleChange(val, heir.id)}
                value={heirSliderValue?.distribution}
              />
            </Styles>
          );
        })}
      <StyledStillAssignable>
        <P>
          {t('will:stillAssignable')}
          <span>{getStillAssignable()}%</span>
        </P>
      </StyledStillAssignable>
    </>
  );
};

const StyledStillAssignable = styled.div`
  margin-left: auto;
  margin-top: 25px;
  span {
    margin-left: 10px;
    background-color: var(--color-gamma-400);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-alpha-150);
    color: var(--color-alpha-600);
    font-weight: var(--font-weight-semibold);
    padding: 5px 10px;
  }
`;

export default HeirDistribution;
