import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

type TwoAuthEnableSuccessModalProps = {
  onSuccess: () => void;
};

const TwoAuthEnableSuccessModal = ({
  onSuccess,
}: TwoAuthEnableSuccessModalProps) => {
  const { t } = useTranslation(['auth', 'common']);

  return (
    <Modal show onHide={onSuccess}>
      <h1 className="Typography Typography--title u-weight-600">
        {t('auth:two_auth_enable_modal_title')}
      </h1>

      <p className="Modal-description">
        {t('auth:two_auth_enable_modal_description')}
      </p>

      <div className="t-flex t-justify-end">
        <Button testId="TwoAuthEnableSuccessModalSuccess" onClick={onSuccess}>
          {t('common:got_it')}
        </Button>
      </div>
    </Modal>
  );
};

export default TwoAuthEnableSuccessModal;
