import React, { ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';

interface WarningTooltipProps {
  id: string;
  children: ReactNode | ReactNode[];
}

const WarningTooltipRefresher = ({ id, children }: WarningTooltipProps) => {
  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      {children}
      <ReactTooltip
        id={id}
        effect="solid"
        place="bottom"
        multiline
        className="t-bg-eta-100 typo-epsilon t-text-zeta-600"
      />
    </>
  );
};

export default WarningTooltipRefresher;
