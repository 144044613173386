import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation, utilHelper } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { PdfDownloadLink, InfoTooltip } from 'modules/common/components';
import {
  DeleteDocumentButton,
  UploadDocumentButton,
} from 'modules/document/components';

export interface UploadDocumentProps {
  assetItem: AssetDTO;
  assetCategory: string;
  onDocumentUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isPrimaryColor?: boolean;
  optional?: boolean;
}

function UploadDocument(props: UploadDocumentProps) {
  const { assetCategory, onDocumentUpload, isPrimaryColor, optional } = props;
  const assetItem = (props.assetItem || {}) as AssetDTO;

  const { t } = useTranslation(['common']);

  const documentList: DocumentDTO[] = assetItem?.documents || [];
  const isEmailVerified = useSelector(
    (state: RootState) => !!state.user.current?.verified
  );

  const uploadDocumentLabel = `${t('common:upload_document_label')}${
    optional ? ` ${t('common:optional_label')}` : ''
  }`;

  return (
    <>
      <Row data-testid="UploadDocument">
        <Col md={{ span: 12 }} className="d-flex flex-column t-mb-3">
          <div className="t-flex t-items-center">
            <span className="t-text-beta-500 t-mr-2">
              {uploadDocumentLabel}
            </span>
            <InfoTooltip tipText={t('common:upload_document_tooltip')} />
          </div>
          {documentList.map((document) => (
            <div
              key={document.id}
              className="d-flex align-items-center justify-content-between"
            >
              <div className="Form-document">
                <PdfDownloadLink document={document} />
              </div>
              <DeleteDocumentButton
                document={document}
                assetCategory={assetCategory}
                assetId={assetItem.id || ''}
              />
            </div>
          ))}
        </Col>
      </Row>
      <Row className="u-pb-10">
        <Col md={{ span: 12 }} className="d-flex align-items-center">
          <UploadDocumentButton
            isPrimaryColor={isPrimaryColor}
            isDisabled={!isEmailVerified}
            onDocumentUpload={onDocumentUpload}
            title={t('common:browse_to_choose_a_file')}
          />
          <span className="u-pl-20 text-main-sm t-text-beta-400">
            {t('common:file_up_to')} {utilHelper.getMaxFileAssetSize()}
          </span>
        </Col>
      </Row>
    </>
  );
}

export default UploadDocument;
