import React from 'react';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'modules/common/helpers';

import { Button, Modal, RadioCard } from 'modules/common/components';

import { KEY_HOLDER_OPTIONS } from 'modules/securityKeyWizard/constants';

type Inputs = {
  selectAdvanceSecurity: KEY_HOLDER_OPTIONS;
};

interface KeyHolderSelectionModalProps {
  onContinue: (withKeyHolders: boolean) => void;
}

const KeyHolderSelectionModal = ({
  onContinue,
}: KeyHolderSelectionModalProps) => {
  const { t } = useTranslation(['securityKey', 'common']);

  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      selectAdvanceSecurity: KEY_HOLDER_OPTIONS.KEY_HOLDERS,
    },
  });

  const onSubmit = (formData) => {
    onContinue(
      formData?.selectAdvanceSecurity === KEY_HOLDER_OPTIONS.KEY_HOLDERS
    );
  };

  return (
    <Modal show dataTestid="KeyHolderSelectionModal">
      <h1 className="typo-alpha t-pb-5">
        {t('securityKey:key_holder_selection_modal_title')}
      </h1>

      <p className="typo-beta t-pb-1 t-text-delta-700">
        {t('securityKey:key_holder_selection_modal_subtitle')}
      </p>
      <p className="typo-epsilon">
        {t('securityKey:key_holder_selection_modal_description_1')}
      </p>
      <p className="typo-epsilon">
        {t('securityKey:key_holder_selection_modal_description_2')}
      </p>

      <form onSubmit={handleSubmit(onSubmit)} className="t-p-0 t-mt-5">
        <RadioCard
          register={register}
          name="selectAdvanceSecurity"
          value={KEY_HOLDER_OPTIONS.KEY_HOLDERS}
          id={KEY_HOLDER_OPTIONS.KEY_HOLDERS}
          className="t-px-8 t-py-6 t-mb-5"
        >
          <div className="t-flex t-flex-col sm:t-flex-row sm:t-justify-between">
            <p className="typo-beta t-order-2 sm:t-order-1">
              {t('securityKey:with_key_holder_selection_title')}
            </p>
            <div className="t-flex t-justify-end t-order-1 sm:t-order-2 sm:t-ml-2">
              <span className="Character-label Character-label--accepted">
                {t('common:recommended')}
              </span>
            </div>
          </div>
        </RadioCard>

        <RadioCard
          register={register}
          name="selectAdvanceSecurity"
          value={KEY_HOLDER_OPTIONS.NO_KEY_HOLDER}
          id={KEY_HOLDER_OPTIONS.NO_KEY_HOLDER}
          className="t-px-8 t-py-6 t-mb-5"
        >
          <p className="typo-beta t-order-2 sm:t-order-1">
            {t('securityKey:without_key_holder_selection_title')}
          </p>
        </RadioCard>

        <div className="t-flex t-justify-end t-pt-5 t-pb-16 sm:t-pb-0">
          <Button type="submit">{t('common:save_and_continue')}</Button>
        </div>
      </form>
    </Modal>
  );
};

export default KeyHolderSelectionModal;
