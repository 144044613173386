import React from 'react';

export interface GreyLinedContainerProps {
  children: React.ReactNode | React.ReactNode[];
  isLineVisible?: boolean;
}

function GreyLinedContainer({
  children,
  isLineVisible = false,
}: GreyLinedContainerProps): JSX.Element {
  return (
    <div className="t-flex t-justify-items-stretch">
      {children}
      {isLineVisible && (
        <div className="t-flex t-flex-grow t-flex-col t-justify-center">
          <div className="t-hidden xl:t-block t-h-0.5 t-bg-beta-100 t-ml-1 t--mr-2" />
        </div>
      )}
    </div>
  );
}

export default GreyLinedContainer;
