import utilHelper from 'modules/common/helpers/caseConvertHelper';
import config from 'modules/common/helpers/configHelper';
import httpHelper from 'modules/common/helpers/httpHelper';

import { PROVIDER_CONSTANT_TYPES } from 'modules/estatePlan/constants/enums';

const BASE_URL = config.beUrl;

function getAssetsList() {
  return httpHelper.get(`${BASE_URL}/api/assets`, {});
}

function getAssetsByCategory(category: string) {
  return httpHelper.get(`${BASE_URL}/api/assets`, {
    category: utilHelper.toLowerStartCase(category),
  });
}

function getAsset(id: string, hideErrors = false) {
  return httpHelper.post(
    `${BASE_URL}/api/asset/show`,
    { id },
    undefined,
    hideErrors
  );
}

function addAsset(assetData: AssetDTO, hideErrors: boolean) {
  return httpHelper.post(
    `${BASE_URL}/api/asset`,
    { ...assetData },
    undefined,
    hideErrors
  );
}

function editAsset(assetData: AssetDTO, hideErrors = false) {
  return httpHelper.put(
    `${BASE_URL}/api/asset`,
    { ...assetData },
    undefined,
    hideErrors
  );
}

function deleteAsset(id: string) {
  return httpHelper.delete(`${BASE_URL}/api/asset`, { id });
}

function downloadAsset(data: { id: string }) {
  return httpHelper.post(`${BASE_URL}/api/document/download`, data, {
    responseType: 'blob',
  });
}

function deleteDocument(id: string) {
  return httpHelper.delete(`${BASE_URL}/api/document`, { id });
}

function doneAsset(category: string, type: string) {
  return httpHelper.post(`${BASE_URL}/api/asset/done`, { category, type });
}

function uploadToAsset(data: FormData, hideErrors: boolean) {
  const headers = { 'Content-Type': 'multipart/form-data' };

  return httpHelper.put(
    `${BASE_URL}/api/asset/upload`,
    data,
    { headers },
    hideErrors
  );
}

function uploadToNewAsset(data: FormData, hideErrors: boolean) {
  const headers = { 'Content-Type': 'multipart/form-data' };

  return httpHelper.post(
    `${BASE_URL}/api/asset/upload`,
    data,
    { headers },
    hideErrors
  );
}

function getProvidersByType(type: PROVIDER_CONSTANT_TYPES) {
  return httpHelper.get(`${BASE_URL}/api/constants`, { type });
}

function getProviderByKey(type: PROVIDER_CONSTANT_TYPES, key: string) {
  return httpHelper.get(`${BASE_URL}/api/constants`, { type, key });
}

function getBeneficiaryAssetsList() {
  return httpHelper.get(`${BASE_URL}/beneficiary/assets`, {});
}

function downloadBeneficiaryDocument(data: { id: string }) {
  return httpHelper.post(`${BASE_URL}/beneficiary/document/download`, data, {
    responseType: 'blob',
  });
}

function getSharedAssets() {
  return httpHelper.get(`${BASE_URL}/api/assets/shared`, {});
}

function downloadSharedDocument(data: { id: string }) {
  return httpHelper.post(`${BASE_URL}/api/document/shared`, data, {
    responseType: 'blob',
  });
}

export default {
  getAssetsList,
  getAssetsByCategory,
  getAsset,
  deleteAsset,
  downloadAsset,
  deleteDocument,
  addAsset,
  editAsset,
  doneAsset,
  uploadToAsset,
  uploadToNewAsset,
  getProvidersByType,
  getProviderByKey,
  getBeneficiaryAssetsList,
  downloadBeneficiaryDocument,
  getSharedAssets,
  downloadSharedDocument,
};
