import React from 'react';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';

interface RadioProps {
  label: string;
  name: string;
  value: string;
  id: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  info?: string;
}

export default function Radio({
  label,
  name,
  value,
  id,
  checked,
  onChange,
  info,
}: RadioProps) {
  const ariaLabelledby = `radioLabel-${id}`;
  return (
    <label
      className="Radiobutton"
      tabIndex={0}
      role="radio"
      aria-checked="false"
      aria-labelledby={ariaLabelledby}
      htmlFor={id}
    >
      <input
        id={id}
        checked={checked}
        value={value}
        name={name}
        onChange={onChange}
        type="radio"
        className="jsCeckboxSource Radiobutton-source"
      />
      <span className="Radiobutton-result" />
      <span className="Radiobutton-caption t-mr-2" id={ariaLabelledby}>
        {label}
      </span>

      {!!info && (
        <div>
          <IconInfo
            role="presentation"
            className="t-inline t-text-delta-700 t-mb-1"
            data-tip={info}
            data-for={label || 'radiobutton'}
          />
          <ReactTooltip
            id={label || 'radiobutton'}
            effect="solid"
            place="bottom"
            multiline
          />
        </div>
      )}
    </label>
  );
}
