import React, { useEffect } from 'react';
import { find, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { useTranslation } from 'modules/common/helpers';

import { InfoTooltip } from 'modules/common/components';

import { getAnswers } from 'modules/assistant/constants/questionnaires';
import imgTick from 'images/patient-decree/tick.svg';

export interface ChoiceProps {
  possibleAnswers: SelectedAnswer[];
  isMultipleChoice: boolean;
  isHybrid: boolean;
  questionnaire: string;
  handleSubmit: (arg0: SelectedAnswer | SelectedAnswer[]) => void;
  previousAnswers: null | [];
}

const Choice = ({
  possibleAnswers,
  isMultipleChoice,
  isHybrid,
  questionnaire,
  handleSubmit,
  previousAnswers,
}: ChoiceProps) => {
  const { t } = useTranslation([questionnaire]);
  const dispatch = useDispatch();

  const selectedAnswers = useSelector(
    (state: RootState) => state.temporaryField
  );
  const answerValues = selectedAnswers.value;

  // show previously selected answers (if any)
  useEffect(() => {
    if (previousAnswers) {
      let answers;
      if (isMultipleChoice || (isHybrid && Array.isArray(previousAnswers))) {
        answers = previousAnswers
          .map((item) => find(possibleAnswers, (el) => el.id === item) || {})
          .filter((el) => !isEmpty(el));
      } else {
        answers = previousAnswers;
      }
      dispatch(temporaryFieldSaver(answers));
    }
  }, [previousAnswers]);

  const handlePressSpace = (e, answer: SelectedAnswer) => {
    if (e && e.keyCode === 32) {
      toggleAnswer(answer);
    }
  };

  const toggleAnswer = (answer: SelectedAnswer) => {
    // multiple choice or hybrid component
    if (isMultipleChoice || isHybrid) {
      // answer is multiple choice
      if (!answer.isNormalChoice) {
        const isAnswerAlreadySelected = find(
          answerValues,
          (item) => item.id === answer.id
        );
        const removeAnswerFromSelected = () =>
          answerValues.filter(
            (item) => item.id !== answer.id || item.isNormalChoice
          );
        // if answerValues is not an array, the current saved answer is a normalChoice answer
        // we therefore deselect the normalChoice answer and only keep the multiple choice answers
        const includeAnswerToSelected = () =>
          Array.isArray(answerValues) ? [...answerValues, answer] : [answer];
        const answers = isAnswerAlreadySelected
          ? removeAnswerFromSelected()
          : includeAnswerToSelected();
        dispatch(temporaryFieldSaver(answers));
      } else {
        // if the clicked answer is a normalChoice answer (and therefore hybrid component),
        // we deselect all multiple choice answers, but don't yet change the step
        dispatch(temporaryFieldSaver(answer.id));
      }
      // single choice component, changes step instend
    } else {
      handleSubmit(answer);
    }
  };

  const isLargerCards = possibleAnswers.length > 4;
  const isEven = possibleAnswers.length % 2 === 0;
  const ANSWERS = getAnswers(questionnaire, t) || [];

  return (
    <>
      {possibleAnswers.map((answer) => {
        const A = ANSWERS[answer.id];
        const isSelected =
          !!find(answerValues, (item) => item.id === answer.id) ||
          answerValues === answer.id;
        const { isFamilyFailure } = answer;

        return (
          <div
            className={`AnswerCard u-bg-white 
              ${isSelected && ' isSelected'} 
              ${isLargerCards && ' AnswerCard--larger'}
              ${isFamilyFailure && !isEven && ' AnswerCard--family-failure'}`}
            onClick={() => toggleAnswer(answer)}
            onKeyUp={(e) => handlePressSpace(e, answer)}
            role="button"
            tabIndex={0}
            key={answer.id}
          >
            {isFamilyFailure && !isEven && (
              <div className="d-flex align-content-between">
                <div className="d-flex u-p-10">
                  <div className="u-pl-25 u-pr-15">
                    <img
                      src={A.image}
                      alt={A.infoContent}
                      height="35"
                      width="35"
                    />
                  </div>
                  <div className="Typography Typography--answerTitle u-text-center u-pl-15 u-mt-8">
                    {A.content}
                  </div>
                </div>

                {(isMultipleChoice || (isHybrid && !answer.isNormalChoice)) && (
                  <div className="AnswerCard-tick AnswerCard-tick--family-failure">
                    {isSelected && <img src={imgTick} alt="tick" />}
                  </div>
                )}
              </div>
            )}

            {((isFamilyFailure && isEven) || !isFamilyFailure) && (
              <>
                {(isMultipleChoice || (isHybrid && !answer.isNormalChoice)) && (
                  <div className="AnswerCard-tick">
                    {isSelected && <img src={imgTick} alt="tick" />}
                  </div>
                )}

                {A.infoContent && (
                  <div className="AnswerCard-info">
                    <InfoTooltip tipText={A.infoContent} />
                  </div>
                )}

                <div className="AnswerCard-group t-p-3">
                  <div>
                    <img src={A.image} alt={A.infoContent} />
                  </div>

                  <div className="Typography Typography--answerTitle u-text-center">
                    {A.content}
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

export default Choice;
