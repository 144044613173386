import React from 'react';
import { useNavigate } from 'react-router-dom';
import { snakeCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { RootState } from 'store/reducers';
import { togglePlanStepStatus } from 'store/actions/planActions';
import { setModal } from 'store/actions/modalActions';
import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

import { PLAN_STEPS } from 'modules/estatePlan/constants';
import { MODALS } from 'modules/common/constants';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';
import { ReactComponent as IconArrowRight } from 'images/chevron-next.svg';

interface AssetTypePageFooterProps {
  currentCategory: string;
}

export default function AssetTypePageFooter({
  currentCategory,
}: AssetTypePageFooterProps) {
  const { t } = useTranslation(['plan']);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const assets: AssetsDTO = useSelector((state: RootState) => state.assets);
  const categoryAssets: AssetDTO[] = assets[currentCategory] || [];
  const ableToMarkAsComplete = !categoryAssets.find(
    (asset) => asset?.status !== 1
  );

  const currentStep = PLAN_STEPS[currentCategory];
  const nextStep = Object.values(PLAN_STEPS).find(
    (step) => step.stepNo === currentStep.stepNo + 1
  );

  const planProgress: PlanProgressDTO = useSelector(
    (state: RootState) => state.plan.progress
  );
  const [isStepDone, setIsStepDone] = React.useState(false);
  const categoryWithSpace = snakeCase(currentCategory).replace('_', ' ');

  React.useEffect(() => {
    const stepDone = planProgress[currentStep.dtoKey] === 1;
    setIsStepDone(stepDone);
  }, [currentCategory]);

  React.useEffect(() => {
    if (!ableToMarkAsComplete) {
      dispatch(togglePlanStepStatus(categoryWithSpace, 0));
      setIsStepDone(false);
    }
  }, [ableToMarkAsComplete]);

  const handleToggleMarkDoneClick = async () => {
    await dispatch(togglePlanStepStatus(categoryWithSpace, isStepDone ? 0 : 1));
    setIsStepDone((prev) => !prev);
  };

  const [markedAsDoneCompleted, setMarkedAsDoneCompleted] =
    React.useState(false);
  React.useEffect(() => {
    if (nextStep && markedAsDoneCompleted) {
      navigate(nextStep.path);
      setMarkedAsDoneCompleted(false);
    }
  });

  const handleToggleMarkAsCompleteModal = async () => {
    await dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: t('plan:mark_as_complete_modal_title'),
          description: t('plan:mark_as_complete_modal_description'),
          primaryButtonText: t('common:continue'),
          successAsyncAction: async () => {
            await handleToggleMarkDoneClick();
            setMarkedAsDoneCompleted(true);
          },
          cancelAction: () => {
            if (nextStep?.path) navigate(nextStep.path);
          },
        },
      })
    );
  };

  const onNextStepClick = () => {
    if (
      !!nextStep?.path &&
      ((!isStepDone && !ableToMarkAsComplete) || isStepDone)
    ) {
      navigate(nextStep.path);
    } else {
      handleToggleMarkAsCompleteModal();
    }
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, [ableToMarkAsComplete]);

  return (
    <div className="t-flex t-justify-end t-flex-wrap t-mt-12">
      <p
        className="typo-eta t-text-beta-700 t-flex t-items-center"
        data-tour="vault_markAsCompleted"
      >
        <span>{t('plan:mark_category_completed')}</span>
        <IconInfo
          className="t-text-delta-700 t-ml-2"
          data-tip={t('plan:mark_category_completed_tooltip')}
        />
        <label
          className={`Switcher t-ml-2.5 ${
            !ableToMarkAsComplete ? 't-cursor-not-allowed' : ''
          }`}
          htmlFor="markAsCompleted"
          data-tip={t('plan:unable_to_mark_category_completed_tooltip')}
          data-for="unableToMarkAsComplete"
          data-tip-disable={ableToMarkAsComplete}
        >
          <input
            name="markAsCompleted"
            id="markAsCompleted"
            type="checkbox"
            checked={isStepDone}
            className={`Switcher-source`}
            onChange={handleToggleMarkDoneClick}
            disabled={!ableToMarkAsComplete}
          />
          <span className="Switcher-result" />
        </label>
      </p>
      <ReactTooltip
        id="unableToMarkAsComplete"
        effect="solid"
        place="bottom"
        multiline
        className="t-bg-eta-100 typo-epsilon t-text-zeta-600"
      />
      <Button
        category={!isStepDone ? 'ghost' : 'primary'}
        className={`${
          !isStepDone ? 't-bg-transparent' : ''
        } t-flex t-items-center t-ml-9`}
        onClick={onNextStepClick}
      >
        <span>{t('plan:next_category')}</span>
        <IconArrowRight className="t-ml-3" />
      </Button>
    </div>
  );
}
