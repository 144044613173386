import React from 'react';

interface StyledOrProps {
  children: React.ReactNode;
}

const StyledOr = ({ children }: StyledOrProps) => {
  return (
    <div className="t-font-secondary t-text-xl t-font-semibold t-mt-1.5 t-mb-5 t-text-beta-200">
      {children}
    </div>
  );
};

export default StyledOr;
