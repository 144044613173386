import React from 'react';

import { BequestCard } from 'modules/asset/components';
import { Row, Col } from 'modules/bootstrap/components';

export interface BequestEndProps {
  bequests: BequestDTO[];
}

export default function BequestEnd({ bequests }: BequestEndProps) {
  return (
    <Row style={{ marginTop: 10 }}>
      {bequests.map((item, idx) => (
        <Col sm={10} md={5} key={`${item.description}_${idx}`}>
          <BequestCard isDropdownMenuHidden bequestData={item} />
        </Col>
      ))}
    </Row>
  );
}
