import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconFile } from 'images/icon-file.svg';

type StaticFile = {
  documentTitle: string;
};

function StaticFile({ documentTitle }: StaticFile) {
  const { t } = useTranslation(['plan']);
  const fileExtension = documentTitle.split('.').pop()?.toUpperCase();

  return (
    <>
      <div
        className="Character-iconWrapper t-flex t-items-center"
        data-tip={documentTitle || t('plan:no_download')}
      >
        <div className="t-flex t-items-center t-outline-none t-bg-delta-100 t-rounded-sm t-p-1.5">
          <span>
            <IconFile className="t-h-3 t-w-3 t-text-delta-500" />
          </span>
          <span className="t-whitespace-nowrap typo-kappa t-text-delta-500 t-ml-1.5">
            {fileExtension}
          </span>
        </div>
        <div className="t-outline-none t-text-left t-pl-2.5 t-text-delta-500">
          {documentTitle}
        </div>
      </div>
    </>
  );
}

export default StaticFile;
