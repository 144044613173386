export enum CONSTANT_TYPES {
  BANK = 'bank',
  BANK_ACCOUNT_TYPE = 'bank account type',
  ONLINE_WALLET = 'online wallet',
  COUNTRY = 'country',
  DOCTOR = 'doctor',
  ATTORNEY = 'attorney',
  INSURER = 'insurer',
  INSURANCE_TYPE = 'insurance type',
  PASSWORD = 'password',
  SOCIAL_MEDIA = 'social media',
  CLOUD_STORAGE = 'cloud storage',
  FUNERAL_TYPE = 'funeral type',
  ELECTRONIC_DEVICES = 'electronic devices',
}

export enum PROVIDER_CONSTANT_TYPES {
  // DEVICES
  PHONE = 'phone',
  COMPUTER = 'computer',
  TABLET = 'tablet',
  OTHER_ELECTRONIC_DEVICE = 'other electronic device',

  // HEALTH CARE
  ALL_HEALTH_INSURANCE = 'all health insurance',
  CARE_INSURANCE = 'care insurance',
  OTHER_HEALTH_INSURANCE = 'other health insurance',
  HEALTH_INSURANCE_PUBLIC = 'health insurance public', // FIXME: ?
  HEALTH_INSURANCE_PRIVATE = 'health insurance private', // FIXME: ?

  // LEGAL
  INSURANCE = 'insurance',

  // DIGITAL
  PASSWORD = 'password',
  EMAIL = 'email',
  SOCIAL = 'social media',
  CLOUD_STORAGE = 'cloud storage',
  SUBSCRIPTION = 'subscription',

  // FINANCIAL
  DEATH_BENEFIT_INSURANCE = 'death benefit insurance',
  BANK_ACCOUNT = 'bank account',
  ONLINE_WALLET = 'online wallet',
  LIFE_INSURANCE = 'life insurance',
}

export enum PAGES {
  TWO_FA = 'twoFa',
  TRANSMORTAL_AUTORIZATION = 'transmortalAuthorization',
  MY_CONTACTS = 'myContacts',
  MY_FAMILY = 'myFamily',
  DEVICES = 'devices',
  HEALTH = 'healthCare',
  LEGAL = 'legal',
  DIGITAL = 'digitalEstate',
  FINANCIAL = 'financial',
  AFTERLIFE = 'afterLife',
}

export enum DEVICE_SUBTYPES {
  PHONE = 'phone',
  COMPUTER = 'computer',
  TABLET = 'tablet',
  OTHER = 'other electronic device',
}
export enum LEGAL_SUBTYPES {
  POWER_OF_ATTORNEY = 'power of attorney',
  WILL = 'will',
  PERSONAL_DOCUMENTS = 'personal document',
  FORWARDING_REASON = 'forwarding reason',
  ATTORNEY = 'attorney',
  OTHER_LEGAL_DOCUMENT = 'other legal document',
}
export enum HEALTH_SUBTYPES {
  HEALTH_INSURANCE = 'health insurance',
  CARE_INSURANCE = 'care insurance',
  OTHER_HEALTH_INSURANCE = 'other health insurance',
  DONOR = 'organ donor card',
  DECREE = 'patient decree',
  HCP = 'health care proxy',
}
export enum DIGITAL_SUBTYPES {
  PASSWORD = 'password',
  EMAIL = 'email',
  SOCIAL = 'social media',
  CLOUD = 'cloud storage',
  SUBSCRIPTION = 'subscription',
  ACTION = 'action options',
  OTHER = 'other digital account',
}
export enum FINANCIAL_SUBTYPES {
  BANK = 'bank account',
  WALLETS = 'online wallet',
  LIFE_INSURANCE = 'life insurance',
  TRADING_PORTFOLIO = 'trading portfolio',
  SAFE_DEPOSIT = 'safe deposit',
  LONG_TERM_ASSETS = 'long term asset',
  ONGOING_CONTRACTS = 'ongoing contract',
  CREDIT_CARD = 'credit card',
  FINANCIAL_ADVISOR = 'financial advisor',
  OTHER = 'other financial asset',
}
export enum AFTERLIFE_SUBTYPES {
  FUNERAL = 'funeral',
  MESSAGE = 'last message',
  LIST_OF_CONTACTS = 'list of contacts',
  DEATH_BENEFIT_INSURANCE = 'death benefit insurance',
  OTHER_WISHES = 'other wishes',
}

export enum INSURANCES_SUBTYPES {
  OTHER_INSURANCES = 'other insurance',
}

export enum TWO_AUTH_TYPES {
  SMS = 'sms',
  GOOGLE = 'google',
}

export enum OTHER_PROVIDERS {
  OTHER = 'other',
}
