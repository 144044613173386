import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'store/reducers';
import { setModal } from 'store/actions/modalActions';
import {
  getAssetsList,
  downloadAsset,
  deleteAsset,
} from 'store/actions/documentActions';
import { getPlanProgress } from 'store/actions/planActions';
import { useTranslation } from 'modules/common/helpers';

import {
  EpTransmortalWizard,
  AnswersAccordion,
  TransmortalCard,
} from 'modules/estatePlan/components';
import { ProfileForm } from 'modules/profile/components';

import { PAGES } from 'modules/estatePlan/constants/enums';
import { MODALS } from 'modules/common/constants';

function EpTransmortal() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['plan']);
  const [isProfileFormVisible, setIsProfileFormVisible] = useState(false);
  const [initialStep, setInitialStep] = useState(1);

  const plan: PlanProgressDTO = useSelector(
    (state: RootState) => state.plan.progress
  );

  const handleCloseProfileForm = async () => {
    await dispatch(getPlanProgress());
    await setIsProfileFormVisible(false);
  };

  const onDeleteTransmortalAsset = async (
    id: string,
    title: string,
    assetCategory: string,
    assetType: string
  ) => {
    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: t('plan:delete_transmortal_confirm_title', {
            assetName: title,
          }),
          description: t('plan:delete_transmortal_confirm_text', {
            assetName: title,
          }),
          primaryButtonText: t('common:confirm'),
          successAsyncAction: async () => {
            await dispatch(deleteAsset(id, assetCategory, assetType));
            await dispatch(getPlanProgress());
            await dispatch(getAssetsList());
          },
        },
      })
    );
  };

  const onDownloadAsset = async (document: DocumentDTO) => {
    await dispatch(downloadAsset(document));
  };

  const handleView = () => {
    setInitialStep(2);
    setIsProfileFormVisible(true);
  };

  return (
    <>
      <h2 className="Typography Typography--title u-weight-600 u-pb-10">
        {t('plan:step_authorization_sub')}
      </h2>
      <p className="t-mb-6">{t('plan:step_authorization_description')}</p>

      <AnswersAccordion page={PAGES.TRANSMORTAL_AUTORIZATION} />

      <h3 className="Typography Typography--subTitle u-weight-600 u-pb-10">
        {t('plan:step_transmortalAuthorization')}
      </h3>
      {plan.transmortalAuthorization ? (
        <TransmortalCard
          onDelete={onDeleteTransmortalAsset}
          onDownload={onDownloadAsset}
          onView={handleView}
        />
      ) : (
        <div className="Card Card--shadow u-pt-25 u-pr-30 u-pb-25 u-pl-30 u-bg-white">
          <div className="d-flex">
            <ProfileForm
              isProfilePage={false}
              setIsProfileFormVisible={setIsProfileFormVisible}
              isVerificationEmailWarning={true}
            />
          </div>
        </div>
      )}
      <div className="u-pb-40" />
      {isProfileFormVisible && (
        <EpTransmortalWizard
          initialStep={initialStep}
          handleClose={handleCloseProfileForm}
          onDownload={onDownloadAsset}
        />
      )}
    </>
  );
}

export default EpTransmortal;
