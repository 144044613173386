import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { sendFeedback } from 'store/actions/userActions';
import { useTranslation } from 'modules/common/helpers';

import { TextInputRef, TextAreaRef, Button } from 'modules/common/components';

type Inputs = {
  subject: string;
  body: string;
};

interface ContactUsFormProps {
  onSuccess?: () => void;
}

export default function ContactUsForm({ onSuccess }: ContactUsFormProps) {
  const { t } = useTranslation(['auth', 'common', 'help']);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm<Inputs>();

  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (formData: FeedbackDTO) => {
    setLoading(true);
    await dispatch(sendFeedback(formData));
    reset();
    if (onSuccess) onSuccess();
    setLoading(false);
  };

  return (
    <form
      className="Form"
      onSubmit={handleSubmit(onSubmit)}
      data-testid="ContactUsForm"
    >
      <div className="Form-group t-pb-6">
        <TextInputRef
          {...register('subject', {
            required: true,
            validate: (value) => {
              if (value.trim().length < 2)
                return t(
                  'common:contact_us_subject_field_min_chars_error'
                ) as string;
            },
          })}
          label={t('common:page_contact_us_subject')}
          type="text"
          error={
            errors.subject?.message ||
            (errors.subject && (t('common:required_upper') as string))
          }
          autoComplete={false}
          defaultValue=""
        />
      </div>
      <div className="Form-group t-pb-6">
        <TextAreaRef
          rows={5}
          maxLength={3000}
          label={t('common:your_message')}
          {...register('body', {
            required: true,
            validate: (value) => {
              if (value.trim().length < 5)
                return t(
                  'common:contact_us_body_field_min_chars_error'
                ) as string;
            },
          })}
          error={
            errors.body?.message ||
            (errors.body && (t('common:required_upper') as string))
          }
        />
      </div>
      <div className="t-pt-2.5">
        <Button type="submit" loading={loading}>
          {t('common:send_message')}
        </Button>
      </div>
    </form>
  );
}
