import React from 'react';
import { useDispatch } from 'react-redux';

import { switchAccount } from 'store/actions/userActions';
import { getFullName, useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

interface LoginAccountSelectionModalProps {
  familyAccounts: FamilyAccount[];
  onClose: () => void;
}

const LoginAccountSelectionModal = ({
  familyAccounts,
  onClose,
}: LoginAccountSelectionModalProps) => {
  const { t } = useTranslation(['common', 'familyAccounts']);
  const dispatch = useDispatch();

  const mainAccount =
    familyAccounts.find((account) => !!account.primary) ||
    ({} as FamilyAccount);
  const subAccounts = familyAccounts.filter(
    (account) => !account.primary && !account.deceased
  );
  const currentAccount =
    familyAccounts.find((account) => !!account.active) || ({} as FamilyAccount);

  const [selectedAccountId, setSelectedAccountId] = React.useState(
    mainAccount.id
  );
  const [loading, setLoading] = React.useState(false);

  const handleAccountLogin = async () => {
    if (selectedAccountId === currentAccount.id) {
      onClose();
      return;
    }
    setLoading(true);
    await dispatch(switchAccount(selectedAccountId));
    setLoading(true);
    onClose();
  };

  return (
    <Modal show dataTestid="LoginAccountSelectionModal">
      <h1 className="typo-alpha">
        {t('familyAccounts:account_selection_modal_title')}
      </h1>

      <div className="t-flex t-justify-center t-flex-wrap t-mt-6">
        {[mainAccount, ...subAccounts].map((account) => {
          const selected = selectedAccountId === account.id;
          const isMainAccount = !!account.primary;
          return (
            <div
              key={account.id}
              className="t-flex t-flex-col t-items-center t-w-full md:t-w-1/3 t-mb-6"
            >
              <div
                className={`t-py-1 t-px-2 t-rounded-sm t-mb-4 ${
                  isMainAccount ? 't-bg-delta-900' : 't-bg-delta-500'
                }`}
              >
                <p className="t-text-xs t-text-beta-50">
                  {isMainAccount
                    ? t('familyAccounts:main_account_badge')
                    : t('familyAccounts:family_account_badge')}
                </p>
              </div>
              <div
                className={`t-rounded-full t-flex t-justify-center t-items-center t-cursor-pointer t-overflow-hidden t-p-1 ${
                  selected
                    ? 't-border-4 t-border-solid t-border-delta-700'
                    : isMainAccount
                    ? 't-bg-delta-900'
                    : 't-bg-delta-500'
                }`}
                style={{ width: '100px', height: '100px' }}
                onClick={() => {
                  setSelectedAccountId(!selected ? account.id : '');
                }}
              >
                <p
                  className={`t-select-none t-w-full t-h-full t-items-center t-text-gamma-400 t-text-xxl t-flex t-justify-center t-rounded-full t-uppercase ${
                    isMainAccount ? 't-bg-delta-900' : 't-bg-delta-500'
                  }`}
                >
                  {(account?.name || ' ')[0] + (account?.surname || ' ')[0]}
                </p>
              </div>
              <p
                className={`typo-beta t-text-center t-max-w-56 t-mt-4 ${
                  isMainAccount ? 't-text-delta-900' : 't-text-delta-700'
                }`}
              >
                {getFullName(account, false)}
              </p>
            </div>
          );
        })}
      </div>

      <div className="t-flex t-justify-center">
        <Button
          className="t-mt-6"
          onClick={handleAccountLogin}
          disabled={!selectedAccountId}
          loading={loading}
        >
          {t('familyAccounts:choose_account_button')}
        </Button>
      </div>
    </Modal>
  );
};

export default LoginAccountSelectionModal;
