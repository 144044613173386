import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { lowerCase, startCase } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { useTranslation } from 'modules/common/helpers';

import { Button, Modal, TextInputRef } from 'modules/common/components';
import { InputLibraryModal } from 'modules/estatePlan/components';

import { FIELDS, FIELD_TYPE_KEYS } from 'modules/estatePlan/constants';

import { ReactComponent as IconPlus } from 'images/icon-plus.svg';
import { ReactComponent as IconDelete } from 'images/icon-delete.svg';
import { ReactComponent as ArrowLeftIcon } from 'images/icon-arrow-left.svg';

interface AddCustomAssetTypeModalProps {
  isVisible: boolean;
  onClose: () => void;
  assetCategory: string;
  categorizedAssetTypes: AssetTypeDTO[];
  handleSave: (any) => void;
  handleBackToAssetTypeSelection: () => void;
}

const initialFields = [
  FIELDS[FIELD_TYPE_KEYS.NAME],
  FIELDS[FIELD_TYPE_KEYS.CONTACTS],
];

export default function AddCustomAssetTypeModal({
  isVisible,
  onClose,
  assetCategory,
  categorizedAssetTypes,
  handleSave,
  handleBackToAssetTypeSelection,
}: AddCustomAssetTypeModalProps) {
  const { t } = useTranslation(['common', 'plan', 'fieldLibrary']);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({ shouldUnregister: false });

  const [fields, setFields] = useState(initialFields);
  const [visibleFieldLibraryModal, setVisibleFieldLibraryModal] =
    useState(false);
  const [visibleAssetTypeEditModal, setVisibleAssetTypeEditModal] =
    useState(isVisible);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const addField = (field: AssetFieldDTO) => {
    const newField: AssetFieldDTO = {
      ...field,
      help: field.help || '',
      required: false,
    };

    // copy state to not mutate it & add new field
    const newFields = [...fields, newField];

    const newOrderedFields = orderFields(newFields);

    setFields(newOrderedFields);
    handleCloseFieldLibraryModal();
  };

  const handleAssetTypeSave = (dataForm) => {
    const { name, description } = dataForm;
    const newAssetType = {
      name: lowerCase(name),
      label: name,
      order: String(categorizedAssetTypes.length),
      category: lowerCase(startCase(assetCategory)),
      description: description,
      structure: [...fields],
    };
    handleSave(newAssetType);
  };

  const deleteField = (orderId) => {
    const newFields = [...fields];
    const index = newFields.findIndex((f) => f.order === orderId);
    if (index !== -1) newFields.splice(index, 1);

    const newOrderedFields = orderFields(newFields);

    setFields(newOrderedFields);
  };

  const orderFields = (fieldsToOrder) => {
    // push the contacts field to the end
    const contactFieldIndex = fieldsToOrder.findIndex(
      (f) => f.type === FIELD_TYPE_KEYS.CONTACTS
    );
    fieldsToOrder.push(fieldsToOrder.splice(contactFieldIndex, 1)[0]);
    // add the order key to the fields, depending on the sequence the user added them
    return fieldsToOrder
      .map((f, index) => ({ ...f, order: index }))
      .sort((a, b) => a?.order - b?.order);
  };

  const errName =
    !!errors?.name &&
    (errors.name.message ||
      `${t('plan:edit_category_modal_input')} ${t('common:required')}`);

  const handleShowFieldLibraryModal = () => {
    setVisibleFieldLibraryModal(true);
    setVisibleAssetTypeEditModal(false);
  };

  const handleCloseFieldLibraryModal = () => {
    setVisibleFieldLibraryModal(false);
    setVisibleAssetTypeEditModal(true);
  };

  const checkIfAssetTypeNameNotExists = (name) => {
    const assetTypeAlreadyExists = !!categorizedAssetTypes?.find(
      (type) => type.name === name
    );
    if (assetTypeAlreadyExists) return t('plan:asset_type_name_exists');
  };

  return (
    <>
      {visibleFieldLibraryModal && (
        <InputLibraryModal
          onClose={handleCloseFieldLibraryModal}
          assetCategory={assetCategory}
          addField={addField}
          fields={fields}
        />
      )}
      {visibleAssetTypeEditModal && (
        <Modal show onHide={onClose}>
          <Button
            category="ghost"
            className="t-pl-1 t-mb-4"
            onClick={handleBackToAssetTypeSelection}
          >
            <ArrowLeftIcon className="t-mr-3 t-text-alpha-600" />
            <span>{t('common:back')}</span>
          </Button>
          <form onSubmit={handleSubmit(handleAssetTypeSave)}>
            <h1 className="t-text-xl t-font-secondary t-mb-6 t-font-semibold">
              {t('plan:add_custom_category_modal_title')}
            </h1>
            <TextInputRef
              {...register('name', {
                required: true,
                validate: (value) => {
                  return (
                    checkIfAssetTypeNameNotExists(value?.toLowerCase()) || true
                  );
                },
              })}
              label={t('plan:edit_category_modal_input')}
              type="text"
              error={errName as string}
              autoComplete={false}
              defaultValue=""
              maxLength={300}
            />

            <input
              type="hidden"
              id="description"
              {...register('description')}
              value="plan:default_asset_type_description"
            />

            <p className="t-text-md t-mt-5 t-text-beta-500 t-mb-2">
              {t('plan:edit_category_modal_field_label')}
            </p>

            {fields.map((field) => (
              <div
                className="card t-mb-5 t-flex t-justify-between t-p-5"
                key={field.order + field.type}
              >
                <p className="t-text-lg t-my-auto">{t(field.label)}</p>
                <button
                  type="button"
                  className={`t-ml-10 t-outline-none ${
                    field.required ? 't-cursor-not-allowed' : ''
                  }`}
                  onClick={() => !field.required && deleteField(field.order)}
                  data-tip={
                    field.required
                      ? t('plan:required_asset_type_field_delete_button')
                      : undefined
                  }
                >
                  <IconDelete
                    className={`t-fill-current ${
                      field.required ? 't-text-beta-400' : 't-text-epsilon-400'
                    }`}
                  />
                </button>
              </div>
            ))}

            <div className="t-flex t-justify-between t-items-center t-mt-12">
              <p>{t('plan:add_input_paragraph')}</p>
              <Button
                onClick={handleShowFieldLibraryModal}
                className="t-ml-2 t-self-end t-flex"
              >
                <div>
                  <IconPlus
                    role="presentation"
                    className="t-inline t-text-gamma-400 xs:t-left-0 md:t--left-1.5 xs:t-mr-0 sm:t-mr-1"
                  />
                </div>
                <span className="xs:t-hidden sm:t-inline t-whitespace-nowrap">
                  {t('common:add_new')}
                </span>
              </Button>
            </div>
            <div className="t-flex t-flex-wrap t-justify-end t-mt-12">
              <Button
                category="secondary"
                onClick={onClose}
                className="t-self-end"
              >
                {t('common:cancel')}
              </Button>
              <Button type="submit" className="t-ml-2 xs:t-mt-3">
                {t('common:save')}
              </Button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
}
