import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import iban from 'iban';

import { useTranslation } from 'modules/common/helpers';
import { FormControl } from 'modules/common/types';

export interface IbanProps {
  error: string;
  label: string;
  name: string;
  control: FormControl;
  defaultValue: string;
  required?: boolean;
  invalidMessage?: string;
}

export default function Iban({
  error,
  label,
  name,
  control,
  defaultValue,
  required,
  invalidMessage,
}: IbanProps) {
  const { t } = useTranslation(['common']);
  const invalidMessageDefault = invalidMessage || t('common:invalid_iban');

  return (
    <div className="t-mb-3.5">
      <span
        className={`t-inline-block t-mb-2 t-text-beta-500 ${
          error ? 't-text-epsilon-600' : ''
        }`}
      >
        {label}
      </span>
      <Controller
        name={name}
        render={({ field }) => (
          <InputMask
            mask="**** **** **** **** **** **"
            className={`Field ${error ? 'isErrored' : ''}`}
            {...field}
          />
        )}
        control={control}
        defaultValue={defaultValue}
        rules={{
          validate: (value) => {
            const formattedValue = value?.replace(/_| /g, '') || '';
            if (!formattedValue && !required) return true;
            return !iban.isValid(value) ? invalidMessageDefault : true;
          },
        }}
      />
      {error && (
        <div className="t-pt-0.5">
          <span className="text-main-sm t-text-epsilon-600">{error}</span>
        </div>
      )}
    </div>
  );
}
