import { LOAD_ASSISTANT_PROGRESS } from 'store/types/assistantActionTypes';
import helper from 'store/reducers/reducerHelper';
import initialState from 'store/reducers/initialState';

const assistantReducer = (state = initialState.assistant, action) => {
  return helper.handleActions(state, action, {
    [LOAD_ASSISTANT_PROGRESS](state, payload) {
      state.progress = payload.progress as AssistantProgressDTO;
    },
  });
};

export default assistantReducer;
