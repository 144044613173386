import React from 'react';
import ReactTooltip from 'react-tooltip';

import { useTranslation } from 'modules/common/helpers';
import { FormRegister } from 'modules/common/types';

import { FIELD_TYPE_KEYS as F } from 'modules/estatePlan/constants/fieldLibrary';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';

export interface SwitchInputProps {
  label: string;
  name: string;
  register: FormRegister;
  defaultValue: boolean;
  required?: boolean;
  labelClassName?: string;
  info?: string;
}

export default function SwitchInput({
  register,
  label,
  name,
  defaultValue,
  required = false,
  labelClassName = '',
  info = '',
}: SwitchInputProps) {
  const { t } = useTranslation(['plan']);
  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  return (
    <label className="Switcher t-w-full t-mb-3.5" htmlFor={name}>
      <input
        {...register(name, { required: required })}
        name={name}
        id={name}
        type="checkbox"
        defaultChecked={defaultValue}
        className="Switcher-source"
        data-testid="SwitchInput"
      />
      <span className="Switcher-result" />
      <span
        className={`${
          labelClassName || 't-font-secondary t-font-semibold t-text-beta-500'
        } t-pl-3 t-transition-all t-duration-300`}
        id="checkboxLabel"
      >
        {label}
      </span>
      {(name === F.TWO_AUTH || !!info) && (
        <span>
          <IconInfo
            role="presentation"
            className="t-inline t-text-delta-700 t-ml-2"
            data-tip={info || t('plan:financial_two-factor-auth-tooltip')}
          />
        </span>
      )}
    </label>
  );
}
