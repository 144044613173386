import produce from 'immer';

import initialState from 'store/reducers/initialState';
import { GET_FAMILY_ACCOUNTS } from 'store/types/familyAccountTypes';

const familyAccountReducer = (
  state = initialState.familyAccounts,
  action: ReducerAction
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_FAMILY_ACCOUNTS: {
        const {
          payload: { familyAccounts },
        } = action;
        draft.list = familyAccounts || [];

        return draft;
      }

      default:
        return draft;
    }
  });

export default familyAccountReducer;
