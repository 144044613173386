import React from 'react';

import { SiteLanguage } from 'modules/common/components';
import { ClickableLogo } from 'modules/auth/components';

interface SignUpLogoAndLanguageProps {
  customization: ClientCustomization;
  hideLanguageFlags?: boolean;
  hideLogo?: boolean;
}

export default function SignUpLogoAndLanguage({
  customization,
  hideLanguageFlags,
}: SignUpLogoAndLanguageProps) {
  return (
    <div className={`Form-logo t-pb-6 ${customization?.headerClassName || ''}`}>
      <ClickableLogo customization={customization} />

      {!customization?.noLanguageSwitcher && !hideLanguageFlags && (
        <div>
          <SiteLanguage additionalClass="t-pb-0" />
        </div>
      )}
    </div>
  );
}
