import { utilHelper, validationHelper } from 'modules/common/helpers';

export default {
  getPasswordValidity,
  getPasswordErrorMessages,
};

function getPasswordValidity(password: string): PasswordValidity {
  return {
    oneLowercase: validationHelper.hasLowerCase(password),
    oneUppercase: validationHelper.hasUpperCase(password),
    oneNumber: validationHelper.hasNumber(password),
    oneSpecial: validationHelper.hasSpecial(password),
    minLength: validationHelper.hasMinLength(password, 8),
  };
}

function getPasswordErrorMessages(password: string, t): string {
  let errorMsg = '';

  if (!password) {
    errorMsg = t('auth:password_required');
  } else {
    const pwValidity: PasswordValidity = getPasswordValidity(password);

    if (!validationHelper.isAllKeysTruthy(pwValidity)) {
      errorMsg = `${t('auth:password_validate_err_start')} `;
      const errChunks = [
        pwValidity['oneLowercase'] || t('auth:password_validate_err_lower'),
        pwValidity['oneUppercase'] || t('auth:password_validate_err_upper'),
        pwValidity['oneSpecial'] || t('auth:password_validate_err_special'),
        pwValidity['oneNumber'] || t('auth:password_validate_err_number'),
      ];

      errorMsg += errChunks.filter((v) => v !== true).join(', ');

      if (pwValidity['minLength']) {
        errorMsg = `${
          utilHelper.removeLastEntryFromString(
            errorMsg,
            ',',
            t('auth:password_validate_err_and')
          ) + t('auth:password_validate_err_include')
        }`;
      } else {
        // if there is another error than "8 chars min", dont render "and" in front of "8 chars min" err text
        const hasOtherErrThanErrMin = !!errChunks.some(
          (value) => typeof value === 'string'
        );
        errorMsg += hasOtherErrThanErrMin
          ? t('auth:password_validate_err_min')
          : t('auth:password_validate_err_min_wo_other');
      }
    }
  }
  return errorMsg;
}
