import React from 'react';
import { Accordion, AccordionContext } from 'react-bootstrap';
import { range } from 'lodash';

import {
  scrollToElementId,
  useTranslation,
  customHooks,
} from 'modules/common/helpers';

import { ContextAwareToggle } from 'modules/common/components';

type FaqTabProps = {
  tabNumber: string;
  faqCount: number;
};

const FaqTab = ({ tabNumber, faqCount }: FaqTabProps) => {
  const { t } = useTranslation();
  const eventKey = Number(tabNumber) - 1;

  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const inViewport = customHooks.useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible

  const currentEventKey = React.useContext(AccordionContext);
  const [wasScrolled, setWasScrolled] = React.useState(false);

  React.useEffect(() => {
    let scrollingCompleted = false;
    const isCurrentEventKey = currentEventKey.activeEventKey === `${eventKey}`;
    if (
      isCurrentEventKey &&
      eventKey !== 0 &&
      !wasScrolled &&
      !scrollingCompleted
    ) {
      if (!inViewport) {
        handleScrolling();
      } else {
        waitForBootstrapAnimation();
      }
    }

    if (!currentEventKey.activeEventKey) {
      setWasScrolled(false);
    }

    return () => {
      scrollingCompleted = true;
    };
  }, [currentEventKey, inViewport]);

  const handleScrolling = async () => {
    await setTimeout(() => {
      scrollToElementId(`faq_tab_${eventKey}`);
    }, 500);
    setWasScrolled(true);
  };

  const waitForBootstrapAnimation = async () => {
    await setTimeout(() => {
      setWasScrolled(true);
    }, 500);
  };

  const handleOnclick = () => {
    setWasScrolled(false);
  };

  return (
    <div className="Accordion-item" data-testid="FaqTab">
      <div
        className="d-flex align-items-center justify-content-between"
        id={`faq_tab_${eventKey}`}
        ref={ref}
      >
        <h2 className="Typography Typography--landingTitle">
          {t(`faq:accordion_tab${tabNumber}`)}
        </h2>

        <div>
          <ContextAwareToggle
            eventKey={`${eventKey}`}
            onClick={handleOnclick}
          />
        </div>
      </div>
      <Accordion.Collapse eventKey={`${eventKey}`}>
        <>
          {range(faqCount).map((i) => (
            <React.Fragment key={i}>
              <h3 className="Typography Typography--landingQuote u-weight-600 u-pt-25 u-pb-25 u-color-amazon">
                {t(`faq:accordion_tab${tabNumber}_title${i + 1}`)}
              </h3>
              <p className="Typography Typography--landingMain u-pb-60">
                {t(`faq:accordion_tab${tabNumber}_title${i + 1}_description`)}
              </p>
            </React.Fragment>
          ))}
        </>
      </Accordion.Collapse>
    </div>
  );
};

export default FaqTab;
