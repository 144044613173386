export const ONBOARDING_WIZARD_STEPS = {
  STEP_PERSONAL_INFO: 1,
  STEP_DATA_SECURITY: 2,
  STEP_REQUIREMENT_CHECK: 3,
};

export const STEP_LABELS = {
  personalInfo: {
    label: 'onBoardingWizard:personal_info',
    step: 1,
  },
  dataSecurity: {
    label: 'onBoardingWizard:data_security',
    step: 2,
  },
  requirementCheck: {
    label: 'onBoardingWizard:requirement_check',
    step: 3,
  },
};
