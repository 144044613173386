import React from 'react';
import Select, { components } from 'react-select';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { assetsSortingHelpers } from 'modules/assetType/helpers';
import { useGetCountries, useTranslation } from 'modules/common/helpers';

import { AssetCard } from 'modules/asset/components';
import { Col, Row } from 'modules/bootstrap/components';
import { AddAssetProcess } from 'modules/assetType/components';

import { ReactComponent as IconEmpty } from 'images/icon_assets_empty.svg';

const sortingOptions = [
  { value: 'last_added', label: i18next.t('plan:sort_by_last_added') },
  { value: 'last_modified', label: i18next.t('plan:sort_by_last_modified') },
  { value: 'category', label: i18next.t('plan:sort_by_category') },
  { value: 'alphabetic', label: i18next.t('plan:sort_by_alphabetic_order') },
];

interface CreatedAssetsProps {
  assetCategory: string;
  categoryAssets: AssetDTO[];
  openHCP?: () => void;
  unassignAssetsFocused?: boolean;
}

const CreatedAssets = ({
  assetCategory,
  categoryAssets,
  unassignAssetsFocused,
}: CreatedAssetsProps) => {
  const { t } = useTranslation(['plan']);
  const countries = useGetCountries();

  const assetTypes: AssetTypesDTO = useSelector(
    (state: RootState) => state.assetTypes
  );
  const categorizedAssetTypes: AssetTypeDTO[] = assetTypes[assetCategory];

  const allAvailableProviders: ProvidersDTO = useSelector(
    (state: RootState) => state.providers.current
  );

  const [selectedSortingOption, setSelectedSortingOption] = React.useState(
    sortingOptions[0]
  );

  React.useEffect(() => {
    handleSortingChange(sortingOptions[0]);
  }, [assetCategory]);

  let sortedAssets: AssetDTO[];
  switch (selectedSortingOption?.value) {
    case sortingOptions[1].value:
      sortedAssets = assetsSortingHelpers.sortByLastModified(categoryAssets);
      break;
    case sortingOptions[2].value:
      sortedAssets = assetsSortingHelpers.sortByCategory(categoryAssets);
      break;
    case sortingOptions[3].value:
      sortedAssets = assetsSortingHelpers.sortByAlphabeticOrder(
        categoryAssets,
        categorizedAssetTypes,
        allAvailableProviders,
        countries,
        t
      );
      break;
    default:
      sortedAssets = assetsSortingHelpers.sortByLastAdded(categoryAssets);
      break;
  }

  const handleSortingChange = (selectedOption) => {
    const option = selectedOption as SelectOption;
    setSelectedSortingOption(option);
  };

  const { ValueContainer } = components;

  const ValueOptionLabel = (props: any) => {
    return (
      <ValueContainer {...props}>
        <p>{`${t('plan:sort_by')} ${props?.children}`}</p>
      </ValueContainer>
    );
  };

  // TODO: consider to create a custom component for the below Select if it will be used in other places
  return (
    <div className="t-mt-7">
      <div className="t-flex t-justify-between t-items-center t-flex-wrap">
        <div
          className={`t-flex t-justify-between t-items-center ${
            !categoryAssets.length ? 't-w-full' : ''
          }`}
        >
          <h2 className="typo-beta">{t('plan:your_assets_title')}</h2>
          {!categoryAssets.length && (
            <AddAssetProcess assetCategory={assetCategory} />
          )}
        </div>

        {!!categoryAssets.length && (
          <Select
            id="sorting"
            name="sorting"
            onChange={handleSortingChange}
            options={sortingOptions}
            defaultValue={sortingOptions[0]}
            value={selectedSortingOption}
            components={{ SingleValue: ValueOptionLabel }}
            className="Select typo-epsilon t-text-beta-500 t-bg-gamma-400 t-rounded-sm sm:t-w-72"
            classNamePrefix="Select"
            isSearchable={false}
            styles={{
              control: (base) => {
                return {
                  ...base,
                  backgroundColor: 'var(--color-gamma-400) !important',
                };
              },
              singleValue: (base) => {
                return {
                  ...base,
                  color: 'var(--color-beta-500) !important',
                };
              },
              valueContainer: (base) => {
                return {
                  ...base,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '6px !important',
                };
              },
            }}
          />
        )}
      </div>

      {!sortedAssets.length && (
        <>
          <p className="typo-epsilon t-text-beta-500 t-mt-5">
            {t('plan:your_assets_empty_text')}
          </p>
          <div className="t-flex t-justify-center t-mt-4">
            <IconEmpty />
          </div>
          <p className="typo-epsilon t-text-beta-200 t-text-center t-mt-2.5">
            {t('plan:your_assets_empty_text_sub')}
          </p>
        </>
      )}

      {!!sortedAssets.length && (
        <div className="t-mt-5">
          <Row>
            {sortedAssets.map((asset) => (
              <Col
                key={asset.id}
                xl={{ span: 6 }}
                className="t-pb-5"
                id={asset.id}
              >
                <AssetCard
                  asset={asset}
                  assetCategory={assetCategory}
                  unassignAssetsFocused={unassignAssetsFocused}
                />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default CreatedAssets;
