import { EstatePlanPage } from 'modules/estatePlan/components';
import {
  LoginPage,
  PasswordForgotPage,
  PasswordResetPage,
  SetPasswordPageManager,
  SignUpPageManager,
} from 'modules/auth/components';
import { NotFoundPage } from 'modules/notFoundPage/components';
import { DashboardPage } from 'modules/dashboard/components';
import { Profile } from 'modules/profile/components';
import { ReferralPage } from 'modules/referral/components';
import { ImmediateAccessPage } from 'modules/emergencyAccess/components';
import { TermsAndConditionsPage } from 'modules/termsAndConditions/components';
import {
  Assistant,
  QuestionnaireRoutes,
  Recommendations,
} from 'modules/assistant/components';
import { ContactUs } from 'modules/contactUs/components';
import {
  ContactAssetViewPage,
  MyContactsPage,
} from 'modules/myContacts/components';
import { ImprintPage } from 'modules/imprint/components';
import { PrivacyPage } from 'modules/privacy/components';
import {
  BeneficiaryAccessPage,
  BeneficiaryAssetsPage,
} from 'modules/afterDeathManagement/components';
import { VerifyEmail } from 'modules/auth/components';
import { OnBoardingWizard } from 'modules/onBoardingWizard/components';
import { MyAgentPage } from 'modules/agent/components';
import {
  DeathReportPage,
  KeyHolderPage,
} from 'modules/afterDeathManagement/components';
import {
  EmailVerificationResultPage,
  VerifyAndLogin,
} from 'modules/publicPage/components';

const invalidTokenProps = {
  isInvalidEmailToken: true,
};

const notFoundPageProps = {
  isInvalidEmailToken: false,
};

export const routes: AppRoute[] = [
  {
    path: '/',
    exact: true,
    component: DashboardPage,
    pageProps: {
      pageId: 'dashboard',
      title: 'Dashboard',
      visibleForViewer: true,
    } as AppPageProp,
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    pageProps: {
      pageId: 'dashboard',
      title: 'Dashboard',
    },
  },
  {
    path: '/plan/*',
    component: EstatePlanPage,
    pageProps: {
      pageId: 'estate_plan',
      title: 'Estate plan',
    },
  },
  {
    path: '/login',
    component: LoginPage,
    pageProps: {
      pageId: 'log_in',
      title: 'Log In',
      public: true,
    },
  },
  {
    path: '/register',
    component: SignUpPageManager,
    exact: true,
    pageProps: {
      pageId: 'register',
      title: 'Registration',
      public: true,
    },
  },
  {
    path: '/register/:token',
    component: SignUpPageManager,
    exact: true,
    pageProps: {
      pageId: 'register',
      title: 'Registration',
      public: true,
    },
  },
  {
    path: '/register/:token/:client',
    component: SignUpPageManager,
    exact: true,
    pageProps: {
      pageId: 'register',
      title: 'Registration',
      public: true,
    },
  },
  {
    path: '/verify-email',
    component: VerifyEmail,
    pageProps: {
      pageId: 'verify-email',
      title: 'Verify Email',
      isNoWrapper: true,
    },
  },
  {
    path: '/register-wizard',
    component: OnBoardingWizard,
    pageProps: {
      pageId: 'register',
      title: 'Registration',
      isNoWrapper: true,
    },
  },
  {
    path: '/password-forgot',
    component: PasswordForgotPage,
    pageProps: {
      pageId: 'password_forgot',
      title: 'Forgot password',
      public: true,
    },
  },
  {
    path: '/password-reset/:token',
    component: PasswordResetPage,
    pageProps: {
      pageId: 'password_reset',
      title: 'Reset password',
      public: true,
    },
  },
  {
    path: '/set-password/:token',
    component: SetPasswordPageManager,
    pageProps: {
      pageId: 'set_password_normal',
      title: 'Set password',
      public: true,
    },
  },
  {
    path: '/set-password/:token/:client',
    component: SetPasswordPageManager,
    pageProps: {
      pageId: 'set_password_client',
      title: 'Set password',
      public: true,
    },
  },
  {
    path: '/profile',
    component: Profile,
    pageProps: {
      pageId: 'profile',
      title: 'Profile',
      visibleForViewer: true,
    },
  },
  {
    path: '/referral',
    component: ReferralPage,
    pageProps: {
      pageId: 'referral',
      title: 'referral',
      visibleForViewer: true,
    },
  },
  {
    path: '/my-contacts',
    component: MyContactsPage,
    exact: true,
    pageProps: {
      pageId: 'my_contacts',
      title: 'My Contacts',
    },
  },
  {
    path: '/terms-and-conditions',
    component: TermsAndConditionsPage,
    pageProps: {
      pageId: 'terms_and_conditions',
      title: 'Terms and Conditions',
      visibleForViewer: true,
    },
  },
  {
    path: '/imprint',
    component: ImprintPage,
    pageProps: {
      pageId: 'imprint',
      title: 'Imprint',
      visibleForViewer: true,
    },
  },
  {
    path: '/privacy',
    component: PrivacyPage,
    pageProps: {
      pageId: 'privacy',
      title: 'Privacy',
      visibleForViewer: true,
    },
  },
  {
    path: '/privacy-vbbb',
    component: PrivacyPage,
    pageProps: {
      pageId: 'privacy-vbbb',
      title: 'Privacy',
      visibleForViewer: true,
    },
  },
  {
    path: '/assistant',
    component: Assistant,
    exact: true,
    pageProps: {
      pageId: 'assistant',
      title: 'Assistant',
    },
  },
  {
    path: '/recommendations',
    component: Recommendations,
    pageProps: {
      pageId: 'Recommendations',
      title: 'Recommendations',
    },
  },
  {
    path: '/assistant/*',
    component: QuestionnaireRoutes,
    pageProps: {
      pageId: 'questionnaires',
      title: 'Questionnaire',
    },
  },
  {
    path: '/help',
    component: ContactUs,
    pageProps: {
      pageId: 'help',
      title: 'Help',
      visibleForViewer: true,
      visibleForBeneficiary: true,
    },
  },
  {
    path: '/contact/:id',
    component: ContactAssetViewPage,
    pageProps: {
      pageId: 'contactAssetView',
      title: 'Contact Asset View',
    },
  },
  {
    path: '/invalid-token',
    component: () => NotFoundPage(invalidTokenProps),
    pageProps: {
      pageId: 'invalid_token',
      title: 'Invalid Email Token',
      isNoWrapper: true,
      public: true,
    },
  },
  {
    path: '/access',
    component: BeneficiaryAccessPage,
    pageProps: {
      pageId: 'access',
      title: 'Beneficiary Access',
      public: true,
    },
  },
  {
    path: '/zugang',
    component: BeneficiaryAccessPage,
    pageProps: {
      pageId: 'access',
      title: 'Beneficiary Access',
      public: true,
    },
  },
  {
    path: '/beneficiary',
    component: BeneficiaryAssetsPage,
    pageProps: {
      pageId: 'beneficiary',
      title: 'Beneficiary Assets Page',
      visibleForBeneficiary: true,
    },
  },
  {
    path: '/shared',
    component: ImmediateAccessPage,
    exact: true,
    pageProps: {
      pageId: 'immediate_access',
      title: 'Immediate Access',
      visibleForViewer: true,
    },
  },
  {
    path: '/shared/:token',
    component: SetPasswordPageManager,
    pageProps: {
      pageId: 'access_shared_assets',
      title: 'Access Shared Assets',
      public: true,
    },
  },
  {
    path: '/my-agent',
    component: MyAgentPage,
    exact: true,
    pageProps: {
      pageId: 'my_agent',
      title: 'My Agent',
    },
  },
  {
    path: '/incident-report',
    component: DeathReportPage,
    pageProps: {
      pageId: 'incident_report',
      title: 'Incident Report',
      public: true,
    },
  },
  {
    path: '/key-holder/:token',
    component: KeyHolderPage,
    pageProps: {
      pageId: 'key_holder',
      title: 'Key Holder',
      public: true,
    },
  },
  {
    path: '/verify-login/:token',
    component: VerifyAndLogin,
    pageProps: {
      pageId: 'email_verification_status',
      title: 'Email Verification Status',
      public: true,
    },
  },
  {
    path: '/email-verification',
    component: EmailVerificationResultPage,
    pageProps: {
      pageId: 'email_verification_status',
      title: 'Email Verification Status',
      public: true,
    },
  },
  {
    path: '*',
    component: () => NotFoundPage(notFoundPageProps),
    pageProps: {
      pageId: 'not_found',
      title: 'Page not found',
      visibleForViewer: true,
      isNoWrapper: true,
    },
  },
];
