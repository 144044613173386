import * as Sentry from '@sentry/react';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'store/reducers';

import { config } from 'modules/common/helpers';
import {
  ASYNC_ACTION_START,
  ASYNC_ACTION_END,
} from 'store/types/commonActionTypes';

const enhancers: any = [];

const middleware = [thunk];

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Don't send any data for security reasons. Only the type of action to have a better trace
  actionTransformer: (action) => {
    const newAction = action;
    newAction.payload = 'payload removed in store setup for security reasons';
    return newAction;
  },
  stateTransformer: () => null,
});

if (!config.isDevLocal && !!config.sentryDSN) {
  enhancers.push(sentryReduxEnhancer);
}

if (!!config.isDevLocal) {
  const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(
      devToolsExtension({
        actionsDenylist: [ASYNC_ACTION_START, ASYNC_ACTION_END],
      })
    );
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const configureStore = (persistedState?) =>
  createStore(rootReducer, persistedState, composedEnhancers);

export type AppStore = ReturnType<typeof configureStore>;

export default configureStore;
