import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBillingPortalUrl } from 'store/actions/userActions';

const usePortalUrl = () => {
  const dispatch = useDispatch();
  const [portalUrl, setPortalUrl] = useState('');

  useEffect(() => {
    const fetchPortalUrl = async () => {
      const res: any = await dispatch(getBillingPortalUrl());

      if (res && res.sessionId) {
        setPortalUrl(res.sessionId);
      }
    };

    fetchPortalUrl();
  }, [dispatch]);

  return portalUrl;
};

export default usePortalUrl;
