import React from 'react';

import { isJsonString, useTranslation } from 'modules/common/helpers';
import {
  FormControl,
  FormErrors,
  FormGetValues,
  FormRegister,
  FormSetValue,
} from 'modules/common/types';

import { Col, Row } from 'modules/bootstrap/components';
import { CountryInput, TextInputRef } from 'modules/common/components';

import { FIELD_TYPE_KEYS as F } from 'modules/estatePlan/constants/fieldLibrary';
import { COUNTRIES } from 'modules/common/constants/enums';

interface AddressParserInputPros {
  control: FormControl;
  register: FormRegister;
  setValue: FormSetValue;
  getValues: FormGetValues;
  errors: FormErrors;
  required: boolean;
  stringifiedAddress?: string;
  label?: string;
}

const FullAddressInputs = ({
  register,
  control,
  setValue,
  getValues,
  errors,
  required,
  stringifiedAddress = '',
  label = '',
}: AddressParserInputPros) => {
  const { t } = useTranslation(['auth', 'common']);

  const {
    address = '',
    zip = '',
    city = '',
    country = '',
  } = !stringifiedAddress
    ? {}
    : isJsonString(stringifiedAddress)
    ? JSON.parse(stringifiedAddress)
    : { address: stringifiedAddress };

  const getParsedAddressErrors = () => {
    let errStreetAddress = '',
      errZip = '',
      errCity = '';
    if (errors.streetAddress)
      errStreetAddress = `${t('auth:address_label')} ${t('common:required')}`;
    if (errors.zip)
      errZip = `${t('auth:postal_label')} ${t('common:required')}`;
    if (errors.city)
      errCity = `${t('auth:city_label')} ${t('common:required')}`;
    return { errStreetAddress, errZip, errCity };
  };

  const addressFieldErrors =
    (errors[F.STREET_ADDRESS] || errors[F.ZIP] || errors[F.CITY]) &&
    getParsedAddressErrors();

  // TODO: fix placeholder display for the CountryInput

  return (
    <div className="">
      {label && (
        <p className={`Form-label ${addressFieldErrors ? 'isErrored' : ''}`}>
          {t(label)}
        </p>
      )}
      <Row>
        <Col xl={{ span: 8 }}>
          <TextInputRef
            {...register('streetAddress', { required: required })}
            name="streetAddress"
            placeholder={t('auth:address_label')}
            type="text"
            error={addressFieldErrors?.errStreetAddress}
            defaultValue={address}
          />
        </Col>

        <Col xl={{ span: 4 }}>
          <TextInputRef
            {...register('zip', { required: required })}
            name="zip"
            placeholder={t('auth:postal_label')}
            type="text"
            error={addressFieldErrors?.errZip}
            defaultValue={zip}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={{ span: 8 }}>
          <TextInputRef
            {...register('city', { required: required })}
            name="city"
            placeholder={t('auth:city_label')}
            type="text"
            error={addressFieldErrors?.errCity}
            defaultValue={city}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={{ span: 8 }}>
          <CountryInput
            control={control}
            error={(errors.country?.type as string) || ''}
            defaultValue={country || COUNTRIES.DE}
            isRequired={required}
            refSetValue={setValue}
            refGetValues={getValues}
            isDefaultLabelVisible={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FullAddressInputs;
