import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { setModal } from 'store/actions/modalActions';
import {
  optionsHelper,
  useTranslation,
  validationHelper,
} from 'modules/common/helpers';
import { getFormattedProviderAssetByKey } from 'modules/asset/helpers';

import { LinkCurrentAssetModal } from 'modules/asset/components';
import {
  Button,
  SelectController,
  TextInputRef,
} from 'modules/common/components';
import { Checkbox } from 'modules/elements/components';

import {
  CommonFormInputProps,
  FormErrors,
  FormFieldError,
} from 'modules/common/types';

import { ASSET_TYPE_KEYS } from 'modules/estatePlan/constants';
import { MODALS } from 'modules/common/constants';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';

const paymentMethodOptions = optionsHelper.getPaymentMethodOptions();
const paymentFrequencyOptions = optionsHelper.getPaymentFrequencyOptions();

interface AssetFormOnGoingCostsProps extends CommonFormInputProps {
  errors: FormErrors;
  currentAsset: AssetDTO | null;
  onRelatedAssetSave: (asset: AssetDTO | undefined) => void;
  relatedAsset: AssetDTO | undefined;
  assetsToLink: AssetDTO[];
  handleToggleAssetForm: (isOpen: boolean) => void;
}

const AssetFormOnGoingCosts = ({
  control,
  register,
  errors,
  watch,
  setValue,
  onRelatedAssetSave,
  relatedAsset,
  currentAsset,
  assetsToLink,
  handleToggleAssetForm,
}: AssetFormOnGoingCostsProps) => {
  const { t } = useTranslation(['common', 'plan']);
  const dispatch = useDispatch();

  const isGlobalModalOpen = useSelector(
    (state: RootState) => !!state.modal.name
  );

  const [onGoingCostsFieldsOpened, setOnGoingCostsFieldsOpened] =
    React.useState(
      !!currentAsset?.paymentMethod || !!currentAsset?.relatedAssetId
    );
  const [openLinkCurrentAssetModal, setOpenLinkCurrentAssetModal] =
    React.useState(false);
  const [formattedCurrentAsset, setFormattedCurrentAsset] =
    React.useState(currentAsset);

  const displayedFieldValue = relatedAsset
    ? relatedAsset?.assetTypeName === ASSET_TYPE_KEYS.BANK_ACCOUNT
      ? t(`subtypes:${relatedAsset?.subtype}`)
      : relatedAsset.username
    : '';

  React.useEffect(() => {
    if (currentAsset?.provider) {
      getFormattedProviderAsset();
    }
  }, [currentAsset]);

  React.useEffect(() => {
    if (relatedAsset) {
      getFormattedProviderRelatedAsset();
    }
  }, []);

  React.useEffect(() => {
    if (openLinkCurrentAssetModal || isGlobalModalOpen) {
      handleToggleAssetForm(true);
    } else {
      handleToggleAssetForm(false);
    }
  }, [openLinkCurrentAssetModal, isGlobalModalOpen]);

  const defaultPaymentMethod = paymentMethodOptions.find(
    (method) => method.value === formattedCurrentAsset?.paymentMethod
  );
  const defaultPaymentFrequency = paymentFrequencyOptions.find(
    (frequency) => frequency.value === formattedCurrentAsset?.paymentFrequency
  );

  const costAmountErr = errors.costAmount
    ? errors.costAmount?.type === 'validate'
      ? t('common:invalid_number')
      : `${t('plan:on_going_costs_cost_amount_input_label')} ${t(
          'common:required'
        )}`
    : '';

  const getFormattedProviderAsset = async () => {
    const formattedAsset = await getFormattedProviderAssetByKey(
      formattedCurrentAsset || ({} as AssetDTO)
    );
    setFormattedCurrentAsset(formattedAsset);
  };

  const getFormattedProviderRelatedAsset = async () => {
    const formattedAsset = await getFormattedProviderAssetByKey(
      relatedAsset || ({} as AssetDTO)
    );
    onRelatedAssetSave(formattedAsset);
  };

  const toggleOnGoingCostsFields = () => {
    setOnGoingCostsFieldsOpened((prevState) => {
      if (prevState) {
        handleOpenDeleteWarningModal();
        return prevState;
      } else {
        return !prevState;
      }
    });
  };

  const handleOpenLinkCurrentAssetModal = (event) => {
    event?.preventDefault();
    setOpenLinkCurrentAssetModal(true);
  };

  const handleCloseLinkCurrentAssetModal = () => {
    setOpenLinkCurrentAssetModal(false);
  };

  const handleOpenMethodChangeModal = (event) => {
    event?.preventDefault();
    handleWarningModals(true);
  };

  const { paymentMethod, costAmount, paymentFrequency } = watch();
  const handleOpenDeleteWarningModal = () => {
    if (relatedAsset || paymentMethod || costAmount || paymentFrequency) {
      handleWarningModals(false);
    } else {
      setOnGoingCostsFieldsOpened(false);
    }
  };

  const handleWarningModals = (methodChanged: boolean) => {
    const title = methodChanged
      ? t('plan:on_going_costs_method_change_confirm_modal_title')
      : t('plan:on_going_costs_change_warning_modal_title');
    const description = methodChanged
      ? t('plan:on_going_costs_method_change_confirm_modal_desc')
      : '';
    const successAction = () => {
      methodChanged ? onRelatedAssetSave(undefined) : handleOnGoingCostDelete();
    };
    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title,
          description,
          successAction,
          primaryButtonText: t('plan:change'),
        },
      })
    );
  };

  const handleOnGoingCostDelete = () => {
    setValue('paymentMethod', '');
    setValue('costAmount', '');
    setValue('paymentFrequency', '');
    onRelatedAssetSave(undefined);
    const copiedCurrentAsset = formattedCurrentAsset
      ? {
          ...formattedCurrentAsset,
          paymentMethod: '',
          costAmount: '',
          paymentFrequency: '',
        }
      : null;

    setFormattedCurrentAsset(copiedCurrentAsset);
    setOnGoingCostsFieldsOpened(false);
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      {openLinkCurrentAssetModal && (
        <LinkCurrentAssetModal
          assets={assetsToLink}
          onClose={handleCloseLinkCurrentAssetModal}
          onSave={onRelatedAssetSave}
        />
      )}

      <div className="t-mt-4" data-testid="AssetFormOnGoingCosts">
        <Checkbox
          text={t('plan:on_going_costs_checkbox_text')}
          checked={onGoingCostsFieldsOpened}
          id="on_going_costs"
          onChange={toggleOnGoingCostsFields}
          textClassName="typo-epsilon t-text-beta-500"
          isBlueBg
          testId="onGoingCostCheckbox"
        />
        <IconInfo
          role="presentation"
          className="t-inline t-text-delta-700 t-ml-2"
          data-tip={t('plan:on_going_costs_checkbox_tooltip')}
        />

        {onGoingCostsFieldsOpened && (
          <div className="t-mt-6">
            <h2 className="typo-beta">
              {t('plan:on_going_costs_component_header')}
            </h2>
            <p
              className={`typo-epsilon t-mt-4 t-text-beta-500 t-mb-2 ${
                !!errors.paymentMethod && !relatedAsset
                  ? 't-text-epsilon-600'
                  : ''
              }`}
            >
              {t('plan:on_going_costs_component_payment_method')}
            </p>
            {!relatedAsset && (
              <>
                <SelectController
                  rules={{ required: true }}
                  control={control}
                  error={errors.paymentMethod as FormFieldError}
                  id="paymentMethod"
                  name="paymentMethod"
                  options={paymentMethodOptions}
                  defaultValue={defaultPaymentMethod}
                  className={`Select t-w-full ${
                    errors.paymentMethod ? 'isErrored' : ''
                  }`}
                />
                {errors.paymentMethod && (
                  <div className="Form-alert">
                    <span className="text-main-sm t-text-epsilon-600">{`${t(
                      'plan:on_going_costs_component_payment_method'
                    )} ${t('common:required')}`}</span>
                  </div>
                )}
              </>
            )}

            {!relatedAsset && !!assetsToLink.length && (
              <>
                <p className="t-uppercase typo-epsilon t-text-beta-500 t-mt-3.5">
                  {t('common:or')}
                </p>
                <Button
                  className="t-mt-2.5"
                  onClick={handleOpenLinkCurrentAssetModal}
                  testId="linkExistingAssetButton"
                >
                  {t('plan:on_going_costs_link_current_asset_btn')}
                </Button>
              </>
            )}

            {relatedAsset && (
              <div className="t-flex t-items-center t-justify-between t-py-3 t-pl-5 t-pr-2 t-border t-border-solid t-border-beta-200 t-rounded">
                <div className="t-flex t-items-center">
                  <h2 className="typo-beta">{relatedAsset.provider}</h2>
                  <p className="typo-delta t-ml-3">{displayedFieldValue}</p>
                </div>
                <button
                  className="t-text-alpha-600 t-outline-none"
                  onClick={handleOpenMethodChangeModal}
                >
                  {t('plan:change')}
                </button>
              </div>
            )}

            <div className="t-mt-4">
              <TextInputRef
                {...register('costAmount', {
                  required: true,
                  validate: (value) => {
                    if (!value) return true;
                    return validationHelper.isValidNumberNonInteger(value);
                  },
                })}
                label={t('plan:on_going_costs_cost_amount_input_label')}
                name="costAmount"
                type="number"
                defaultValue={formattedCurrentAsset?.costAmount}
                error={costAmountErr}
                placeholder="0,00"
                iconLeft="€"
              />
            </div>

            <p
              className={`typo-epsilon t-text-beta-500 t-mb-2 ${
                errors.paymentFrequency ? 't-text-epsilon-600' : ''
              }`}
            >
              {t('plan:on_going_costs_component_frequency_label')}
            </p>
            <SelectController
              id="paymentFrequency"
              name="paymentFrequency"
              options={paymentFrequencyOptions}
              control={control}
              rules={{ required: true }}
              defaultValue={defaultPaymentFrequency}
              placeholder={t(
                'plan:on_going_costs_payment_frequency_placeholder'
              )}
              className={`Select t-w-full ${
                errors.paymentFrequency ? 'isErrored' : ''
              }`}
            />

            {errors.paymentFrequency && (
              <div className="Form-alert">
                <span className="text-main-sm t-text-epsilon-600">{`${t(
                  'plan:on_going_costs_component_frequency_label'
                )} ${t('common:required')}`}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AssetFormOnGoingCosts;
