import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch } from 'react-redux';

import { createTransmortalAuthorization } from 'store/actions/userActions';
import { getAssetsList } from 'store/actions/assetActions';
import { useTranslation } from 'modules/common/helpers';

import { EpTransmortalContract } from 'modules/estatePlan/components';
import { Button } from 'modules/common/components';

interface EpTransmortalWizardStep1Props {
  setNextStep: (stepNo: number) => void;
  handleClose: () => void;
}

function EpTransmortalWizardStep1(props: EpTransmortalWizardStep1Props) {
  const { setNextStep, handleClose } = props;
  const { t } = useTranslation(['common', 'transmortalAuth']);
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = React.useState(false);

  const handleSign = async () => {
    try {
      setSubmitting(true);
      await dispatch(createTransmortalAuthorization());
      await dispatch(getAssetsList());
    } finally {
      setSubmitting(false);
    }

    setNextStep(2);
  };

  return (
    <>
      <h1 className="Typography Typography--title u-weight-600 t-pb-5">
        {t('transmortalAuth:transmortal_title_sign')}
      </h1>
      <div className="Modal-info u-pr-0 u-pb-30">
        <Scrollbars style={{ height: 340 }}>
          <EpTransmortalContract />
        </Scrollbars>
      </div>

      <div className="t-flex t-flex-wrap t-justify-end u-pt-30">
        <Button
          category="secondary"
          onClick={handleClose}
          className="t-self-end"
          disabled={submitting}
        >
          {t('skip_for_now')}
        </Button>

        <Button
          onClick={handleSign}
          className="t-ml-5 xs:t-mt-5"
          loading={submitting}
        >
          {t('sign_now')}
        </Button>
      </div>
    </>
  );
}

export default EpTransmortalWizardStep1;
