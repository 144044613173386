import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';
import { ASSET_TYPE_KEYS } from 'modules/estatePlan/constants';

import { ReactComponent as CheckIcon } from 'images/check.svg';

interface AccountAssetCardProps {
  asset: AssetDTO;
  selectedAssetId: string;
  handleAssetSelect: (assetId: string) => void;
}

const AccountAssetCard = ({
  asset,
  selectedAssetId,
  handleAssetSelect,
}: AccountAssetCardProps) => {
  const { t } = useTranslation(['common']);
  const { provider, assetTypeName, username, subtype } = asset;

  const displayedField =
    assetTypeName === ASSET_TYPE_KEYS.BANK_ACCOUNT
      ? 'Account type'
      : 'Username';
  const displayedFieldValue =
    (subtype || username) && assetTypeName === ASSET_TYPE_KEYS.BANK_ACCOUNT
      ? t(`subtypes:${subtype}`)
      : username;

  return (
    <div className="card t-w-full t-h-full t-px-5 t-py-4 t-flex t-flex-col t-justify-between">
      <div>
        <h2 className="typo-beta">{provider}</h2>

        {!!displayedFieldValue && (
          <div className="t-mt-4">
            <p className="typo-epsilon t-text-beta-500">{displayedField}</p>
            <p className="typo-delta t-mt-1.5">{displayedFieldValue}</p>
          </div>
        )}
      </div>

      <div className="t-flex t-justify-end t-mt-4">
        <Button
          category="tertiary"
          onClick={() => handleAssetSelect(asset?.id || '')}
          disabled={!!selectedAssetId}
        >
          {!selectedAssetId ? (
            t('common:select')
          ) : (
            <div className="t-flex t-items-center">
              <CheckIcon className="t-w-3 t-h-3 t-mr-2 t-text-alpha-600" />
              <p className="typo-epsilon t-text-alpha-600">
                {t('common:selected')}
              </p>
            </div>
          )}
        </Button>
      </div>
    </div>
  );
};

export default AccountAssetCard;
