export class CustomResponseException extends Error {
  constructor(
    public message: string,
    public status: number,
    public errorCode: string
  ) {
    super(message);
    this.errorCode = errorCode;
    this.message = message;
    this.status = status;
  }
}
