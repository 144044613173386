import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { camelCase } from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { RootState } from 'store/reducers';
import { dataProcessingHelper } from 'modules/assistant/helpers';
import { useTranslation } from 'modules/common/helpers';

import {
  AddContactsProcess,
  MyContactsWidgetContactCard,
} from 'modules/dashboard/components';
import { Button } from 'modules/common/components';

import { NEXT_STEPS } from 'modules/dashboard/constants/nextSteps';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';
import { CONTENT_QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/contentQuestionnaires';

import imgPlantContact from 'images/img-plant-contact.png';
import { ReactComponent as IconEmptyContacts } from 'images/icon_empty_contacts.svg';
import { ReactComponent as IconPlus } from 'images/icon-plus.svg';
import { ReactComponent as IconEye } from 'images/eye.svg';

const questionnaireKeys = Object.values(QUESTIONNAIRE_KEYS) as string[];
const contentQuestionnaireKeys = Object.values(
  CONTENT_QUESTIONNAIRE_KEYS
) as string[];

const checkIfFromAssistant = (recommendation: string) => {
  return [...questionnaireKeys, ...contentQuestionnaireKeys].includes(
    camelCase(recommendation)
  );
};

const MyContactsWidget = () => {
  const { t } = useTranslation(['common', 'dashboard']);

  const contactsData = useSelector((state: RootState) => state.contacts.list);

  // for dynamically changing the height of the widget based on the height of NextStepsWidget
  const nextSteps = useSelector((state: RootState) => state.nextSteps.list);

  const filteredNextSteps = nextSteps?.filter((item) => {
    const isFromAssistant = checkIfFromAssistant(item.recommendation);
    if (!NEXT_STEPS[item.recommendation]?.title && !isFromAssistant)
      return false;
    if (
      isFromAssistant &&
      !dataProcessingHelper.isAvailable(item.recommendation)
    )
      return false;
    return true;
  });

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const initialAssessmentCompleted = currentUser.initialAssessment;
  const isShortWidget =
    !initialAssessmentCompleted || !filteredNextSteps?.length;

  const [showAddContact, setShowAddContactModal] = React.useState(false);

  const isNoContacts = !contactsData.length;

  const handleOpenAddContact = () => {
    setShowAddContactModal(true);
  };
  const handleCancelAddContact = () => {
    setShowAddContactModal(false);
  };

  return (
    <>
      {showAddContact && (
        <AddContactsProcess onCancel={handleCancelAddContact} />
      )}
      <div className="card t-overflow-hidden t-flex t-flex-col t-h-96 xl:t-h-full ">
        <div className="t-bg-gradient-to-r t-from-alpha-700 t-to-alpha-600 t-h-20 t-relative t-flex t-items-center">
          <h1 className="typo-alpha t-pl-3 t-py-6 t-text-gamma-400 t-z-10">
            {t('dashboard:my_contacts_widget_title')}
          </h1>
          <img
            src={imgPlantContact}
            alt="my contacts"
            className="t-absolute t-top-0 t-right-0 t-h-20 t-z-0"
          />
        </div>
        <div
          className={`t-flex-grow t-flex t-flex-col ${
            !isShortWidget ? 't-max-h-96' : 't-max-h-72'
          }`}
        >
          <div
            className={`t-bg-alpha-200 t-pb-5 t-flex-grow ${
              !isNoContacts ? 't-overflow-y-auto' : ''
            }`}
          >
            <Scrollbars>
              {isNoContacts && (
                <div className="t-flex t-flex-col t-justify-center t-items-center t-py-4 t-h-full">
                  <IconEmptyContacts
                    onClick={handleOpenAddContact}
                    className="t-cursor-pointer"
                  />
                  <p className="typo-epsilon t-text-beta-500 t-px-6 t-mt-4 t-text-center">
                    {t('dashboard:my_contacts_widget_no_contact_desc')}
                  </p>
                </div>
              )}

              {!isNoContacts && (
                <div className="t-mt-3 t-px-2.5">
                  <div className="t-flex t-items-center t-mb-3">
                    {!contactsData.length && (
                      <div className="t-w-2.5 t-h-2.5 t-rounded-full t-bg-epsilon-600 t-mr-2" />
                    )}
                  </div>
                  {contactsData.length ? (
                    contactsData.map((contact) => (
                      <MyContactsWidgetContactCard
                        contact={contact}
                        key={contact.id}
                      />
                    ))
                  ) : (
                    <p className="typo-epsilon t-text-beta-500 t-mt-3">
                      {t('dashboard:my_contacts_widget_no_contacts')}
                    </p>
                  )}
                </div>
              )}
            </Scrollbars>
          </div>

          <div
            className={`t-py-4 t-px-2 t-flex t-bg-gamma-400 t-h-16 t-justify-end`}
          >
            {!isNoContacts && (
              <Link to="/my-contacts" className="button-secondary t-mr-2.5">
                <div>
                  <IconEye className="t-text-alpha-600 t-mr-2" />
                </div>

                <p className="t-uppercase t-whitespace-nowrap">
                  {t('common:view')}
                </p>
              </Link>
            )}
            <Button onClick={handleOpenAddContact}>
              <div>
                <IconPlus
                  role="presentation"
                  className="t-inline t-text-gamma-400 t-mr-2"
                />
              </div>

              <span className="t-whitespace-nowrap">{t('common:add')}</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyContactsWidget;
