// This is use for get number with thousands separators (dot)
// Exp: 10000000 -> 10.000.000
// note: 10000,9999 will not work
// convert to the German non-integer number format only
export const getNumberWithDots = (number: number | string) => {
  return number
    .toString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
