import React from 'react';
import { useDispatch } from 'react-redux';

import { revokeKeyHolder } from 'store/actions/keyHolderActions';
import { useTranslation } from 'modules/common/helpers';

import {
  Button,
  Modal,
  WarningTooltipRefresher,
} from 'modules/common/components';
import {
  KeyHolderCard,
  KeyHolderForm,
  RevokeKeyHoldersProcess,
} from 'modules/securityKeyWizard/components';

import { ReactComponent as IconPlus } from 'images/icon-plus.svg';

export interface SetKeyHoldersModalProps {
  onSubmit: () => void;
  onBack: () => void;
  keyHolders: KeyHolder[];
  managing?: boolean;
  subscriptionExpired?: boolean;
}

const SetKeyHoldersModal = ({
  onSubmit,
  onBack,
  keyHolders,
  managing,
  subscriptionExpired,
}: SetKeyHoldersModalProps) => {
  const { t } = useTranslation(['securityKey', 'common']);
  const dispatch = useDispatch();

  const [addingMoreHolder, setAddingMoreHolder] = React.useState(false);
  const [hide, setHide] = React.useState(false);
  const [revokeWarning, setRevokeWarning] = React.useState(false);
  const [revokeId, setRevokeId] = React.useState('');
  const [editing, setEditing] = React.useState(false);

  const handleAddNewKeyHolderSuccess = () => {
    setAddingMoreHolder(false);
  };

  const removeKeyHolder = async (id: string) => {
    if (managing) {
      setRevokeWarning(true);
      setRevokeId(id);
    } else {
      await dispatch(revokeKeyHolder(id));
    }
  };

  const onHide = (hide: boolean) => {
    setHide(hide);
  };

  const handleFormClose = () => {
    setAddingMoreHolder(false);
  };

  const handleRevokeCancel = () => {
    setRevokeWarning(false);
  };

  const handleWhileEditing = (editing: boolean) => {
    setEditing(editing);
  };

  return (
    <>
      {revokeWarning && (
        <Modal show onHide={handleRevokeCancel}>
          <RevokeKeyHoldersProcess
            onCancel={handleRevokeCancel}
            revokeId={revokeId}
          />
        </Modal>
      )}
      {!revokeWarning && (
        <Modal show dataTestid="SetKeyHoldersModal">
          <div className={hide ? 't-hidden' : ''}>
            <h1 className="typo-alpha t-pb-4">
              {managing && !!keyHolders.length
                ? t('securityKey:manage_key_holder_overview_modal_title')
                : t('securityKey:set_key_holders_modal_title')}
            </h1>

            <p className="typo-gamma t-mb-5">
              {managing && !!keyHolders.length
                ? t('securityKey:manage_key_holder_overview_modal_description')
                : t('securityKey:set_key_holders_modal_description')}
            </p>

            {!!keyHolders.length && (
              <div className="t-mb-2">
                {keyHolders.map((keyHolder) => (
                  <KeyHolderCard
                    keyHolder={keyHolder}
                    onDelete={removeKeyHolder}
                    key={keyHolder.email}
                    keyHolders={keyHolders}
                    onToggleParent={onHide}
                    whileEditing={handleWhileEditing}
                  />
                ))}
              </div>
            )}

            {(addingMoreHolder || !keyHolders.length) && !editing && (
              <KeyHolderForm
                onSubmitSuccess={handleAddNewKeyHolderSuccess}
                keyHolders={keyHolders}
                onToggleParent={onHide}
                onClose={handleFormClose}
                submitDisabled={subscriptionExpired}
              />
            )}

            {!addingMoreHolder && !!keyHolders.length && !editing && (
              <div className="t-flex t-my-7">
                <WarningTooltipRefresher id="add-key-holder">
                  <div
                    data-tip={t(
                      'common:feature_use_subscription_expired_tooltip'
                    )}
                    data-tip-disable={!subscriptionExpired}
                    data-for="add-key-holder"
                  >
                    <Button
                      category="secondary"
                      className="t-mb-3"
                      onClick={() => {
                        setAddingMoreHolder(true);
                      }}
                      disabled={subscriptionExpired}
                    >
                      <IconPlus
                        className={`t-mr-3 ${
                          !subscriptionExpired ? 't-text-alpha-600' : ''
                        }`}
                      />
                      {t('securityKey:add_more_key_holder_button')}
                    </Button>
                  </div>
                </WarningTooltipRefresher>
              </div>
            )}

            <div className="t-flex t-flex-col t-items-end sm:t-flex-row sm:t-justify-end t-pb-16 sm:t-pb-0">
              {!keyHolders.length && !managing && (
                <div>
                  <Button
                    testId="cancel"
                    category="secondary"
                    className="t-mb-3 sm:t-mb-0 sm:t-mr-2.5"
                    onClick={onBack}
                  >
                    {t('common:back')}
                  </Button>
                </div>
              )}
              <div>
                <Button
                  category={
                    !keyHolders.length && !!managing ? 'secondary' : 'primary'
                  }
                  disabled={
                    !keyHolders.length && !!managing
                      ? false
                      : !keyHolders.length || addingMoreHolder || editing
                  }
                  onClick={onSubmit}
                >
                  {managing && !keyHolders.length
                    ? t('common:close')
                    : managing
                    ? t('common:save')
                    : t('common:save_and_continue')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SetKeyHoldersModal;
