import { useMemo } from 'react';
import countries from 'i18n-iso-countries';

import { useTranslation } from 'modules/common/helpers';

import countryDataEn from 'i18n-iso-countries/langs/en.json';
import countryDataDe from 'i18n-iso-countries/langs/de.json';

import { COUNTRIES } from 'modules/common/constants/enums';

countries.registerLocale(countryDataEn);
countries.registerLocale(countryDataDe);

const useGetCountries = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return useMemo(() => {
    if (!language) return [];

    const languageObjects = Object.entries(countries.getNames(language)).map(
      ([value, label]) => ({
        value,
        label: Array.isArray(label) ? label[0] : label,
      })
    );

    // move germany to the front
    const germanyIndex = languageObjects.findIndex(
      (lang) => lang.value === COUNTRIES.DE
    );
    languageObjects.unshift(languageObjects.splice(germanyIndex, 1)[0]);

    return languageObjects;
  }, [language]);
};

export default useGetCountries;
