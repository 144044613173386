import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

import { RootState } from 'store/reducers';
import { downloadDocument } from 'store/actions/assetActions';
import { useTranslation } from 'modules/common/helpers';

import { Button, PdfDownloadLink } from 'modules/common/components';
import { Checkbox } from 'modules/elements/components';

import afterDeathIcon1 from 'images/self_key_holder_after_death_step_1.png';
import afterDeathIcon2 from 'images/self_key_holder_after_death_step_2.png';
import afterDeathIcon3 from 'images/self_key_holder_after_death_step_3.png';

export interface SecurityKeyFinalProps {
  handleAddKeyHolders: () => void;
  onCancel: () => void;
  withKeyHolders?: boolean;
  modifying?: boolean;
  deleting?: boolean;
}

const afterDeathStep = [
  {
    image: afterDeathIcon1,
    transKey: 'security_final_after_death_info_modal_1',
    withFile: true,
  },
  {
    image: afterDeathIcon2,
    transKey: 'security_final_after_death_info_modal_2',
  },
  {
    image: afterDeathIcon3,
    transKey: 'security_final_after_death_info_modal_3',
  },
];

function SecurityKeyFinal({
  onCancel,
  handleAddKeyHolders,
  withKeyHolders,
  modifying,
  deleting,
}: SecurityKeyFinalProps) {
  const { t } = useTranslation(['common', 'securityKey']);
  const dispatch = useDispatch();

  const userData: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const [loading, setLoading] = React.useState(false);
  const [boxChecked, setBoxChecked] = React.useState(false);
  const [checkboxErr, setCheckboxErr] = React.useState(false);

  const setWithoutKeyHolders = !withKeyHolders && !modifying && !deleting;

  const onDownload = async () => {
    if (!boxChecked && setWithoutKeyHolders) {
      setCheckboxErr(true);
      return;
    }
    setLoading(true);
    if (userData.document?.documentTitle && !modifying) {
      await dispatch(
        downloadDocument(userData.document, { returnError: true })
      );
    }
    setLoading(false);
    onCancel();
  };

  const handleCheckboxChange = () => {
    setBoxChecked((prev) => !prev);
    setCheckboxErr(false);
  };

  return (
    <div data-testid="SecurityKeyFinal">
      <h1 className="title t-font-semibold t-pb-7 ">
        {modifying && !deleting
          ? t('securityKey:security_key_standard_title')
          : withKeyHolders && !deleting
          ? t('securityKey:security_final_with_key_holders_title')
          : t('securityKey:security_final_standard_title')}
      </h1>

      <p className="t-pb-5">
        {modifying && !deleting
          ? t('securityKey:security_key_standard_description')
          : withKeyHolders && !deleting
          ? t('securityKey:security_final_with_key_holders_description')
          : t('securityKey:security_final_standard_description')}
      </p>

      {!modifying && !deleting && (
        <p className="typo-epsilon t-pb-5">
          {withKeyHolders
            ? t(
                'securityKey:security_final_after_death_info_modal_sub_desc_with_key_holders'
              )
            : t('securityKey:security_final_after_death_info_modal_sub_desc')}
        </p>
      )}

      {!modifying && !deleting && (
        <div className="t-relative">
          {afterDeathStep.map(({ image, transKey, withFile }, idx) => (
            <div className="t-flex t-items-center t-mb-6" key={idx}>
              <img
                src={image}
                alt={`After death step ${idx}`}
                className="t-w-14 t-py-0.5 t-z-50 t-bg-gamma-400"
              />
              <div className="t-ml-5">
                <p
                  className="typo-epsilon"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(t(`securityKey:${transKey}`)),
                  }}
                />
                {withFile &&
                  userData.document?.documentTitle &&
                  !userData.passphraseOptOut && (
                    <div className="t-mb-2 5">
                      <PdfDownloadLink document={userData.document} />
                    </div>
                  )}
              </div>
            </div>
          ))}
          <div className="t-absolute t-h-full t-py-1.5 t-top-0 t-left-7 t-z-0">
            <div className="t-h-full t-w-0 t-border-l-2 t-border-solid t-border-beta-200" />
          </div>
        </div>
      )}

      {setWithoutKeyHolders && (
        <p className="typo-epsilon t-text-delta-700 t-p-2.5 t-mb-7 t-rounded-sm t-bg-delta-100">
          {t('securityKey:security_final_key_holder_adding_recommendation')}
        </p>
      )}

      {setWithoutKeyHolders && (
        <div className="t-mb-7">
          <Checkbox
            text={t('securityKey:assign_key_holder_text')}
            checked={boxChecked}
            id="withKeyHolders"
            onChange={handleCheckboxChange}
            isBlueBg={true}
            isError={checkboxErr}
            textClassName={checkboxErr ? 't-text-epsilon-600' : ''}
          />
        </div>
      )}

      <div className="t-flex t-flex-col t-items-end sm:t-flex-row sm:t-justify-end">
        {setWithoutKeyHolders && (
          <div>
            <Button
              category="secondary"
              onClick={handleAddKeyHolders}
              className="t-mr-3"
            >
              <span className="t-px-3">
                {t('securityKey:add_a_key_holder')}
              </span>
            </Button>
          </div>
        )}
        <div>
          <Button
            onClick={onDownload}
            loading={loading}
            disabled={!boxChecked && setWithoutKeyHolders}
          >
            {modifying || deleting
              ? t('common:finish')
              : t('securityKey:download_and_finish')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SecurityKeyFinal;
