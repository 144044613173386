import React from 'react';
import { isEmpty } from 'lodash';

import { useTranslation } from 'modules/common/helpers';

import { PersonalInfoForm, HowDoesItWorkModal } from 'modules/zvr/components';
import { Button, Modal } from 'modules/common/components';

import { ZVR_EDIT_STEPS } from 'modules/zvr/constants';
import { ReactComponent as IconQuestionCircle } from 'images/icon-question-with-circle.svg';

interface RegisteredZVREditTrustedPersonModalProps {
  handleNextStep: (step: number) => void;
  editContact: ContactDTO;
  handleAddExistingContactModal: (contactId: string) => void;
}

export default function RegisteredZVREditTrustedPersonModal({
  handleNextStep,
  editContact,
  handleAddExistingContactModal,
}: RegisteredZVREditTrustedPersonModalProps) {
  const { t } = useTranslation(['zvrRegistration']);

  const [isSubmitForm, setIsSubmitForm] = React.useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);

  const isNewPersonCreation = isEmpty(editContact);

  const handleSubmitSuccess = () => {
    setIsSubmitForm(false);
    handleNextStep(ZVR_EDIT_STEPS.STEP_TRUSTED_OVERVIEW);
  };

  const handleSubmitFormFalse = () => {
    setIsSubmitForm(false);
  };

  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  const onClose = () => {
    handleNextStep(ZVR_EDIT_STEPS.STEP_TRUSTED_OVERVIEW);
  };

  return (
    <>
      {isInfoModalOpen && <HowDoesItWorkModal onClose={handleInfoModalClose} />}
      <Modal
        show
        onHide={onClose}
        innerCardClassName={`${isInfoModalOpen ? 't-hidden' : ''}`}
      >
        <h1 className="typo-alpha">
          {t(
            `zvrRegistration:${
              isNewPersonCreation
                ? 'add_new_contact_and_update'
                : 'edit_trusted_person_modal_title'
            }`
          )}
        </h1>
        <PersonalInfoForm
          personalObject={editContact}
          isTrustedPerson={true}
          isSubmitForm={isSubmitForm}
          handleSubmitSuccess={handleSubmitSuccess}
          submitFormFalse={handleSubmitFormFalse}
          handleAddExistingContactModal={handleAddExistingContactModal}
          isEditZVR
        />

        <button
          onClick={() => setIsInfoModalOpen(true)}
          type="button"
          className="t-flex t-items-center t-w-full t-justify-end t-mt-8 t-outline-none"
        >
          <IconQuestionCircle className="t-text-alpha-600 t-mr-2" />
          <h4 className="typo-eta t-text-alpha-600">
            {t('zvrRegistration:what_does_that_mean')}
          </h4>
        </button>

        <div className="t-flex t-justify-end t-items-end t-mt-4 t-flex-col sm:t-flex-row t-pb-16 sm:t-pb-0">
          <div>
            <Button
              category="secondary"
              className="t-mr-0 sm:t-mr-3 t-py-2 t-mb-3 sm:t-mb-0"
              disabled={isSubmitForm}
              onClick={onClose}
            >
              {t('zvrRegistration:cancel')}
            </Button>
          </div>

          <div>
            <Button
              className="t-py-2"
              onClick={() => setIsSubmitForm(true)}
              loading={isSubmitForm}
            >
              {t('zvrRegistration:save_continue')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
