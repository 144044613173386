import { reactToast } from 'modules/common/helpers';

import {
  LOAD_PLAN_PROGRESS,
  SET_INTRODUCTION_DONE,
} from 'store/types/planActionTypes';
import { planService } from 'modules/estatePlan/services';
import helper from 'store/actions/actionHelper';

export const loadPlanProgressSuccess = (progress: PlanProgressDTO) =>
  helper.getAction(LOAD_PLAN_PROGRESS, { progress });
const setPlanIntroDoneSuccess = () =>
  helper.getAction(SET_INTRODUCTION_DONE, {});

export const getPlanProgress = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const progress = await planService.getPlanProgress();
    dispatch(loadPlanProgressSuccess(progress));
  }, actionOptions);
};

export const setPlanIntroDone = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const response = await planService.setPlanIntroDone();

    if (response?.message) {
      dispatch(setPlanIntroDoneSuccess());
      reactToast.showMessage(response.message);

      return true;
    }

    if (response.error) reactToast.showError(response.error);
    return false;
  }, actionOptions);
};

export const togglePlanStepStatus = (
  category: string,
  status: number,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async () => {
    await planService.togglePlanStepStatus(category, status);

    return true;
  }, actionOptions);
};

export const createLetter = (
  postalInfo: PostalInfo,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await planService.createLetter(postalInfo);

    if (response?.message) {
      reactToast.showMessage(response.message);
    }

    return response;
  }, actionOptions);
};

export const sendLetter = (
  paymentToken: string,
  letterId: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await planService.sendLetter(paymentToken, letterId);

    if (response?.message) {
      reactToast.showMessage(response.message);
    }

    return true;
  }, actionOptions);
};

export const deleteLetter = (id: string, actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await planService.deleteLetter(id);

    if (response?.message) {
      reactToast.showMessage(response.message);
    }

    return true;
  }, actionOptions);
};
