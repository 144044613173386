import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { getContactOf } from 'store/actions/contactOfActions';
import { generateConfirmationToken } from 'store/actions/userActions';
import {
  getFullName,
  handleResponseError,
  useTranslation,
} from 'modules/common/helpers';

import { Button } from 'modules/common/components';

import {
  ContactOfConfirmationCodeModal,
  ImmediateAccessInfoModal,
} from 'modules/myContacts/components';

import { ERROR_CODES } from 'modules/common/constants';

import { ReactComponent as IconEye } from 'images/eye.svg';

type Input = {
  code: number;
};

interface ContactOfCardProps {
  contactOf: ContactOfDTO;
}

export default function ContactOfCard({ contactOf }: ContactOfCardProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'mcontacts']);
  const [loading, setLoading] = React.useState(false);

  const copiedContactOf = {
    ...contactOf,
    name: contactOf.userName,
    surname: contactOf.userSurname,
  };

  const fullName = getFullName(copiedContactOf, false);
  const fullNameWithTitle = getFullName(copiedContactOf);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Input>();

  const [showConfirmationCodeModal, setShowConfirmationCodeModal] =
    React.useState(false);
  const [fullInfoContactOf, setFullInfoContactOf] = React.useState(contactOf);

  const onShowAccessCode = async (code: number) => {
    const response: any = await dispatch(
      getContactOf(code, contactOf.id, { returnError: true })
    );
    const responseError = handleResponseError(response);

    if (responseError === ERROR_CODES.INVALID_TOKEN) {
      setError('code', { type: 'invalidToken' });
    } else if (!responseError) {
      setFullInfoContactOf(response);
      setShowConfirmationCodeModal(false);
    }
  };

  const handleConfirmationCodeModalOpen = async () => {
    setLoading(true);
    await dispatch(generateConfirmationToken());
    setShowConfirmationCodeModal(true);
    setLoading(false);
  };

  const handleConfirmationCodeModalClose = () => {
    setShowConfirmationCodeModal(false);
  };

  const handleImmediateAccessInfoModalClose = () => {
    setFullInfoContactOf(contactOf);
  };

  return (
    <>
      {fullInfoContactOf?.token && (
        <ImmediateAccessInfoModal
          fullInfoContactOf={fullInfoContactOf}
          onClose={handleImmediateAccessInfoModalClose}
        />
      )}
      {showConfirmationCodeModal && (
        <ContactOfConfirmationCodeModal
          onShowAccessCode={onShowAccessCode}
          onCancel={handleConfirmationCodeModalClose}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
        />
      )}
      <div className="card t-py-6 t-px-7 t-mb-4">
        <p className="typo-delta t-text-beta-700">{fullNameWithTitle}</p>

        <p className="typo-epsilon t-text-beta-500 t-mt-2">
          {t(`mcontacts:contact_of_card_description`, {
            name: fullName,
          })}
        </p>
        <Button
          className="t-mt-3"
          onClick={handleConfirmationCodeModalOpen}
          loading={loading}
        >
          {!loading && <IconEye className="t-mr-2.5" />}
          <span>{t('mcontacts:contact_of_card_show_code_btn')}</span>
        </Button>
      </div>
    </>
  );
}
