import React from 'react';
import { Controller } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-2';

import { useTranslation, validationHelper } from 'modules/common/helpers';
import { FormControl } from 'modules/common/types';

interface PhoneInputProps {
  control: FormControl;
  error: string;
  defaultValue?: string;
  isRequired?: boolean;
  isDefaultLabelVisible?: boolean;
  label?: string;
  name?: string;
}

export default function PhoneInput(props: PhoneInputProps) {
  const {
    control,
    error,
    defaultValue,
    isRequired,
    isDefaultLabelVisible = true,
    label,
    name,
  } = props;
  const { t } = useTranslation(['auth']);

  return (
    <>
      <div className="Form-group" data-testid="PhoneInput">
        {isDefaultLabelVisible && (
          <label
            className={`Form-label ${error ? 'isErrored' : ''}`}
            htmlFor={name || 'phone'}
          >
            {label || t('auth:phone_label')}
          </label>
        )}

        <Controller
          name={name || 'phone'}
          render={({ field }) => (
            <ReactPhoneInput
              country="de"
              preferredCountries={['de']}
              containerClass={error ? 'isErrored' : ''}
              {...field}
              inputProps={{
                id: name || 'phone',
              }}
            />
          )}
          control={control}
          defaultValue={defaultValue}
          rules={{
            required: !!isRequired,
            validate: (value) => {
              if (!isRequired && !value) return true;

              return validationHelper.isValidPhone(`+${value}`);
            },
          }}
        />
        {error && (
          <div className="Form-alert">
            <span className="text-main-sm t-text-epsilon-600">{error}</span>
          </div>
        )}
      </div>
    </>
  );
}
