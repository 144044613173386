import { find } from 'lodash';
import { isJsonString } from 'modules/common/helpers';

export const getFullAddress = (
  stringifiedAddress: string,
  countries: SelectOption[]
) => {
  if (!stringifiedAddress || !countries.length) return '';
  if (!isJsonString(stringifiedAddress)) return stringifiedAddress;
  const addressData = JSON.parse(stringifiedAddress);
  if (addressData.country) {
    addressData.country =
      find(countries, (item) => item.value === addressData.country)?.label ||
      addressData.country;
  }
  return Object.values(addressData).join(', ');
};
