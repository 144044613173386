import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

import iconBeneProcess1 from 'images/icon_bene_process_1.svg';
import iconBeneProcess2 from 'images/icon_bene_process_2.svg';
import iconBeneProcess3 from 'images/icon_bene_process_3.svg';

interface AddContactInfoModalProps {
  onCancel: () => void;
  handleNextStep: () => void;
}

const contactInfo = [
  {
    icon: iconBeneProcess1,
    transKey: 'my_contacts_info_modal_1',
  },
  {
    icon: iconBeneProcess2,
    transKey: 'my_contacts_info_modal_2',
  },
  {
    icon: iconBeneProcess3,
    transKey: 'my_contacts_info_modal_3',
  },
];

function AddContactInfoModal({
  onCancel,
  handleNextStep,
}: AddContactInfoModalProps) {
  const { t } = useTranslation(['dashboard', 'common']);

  return (
    <Modal show onHide={onCancel} className="">
      <h1 className="typo-alpha">
        {t('dashboard:my_contacts_info_modal_title')}
      </h1>

      <p className="typo-gamma t-text-beta-500 t-mt-6">
        {t('dashboard:my_contacts_info_modal_description')}
      </p>

      <div className="t-relative">
        {contactInfo.map(({ icon, transKey }, idx) => (
          <div className="t-flex t-items-center t-mt-6" key={idx}>
            <img
              src={icon}
              alt={`Contact info ${idx}`}
              className="t-w-14 t-py-0.5 t-z-50 t-bg-gamma-400"
            />
            <p className="typo-gamma t-ml-5">{t(`dashboard:${transKey}`)}</p>
          </div>
        ))}
        <div className="t-absolute t-h-full t-py-1.5 t-top-0 t-left-7 t-z-0">
          <div className="t-h-full t-w-0 t-border-l-2 t-border-solid t-border-beta-200" />
        </div>
      </div>

      <div className="t-flex t-justify-end t-items-end t-mt-8">
        <Button
          category="secondary"
          className="t-mr-3 t-py-2"
          onClick={onCancel}
        >
          {t('common:cancel')}
        </Button>

        <Button className="t-py-2" onClick={handleNextStep}>
          {t('common:continue')}
        </Button>
      </div>
    </Modal>
  );
}

export default AddContactInfoModal;
