import icons from 'modules/assistant/constants/questionnaires/icons';
import { STEP_COMPONENT_TYPE as C } from 'modules/assistant/constants/questionnaires';

export enum STEP_KEYS {
  // ids of steps (questions)
  STEP_START = 'start',
  STEP_1 = 'contactSelection',
  STEP_2 = 'substitutePerson',
  STEP_2_1 = 'addSubstitutePerson',
  STEP_4 = 'subAuthorization',
  STEP_5 = 'beyondDeath',
  STEP_6 = 'representation',
  STEP_7 = 'specificationOfRights',
  STEP_8 = 'libertyDeprivation',
  STEP_9 = 'determinateHousing',
  STEP_10 = 'legalRepresentation',
  STEP_11 = 'disposeDigitalEstate',
  STEP_12 = 'otherRights',
  STEP_13 = 'disposeWealth',
  STEP_END = 'end',
}

const S = STEP_KEYS;

export enum HEALTH_CARE_PROXY_ANSWER_KEYS {
  YES = 'true',
  NO = 'false',
  MEDICALREPORT = 'medicalReport',
  MEDICALCONFIRMATION = 'medicalConfirmation',
  FULLREPRESENTATION = 'fullRepresentation',
  OWNSPECIFICATION = 'ownSpecification',
  VIEWDOCUMENTS = 'viewDocuments',
  HEALTHISSUES = 'healthIssues',
  TOWARDSNURSINGSTAFF = 'towardsNursingStaff',
  NOTHINGOFABOVE = 'nothingOfTheAbove',
  LIBERTYDEPRIVATION = 'libertyDeprivation',
  MEDICALMEASURES = 'medialMeasures',
  DETERMINATELOCATION = 'determinateLocation',
  RENTALCONTRACTS = 'rentalContracts',
  NURSINGHOMECONTRACTS = 'nursingHomeContracts',
  AUTHORITIES = 'authorities',
  COURTS = 'courts',
  HIREATTORNEYS = 'hireAttorneys',
  DELETE = 'delete',
  DISPOSE = 'dispose',
  PERSONALMAIL = 'personalMail',
  TELECOMMUNICATION = 'telecommunication',
  BANKREPRESENTATION = 'bankRepresentation',
  INSURANCES = 'insurances',
  NODONATIONS = 'noDonations',
  NODONATIONSTOONESELF = 'noDonationsToOneself',
}

const A = HEALTH_CARE_PROXY_ANSWER_KEYS;

export const getHealthCareProxyQuestionnaire = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    [S.STEP_START]: {
      content: t('s_start'),
      type: C.START,
      linksTo: S.STEP_1,
    },
    [S.STEP_1]: {
      content: t('s_1'),
      current: 1,
      total: 13,
      type: C.MULTI_CONTACTS,
      linksTo: S.STEP_2,
      qa: [
        { question: t('s_1_qa_q_1'), answer: t('s_1_qa_a_1') },
        { question: t('s_1_qa_q_2'), answer: t('s_1_qa_a_2') },
        { question: t('s_1_qa_q_3'), answer: t('s_1_qa_a_3') },
      ],
    },
    [S.STEP_2]: {
      content: t('s_2'),
      current: 2,
      total: 13,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_2_1 },
        { id: A.NO, linksTo: S.STEP_4 },
      ],
    },
    [S.STEP_2_1]: {
      content: t('s_2_1'),
      current: 3,
      total: 13,
      type: C.CONTACT,
      linksTo: S.STEP_4,
    },
    [S.STEP_4]: {
      content: t('s_4'),
      current: 4,
      total: 13,
      infoContent: t('s_info_4'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_5 },
        { id: A.NO, linksTo: S.STEP_5 },
      ],
      qa: [
        { question: t('s_4_qa_q_1'), answer: t('s_4_qa_a_1') },
        { question: t('s_4_qa_q_2'), answer: t('s_4_qa_a_2') },
      ],
    },
    [S.STEP_5]: {
      content: t('s_5'),
      current: 5,
      total: 13,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_6 },
        { id: A.NO, linksTo: S.STEP_6 },
      ],
      qa: [{ question: t('s_5_qa_q_1'), answer: t('s_5_qa_a_1') }],
    },
    [S.STEP_6]: {
      content: t('s_6'),
      current: 6,
      total: 13,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.FULLREPRESENTATION, linksTo: S.STEP_8 },
        { id: A.OWNSPECIFICATION, linksTo: S.STEP_7 },
      ],
    },
    [S.STEP_7]: {
      content: t('s_7'),
      current: 7,
      total: 13,
      type: C.SINGLEANDMULTIPLE, // needs to be added
      linksTo: S.STEP_8,
      possibleAnswers: [
        { id: A.VIEWDOCUMENTS },
        { id: A.HEALTHISSUES },
        { id: A.TOWARDSNURSINGSTAFF },
        // OR
        { id: A.NOTHINGOFABOVE, isNormalChoice: true, isFamilyFailure: true },
      ],
    },
    [S.STEP_8]: {
      content: t('s_8'),
      current: 8,
      total: 13,
      type: C.SINGLEANDMULTIPLE, // needs to be added
      linksTo: S.STEP_9,
      possibleAnswers: [
        { id: A.LIBERTYDEPRIVATION },
        { id: A.MEDICALMEASURES },
        // OR
        { id: A.NOTHINGOFABOVE, isNormalChoice: true, isFamilyFailure: true },
      ],
      qa: [{ question: t('s_8_qa_q_1'), answer: t('s_8_qa_a_1') }],
    },
    [S.STEP_9]: {
      content: t('s_9'),
      current: 9,
      total: 13,
      type: C.SINGLEANDMULTIPLE, // needs to be added
      linksTo: S.STEP_10,
      possibleAnswers: [
        { id: A.DETERMINATELOCATION },
        { id: A.RENTALCONTRACTS },
        { id: A.NURSINGHOMECONTRACTS },
        // OR
        { id: A.NOTHINGOFABOVE, isNormalChoice: true, isFamilyFailure: true },
      ],
      qa: [{ question: t('s_9_qa_q_1'), answer: t('s_9_qa_a_1') }],
    },
    [S.STEP_10]: {
      content: t('s_10'),
      current: 10,
      total: 13,
      type: C.SINGLEANDMULTIPLE, // needs to be added
      linksTo: S.STEP_11,
      possibleAnswers: [
        { id: A.AUTHORITIES },
        { id: A.COURTS },
        { id: A.HIREATTORNEYS },
        // OR
        { id: A.NOTHINGOFABOVE, isNormalChoice: true, isFamilyFailure: true },
      ],
    },
    [S.STEP_11]: {
      content: t('s_11'),
      current: 11,
      total: 13,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.DELETE, linksTo: S.STEP_12 },
        { id: A.DISPOSE, linksTo: S.STEP_12 },
        { id: A.NOTHINGOFABOVE, linksTo: S.STEP_12, isFamilyFailure: true },
      ],
      qa: [
        { question: t('s_11_qa_q_1'), answer: t('s_11_qa_a_1') },
        { question: t('s_11_qa_q_2'), answer: t('s_11_qa_a_2') },
      ],
    },
    [S.STEP_12]: {
      content: t('s_12'),
      current: 12,
      total: 13,
      type: C.SINGLEANDMULTIPLE, // needs to be added
      linksTo: S.STEP_13,
      possibleAnswers: [
        { id: A.PERSONALMAIL },
        { id: A.TELECOMMUNICATION },
        { id: A.BANKREPRESENTATION },
        { id: A.INSURANCES },
        // OR
        { id: A.NOTHINGOFABOVE, isNormalChoice: true, isFamilyFailure: true },
      ],
    },
    [S.STEP_13]: {
      content: t('s_13'),
      current: 13,
      total: 13,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_END },
        { id: A.NO, linksTo: S.STEP_END },
        { id: A.NODONATIONS, linksTo: S.STEP_END },
        { id: A.NODONATIONSTOONESELF, linksTo: S.STEP_END },
      ],
      qa: [{ question: t('s_13_qa_q_1'), answer: t('s_13_qa_a_1') }],
    },
    [S.STEP_END]: {
      content: t('s_end'),
      type: C.END,
    },
  };
};

export const getHealthCareProxyAnswers = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    [A.MEDICALREPORT]: {
      content: t('a_medicalReport'),
      infoContent: t('info_medialReport'),
      image: icons.imgMedicalReport,
    },
    [A.MEDICALCONFIRMATION]: {
      content: t('a_medicalConfirmation'),
      image: icons.imgMedicalConfirmation,
    },
    [A.FULLREPRESENTATION]: {
      content: t('a_fullRepresentation'),
      infoContent: t('info_fullRepresentation'),
      image: icons.imgFullRepresentation,
    },
    [A.OWNSPECIFICATION]: {
      content: t('a_ownSpecification'),
      image: icons.imgOwnSpecification,
    },
    [A.VIEWDOCUMENTS]: {
      content: t('a_viewDocuments'),
      image: icons.imgViewDocuments,
    },
    [A.HEALTHISSUES]: {
      content: t('a_healthIssues'),
      image: icons.imgHealthIssues,
    },
    [A.TOWARDSNURSINGSTAFF]: {
      content: t('a_towardsNursingStaff'),
      image: icons.imgTowardsNursingStaff,
    },
    [A.NOTHINGOFABOVE]: {
      content: t('a_nothingOfTheAbove'),
      image: icons.imgNothing,
    },
    [A.LIBERTYDEPRIVATION]: {
      content: t('a_libertyDeprivation'),
      infoContent: t('info_libertyDeprivation'),
      image: icons.imgLibertyDeprivation,
    },
    [A.MEDICALMEASURES]: {
      content: t('a_medialMeasures'),
      infoContent: t('info_medicalMeasures'),
      image: icons.imgMedicalMeasures,
    },
    [A.DETERMINATELOCATION]: {
      content: t('a_determinateLocation'),
      image: icons.imgDeterminateLocation,
    },
    [A.RENTALCONTRACTS]: {
      content: t('a_rentalContracts'),
      infoContent: t('info_rentalContracts'),
      image: icons.imgRentalContracts,
    },
    [A.NURSINGHOMECONTRACTS]: {
      content: t('a_nursingHomeContracts'),
      image: icons.imgNursingHomeContracts,
    },
    [A.AUTHORITIES]: {
      content: t('a_authorities'),
      image: icons.imgAuthorities,
    },
    [A.COURTS]: {
      content: t('a_courts'),
      image: icons.imgCourts,
    },
    [A.HIREATTORNEYS]: {
      content: t('a_hireAttorneys'),
      image: icons.imgHireAttorneys,
    },
    [A.DELETE]: {
      content: t('a_delete'),
      image: icons.imgDelete,
    },
    [A.DISPOSE]: {
      content: t('a_dispose'),
      infoContent: t('info_dispose'),
      image: icons.imgDispose,
    },
    [A.PERSONALMAIL]: {
      content: t('a_personalMail'),
      infoContent: t('info_personalMail'),
      image: icons.imgPersonalMail,
    },
    [A.TELECOMMUNICATION]: {
      content: t('a_telecommunication'),
      infoContent: t('info_telecommunication'),
      image: icons.imgTelecommunication,
    },
    [A.BANKREPRESENTATION]: {
      content: t('a_bankRepresentation'),
      infoContent: t('info_bankRepresentation'),
      image: icons.imgBankRepresentation,
    },
    [A.INSURANCES]: {
      content: t('a_insurances'),
      infoContent: t('info_insurances'),
      image: icons.imgInsurances,
    },
    [A.NODONATIONS]: {
      content: t('a_noDonations'),
      image: icons.imgNoDonations,
    },
    [A.NODONATIONSTOONESELF]: {
      content: t('a_noDonationsToOneself'),
      image: icons.imgNoDonationsToOneself,
    },
    [A.YES]: {
      content: t('a_true'),
      image: icons.imgYes,
    },
    [A.NO]: {
      content: t('a_false'),
      image: icons.imgNo,
    },
  };
};

export const getHealthCareProxyStartScreen = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    listItems: [
      t('start_screen_list_1'),
      t('start_screen_list_2'),
      t('start_screen_list_3'),
    ],
  };
};
