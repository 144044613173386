import { DASHBOARD_TABLE_ROW_TYPES } from 'modules/dashboard/constants';
import { DASHBOARD_TABLE_COLUMNS } from 'modules/common/constants/enums';
import { ASSET_CATEGORIES } from 'modules/estatePlan/constants';

const getDashboardTableCols = (
  assetCategory: ASSET_CATEGORIES,
  assetType = ''
): DASHBOARD_TABLE_COLUMNS[] => {
  const exactKey: string = assetType
    ? `${assetCategory}_${assetType}`
    : assetCategory;
  const categoryKey = assetCategory;

  if (DASHBOARD_TABLE_ROW_TYPES[exactKey])
    return DASHBOARD_TABLE_ROW_TYPES[exactKey];

  if (DASHBOARD_TABLE_ROW_TYPES[categoryKey])
    return DASHBOARD_TABLE_ROW_TYPES[categoryKey];

  return DASHBOARD_TABLE_ROW_TYPES.COMMON;
};

export default getDashboardTableCols;
