import i18n from 'i18next';
import { SUCCESS_CODES } from 'modules/common/constants';

export const getSuccessMessage = (resMessage: string) => {
  const codes = Object.values(SUCCESS_CODES);
  if (codes.includes(resMessage)) {
    return i18n.t(`successMessages:${resMessage}`);
  }

  return resMessage;
};
