import icons from 'modules/assistant/constants/questionnaires/icons';

import { STEP_COMPONENT_TYPE as C } from 'modules/assistant/constants/questionnaires';

export enum STEP_KEYS {
  // ids of steps (questions)
  // NOTE: we should probably make the below keys as descriptive as we did for the answers
  // because we will store "keyStep: keyAnswer" in the backend
  STEP_START = 'start',
  STEP_1 = 'applicableSituations',
  STEP_1_1 = 'descriptionApplicableSituations',
  STEP_2 = 'lifeSupport',
  STEP_3 = 'painRelief',
  STEP_4 = 'lifeShorteningThroughPainRelief',
  STEP_5 = 'artificialNutrition',
  STEP_6 = 'resuscitation',
  STEP_7 = 'emergencyResuscitation',
  STEP_8 = 'resuscitationCirculatoryArrest',
  STEP_9 = 'artificialRespiration',
  STEP_10 = 'dialysis',
  STEP_11 = 'antibiotics',
  STEP_12 = 'bloodTransfusion',
  STEP_13 = 'placeToDie',
  STEP_14 = 'sourceOfSupport',
  STEP_14_1 = 'contactSourceOfSupport',
  STEP_14_2 = 'churchSourceOfSupport',
  STEP_15 = 'obligationOfSecrecy',
  STEP_15_1 = 'contactObligationOfSecrecy',
  STEP_16 = 'interpretationAuthority',
  STEP_16_1 = 'contactInterpretationAuthority',
  STEP_17 = 'interpretationOfGestures',
  STEP_17_1 = 'contactInterpretationOfGestures',
  STEP_18 = 'healthCareProxy',
  STEP_18_1 = 'healthCareProxyReference',
  STEP_19 = 'careDirective',
  STEP_19_1 = 'careDirectiveReference',
  STEP_20 = 'organDonorCard',
  STEP_20_1 = 'organDonorCardReference',
  STEP_21 = 'priority',
  STEP_22 = 'interpretationAids',
  STEP_22_1 = 'documentInterpretationAids',
  STEP_23 = 'validityPatientDecree',
  STEP_23_1 = 'dateValidityPatientDecree',
  STEP_END = 'end',
}
const S = STEP_KEYS;

export enum PATIENT_DECREE_ANSWER_KEYS {
  YES = 'true',
  NO = 'false',
  PALLIATIVE = 'palliative',
  DEATHPROCESS = 'deathProcess',
  INCURABLEDISEASE = 'incurableDisease',
  BRAINDAMAGE = 'brainDamage',
  BRAINDEGENERATION = 'brainDegeneration',
  DESCRIPTION = 'description',
  SAFELIFE = 'safeLife',
  NOTSAFELIFE = 'notSafeLife',
  REJECTREANIMATION = 'rejectReanimation',
  REFUSEREANIMATION = 'refuseReanimation',
  HOME = 'home',
  HOSPITAL = 'hospital',
  HOSPICE = 'hospice',
  CONTACT = 'contact',
  CHURCH = 'church',
  REPRESENTATIVE = 'representative',
  CAREGIVER = 'caregiver',
  DOCTOR = 'doctor',
  SOMEONEELSE = 'someoneElse',
  NOBODY = 'nobody',
  ORGANDONOR = 'organDonor',
  PATIENTDECREE = 'patientDecree',
  REVOKE = 'revoke',
  TIMELIMIT = 'timelimit',
  REFERENCE = 'reference',
  REFERENCE_DOCUMENT = 'referenceDocument',
  NUMBING = 'numbing',
  NONUMBING = 'noNumbing',
}

const A = PATIENT_DECREE_ANSWER_KEYS;

export const getPatientDecreeQuestionnaire = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    [S.STEP_START]: {
      content: t('s_start'),
      type: C.START,
      linksTo: S.STEP_1,
    },
    [S.STEP_1]: {
      content: t('s_1'),
      current: 1,
      total: 34,
      type: C.MULTIPLE,
      linksTo: S.STEP_2,
      possibleAnswers: [
        { id: A.DEATHPROCESS },
        { id: A.INCURABLEDISEASE },
        { id: A.BRAINDAMAGE },
        { id: A.BRAINDEGENERATION },
        { id: A.DESCRIPTION, linksTo: S.STEP_1_1, isFamilyFailure: true },
      ],
      qa: [{ question: t('s_1_qa_q_1'), answer: t('s_1_qa_a_1') }],
    },
    [S.STEP_1_1]: {
      content: t('s_1_1'),
      current: 2,
      total: 34,
      type: C.FREE_TEXT,
      linksTo: S.STEP_2,
    },
    [S.STEP_2]: {
      content: t('s_2'),
      current: 3,
      total: 34,
      infoContent: t('s_info_2'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.SAFELIFE, linksTo: S.STEP_3 },
        { id: A.NOTSAFELIFE, linksTo: S.STEP_3 },
      ],
      qa: [
        { question: t('s_2_qa_q_1'), answer: t('s_2_qa_a_1') },
        { question: t('s_2_qa_q_2'), answer: t('s_2_qa_a_2') },
      ],
    },
    [S.STEP_3]: {
      content: t('s_3'),
      current: 4,
      total: 34,
      infoContent: t('s_info_3'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.NUMBING, linksTo: S.STEP_4 },
        { id: A.NONUMBING, linksTo: S.STEP_4 },
      ],
      qa: [
        { question: t('s_3_qa_q_1'), answer: t('s_3_qa_a_1') },
        { question: t('s_3_qa_q_2'), answer: t('s_3_qa_a_2') },
      ],
    },
    [S.STEP_4]: {
      content: t('s_4'),
      current: 5,
      total: 34,
      infoContent: t('s_info_4'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_5 },
        { id: A.NO, linksTo: S.STEP_5 },
      ],
      qa: [{ question: t('s_4_qa_q_1'), answer: t('s_4_qa_a_1') }],
    },
    [S.STEP_5]: {
      content: t('s_5'),
      current: 6,
      total: 34,
      infoContent: t('s_info_5'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_6 },
        { id: A.NO, linksTo: S.STEP_6 },
        { id: A.PALLIATIVE, linksTo: S.STEP_6 },
      ],
    },
    [S.STEP_6]: {
      content: t('s_6'),
      current: 7,
      total: 34,
      infoContent: t('s_info_6'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_9 },
        { id: A.NO, linksTo: S.STEP_7 },
      ],
      qa: [
        { question: t('s_6_qa_q_1'), answer: t('s_6_qa_a_1') },
        { question: t('s_6_qa_q_2'), answer: t('s_6_qa_a_2') },
      ],
    },
    [S.STEP_7]: {
      content: t('s_7'),
      current: 8,
      total: 34,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_8 },
        { id: A.NO, linksTo: S.STEP_8 },
      ],
    },
    [S.STEP_8]: {
      content: t('s_8'),
      current: 9,
      total: 34,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_9 },
        { id: A.NO, linksTo: S.STEP_9 },
        { id: A.PALLIATIVE, linksTo: S.STEP_9 },
      ],
    },
    [S.STEP_9]: {
      content: t('s_9'),
      current: 10,
      total: 34,
      infoContent: t('s_info_9'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_10 },
        { id: A.NO, linksTo: S.STEP_10 },
      ],
    },
    [S.STEP_10]: {
      content: t('s_10'),
      current: 11,
      total: 34,
      infoContent: t('s_info_10'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_11 },
        { id: A.NO, linksTo: S.STEP_11 },
      ],
    },
    [S.STEP_11]: {
      content: t('s_11'),
      current: 12,
      total: 34,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_12 },
        { id: A.NO, linksTo: S.STEP_12 },
        { id: A.PALLIATIVE, linksTo: S.STEP_12 },
      ],
    },
    [S.STEP_12]: {
      content: t('s_12'),
      current: 13,
      total: 34,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_13 },
        { id: A.NO, linksTo: S.STEP_13 },
        { id: A.PALLIATIVE, linksTo: S.STEP_13 },
      ],
    },
    [S.STEP_13]: {
      content: t('s_13'),
      current: 14,
      total: 34,
      infoContent: t('s_info_13'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.HOME, linksTo: S.STEP_14 },
        { id: A.HOSPITAL, linksTo: S.STEP_14 },
        { id: A.HOSPICE, linksTo: S.STEP_14 },
      ],
      qa: [{ question: t('s_13_qa_q_1'), answer: t('s_13_qa_a_1') }],
    },
    [S.STEP_14]: {
      content: t('s_14'),
      current: 15,
      total: 34,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.CONTACT, linksTo: S.STEP_14_1 },
        { id: A.CHURCH, linksTo: S.STEP_14_2 },
        { id: A.HOSPICE, linksTo: S.STEP_15 },
      ],
      qa: [{ question: t('s_14_qa_q_1'), answer: t('s_14_qa_a_1') }],
    },
    [S.STEP_14_1]: {
      content: t('s_14_1'),
      current: 16,
      total: 34,
      type: C.CONTACT,
      linksTo: S.STEP_15,
    },
    [S.STEP_14_2]: {
      content: t('s_14_2'),
      current: 17,
      total: 34,
      type: C.FREE_TEXT,
      linksTo: S.STEP_15,
    },
    [S.STEP_15]: {
      content: t('s_15'),
      current: 18,
      total: 34,
      infoContent: t('s_info_15'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_15_1 },
        { id: A.NO, linksTo: S.STEP_16 },
      ],
      qa: [{ question: t('s_15_qa_q_1'), answer: t('s_15_qa_a_1') }],
    },
    [S.STEP_15_1]: {
      content: t('s_15_1'),
      current: 19,
      total: 34,
      type: C.CONTACT,
      linksTo: S.STEP_16,
    },
    [S.STEP_16]: {
      content: t('s_16'),
      current: 20,
      total: 34,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.REPRESENTATIVE, linksTo: S.STEP_17 },
        { id: A.CAREGIVER, linksTo: S.STEP_17 },
        { id: A.DOCTOR, linksTo: S.STEP_17 },
        { id: A.SOMEONEELSE, linksTo: S.STEP_16_1 },
        { id: A.NOBODY, linksTo: S.STEP_17, isFamilyFailure: true },
      ],
    },
    [S.STEP_16_1]: {
      content: t('s_16_1'),
      current: 21,
      total: 34,
      type: C.CONTACT,
      linksTo: S.STEP_17,
    },
    [S.STEP_17]: {
      content: t('s_17'),
      current: 22,
      total: 34,
      infoContent: t('s_info_17'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.REPRESENTATIVE, linksTo: S.STEP_18 },
        { id: A.CAREGIVER, linksTo: S.STEP_18 },
        { id: A.DOCTOR, linksTo: S.STEP_18 },
        { id: A.SOMEONEELSE, linksTo: S.STEP_17_1 },
        { id: A.NOBODY, linksTo: S.STEP_18, isFamilyFailure: true },
      ],
    },
    [S.STEP_17_1]: {
      content: t('s_17_1'),
      current: 23,
      total: 34,
      type: C.CONTACT,
      linksTo: S.STEP_18,
    },
    [S.STEP_18]: {
      content: t('s_18'),
      current: 24,
      total: 34,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.REFERENCE_DOCUMENT, linksTo: S.STEP_18_1 },
        { id: A.NO, linksTo: S.STEP_19 },
      ],
    },
    [S.STEP_18_1]: {
      content: t('s_18_1'),
      current: 25,
      total: 34,
      type: C.FREE_TEXT,
      linksTo: S.STEP_19,
    },
    [S.STEP_19]: {
      content: t('s_19'),
      current: 26,
      total: 34,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.REFERENCE_DOCUMENT, linksTo: S.STEP_19_1 },
        { id: A.NO, linksTo: S.STEP_20 },
      ],
    },
    [S.STEP_19_1]: {
      content: t('s_19_1'),
      current: 27,
      total: 34,
      type: C.FREE_TEXT,
      linksTo: S.STEP_20,
    },
    [S.STEP_20]: {
      content: t('s_20'),
      current: 28,
      total: 34,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.REFERENCE, linksTo: S.STEP_20_1 },
        { id: A.NO, linksTo: S.STEP_21 },
      ],
    },
    [S.STEP_20_1]: {
      content: t('s_20_1'),
      current: 29,
      total: 34,
      type: C.FREE_TEXT,
      linksTo: S.STEP_21,
    },
    [S.STEP_21]: {
      content: t('s_21'),
      current: 30,
      total: 34,
      infoContent: t('s_info_21'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.ORGANDONOR, linksTo: S.STEP_22 },
        { id: A.PATIENTDECREE, linksTo: S.STEP_22 },
      ],
    },
    [S.STEP_22]: {
      content: t('s_22'),
      current: 31,
      total: 34,
      infoContent: t('s_info_22'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_22_1 },
        { id: A.NO, linksTo: S.STEP_23 },
      ],
      qa: [{ question: t('s_22_qa_q_1'), answer: t('s_22_qa_a_1') }],
    },
    [S.STEP_22_1]: {
      content: t('s_22_1'),
      current: 32,
      total: 34,
      type: C.FREE_TEXT,
      linksTo: S.STEP_23,
    },
    [S.STEP_23]: {
      content: t('s_23'),
      current: 33,
      total: 34,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.REVOKE, linksTo: S.STEP_END },
        { id: A.TIMELIMIT, linksTo: S.STEP_23_1 },
      ],
    },
    [S.STEP_23_1]: {
      content: t('s_23_1'),
      current: 34,
      total: 34,
      type: C.EXPIRATION_DATE,
      linksTo: S.STEP_END,
    },
    [S.STEP_END]: {
      content: t('s_end'),
      type: C.END,
    },
  };
};

export const getPatientDecreeAnswers = (
  questionnaire,
  i18nTFunc
): PatientAnswers => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);

  return {
    [A.DEATHPROCESS]: {
      content: t('a_deathProcess'),
      infoContent: t('info_deathProcess'),
      image: icons.imgDeathProcess,
    },
    [A.INCURABLEDISEASE]: {
      content: t('a_incurableDisease'),
      image: icons.imgIncurableDisease,
    },
    [A.BRAINDAMAGE]: {
      content: t('a_brainDamage'),
      infoContent: t('info_brainDamage'),
      image: icons.imgBrainDamage,
    },
    [A.BRAINDEGENERATION]: {
      content: t('a_brainDegeneration'),
      infoContent: t('info_brainDegeneration'),
      image: icons.imgBrainDegeneration,
    },
    [A.DESCRIPTION]: {
      content: t('a_description'),
      // here needs to follow a free text user input
      image: icons.imgDescription,
    },
    [A.SAFELIFE]: {
      content: t('a_safeLife'),
      image: icons.imgSafeLife,
    },
    [A.NOTSAFELIFE]: {
      content: t('a_notSafeLife'),
      image: icons.imgNotSafeLife,
    },
    [A.HOME]: {
      content: t('a_home'),
      image: icons.imgHome,
    },
    [A.HOSPITAL]: {
      content: t('a_hospital'),
      image: icons.imgHospital,
    },
    [A.HOSPICE]: {
      content: t('a_hospice'),
      infoContent: t('info_hospice'),
      image: icons.imgHospice,
    },
    [A.CONTACT]: {
      content: t('a_contact'),
      infoContent: t('info_contact'),
      image: icons.imgContact,
    },
    [A.CHURCH]: {
      content: t('a_church'),
      // here needs to follow a free text user input
      image: icons.imgChurch,
    },
    [A.REPRESENTATIVE]: {
      content: t('a_representative'),
      image: icons.imgRepresentative,
    },
    [A.CAREGIVER]: {
      content: t('a_caregiver'),
      image: icons.imgCaregiver,
    },
    [A.DOCTOR]: {
      content: t('a_doctor'),
      image: icons.imgDoctor,
    },
    [A.SOMEONEELSE]: {
      content: t('a_someoneElse'),
      // here it should link to the contact selection
      image: icons.imgSomeoneElse,
    },
    [A.NOBODY]: {
      content: t('a_nobody'),
      image: icons.imgNobody,
    },
    [A.ORGANDONOR]: {
      content: t('a_organDonor'),
      infoContent: t('info_organDonor'),
      image: icons.imgOrganDonor,
    },
    [A.PATIENTDECREE]: {
      content: t('a_patientDecree'),
      image: icons.imgPatientDecree,
    },
    [A.REVOKE]: {
      content: t('a_revoke'),
      image: icons.imgRevoke,
    },
    [A.TIMELIMIT]: {
      content: t('a_timelimit'),
      // should be followed by a date picker UI
      image: icons.imgTimelimit,
    },
    [A.YES]: {
      content: t('a_true'),
      image: icons.imgYes,
    },
    [A.NO]: {
      content: t('a_false'),
      image: icons.imgNo,
    },
    [A.REFERENCE]: {
      content: t('a_reference'),
      image: icons.imgReference,
    },
    [A.REFERENCE_DOCUMENT]: {
      content: t('a_referenceDocument'),
      image: icons.imgReferenceDocument,
    },
    [A.PALLIATIVE]: {
      content: t('a_palliative'),
      infoContent: t('info_palliative'),
      image: icons.imgPalliative,
    },
    [A.NUMBING]: {
      content: t('a_numbing'),
      infoContent: t('info_numbing'),
      image: icons.imgYes,
    },
    [A.NONUMBING]: {
      content: t('a_noNumbing'),
      infoContent: t('info_noNumbing'),
      image: icons.imgNo,
    },
  };
};

export const getPatientDecreeStartScreen = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    listItems: [
      t('start_screen_list_1'),
      t('start_screen_list_2'),
      t('start_screen_list_3'),
    ],
  };
};
