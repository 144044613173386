import React, { FormEvent, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { reportDeath } from 'store/actions/afterDeathActions';
import { dateHelper, useTranslation, utilHelper } from 'modules/common/helpers';
import { config, reactToast } from 'modules/common/helpers';
import { handleResponseError } from 'modules/common/helpers';

import {
  StaticFile,
  TextInputRef,
  BirthdayInput,
  TextAreaRef,
  PhoneInput,
  Button,
} from 'modules/common/components';
import { UploadDocumentButton } from 'modules/document/components';

import { ReactComponent as DeleteIcon } from 'images/icon-delete.svg';

type Inputs = {
  userFullName: string;
  userEmail?: string;
  userBirthday?: string;
  userPlaceOfBirth?: string;
  otherInfo?: string;
  reporterFullName?: string;
  reporterPhone?: string;
  reporterEmail?: string;
};

interface IncidentReportFormProps {
  onSuccess: () => void;
}

const IncidentReportForm = ({ onSuccess }: IncidentReportFormProps) => {
  const { t } = useTranslation(['common', 'auth']);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,

    formState: { errors },
  } = useForm<Inputs>();

  const [file, setFile] = useState<File | null>(null);
  const [fileErr, setFileErr] = useState('');
  const [isNextStep, setIsNextStep] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDocumentUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target || !event.target.files || !event.target.files[0]) {
      return;
    }

    const newFile = event.target.files[0];

    if (newFile.size > config.maxDocumentSize) {
      reactToast.showWarning(t('common:warning_file_too_large'));
      return;
    }

    setFileErr('');
    setFile(newFile);
  };

  const errEmail = (fieldName) => {
    if (errors[fieldName]?.type === 'required') {
      return `${t(`afterDeath:${fieldName}_label`)} ${t('common:required')}`;
    }
    if (errors[fieldName]) {
      return `${t('auth:email_not_valid')}`;
    }
    return null;
  };

  const onSubmit = async (formData: Inputs) => {
    if (!isNextStep) {
      setIsNextStep(true);
      return;
    }

    const formDataObj = new FormData();
    if (!!file) {
      formDataObj.set('document', file);
    }

    Object.entries(formData).forEach(([key, value]) => {
      let resValue = value;
      const resKey = key;

      switch (key) {
        case 'userBirthday':
          resValue = formData.userBirthday
            ? dateHelper.convertDateFormat(
                formData.userBirthday,
                config.format.serverDate
              )
            : '';
          break;
        default:
          break;
      }

      formDataObj.set(resKey, resValue as string);
    });

    setLoading(true);
    const response: any = await dispatch(
      reportDeath(formDataObj, true, { returnError: true })
    );
    const error = handleResponseError(response);

    if (error) {
      reactToast.showError(`errors:${error}`);
      setLoading(false);
    } else {
      onSuccess();
    }
  };

  const submit = (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (!file?.name) {
      setFileErr(
        `${t('afterDeath:death_report_upload_title')} ${t('common:required')}`
      );
    }

    handleSubmit(async () => {
      if (!file?.name) {
        return;
      }
      await onSubmit(getValues());
    })();
  };

  return (
    <div className="card t-shadow-lg t-bg-beta-50 t-p-6 lg:t-px-12 lg:t-pt-12 t-w-full lg:t-w-120">
      <h2 className="typo-beta t-mb-5">
        {isNextStep
          ? t('afterDeath:death_report_reporter_form_label')
          : t('afterDeath:death_report_form_label')}
      </h2>
      <p className="typo-epsilon t-mt-5">
        {isNextStep
          ? t('afterDeath:death_report_reporter_form_description')
          : t('afterDeath:death_report_form_description')}
      </p>
      <form
        onSubmit={submit}
        className="Form t-w-full lg:t-w-96 t-bg-transparent t-mt-5"
        data-testid="incident-report-form"
      >
        <fieldset
          disabled={loading}
          className={isNextStep ? 't-hidden' : ''}
          data-testid="form-part-1"
        >
          <div className="t-mb-5">
            {!file?.name && (
              <>
                <p
                  className={`typo-epsilon t-text-beta-200 t-mb-1.5 ${
                    fileErr ? 't-text-epsilon-600' : ''
                  }`}
                >
                  {t('afterDeath:death_report_upload_title')}
                </p>

                <div className="t-flex t-justify-between t-items-center">
                  <UploadDocumentButton
                    onDocumentUpload={handleDocumentUpload}
                    title={t('common:browse_to_choose_a_file')}
                  />

                  <div className="text-main-sm t-text-beta-400">
                    {t('common:file_up_to')} {utilHelper.getMaxFileAssetSize()}
                  </div>
                </div>
              </>
            )}
            {file?.name && (
              <div className="t-mb-2 5 t-flex t-justify-between t-items-center">
                <StaticFile documentTitle={file?.name} />
                <button
                  onClick={() => {
                    setFile(null);
                  }}
                  className="typo-theta t-text-delta-500 t-flex t-items-center t-whitespace-nowrap t-ml-4"
                >
                  <DeleteIcon className="t-mr-0.5 t-text-epsilon-400" />
                  {t('common:delete_label')}
                </button>
              </div>
            )}

            {fileErr && (
              <div className="Form-alert">
                <span className="text-main-sm t-text-epsilon-600">
                  {fileErr}
                </span>
              </div>
            )}
          </div>
          <TextInputRef
            {...register('userFullName', { required: true })}
            label={t('afterDeath:userFullName_label')}
            type="text"
            autoComplete={false}
            defaultValue=""
            error={
              errors.userFullName &&
              `${t('afterDeath:userFullName_label')} ${t('common:required')}`
            }
          />

          <div className="t-mt-2">
            <TextInputRef
              {...register('userEmail', {
                validate: (value) => {
                  if (!value?.trim()) return true;
                  return isEmail(value);
                },
              })}
              label={`${t('afterDeath:userEmail_label')} (${t(
                'common:optional'
              )})`}
              type="email"
              autoComplete={false}
              defaultValue=""
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('userEmail', e.target.value.trim())
              }
              error={errEmail('userEmail')}
            />
          </div>

          <div className="t-mt-2">
            <BirthdayInput
              control={control}
              error={errors['userBirthday']?.message || ''}
              defaultValue=""
              customName="userBirthday"
              label={`${t('afterDeath:userBirthday_label')} (${t(
                'common:optional'
              )})`}
            />
          </div>

          <div className="t-mt-2">
            <TextInputRef
              {...register('userPlaceOfBirth')}
              label={`${t('afterDeath:userPlaceOfBirth_label')} (${t(
                'common:optional'
              )})`}
              type="text"
              autoComplete={false}
              defaultValue=""
              error=""
            />
          </div>

          <div className="t-mt-2">
            <TextAreaRef
              {...register('otherInfo')}
              label={`${t('afterDeath:information_field_label')} (${t(
                'common:optional'
              )})`}
              defaultValue=""
              maxLength={2000}
              rows={3}
              error=""
            />
          </div>
        </fieldset>

        <fieldset
          disabled={loading}
          className={!isNextStep ? 't-hidden' : ''}
          data-testid="form-part-2"
        >
          <div>
            <TextInputRef
              {...register('reporterFullName', {
                required: isNextStep,
              })}
              label={t('afterDeath:reporterFullName_label')}
              type="text"
              autoComplete={false}
              defaultValue=""
              error={
                errors.reporterFullName &&
                `${t('afterDeath:reporterFullName_label')} ${t(
                  'common:required'
                )}`
              }
            />
          </div>

          <div>
            <TextInputRef
              {...register('reporterEmail', {
                required: isNextStep,
                validate: (value) => {
                  if (!isNextStep && !value?.trim()) return true;
                  else if (isNextStep && !value?.trim()) return false;
                  return isEmail(value || '');
                },
              })}
              label={t('afterDeath:reporterEmail_label')}
              type="email"
              autoComplete={false}
              defaultValue=""
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('reporterEmail', e.target.value.trim())
              }
              error={errEmail('reporterEmail')}
            />
          </div>

          <div>
            <PhoneInput
              name="reporterPhone"
              control={control}
              error={
                errors.reporterPhone
                  ? `${t('afterDeath:reporterPhone_label')} ${t(
                      'common:invalid'
                    )}`
                  : ''
              }
              defaultValue=""
              label={`${t('afterDeath:reporterPhone_label')} (${t(
                'common:optional'
              )})`}
            />
          </div>
        </fieldset>

        <div className="t-flex t-justify-end t-pt-5">
          <Button
            loading={loading}
            type="submit"
            className="t-whitespace-nowrap"
          >
            <span className="u-pl-10 u-pr-10">
              {isNextStep ? t('common:submit') : t('common:continue')}
            </span>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default IncidentReportForm;
