export const getOrganDonorCardQuestionnaire = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return [
    {
      title: t('s_start_title'),
      content: t('s_start_content'),
    },
    {
      title: t('s_1_title'),
      content: t('s_1_content'),
    },
    {
      title: t('s_2_title'),
      content: t('s_2_content'),
    },
    {
      title: t('s_3_title'),
      content: t('s_3_content'),
    },
    {
      title: t('s_4_title'),
      content: t('s_4_content'),
    },
    {
      title: t('s_5_title'),
      content: t('s_5_content'),
    },
    {
      title: t('s_6_title'),
      content: t('s_6_content'),
    },
    {
      title: t('s_7_title'),
      content: t('s_7_content'),
    },
    {
      title: t('s_8_title'),
      content: t('s_8_content'),
    },
    {
      title: t('s_9_title'),
      content: t('s_9_content'),
    },
    {
      title: t('s_10_title'),
      content: t('s_10_content'),
    },
    {
      title: t('s_11_title'),
      content: t('s_11_content'),
    },
    {
      title: t('s_12_title'),
      content: t('s_12_content'),
    },
    {
      title: t('s_13_title'),
      content: t('s_13_content'),
    },
    {
      title: t('s_14_title'),
      content: t('s_14_content'),
    },
    {
      title: t('s_end_title'),
      content: t('s_end_content'),
    },
  ];
};
