import React, { ReactNode } from 'react';
import ContentLoader from 'react-content-loader';

interface SkeletonProps {
  viewBox: string;
  height: string;
  width: string;
  children: ReactNode | ReactNode[];
}

export default function Skeleton({
  viewBox,
  width,
  height,
  children,
}: SkeletonProps) {
  return (
    <ContentLoader
      width={width}
      height={height}
      viewBox={viewBox}
      backgroundColor="var(--color-beta-200)"
      foregroundColor="var(--color-beta-100)"
      speed={2}
    >
      {children}
    </ContentLoader>
  );
}
