import helper from 'store/actions/actionHelper';

import { HANDLE_ASSETS_SEARCHING } from 'store/types/assetTypes';

export const handleAssetsSearching = (
  data: AssetsSearchDTO,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAction((dispatch) => {
    dispatch(helper.successAction(HANDLE_ASSETS_SEARCHING, { data }));
  }, actionOptions);
};
