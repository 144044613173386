import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import {
  navigateToStep,
  updateQuestionnaire,
} from 'store/actions/questionnaireActions';
import { getStepsArrayFromStore } from 'modules/assistant/helpers/questionnaireHelper';
import { useTranslation } from 'modules/common/helpers';

import { Card } from 'modules/elements/components';
import { EndScreenChoice } from 'modules/assistant/components/Answers/EndScreen';

import {
  getQuestionnaire,
  QUESTIONNAIRE_KEYS,
  STEP_COMPONENT_TYPE,
} from 'modules/assistant/constants/questionnaires';
import { STEP_KEYS } from 'modules/assistant/constants/questionnaires/patientDecree';

export interface EndScreenProps {
  questionnaire: QUESTIONNAIRE_KEYS;
}

const EndScreen = ({ questionnaire }: EndScreenProps) => {
  const { t } = useTranslation([questionnaire]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateQuestionnaire(
        questionnaire,
        STEP_KEYS.STEP_END,
        '',
        STEP_KEYS.STEP_END,
        STEP_COMPONENT_TYPE.END
      )
    );
  }, []);

  const questionnaireData = useSelector(
    (state: RootState) => state.questionnaires[questionnaire]
  );
  const stepsCollection = getStepsArrayFromStore(questionnaireData);

  const QUESTIONNAIRE = getQuestionnaire(questionnaire, t);

  const getAnswersArray = (
    stepKey: STEP_KEYS,
    choiceType: STEP_COMPONENT_TYPE
  ): AnswerElement[] => {
    const answerValue = questionnaireData[stepKey];
    const { possibleAnswers }: { possibleAnswers: SelectedAnswer[] } =
      QUESTIONNAIRE[stepKey];
    const answerArr = Array.isArray(answerValue) ? answerValue : [answerValue];

    switch (choiceType) {
      case STEP_COMPONENT_TYPE.SINGLE:
      case STEP_COMPONENT_TYPE.MULTIPLE:
      case STEP_COMPONENT_TYPE.SINGLEANDMULTIPLE:
        return possibleAnswers.map((item) => ({
          text: t(`aes_${item.id}`),
          isSelected: answerArr.includes(item.id),
        }));
      case STEP_COMPONENT_TYPE.FREE_TEXT:
      case STEP_COMPONENT_TYPE.CONTACT:
      case STEP_COMPONENT_TYPE.EXPIRATION_DATE:
        return [{ text: answerValue }];
      case STEP_COMPONENT_TYPE.HEIR_CONTACTS:
      case STEP_COMPONENT_TYPE.BANK_POA_CONTACT:
      case STEP_COMPONENT_TYPE.SUBSTITUTE_HEIR:
      case STEP_COMPONENT_TYPE.INHERITANCE_DISTRIBUTION:
      case STEP_COMPONENT_TYPE.PARTNER_PROFILE:
      case STEP_COMPONENT_TYPE.BEQUEST:
      case STEP_COMPONENT_TYPE.MULTI_CONTACTS:
      case STEP_COMPONENT_TYPE.FORM:
      case STEP_COMPONENT_TYPE.ADD_INFORMATION:
        return answerValue;
      default:
        return [];
    }
  };

  return (
    <Card>
      <div className="d-flex flex-column">
        {stepsCollection
          .filter(
            (stepKey) =>
              ![STEP_KEYS.STEP_START, STEP_KEYS.STEP_END].includes(stepKey)
          )
          .map((stepKey) => {
            if (!QUESTIONNAIRE[stepKey]) return null;

            return (
              <EndScreenChoice
                choiceType={QUESTIONNAIRE[stepKey].type}
                title={QUESTIONNAIRE[stepKey].content}
                answers={getAnswersArray(stepKey, QUESTIONNAIRE[stepKey].type)}
                handleClick={() =>
                  dispatch(navigateToStep(questionnaire, stepKey))
                }
                key={stepKey}
                questionnaire={questionnaire}
                stepKey={stepKey}
              />
            );
          })}
      </div>
    </Card>
  );
};

export default EndScreen;
