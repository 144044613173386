import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import ReactTooltip from 'react-tooltip';

import { RootState } from 'store/reducers';
import {
  translateHelper,
  scrollToElementId,
  useTranslation,
} from 'modules/common/helpers';
import { getSelectOptionsContacts } from 'modules/contact/helpers';

import { FormControl, FormErrors, FormWatch } from 'modules/common/types';
import { Button, SelectController } from 'modules/common/components';
import { ContactModal } from 'modules/contact/components';

import { REDIRECT_ACTIONS } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';

import { ReactComponent as IconExclamation } from 'images/exclamation-mark-with-circle.svg';

interface SelectContactInputProps {
  control: FormControl;
  errors: FormErrors;
  watch: FormWatch;
  defaultValue?: ContactDTO[];
  assetType?: string;
  dataOnRedirect: Record<string, string>;
  handleToggleAssetForm: (isOpen: boolean) => void;
}

export default function SelectContactInput(props: SelectContactInputProps) {
  const {
    control,
    errors,
    defaultValue,
    assetType,
    watch,
    dataOnRedirect = {},
    handleToggleAssetForm,
  } = props;
  const { t, i18n } = useTranslation(['common']);

  const { actionType, elementId } = dataOnRedirect;
  const [isAssignToFieldHighlighted, setIsAssignToFieldHighlighted] =
    useState(false);
  const selectedContactIds = watch('contactIds');

  useEffect(() => {
    if (
      elementId &&
      actionType === REDIRECT_ACTIONS.HIGHLIGHT_ASSIGN_TO_FIELD
    ) {
      setIsAssignToFieldHighlighted(true);
    }
    if (isAssignToFieldHighlighted) {
      scrollToElementId(elementId);
    }
  }, [actionType, elementId]);

  const contactsData: ContactDTO[] = useSelector(
    (state: RootState) => state.contacts.list
  );

  const [showContactModal, setShowContactModal] = useState(false);

  const handleCloseContactModal = () => {
    handleToggleAssetForm(false);
    setShowContactModal(false);
  };
  const handleShowContactModal = () => {
    handleToggleAssetForm(true);
    setShowContactModal(true);
  };

  const contactsOptions = getSelectOptionsContacts(contactsData);
  contactsOptions.push({ value: '-1', label: 'new' });

  const defaultOptions = getSelectOptionsContacts(defaultValue);

  const customStyles = {
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.backgroundColor,
      };
    },
    control: (styles) => {
      return {
        ...styles,
        height: 'auto !important',
        minHeight: '36px !important',
      };
    },
  };

  return (
    <div>
      {showContactModal && (
        <ContactModal handleClose={handleCloseContactModal} />
      )}

      <div className="t-flex t-items-center t-pb-2">
        {isAssignToFieldHighlighted && !selectedContactIds?.length && (
          <div className="t-rounded t-mr-2 t-bg-epsilon-600 t-w-2.5 t-h-2.5"></div>
        )}
        <p
          className={`Form-label t-p-0 ${errors.contactIds ? 'isErrored' : ''}`}
        >
          {translateHelper.getAssignToLabel(assetType, t, i18n)}
        </p>
      </div>

      <SelectController
        id="contactIds"
        name="contactIds"
        options={contactsOptions}
        control={control}
        isMulti
        styles={customStyles}
        components={{
          Option: (selectProps) => (
            <AddContactOption
              {...selectProps}
              handleClick={handleShowContactModal}
            />
          ),
        }}
        defaultValue={defaultOptions}
        className={`Select ${
          errors.contactIds ||
          (isAssignToFieldHighlighted && !selectedContactIds?.length)
            ? 'isErrored'
            : ''
        } `}
      />
      {errors.contactIds && (
        <div className="Form-alert">
          <span className="text-main-sm t-text-epsilon-600">
            {`${t('common:contact')} ${t('common:required')}`}
          </span>
        </div>
      )}
    </div>
  );
}

AddContactOption.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    missingInfo: PropTypes.object,
  }).isRequired,
  innerRef: PropTypes.func,
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.any,
};

function AddContactOption(props) {
  const { data, innerRef, handleClick } = props;
  const { t } = useTranslation(['common']);
  const { missingInfo } = data;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return data.value === '-1' && data.label === 'new' ? (
    <div className="t-flex t-pt-5">
      <Button category="secondary" ref={innerRef} onClick={handleClick}>
        {t('common:add_contact_caption')}
      </Button>
    </div>
  ) : (
    <components.Option {...props}>
      <div className="t-flex">
        {props.children}
        {(missingInfo.birthday || missingInfo.gender) && (
          <>
            <div
              data-tip={`${
                missingInfo.birthday
                  ? t('mcontacts:asset_form_date_of_birth')
                  : ''
              }${
                missingInfo.birthday && missingInfo.gender
                  ? t('mcontacts:form_and_text')
                  : ''
              }${
                missingInfo.gender ? t('mcontacts:asset_form_gender') : ''
              } ${t('mcontacts:asset_form_is_missing')}`}
              data-for={data.value}
            >
              <IconExclamation
                className="t-text-zeta-600 t-ml-2"
                role="presentation"
              />
            </div>
            <ReactTooltip
              id={data.value}
              effect="solid"
              place="bottom"
              multiline
              className="t-bg-eta-100 typo-epsilon t-text-zeta-600"
            />
          </>
        )}
      </div>
    </components.Option>
  );
}
