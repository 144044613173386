import React, { useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { camelCase, find } from 'lodash';

import { RootState } from 'store/reducers';
import { getAssetTypesList } from 'store/actions/assetTypeActions';
import { useTranslation } from 'modules/common/helpers';

import { Row, Col } from 'modules/bootstrap/components';
import { ContextAwareToggle } from 'modules/common/components';
import { AssetCard } from 'modules/asset/components';

type ContactAccordionItemProps = {
  assets: AssetDTO[];
  itemTitle: string;
};

const ContactAccordionItem = ({
  itemTitle,
  assets,
}: ContactAccordionItemProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['plan']);

  const assetTypes: AssetTypesDTO = useSelector(
    (state: RootState) => state.assetTypes
  );

  useEffect(() => {
    dispatch(getAssetTypesList());
  }, []);

  return (
    <div className="Accordion-item" data-testid="ContactAccordionItem">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="Typography Typography--landingTitle">{t(itemTitle)}</h2>

        <div>
          <ContextAwareToggle eventKey={itemTitle} />
        </div>
      </div>
      <Accordion.Collapse className="u-pt-25" eventKey={itemTitle}>
        <Row>
          {assets.map((asset) => {
            const storeKey = camelCase(asset.category);
            const assetType: AssetTypeDTO | undefined = find(
              assetTypes[storeKey],
              (item) => item.id === asset.assetTypeId
            );

            if (!assetType) return null;
            return (
              <Col key={asset.id} xl="6" className="t-pb-5 t-cursor-pointer">
                <AssetCard
                  asset={asset}
                  assetCategory={asset.category}
                  hideDots
                  isNotEstatePlanPage
                />
              </Col>
            );
          })}
        </Row>
      </Accordion.Collapse>
    </div>
  );
};

export default ContactAccordionItem;
