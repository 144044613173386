import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import { useQuery, getFullName, capitalizeWords } from 'modules/common/helpers';

import { Row, Col } from 'modules/bootstrap/components';
import {
  ClickableLogo,
  SetPasswordForm,
  SetPasswordHeader,
} from 'modules/auth/components';

import { CLIENT_CUSTOMIZATIONS } from 'modules/common/constants';

import { ReactComponent as IconRectangleBg } from 'images/img_bg_rectangle_1.svg';
import { ReactComponent as IconRectangleSmallScreenBg } from 'images/img_bg_rectangle_2.svg';

interface ClientSetPasswordPageProps {
  token: string;
  client: string;
}

function ClientSetPasswordPage({ client, token }: ClientSetPasswordPageProps) {
  const name = useQuery().get('name');
  const surname = useQuery().get('surname');
  const fullName = capitalizeWords(getFullName({ name, surname }));

  const customization: ClientCustomization =
    CLIENT_CUSTOMIZATIONS[client || '']?.setPassword || {};

  return (
    <div>
      <IconRectangleBg
        className="t-fixed t-w-screen t-h-auto t-bottom-0 t-z-0 t-hidden lg:t-inline"
        style={{ color: customization?.rectangleBgColor || 'white' }}
      />
      <IconRectangleSmallScreenBg
        className="t-fixed t-w-screen t-h-auto t-bottom-0 t-z-0 md:t-hidden"
        style={{ color: customization?.rectangleBgColor || 'white' }}
      />
      <div className="t-fixed t-h-full t-w-1/2 t-hidden lg:t-inline">
        <div className="t-h-full t-w-full t-relative">
          <div className="t-max-w-sm t-w-10/12 t-px-6 t-absolute t-top-16 t-left-1/2 -t-translate-x-1/2 -t-translate-y-1/2">
            <ClickableLogo customization={customization} />
          </div>
          <div className="t-max-w-sm t-w-10/12 t-p-6 t-absolute t-top-1/2 t-left-1/2 -t-translate-x-1/2 -t-translate-y-1/2">
            <SetPasswordHeader
              customization={customization}
              client={client}
              fullName={fullName}
            />
          </div>
        </div>
      </div>
      <main className="t-bg-alpha-100 t-min-h-screen">
        <Row
          className="t-min-h-screen t-w-full t-m-0 xs:t-p-3 lg:t-p-0"
          style={{
            backgroundColor: customization?.mainBgColor || 'inherit',
          }}
        >
          <Col
            lg={{ span: 6, offset: 6 }}
            md={{ span: 8, offset: 2 }}
            className="t-p-0 t-z-50"
          >
            <div className="lg:t-hidden">
              <SetPasswordForm
                customization={customization}
                token={token}
                client={client}
                fullName={fullName}
              />
            </div>

            <div className="t-hidden lg:t-inline">
              <Scrollbars>
                <SetPasswordForm
                  customization={customization}
                  token={token}
                  client={client}
                  fullName={fullName}
                />
              </Scrollbars>
            </div>
          </Col>
        </Row>
      </main>
    </div>
  );
}

export default ClientSetPasswordPage;
