import React from 'react';

import { isJsonString, useTranslation } from 'modules/common/helpers';

import { FIELD_TYPE_KEYS as F } from 'modules/estatePlan/constants/fieldLibrary';

interface AssetViewModalMultipleInputsPros {
  stringifiedField?: string;
  label: string;
  type: string;
}

const AssetViewModalMultipleInputs = ({
  stringifiedField = '',
  label,
  type,
}: AssetViewModalMultipleInputsPros) => {
  const { t } = useTranslation(['common']);

  const inputValues = !stringifiedField
    ? []
    : isJsonString(stringifiedField)
    ? JSON.parse(stringifiedField)
    : [stringifiedField];

  if (!inputValues.length) return null;

  return inputValues.map((inputValue, idx) => (
    <div key={`${inputValue}_${idx}`} className="t-mb-3">
      <p className={`typo-zeta t-pb-2`}>{`${t(label)}${
        inputValues.length !== 1 ? `${idx + 1}` : ''
      }`}</p>
      <p className="typo-delta t-p-2 t-bg-beta-100 t-rounded-sm t-flex t-justify-between">
        {type === F.PHONE ? `+${inputValue}` : inputValue}
      </p>
    </div>
  ));
};

export default AssetViewModalMultipleInputs;
