import React from 'react';
import classnames from 'classnames';

import { useTranslation } from 'modules/common/helpers';

import { BADGE_STATUS } from 'modules/common/constants/generic';
import { RECO_TYPE } from 'modules/assistant/constants/questionnaires';

interface InfoBadgeProps {
  priority?: BADGE_STATUS | string | number;
  type?: string;
}

export default function InfoBadge({
  type = RECO_TYPE.ESTATE,
  priority,
}: InfoBadgeProps) {
  const { t } = useTranslation(['recommendations']);

  const badgeClass = classnames({
    't-border t-border-solid t-px-1.5 t-py-1 t-flex t-items-center t-rounded-xs lg:t-whitespace-nowrap':
      true,
    't-border-alpha-700':
      priority === BADGE_STATUS.HIGH && type === RECO_TYPE.CARE,
    't-border-delta-700':
      (priority === BADGE_STATUS.HIGH && type === RECO_TYPE.ESTATE) ||
      priority === BADGE_STATUS.NEEDED,
    't-border-alpha-200':
      priority === BADGE_STATUS.MEDIUM && type === RECO_TYPE.CARE,
    't-border-delta-300':
      priority === BADGE_STATUS.MEDIUM && type === RECO_TYPE.ESTATE,
    't-border-beta-400':
      priority === BADGE_STATUS.LOW ||
      priority === BADGE_STATUS.DISABLED ||
      priority === undefined,
  });

  const textClass = classnames({
    'typo-kappa': true,
    't-text-alpha-700':
      priority === BADGE_STATUS.HIGH && type === RECO_TYPE.CARE,
    't-text-delta-700':
      (priority === BADGE_STATUS.HIGH && type === RECO_TYPE.ESTATE) ||
      priority === BADGE_STATUS.NEEDED,
    't-text-alpha-600':
      priority === BADGE_STATUS.MEDIUM && type === RECO_TYPE.CARE,
    't-text-delta-500':
      priority === BADGE_STATUS.MEDIUM && type === RECO_TYPE.ESTATE,
    't-text-beta-400':
      priority === BADGE_STATUS.LOW ||
      priority === BADGE_STATUS.DISABLED ||
      priority === undefined,
  });

  let title;
  switch (priority) {
    case BADGE_STATUS.DISABLED:
      title = t('recommendations:available_soon');
      break;
    case BADGE_STATUS.NEEDED:
      title = t('status_needed');
      break;
    case BADGE_STATUS.HIGH:
      title = t('status_very_important');
      break;
    case BADGE_STATUS.MEDIUM:
      title = t('status_important');
      break;
    case BADGE_STATUS.LOW:
    default:
      title = t('status_sensible');
      break;
  }

  return (
    <span className={badgeClass}>
      <span className={textClass}>{title}</span>
    </span>
  );
}
