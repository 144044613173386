import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { FormControl } from 'modules/common/types';
import { Form } from 'modules/bootstrap/components';

import { dateHelper, useTranslation } from 'modules/common/helpers';
import { ReactComponent as IconInfo } from 'images/icon-info.svg';

interface BirthdayInputProps {
  control: FormControl;
  error: string;
  defaultValue?: string;
  isRequired?: boolean;
  tooltipText?: string;
  adultOnly?: boolean;
  label?: string;
  customName?: string;
}

export default function BirthdayInput({
  control,
  error,
  defaultValue,
  isRequired,
  tooltipText,
  adultOnly,
  label,
  customName,
}: BirthdayInputProps) {
  const { t } = useTranslation(['auth', 'common']);

  return (
    <div className="Form-group" data-testid="BirthdayInput">
      <Form.Label
        className={`Form-label ${error ? 'isErrored' : ''}`}
        htmlFor="birthday"
      >
        {label || t('auth:birthday_label')}
      </Form.Label>
      {tooltipText && (
        <IconInfo
          role="presentation"
          className="t-inline t-text-delta-700 t-ml-2"
          data-tip={tooltipText}
        />
      )}
      <Controller
        name={customName || 'birthday'}
        render={({ field }) => (
          <InputMask
            placeholder={t('common:date_placeholder')}
            mask="99.99.9999"
            className={`Field ${error ? 'isErrored' : ''}`}
            id="birthday"
            {...field}
          />
        )}
        control={control}
        defaultValue={defaultValue}
        rules={{
          validate: (value) => {
            if (!value && !isRequired) return true;

            if (!value)
              return `${t('auth:birthday_label')} ${t('common:required')}`;
            if (!dateHelper.isValidBirthdayDate(value))
              return `${t('auth:birthday_wrong')}`;
            if (dateHelper.checkIsNotTooEarly(value))
              return dateHelper.checkIsNotTooEarly(value);
            if (adultOnly && dateHelper.checkIsNotAdult(value))
              return dateHelper.checkIsNotAdult(value);

            return true;
          },
        }}
      />
      {error && (
        <div className="Form-alert">
          <span className="text-main-sm t-text-epsilon-600">{error}</span>
        </div>
      )}
    </div>
  );
}
