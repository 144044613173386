import React from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import isEmail from 'validator/lib/isEmail';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { createKeyHolder, editKeyHolder } from 'store/actions/keyHolderActions';
import { setModal } from 'store/actions/modalActions';
import {
  handleResponseError,
  optionsHelper,
  useTranslation,
} from 'modules/common/helpers';

import { Row, Col } from 'modules/bootstrap/components';
import {
  Button,
  PhoneInput,
  SelectController,
  TextInputRef,
  WarningTooltipRefresher,
} from 'modules/common/components';
import { ERROR_CODES, MODALS } from 'modules/common/constants';

import { ReactComponent as IconCross } from 'images/cross.svg';

interface SubmitFields extends KeyHolder {
  gender?: SelectOption;
}

interface KeyHolderFormProps {
  onSubmitSuccess: () => void;
  keyHolders: KeyHolder[];
  data?: KeyHolder;
  onToggleParent: (hide: boolean) => void;
  onClose?: () => void;
  submitDisabled?: boolean;
}

const KeyHolderForm = ({
  onSubmitSuccess,
  data,
  keyHolders,
  onToggleParent,
  onClose,
  submitDisabled,
}: KeyHolderFormProps) => {
  const { t } = useTranslation(['securityKey', 'common']);
  const {
    control,
    register,
    handleSubmit,
    setError,

    formState: { errors },
  } = useForm<SubmitFields>();
  const dispatch = useDispatch();

  const currentUser = useSelector((state: RootState) => state.user.current);
  const [isSaving, setIsSaving] = React.useState(false);

  const genderOptions = optionsHelper.getGenderOptions();
  const currentSelectedGenderOption =
    genderOptions.find((gender) => gender.value === data?.gender) ||
    genderOptions[0];

  const errEmail = () => {
    if (errors.email) {
      if (errors.email.type === 'required')
        return `${t('auth:email_required')}`;
      if (['yourselfEmail', 'alreadyAssigned'].includes(errors.email.type))
        return errors.email.message;
      return `${t('auth:email_not_valid')}`;
    }
    return null;
  };

  const isValid = (formData: KeyHolder) => {
    if (formData.email.trim().toLowerCase() === currentUser.email) {
      setError('email', {
        message: t('auth:email_cannot_assign_yourself'),
        type: 'yourselfEmail',
      });
      return false;
    }
    const emailExisted = (keyHolders || []).find(
      (holder) => holder.email === formData.email
    );

    if (!!emailExisted && !data?.email) {
      setError('email', {
        message: t('auth:email_already_assigned'),
        type: 'alreadyAssigned',
      });
      return false;
    }
    return true;
  };

  const onKeyHolderSubmit = async (formData: SubmitFields) => {
    if (!isValid(formData)) return;

    const keyHolder: KeyHolder = {
      ...(data?.email ? data : {}),
      ...formData,
      gender: (formData?.gender?.value as string) || '',
    };

    setIsSaving(true);

    let response;
    if (data?.email) {
      response = await dispatch(
        editKeyHolder(keyHolder, { returnError: true })
      );
    } else {
      response = await dispatch(
        createKeyHolder(keyHolder, {
          returnError: true,
        })
      );
    }

    const responseError = handleResponseError(response);
    if (responseError === ERROR_CODES.NOT_ALLOWED) {
      onToggleParent(true);
      dispatch(
        setModal({
          name: MODALS.PASSPHRASE,
          props: {
            successAction: handlePassPhraseSuccess,
            cancelAction: handlePassPhraseCancel,
          },
        })
      );
    } else if (!responseError) {
      onSubmitSuccess();
    }
    setIsSaving(false);
  };

  const handlePassPhraseSuccess = () => {
    onToggleParent(false);

    handleSubmit(onKeyHolderSubmit)();
  };

  const handlePassPhraseCancel = () => {
    onToggleParent(false);
  };

  return (
    <div className="card t-relative t-p-6 t-pt-8 t-mt-5 t-mb-8">
      {!!keyHolders.length && (
        <button className="t-absolute t-top-3 t-right-5" onClick={onClose}>
          <IconCross className="t-h-3 t-w-3 t-text-beta-400" />
        </button>
      )}

      <form onSubmit={handleSubmit(onKeyHolderSubmit)}>
        <fieldset disabled={isSaving}>
          <Row>
            <Col xs={6} xl={3}>
              <div className="Form-group">
                <label className="Form-label" htmlFor="gender">
                  {t('auth:title')}
                </label>

                <SelectController
                  id="gender"
                  name="gender"
                  options={genderOptions}
                  control={control}
                  defaultValue={currentSelectedGenderOption}
                />
              </div>
            </Col>
            <Col xl={4}>
              <TextInputRef
                {...register('name', { required: true })}
                label={t('auth:name_f')}
                type="text"
                autoComplete={false}
                defaultValue={data?.name || ''}
                error={
                  errors.name && `${t('auth:name_f')} ${t('common: required')}`
                }
              />
            </Col>

            <Col xl={5}>
              <TextInputRef
                {...register('surname', { required: true })}
                label={t('auth:name_l')}
                type="text"
                autoComplete={false}
                defaultValue={data?.surname || ''}
                error={
                  errors.surname &&
                  `${t('auth:name_l')} ${t('common: required')}`
                }
              />
            </Col>
          </Row>

          <Row className={`t-pb-2.5 ${data?.email ? 't-hidden' : ''}`}>
            <Col>
              <TextInputRef
                {...register('email', {
                  required: true,
                  validate: (value) => {
                    if (!value) return true;
                    return isEmail(value.trim());
                  },
                })}
                label={t('securityKey:key_holder_email_label')}
                type="email"
                error={errEmail()}
                defaultValue={data?.email || ''}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <PhoneInput
                name="phone"
                control={control}
                error={
                  errors.phone
                    ? `${t('auth:phone_label')} ${t('common:invalid')}`
                    : ''
                }
                defaultValue={data?.phone || ''}
                label={`${t('auth:phone_label')} (${t('common:optional')})`}
              />
            </Col>
          </Row>
        </fieldset>

        <div className="t-flex t-justify-end t-mt-4">
          <WarningTooltipRefresher id="add-key-holder">
            <div
              data-tip={t('common:feature_use_subscription_expired_tooltip')}
              data-tip-disable={!submitDisabled}
              data-for="add-key-holder"
            >
              <Button
                type="submit"
                disabled={!isEmpty(errors) || submitDisabled}
                loading={isSaving}
              >
                {data?.email
                  ? t('securityKey:edit_key_holder')
                  : t('securityKey:save_key_holder')}
              </Button>
            </div>
          </WarningTooltipRefresher>
        </div>
      </form>
    </div>
  );
};

export default KeyHolderForm;
