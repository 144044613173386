import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import { enGB, de } from 'date-fns/locale';

import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

import { LOCAL_STORAGE_VARS, LANGUAGES } from 'modules/common/constants/enums';
import { ReactComponent as IconTrash } from 'images/trash-hollow.svg';
import 'react-datepicker/dist/react-datepicker.css';

export interface DatepickerProps {
  onChange: (val: Date) => void;
  onSubmit: () => void;
  onDelete?: () => void;
  onClose?: () => void;
  date: Date | string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  showTrash?: boolean;
  customInfoTransKey?: string;
  hideInfo?: boolean;
}

export default function Datepicker({
  onChange,
  date,
  onSubmit,
  onDelete,
  minDate,
  maxDate,
  onClose,
  disabled,
  showTrash,
  customInfoTransKey,
  hideInfo,
}: DatepickerProps) {
  const { t } = useTranslation(['common']);

  const userLang = localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE);
  registerLocale('enGB', enGB);
  registerLocale('de', de);
  const userRegisterLocale = userLang === LANGUAGES.DE ? 'de' : 'enGB';
  const lang = userLang === LANGUAGES.DE ? de : enGB;
  const desiredFormat = userLang === LANGUAGES.DE ? 'do MMM.' : 'MMM. do';
  const localDate = format(
    date
      ? new Date(typeof date === 'string' ? date?.replace(/-/g, '/') : date)
      : new Date(),
    desiredFormat,
    {
      locale: lang,
    }
  );

  return (
    <DatePicker
      selected={
        new Date(typeof date === 'string' ? date?.replace(/-/g, '/') : date) ||
        new Date()
      }
      onChange={onChange}
      shouldCloseOnSelect={false}
      minDate={minDate}
      maxDate={maxDate}
      onClickOutside={onClose}
      inline
      locale={userRegisterLocale}
    >
      <div className="t-w-full t-flex t-justify-center">
        <div
          className="t-bg-beta-200 t-inline-block t-w-5/6"
          style={{ height: '1px' }}
        />
      </div>

      <div className="t-flex t-mb-4">
        <div className="t-mt-3 t-mx-5 t-w-full t-flex">
          {onDelete && !disabled && showTrash && (
            <button
              onClick={onDelete}
              className="t-inline-block t-align-middle t-text-beta-700"
            >
              <IconTrash />
            </button>
          )}
          <Button
            className={`t-w-full t-inline-block ${disabled ? 't-mb-3' : ''} ${
              onDelete && !disabled && showTrash ? 't-ml-4' : ''
            }`}
            disabled={disabled}
            onClick={onSubmit}
          >
            {t('common:save')}
          </Button>
        </div>
      </div>

      {!disabled && !hideInfo && (
        <p className="t-mx-5 t-mb-4 t-float-left t-text-beta-400">
          {t(customInfoTransKey || 'common:reminder_info_text', {
            date: localDate,
          })}
        </p>
      )}
    </DatePicker>
  );
}
