import config from 'modules/common/helpers/configHelper';
import httpHelper from 'modules/common/helpers/httpHelper';
import utilHelper from 'modules/common/helpers/caseConvertHelper';

const BASE_URL = config.beUrl;

function getAssetTemplatesByCategory(category: string, assetType?: string) {
  return httpHelper.get(`${BASE_URL}/api/default-asset-type-structures`, {
    category: utilHelper.toLowerStartCase(category),
    ...(assetType && { assetType }),
  });
}

export default {
  getAssetTemplatesByCategory,
};
