import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { find } from 'lodash';

import { RootState } from 'store/reducers';
import { dateHelper, config, useTranslation } from 'modules/common/helpers';

import { ContactCard } from 'modules/myContacts/components';
import {
  EndScreenAnswerElement,
  HeirContactEnd,
  TextAreaEnd,
  PartnerProfileEnd,
  HeirDistributionEnd,
  SubstituteHeirEnd,
  BequestEnd,
  QuestionnaireFormEnd,
  AddInformationEnd,
} from 'modules/assistant/components/Answers/EndScreen';

import {
  QUESTIONNAIRE_KEYS,
  STEP_COMPONENT_TYPE,
} from 'modules/assistant/constants/questionnaires';

import { ReactComponent as IconEdit } from 'images/icon-edit.svg';

export interface EndScreenChoiceProps {
  choiceType: STEP_COMPONENT_TYPE;
  title: string;
  answers: AnswerElement[];
  handleClick: () => void;
  questionnaire: QUESTIONNAIRE_KEYS;
  stepKey: string;
}

const EndScreenChoice = ({
  choiceType,
  title,
  answers,
  handleClick,
  questionnaire,
  stepKey,
}: EndScreenChoiceProps) => {
  const { t } = useTranslation(['common']);

  const contactsData: ContactDTO[] =
    useSelector((state: RootState) => state.contacts.list) || [];

  const heirs: ContactDTO[] =
    useSelector((state: RootState) => state.heirs.list) || [];

  const renderAnswer = (): React.ReactNode => {
    switch (choiceType) {
      case STEP_COMPONENT_TYPE.SINGLE:
      case STEP_COMPONENT_TYPE.MULTIPLE:
      case STEP_COMPONENT_TYPE.SINGLEANDMULTIPLE:
        return (
          <div className="d-flex flex-wrap">
            {answers.map((answer) => (
              <EndScreenAnswerElement
                isSelected={!!answer.isSelected}
                key={answer.text}
                isNoHover
              >
                {answer.text}
              </EndScreenAnswerElement>
            ))}
          </div>
        );

      case STEP_COMPONENT_TYPE.FREE_TEXT:
        return <TextAreaEnd rows={3} readOnly defaultValue={answers[0].text} />;

      case STEP_COMPONENT_TYPE.EXPIRATION_DATE:
        return (
          <TextAreaEnd
            rows={1}
            readOnly
            defaultValue={dateHelper.convertDateFormat(
              answers[0].text,
              config.format.uiDate
            )}
            width="106px"
          />
        );

      case STEP_COMPONENT_TYPE.CONTACT: {
        const contactData =
          find(contactsData, (item) => item.id === answers[0].text) ||
          ({} as ContactDTO);
        return (
          <ContactCard
            contactData={contactData}
            isDropdownMenuHidden
            className="card t-py-3 t-px-0 xl:t-py-6 xl:t-px-7 xl:t-max-w-sm"
          />
        );
      }

      case STEP_COMPONENT_TYPE.MULTI_CONTACTS: {
        const defaultSelectedContacts = answers.map(
          (answer: AnswerElement | string) =>
            contactsData.find((contact) => contact.id === answer) ||
            ({} as ContactDTO)
        );

        return (
          <div>
            {defaultSelectedContacts.map((selectedContact) => (
              <React.Fragment key={selectedContact.id}>
                <ContactCard
                  contactData={selectedContact}
                  className="card t-py-3 t-px-0 xl:t-py-6 xl:t-px-7 xl:t-max-w-sm"
                  isDropdownMenuHidden
                />
              </React.Fragment>
            ))}
          </div>
        );
      }
      case STEP_COMPONENT_TYPE.HEIR_CONTACTS:
        return <HeirContactEnd heirs={heirs} />;
      case STEP_COMPONENT_TYPE.PARTNER_PROFILE:
        return <PartnerProfileEnd partner={answers as Record<string, any>} />;
      case STEP_COMPONENT_TYPE.INHERITANCE_DISTRIBUTION:
        return <HeirDistributionEnd distributionHeirs={answers} />;
      case STEP_COMPONENT_TYPE.SUBSTITUTE_HEIR:
        return (
          <SubstituteHeirEnd
            substituteHeirs={answers as Record<string, any>[]}
          />
        );
      case STEP_COMPONENT_TYPE.BEQUEST:
        return (
          <BequestEnd
            bequests={answers as Record<string, any> as BequestDTO[]}
          />
        );
      case STEP_COMPONENT_TYPE.FORM:
        return (
          <QuestionnaireFormEnd
            answerData={answers as Record<string, any>}
            questionnaire={questionnaire}
            stepKey={stepKey}
          />
        );
      case STEP_COMPONENT_TYPE.ADD_INFORMATION:
        return (
          <AddInformationEnd
            answerData={answers}
            questionnaire={questionnaire}
            stepKey={stepKey}
          />
        );
      case STEP_COMPONENT_TYPE.BANK_POA_CONTACT:
        return (
          <ContactCard
            contactData={answers as any as ContactDTO}
            isDropdownMenuHidden
            className="card t-py-3 t-px-0 xl:t-py-6 xl:t-px-7 xl:t-max-w-sm"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="t-mb-8">
      <div className="t-flex t-justify-between t-mb-2">
        <p className="t-mr-2 t-text-beta-500">{title}</p>
        <button
          className="xs:t-hidden xl:t-flex t-align-baseline t-cursor-pointer"
          type="button"
          onClick={handleClick}
        >
          <div className="t-absolute">
            <IconEdit
              className="t-text-delta-500"
              style={{ minWidth: '15px', maxWidth: '15px' }}
            />
          </div>
          <StyledLink>{t('common:edit_label')}</StyledLink>
        </button>
      </div>

      <div className="xs:t-flex xs:t-justify-between xl:t-block">
        {renderAnswer()}
        <button
          className="xl:t-hidden t-ml-2 t-cursor-pointer t-bg-delta-200 t-p-2 t-shadow t-rounded t-self-start t-mt-2"
          type="button"
          onClick={handleClick}
        >
          <IconEdit
            className="t-text-delta-500"
            style={{ minWidth: '15px', maxWidth: '15px' }}
          />
        </button>
      </div>
    </div>
  );
};

export default EndScreenChoice;

const StyledLink = styled.span`
  color: var(--color-delta-500);
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  letter-spacing: 0.1em;
  margin-left: 22px;
  text-transform: capitalize;
  white-space: nowrap;
`;
