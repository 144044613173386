import dataService from 'modules/common/services/dataService';
import { LOAD_CONTACT_OF_LIST } from 'store/types/contactOfActionTypes';
import helper from 'store/actions/actionHelper';

export const getContactOfListSuccess = (contactOf: ContactDTO[]) =>
  helper.getAction(LOAD_CONTACT_OF_LIST, { contactOf });

export const getContactOfList = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const contactOf = await dataService.getContactOfList();

    dispatch(getContactOfListSuccess(contactOf));
  }, actionOptions);
};

export const getContactOf = (
  code: number,
  contactOfId: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await dataService.getContactOf(code, contactOfId);
    return response;
  }, actionOptions);
};
