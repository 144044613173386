import React, { useState } from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Modal } from 'modules/common/components';
import {
  InputLibraryCard,
  ProviderInputModal,
} from 'modules/estatePlan/components';

import { FIELD_LIBRARY, FIELD_TYPE_KEYS } from 'modules/estatePlan/constants';

interface InputLibraryModalProps {
  onClose: () => void;
  addField: (fieldData: AssetFieldDTO) => void;
  assetCategory: string;
  fields: AssetFieldDTO[];
}

export default function InputLibraryModal({
  onClose,
  addField,
  assetCategory,
  fields,
}: InputLibraryModalProps) {
  const { t } = useTranslation(['common', 'plan', 'fieldLibrary']);

  const [showProviderModal, setShowProviderModal] = useState(false);
  const [fieldToAdd, setFieldToAdd] = useState({} as AssetFieldDTO);

  const onAddField = (field: AssetFieldDTO) => {
    if (field.type === FIELD_TYPE_KEYS.PROVIDER) {
      setShowProviderModal(true);
      setFieldToAdd(field);
    } else {
      addField(field);
    }
  };

  const handleProviderFormSuccess = ({ providerType }) => {
    addField({ ...fieldToAdd, providerType });
    setFieldToAdd({} as AssetFieldDTO);
  };

  return (
    <Modal
      show
      onHide={onClose}
      className="large"
      innerCardClassName="t-bg-beta-50"
    >
      <h1 className="t-text-xl t-font-secondary t-mb-6 t-font-semibold">
        {t('plan:input_lib_modal_title')}
      </h1>
      <div className="t-grid md:t-grid-cols-2 lg:t-grid-cols-3 xl:t-grid-cols-4 t-grid-cols-1 t-gap-6">
        {FIELD_LIBRARY[assetCategory].map((field: AssetFieldDTO) => {
          const fieldAlreadyExists = !!fields.find(
            (f) => f.type === field.type
          );
          return (
            <InputLibraryCard
              key={field.type}
              isDisabled={fieldAlreadyExists}
              field={field}
              addField={onAddField}
            />
          );
        })}
      </div>
      <div className="t-flex t-justify-end">
        <button
          type="button"
          onClick={onClose}
          className="t-text-sm t-rounded t-h-12 t-mt-10 t-px-4 t-py-2 t-tracking-widest t-bg-alpha-200 t-text-alpha-600"
        >
          {t('common:cancel')}
        </button>
      </div>

      <ProviderInputModal
        isVisible={showProviderModal}
        onCancel={() => setShowProviderModal(false)}
        onSuccess={handleProviderFormSuccess}
      />
    </Modal>
  );
}
