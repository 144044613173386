import { isEmpty } from 'lodash';
import { getNumberWithDots } from 'modules/common/helpers';
import {
  INHERITANCE_RELATIONSHIP,
  INHERITANCE_TYPE,
} from 'modules/common/constants/enums';

// The below functions are used to calculate the inheritance tax 2021

const calculateChildrenPensionAllowance = (childrenAge) => {
  if (childrenAge <= 5) return 52000;
  if (childrenAge <= 10) return 41000;
  if (childrenAge <= 15) return 30700;
  if (childrenAge <= 20) return 20500;
  if (childrenAge <= 27) return 10300;
  return 0;
};

const getTaxClass1Percentage = (taxableAmount) => {
  if (taxableAmount <= 75000) return 7;
  if (taxableAmount <= 300000) return 11;
  if (taxableAmount <= 600000) return 15;
  if (taxableAmount <= 6000000) return 19;
  if (taxableAmount <= 13000000) return 23;
  if (taxableAmount <= 26000000) return 23;
  return 30;
};

const getTaxClass2Percentage = (taxableAmount) => {
  if (taxableAmount <= 75000) return 15;
  if (taxableAmount <= 300000) return 20;
  if (taxableAmount <= 600000) return 25;
  if (taxableAmount <= 6000000) return 30;
  if (taxableAmount <= 13000000) return 35;
  if (taxableAmount <= 26000000) return 40;
  return 43;
};

const getTaxClass3Percentage = (taxableAmount) => {
  if (taxableAmount <= 6000000) return 30;
  return 50;
};

const calculateFreeAllowance = ({ relationship }) => {
  switch (relationship) {
    case INHERITANCE_RELATIONSHIP.SPOUSE:
      return 500000;

    case INHERITANCE_RELATIONSHIP.CHILD:
    case INHERITANCE_RELATIONSHIP.STEP_CHILD:
    case INHERITANCE_RELATIONSHIP.GRANDCHILD_DISEASED_CHILD:
      return 400000;

    case INHERITANCE_RELATIONSHIP.GRANDCHILD:
      return 200000;

    case INHERITANCE_RELATIONSHIP.PARENT:
    case INHERITANCE_RELATIONSHIP.GRANDPARENT:
    case INHERITANCE_RELATIONSHIP.GREAT_GRANDCHILD:
      return 100000;

    case INHERITANCE_RELATIONSHIP.OTHER:
      return 20000;

    default:
      return 100000;
  }
};

const calculatePensionAllowance = ({
  relationship,
  inheritanceType,
  childrenAge,
}) => {
  if (inheritanceType === INHERITANCE_TYPE.GIFT) return 0;

  if (relationship === INHERITANCE_RELATIONSHIP.SPOUSE) return 256000;

  // is relationship children / step children / grand children
  const { CHILD, STEP_CHILD, GRANDCHILD } = INHERITANCE_RELATIONSHIP;
  if ([CHILD, STEP_CHILD, GRANDCHILD].includes(relationship)) {
    return calculateChildrenPensionAllowance(childrenAge);
  }

  return 0;
};

const calculateInheritanceTaxRate = (taxableAmount, relationship) => {
  switch (relationship) {
    case INHERITANCE_RELATIONSHIP.SPOUSE:
    case INHERITANCE_RELATIONSHIP.CHILD:
    case INHERITANCE_RELATIONSHIP.STEP_CHILD:
    case INHERITANCE_RELATIONSHIP.GRANDCHILD_DISEASED_CHILD:
    case INHERITANCE_RELATIONSHIP.GRANDCHILD:
    case INHERITANCE_RELATIONSHIP.PARENT:
    case INHERITANCE_RELATIONSHIP.GRANDPARENT:
    case INHERITANCE_RELATIONSHIP.GREAT_GRANDCHILD:
      return { taxClass: 'Ⅰ', taxRate: getTaxClass1Percentage(taxableAmount) };

    case INHERITANCE_RELATIONSHIP.OTHER:
      return { taxClass: 'Ⅲ', taxRate: getTaxClass3Percentage(taxableAmount) };

    default:
      return { taxClass: 'Ⅱ', taxRate: getTaxClass2Percentage(taxableAmount) };
  }
};

export const inheritanceCalculator = (
  inputData,
  pensionAllowanceExcluded?: boolean
) => {
  if (isEmpty(inputData)) return {};
  const initialInheritedAmount = inputData.worth;
  const personalFreeAllowance = calculateFreeAllowance(inputData);
  const nonTaxableAllowance = pensionAllowanceExcluded
    ? 0
    : calculatePensionAllowance(inputData);
  const inheritanceAllowance = personalFreeAllowance + nonTaxableAllowance;
  const taxableAmount = initialInheritedAmount - inheritanceAllowance;
  const { taxClass, taxRate } = calculateInheritanceTaxRate(
    initialInheritedAmount,
    inputData.relationship
  );
  const inheritanceTax =
    taxableAmount <= 0
      ? '0,00'
      : getNumberWithDots(((taxableAmount * taxRate) / 100).toFixed(2));
  return {
    personalFreeAllowance: getNumberWithDots(personalFreeAllowance.toFixed(2)),
    nonTaxableAllowance: getNumberWithDots(nonTaxableAllowance.toFixed(2)),
    taxableAmount:
      taxableAmount <= 0 ? '0,00' : getNumberWithDots(taxableAmount.toFixed(2)),
    taxRate,
    inheritanceTax,
    taxClass,
  };
};
