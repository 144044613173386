import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { camelCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'store/reducers';
import { getCardLink } from 'modules/assistant/helpers/questionnaireHelper';

import { InfoBadge, EscalatedButton, Button } from 'modules/common/components';
import {
  RecoReminder,
  RecoStartModal,
} from 'modules/assistant/components/Assistant';

import {
  BADGE_STATUS,
  ESCALATED_BUTTON_STATUS,
} from 'modules/common/constants/generic';
import {
  QUESTIONNAIRE_KEYS_WITH_CONTENT,
  RECO_TYPE,
} from 'modules/assistant/constants/questionnaires';
import { RECOMMENDATION_INFO } from 'modules/assistant/constants';
import { QUESTIONNAIRES_CTAS } from 'modules/assistant/constants/questionnaires/questionnairesCTAs';

import { ReactComponent as IconDocs } from 'images/docs.svg';
import { setModal } from 'store/actions/modalActions';
import { MODALS } from 'modules/common/constants';
import { useTranslation } from 'modules/common/helpers';

export interface RecoCardProps {
  card: RecommendationDTO;
  type: RECO_TYPE;
}

const RecoCard = ({ card, type }: RecoCardProps) => {
  const { name = '', status, priority, isAvailable, reminder } = card;
  const { t } = useTranslation(['recommendations']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recommendation = camelCase(name);

  const isVerifiedUser = useSelector(
    (state: RootState) => state.user.current?.verified
  );

  const [isHovered, setIsHovered] = useState(false);
  const [starting, setStarting] = useState(false);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const { upload, create, viewDetail } = QUESTIONNAIRES_CTAS[recommendation];

  const onFocused = () => setIsHovered(true);
  const onUnFocused = () => setIsHovered(false);

  let btnType =
    type === RECO_TYPE.CARE
      ? ESCALATED_BUTTON_STATUS.REGULAR
      : ESCALATED_BUTTON_STATUS.GOOFY;
  btnType = isAvailable ? btnType : ESCALATED_BUTTON_STATUS.DISABLED;

  let btnText = '';
  switch (status) {
    case 0:
      btnText = isAvailable ? t('start') : t('available_soon');
      break;
    case 0.5:
      btnText = t('continue');
      break;
    case 1:
      btnText = t('look_at');
      break;
    default:
      btnText = t('available_soon');
      break;
  }

  const startBtnClassName =
    type === RECO_TYPE.ESTATE
      ? 't-bg-delta-900 t-text-gamma-400 t-shadow-delta-900'
      : '';
  const viewDetailBtnClassName =
    type === RECO_TYPE.ESTATE ? 't-text-delta-900' : 't-text-alpha-600';

  const isDisabled = !isAvailable && status === 0;
  const { preparationHint } = RECOMMENDATION_INFO[recommendation] || {};

  const handleViewDetail = () => {
    const exceptionKeys: string[] = Object.values(
      QUESTIONNAIRE_KEYS_WITH_CONTENT
    );
    if (exceptionKeys.includes(recommendation)) {
      navigate(getCardLink(`${recommendation}Content`));
    } else {
      navigate(getCardLink(name));
    }
  };

  const onViewDetail = () => {
    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: t(`${name}`),
          description: t(`recommendations:${name}_starting_modal_description`),
          primaryButtonText: t('common:read_more'),
          successAction: handleViewDetail,
        },
      })
    );
  };

  const onStarting = () => {
    setStarting(true);
  };

  const closeCTAProcess = () => {
    setStarting(false);
  };

  return (
    <>
      {starting && (
        <RecoStartModal
          recoName={name}
          onClose={closeCTAProcess}
          create={create}
          upload={upload}
        />
      )}

      <div
        className="card t-flex t-mt-5 t-min-h-56 t-shadow-lg"
        onMouseOver={onFocused}
        onFocus={onFocused}
        onMouseOut={onUnFocused}
        onBlur={onUnFocused}
      >
        <div className="t-w-full t-flex t-flex-col t-justify-between t-mx-4 t-p-4">
          <div className="t-flex t-justify-between t-items-center t-flex-wrap">
            <InfoBadge
              type={type}
              priority={isAvailable ? priority : BADGE_STATUS.DISABLED}
            />
            {isAvailable && isVerifiedUser && (
              <div className="t-relative">
                <RecoReminder recommendation={name} reminder={reminder} />
              </div>
            )}
          </div>

          <h2 className="t-font-secondary t-text-lg t-font-semibold xs:t-w-full sm:t-w-auto xs:t-mt-2 sm:t-mt-0">
            {t(`${name}`)}
          </h2>
          <p className="t-mt-2">{t(`${name}_descr`)}</p>

          <div className="t-flex">
            {preparationHint && (
              <p
                className="t-text-beta-400 t-my-2.5 t-mr-3 t-tracking-widest t-text-xxs"
                data-tip={t(preparationHint)}
              >
                <IconDocs className="t-inline t-mr-2" />
                {t('recommendations:preparation_hint')}
              </p>
            )}
          </div>
          <div className="t-mt-7 xs:t-mb-2">
            {isAvailable ? (
              <div
                className={`t-flex ${
                  viewDetail ? 't-justify-between' : 't-justify-end'
                } `}
              >
                {viewDetail && (
                  <Button
                    category="tertiary"
                    className={viewDetailBtnClassName}
                    onClick={onViewDetail}
                  >
                    {t('assistant:detail')}
                  </Button>
                )}

                {(create || upload) && (
                  <Button
                    className={`t-mr-2 ${startBtnClassName}`}
                    onClick={onStarting}
                  >
                    {t('common:start')}
                  </Button>
                )}
              </div>
            ) : (
              // This will be remove when we have the new design for the unavailable recos
              <div className="t-flex t-justify-end">
                <EscalatedButton
                  status={btnType}
                  text={btnText}
                  isEscalated={isHovered}
                  isDisabled={isDisabled}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoCard;
