import initialState from 'store/reducers/initialState';
import produce from 'immer';
import { GET_ASSET_TEMPLATES_BY_CATEGORY } from 'store/types/assetTemplateTypes';

const assetTemplateReducer = (state = initialState.assetTemplates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_ASSET_TEMPLATES_BY_CATEGORY: {
        const {
          payload: { assetTemplates, assetCategory },
        } = action;

        const newAssetTemplates = assetTemplates
          .map((assetTemplate) => {
            assetTemplate.structure.sort((a, b) => a.order - b.order); // sort structure inside asset templates by order
            return assetTemplate;
          })
          .sort((a, b) => a.order - b.order); // sort assetTemplates by order

        draft[assetCategory] = newAssetTemplates;
        return draft;
      }
      default:
        return draft;
    }
  });

export default assetTemplateReducer;
