import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { AddContactsProcess } from 'modules/dashboard/components';
import { Button } from 'modules/common/components';

import { ReactComponent as IconPlus } from 'images/icon-plus.svg';

export interface BackupContactEmptySectionProps {
  contactsData: ContactDTO[];
}

const BackupContactEmptySection = () => {
  const { t } = useTranslation(['profile', 'common']);

  const [addContactProcessOpen, setAddContactProcessOpen] =
    React.useState(false);

  const handleOpenAddContactProcess = () => {
    setAddContactProcessOpen(true);
  };

  const handleCloseAddContactProcess = () => {
    setAddContactProcessOpen(false);
  };

  return (
    <>
      {addContactProcessOpen && (
        <AddContactsProcess
          onCancel={handleCloseAddContactProcess}
          data-testid="AddContactsProcess"
          limited
        />
      )}

      <div className="t-mt-10" data-testid="BackupContactEmptySection">
        <h2 className="typo-beta t-mb-3">
          {t('profile:backup_contact_section_title')}
        </h2>

        <div className="card t-bg-eta-100 t-px-5 t-py-4">
          <p className="typo-epsilon t-text-zeta-600">
            {t('profile:backup_contact_empty_card_desc')}
          </p>

          <div className="t-mt-4 t-flex t-justify-end">
            <Button
              onClick={handleOpenAddContactProcess}
              testId="add-contact-btn"
            >
              <div>
                <IconPlus
                  role="presentation"
                  className="t-inline t-text-gamma-400 t-mr-2"
                />
              </div>
              <span className="t-whitespace-nowrap t-uppercase">
                {t('profile:add_contact_button')}
              </span>
            </Button>
          </div>
        </div>

        <div className="card t-px-5 t-py-6 t-mt-3">
          <div className="t-flex">
            <p className="typo-kappa t-text-beta-700 t-py-1.5 t-px-2.5 t-bg-beta-100 t-rounded-sm">
              {t('profile:backup_contact_feature_unavailable')}
            </p>
          </div>

          <p className="typo-delta t-mt-4">
            {t('profile:backup_contact_feature_info_card_desc')}
          </p>
        </div>
      </div>
    </>
  );
};

export default BackupContactEmptySection;
