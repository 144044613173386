import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { camelCase, isEmpty } from 'lodash';

import { RootState } from 'store/reducers';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { getPotentialContactDeletionStep } from 'modules/assistant/helpers/questionnaireHelper';
import { useTranslation } from 'modules/common/helpers';

import { Button, Modal, Spinner } from 'modules/common/components';
import { HowDoesItWorkModal } from 'modules/zvr/components';

import { ZVR_REGISTRATION_STEPS } from 'modules/zvr/constants';
import {
  ASSET_TYPE_KEYS,
  ASSET_CATEGORIES,
} from 'modules/estatePlan/constants';
import {
  QUESTIONNAIRES_TO_ASSET_TYPES,
  REDIRECT_ACTIONS,
} from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';
import bundesNotarKammerLogo from 'images/logo_bundesnotarkammer.svg';
import { ReactComponent as IconDownload } from 'images/icon-download.svg';
import { ReactComponent as IconCheck } from 'images/icon-check-unfilled-circle.svg';
import { ReactComponent as IconQuestionCircle } from 'images/icon-question-with-circle.svg';

interface RegistrationZVRStartModalProps {
  onCancelProcess: () => void;
  handleNextStep: (step: number) => void;
  downloading?: boolean;
  handleDocumentDownload?: () => void;
  questionnaire: QUESTIONNAIRE_KEYS;
  isFromLegacyPlan: boolean;
}

export default function RegistrationZVRStartModal({
  onCancelProcess,
  handleNextStep,
  downloading = false,
  handleDocumentDownload,
  questionnaire,
  isFromLegacyPlan,
}: RegistrationZVRStartModalProps) {
  const { t } = useTranslation(['zvrRegistration']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);

  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const assistantProgress = useSelector(
    (state: RootState) => state.assistant.progress
  );
  const assets: AssetsDTO = useSelector((state: RootState) => state.assets);

  const categoryAssets = useSelector(
    (state: RootState) => state.assets[ASSET_CATEGORIES.HEALTH]
  );

  const isHCP = questionnaire === QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY;
  const hcpQuestionnaireData = useSelector(
    (state: RootState) =>
      state.questionnaires[QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY]
  );
  const contacts: ContactDTO[] =
    useSelector((state: RootState) => state.contacts.list) || [];

  const missingContactStep = getPotentialContactDeletionStep(
    QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY,
    hcpQuestionnaireData,
    contacts
  );

  const isHCPQuestionnaireComplete =
    assistantProgress[QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY]?.status === 1;
  const latestHCPGeneratedAsset = categoryAssets?.find(
    (asset) =>
      asset.assetTypeName === ASSET_TYPE_KEYS.HEALTH_CARE_PROXY &&
      !asset.archived &&
      asset.generated
  );

  const isPDQuestionnaireComplete =
    assistantProgress[QUESTIONNAIRE_KEYS.PATIENT_DECREE]?.status === 1;
  const latestPDGeneratedAsset = categoryAssets?.find(
    (asset) =>
      asset.assetTypeName === ASSET_TYPE_KEYS.PATIENT_DECREE &&
      !asset.archived &&
      asset.generated
  );

  const isHCPComplete = isHCPQuestionnaireComplete && !!latestHCPGeneratedAsset;
  const isPDComplete = isPDQuestionnaireComplete && !!latestPDGeneratedAsset;

  const isHCPorPDComplete = isHCP ? isPDComplete : isHCPComplete;

  const nextStep = () => {
    if (isHCPorPDComplete) {
      if (user.profile === 1) {
        if (isHCP) {
          handleNextStep(ZVR_REGISTRATION_STEPS.STEP_TRUSTED_PERSON);
        } else {
          handleNextStep(
            isHCPComplete
              ? ZVR_REGISTRATION_STEPS.STEP_TRUSTED_PERSON
              : ZVR_REGISTRATION_STEPS.STEP_PAYMENT_CHECKOUT
          );
        }
      } else {
        handleNextStep(ZVR_REGISTRATION_STEPS.STEP_PROFILE_NOT_COMPLETE);
      }
    } else {
      handleNextStep(
        !!missingContactStep
          ? ZVR_REGISTRATION_STEPS.STEP_NO_HCP
          : !!isHCP && (!isPDQuestionnaireComplete || !latestPDGeneratedAsset)
          ? ZVR_REGISTRATION_STEPS.STEP_NO_PATIENT_DECREE
          : !isHCPQuestionnaireComplete || !latestHCPGeneratedAsset
          ? ZVR_REGISTRATION_STEPS.STEP_NO_HCP
          : user.profile === 1
          ? ZVR_REGISTRATION_STEPS.STEP_TRUSTED_PERSON
          : ZVR_REGISTRATION_STEPS.STEP_PROFILE_NOT_COMPLETE
      );
    }
  };

  const { path, category, assetType } =
    QUESTIONNAIRES_TO_ASSET_TYPES[camelCase(questionnaire)];

  const getLatestGeneratedAsset = (type: string) => {
    const assetCategories = Object.keys(assets);
    const flattedAssets = assetCategories.reduce(
      (acc: AssetDTO[], key: string) => [...acc, ...assets[key]],
      []
    );

    return flattedAssets.find(
      (asset) => asset.assetTypeName === type && !asset.archived
    );
  };

  const latestGeneratedAsset = !isFromLegacyPlan
    ? getLatestGeneratedAsset(assetType)
    : ({} as AssetDTO);

  const handleRedirect = async () => {
    const savedFields = {
      actionType: REDIRECT_ACTIONS.GO_TO_ASSET_CARD,
      assetId: latestGeneratedAsset?.id,
      category,
      assetType,
    };
    await dispatch(temporaryFieldSaver(savedFields));
    navigate(path);
  };

  const handleCancelProcess = async () => {
    if (!isFromLegacyPlan && !isEmpty(latestGeneratedAsset)) {
      await handleRedirect();
    }
    onCancelProcess();
  };

  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      {isInfoModalOpen && (
        <HowDoesItWorkModal onClose={handleInfoModalClose} isPD={!isHCP} />
      )}
      {!isInfoModalOpen && (
        <Modal show onHide={handleCancelProcess}>
          <div className="t-flex t-items-center t-justify-between">
            <img
              src={bundesNotarKammerLogo}
              className="t-w-1/2"
              alt="Bundesnotarkammer logo"
            />
            <button
              type="button"
              disabled={downloading}
              className="t-outline-none t-mr-5 t-text-beta-200"
              onClick={handleDocumentDownload}
            >
              {downloading ? (
                <div className="t-flex t-items-center">
                  <span className="typo-theta t-text-beta-200">
                    {t('zvrRegistration:downloading')}
                  </span>
                  <Spinner className="t-w-5 t-h-5 t-ml-1" />
                </div>
              ) : (
                <div className="t-flex t-items-center">
                  <span className="typo-theta t-text-beta-200">
                    {t('zvrRegistration:download')}
                  </span>
                  <IconDownload
                    role="presentation"
                    data-tip={
                      questionnaire === QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY
                        ? t('zvrRegistration:document_download_tooltip_hcp')
                        : t('zvrRegistration:document_download_tooltip_pd')
                    }
                    className="t-inline t-ml-1"
                  />
                </div>
              )}
            </button>
          </div>
          <h1 className="typo-alpha t-mt-9">
            {t('zvrRegistration:registration_modal_title')}
          </h1>
          <p className="typo-epsilon t-mt-4">
            {t(
              isHCP
                ? 'zvrRegistration:registration_modal_description'
                : 'zvrRegistration:pd_registration_modal_description'
            )}
          </p>

          {!downloading && !isFromLegacyPlan && (
            <div className="t-flex t-items-center t-py-4 t-mt-4 t-border-t t-border-b t-border-solid t-border-beta-200">
              <IconCheck className="t-text-alpha-600" />
              <p className="typo-theta t-text-beta-700 t-ml-4">
                {questionnaire === QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY
                  ? t('zvrRegistration:successfully_deposited_hcp')
                  : t('zvrRegistration:successfully_deposited_pd')}
              </p>
            </div>
          )}

          <div className="t-flex t-justify-end t-items-center t-mt-4">
            <h3 className="typo-gamma t-line-through t-mr-3">
              {t('zvrRegistration:zvr_registration_price_without_discount')}
            </h3>
            <h1 className="typo-alpha">
              {t('zvrRegistration:zvr_registration_price_discount')}
            </h1>
            <IconInfo
              role="presentation"
              data-tip={t('zvrRegistration:tooltip_price')}
              className="t-inline t-text-delta-700 t-ml-3"
            />
          </div>

          <div className="t-flex t-justify-end t-items-end t-mt-4 t-flex-col sm:t-flex-row">
            <div>
              <Button
                category="secondary"
                className="t-mr-0 sm:t-mr-3 t-py-2 t-mb-3 sm:t-mb-0"
                onClick={handleCancelProcess}
              >
                {t('zvrRegistration:cancel')}
              </Button>
            </div>

            <div>
              <Button
                className="t-py-2"
                onClick={nextStep}
                loading={downloading}
              >
                {t('zvrRegistration:register_officially')}
              </Button>
            </div>
          </div>

          <button
            onClick={() => setIsInfoModalOpen(true)}
            type="button"
            className="t-flex t-items-center t-w-full t-justify-end t-mt-8"
          >
            <IconQuestionCircle className="t-text-alpha-600 t-mr-2" />
            <h4 className="typo-eta t-text-alpha-600">
              {t('zvrRegistration:hcp_card_how_btn')}
            </h4>
          </button>
        </Modal>
      )}
    </>
  );
}
