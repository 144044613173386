import React from 'react';
import classnames from 'classnames';

import { InfoTooltip } from 'modules/common/components';

export interface CheckboxProps {
  checked: boolean;
  id?: string;
  onChange?: () => void;
  text?: string;
  disabled?: boolean;
  textClassName?: string;
  isBlueBg?: boolean;
  tooltipText?: string;
  isError?: boolean;
  testId?: string;
}

export default function Checkbox({
  checked,
  id,
  onChange,
  text,
  tooltipText,
  testId,
  disabled = false,
  textClassName = '',
  isBlueBg = false,
  isError = false,
}: CheckboxProps) {
  const labelClass = classnames({
    'Checkbox Checkbox--medium': true,
    't-cursor-not-allowed': disabled,
  });

  const boxClass = classnames({
    'Checkbox-result': true,
    blue: isBlueBg,
    't-border-epsilon-600': isError,
  });

  return (
    <>
      <label
        className={labelClass}
        tabIndex={0}
        role="checkbox"
        aria-checked={checked}
        aria-labelledby="checkboxLabel"
        htmlFor={id}
        data-tip={tooltipText}
      >
        <input
          id={id}
          type="checkbox"
          className="jsCeckboxSource Checkbox-source"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          data-testid={testId}
        />
        <span className={boxClass} />
        {!!text && (
          <span className="Checkbox-caption" id="checkboxLabel">
            <span className={textClassName}>
              {text}
              &nbsp;
            </span>
          </span>
        )}
      </label>
      {tooltipText && <InfoTooltip tipText={tooltipText} className="t-p-1" />}
    </>
  );
}
