import styled from 'styled-components';

const TextAreaEnd = styled.textarea`
  border: 1px solid var(--color-beta-100);
  box-sizing: border-box;
  border-radius: var(--border-radius-sm);
  color: var(--color-beta-500);
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-md);
  line-height: var(--line-height-sm);
  margin-top: 8px;
  padding: 8px 16px;
  resize: none;
  &:focus {
    box-shadow: none;
    outline: none;
  }
  width: ${({ width }) => width || '415px'};
`;

export default TextAreaEnd;
