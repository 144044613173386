import React from 'react';

import {
  dateHelper,
  getFullAddress,
  optionsHelper,
  useGetCountries,
  config,
  useTranslation,
} from 'modules/common/helpers';

import {
  getQuestionnaire,
  QUESTIONNAIRE_KEYS,
} from 'modules/assistant/constants/questionnaires';
import { QUESTIONNAIRE_FORM_FIELD_TYPES as F } from 'modules/assistant/constants/questionnaires/questionnaireFormFieldTypes';

interface AddInformationEndProps {
  answerData: Record<string, any>[];
  questionnaire: QUESTIONNAIRE_KEYS;
  stepKey: string;
}

export default function AddInformationEnd({
  answerData,
  questionnaire,
  stepKey,
}: AddInformationEndProps) {
  const { t } = useTranslation(['common', 'assistant', questionnaire]);
  const countries = useGetCountries();

  const QUESTIONNAIRE = getQuestionnaire(questionnaire, t);
  const stepData = QUESTIONNAIRE[stepKey]?.formElements || [];

  const genderOptions = optionsHelper.getGenderOptions2();

  const getFieldValue = (fieldsData, value) => {
    switch (fieldsData.type) {
      case F.INSURANCE_TYPE:
        return (
          fieldsData?.options?.find((option) => option?.value === value)
            ?.label || value
        );
      case F.ADDRESS:
        return getFullAddress(value, countries);
      case F.DATE:
      case F.BIRTHDAY:
        return dateHelper.convertDateFormat(
          value,
          config.format.uiDashboardDate
        );
      case F.GENDER:
        return genderOptions.find((option) => option.value === value)?.label;
      case F.PHONE:
        return `+${value}`;
      default:
        return value;
    }
  };

  return (
    <div className="t-grid t-grid-cols-12 t-gap-2 t-w-full">
      {answerData.map((item, idx) => (
        <div
          className="t-rounded t-p-3 t-bg-alpha-100 t-col-span-12 xl:t-col-span-6 t-mt-2"
          key={`${Object.keys(item)[0]}_${idx}`}
        >
          {stepData.map((field, idx) => {
            if (!item[field.type]) return null;
            return (
              <React.Fragment
                key={`${field.type}_${idx}_${Object.keys(item)[0]}`}
              >
                <p className="typo-zeta t-pb-1 t-mt-2">{field.label}</p>
                <p className="typo-delta t-p-2 t-bg-beta-50 t-rounded-sm t-text-alpha-600">
                  {getFieldValue(field, item[field.type])}
                </p>
              </React.Fragment>
            );
          })}
        </div>
      ))}
    </div>
  );
}
