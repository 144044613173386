import produce from 'immer';
import initialState from 'store/reducers/initialState';
import { GET_NEXT_STEPS } from 'store/types/nextStepTypes';

const nextStepReducer = (
  state = initialState.nextSteps,
  action: ReducerAction
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_NEXT_STEPS: {
        draft.list = action.payload.nextSteps;
        return draft;
      }
      default:
        return draft;
    }
  });

export default nextStepReducer;
