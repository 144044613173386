import { LOAD_HEIRS } from 'store/types/heirActionTypes';
import helper from 'store/reducers/reducerHelper';
import initialState from 'store/reducers/initialState';

const heirReducer = (state = initialState.heirs, action) => {
  return helper.handleActions(state, action, {
    [LOAD_HEIRS](state, payload) {
      state.list = payload.heirs as ContactDTO[];
    },
  });
};

export default heirReducer;
