import React from 'react';

import { useTranslation } from 'modules/common/helpers';
import { Button } from 'modules/common/components';

import { ReactComponent as IconArrowRestart } from 'images/arrow-restart.svg';

interface ResetButtonProps {
  reset: () => void;
  className?: string;
}

const ResetButton = ({ reset, className }: ResetButtonProps) => {
  const { t } = useTranslation(['common']);
  return (
    <div className={className}>
      <Button
        onClick={reset}
        className="t-px-0 t-bg-transparent"
        category="ghost"
      >
        <IconArrowRestart className="t-w-5 t-mr-2" />
        <span>{t('assistant:reset_all')}</span>
      </Button>
    </div>
  );
};

export default ResetButton;
