import React from 'react';

import {
  getDisplayedName,
  getDisplayedOption1,
  getDisplayedOption2,
} from 'modules/asset/helpers';
import {
  getFullAddress,
  useGetCountries,
  useTranslation,
} from 'modules/common/helpers';

import {
  Button,
  PdfDownloadLinkAsBeneficiary,
  TextWithHyphenSpan,
} from 'modules/common/components';

import { ASSET_TYPES_DOC_GEN } from 'modules/estatePlan/constants/assetTypeWithDocGen';

interface BeneficiaryAssetCardProps {
  asset: AssetDTO;
  assetType: AssetTypeDTO;
  handleViewAsset: (asset, assetType) => void;
}

const BeneficiaryAssetCard = ({
  asset,
  assetType,
  handleViewAsset,
}: BeneficiaryAssetCardProps) => {
  const countries = useGetCountries();
  const { t } = useTranslation(['plan']);

  const copiedAsset = {
    ...asset,
    address: asset?.address
      ? getFullAddress(asset?.address, countries)
      : undefined,
  };

  // check if asset type has document generator
  const hasDocumentGenerator = Object.values(ASSET_TYPES_DOC_GEN).includes(
    assetType?.name
  );

  const displayedNameObj = getDisplayedName(copiedAsset, assetType, []);
  const displayedOption1 = getDisplayedOption1(
    copiedAsset,
    assetType,
    hasDocumentGenerator,
    displayedNameObj,
    []
  );

  const displayedOption2 = getDisplayedOption2(
    copiedAsset,
    assetType,
    hasDocumentGenerator,
    displayedNameObj,
    displayedOption1,
    []
  );

  const assetName = asset.name ? asset.name : t(`${displayedNameObj.value}`);

  const openAssetViewModal = () => {
    handleViewAsset(asset, assetType);
  };

  return (
    <div className="card t-h-full t-py-5 t-px-5 t-flex t-flex-col t-justify-between">
      <div>
        <h2 className={`typo-beta t-text-delta-700 t-capitalize`}>
          <TextWithHyphenSpan>{assetName}</TextWithHyphenSpan>
        </h2>

        {displayedOption1?.value && (
          <div className="t-mt-4">
            {displayedOption1.label && (
              <p className="typo-delta t-text-beta-200">
                {t(displayedOption1.label)}
              </p>
            )}
            <p className="typo-delta t-text-beta-500">
              {displayedOption1.value}
            </p>
          </div>
        )}

        {displayedOption2?.value && (
          <div className="t-mt-1">
            {displayedOption2.label && (
              <p className="typo-delta t-text-beta-200">
                {t(displayedOption2.label)}
              </p>
            )}
            <p className="typo-delta t-text-beta-500">
              {displayedOption2.value}
            </p>
          </div>
        )}

        {asset?.documents && asset.documents.length > 0 && (
          <div
            className="t-text-beta-500 t-mt-4"
            onClick={(e) => e.stopPropagation()}
          >
            <p className="typo-delta t-text-beta-400">
              {t('common:document_label')}
            </p>
            {asset.documents.map((document) => (
              <PdfDownloadLinkAsBeneficiary
                document={document}
                key={document.id}
              />
            ))}
          </div>
        )}
      </div>

      <div className="t-flex t-justify-end t-mt-3">
        <Button category="tertiary" onClick={openAssetViewModal}>
          {t('common:open')}
        </Button>
      </div>
    </div>
  );
};

export default BeneficiaryAssetCard;
