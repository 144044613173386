import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { toggleAccountPanel } from 'store/actions/layoutActions';
import { getFullName, useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

import { ReactComponent as ChevronIcon } from 'images/icon-bold-chevron-up.svg';
import { CLICK_OUTSIDE_ELEMENT_TO_EXCLUDE } from 'modules/common/constants';

const AccountMenuDropdown = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['familyAccounts']);

  const isAccountPanelOpen = useSelector(
    (state: RootState) => state.layout.isAccountPanelOpen
  );

  const familyAccounts = useSelector(
    (state: RootState) => state.familyAccounts.list
  );

  const activeAccount =
    familyAccounts.find((account) => !!account.active) || {};

  const displayedName =
    getFullName(activeAccount, false).trim() ||
    t('familyAccounts:empty_name_placeholder');

  const toggleProfilePanel = () => {
    dispatch(toggleAccountPanel());
  };

  return (
    <div id={CLICK_OUTSIDE_ELEMENT_TO_EXCLUDE.ACCOUNT_NAV_BUTTON}>
      <Button
        category="ghost"
        className="t-flex hover:t-border-delta-700 t-max-w-40 sm:t-max-w-48"
        onClick={toggleProfilePanel}
      >
        <p className="typo-eta t-uppercase t-text-delta-700 t-whitespace-nowrap t-truncate">
          {displayedName}
        </p>
        <ChevronIcon
          className={`t-w-4 t-h-2.5 t-text-delta-700 t-ml-2.5 ${
            !isAccountPanelOpen ? 't-transform t-rotate-180' : ''
          }`}
        />
      </Button>
    </div>
  );
};

export default AccountMenuDropdown;
