import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'store/reducers';
import { markAllAsRead } from 'store/actions/notificationActions';
import { useTranslation } from 'modules/common/helpers';

import { Notification } from 'modules/notification/components';

import { NOTIFICATIONS } from 'modules/notification/constants';
import { NOTIFICATION_CODES as NC } from 'modules/notification/constants';

import flag from 'images/flag-circle.png';
import imgAllDone from 'images/img_all_done.png';

export default function NotificationPanel() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['notification']);
  const beNotifications = useSelector(
    (state: RootState) => state.notifications.notifications
  );
  const referralOrigin = useSelector(
    (state: RootState) => state.user.current.referralOrigin || false
  );

  // if there is any notifications for the referral bonus
  // remove them if referralOrigin = false
  const notifications = beNotifications.filter((notification) =>
    notification.code === NC.REFERRAL_BONUS ? !!referralOrigin : true
  );
  const [viewUnreadNotifications, setViewUnreadNotifications] =
    React.useState(true);

  const handleMarkAllAsRead = async () => {
    await dispatch(markAllAsRead());
  };

  const toggleViewReadNotifications = () => {
    setViewUnreadNotifications((prevState) => !prevState);
  };

  const unreadNotifications = notifications.filter(
    (notification) => !notification.read
  );

  const unreadNotificationCount = unreadNotifications.length;
  const shownNotifications = viewUnreadNotifications
    ? unreadNotifications
    : notifications;
  const numberOfShownNotifications = shownNotifications.length;

  return (
    <div
      className="card t-rounded-sm t-w-80 t-absolute t-z-50 t-right-0 t-top-32 xl:t-top-16 t-overflow-y-auto"
      style={{ maxHeight: '90vh' }}
    >
      <div className="t-flex t-justify-between t-mb-5 t-pt-5 t-px-6">
        <h2 className="typo-beta t-mr-2 t-whitespace-nowrap">
          {t('notification:panel_title')}
        </h2>
        {numberOfShownNotifications > 0 && (
          <div className="t-rounded-full t-bg-beta-100 t-w-5 t-h-5 t-flex t-justify-center t-items-center">
            <p className="t-text-beta-500" style={{ fontSize: '12px' }}>
              {numberOfShownNotifications}
            </p>
          </div>
        )}
      </div>
      <div className="t-flex t-items-center t-mb-2 t-px-6">
        <p className="typo-zeta t-text-beta-400 t-whitespace-nowrap t-mr-2">
          {t('notification:only_show_unread')}
        </p>
        <label className="Switcher" htmlFor="viewReadNoti">
          <input
            name="viewReadNoti"
            id="viewReadNoti"
            type="checkbox"
            defaultChecked={viewUnreadNotifications}
            className="Switcher-source"
            onChange={toggleViewReadNotifications}
          />
          <span className="Switcher-result" />
        </label>
      </div>

      {shownNotifications.map((beNoti) => {
        const notification = { ...beNoti, ...NOTIFICATIONS[beNoti.code] };
        return <Notification key={beNoti.id} notification={notification} />;
      })}
      <div className="t-text-center t-pb-6 t-pt-2">
        <div className="t-mb-4 t-mx-6 t-flex t-justify-center">
          <img
            src={numberOfShownNotifications ? flag : imgAllDone}
            alt="notification illustration"
          />
        </div>

        <p className="typo-theta t-mx-12">
          {t(
            `notification:${
              numberOfShownNotifications
                ? 'all_notifications_last_30_days'
                : viewUnreadNotifications
                ? 'all_notifications_read'
                : 'no_read_notifications_last_30_days'
            }`
          )}
        </p>
        {viewUnreadNotifications && unreadNotificationCount > 0 && (
          <button
            onClick={handleMarkAllAsRead}
            className="t-text-beta-400 t-mx-3 t-mt-3.5 typo-zeta t-font-thin t-outline-none t-underline t-whitespace-nowrap"
          >
            {t('notification:mark_read')}
          </button>
        )}
      </div>
    </div>
  );
}
