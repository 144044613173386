import React from 'react';
import { useOutsideAlerter } from 'modules/common/helpers';

interface OutsideClickerProps {
  onOutsideClick?: () => void;
  excludedElementId?: string;
  children: React.ReactNode | React.ReactNode[];
}

export default function OutsideClicker({
  onOutsideClick,
  excludedElementId,
  children,
}: OutsideClickerProps) {
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, onOutsideClick, excludedElementId);

  return <div ref={wrapperRef}>{children}</div>;
}
