import React from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import ReactTooltip from 'react-tooltip';

import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

import {
  QUESTIONNAIRE_KEYS,
  QUESTIONNAIRE_PAGES,
} from 'modules/assistant/constants/questionnaires';

import imgRequirementCheck from 'images/img-requirement-check.png';

const OnBoardingWizardReqCheck = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'onBoardingWizard']);

  const onSkip = () => {
    navigate('/');
  };

  const onGoToInitialAssessment = () => {
    navigate(QUESTIONNAIRE_PAGES[QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT].path);
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div className="t-mt-8" data-testid="OnBoardingWizardReqCheck">
      <h1 className="typo-alpha">
        {t('onBoardingWizard:requirement_check_page_title')}
      </h1>
      <p className="typo-gamma t-mt-2.5">
        {t('onBoardingWizard:requirement_check_page_desc')}
      </p>

      <div className="t-flex t-flex-col t-items-center t-mt-5">
        <img src={imgRequirementCheck} alt="requirement check" />
        <p
          className="typo-gamma t-mt-3"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t('onBoardingWizard:requirement_check_estimated_time_desc')
            ),
          }}
        />

        <div className="t-flex t-mt-8">
          <div
            data-tip={t('common:skip_for_now_button_tooltip')}
            data-for="skipForNow"
          >
            <Button onClick={onSkip} category="secondary" className="t-mr-2.5">
              {t('common:skip_for_now')}
            </Button>
          </div>
          <ReactTooltip id="skipForNow" effect="solid" multiline />

          <Button onClick={onGoToInitialAssessment}>
            {t('common:start_now')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingWizardReqCheck;
