import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useTranslation, validationHelper } from 'modules/common/helpers';
import {
  generateSelfKeyDocument,
  getAllowedToken,
  passphraseOptIn,
} from 'store/actions/userActions';

import { Button, Modal, TextInputRef } from 'modules/common/components';

import { ERROR_CODES } from 'modules/common/constants';

import imgSecurity from 'images/zero-knowledge-policy.png';

export interface SecurityKeyEmailConfirmModalProps {
  passphrase: string;
  onCancel: () => void;
  goToSelectionStep: () => void;
}

type Input = {
  code: number;
};

function SecurityKeyEmailConfirmModal({
  passphrase,
  onCancel,
  goToSelectionStep,
}: SecurityKeyEmailConfirmModalProps) {
  const { t } = useTranslation(['auth', 'securityKey']);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Input>();

  const [loading, setLoading] = React.useState(false);

  const onSubmit = async ({ code }) => {
    if (!passphrase || !code) return;

    setLoading(true);
    const response: any = await dispatch(passphraseOptIn(passphrase, code));

    if (response?.errorCode === ERROR_CODES.INVALID_TOKEN) {
      setError('code', { type: 'invalidToken' });
      return;
    }

    let allowed: any;
    if (!!response) allowed = await dispatch(getAllowedToken(passphrase));
    if (!!allowed) await dispatch(generateSelfKeyDocument());

    setLoading(false);
    if (!!response) goToSelectionStep();
  };

  const error =
    errors['code']?.type === 'invalidToken'
      ? t('common:invalid_code')
      : errors['code']?.type === 'validate'
      ? `${t('auth:confirmation_code')} ${t('common:invalid')}`
      : errors['code']
      ? `${t('auth:confirmation_code')} ${t('common:required')}`
      : '';

  return (
    <Modal show dataTestid="SecurityKeyEmailConfirmModal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="typo-alpha">
          {t(`securityKey:set_passphrase_confirm_modal_title`)}
        </h1>

        <p className="typo-epsilon t-mt-4">
          {t(`securityKey:set_passphrase_confirm_modal_description`)}
        </p>

        <div className="t-mt-6 t-flex t-items-center">
          <TextInputRef
            inputClass="t-w-1/2"
            {...register('code', {
              required: true,
              validate: (value) => validationHelper.validateCode(4, value),
            })}
            label={t('auth:confirmation_code')}
            type="tel"
            placeholder="XXXX"
            maxLength={4}
            autoComplete={false}
            error={error}
          />

          <img
            src={imgSecurity}
            alt="security"
            className="t-hidden sm:t-inline-block"
          />
        </div>
        <div className="t-flex t-justify-end t-mt-7 t-flex-col sm:t-flex-row">
          <div>
            <Button
              onClick={onCancel}
              category="secondary"
              className="t-mr-3 t-mb-3 sm:t-mb-0"
              disabled={loading}
            >
              {t('common:cancel')}
            </Button>
          </div>

          <div>
            <Button type="submit" className="t-py-2" loading={loading}>
              {t('common:submit_and_continue')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default SecurityKeyEmailConfirmModal;
