import {
  PLAN_STEPS,
  PLAN_STEP_KEYS,
} from 'modules/estatePlan/constants/constants';
import { find } from 'lodash';

export default function getPlanStepByPath(path: string): PlanStep {
  return (
    find(PLAN_STEPS, (step) => path.includes(step.path)) ||
    PLAN_STEPS[PLAN_STEP_KEYS.INTRODUCTION]
  );
}
