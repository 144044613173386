import config from 'modules/common/helpers/configHelper';
import httpHelper from 'modules/common/helpers/httpHelper';

const BASE_URL = config.beUrl;

function getKeyHolders() {
  return httpHelper.get(`${BASE_URL}/api/key-holders`, {});
}

function createKeyHolder(keyHolderData: KeyHolder, hideErrors: boolean) {
  return httpHelper.post(
    `${BASE_URL}/api/key-holder`,
    { ...keyHolderData },
    undefined,
    hideErrors
  );
}

function editKeyHolder(keyHolderData: KeyHolder, hideErrors: boolean) {
  return httpHelper.put(
    `${BASE_URL}/api/key-holder`,
    { ...keyHolderData },
    undefined,
    hideErrors
  );
}

function deleteKeyHolder(id: string) {
  return httpHelper.delete(`${BASE_URL}/api/key-holder`, { id });
}

export default {
  getKeyHolders,
  createKeyHolder,
  editKeyHolder,
  deleteKeyHolder,
};
