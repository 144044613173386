import React from 'react';

import { useTranslation, validationHelper } from 'modules/common/helpers';

import {
  FormErrors,
  FormHandleSubmit,
  FormRegister,
} from 'modules/common/types';
import { Button, Modal, TextInputRef } from 'modules/common/components';

import imgSecurity from 'images/zero-knowledge-policy.png';

interface ContactOfConfirmationCodeModalProps {
  onShowAccessCode: (code: number) => void;
  onCancel: () => void;
  register: FormRegister;
  handleSubmit: FormHandleSubmit;
  errors: FormErrors;
}

type Input = {
  code: number;
};

export default function ContactOfConfirmationCodeModal({
  onShowAccessCode,
  onCancel,
  register,
  handleSubmit,
  errors,
}: ContactOfConfirmationCodeModalProps) {
  const { t } = useTranslation(['profile', 'common']);
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (formData: Input) => {
    setLoading(true);
    await onShowAccessCode(formData.code);
    setLoading(false);
  };

  const error =
    errors['code']?.type === 'invalidToken'
      ? t('common:invalid_code')
      : errors['code']?.type === 'validate'
      ? `${t('auth:confirmation_code')} ${t('common:invalid')}`
      : errors['code']
      ? `${t('auth:confirmation_code')} ${t('common:required')}`
      : '';

  return (
    <Modal show>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="typo-alpha">
          {t('mcontacts:confirmation_code_modal_title')}
        </h1>

        <p className="typo-epsilon t-mt-4">
          {t('mcontacts:confirmation_code_modal_description')}
        </p>

        <fieldset disabled={loading}>
          <div className="t-mt-6 t-flex t-items-center">
            <TextInputRef
              inputClass="t-w-1/2"
              {...register('code', {
                required: true,
                validate: (value) => validationHelper.validateCode(4, value),
              })}
              label={t('auth:confirmation_code')}
              type="tel"
              placeholder="XXXX"
              maxLength={4}
              autoComplete={false}
              error={error}
            />

            <img
              src={imgSecurity}
              alt="security"
              className="t-hidden sm:t-inline-block"
            />
          </div>
        </fieldset>
        <div className="t-flex t-justify-end t-mt-7 t-flex-col sm:t-flex-row">
          <Button
            onClick={onCancel}
            category="secondary"
            className="t-py-2 t-mr-3"
            disabled={loading}
          >
            {t('common:cancel')}
          </Button>
          <Button type="submit" loading={loading}>
            {t('common:continue')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
