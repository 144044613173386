import React from 'react';
import isEmail from 'validator/lib/isEmail';

import { isJsonString, useTranslation } from 'modules/common/helpers';
import {
  CommonFormInputProps,
  FormErrors,
  FormSetValue,
} from 'modules/common/types';
import { Button, PhoneInput, TextInputRef } from 'modules/common/components';

import { VALIDATE } from 'modules/common/constants/generic';
import { FIELD_TYPE_KEYS as F } from 'modules/estatePlan/constants/fieldLibrary';

import { ReactComponent as IconTrash } from 'images/trash-hollow.svg';
import { ReactComponent as IconPlus } from 'images/icon-plus.svg';

interface MultiFieldProps extends CommonFormInputProps {
  stringifiedValues?: string;
  setParentValue: FormSetValue;
  errors: FormErrors;
  field: AssetFieldDTO;
  trimmed?: boolean;
  customLabel?: string;
}

export default function MultiField({
  stringifiedValues,
  setParentValue,
  control,
  register,
  errors,
  field,
  watch,
  setValue,
  trimmed,
  customLabel,
}: MultiFieldProps) {
  const { t } = useTranslation(['common', 'plan']);
  const defaultValues = !stringifiedValues
    ? ['']
    : isJsonString(stringifiedValues)
    ? JSON.parse(stringifiedValues)
    : [stringifiedValues];

  // this will not be updated when value of a field is changed
  // to avoid the re-rendering of the whole component
  const [inputs, setInputs] = React.useState(defaultValues);
  // this will be updated when value of a field is changed
  // to update the field value in the parent component
  const [updatedInputs, setUpdatedInputs] = React.useState(defaultValues);

  const errorRequiredLabel = `${t(field.label)} ${t('common:required')}`;
  const errorInvalidLabel = `${t(field.label)} ${t('common:invalid')}`;
  const getInputName = (num: number) => `${field.type}-${num}-multi`;
  const inputsLength = inputs.length;

  const currentValues = [...new Array(inputsLength)].map((_, idx) => {
    const inputValue = watch(getInputName(idx)) || '';
    return inputValue;
  });

  React.useEffect(() => {
    const valuesDifferent = `${updatedInputs}` !== `${currentValues}`;
    if (valuesDifferent) {
      setUpdatedInputs(currentValues);
    }
  }, [currentValues]);

  React.useEffect(() => {
    updatingParentValue(updatedInputs);
  }, [updatedInputs]);

  const updatingParentValue = (values) => {
    const fieldValueArray = values
      ?.filter((input) => input !== '')
      .map((input) => (trimmed ? input.trim() : input));
    const fieldValue = fieldValueArray?.length
      ? JSON.stringify(fieldValueArray)
      : '';
    setParentValue(field.type, fieldValue);
  };

  const handleInputDelete = (idx: number) => {
    const newUpdatedInputs = [...updatedInputs];
    const newInputs = [...inputs];
    newInputs.splice(idx, 1);
    newUpdatedInputs.splice(idx, 1);
    newUpdatedInputs.forEach((input, idx) => {
      setValue(getInputName(idx), input);
    });
    setUpdatedInputs(newUpdatedInputs);
    setInputs(newInputs);
  };

  const getErrorLabel = (fieldName) => {
    const error = errors[fieldName];
    if (error) {
      return error?.type === VALIDATE ? errorInvalidLabel : errorRequiredLabel;
    }
    return '';
  };

  const addInput = (defaultValue: string, inputNo: number) => {
    switch (field.type) {
      case F.EMAIL:
        return (
          <div className="t-flex-grow">
            <TextInputRef
              {...register(getInputName(inputNo), {
                required: field.required,
                validate: (value) => {
                  if (!value) return true;
                  return isEmail(value.trim());
                },
              })}
              name={getInputName(inputNo)}
              label={customLabel || t(field.label)}
              type="email"
              error={getErrorLabel(getInputName(inputNo))}
              defaultValue={defaultValue}
            />
          </div>
        );
      case F.PHONE:
        return (
          <div className="t-flex-grow">
            <PhoneInput
              control={control}
              error={getErrorLabel(getInputName(inputNo))}
              defaultValue={defaultValue}
              isRequired={field.required}
              name={getInputName(inputNo)}
              label={customLabel || ''}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const renderInputs = () => {
    return inputs.map((input, idx) => (
      <div key={`${input}_${idx}`} className="t-flex">
        {addInput(input, idx)}
        {inputs.length !== 1 && (
          <button
            onClick={() => handleInputDelete(idx)}
            type="button"
            className="t-align-bottom t-text-beta-700 t-ml-2 t-flex-grow-0 t-outline-none"
          >
            <IconTrash />
          </button>
        )}
      </div>
    ));
  };

  const handleAddInput = () => {
    setInputs([...inputs, '']);
  };

  const inputElements = renderInputs();
  const isPossibleToAdd = !!watch(getInputName(inputsLength - 1));

  return (
    <div>
      {inputElements}
      {isPossibleToAdd && (
        <Button category="ghost" className="t-mb-2.5" onClick={handleAddInput}>
          <IconPlus
            role="presentation"
            className="t-inline t-text-delta-600 t-align-bottom t-mr-2"
          />
          <span className="xs:t-hidden sm:t-inline t-uppercase t-align-text-bottom">{`${t(
            field.label
          )}`}</span>
        </Button>
      )}
      <input {...register(field.type)} name={field.type} type="hidden" />
    </div>
  );
}
