import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { camelCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { RootState } from 'store/reducers';
import { downloadDocument } from 'store/actions/assetActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { generateQuestionnairePDF } from 'store/actions/questionnaireActions';
import { fetchAssistantProgress } from 'store/actions/assistantActions';
import { getAssetTypesList } from 'store/actions/assetTypeActions';
import { setModal } from 'store/actions/modalActions';
import { isAllowedToken, useTranslation } from 'modules/common/helpers';

import {
  AdditionalInfoBox,
  Button,
  Modal,
  TextInputRef,
} from 'modules/common/components';

import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';
import {
  QUESTIONNAIRES_TO_ASSET_TYPES,
  REDIRECT_ACTIONS,
} from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';
import { MODALS } from 'modules/common/constants';

import { ReactComponent as IconCheck } from 'images/icon-pricing-check.svg';
import { LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';

interface FinalizeStepModalProps {
  onClose: () => void;
  handleZVRProcess: (document: DocumentDTO) => void;
  questionnaire: QUESTIONNAIRE_KEYS;
}

const FinalizeStepModal = ({
  onClose,
  handleZVRProcess,
  questionnaire,
}: FinalizeStepModalProps) => {
  const { t } = useTranslation(['assistant', 'common']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,

    formState: { errors },
  } = useForm({ shouldUnregister: false });

  const assistantProgress = useSelector(
    (state: RootState) => state.assistant.progress
  );
  const assets: AssetsDTO = useSelector((state: RootState) => state.assets);
  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const isGlobalModal = useSelector((state: RootState) => !!state.modal.name);

  const [isDownloading, setIsDownloading] = React.useState(false);

  const { path, category, assetType } =
    QUESTIONNAIRES_TO_ASSET_TYPES[camelCase(questionnaire)];

  const getLatestGeneratedAsset = (type: string) => {
    const assetCategories = Object.keys(assets);
    const flattedAssets = assetCategories.reduce(
      (acc: AssetDTO[], key: string) => [...acc, ...assets[key]],
      []
    );

    return flattedAssets.find(
      (asset) => asset.assetTypeName === type && !asset.archived
    );
  };

  const latestGeneratedAsset = getLatestGeneratedAsset(assetType);
  const answersChanged =
    assistantProgress[questionnaire]?.status === 0.5 &&
    latestGeneratedAsset?.id;

  const enterZVRProcess =
    !user.zvr &&
    [
      QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY,
      QUESTIONNAIRE_KEYS.PATIENT_DECREE,
    ].includes(questionnaire);

  const handleDocumentGeneration = async () => {
    setIsDownloading(true);
    const { location } = getValues();
    const document: any = await dispatch(
      generateQuestionnairePDF(questionnaire, category, location)
    );

    if (document) {
      await dispatch(getAssetTypesList());
      await dispatch(fetchAssistantProgress());
      await dispatch(downloadDocument(document));

      const dataToSave = {
        actionType: REDIRECT_ACTIONS.GO_TO_ASSET_CARD,
        assetId: document?.assetId || latestGeneratedAsset?.id,
        category,
        assetType,
        documentId: document.id,
      };

      // temporarily save to localStorage
      localStorage.setItem(
        LOCAL_STORAGE_VARS.DATA_ON_REDIRECT,
        JSON.stringify(dataToSave)
      );

      setIsDownloading(false);
      if (enterZVRProcess) {
        handleZVRProcess(document);
      } else {
        await handleRedirect(dataToSave);
      }
      onClose();
    }
  };

  const handleRedirect = async (dataToSave) => {
    await dispatch(temporaryFieldSaver(dataToSave));
    navigate(path);
  };

  // Passphrase is required to download the document
  const [passphraseApproved, setPassphraseApproved] = React.useState(false);
  React.useEffect(() => {
    if (passphraseApproved) {
      onSave();
    }
  }, [passphraseApproved]);

  const onSave = async () => {
    if (!user.passphraseOptOut && !isAllowedToken()) {
      dispatch(
        setModal({
          name: MODALS.PASSPHRASE,
          props: {
            successAction: () => {
              setPassphraseApproved(true);
            },
          },
        })
      );
      return;
    }
    setPassphraseApproved(false);
    if (answersChanged) {
      dispatch(
        setModal({
          name: MODALS.COMMON_MODAL,
          props: {
            title: t('assistant:finalize_archive_modal_title'),
            description: t('assistant:finalize_archive_modal_description'),
            primaryButtonText: t(
              'assistant:finalize_archive_modal_generate_btn'
            ),
            successAction: handleArchiveAndGenerateNewDoc,
          },
        })
      );
      return;
    }
    await handleDocumentGeneration();
  };

  const handleArchiveAndGenerateNewDoc = async () => {
    await handleDocumentGeneration();
  };

  const locationRequired = `${t('common:required_upper')}`;

  return (
    <>
      {!isGlobalModal && (
        <Modal show onHide={onClose}>
          <h1 className="typo-alpha">
            {t(`assistant:${questionnaire}_finalize_title`)}
          </h1>
          <p className="typo-epsilon t-mt-4">
            {t(`assistant:${questionnaire}_finalize_description`)}
          </p>
          <div className="t-mt-7">
            <p className="typo-gamma">
              {t(`assistant:${questionnaire}_finalize_what_next`)}
            </p>
            <div className="t-flex t-mt-4">
              <div className="t-mr-3 t-mt-1">
                <IconCheck className="t-text-alpha-600" />
              </div>
              <p className="typo-epsilon">
                {t(`assistant:${questionnaire}_finalize_check_list_1`)}
              </p>
            </div>
            <div className="t-flex t-mt-4">
              <div className="t-mr-3 t-mt-1">
                <IconCheck className="t-text-alpha-600" />
              </div>
              <p className="typo-epsilon">
                {t(`assistant:${questionnaire}_finalize_check_list_2`)}
              </p>
            </div>
            <div className="t-flex  t-mt-4">
              <div className="t-mr-3 t-mt-1">
                <IconCheck className="t-text-alpha-600" />
              </div>
              <p className="typo-epsilon">
                {t(`assistant:${questionnaire}_finalize_check_list_3`)}
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSave)}>
            <div className="t-mt-7">
              <TextInputRef
                {...register('location', { required: true })}
                label={t(`assistant:${questionnaire}_location_input_label`)}
                type="text"
                autoComplete={false}
                error={errors.location && locationRequired}
              />
            </div>
            <div className="t-mt-4" />
            <AdditionalInfoBox>
              <p>{t(`assistant:${questionnaire}_location_additional_info`)}</p>
            </AdditionalInfoBox>
            <div className="t-flex t-justify-end t-mt-7 t-flex-col sm:t-flex-row">
              <div>
                <Button
                  category="secondary"
                  className="t-mr-0 sm:t-mr-3 t-py-2 t-mb-3 sm:t-mb-0"
                  onClick={onClose}
                  disabled={isDownloading}
                >
                  {t('common:cancel')}
                </Button>
              </div>
              <div>
                <Button
                  className="t-py-2"
                  loading={isDownloading}
                  type="submit"
                >
                  <span>{t(`assistant:${questionnaire}_save_button`)}</span>
                </Button>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default FinalizeStepModal;
