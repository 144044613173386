import React from 'react';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { RootState } from 'store/reducers';
import { TransmortalAssetSelector } from 'store/selectors/selectors';
import { useTranslation } from 'modules/common/helpers';

import { EpTransmortalContract } from 'modules/estatePlan/components';
import { Button } from 'modules/common/components';

import { ReactComponent as IconDownload } from 'images/icon-download.svg';

interface EpTransmortalWizardStep2Props {
  handleClose: () => void;
  onDownload: (document: DocumentDTO) => void;
}

function EpTransmortalWizardStep2({
  handleClose,
  onDownload,
}: EpTransmortalWizardStep2Props) {
  const { t } = useTranslation(['common', 'transmortalAuth']);

  const transmortalAsset: AssetDTO = useSelector((state: RootState) =>
    TransmortalAssetSelector(state)
  );

  const [loading, setLoading] = React.useState(false);

  const lastDocument: DocumentDTO = transmortalAsset?.documents?.length
    ? transmortalAsset.documents[transmortalAsset.documents.length - 1]
    : ({} as DocumentDTO);

  const handleDownload = async () => {
    setLoading(true);
    await onDownload(lastDocument);
    setLoading(false);
  };
  return (
    <>
      <h1 className="Typography Typography--title u-weight-600 t-pb-5">
        {t('transmortalAuth:transmortal_title_done')}
      </h1>
      <div className="Modal-info u-pr-0 u-pb-30">
        <Scrollbars style={{ height: 340 }}>
          <EpTransmortalContract />
        </Scrollbars>
      </div>

      <div className="t-flex t-justify-end t-flex-wrap u-pt-30">
        <Button
          category="secondary"
          onClick={handleDownload}
          className="t-self-end"
          loading={loading}
          disabled={!transmortalAsset?.id}
        >
          {!loading && (
            <IconDownload className="t-text-alpha-600 t-w-4 t-mr-2" />
          )}

          {t('download')}
        </Button>

        <Button
          onClick={handleClose}
          className="t-ml-2.5 xs:t-mt-5"
          disabled={loading}
        >
          {t('close')}
        </Button>
      </div>
    </>
  );
}

export default EpTransmortalWizardStep2;
