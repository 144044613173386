import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, addYears } from 'date-fns';
import styled from 'styled-components';

import { RootState } from 'store/reducers';
import {
  temporaryFieldSaver,
  temporaryFieldError,
} from 'store/actions/temporaryFieldSaverActions';
import { dateHelper, config, useTranslation } from 'modules/common/helpers';

import { EndScreenAnswerElement } from 'modules/assistant/components/Answers/EndScreen';
import { StyledAnswerText } from 'modules/elements/components';
import { Row, Col } from 'modules/bootstrap/components';
import { TextInput } from 'modules/common/components';

export interface ExpirationDateProps {
  previousAnswers?: string;
}

const ExpirationDate = ({ previousAnswers }: ExpirationDateProps) => {
  const { t } = useTranslation(['assistant']);
  const dispatch = useDispatch();

  const [expireYears, setExpireYears] = useState<number | null>(null);

  const temporaryField = useSelector(
    (state: RootState) => state.temporaryField
  );
  const inputValue = temporaryField.value;
  const inputError = temporaryField.error;

  // show previous input val, if entered before
  useEffect(() => {
    if (previousAnswers && typeof previousAnswers === 'string') {
      const formattedDate = dateHelper.convertDateFormat(
        previousAnswers,
        config.format.uiDate
      );
      dispatch(temporaryFieldSaver(formattedDate));
    }
  }, [previousAnswers]);

  const dateIsComplete = (value) => {
    const digitCount = value.replace(/\D/g, '').length;

    return digitCount === 8;
  };

  const handleChange = (_, value: string) => {
    if (dateIsComplete(value)) {
      if (dateHelper.isFutureDate(value, config.format.uiDate)) {
        dispatch(temporaryFieldError(''));
      } else {
        dispatch(temporaryFieldError(t('assistant:wrong_expiration_date')));
      }
    } else {
      dispatch(temporaryFieldError(t('assistant:invalid_date')));
    }

    setExpireYears(null);
    dispatch(temporaryFieldSaver(value));
  };

  const handlePressSpace = (e, yearsAfter: number) => {
    if (e && e.keyCode === 32) {
      setExpirationDate(yearsAfter);
    }
  };

  const setExpirationDate = (yearsAfter: number): void => {
    const formattedDate = format(
      addYears(new Date(), yearsAfter),
      config.format.uiDate
    );
    dispatch(temporaryFieldSaver(formattedDate));
    setExpireYears(yearsAfter);
    dispatch(temporaryFieldError(null));
  };

  return (
    <div className="card xs:t-w-full md:t-w-4/5 xs:t-p-4 md:t-p-7">
      <StyledAnswerText>
        {t('assistant:document_validity_period')}
      </StyledAnswerText>
      <div className="t-flex t-flex-wrap">
        <StyledWrapper
          onClick={() => setExpirationDate(1)}
          onKeyUp={(e) => handlePressSpace(e, 1)}
          role="button"
          tabIndex={0}
        >
          <EndScreenAnswerElement isSelected={expireYears === 1}>
            1 {t('assistant:year')}
          </EndScreenAnswerElement>
        </StyledWrapper>
        <StyledWrapper
          onClick={() => setExpirationDate(2)}
          onKeyUp={(e) => handlePressSpace(e, 2)}
          role="button"
          tabIndex={0}
        >
          <EndScreenAnswerElement isSelected={expireYears === 2}>
            2 {t('assistant:years')}
          </EndScreenAnswerElement>
        </StyledWrapper>
        <StyledWrapper
          onClick={() => setExpirationDate(5)}
          onKeyUp={(e) => handlePressSpace(e, 5)}
          role="button"
          tabIndex={0}
        >
          <EndScreenAnswerElement isSelected={expireYears === 5}>
            5 {t('assistant:years')}
          </EndScreenAnswerElement>
        </StyledWrapper>
      </div>

      <Row>
        <Col md={6}>
          <div className="Form u-mt-8">
            <TextInput
              name="expiration-date"
              label={t('assistant:expiration_date')}
              type="text"
              mask="99.99.9999"
              placeholder="dd.mm.yyyy"
              value={inputValue}
              onChange={handleChange}
              error={inputError}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExpirationDate;

const StyledWrapper = styled.div`
  :focus {
    outline: none;
  }
`;
