import produce from 'immer';

import initialState from 'store/reducers/initialState';
import { HANDLE_ASSETS_SEARCHING } from 'store/types/assetTypes';

const assetReducer = (
  state = initialState.assetSearch,
  action: ReducerAction
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HANDLE_ASSETS_SEARCHING: {
        const { searching, result } = action.payload.data;

        draft.searching = searching;
        draft.result = result;

        return draft;
      }

      default:
        return draft;
    }
  });

export default assetReducer;
