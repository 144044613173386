import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'modules/common/helpers';

import { SiteLanguage } from 'modules/common/components';
import { Row, Col } from 'modules/bootstrap/components';

import authBg from 'images/two-column-page-bg-1.png';
import logo from 'images/logo.svg';
import leaf from 'images/leaf.svg';

type NotFoundPageProps = {
  isInvalidEmailToken: boolean;
};

export default function NotFoundPage(props: NotFoundPageProps) {
  const { isInvalidEmailToken } = props;
  const { t } = useTranslation(['auth']);

  return (
    <>
      <img
        src={authBg}
        className="Decoration Decoration--authentication"
        alt="Not found background"
      />
      <main
        className="t-min-h-screen t-bg-gamma-400 t-p-3 lg:t-p-0"
        data-testid="NotFoundPage"
      >
        <Row className="t-w-full">
          <Col
            lg={{ span: 6, offset: 6 }}
            md={{ span: 8, offset: 2 }}
            className="u-h-100"
          >
            <div className="Form t-pb-12 t-pt-5 md:t-py-12 md:t-px-16 u-h-100">
              <div className="Form-logo">
                <img src={logo} alt="Ninebarc logo" />

                <SiteLanguage additionalClass="u-pb-0" />
              </div>

              <div className="u-pb-40">
                <img src={leaf} alt="Ninebarc leaf" />
              </div>

              <h1 className="Typography Typography--title t-pb-5 u-weight-600">
                {isInvalidEmailToken
                  ? t('auth:invalid_email_token_title')
                  : t('auth:not_found_title')}
              </h1>

              <p className="Typography Typography--subTitle u-pb-30">
                {isInvalidEmailToken
                  ? t('auth:invalid_email_token_description')
                  : t('auth:not_found_description')}
              </p>
              <Link
                className="button-secondary d-inline-flex"
                to={isInvalidEmailToken ? '/login' : '/'}
              >
                {isInvalidEmailToken
                  ? t('auth:go_to_login')
                  : t('auth:go_home')}
              </Link>
            </div>
          </Col>
        </Row>
      </main>
    </>
  );
}
