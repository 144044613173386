import React from 'react';
import ReactDOM from 'react-dom';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

import { config } from 'modules/common/helpers';
import { ViewportProvider } from 'modules/common/helpers/viewportHelper';
import { routes } from 'routes';
import configureStore from 'store';
import 'localization/i18n';
import routerHistory from 'routerHistory';
import { App } from 'modules/app/components';
import { ErrorPage } from 'modules/errorPage/components';
import { MatomoWrapper } from 'modules/common/components';

const store = configureStore();

if (config.nodeEnv && config.sentryDSN) {
  Sentry.init({
    dsn: config.sentryDSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: config.nodeEnv,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={() => <ErrorPage />}>
    <Provider store={store}>
      <HistoryRouter history={routerHistory}>
        <MatomoWrapper siteId={config.matomoSiteId}>
          <ViewportProvider>
            <App routes={routes} />
          </ViewportProvider>
        </MatomoWrapper>
      </HistoryRouter>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
