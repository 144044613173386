import { Controller } from 'react-hook-form';
import Select, {
  components as selectComponents,
  NoticeProps,
  GroupBase,
} from 'react-select';
import React from 'react';

import { useTranslation } from 'modules/common/helpers';
import { FormFieldError } from 'modules/common/types';

import { ControllerProps } from 'react-hook-form/dist/types/controller';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

interface SelectControllerProps extends Partial<ControllerProps<any>> {
  id?: string;
  name: string;
  placeholder?: string;
  className?: string;
  classNamePrefix?: string;
  options: SelectOption[];
  isMulti?: boolean;
  styles?: Record<string, any>;
  components?: Partial<
    SelectComponents<any, boolean, GroupBase<unknown>>
  > | null;
  disabled?: boolean;
  error?: FormFieldError;
  noOptionsMessage?: string;
}

const SelectController: React.FC<SelectControllerProps> = ({
  placeholder,
  defaultValue,
  name,
  options,
  control,
  components,
  noOptionsMessage,
  ...restProps
}) => {
  const { t } = useTranslation(['common']);
  const translatedDefaultPlaceholder =
    placeholder || t('common:default_select_placeholder');
  const protectedDefaultValue = defaultValue || '';

  const NoOptionsMessage = (props: NoticeProps) => {
    return (
      <selectComponents.NoOptionsMessage {...props}>
        <span>
          {noOptionsMessage || t('common:default_no_options_message')}
        </span>
      </selectComponents.NoOptionsMessage>
    );
  };

  return (
    <Controller
      name={name}
      defaultValue={protectedDefaultValue}
      control={control}
      {...restProps}
      render={({ field }) => (
        <Select
          options={options}
          placeholder={translatedDefaultPlaceholder}
          {...restProps}
          {...field}
          components={components || { NoOptionsMessage }}
          inputId={name}
        />
      )}
      {...restProps}
    />
  );
};

SelectController.defaultProps = {
  placeholder: '',
  className: 'Select',
  classNamePrefix: 'Select',
};

export default SelectController;
