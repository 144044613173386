import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { switchAccount } from 'store/actions/userActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { setModal } from 'store/actions/modalActions';
import {
  getFullName,
  highlightElement,
  scrollToElementId,
  useTranslation,
} from 'modules/common/helpers';

import { Button } from 'modules/common/components';
import {
  AddFamilyAccountProcess,
  FamilyAccountCard,
} from 'modules/familyAccount/components';

import { REDIRECT_ACTIONS } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';
import { MODALS } from 'modules/common/constants';

import { ReactComponent as IconInfo } from 'images/icon-info-white.svg';
import { ReactComponent as IconPlus } from 'images/icon-plus.svg';
import imgNoResult from 'images/img_no_result_2.png';

interface MyFamilyAccountsProps {
  backToMyContactsTab: () => void;
}

const MyFamilyAccounts = ({ backToMyContactsTab }: MyFamilyAccountsProps) => {
  const { t } = useTranslation(['common', 'familyAccounts']);
  const dispatch = useDispatch();

  const [addFamilyAccountProcess, setAddFamilyAccountProcess] =
    React.useState(false);

  const dataOnRedirect = useSelector(
    (state: RootState) => state.temporaryField.value
  );
  const familyAccounts = useSelector(
    (state: RootState) => state.familyAccounts.list
  );
  const mainAccount =
    familyAccounts.find((account) => !!account.primary) ||
    ({} as FamilyAccount);
  const subAccounts = familyAccounts.filter((account) => !account.primary);

  const contactsData =
    useSelector((state: RootState) => state.contacts.list) || [];

  const familyAccountIds = familyAccounts.map((account) => account.id);
  const contactsToAddAsFamilyMembers = contactsData.filter(
    (contact) => !familyAccountIds.includes(contact.secondaryUserId)
  );

  const [highlightedCardId, setHighlightedCardId] = React.useState('');
  React.useEffect(() => {
    if (
      dataOnRedirect?.actionType ===
        REDIRECT_ACTIONS.GO_TO_FAMILY_ACCOUNT_CARD &&
      dataOnRedirect?.familyAccountId
    ) {
      setHighlightedCardId(dataOnRedirect.familyAccountId);
      scrollToElementId(dataOnRedirect.familyAccountId);
      dispatch(temporaryFieldSaver(null));
      highlightElement.stop(() => {
        setHighlightedCardId('');
      });
    }
  }, [dataOnRedirect]);

  const switchToMainAccount = async () => {
    await dispatch(switchAccount(mainAccount.id));
  };

  const openAddNewFamilyMemberProcess = () => {
    dispatch(setModal(MODALS.ADD_FAMILY_ACCOUNT_PROCESS));
  };

  const withContactsToAdd = !!contactsToAddAsFamilyMembers.length;

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <React.Fragment>
      {addFamilyAccountProcess && (
        <AddFamilyAccountProcess
          onClose={() => {
            setAddFamilyAccountProcess(false);
          }}
        />
      )}
      <div>
        <div className="card t-py-6 t-px-7 t-mb-4">
          <div className="t-flex t-flex-col t-items-start sm:t-flex-row sm:t-justify-between">
            <p className="typo-delta t-order-2 sm:t-order-1 t-mt-1 sm:t-mt-0 sm:t-mr-2">
              {getFullName(mainAccount, true).trim() ||
                t('familyAccounts:empty_name_placeholder')}
            </p>
            <div className="t-w-full sm:t-w-auto t-flex t-justify-end t-order-1 sm:t-order-2">
              <div className="t-bg-delta-900 t-py-1 t-px-2 t-rounded-sm t-flex t-gap-x-1">
                <p className="t-text-xs t-text-beta-50 t-mr-2">
                  {t('familyAccounts:main_account_badge')}
                </p>
                <IconInfo
                  role="presentation"
                  data-tip={t('familyAccounts:main_account_card_tooltip')}
                />
              </div>
            </div>
          </div>

          <p className="typo-epsilon t-text-alpha-600 t-mt-1">
            {t('familyAccounts:you')}
          </p>

          {!!subAccounts.length && (
            <div className="t-flex t-justify-end t-mt-3">
              <Button
                className="t-uppercase t-mt-2 sm:t-mt-0"
                onClick={switchToMainAccount}
                disabled={mainAccount.active}
              >
                {t('common:switch')}
              </Button>
            </div>
          )}
        </div>

        <div className="t-flex t-justify-between t-items-center t-mt-10 t-pb-2.5">
          <h2 className="typo-alpha">
            {t('familyAccounts:family_account_overview_title')}
          </h2>

          {!!subAccounts.length && (
            <Button
              category="secondary"
              onClick={openAddNewFamilyMemberProcess}
            >
              <IconPlus
                role="presentation"
                className="t-inline t-text-alpha-600 t-mr-2"
              />
              <span className="xs:t-hidden sm:t-inline">
                {t('common:add_new')}
              </span>
            </Button>
          )}
        </div>

        <p className="t-mb-10">
          {t('familyAccounts:family_account_overview_desc')}
        </p>

        {!subAccounts.length && (
          <div className="t-flex t-items-center t-flex-col t-mb-20">
            <Button
              category="secondary"
              onClick={openAddNewFamilyMemberProcess}
            >
              <IconPlus
                role="presentation"
                className="t-inline t-text-alpha-600 t-mr-2"
              />
              <span>
                {!!withContactsToAdd
                  ? t('familyAccounts:add_existing_contact_as_family_button')
                  : t('familyAccounts:add_new_contact_as_family_button')}
              </span>
            </Button>

            <img
              src={imgNoResult}
              className="t-max-w-28 t-mt-14"
              alt="No family member found!"
            />

            <p className="typo-epsilon t-text-beta-200 t-mt-3">
              {t('familyAccounts:no_family_members_text')}
            </p>
          </div>
        )}

        {subAccounts.map((account) => {
          const isHighlighted = highlightedCardId === account.id;
          return (
            <FamilyAccountCard
              account={account}
              backToMyContactsTab={backToMyContactsTab}
              key={account.id}
              highlight={isHighlighted}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default MyFamilyAccounts;
