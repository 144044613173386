import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';

type FormConditionsProps = {
  pwValidity: PasswordValidity;
};

const FormConditions = ({ pwValidity }: FormConditionsProps) => {
  const { t } = useTranslation(['auth']);

  return (
    <div className="Form-conditions">
      <Row>
        <Col xs="6">
          <ul className="text-main-sm">
            <li
              className={`Form-conditionsItem ${
                pwValidity['oneLowercase'] && 'isChecked'
              }`}
            >
              {t('auth:password_validate_lower')}
            </li>
            <li
              className={`Form-conditionsItem ${
                pwValidity['oneUppercase'] && 'isChecked'
              }`}
            >
              {t('auth:password_validate_upper')}
            </li>
            <li
              className={`Form-conditionsItem ${
                pwValidity['oneNumber'] && 'isChecked'
              }`}
            >
              {t('auth:password_validate_number')}
            </li>
          </ul>
        </Col>

        <Col xs="6">
          <ul className="text-main-sm">
            <li
              className={`Form-conditionsItem ${
                pwValidity['oneSpecial'] && 'isChecked'
              }`}
            >
              {t('auth:password_validate_special')}
            </li>
            <li
              className={`Form-conditionsItem ${
                pwValidity['minLength'] && 'isChecked'
              }`}
            >
              {t('auth:password_validate_min')}
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default FormConditions;
