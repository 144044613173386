import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGES, LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';

import commonEn from 'localization/translations/en/common.json';
import commonDe from 'localization/translations/de/common.json';
import planEn from 'localization/translations/en/estatePlan/plan.json';
import planDe from 'localization/translations/de/estatePlan/plan.json';
import fieldLibraryEn from 'localization/translations/en/estatePlan/fieldLibrary.json';
import fieldLibraryDe from 'localization/translations/de/estatePlan/fieldLibrary.json';
import authEn from 'localization/translations/en/auth.json';
import authDe from 'localization/translations/de/auth.json';
import transmortalAuthEn from 'localization/translations/en/transmortalAuth.json';
import transmortalAuthDe from 'localization/translations/de/transmortalAuth.json';
import profileEn from 'localization/translations/en/profile.json';
import profileDe from 'localization/translations/de/profile.json';
import mcontactsEn from 'localization/translations/en/mcontacts.json';
import mcontactsDe from 'localization/translations/de/mcontacts.json';
import helpEn from 'localization/translations/en/help.json';
import helpDe from 'localization/translations/de/help.json';
import faqEn from 'localization/translations/en/faq.json';
import faqDe from 'localization/translations/de/faq.json';
import termsAndConditionsEn from 'localization/translations/en/termsAndConditions.json';
import termsAndConditionsDe from 'localization/translations/de/termsAndConditions.json';
import dashboardEn from 'localization/translations/en/dashboard.json';
import dashboardDe from 'localization/translations/de/dashboard.json';
import assistantEn from 'localization/translations/en/assistant/assistant.json';
import assistantDe from 'localization/translations/de/assistant/assistant.json';
import recommendationsEn from 'localization/translations/en/assistant/recommendations.json';
import recommendationsDe from 'localization/translations/de/assistant/recommendations.json';
import organDonorCardEn from 'localization/translations/en/assistant/organDonorCard.json';
import organDonorCardDe from 'localization/translations/de/assistant/organDonorCard.json';
import patientDecreeEn from 'localization/translations/en/assistant/patientDecree.json';
import patientDecreeDe from 'localization/translations/de/assistant/patientDecree.json';
import healthCareProxyEn from 'localization/translations/en/assistant/healthCareProxy.json';
import healthCareProxyDe from 'localization/translations/de/assistant/healthCareProxy.json';
import willEn from 'localization/translations/en/assistant/will.json';
import willDe from 'localization/translations/de/assistant/will.json';
import initialAssessmentEn from 'localization/translations/en/assistant/initialAssessment.json';
import initialAssessmentDe from 'localization/translations/de/assistant/initialAssessment.json';
import subtypesEn from 'localization/translations/en/subtypes.json';
import subtypesDe from 'localization/translations/de/subtypes.json';
import assetTypeNamingDe from 'localization/translations/de/assetTypeNaming.json';
import assetTypeNamingEn from 'localization/translations/en/assetTypeNaming.json';
import toastrEn from 'localization/translations/en/toastr.json';
import toastrDe from 'localization/translations/de/toastr.json';
import answersEn from 'localization/translations/en/answers.json';
import answersDe from 'localization/translations/de/answers.json';
import tutorialEn from 'localization/translations/en/tutorial.json';
import tutorialDe from 'localization/translations/de/tutorial.json';
import imprintEn from 'localization/translations/en/imprint.json';
import imprintDe from 'localization/translations/de/imprint.json';
import privacyEn from 'localization/translations/en/privacy.json';
import privacyDe from 'localization/translations/de/privacy.json';
import privacyCustomEn from 'localization/translations/en/privacyCustom.json';
import privacyCustomDe from 'localization/translations/de/privacyCustom.json';
import subscriptionEn from 'localization/translations/en/subscription.json';
import subscriptionDe from 'localization/translations/de/subscription.json';
import passPhraseModalEn from 'localization/translations/en/passPhraseModal.json';
import passPhraseModalDe from 'localization/translations/de/passPhraseModal.json';
import paymentDe from 'localization/translations/de/payment.json';
import paymentEn from 'localization/translations/en/payment.json';
import securityKeyEn from 'localization/translations/en/securityKey.json';
import securityKeyDe from 'localization/translations/de/securityKey.json';
import financialSupplyCareDe from 'localization/translations/de/assistant/contentQuestionnaires/financialSupplyCare.json';
import financialSupplyCareEn from 'localization/translations/en/assistant/contentQuestionnaires/financialSupplyCare.json';
import digitalEstateEn from 'localization/translations/en/assistant/contentQuestionnaires/digitalEstate.json';
import digitalEstateDe from 'localization/translations/de/assistant/contentQuestionnaires/digitalEstate.json';
import financialSupplyAgedEn from 'localization/translations/en/assistant/contentQuestionnaires/financialSupplyAged.json';
import financialSupplyAgedDe from 'localization/translations/de/assistant/contentQuestionnaires/financialSupplyAged.json';
import custodyOrderContentEn from 'localization/translations/en/assistant/contentQuestionnaires/custodyOrder.json';
import custodyOrderContentDe from 'localization/translations/de/assistant/contentQuestionnaires/custodyOrder.json';
import inheritanceTaxEn from 'localization/translations/en/assistant/contentQuestionnaires/inheritanceTax.json';
import inheritanceTaxDe from 'localization/translations/de/assistant/contentQuestionnaires/inheritanceTax.json';
import purchaseLoansRightsEn from 'localization/translations/en/assistant/contentQuestionnaires/purchaseLoansRights.json';
import purchaseLoansRightsDe from 'localization/translations/de/assistant/contentQuestionnaires/purchaseLoansRights.json';
import vehicleDocumentsEn from 'localization/translations/en/assistant/contentQuestionnaires/vehicleDocuments.json';
import vehicleDocumentsDe from 'localization/translations/de/assistant/contentQuestionnaires/vehicleDocuments.json';
import landlordRentalAgreementEn from 'localization/translations/en/assistant/contentQuestionnaires/landlordRentalAgreement.json';
import landlordRentalAgreementDe from 'localization/translations/de/assistant/contentQuestionnaires/landlordRentalAgreement.json';
import emergencyPassEn from 'localization/translations/en/assistant/contentQuestionnaires/emergencyPass.json';
import emergencyPassDe from 'localization/translations/de/assistant/contentQuestionnaires/emergencyPass.json';
import postalPowerOfAttorneyEn from 'localization/translations/en/assistant/contentQuestionnaires/postalPowerOfAttorney.json';
import postalPowerOfAttorneyDe from 'localization/translations/de/assistant/contentQuestionnaires/postalPowerOfAttorney.json';
import occupationalDisabilityCareEn from 'localization/translations/en/assistant/contentQuestionnaires/occupationalDisabilityCare.json';
import occupationalDisabilityCareDe from 'localization/translations/de/assistant/contentQuestionnaires/occupationalDisabilityCare.json';
import careDirectiveEn from 'localization/translations/en/assistant/contentQuestionnaires/careDirective.json';
import careDirectiveDe from 'localization/translations/de/assistant/contentQuestionnaires/careDirective.json';
import careContentEn from 'localization/translations/en/assistant/contentQuestionnaires/care.json';
import careContentDe from 'localization/translations/de/assistant/contentQuestionnaires/care.json';

import referralEn from 'localization/translations/en/referral.json';
import referralDe from 'localization/translations/de/referral.json';
import errorsDe from 'localization/translations/de/errors.json';
import errorsEn from 'localization/translations/en/errors.json';
import beneficiaryEn from 'localization/translations/en/beneficiary.json';
import beneficiaryDe from 'localization/translations/de/beneficiary.json';
import zvrRegistrationEn from 'localization/translations/en/zvrRegistration.json';
import zvrRegistrationDe from 'localization/translations/de/zvrRegistration.json';
import shareAssetsEn from 'localization/translations/en/shareAssets.json';
import shareAssetsDe from 'localization/translations/de/shareAssets.json';
import notificationDe from 'localization/translations/de/notification.json';
import notificationEn from 'localization/translations/en/notification.json';
import organDonorCardContentEn from 'localization/translations/en/assistant/contentQuestionnaires/organDonorCard.json';
import organDonorCardContentDe from 'localization/translations/de/assistant/contentQuestionnaires/organDonorCard.json';
import patientDecreeContentEn from 'localization/translations/en/assistant/contentQuestionnaires/patientDecree.json';
import patientDecreeContentDe from 'localization/translations/de/assistant/contentQuestionnaires/patientDecree.json';
import healthCareProxyContentEn from 'localization/translations/en/assistant/contentQuestionnaires/healthCareProxy.json';
import healthCareProxyContentDe from 'localization/translations/de/assistant/contentQuestionnaires/healthCareProxy.json';
import willContentEn from 'localization/translations/en/assistant/contentQuestionnaires/will.json';
import willContentDe from 'localization/translations/de/assistant/contentQuestionnaires/will.json';
import inheritanceTaxCalEn from 'localization/translations/en/estatePlan/inheritanceTaxCal.json';
import inheritanceTaxCalDe from 'localization/translations/de/estatePlan/inheritanceTaxCal.json';
import onBoardingWizardEn from 'localization/translations/en/onBoardingWizard.json';
import onBoardingWizardDe from 'localization/translations/de/onBoardingWizard.json';

import petWatchingAgreementEn from 'localization/translations/en/assistant/petWatchingAgreement.json';
import petWatchingAgreementDE from 'localization/translations/de/assistant/petWatchingAgreement.json';
import custodyOrderEn from 'localization/translations/en/assistant/custodyOrder.json';
import custodyOrderDE from 'localization/translations/de/assistant/custodyOrder.json';

import funeralProvisionDe from 'localization/translations/de/assistant/funeralProvision.json';
import funeralProvisionEn from 'localization/translations/en/assistant/funeralProvision.json';
import funeralProvisionContentDe from 'localization/translations/de/assistant/contentQuestionnaires/funeralProvision.json';
import funeralProvisionContentEn from 'localization/translations/en/assistant/contentQuestionnaires/funeralProvision.json';

import bankAuthorizationLetterEn from 'localization/translations/en/assistant/bankAuthorizationLetter.json';
import bankAuthorizationLetterDe from 'localization/translations/de/assistant/bankAuthorizationLetter.json';
import bankAuthorizationLetterContentEn from 'localization/translations/en/assistant/contentQuestionnaires/bankAuthorizationLetter.json';
import bankAuthorizationLetterContentDe from 'localization/translations/de/assistant/contentQuestionnaires/bankAuthorizationLetter.json';

import agentsEn from 'localization/translations/en/agents.json';
import agentsDe from 'localization/translations/de/agents.json';

import afterDeathEn from 'localization/translations/en/afterDeath.json';
import afterDeathDe from 'localization/translations/de/afterDeath.json';

import successMessagesEn from 'localization/translations/en/successMessages.json';
import successMessagesDe from 'localization/translations/de/successMessages.json';

import familyAccountsEn from 'localization/translations/en/familyAccounts.json';
import familyAccountsDe from 'localization/translations/de/familyAccounts.json';
import releasesEn from 'localization/translations/en/releases.json';
import releasesDe from 'localization/translations/de/releases.json';

const lng =
  localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE) || LANGUAGES.DE;
const fallbackLng = lng === LANGUAGES.EN ? LANGUAGES.DE : LANGUAGES.EN;

i18n.use(initReactI18next).init({
  lng,
  fallbackLng,
  debug: false,
  keySeparator: ' ',
  resources: {
    en: {
      common: commonEn,
      plan: planEn,
      auth: authEn,
      transmortalAuth: transmortalAuthEn,
      profile: profileEn,
      mcontacts: mcontactsEn,
      help: helpEn,
      faq: faqEn,
      termsAndConditions: termsAndConditionsEn,
      dashboard: dashboardEn,
      assistant: assistantEn,
      recommendations: recommendationsEn,
      patientDecree: patientDecreeEn,
      organDonorCard: organDonorCardEn,
      healthCareProxy: healthCareProxyEn,
      will: willEn,
      initialAssessment: initialAssessmentEn,
      subtypes: subtypesEn,
      assetTypeNaming: assetTypeNamingEn,
      toastr: toastrEn,
      answers: answersEn,
      tutorial: tutorialEn,
      imprint: imprintEn,
      privacy: privacyEn,
      privacyCustom: privacyCustomEn,
      subscription: subscriptionEn,
      passPhraseModal: passPhraseModalEn,
      securityKey: securityKeyEn,
      fieldLibrary: fieldLibraryEn,
      financialSupplyCare: financialSupplyCareEn,
      financialSupplyAged: financialSupplyAgedEn,
      custodyOrderContent: custodyOrderContentEn,
      inheritanceTax: inheritanceTaxEn,
      digitalEstate: digitalEstateEn,
      purchaseLoansRights: purchaseLoansRightsEn,
      vehicleDocuments: vehicleDocumentsEn,
      landlordRentalAgreement: landlordRentalAgreementEn,
      bankAuthorizationLetter: bankAuthorizationLetterEn,
      bankAuthorizationLetterContent: bankAuthorizationLetterContentEn,
      payment: paymentEn,
      referral: referralEn,
      errors: errorsEn,
      beneficiary: beneficiaryEn,
      emergencyPass: emergencyPassEn,
      postalPowerOfAttorney: postalPowerOfAttorneyEn,
      occupationalDisabilityCare: occupationalDisabilityCareEn,
      careDirective: careDirectiveEn,
      careContent: careContentEn,
      zvrRegistration: zvrRegistrationEn,
      shareAssets: shareAssetsEn,
      notification: notificationEn,
      patientDecreeContent: patientDecreeContentEn,
      organDonorCardContent: organDonorCardContentEn,
      healthCareProxyContent: healthCareProxyContentEn,
      willContent: willContentEn,
      inheritanceTaxCal: inheritanceTaxCalEn,
      onBoardingWizard: onBoardingWizardEn,
      petWatchingAgreement: petWatchingAgreementEn,
      custodyOrder: custodyOrderEn,
      funeralProvision: funeralProvisionEn,
      funeralProvisionContent: funeralProvisionContentEn,
      agents: agentsEn,
      afterDeath: afterDeathEn,
      successMessages: successMessagesEn,
      familyAccounts: familyAccountsEn,
      releases: releasesEn,
    },
    de: {
      common: commonDe,
      plan: planDe,
      auth: authDe,
      transmortalAuth: transmortalAuthDe,
      profile: profileDe,
      mcontacts: mcontactsDe,
      help: helpDe,
      faq: faqDe,
      termsAndConditions: termsAndConditionsDe,
      dashboard: dashboardDe,
      assistant: assistantDe,
      recommendations: recommendationsDe,
      patientDecree: patientDecreeDe,
      organDonorCard: organDonorCardDe,
      healthCareProxy: healthCareProxyDe,
      will: willDe,
      initialAssessment: initialAssessmentDe,
      subtypes: subtypesDe,
      assetTypeNaming: assetTypeNamingDe,
      toastr: toastrDe,
      answers: answersDe,
      tutorial: tutorialDe,
      imprint: imprintDe,
      privacy: privacyDe,
      privacyCustom: privacyCustomDe,
      subscription: subscriptionDe,
      passPhraseModal: passPhraseModalDe,
      securityKey: securityKeyDe,
      fieldLibrary: fieldLibraryDe,
      financialSupplyCare: financialSupplyCareDe,
      financialSupplyAged: financialSupplyAgedDe,
      custodyOrderContent: custodyOrderContentDe,
      inheritanceTax: inheritanceTaxDe,
      digitalEstate: digitalEstateDe,
      purchaseLoansRights: purchaseLoansRightsDe,
      vehicleDocuments: vehicleDocumentsDe,
      landlordRentalAgreement: landlordRentalAgreementDe,
      bankAuthorizationLetter: bankAuthorizationLetterDe,
      bankAuthorizationLetterContent: bankAuthorizationLetterContentDe,
      payment: paymentDe,
      referral: referralDe,
      errors: errorsDe,
      beneficiary: beneficiaryDe,
      emergencyPass: emergencyPassDe,
      postalPowerOfAttorney: postalPowerOfAttorneyDe,
      occupationalDisabilityCare: occupationalDisabilityCareDe,
      careDirective: careDirectiveDe,
      careContent: careContentDe,
      zvrRegistration: zvrRegistrationDe,
      shareAssets: shareAssetsDe,
      notification: notificationDe,
      patientDecreeContent: patientDecreeContentDe,
      organDonorCardContent: organDonorCardContentDe,
      healthCareProxyContent: healthCareProxyContentDe,
      willContent: willContentDe,
      inheritanceTaxCal: inheritanceTaxCalDe,
      onBoardingWizard: onBoardingWizardDe,
      petWatchingAgreement: petWatchingAgreementDE,
      custodyOrder: custodyOrderDE,
      funeralProvision: funeralProvisionDe,
      funeralProvisionContent: funeralProvisionContentDe,
      agents: agentsDe,
      afterDeath: afterDeathDe,
      successMessages: successMessagesDe,
      familyAccounts: familyAccountsDe,
      releases: releasesDe,
    },
  },
  defaultNS: 'common',
});

export default i18n;
