import React from 'react';
import { values } from 'lodash';

import { useTranslation } from 'modules/common/helpers';
import { CommonFormInputProps } from 'modules/common/types';

import { SelectController, TextInputRef } from 'modules/common/components';

import {
  HEALTH_INSURANCE,
  OTHER_HEALTH_INSURANCE,
  PERSONAL_DOCUMENTS,
  LONG_TERM_ASSETS,
  ONGOING_CONTRACTS,
  INSURANCES,
  PASSWORD,
  BANK_ACCOUNT,
  POWER_OF_ATTORNEY,
  FORWARDING_REASON,
} from 'modules/estatePlan/constants/subtypes';
import {
  HEALTH_SUBTYPES,
  LEGAL_SUBTYPES,
  FINANCIAL_SUBTYPES,
  DIGITAL_SUBTYPES,
  AFTERLIFE_SUBTYPES,
  INSURANCES_SUBTYPES,
} from 'modules/estatePlan/constants/enums';
import {
  ADVISOR_TYPES,
  ATTORNEY_TYPES,
  CREDIT_CARD_PROVIDERS,
  LIST_OF_CONTACTS,
  SUBTYPE_OTHER,
} from 'modules/common/constants/subtypes';

export interface SubtypeSelectProps extends CommonFormInputProps {
  error: string;
  label: string;
  field: AssetFieldDTO;
  asset: AssetDTO;
  assetCategory: string;
  assetType: AssetTypeDTO;
  preselectedSubtype: string;
}

export default function SubtypeSelect({
  error,
  label,
  control,
  field,
  asset,
  assetCategory,
  assetType,
  watch,
  register,
  setValue,
  preselectedSubtype = '',
}: SubtypeSelectProps) {
  const { t } = useTranslation(['common', 'subtypes']);

  const getSubtypeOptions = (fieldSubtype) => {
    const formatOptions = (subtype) =>
      values(subtype).map((item) => ({
        value: item,
        label: t(`subtypes:${item}`),
      }));
    switch (fieldSubtype) {
      case HEALTH_SUBTYPES.HEALTH_INSURANCE:
        return formatOptions(HEALTH_INSURANCE);
      case HEALTH_SUBTYPES.OTHER_HEALTH_INSURANCE:
        return formatOptions(OTHER_HEALTH_INSURANCE);
      case INSURANCES_SUBTYPES.OTHER_INSURANCES:
        return formatOptions(INSURANCES);
      case LEGAL_SUBTYPES.PERSONAL_DOCUMENTS:
        return formatOptions(PERSONAL_DOCUMENTS);
      case LEGAL_SUBTYPES.POWER_OF_ATTORNEY:
        return formatOptions(POWER_OF_ATTORNEY);
      case LEGAL_SUBTYPES.FORWARDING_REASON:
        return formatOptions(FORWARDING_REASON);
      case FINANCIAL_SUBTYPES.LONG_TERM_ASSETS:
        return formatOptions(LONG_TERM_ASSETS);
      case FINANCIAL_SUBTYPES.ONGOING_CONTRACTS:
        return formatOptions(ONGOING_CONTRACTS);
      case DIGITAL_SUBTYPES.PASSWORD:
        return formatOptions(PASSWORD);
      case FINANCIAL_SUBTYPES.BANK:
        return formatOptions(BANK_ACCOUNT);
      case AFTERLIFE_SUBTYPES.LIST_OF_CONTACTS:
        return formatOptions(LIST_OF_CONTACTS);
      case FINANCIAL_SUBTYPES.CREDIT_CARD:
        return formatOptions(CREDIT_CARD_PROVIDERS);
      case FINANCIAL_SUBTYPES.FINANCIAL_ADVISOR:
        return formatOptions(ADVISOR_TYPES);
      case LEGAL_SUBTYPES.ATTORNEY:
        return formatOptions(ATTORNEY_TYPES);
      default:
        return null;
    }
  };

  const getDefaultOption = (
    options: SelectOption[] | null
  ): SelectOption | undefined =>
    options?.find((item) => {
      if (asset?.subtype) {
        return item.value === asset?.subtype;
      } else if (preselectedSubtype) {
        return item.value === preselectedSubtype;
      }
      return false;
    });

  const subtypeOptions = getSubtypeOptions(field.subtype);
  const defaultSubtypeOption = getDefaultOption(subtypeOptions) || '';
  const [defaultDisplayedOption, setDefaultDisplayedOption] = React.useState<
    string | SelectOption | undefined
  >(defaultSubtypeOption);

  const chosenLabel = assetType.custom
    ? t(`subtypes:${assetCategory}_${assetType.name}_label`)
    : label;
  const [otherSubtypeDefaultValue, setOtherSubtypeDefaultValue] =
    React.useState('');
  const [isOtherSubtype, setIsOtherSubtype] = React.useState(false);

  React.useEffect(() => {
    const isDefaultValueTakenFromOtherSubtypes =
      asset.subtype && !defaultSubtypeOption;

    if (isDefaultValueTakenFromOtherSubtypes) {
      setOtherSubtypeDefaultValue(asset.subtype || '');
      const defaultOtherOption = subtypeOptions?.find((item) =>
        [SUBTYPE_OTHER.OTHERS, SUBTYPE_OTHER.OTHER].includes(item.value)
      );
      setIsOtherSubtype(true);
      setValue('subtype', defaultOtherOption);
      setValue('otherSubtype', asset.subtype);
    } else {
      setIsOtherSubtype(false);
      setDefaultDisplayedOption(defaultSubtypeOption);
    }
  }, [asset?.subtype]);

  React.useEffect(() => {
    setIsOtherSubtype(
      [SUBTYPE_OTHER.OTHERS, SUBTYPE_OTHER.OTHER].includes(
        watch('subtype')?.value
      )
    );
  }, [watch('subtype')?.value]);

  return (
    <>
      <div className="t-mb-3.5">
        <label
          htmlFor="subtype"
          className={`t-inline-block t-mb-2 t-text-beta-500 ${
            error ? 't-text-epsilon-600' : ''
          }`}
        >
          {chosenLabel}
        </label>
        <SelectController
          id="subtype"
          name="subtype"
          options={subtypeOptions || ([] as SelectOption[])}
          control={control}
          defaultValue={defaultDisplayedOption as SelectOption}
          className={`Select ${error ? 'isErrored' : ''}`}
          rules={{ required: field.required }}
        />
        {error && (
          <div className="t-pt-0.5">
            <span className="text-main-sm t-text-epsilon-600">
              {`${t(`subtypes:${assetCategory}_${assetType.name}_label`)} ${t(
                'common:required'
              )}`}
            </span>
          </div>
        )}
      </div>

      {isOtherSubtype && (
        <div className="Form-group">
          <TextInputRef
            {...register('otherSubtype', { required: true })}
            label={t('plan:subtype_other_label')}
            type="text"
            autoComplete={false}
            error={error}
            defaultValue={otherSubtypeDefaultValue as string}
          />
        </div>
      )}
    </>
  );
}
