import React from 'react';

import { ReactComponent as SandwichIcon } from 'images/sandwich.svg';
import { ReactComponent as CrossIcon } from 'images/cross.svg';

interface MobileMenuProps {
  toggleMenu: () => void;
  showMobileMenu: boolean;
}

export default function MobileMenu({
  toggleMenu,
  showMobileMenu,
}: MobileMenuProps) {
  return (
    <div className="SiteNavigation-mobileInfo">
      <button
        type="button"
        className="SiteNavigation-mobileToggler t-outline-none"
        onClick={toggleMenu}
      >
        {showMobileMenu ? (
          <CrossIcon className="t-text-alpha-700" />
        ) : (
          <SandwichIcon className="t-text-alpha-700" />
        )}
      </button>
    </div>
  );
}
