import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { getFullName, useTranslation } from 'modules/common/helpers';
import {
  getAliveContactsData,
  getSelectOptions,
} from 'modules/contact/helpers';

import { RootState } from 'store/reducers';
import { Button, Modal } from 'modules/common/components';
import { StyledOr } from 'modules/elements/components';
import { QuestionnaireContactForm } from 'modules/assistant/components/Questionnaire';
import { ContactCard } from 'modules/myContacts/components';

import iconPlus from 'images/icon-plus.svg';

export interface QuestionnaireContactsProps {
  previousAnswer?: ContactDTO[];
  isSingleSelection?: boolean;
  addNewButtonText?: string;
  birthPlaceRequired?: boolean;
  emailRequired?: boolean;
  phoneRequired?: boolean;
}

const QuestionnaireContacts = ({
  previousAnswer,
  isSingleSelection,
  addNewButtonText,
  birthPlaceRequired,
  emailRequired,
  phoneRequired,
}: QuestionnaireContactsProps) => {
  const { t } = useTranslation(['common', 'assistant']);
  const dispatch = useDispatch();

  const formattedPreviousAnswer =
    isSingleSelection && !!previousAnswer
      ? ([previousAnswer] as Record<string, any>[] as ContactDTO[])
      : previousAnswer;

  const [showForm, setShowForm] = useState(false);
  const [data, setData] = useState<ContactDTO[]>(formattedPreviousAnswer || []);
  const [dataToEdit, setDataToEdit] = useState<ContactDTO>({} as ContactDTO);
  const [addedFromContacts, setAddedFromContacts] = useState(false);

  const contactsData: ContactDTO[] =
    useSelector((state: RootState) => state.contacts.list) || [];
  const familyAccounts = useSelector(
    (state: RootState) => state.familyAccounts.list
  );

  const availableContacts = getAliveContactsData(contactsData, familyAccounts);

  // filter all the contacts that have been used for creating questionnaire contacts
  const filteredContacts = availableContacts.filter(
    (contact) =>
      !data.find(
        (questionnaireContact) => questionnaireContact.id === contact.id
      )
  );

  // show previous input val, if entered before
  useEffect(() => {
    if (formattedPreviousAnswer) {
      dispatch(temporaryFieldSaver(previousAnswer));
    }
  }, [formattedPreviousAnswer]);

  const shouldHideOnSingleSelection = !!isSingleSelection && !!data.length;

  useEffect(() => {
    dispatch(temporaryFieldSaver(shouldHideOnSingleSelection ? data[0] : data));
  }, [data]);

  const handleContactFormSubmit = async (
    isContact: boolean,
    submitData: ContactDTO
  ) => {
    // if the user creates the questionnaire contact from an empty form or contact data taken from contacts
    if (isContact || addedFromContacts) {
      setData([...data, submitData]);
    } else {
      setData((prev) => {
        const copiedState = [...prev];

        const idx = copiedState.findIndex((item) => item.id === submitData.id);
        copiedState[idx] = submitData;
        return copiedState;
      });
    }
    setAddedFromContacts(false);
    handleFormModalClose();
  };

  const onEditContact = (contact) => {
    setDataToEdit(contact);
    setShowForm(true);
  };

  const handleFormModalClose = () => {
    setShowForm(false);
    setDataToEdit({} as ContactDTO);
    setAddedFromContacts(false);
  };

  const onDeleteContact = (contact) => {
    const copiedData = [...data];
    const idx = copiedData.findIndex((item) => item.id === contact.id);
    copiedData.splice(idx, 1);
    setData(copiedData);
  };

  const handleSelectChange = (selectedOption): void => {
    const option = selectedOption as SelectOption;

    const selectedContact = (filteredContacts.find(
      (contact) => contact.id === option.value
    ) || {}) as ContactDTO;
    // set the selected contact to add as a questionnaire contact form
    setAddedFromContacts(true);
    setDataToEdit(selectedContact);
    setShowForm(true);
  };

  const contactsOptions = getSelectOptions(filteredContacts);
  const selectedOption = addedFromContacts
    ? contactsOptions.find((option) => option.value === dataToEdit.id)
    : undefined;

  return (
    <>
      {showForm && (
        <Modal show onHide={handleFormModalClose}>
          <QuestionnaireContactForm
            onCancel={handleFormModalClose}
            onSuccess={handleContactFormSubmit}
            contactItem={dataToEdit}
            birthPlaceRequired={birthPlaceRequired}
            emailRequired={emailRequired}
            phoneRequired={phoneRequired}
          />
        </Modal>
      )}

      <div
        className={
          !shouldHideOnSingleSelection
            ? 'card xs:t-w-full md:t-w-4/5 xs:t-p-4 md:t-p-7'
            : ''
        }
        data-testid="QuestionnaireContacts"
      >
        {!shouldHideOnSingleSelection && (
          <div className="Form-group">
            <label className="Form-label" htmlFor="contact">
              {t('common:from_existing_contact')}
            </label>

            <Select
              id="contact"
              name="contact"
              value={selectedOption}
              onChange={handleSelectChange}
              options={contactsOptions}
              className="Select"
              classNamePrefix="Select"
              placeholder={t('common:default_select_placeholder')}
            />
          </div>
        )}
        <div className="d-flex flex-column align-items-center">
          {!shouldHideOnSingleSelection && (
            <>
              <StyledOr>{t('common:or')}</StyledOr>

              <Button category="secondary" onClick={() => setShowForm(true)}>
                <img
                  src={iconPlus}
                  className="button-icon-amazon"
                  alt={addNewButtonText || t('common:add_new_contact')}
                  role="presentation"
                />
                {addNewButtonText || t('common:add_new_contact')}
              </Button>

              <div className="u-pb-15" />
            </>
          )}

          <div>
            {data?.map((item) => (
              <ContactCard
                key={getFullName(item, false)}
                contactData={item}
                onDelete={onDeleteContact}
                onEdit={onEditContact}
                className="card xl:t-py-6 xl:t-px-7 xs:t-py-3 xs:t-px-3.5 xl:t-max-w-sm"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireContacts;
