import { config, httpHelper } from 'modules/common/helpers';

const BASE_URL = config.beUrl;

export default {
  addQuestionnaireReminder,
  addAssetReminder,
};

function addQuestionnaireReminder({ date, id }) {
  return httpHelper.post(`${BASE_URL}/api/remind/answer`, { date, id });
}

function addAssetReminder({ date, id, type }) {
  return httpHelper.post(`${BASE_URL}/api/remind/asset`, {
    date,
    id,
    type,
  });
}
