import React from 'react';

import {
  config,
  dateHelper,
  getFullName,
  useGetCountries,
  useGetCountryOptionByCode,
  useTranslation,
} from 'modules/common/helpers';
import { Button } from 'modules/common/components';

import { ReactComponent as LocationIcon } from 'images/location-icon.svg';
import emptyProfileImg from 'images/empty-profile.png';

export interface ProfileCardProps {
  userData: UserProfileDTO;
  handleShowInfo: () => void;
}

function ProfileCard({ userData, handleShowInfo }: ProfileCardProps) {
  const { t } = useTranslation(['auth', 'common', 'profile']);
  const countries = useGetCountries();
  const country = useGetCountryOptionByCode(userData.country, countries);

  const fullName = getFullName(userData);

  const isEmptyProfile = !(
    userData.zip ||
    userData.city ||
    country.label ||
    userData.name ||
    userData.surname ||
    userData.placeOfBirth ||
    userData.gender
  );

  const hasLocation = !!userData.zip && !!userData.city && !!country.label;

  return (
    <div className="t-w-full" data-testid="profile-card">
      <div className="t-flex t-justify-between t-items-start">
        <p className="typo-delta t-mt-1.5">{fullName}</p>
        <Button
          category="tertiary"
          className="t-ml-3 t-whitespace-nowrap"
          onClick={handleShowInfo}
          testId="profile-card-edit-profile-button"
        >
          {t('common:edit')}
        </Button>
      </div>

      <p className="typo-epsilon t-mt-0.5">
        {dateHelper.convertDateFormat(
          userData.birthday || '',
          config.format.uiDate
        )}{' '}
        {userData.placeOfBirth && <span> {userData.placeOfBirth}</span>}
      </p>

      {isEmptyProfile && (
        <div className="t-flex t-items-center t-mr-5 lg:t-mr-10">
          <img src={emptyProfileImg} alt="empty profile" className="t-w-24" />
          <p className="typo-epsilon t-ml-3 sm:t-ml-5 t-text-beta-500">
            {t('profile:no_profile_info_message')}
          </p>
        </div>
      )}

      <div className="t-flex t-mt-6">
        {hasLocation && <LocationIcon className="t-mr-2 t-text-beta-500" />}
        <div>
          {userData.address && (
            <p className="typo-epsilon">{userData.address}</p>
          )}
          <p className="typo-epsilon">{`${userData.zip || ''} ${
            userData.city || ''
          }${!!userData.zip || !!userData.city ? ',' : ''} ${
            country.label || ''
          }`}</p>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
