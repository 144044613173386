import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { RequestAdviceFeedbackModal } from 'modules/assistant/components/Answers/RequestAdvice';
import { Carousel, Modal } from 'modules/common/components';
import { AgentSuggestion, AgentContactModal } from 'modules/agent/components';
import { ContactUsForm } from 'modules/contactUs/components';

import { CONTENT_QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/contentQuestionnaires';

import { ReactComponent as IconInsurance } from 'images/insurance.svg';
import { ReactComponent as IconConsultation } from 'images/consultation.svg';
import { ReactComponent as IconExternalLink } from 'images/external-link.svg';
import { ReactComponent as IconNotary } from 'images/icon_legal_document.svg';

interface SuggestionsProps {
  contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS;
}

const Suggestions = ({ contentQuestionnaire }: SuggestionsProps) => {
  const { t } = useTranslation(['assistant', 'common']);
  const [requestModalSubject, setRequestModalSubject] = useState('');
  const [agentModalOpen, setAgentModalOpen] = useState(false);
  const [contactUsModalOpen, setContactUsModalOpen] = useState(false);

  const handleRequestClick = (subject) => {
    if (subject === 'requestAdvice') {
      setContactUsModalOpen(true);
    } else if (subject === 'notarySearch') {
      window.open('https://www.notar.de/notarsuche/notarsuche', '_blank');
    } else {
      setRequestModalSubject(subject);
    }
  };

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const isUserWithAgentRelation = !isEmpty(currentUser.agent);

  const requestAdviceItems = [
    {
      name: 'notarySearch',
      icon: <IconNotary className="t-w-20 t-h-16 t-hidden xl:t-block t-ml-4" />,
      visible: contentQuestionnaire === CONTENT_QUESTIONNAIRE_KEYS.WILL,
    },
    {
      name: 'requestAdvice',
      icon: <IconConsultation className="t-w-1/3 t-hidden xl:t-block" />,
      visible: true,
    },
    {
      name: 'careInsurance',
      icon: <IconInsurance className="t-w-1/3 t-hidden xl:t-block" />,
      visible: true,
      skipIfAgent: true,
    },
  ];

  const shownSuggestions = requestAdviceItems.filter((suggestion) =>
    isUserWithAgentRelation ? !suggestion.skipIfAgent : !!suggestion.visible
  );

  const handleAgentSuggestionOpen = () => {
    setAgentModalOpen(true);
  };

  const handleAgentSuggestionClose = () => {
    setAgentModalOpen(false);
  };

  const handleContactUsModalClose = () => {
    setContactUsModalOpen(false);
  };

  const carouselBreakPoints = [
    {
      width: 1,
      itemsToShow: 1,
      itemsToScroll: 1,
      initialActiveIndex: 0,
      pagination: false,
    },
    {
      width: 768,
      itemsToShow: 1.5,
      itemsToScroll: 1,
      initialActiveIndex: 0,
      pagination: false,
    },
  ];

  return (
    <>
      {!!requestModalSubject && (
        <RequestAdviceFeedbackModal
          onCancel={() => setRequestModalSubject('')}
          subject={requestModalSubject}
        />
      )}

      {agentModalOpen && (
        <AgentContactModal onClose={handleAgentSuggestionClose} />
      )}

      {contactUsModalOpen && (
        <Modal show onHide={handleContactUsModalClose}>
          <h1 className="typo-alpha t-pb-6">{t('help:title')}</h1>
          <ContactUsForm onSuccess={handleContactUsModalClose} />
        </Modal>
      )}

      <div className="t-w-full t-rounded t-bg-beta-100 t-py-6 t-mt-11">
        <p className="t-font-secondary t-font-semibold t-text-lg t-pl-6">
          {t('assistant:more_suggestions')}
        </p>
        <Carousel breakPoints={carouselBreakPoints}>
          {isUserWithAgentRelation && (
            <AgentSuggestion onClick={handleAgentSuggestionOpen} />
          )}
          {shownSuggestions.map((suggestion) => (
            <button
              type="button"
              onClick={() => handleRequestClick(suggestion.name)}
              className="card t-bg-gamma-400 t-min-h-44 t-flex t-p-5 t-mb-4 t-mt-8 md:t-mx-3 t-w-full"
              key={suggestion.name}
            >
              {suggestion.icon}
              <div className="t-ml-6 t-flex-grow">
                <p className="t-mb-3 t-text-left typo-beta">
                  {t(`assistant:${suggestion.name}`)}
                </p>
                <p className="t-mb-3 t-text-left t-whitespace-normal">
                  {t(`assistant:${suggestion.name}_content`)}
                </p>
              </div>
              <IconExternalLink className="t-text-delta-700 t-ml-auto t-w-20" />
            </button>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default Suggestions;
