import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'modules/common/helpers';

import { Modal } from 'modules/common/components';
import { ContactForm } from 'modules/contact/components';

ContactModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

function ContactModal({ handleClose }) {
  const { t } = useTranslation(['dashboard']);

  return (
    <Modal show onHide={handleClose} hasCloseCross>
      <h1 className="Typography Typography--title u-weight-600 u-pb-40">
        {t('dashboard:contact_title')}
      </h1>

      <ContactForm onClose={handleClose} limited />
    </Modal>
  );
}

export default ContactModal;
