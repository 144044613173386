import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { PersonalInfoForm } from 'modules/zvr/components';
import { Button, Modal } from 'modules/common/components';

import { ZVR_REGISTRATION_STEPS } from 'modules/zvr/constants';

interface ProfileUpdateModalProps {
  handleNextStep: (step: number) => void;
}

export default function ProfileUpdateModal({
  handleNextStep,
}: ProfileUpdateModalProps) {
  const { t } = useTranslation(['zvrRegistration']);

  const [isSubmitForm, setIsSubmitForm] = React.useState(false);
  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const handleSubmitSuccess = () => {
    setIsSubmitForm(false);
    handleNextStep(ZVR_REGISTRATION_STEPS.STEP_TRUSTED_PERSON);
  };

  const handleSubmitFormFalse = () => {
    setIsSubmitForm(false);
  };

  const onClose = () => {
    handleNextStep(ZVR_REGISTRATION_STEPS.STEP_REGISTRATION_CANCEL);
  };

  return (
    <Modal show onHide={onClose}>
      <h1 className="typo-alpha">
        {t('zvrRegistration:complete_your_profile')}
      </h1>
      <PersonalInfoForm
        personalObject={user}
        isTrustedPerson={false}
        isSubmitForm={isSubmitForm}
        handleSubmitSuccess={handleSubmitSuccess}
        submitFormFalse={handleSubmitFormFalse}
      />

      <div className="t-flex t-justify-end t-items-end t-mt-4 t-flex-col sm:t-flex-row t-pb-16 sm:t-pb-0">
        <div>
          <Button
            category="secondary"
            className="t-mr-0 sm:t-mr-3 t-py-2 t-mb-3 sm:t-mb-0"
            disabled={isSubmitForm}
            onClick={onClose}
          >
            {t('zvrRegistration:cancel')}
          </Button>
        </div>

        <div>
          <Button
            className="t-py-2"
            onClick={() => setIsSubmitForm(true)}
            loading={isSubmitForm}
          >
            {t('zvrRegistration:save_continue')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
