import { FIELD_TYPE_KEYS as F } from 'modules/estatePlan/constants';
import { ASSET_TYPES_DOC_GEN } from 'modules/estatePlan/constants/assetTypeWithDocGen';

export const getTypeLabel = (type, assetType: AssetTypeDTO) => {
  const structure = assetType?.structure;
  return structure?.find((s) => s.type === type)?.label;
};

export const getAssetProviderValue = (providerKey, providers) => {
  return (
    providers.find((provider) => provider.key === providerKey)?.value ||
    providerKey
  );
};

export const getDisplayedName = (asset: AssetDTO, assetType, providers) => {
  const firstRequiredField = assetType?.structure?.find(
    (type) => type.required
  );
  if (asset?.subtype) {
    return {
      type: F.SUBTYPE,
      value: `subtypes:${asset.subtype}`,
      label: `subtypes:${asset.subtype}`,
    };
  }

  if (asset?.provider) {
    return {
      type: F.PROVIDER,
      value: getAssetProviderValue(asset?.provider, providers),
      label: getTypeLabel(F.PROVIDER, assetType),
    };
  }

  return {
    type: firstRequiredField?.type,
    value: asset[firstRequiredField?.type],
    label: getTypeLabel(firstRequiredField?.type, assetType),
  };
};

export const getDisplayedOption1 = (
  asset: AssetDTO,
  assetType,
  hasDocumentGenerator,
  displayedNameObj,
  providers
) => {
  const copiedAsset = { ...asset };

  if (hasDocumentGenerator) {
    return getDisplayedName(asset, assetType, providers);
  }

  copiedAsset[displayedNameObj.type] = undefined;

  if ([F.PROVIDER, F.SUBTYPE].includes(displayedNameObj.type)) {
    const option1 = getDisplayedName(copiedAsset, assetType, providers);
    if (option1.value) return option1;
  }

  Object.keys(copiedAsset).forEach((field) => {
    if (!copiedAsset[field]) delete copiedAsset[field];
  });

  const assetTypeStructureOrder = [...assetType.structure].map(
    (item) => item.type
  );

  for (const type of assetTypeStructureOrder) {
    if (copiedAsset[type]) {
      return {
        type: type,
        value: ([F.PASSWORD, F.IBAN, F.PUK] as string[]).includes(type)
          ? '*'.repeat(copiedAsset[type].length)
          : copiedAsset[type],
        label: getTypeLabel(type, assetType),
      };
    }
  }

  return;
};

export const getDisplayedOption2 = (
  asset: AssetDTO,
  assetType,
  hasDocumentGenerator,
  displayedNameObj,
  displayedOption1,
  providers
) => {
  const copiedAsset = { ...asset };
  if (!displayedOption1) return;

  if (hasDocumentGenerator) {
    return getDisplayedOption1(
      asset,
      assetType,
      false,
      displayedNameObj,
      providers
    );
  }

  copiedAsset[displayedOption1.type] = undefined;
  const option2 = getDisplayedOption1(
    copiedAsset,
    assetType,
    false,
    displayedNameObj,
    providers
  );
  if (option2?.type !== displayedOption1.type) return option2;
  return;
};

export const getAssetName = (
  assetType: AssetTypeDTO,
  asset: AssetDTO,
  providers: ProviderDTO[],
  t: (key: string) => string
) => {
  const displayedNameObj = getDisplayedName(asset, assetType, providers);
  const hasDocumentGenerator = Object.values(ASSET_TYPES_DOC_GEN).includes(
    assetType?.name
  );
  return (hasDocumentGenerator || asset?.generated) && asset.name
    ? asset.name
    : t(`${displayedNameObj.value}`);
};
