import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { getContactsList } from 'store/actions/contactActions';
import {
  createFamilyMember,
  getFamilyAccounts,
} from 'store/actions/familyAccountActions';
import { getFullName, dateHelper, config } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';
import { FamilyAccountDisclaimerModal } from 'modules/familyAccount/components';
import { ContactForm } from 'modules/contact/components';

import { RELATIONSHIP } from 'modules/common/constants/enums';

import imgTick from 'images/patient-decree/tick.svg';
import { ReactComponent as IconEmpty } from 'images/icon_empty.svg';
import { ReactComponent as IconPlus } from 'images/icon-plus.svg';

interface AddExistingContactAsFamilyMembersModalProps {
  familyAccounts: FamilyAccount[];
  relations: SelectOption[];
  onClose: (successful?: boolean) => void;
}

const AddExistingContactAsFamilyMembersModal = ({
  familyAccounts,
  relations,
  onClose,
}: AddExistingContactAsFamilyMembersModalProps) => {
  const { t } = useTranslation(['common', 'familyAccounts']);
  const dispatch = useDispatch();
  const contactsData =
    useSelector((state: RootState) => state.contacts.list) || [];

  const familyAccountIds = familyAccounts.map((account) => account.id);
  const contactsToAddAsFamilyMembers = contactsData.filter(
    (contact) => !familyAccountIds.includes(contact.secondaryUserId)
  );

  const [selectData, setSelectData] = React.useState(
    {} as { [id: string]: boolean }
  );
  const [loading, setLoading] = React.useState(false);
  const [newContactModalOpen, setNewContactModalOpen] = React.useState(false);
  const [disclaimerModal, setDisclaimerModal] = React.useState(false);

  React.useEffect(() => {
    if (contactsToAddAsFamilyMembers.length) {
      const initialSelectData = {};
      contactsToAddAsFamilyMembers.forEach((contact) => {
        initialSelectData[contact.id] = false;
      });
      setSelectData(initialSelectData);
    }
  }, [contactsToAddAsFamilyMembers.length]);

  const onSelectContact = (id: string) => {
    setSelectData((prevState) => {
      return { ...prevState, [id]: !prevState[id] };
    });
  };

  const handleAddContactsAsFamilyMembers = async () => {
    setLoading(true);
    const contactIds = contactsToAddAsFamilyMembers.map(
      (contact) => contact.id
    );
    const selectedContactIds = contactIds.filter((id) => !!selectData[id]);
    selectedContactIds;
    await Promise.all(
      selectedContactIds.map((id) => dispatch(createFamilyMember(id)))
    );
    await dispatch(getFamilyAccounts());
    await dispatch(getContactsList());
    setLoading(false);
    setDisclaimerModal(false);
    onClose(true);
  };

  const handleContinue = () => {
    if (!!isContactsAvailable && !isAllUnderage) {
      setDisclaimerModal(true);
    } else {
      handleOpenContactFormModal();
    }
  };

  const handleOpenContactFormModal = () => {
    setNewContactModalOpen(true);
  };

  const handleCloseContactFormModal = () => {
    setNewContactModalOpen(false);
  };

  const onCancelProcess = () => {
    onClose();
  };

  const contactsSelected = !!Object.values(selectData).find((value) => !!value);

  const isAllUnderage = !contactsToAddAsFamilyMembers.find((contact) => {
    const contactBirthDate = dateHelper.convertDateFormat(
      contact.birthday,
      config.format.uiDate
    );
    return !!dateHelper.checkIsNotChild(contactBirthDate);
  });

  const isContactsAvailable = !!contactsToAddAsFamilyMembers.length;

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, [familyAccountIds]);

  return (
    <>
      {newContactModalOpen && (
        <Modal show onHide={handleCloseContactFormModal}>
          <h1 className="typo-alpha t-mb-6">{t('dashboard:contact_title')}</h1>

          <ContactForm
            onClose={handleCloseContactFormModal}
            onSuccess={handleCloseContactFormModal}
            limited={true}
          />
        </Modal>
      )}
      {disclaimerModal && (
        <FamilyAccountDisclaimerModal
          onClose={() => {
            setDisclaimerModal(false);
          }}
          onConfirm={handleAddContactsAsFamilyMembers}
        />
      )}
      {!newContactModalOpen && !disclaimerModal && (
        <Modal show dataTestid="AddExistingContactAsFamilyMembersModal">
          <h1 className="typo-alpha">
            {t('familyAccounts:add_existing_account_modal_title')}
          </h1>
          <p className="typo-epsilon t-mt-4 t-mb-8">
            {t('familyAccounts:add_existing_account_modal_desc')}
          </p>

          {contactsToAddAsFamilyMembers.map((contact) => {
            const contactBirthDate = dateHelper.convertDateFormat(
              contact.birthday,
              config.format.uiDate
            );

            const isAdult = !!dateHelper.checkIsNotChild(contactBirthDate);
            const fullName = getFullName(contact);
            const relation = relations.find(
              (rel) => rel.value === (contact.relation || RELATIONSHIP.OTHER)
            );
            return (
              <div
                className="card t-py-6 t-px-7 t-mb-4 t-flex t-justify-between"
                key={contact.id}
              >
                <div className="t-flex t-items-center">
                  {isAdult ? (
                    <div
                      role="checkbox"
                      className={`t-flex t-items-center t-justify-center t-h-6 t-w-6 t-border t-border-solid t-rounded-sm t-cursor-pointer
                       ${
                         selectData[contact.id]
                           ? 't-border-delta-500 t-bg-delta-200'
                           : 't-border-delta-300 t-bg-delta-100'
                       }`}
                      onClick={() => {
                        onSelectContact(contact.id);
                      }}
                    >
                      {selectData[contact.id] && (
                        <img src={imgTick} alt="tick" />
                      )}
                    </div>
                  ) : (
                    <div
                      className={`t-flex t-items-center t-justify-center t-h-6 t-w-6 t-border t-border-solid t-rounded-sm t-cursor-not-allowed ${
                        selectData[contact.id]
                          ? 't-border-delta-500 t-bg-delta-200'
                          : 't-border-delta-300 t-bg-delta-100'
                      }`}
                      data-tip={t(
                        'familyAccounts:sub_account_underage_tooltip'
                      )}
                    />
                  )}
                  <p className="typo-beta t-ml-4">{fullName}</p>
                </div>
                <p className="typo-epsilon t-text-alpha-600">
                  {relation?.label || ''}
                </p>
              </div>
            );
          })}

          {!isContactsAvailable && (
            <div className="t-flex t-flex-col t-items-center">
              <IconEmpty />

              <p className="typo-epsilon t-text-beta-200 t-mt-3">
                {t('familyAccounts:no_available_contacts_text')}
              </p>
            </div>
          )}

          <div className="t-flex t-justify-end t-items-end t-mt-8 t-flex-col sm:t-flex-row">
            <div>
              <Button
                category="secondary"
                className="t-mr-0 t-py-2 t-mb-3 sm:t-mb-0 sm:t-mr-3"
                onClick={onCancelProcess}
                disabled={loading}
                testId="secondary-button"
              >
                {t('common:cancel')}
              </Button>
            </div>

            <div>
              <Button
                className="t-py-2"
                onClick={handleContinue}
                loading={loading}
                disabled={
                  !contactsSelected && !!isContactsAvailable && !isAllUnderage
                }
                testId="primary-button"
              >
                {!isContactsAvailable && (
                  <IconPlus role="presentation" className="t-inline t-mr-2" />
                )}
                {!!isContactsAvailable && !isAllUnderage
                  ? t('common:continue')
                  : t('common:add_new_contact')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddExistingContactAsFamilyMembersModal;
