import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';
import { ASSET_CATEGORIES } from 'modules/estatePlan/constants';

import { revokeEntireZVRDocument } from 'store/actions/zvrActions';
import bundesNotarKammerLogo from 'images/logo_bundesnotarkammer.svg';

interface RevokeHCPRegistrationModalProps {
  onClose: () => void;
  onRevokeSuccess?: () => void;
  questionnaire?: QUESTIONNAIRE_KEYS;
}

export default function RevokeHCPRegistrationModal({
  onClose,
  onRevokeSuccess,
  questionnaire,
}: RevokeHCPRegistrationModalProps) {
  const { t } = useTranslation(['zvrRegistration']);
  const dispatch = useDispatch();

  const isPD = questionnaire === QUESTIONNAIRE_KEYS.PATIENT_DECREE;

  const [loading, setLoading] = React.useState(false);

  const categoryAssets = useSelector(
    (state: RootState) => state.assets[ASSET_CATEGORIES.HEALTH]
  );

  const zvrAssets = categoryAssets.filter((asset) => asset.zvr);

  const handleRevokeRegistration = async () => {
    setLoading(true);
    await dispatch(revokeEntireZVRDocument());
    onClose();
    if (onRevokeSuccess) onRevokeSuccess();
    setLoading(false);
  };

  return (
    <Modal show onHide={onClose}>
      <img
        src={bundesNotarKammerLogo}
        alt="Bundesnotarkammer logo"
        className="sm:t-w-1/2 t-w-5/6"
      />
      <h1 className="typo-alpha t-mt-9">
        {t('zvrRegistration:revoke_zvr_application_title')}
      </h1>
      <p className="typo-epsilon t-mt-4">
        {t('zvrRegistration:revoke_zvr_application_description_1')}
      </p>

      <p className="typo-epsilon t-mt-4">
        {t(
          `zvrRegistration:revoke_zvr_application_description_2_${
            zvrAssets.length === 2 ? 'hcp_pd' : isPD ? 'pd' : 'hcp'
          }`
        )}
      </p>

      <div className="t-flex t-justify-end t-items-end t-mt-8 t-flex-col sm:t-flex-row">
        <div>
          <Button
            category="secondary"
            className="t-mr-0 sm:t-mr-3 t-py-2 t-mb-3 sm:t-mb-0"
            onClick={onClose}
            disabled={loading}
          >
            {t('zvrRegistration:cancel')}
          </Button>
        </div>

        <div>
          <Button
            className="t-py-2"
            onClick={handleRevokeRegistration}
            loading={loading}
          >
            {t('zvrRegistration:revoke_zvr_application_button')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
