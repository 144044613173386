import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { AgentInfo } from 'modules/agent/components';
import { Col, Row } from 'modules/bootstrap/components';

import { GENDERS } from 'modules/common/constants/enums';

import imgAgent from 'images/agents/avatar_agent_1.svg';
import imgCompany from 'images/agents/avatar_agent_company.svg';

const MyAgent = () => {
  const { t } = useTranslation(['agents']);

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const agentInfo = currentUser.agent;

  if (!agentInfo) return null;

  const defaultAvatar =
    !agentInfo.name && !agentInfo.surname ? imgCompany : imgAgent;

  return (
    <div data-testid="MyAgent">
      <h1 className="typo-alpha t-mb-2.5">
        {t(`agents:my_agent_page_title_${agentInfo.gender || GENDERS.OTHER}`)}
      </h1>
      <p className="typo-gamma t-mb-10">
        {t(
          `agents:my_agent_page_description_${
            agentInfo.gender || GENDERS.OTHER
          }`
        )}
      </p>

      <Row className="t-w-full">
        <Col xl={8} className="t-p-5 card">
          <div className="t-flex t-flex-col t-items-center">
            <img
              src={agentInfo.profilePicture || defaultAvatar}
              alt="Agent"
              className="t-mt-6 t-w-20 t-h-20 t-rounded-full t-object-cover"
            />
            <AgentInfo
              agentInfo={agentInfo}
              className="t-mt-4 t-flex t-flex-col t-items-center"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MyAgent;
