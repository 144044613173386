import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

import { ReactComponent as IconCheck } from 'images/icon-check-unfilled-circle.svg';
import { ReactComponent as IconInfo } from 'images/icon-info.svg';

interface PostalOptionModalProps {
  onCancel: () => void;
  nextStep: () => void;
}

const PostalOptionModal = ({ onCancel, nextStep }: PostalOptionModalProps) => {
  const { t } = useTranslation(['plan', 'common']);

  const isGlobalModal = useSelector((state: RootState) => !!state.modal.name);

  if (isGlobalModal) return null;

  return (
    <Modal show>
      <h1 className="typo-alpha">
        {t('plan:postal_service_doc_gen_option_title')}
      </h1>
      <p className="typo-epsilon t-mt-4">
        {t('plan:postal_service_doc_gen_option_desc')}
      </p>

      <div className="t-flex t-items-center t-py-4 t-mt-4 t-border-t t-border-b t-border-solid t-border-beta-200">
        <IconCheck className="t-text-alpha-600" />
        <p className="typo-theta t-text-beta-700 t-ml-4">
          {t('plan:postal_service_doc_gen_option_note')}
        </p>
      </div>

      <div className="t-flex t-justify-end t-items-center t-mt-4">
        <h1 className="typo-alpha t-text-delta-700">
          {t('plan:postal_service_doc_gen_price')}
        </h1>
        <IconInfo
          role="presentation"
          data-tip={t('plan:postal_service_doc_gen_price_tooltip')}
          className="t-inline t-text-delta-700 t-ml-3"
        />
      </div>

      <div className="t-flex t-justify-end t-items-end t-mt-4 t-flex-col sm:t-flex-row">
        <div>
          <Button
            category="secondary"
            className="t-mr-0 sm:t-mr-3 t-py-2 t-mb-3 sm:t-mb-0"
            onClick={onCancel}
          >
            {t('common:cancel')}
          </Button>
        </div>

        <div>
          <Button className="t-py-2" onClick={nextStep}>
            {t('plan:postal_service_doc_gen_send_btn')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PostalOptionModal;
