import { EMAIL_ERROR_TYPES } from 'modules/common/constants/enums';
import { FormErrors } from 'modules/common/types';

export const getEmailErr = (
  errors: FormErrors,
  t: (key: string) => string
): string => {
  if (errors.email) {
    if (errors.email.type === EMAIL_ERROR_TYPES.REQUIRED)
      return `${t('auth:email_required')}`;
    if (errors.email.type === EMAIL_ERROR_TYPES.EXISTING_EMAIL)
      return `${t('profile:email_in_use')}`;
    if (errors.email.type === EMAIL_ERROR_TYPES.YOURSELF_EMAIL)
      return errors.email.message as string;
    return `${t('auth:email_not_valid')}`;
  }
  return '';
};

export const getConfirmationCodeErr = (
  errors: FormErrors,
  t: (key: string) => string
) => {
  if (errors.code) {
    switch (errors.code?.type || '') {
      case 'invalidToken':
        return t('common:invalid_code');
      case 'validate':
        return `${t('auth:confirmation_code')} ${t('common:invalid')}`;
      default:
        return `${t('auth:confirmation_code')} ${t('common:required')}`;
    }
  }
  return '';
};
