import React from 'react';

interface DividerProps {
  text: string;
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
  className?: string;
}

export default function Divider({
  text,
  bgColor,
  borderColor,
  textColor,
  className,
}: DividerProps) {
  return (
    <div className={`t-relative ${className}`}>
      <div
        className="t-absolute t-inset-0 t-flex t-items-center"
        aria-hidden="true"
      >
        <div
          className={`t-w-full t-border-t t-border-solid ${
            borderColor || 't-border-beta-400'
          }`}
        />
      </div>
      <div className="t-relative t-flex t-justify-center">
        <span
          className={`t-px-2 ${bgColor || 't-bg-gamma-400'} ${textColor || ''}`}
        >
          {text}
        </span>
      </div>
    </div>
  );
}
