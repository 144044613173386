import icons from 'modules/assistant/constants/questionnaires/icons';

import { STEP_COMPONENT_TYPE as C } from 'modules/assistant/constants/questionnaires';
import { QUESTIONNAIRE_FORM_FIELD_TYPES as F } from 'modules/assistant/constants/questionnaires/questionnaireFormFieldTypes';

export enum STEP_KEYS {
  // ids of steps (questions)
  // NOTE: we should probably make the below keys as descriptive as we did for the answers
  // because we will store "keyStep: keyAnswer" in the backend
  STEP_START = 'start',
  STEP_1 = 'typeBurial',
  STEP_1_1 = 'burialSite',
  STEP_1_1_1 = 'specifyBurialSite',
  STEP_1_1_2 = 'cemetery',
  STEP_1_1_2_1 = 'specifyCemetery',
  STEP_1_1_2_2 = 'familyGrave',
  STEP_1_1_2_3 = 'specifyFamilyGrave',
  STEP_1_1_2_4 = 'beneficialOwner',
  STEP_1_1_2_5 = 'specifyBeneficialOwner',
  STEP_1_1_3 = 'graveStone',
  STEP_1_1_3_1 = 'specifyGraveStone',
  STEP_1_1_4 = 'graveDesign',
  STEP_1_1_4_1 = 'specifyGraveDesign',
  STEP_1_1_5 = 'graveCare',
  STEP_1_1_5_1 = 'graveCareContract',
  STEP_1_1_5_2 = 'specifyGraveCareContract',
  STEP_1_2 = 'specifyTypeBurial',
  STEP_2 = 'funeralCards',
  STEP_2_1 = 'specifyFuneralCards',
  STEP_3 = 'funeralAnnouncement',
  STEP_3_1 = 'newspapers',
  STEP_3_1_1 = 'specifyNewspapers',
  STEP_3_2 = 'content',
  STEP_3_2_1 = 'specifyContent',
  STEP_4 = 'funeralService',
  STEP_4_1 = 'location',
  STEP_4_1_1 = 'specifyLocation',
  STEP_4_2 = 'music',
  STEP_4_2_1 = 'specifyMusic',
  STEP_4_3 = 'process',
  STEP_4_3_1 = 'specifyProcess',
  STEP_4_4 = 'funeralSpeech',
  STEP_4_4_1 = 'speechGiver',
  STEP_4_4_1_1 = 'speechGiverContact',
  STEP_4_4_2 = 'speechContentFamily',
  STEP_4_4_3 = 'speechContentEducation',
  STEP_4_4_4 = 'speechContentPassions',
  STEP_4_4_5 = 'speechContentMotto',
  STEP_4_4_6 = 'speechContentRetro',
  STEP_5 = 'otherWishes',
  STEP_5_1 = 'specifyOtherWishes',
  STEP_END = 'end',
}
const S = STEP_KEYS;

export enum FUNERAL_PROVISION_ANSWER_KEYS {
  YES = 'true',
  NO = 'false',
  EARTH = 'earth',
  FIRE = 'fire',
  TREE = 'tree',
  SEA = 'sea',
  OTHER_BURIAL = 'otherBurial',
  CHOICE_GRAVE = 'choiceGrave',
  ROW_GRAVE = 'rowGrave',
  ANONYMOUS_GRAVE = 'anonymousGrave',
  ECCLESIASTICAL = 'ecclesiastical',
  SECULAR = 'secular',
  NO_FUNERAL_SERVICE = 'noFuneralService',
  SPIRITUAL_PERSON = 'spiritualPerson',
  CONTACT = 'contact',
  SECULAR_PERSON = 'secularPerson',
  OTHER_PERSON = 'otherPerson',
  FAMILY = 'family',
  FUNERALHOME = 'funeralHome',
  DESCRIPTION = 'description',
}

const A = FUNERAL_PROVISION_ANSWER_KEYS;

export const getFuneralProvisionQuestionnaire = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    [S.STEP_START]: {
      content: t('s_start'),
      type: C.START,
      linksTo: S.STEP_1,
    },
    [S.STEP_1]: {
      content: t('s_1'),
      current: 1,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.EARTH, linksTo: S.STEP_1_1 },
        { id: A.FIRE, linksTo: S.STEP_1_1 },
        { id: A.TREE, linksTo: S.STEP_2 },
        { id: A.SEA, linksTo: S.STEP_2 },
        { id: A.OTHER_BURIAL, linksTo: S.STEP_1_2 },
      ],
    },
    [S.STEP_1_1]: {
      content: t('s_1_1'),
      current: 2,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.CHOICE_GRAVE, linksTo: S.STEP_1_1_2 },
        { id: A.ROW_GRAVE, linksTo: S.STEP_1_1_2 },
        { id: A.ANONYMOUS_GRAVE, linksTo: S.STEP_1_1_2 },
        { id: A.OTHER_BURIAL, linksTo: S.STEP_1_1_1 },
      ],
    },
    [S.STEP_1_1_1]: {
      content: t('s_1_1_1'),
      current: 3,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_1_1_2,
    },
    [S.STEP_1_1_2]: {
      content: t('s_1_1_2'),
      current: 4,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_1_1_2_1 },
        { id: A.NO, linksTo: S.STEP_1_1_3 },
      ],
    },
    [S.STEP_1_1_2_1]: {
      content: t('s_1_1_2_1'),
      current: 5,
      total: 40,
      type: C.FORM,
      customNavigationButtons: true,
      smallSize: true,
      linksTo: S.STEP_1_1_2_2,
      formElements: [
        {
          type: F.NAME,
          label: t('s_1_1_2_1_form_field_label_1'),
          required: true,
          span: 12,
        },
        {
          type: F.ADDRESS,
          label: t('s_1_1_2_1_form_field_label_2'),
          required: false,
          span: 12,
        },
      ],
    },
    [S.STEP_1_1_2_2]: {
      content: t('s_1_1_2_2'),
      current: 6,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_1_1_2_3 },
        { id: A.NO, linksTo: S.STEP_1_1_3 },
      ],
    },
    [S.STEP_1_1_2_3]: {
      content: t('s_1_1_2_3'),
      current: 7,
      total: 40,
      type: C.FORM,
      customNavigationButtons: true,
      smallSize: true,
      linksTo: S.STEP_1_1_2_4,
      formElements: [
        {
          type: F.NAME,
          label: t('s_1_1_2_3_form_field_label_1'),
          required: true,
          span: 12,
        },
        {
          type: F.DATE,
          label: t('s_1_1_2_3_form_field_label_2'),
          required: false,
          span: 12,
        },
      ],
    },
    [S.STEP_1_1_2_4]: {
      content: t('s_1_1_2_4'),
      current: 8,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_1_1_2_5 },
        { id: A.NO, linksTo: S.STEP_1_1_3 },
      ],
    },
    [S.STEP_1_1_2_5]: {
      content: t('s_1_1_2_5'),
      current: 9,
      total: 40,
      type: C.FREE_TEXT,
      customFieldLabel: 'funeralProvision:s_1_1_2_5_field_label',
      customFieldPlaceholder: 'funeralProvision:s_1_1_2_5_field_placeholder',
      linksTo: S.STEP_1_1_3,
    },
    [S.STEP_1_1_3]: {
      content: t('s_1_1_3'),
      current: 10,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_1_1_3_1 },
        { id: A.NO, linksTo: S.STEP_1_1_4 },
      ],
      qa: [{ question: t('s_1_1_3_qa_q_1'), answer: t('s_1_1_3_qa_a_1') }],
    },
    [S.STEP_1_1_3_1]: {
      content: t('s_1_1_3_1'),
      current: 11,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_1_1_4,
    },
    [S.STEP_1_1_4]: {
      content: t('s_1_1_4'),
      current: 12,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_1_1_4_1 },
        { id: A.NO, linksTo: S.STEP_1_1_5 },
      ],
    },
    [S.STEP_1_1_4_1]: {
      content: t('s_1_1_4'),
      current: 13,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_1_1_5,
    },
    [S.STEP_1_1_5]: {
      content: t('s_1_1_5'),
      current: 14,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_1_1_5_1 },
        { id: A.NO, linksTo: S.STEP_2 },
      ],
      qa: [{ question: t('s_1_1_5_qa_q_1'), answer: t('s_1_1_5_qa_a_1') }],
    },
    [S.STEP_1_1_5_1]: {
      content: t('s_1_1_5_1'),
      current: 15,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_1_1_5_2 },
        { id: A.NO, linksTo: S.STEP_2 },
      ],
    },
    [S.STEP_1_1_5_2]: {
      content: t('s_1_1_5_2'),
      current: 16,
      total: 40,
      type: C.FORM,
      customNavigationButtons: true,
      smallSize: true,
      linksTo: S.STEP_2,
      formElements: [
        {
          type: F.NAME,
          label: t('s_1_1_5_2_form_field_label_1'),
          required: true,
          span: 12,
        },
        {
          type: F.DATE,
          label: t('s_1_1_5_2_form_field_label_2'),
          required: false,
          span: 12,
        },
      ],
      qa: [{ question: t('s_1_1_5_2_qa_q_1'), answer: t('s_1_1_5_2_qa_a_1') }],
    },
    [S.STEP_1_2]: {
      content: t('s_1_2'),
      current: 2,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_2,
    },
    [S.STEP_2]: {
      content: t('s_2'),
      current: 17,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_2_1 },
        { id: A.NO, linksTo: S.STEP_3 },
      ],
    },
    [S.STEP_2_1]: {
      content: t('s_2_1'),
      current: 18,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_3,
    },
    [S.STEP_3]: {
      content: t('s_3'),
      current: 19,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_3_1 },
        { id: A.NO, linksTo: S.STEP_4 },
      ],
    },
    [S.STEP_3_1]: {
      content: t('s_3_1'),
      current: 20,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_3_1_1 },
        { id: A.NO, linksTo: S.STEP_3_2 },
      ],
    },
    [S.STEP_3_1_1]: {
      content: t('s_3_1_1'),
      current: 21,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_3_2,
    },
    [S.STEP_3_2]: {
      content: t('s_3_2'),
      current: 22,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_3_2_1 },
        { id: A.NO, linksTo: S.STEP_4 },
      ],
    },
    [S.STEP_3_2_1]: {
      content: t('s_3_2_1'),
      current: 23,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_4,
      qa: [{ question: t('s_3_2_qa_q_1'), answer: t('s_3_2_qa_a_1') }],
    },
    [S.STEP_4]: {
      content: t('s_4'),
      current: 24,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.ECCLESIASTICAL, linksTo: S.STEP_4_1 },
        { id: A.SECULAR, linksTo: S.STEP_4_1 },
        { id: A.NO_FUNERAL_SERVICE, linksTo: S.STEP_5 },
      ],
      qa: [
        { question: t('s_4_qa_q_1'), answer: t('s_4_qa_a_1') },
        { question: t('s_4_qa_q_2'), answer: t('s_4_qa_a_2') },
        { question: t('s_4_qa_q_3'), answer: t('s_4_qa_a_3') },
      ],
    },
    [S.STEP_4_1]: {
      content: t('s_4_1'),
      current: 25,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.FAMILY, linksTo: S.STEP_4_2 },
        { id: A.FUNERALHOME, linksTo: S.STEP_4_2 },
        { id: A.DESCRIPTION, linksTo: S.STEP_4_1_1 },
      ],
    },
    [S.STEP_4_1_1]: {
      content: t('s_4_1_1'),
      current: 26,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_4_2,
    },
    [S.STEP_4_2]: {
      content: t('s_4_2'),
      current: 27,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.FAMILY, linksTo: S.STEP_4_3 },
        { id: A.FUNERALHOME, linksTo: S.STEP_4_3 },
        { id: A.DESCRIPTION, linksTo: S.STEP_4_2_1 },
      ],
    },
    [S.STEP_4_2_1]: {
      content: t('s_4_2_1'),
      current: 28,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_4_3,
    },
    [S.STEP_4_3]: {
      content: t('s_4_3'),
      current: 29,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.FAMILY, linksTo: S.STEP_4_4 },
        { id: A.FUNERALHOME, linksTo: S.STEP_4_4 },
        { id: A.DESCRIPTION, linksTo: S.STEP_4_3_1 },
      ],
    },
    [S.STEP_4_3_1]: {
      content: t('s_4_3_1'),
      current: 30,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_4_4,
    },
    [S.STEP_4_4]: {
      content: t('s_4_4'),
      current: 31,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_4_4_1 },
        { id: A.NO, linksTo: S.STEP_5 },
      ],
    },
    [S.STEP_4_4_1]: {
      content: t('s_4_4_1'),
      current: 32,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.SPIRITUAL_PERSON, linksTo: S.STEP_4_4_2 },
        { id: A.SECULAR_PERSON, linksTo: S.STEP_4_4_2 },
        { id: A.CONTACT, linksTo: S.STEP_4_4_1_1 },
        { id: A.OTHER_PERSON, linksTo: S.STEP_4_4_2 },
      ],
    },
    [S.STEP_4_4_1_1]: {
      content: t('s_4_4_1_1'),
      current: 33,
      total: 40,
      type: C.CONTACT,
      linksTo: S.STEP_4_4_2,
    },
    [S.STEP_4_4_2]: {
      content: t('s_4_4_2'),
      current: 34,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_4_4_3,
      qa: [{ question: t('s_4_4_2_qa_q_1'), answer: t('s_4_4_2_qa_a_1') }],
    },
    [S.STEP_4_4_3]: {
      content: t('s_4_4_3'),
      current: 35,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_4_4_4,
      qa: [{ question: t('s_4_4_3_qa_q_1'), answer: t('s_4_4_3_qa_a_1') }],
    },
    [S.STEP_4_4_4]: {
      content: t('s_4_4_4'),
      current: 36,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_4_4_5,
      qa: [{ question: t('s_4_4_4_qa_q_1'), answer: t('s_4_4_4_qa_a_1') }],
    },
    [S.STEP_4_4_5]: {
      content: t('s_4_4_5'),
      current: 37,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_4_4_6,
      qa: [{ question: t('s_4_4_5_qa_q_1'), answer: t('s_4_4_5_qa_a_1') }],
    },
    [S.STEP_4_4_6]: {
      content: t('s_4_4_6'),
      current: 38,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_5,
      qa: [{ question: t('s_4_4_6_qa_q_1'), answer: t('s_4_4_6_qa_a_1') }],
    },
    [S.STEP_5]: {
      content: t('s_5'),
      current: 39,
      total: 40,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_5_1 },
        { id: A.NO, linksTo: S.STEP_END },
      ],
    },
    [S.STEP_5_1]: {
      content: t('s_5'),
      current: 40,
      total: 40,
      type: C.FREE_TEXT,
      linksTo: S.STEP_END,
    },
    [S.STEP_END]: {
      content: t('s_end'),
      type: C.END,
    },
  };
};

export const getFuneralProvisionAnswers = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);

  return {
    [A.EARTH]: {
      content: t('a_earth'),
      infoContent: t('info_earth'),
      image: icons.imgEarth,
    },
    [A.FIRE]: {
      content: t('a_fire'),
      infoContent: t('info_fire'),
      image: icons.imgFire,
    },
    [A.TREE]: {
      content: t('a_tree'),
      infoContent: t('info_tree'),
      image: icons.imgTree,
    },
    [A.SEA]: {
      content: t('a_sea'),
      infoContent: t('info_sea'),
      image: icons.imgSea,
    },
    [A.OTHER_BURIAL]: {
      content: t('a_otherBurial'),
      infoContent: t('info_otherBurial'),
      image: icons.imgOtherBurial,
    },
    [A.CHOICE_GRAVE]: {
      content: t('a_choiceGrave'),
      infoContent: t('info_choiceGrave'),
      image: icons.imgChoiceGrave,
    },
    [A.ROW_GRAVE]: {
      content: t('a_rowGrave'),
      infoContent: t('info_rowGrave'),
      image: icons.imgRowGrave,
    },
    [A.ANONYMOUS_GRAVE]: {
      content: t('a_anonymousGrave'),
      infoContent: t('info_anonymousGrave'),
      image: icons.imgAnonymousGrave,
    },
    [A.ECCLESIASTICAL]: {
      content: t('a_ecclesiastical'),
      infoContent: t('info_ecclesiastical'),
      image: icons.imgEcclesiastical,
    },
    [A.SECULAR]: {
      content: t('a_secular'),
      infoContent: t('info_secular'),
      image: icons.imgSecular,
    },
    [A.NO_FUNERAL_SERVICE]: {
      content: t('a_noFuneralService'),
      image: icons.imgNoFuneralService,
    },
    [A.SPIRITUAL_PERSON]: {
      content: t('a_spiritualPerson'),
      image: icons.imgSpiritualPerson,
    },
    [A.CONTACT]: {
      content: t('a_contact'),
      image: icons.imgContact,
    },
    [A.SECULAR_PERSON]: {
      content: t('a_secularPerson'),
      image: icons.imgSecularPerson,
    },
    [A.OTHER_PERSON]: {
      content: t('a_otherPerson'),
      image: icons.imgOtherPerson,
    },
    [A.FAMILY]: {
      content: t('a_family'),
      image: icons.imgFamily,
    },
    [A.FUNERALHOME]: {
      content: t('a_funeralHome'),
      image: icons.imgFuneralHome,
    },
    [A.DESCRIPTION]: {
      content: t('a_description'),
      image: icons.imgDescription,
    },
    [A.YES]: {
      content: t('a_true'),
      image: icons.imgYes,
    },
    [A.NO]: {
      content: t('a_false'),
      image: icons.imgNo,
    },
  };
};

export const getFuneralProvisionStartScreen = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    listItems: [
      t('start_screen_list_1'),
      t('start_screen_list_2'),
      t('start_screen_list_3'),
    ],
  };
};
