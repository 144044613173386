import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  useAsyncRequest,
  useTranslation,
  validationHelper,
} from 'modules/common/helpers';
import { turnOffGoogleTwoAuth } from 'store/actions/userActions';

import { Button, Modal, TextInputRef } from 'modules/common/components';

type TwoAuthEnterGoogleValidationCodeModalProps = {
  disableSubmitButton?: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  requestActionFn: (...args: any[]) => any;
  twoFaToken?: string;
  is2FAChange?: boolean;
};

type Inputs = {
  tfaCode: number;
};

const TwoAuthEnterGoogleValidationCodeModal = ({
  onCancel,
  onSuccess,
  requestActionFn,
  disableSubmitButton = false,
  twoFaToken,
  is2FAChange = false,
}: TwoAuthEnterGoogleValidationCodeModalProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const {
    register,
    handleSubmit,
    setError,

    formState: { errors },
  } = useForm<Inputs>();
  const { request, loading } = useAsyncRequest(requestActionFn);
  const description =
    requestActionFn === turnOffGoogleTwoAuth
      ? t('auth:two_auth_google_enter_validation_code_disable_description')
      : t('auth:two_auth_google_enter_validation_code_enable_description');

  const onSubmit = useCallback(
    async ({ tfaCode }: Inputs) => {
      const response = await request(tfaCode, twoFaToken);

      if (response) {
        onSuccess();
      } else {
        setError('tfaCode', {
          message: t('auth:two_auth_google_invalid_code'),
          type: 'invalidCode',
        });
      }
    },
    [onSuccess, request, setError, t, twoFaToken]
  );

  let error: string | undefined;
  if (errors?.tfaCode?.type === 'required') {
    error = t('common:field_required');
  }
  if (errors?.tfaCode?.type === 'invalidCode') {
    error = errors.tfaCode.message;
  }
  if (errors?.tfaCode?.type === 'validate') {
    error = `${t('auth:validation_code')} ${t('common:invalid')}`;
  }

  return (
    <Modal dataTestid="TwoAuthEnterGoogleValidationCodeModal" show>
      <h1 className="Typography Typography--title u-weight-600">
        {is2FAChange
          ? t('auth:two_auth_google_enter_validation_code_change_title')
          : t('auth:two_auth_google_enter_validation_code_title')}
      </h1>

      <p className="Modal-description">{description}</p>

      <form onSubmit={handleSubmit(onSubmit)} className="Form">
        <div className="u-pb-25">
          <TextInputRef
            inputClass="Field--confirmCode"
            {...register('tfaCode', {
              required: true,
              validate: (value) => validationHelper.validateCode(6, value),
            })}
            label={t('auth:validation_code')}
            type="tel"
            maxLength={6}
            placeholder="XXXXXX"
            autoComplete={false}
            error={error}
          />
        </div>

        <div className="t-flex t-justify-end">
          <Button
            testId="cancel"
            category="secondary"
            className="t-mr-2.5"
            onClick={onCancel}
          >
            {t('common:cancel')}
          </Button>
          <Button
            testId="submitCode"
            disabled={disableSubmitButton}
            loading={loading}
            type="submit"
          >
            {t('common:submit_code')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default TwoAuthEnterGoogleValidationCodeModal;
