import React from 'react';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import ReactTooltip from 'react-tooltip';

import { RootState } from 'store/reducers';
import { scrollToElementId } from 'modules/common/helpers';

import {
  PostalProcess,
  UploadReminderModal,
} from 'modules/assetType/components';

import {
  LOCAL_STORAGE_VARS,
  POSTAL_STEPS,
  POSTAL_TYPES,
  URL_PARAMS,
  URL_SEARCH_CODES,
} from 'modules/common/constants/enums';
import { ASSET_TYPE_KEYS } from 'modules/estatePlan/constants';
import { PostalServiceWrapper } from 'modules/common/components';

const assetTypesToExclude = [ASSET_TYPE_KEYS.ORGAN_DONOR_CARD];

interface PostDocGenerationProcessProps {
  assetCategory: string;
}

const PostDocGenerationProcess = ({
  assetCategory,
}: PostDocGenerationProcessProps) => {
  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const [processEnd, setProcessEnd] = React.useState(false);

  const dataOnRedirect = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_VARS.DATA_ON_REDIRECT) || `{}`
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const postalPaymentStatus = searchParams.get(URL_PARAMS.STATUS);
  const isFromSuccessfulPostalPayment =
    postalPaymentStatus === URL_SEARCH_CODES.POSTAL_PAYMENT_SUCCESSFUL;

  const [postalStep, setPostalStep] = React.useState(true);

  const onCancelPostal = () => {
    setPostalStep(false);
  };

  // Close the reminder modal and delete the redirect data
  const onCloseReminder = () => {
    localStorage.removeItem(LOCAL_STORAGE_VARS.DATA_ON_REDIRECT);
    searchParams.delete(URL_PARAMS.STATUS);
    searchParams.delete(URL_PARAMS.PAYMENT_TOKEN);
    searchParams.delete(URL_PARAMS.LETTER_ID);
    setSearchParams(searchParams);
    scrollToElementId(dataOnRedirect?.assetId);
    setProcessEnd(true);
  };

  // clear if there is body-scroll lock from the overlap of the vault tutorial and postal service
  React.useEffect(() => {
    ReactTooltip.rebuild();

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const shouldProcessEnd =
    !!processEnd ||
    isEmpty(dataOnRedirect) ||
    !dataOnRedirect.assetId ||
    assetTypesToExclude.includes(dataOnRedirect?.assetType);

  switch (true) {
    case !!shouldProcessEnd:
      return null;
    case !!postalStep:
      return (
        <PostalServiceWrapper
          searchParams={searchParams}
          type={POSTAL_TYPES.UPLOAD}
        >
          <PostalProcess
            onCancel={onCancelPostal}
            isSuccessful={isFromSuccessfulPostalPayment}
            data={dataOnRedirect}
            category={assetCategory}
            contact={user}
            type={POSTAL_TYPES.UPLOAD}
            initialStep={
              isFromSuccessfulPostalPayment ? POSTAL_STEPS.STEP_SUCCESS : ''
            }
          />
        </PostalServiceWrapper>
      );
    case !postalStep:
      return (
        <UploadReminderModal onCancel={onCloseReminder} data={dataOnRedirect} />
      );
    default:
      return null;
  }
};

export default PostDocGenerationProcess;
