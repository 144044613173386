import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import { RootState } from 'store/reducers';

import { AppPage } from 'modules/appPage/components';
import {
  ModalHandler,
  SpinnerOverlay,
  ScrollToTop,
  ReactToastContainer,
} from 'modules/common/components';
import { PublicPage } from 'modules/publicPage/components';

import 'styles/App.scss?v=1.1';
import 'react-toastify/dist/ReactToastify.css';

export interface AppProps {
  routes: AppRoute[];
  children?: Record<string, unknown>;
}

function App({ routes, children }: AppProps) {
  const { trackPageView } = useMatomo();

  const user = useSelector((state: RootState) => state.user.current);
  const location = useLocation();

  useEffect(() => {
    trackPageView();
  }, [location.pathname]);

  const asyncAction = useSelector(
    (state: RootState) => state.common.asyncAction
  );

  const showOverlayWithSpinner = get(
    asyncAction,
    'showOverlayWithSpinner',
    false
  );

  const renderRoute = (route, index: number) => {
    const { pageProps, component: Component } = route;
    const isPublicPage = !!pageProps.public;

    const element = isPublicPage ? (
      <PublicPage {...pageProps}>
        <Component />
      </PublicPage>
    ) : (
      <AppPage {...pageProps}>
        <Component />
      </AppPage>
    );

    return <Route key={index} path={route.path} element={element} />;
  };

  const allowedRoutes =
    !user?.regular && user?.viewer
      ? routes.filter(
          (item) => item.pageProps.public || item.pageProps.visibleForViewer
        )
      : user?.beneficiary
      ? routes.filter(
          (item) =>
            item.pageProps.public || item.pageProps.visibleForBeneficiary
        )
      : routes;

  return (
    <>
      {showOverlayWithSpinner && <SpinnerOverlay />}
      <ScrollToTop />
      <ReactToastContainer />
      <Routes>
        {allowedRoutes.map((route, index: number) => renderRoute(route, index))}
      </Routes>
      <ModalHandler />
      {children}
    </>
  );
}

export default App;
