import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import {
  PAYMENT_METHODS,
  PAYMENT_STEPS,
  BILLING_OPTIONS,
} from 'modules/payment/constants';

import { ReactComponent as CheckmarkIcon } from 'images/check.svg';

const { CREDIT_CARD, SEPA } = PAYMENT_METHODS;
const {
  STEP_CHOOSE_BILLING,
  STEP_PAYMENT_METHOD,
  STEP_SEPA_FORM,
  STEP_SUCCESS,
} = PAYMENT_STEPS;

export interface ProgressBarProps {
  currentStep: number;
  handleStepChange: (value: number) => void;
  billingOption?: string;
  methodOption?: string;
}

export default function ProgressBar({
  currentStep,
  handleStepChange,
  billingOption,
  methodOption,
}: ProgressBarProps) {
  const { t } = useTranslation(['payment']);

  const isLifeTimePayment = billingOption === BILLING_OPTIONS.LIFETIME;

  return (
    <div
      className="t-w-full t-mt-2 t-flex t-justify-center"
      data-testid="ProgressBar"
    >
      <div
        className={`t-flex t-justify-between ${
          methodOption === SEPA ? 't-w-full' : 't-w-2/3'
        }`}
      >
        <div className="t-flex t-flex-col t-justify-center t-items-center t-z-50">
          <button
            type="button"
            className="t-w-10 t-h-10 t-bg-alpha-600 t-rounded-full t-text-lg t-flex t-items-center t-justify-center focus:t-outline-none"
            onClick={() => handleStepChange(STEP_CHOOSE_BILLING)}
            disabled={currentStep === STEP_SUCCESS}
          >
            {billingOption ? (
              <CheckmarkIcon className="t-h-3 t-w-4 t-text-beta-50 t-text-xl" />
            ) : (
              <div className="t-text-beta-50 t-text-xl">
                {STEP_CHOOSE_BILLING}
              </div>
            )}
          </button>
          <p className="typo-epsilon t-mt-2">
            {t('payment:progress_bar_select_plan')}
          </p>
        </div>

        <div className="t-flex-grow t-h-1.5 t-bg-alpha-600 t-relative t-top-4 t--mx-10 t-overflow-hidden" />
        <div className="t-flex t-flex-col t-justify-center t-items-center t-z-50">
          <button
            type="button"
            className={`t-w-10 t-h-10 t-rounded-full ${
              methodOption || currentStep === STEP_PAYMENT_METHOD
                ? 't-bg-alpha-600'
                : 't-bg-beta-50 t-border-2 t-border-solid t-border-alpha-600'
            } t-text-lg t-flex t-items-center t-justify-center focus:t-outline-none`}
            onClick={() =>
              billingOption && handleStepChange(STEP_PAYMENT_METHOD)
            }
            disabled={currentStep === STEP_SUCCESS || isLifeTimePayment}
          >
            {(methodOption && methodOption !== CREDIT_CARD) ||
            currentStep === STEP_SUCCESS ? (
              <CheckmarkIcon className="t-h-3 t-w-4 t-text-beta-50 t-text-xl" />
            ) : (
              <div
                className={
                  currentStep === STEP_PAYMENT_METHOD || methodOption
                    ? 't-text-beta-50 t-text-xl'
                    : 't-text-alpha-600 t-text-xl'
                }
              >
                {STEP_PAYMENT_METHOD}
              </div>
            )}
          </button>
          <p className="typo-epsilon t-mt-2">
            {t(
              isLifeTimePayment
                ? 'payment:progress_bar_pay'
                : 'payment:progress_bar_choose_payment_method'
            )}
          </p>
        </div>

        {methodOption === SEPA && (
          <>
            <div className="t-flex-grow t-h-1.5 t-bg-alpha-600 t-relative t-top-4 t--mx-10 t-overflow-hidden" />

            <div className="t-flex t-flex-col t-justify-center t-items-center t-z-50">
              <button
                type="button"
                className={`t-w-10 t-h-10 t-rounded-full ${
                  currentStep === STEP_SEPA_FORM || currentStep === STEP_SUCCESS
                    ? 't-bg-alpha-600'
                    : 't-bg-beta-50 t-border-2 t-border-solid t-border-alpha-600'
                } t-text-lg t-flex t-items-center t-justify-center focus:t-outline-none`}
                onClick={() => methodOption && handleStepChange(STEP_SEPA_FORM)}
                disabled={currentStep === STEP_SUCCESS}
              >
                {currentStep === STEP_SUCCESS ? (
                  <CheckmarkIcon className="t-h-3 t-w-4 t-text-beta-50 t-text-xl" />
                ) : (
                  <div
                    className={
                      currentStep === STEP_SEPA_FORM
                        ? 't-text-beta-50 t-text-xl'
                        : 't-text-alpha-600 t-text-xl'
                    }
                  >
                    {STEP_SEPA_FORM}
                  </div>
                )}
              </button>
              <p className="typo-epsilon t-mt-2">
                {t('payment:progress_bar_payment_info')}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
