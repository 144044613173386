import { find, findIndex, last, isEqual, isEmpty, camelCase } from 'lodash';
import {
  STEP_COMPONENT_TYPE,
  QUESTIONNAIRE_KEYS,
  QUESTIONNAIRE_PAGES,
} from 'modules/assistant/constants/questionnaires';
import { STEP_KEYS } from 'modules/assistant/constants/questionnaires/patientDecree';
import { STEP_KEYS as INIT_STEP_KEYS } from 'modules/assistant/constants/questionnaires/initialAssessment';
import {
  CONTENT_QUESTIONNAIRE_KEYS,
  CONTENT_QUESTIONNAIRE_PAGES,
} from 'modules/assistant/constants/contentQuestionnaires';

import { STEP_KEYS as HCP_STEP_KEYS } from 'modules/assistant/constants/questionnaires/healthCareProxy';
import { STEP_KEYS as ODC_STEP_KEYS } from 'modules/assistant/constants/questionnaires/organDonorCard';
import { STEP_KEYS as PWA_STEP_KEYS } from 'modules/assistant/constants/questionnaires/petWatchingAgreement';
import { STEP_KEYS as WILL_STEP_KEYS } from 'modules/assistant/constants/questionnaires/will';
import { STEP_KEYS as CO_STEP_KEYS } from 'modules/assistant/constants/questionnaires/custodyOrder';
import { STEP_KEYS as FP_STEP_KEYS } from 'modules/assistant/constants/questionnaires/funeralProvision';

// TODO: probably need to export this constant and use everywhere in the codebase ?
const CURR_STEP_ID_FIELD = 'currStepId';

export const getPreviousStep = (
  currStep: STEP_KEYS,
  stepsCollection: STEP_KEYS[]
): STEP_KEYS | null => {
  if (currStep === STEP_KEYS.STEP_START) return null;
  const idx = findIndex(stepsCollection, (item) => item === currStep);
  const prevStep =
    idx === -1 ? last(stepsCollection) : stepsCollection[idx - 1];

  return prevStep || null;
};

export const getNextStep = (
  step,
  answers: SelectedAnswer[] | SelectedAnswer | string
) => {
  function getLinkFromAnswerOrStep() {
    // if step type is multiple choice or singleAndMultiple & an answer has the linksTo property,
    // the answers linksTo has priority over the steps
    const answerWithLink = (answers as SelectedAnswer[]).find((a) => a.linksTo);
    if (answerWithLink) {
      return answerWithLink.linksTo;
    }
    return step.linksTo;
  }

  switch (step.type) {
    case STEP_COMPONENT_TYPE.START:
    case STEP_COMPONENT_TYPE.ADD_INFORMATION:
    case STEP_COMPONENT_TYPE.FREE_TEXT:
    case STEP_COMPONENT_TYPE.EXPIRATION_DATE:
    case STEP_COMPONENT_TYPE.CONTACT:
    case STEP_COMPONENT_TYPE.SUBSTITUTE_HEIR:
    case STEP_COMPONENT_TYPE.HEIR_CONTACTS:
    case STEP_COMPONENT_TYPE.BEQUEST:
    case STEP_COMPONENT_TYPE.INHERITANCE_DISTRIBUTION:
    case STEP_COMPONENT_TYPE.PARTNER_PROFILE:
    case STEP_COMPONENT_TYPE.MULTI_CONTACTS:
    case STEP_COMPONENT_TYPE.FORM:
    case STEP_COMPONENT_TYPE.BANK_POA_CONTACT:
      return step.linksTo;
    case STEP_COMPONENT_TYPE.MULTIPLE:
      if (Array.isArray(answers)) {
        return getLinkFromAnswerOrStep();
      }
      return null;
    case STEP_COMPONENT_TYPE.SINGLE:
      if (!answers) return null;
      // TODO: dog-nail due to answer might be <SelectedAnswer> or <string>! need refactoring!
      return (
        (answers as SelectedAnswer)?.linksTo ||
        find(step.possibleAnswers, (item) => item.id === (answers as string))
          ?.linksTo
      );
    case STEP_COMPONENT_TYPE.SINGLEANDMULTIPLE:
      if (!answers) return null;
      if (Array.isArray(answers)) {
        return getLinkFromAnswerOrStep();
      }
      return (answers as SelectedAnswer)?.linksTo || step.linksTo;
    default:
      return null;
  }
};

export const getStepsAfter = (
  currStep: STEP_KEYS,
  stepsCollection: STEP_KEYS[]
): STEP_KEYS[] => {
  const idx = findIndex(stepsCollection, (item) => item === currStep);
  const stepsAfter = stepsCollection.filter((item, i) => i > idx);

  return stepsAfter;
};

// receices part of Redux questionnaire store (e.g. questionnaires['patientDegree'])
// and returns array of step keys (! excluding non-steps like 'currStepId' !)
// e.g. ['start', 'applicableSituations', 'lifeSupport']
export const getStepsArrayFromStore = (stepsCollection): STEP_KEYS[] => {
  return Object.entries(stepsCollection)
    .filter((el) => el[0] !== CURR_STEP_ID_FIELD)
    .map((item) => item[0]) as STEP_KEYS[];
};

export const isAnswerEqual = (
  answers: SelectedAnswer[] | SelectedAnswer | string,
  previousAnswer: string | string[] | null
): boolean => {
  if (!answers || !previousAnswer) return false;

  if (typeof previousAnswer === 'string') {
    if (previousAnswer === answers) return true;
  }

  if ((answers as SelectedAnswer).id === (previousAnswer as string))
    return true;

  if (answers && (Array.isArray(answers) || typeof answers === 'object')) {
    return isEqual(answers, previousAnswer);
  }

  return answers === previousAnswer;
};

export const isBreaksExistingAnswerChain = (
  currStep: STEP_KEYS,
  potentialNextStep: STEP_KEYS,
  stepsCollection: STEP_KEYS[]
): boolean => {
  const stepsAfterCurrent = getStepsAfter(currStep, stepsCollection);
  if (isEmpty(stepsAfterCurrent)) return false;
  return stepsAfterCurrent[0] !== potentialNextStep;
};

export const isEndStepOfQuestionnaire = (
  questionnaire: QUESTIONNAIRE_KEYS,
  step: string
): boolean => {
  if (questionnaire === QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT) {
    return step === INIT_STEP_KEYS.STEP_10;
  }

  return step === STEP_KEYS.STEP_END;
};

export const isRecoAvailable = (qKey: string): boolean => {
  const questionnaireKey = camelCase(qKey);

  return !!(
    QUESTIONNAIRE_PAGES[questionnaireKey]?.path ||
    CONTENT_QUESTIONNAIRE_PAGES[questionnaireKey]?.path
  );
};

export const getRelatedDocQuestionnaire = (
  contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS
) => {
  switch (contentQuestionnaire) {
    case CONTENT_QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY:
      return QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY;
    case CONTENT_QUESTIONNAIRE_KEYS.PATIENT_DECREE:
      return QUESTIONNAIRE_KEYS.PATIENT_DECREE;
    case CONTENT_QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD:
      return QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD;
    case CONTENT_QUESTIONNAIRE_KEYS.WILL:
      return QUESTIONNAIRE_KEYS.WILL_GENERATOR;
    case CONTENT_QUESTIONNAIRE_KEYS.CUSTODY_ORDER:
      return QUESTIONNAIRE_KEYS.CUSTODY_ORDER;
    case CONTENT_QUESTIONNAIRE_KEYS.FUNERAL_PROVISION:
      return QUESTIONNAIRE_KEYS.FUNERAL_PROVISION;
    case CONTENT_QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER:
      return QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER;
    default:
      return '';
  }
};

export const getCardLink = (qKey: string): string => {
  const questionnaireKey = camelCase(qKey);
  return (
    QUESTIONNAIRE_PAGES[questionnaireKey]?.path ||
    CONTENT_QUESTIONNAIRE_PAGES[questionnaireKey]?.path ||
    '/not-found'
  );
};

export const getPotentialContactDeletionStep = (
  questionnaire,
  questionnaireData,
  contacts
): string => {
  switch (questionnaire) {
    case QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY:
      return getMissingContactStep(
        [HCP_STEP_KEYS.STEP_1, HCP_STEP_KEYS.STEP_2_1],
        questionnaireData,
        contacts
      );
    case QUESTIONNAIRE_KEYS.PATIENT_DECREE:
      return getMissingContactStep(
        [
          STEP_KEYS.STEP_14_1,
          STEP_KEYS.STEP_15_1,
          STEP_KEYS.STEP_16_1,
          STEP_KEYS.STEP_17_1,
        ],
        questionnaireData,
        contacts
      );
    case QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD:
      return getMissingContactStep(
        [ODC_STEP_KEYS.STEP_1_3],
        questionnaireData,
        contacts
      );

    case QUESTIONNAIRE_KEYS.PET_WATCHING_AGREEMENT:
      return getMissingContactStep(
        [PWA_STEP_KEYS.STEP_1, PWA_STEP_KEYS.STEP_2_1],
        questionnaireData,
        contacts
      );

    case QUESTIONNAIRE_KEYS.WILL_GENERATOR:
      return getMissingContactStep(
        [
          WILL_STEP_KEYS.STEP_7_1_1,
          WILL_STEP_KEYS.STEP_17_1_1,
          WILL_STEP_KEYS.STEP_24_1_1,
        ],
        questionnaireData,
        contacts
      );

    case QUESTIONNAIRE_KEYS.CUSTODY_ORDER:
      return getMissingContactStep(
        [CO_STEP_KEYS.STEP_2, CO_STEP_KEYS.STEP_3_1, CO_STEP_KEYS.STEP_4_1],
        questionnaireData,
        contacts
      );

    case QUESTIONNAIRE_KEYS.FUNERAL_PROVISION:
      return getMissingContactStep(
        [FP_STEP_KEYS.STEP_4_4_1_1],
        questionnaireData,
        contacts
      );
    default:
      return '';
  }
};

const getMissingContactStep = (steps, questionnaireData, contacts) => {
  for (const step of steps) {
    const stepContacts = questionnaireData[step];
    // in case the step data does not exist (sub-steps)
    if (!stepContacts) return '';

    const allContactExist = checkContactExist(contacts, stepContacts);
    if (!allContactExist) return step;
  }
  return '';
};

const checkContactExist = (contacts, stepContacts) => {
  const contactIds = contacts?.map((contact) => contact.id) || [];

  if (Array.isArray(stepContacts)) {
    // for multi-contact steps
    return stepContacts.every((stepContact) =>
      contactIds.includes(stepContact)
    );
  } else {
    // for single Contact step
    return contactIds.includes(stepContacts);
  }
};
