export const NOTIFICATION_CODES = {
  PROGRESS_BAR: 'progressBar',
  REQUIRED_PROFILE_INFO: 'requiredProfileInfo',
  VOLUNTARY_PROFILE_INFO: 'voluntaryProfileInfo',
  VERIFY_EMAIL_ADDRESS: 'verifyEmailAddress',
  SIGN_TA: 'signTa',
  ASSIGN_ASSETS: 'assignAssets',
  ADD_BENEFICIARIES: 'addBeneficiaries',
  DOCUMENT_UNFINISHED: 'documentUnfinished',
  NEW_LOGIN: 'newLogin',
  BENEFICIARY_ADDED: 'beneficiaryAdded',
  ZVR_REGISTERED: 'zvrRegistered',
  ASSISTANT_REMINDER: 'assistantReminder',
  REFERRAL_BONUS: 'referralBonus',
  UPLOAD_SIGN_DOCUMENT: 'signDocumentReminder',
  UPDATE_PASSWORD_REMINDER: 'updatePasswordReminder',
};

export const NOTIFICATION_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  REMINDER: 'reminder',
};

const NC = NOTIFICATION_CODES;
const NT = NOTIFICATION_TYPES;

export const NOTIFICATIONS = {
  [NC.PROGRESS_BAR]: {
    type: NT.INFO as NotificationType,
    title: `notification:${NC.PROGRESS_BAR}_title`,
    description: `notification:${NC.PROGRESS_BAR}_description`,
    link: '/plan',
  },
  [NC.REQUIRED_PROFILE_INFO]: {
    type: NT.ERROR as NotificationType,
    title: `notification:${NC.REQUIRED_PROFILE_INFO}_title`,
    description: `notification:${NC.REQUIRED_PROFILE_INFO}_description`,
    link: '/profile',
  },
  [NC.VOLUNTARY_PROFILE_INFO]: {
    type: NT.WARNING as NotificationType,
    title: `notification:${NC.VOLUNTARY_PROFILE_INFO}_title`,
    description: `notification:${NC.VOLUNTARY_PROFILE_INFO}_description`,
    link: '/profile',
  },
  [NC.VERIFY_EMAIL_ADDRESS]: {
    type: NT.WARNING as NotificationType,
    title: `notification:${NC.VERIFY_EMAIL_ADDRESS}_title`,
    description: `notification:${NC.VERIFY_EMAIL_ADDRESS}_description`,
    link: '/profile',
  },
  [NC.SIGN_TA]: {
    type: NT.WARNING as NotificationType,
    title: `notification:${NC.SIGN_TA}_title`,
    description: `notification:${NC.SIGN_TA}_description`,
    link: '/plan/transmortal',
  },
  [NC.ASSIGN_ASSETS]: {
    type: NT.INFO as NotificationType,
    title: `notification:${NC.ASSIGN_ASSETS}_title`,
    description: `notification:${NC.ASSIGN_ASSETS}_description`,
    link: '/plan',
  },
  [NC.ADD_BENEFICIARIES]: {
    type: NT.INFO as NotificationType,
    title: `notification:${NC.ADD_BENEFICIARIES}_title`,
    description: `notification:${NC.ADD_BENEFICIARIES}_description`,
    link: '/my-contacts',
  },
  [NC.DOCUMENT_UNFINISHED]: {
    type: NT.INFO as NotificationType,
    title: `notification:${NC.DOCUMENT_UNFINISHED}_title`,
    description: `notification:${NC.DOCUMENT_UNFINISHED}_description`,
    link: '/assistant',
  },
  [NC.BENEFICIARY_ADDED]: {
    type: NT.SUCCESS as NotificationType,
    title: `notification:${NC.BENEFICIARY_ADDED}_title`,
    description: `notification:${NC.BENEFICIARY_ADDED}_description`,
  },
  [NC.ZVR_REGISTERED]: {
    type: NT.SUCCESS as NotificationType,
    title: `notification:${NC.ZVR_REGISTERED}_title`,
    description: `notification:${NC.ZVR_REGISTERED}_description`,
  },
  [NC.ASSISTANT_REMINDER]: {
    type: NT.REMINDER as NotificationType,
    title: `notification:${NC.ASSISTANT_REMINDER}_title`,
    description: `notification:${NC.ASSISTANT_REMINDER}_description`,
    link: '/assistant',
  },
  [NC.REFERRAL_BONUS]: {
    type: NT.WARNING as NotificationType,
    title: `notification:${NC.REFERRAL_BONUS}_title`,
    description: `notification:${NC.REFERRAL_BONUS}_description`,
  },
  // not finished
  [NC.NEW_LOGIN]: {
    type: NT.WARNING as NotificationType,
    title: `notification:${NC.NEW_LOGIN}_title`,
    description: `notification:${NC.NEW_LOGIN}_description`,
  },
  [NC.UPLOAD_SIGN_DOCUMENT]: {
    type: NT.REMINDER as NotificationType,
    title: `notification:${NC.UPLOAD_SIGN_DOCUMENT}_title`,
    description: `notification:${NC.UPLOAD_SIGN_DOCUMENT}_description`,
    link: '/plan',
  },
  [NC.UPDATE_PASSWORD_REMINDER]: {
    type: NT.REMINDER as NotificationType,
    title: `notification:${NC.UPDATE_PASSWORD_REMINDER}_title`,
    description: `notification:${NC.UPDATE_PASSWORD_REMINDER}_description`,
    link: '/plan',
  },
};
