import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  resetPassword,
  checkResetToken,
  getCurrentUser,
} from 'store/actions/userActions';
import { reactToast, useTranslation } from 'modules/common/helpers';
import { authHelper } from 'modules/auth/helpers';

import { Row, Col } from 'modules/bootstrap/components';
import { FormConditionsNew } from 'modules/profile/components';
import {
  TextInput,
  SiteLanguage,
  Button,
  PlatformFeatureInfoSection,
} from 'modules/common/components';
import {
  AuthFooter,
  ClickableLogo,
  LoginFooter,
} from 'modules/auth/components';

import { commonFeatures } from 'modules/auth/constants';

import imgRectangle from 'images/img-auth-rectangle.png';

function PasswordResetPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['auth', 'toastr']);
  const { token } = useParams();

  const [isBrokenToken, setIsBrokenToken] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [pwValidity, setPwValidity] = useState({} as PasswordValidity);
  const [isPasswordTyping, setIsPasswordTyping] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    onCheckResetToken();
  }, []);

  useEffect(() => {
    setPwValidity(authHelper.getPasswordValidity(password));
  }, [password]);

  useEffect(() => {
    if (isBrokenToken) {
      navigate('/invalid-token');
    }
  }, [isBrokenToken]);

  const onCheckResetToken = async () => {
    const data: any = await dispatch(checkResetToken(token));

    if (!data) {
      setIsBrokenToken(true);
    }
  };

  const onChange = (_, value) => {
    setPassword(value);
  };

  const isValidResetForm = () => {
    const checkedError = authHelper.getPasswordErrorMessages(password, t);
    setError(checkedError);

    return !checkedError;
  };

  const onResetPassword = async (e) => {
    e.preventDefault();
    if (!isValidResetForm()) return;

    setLoading(true);
    const response: any = await dispatch(
      resetPassword({ password }, token || '')
    );

    if (response) {
      await dispatch(getCurrentUser());
      navigate('/');

      if (response?.message) {
        reactToast.showMessage(response.message);
      }
    }
    setLoading(false);
  };

  const onPasswordInputBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setIsPasswordTyping(false);
    }
  };

  const isPasswordInvalid = Object.values(pwValidity).some((el) => !el);

  return (
    <>
      <Row className="lg:t-p-0 t-min-h-screen t-w-full t-m-0 t-relative">
        <Col
          md={{ span: 6, offset: 3 }}
          lg={{ span: 10, offset: 1 }}
          className="t-p-0 t-z-50"
        >
          <div className="t-hidden md:t-flex t-justify-between t-mt-6 lg:t-mt-10 t-px-3">
            <ClickableLogo />
            <div className="t-flex t-items-center">
              <div className="t-hidden lg:t-inline-block">
                <AuthFooter />
              </div>
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>
          </div>

          <div className="md:t-flex t-flex-col t-items-center t-mt-4 lg:t-mt-16 t-px-3 t-mb-16">
            <div className="t-flex t-justify-between md:t-hidden">
              <ClickableLogo />
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>

            <div className="card t-shadow-lg t-bg-beta-50 t-p-6 lg:t-px-12 lg:t-pt-12 t-w-full lg:t-w-auto t-mt-5">
              <form
                onSubmit={onResetPassword}
                className="Form t-w-full lg:t-w-96 t-bg-transparent"
              >
                <h2 className="typo-beta t-mb-5">
                  {t('auth:password_reset_page_title')}
                </h2>

                <p className="typo-epsilon t-mb-5 t-text-beta-400">
                  {t('auth:password_reset_page_description')}
                </p>

                <fieldset disabled={isLoading}>
                  <TextInput
                    name="password"
                    label={t('common:password_label')}
                    type="password"
                    value={password}
                    onChange={onChange}
                    onBlur={onPasswordInputBlur}
                    onFocus={() => {
                      setIsPasswordTyping(true);
                    }}
                    placeholder={t('auth:set_new_password_placeholder')}
                    error={error}
                  />
                </fieldset>

                {isPasswordTyping && isPasswordInvalid && (
                  <div className="t-bg-beta-50 t-animate-fade-in-100">
                    <FormConditionsNew pwValidity={pwValidity} />
                  </div>
                )}

                <div className="t-flex t-justify-end t-mt-3">
                  <Button
                    loading={isLoading}
                    testId="reset-password-button"
                    type="submit"
                  >
                    <span className="t-px-3">
                      {t('auth:set_password_button_text')}
                    </span>
                  </Button>
                </div>
              </form>
            </div>

            <div className="t-my-5 lg:t-mt-16 t-flex t-justify-center md:t-inline">
              <PlatformFeatureInfoSection features={commonFeatures} />
            </div>

            <div className="lg:t-hidden t-mt-16">
              <AuthFooter />
            </div>
          </div>

          <div className="t-absolute t-bottom-0 t-w-full t-flex t-justify-center md:t-inline">
            <LoginFooter />
          </div>
        </Col>
      </Row>
      <div className="t-fixed t-w-screen t-object-cover t-h-3/4 t-bottom-0">
        <img
          src={imgRectangle}
          className="t-w-full t-h-full"
          alt="Rectangle background"
        />
      </div>
    </>
  );
}

export default PasswordResetPage;
