import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { camelCase, find, isEmpty } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';

import { RootState } from 'store/reducers';
import {
  setAssetToEdit,
  setIsShowEditAssetForm,
} from 'store/actions/layoutActions';
import { getAsset } from 'store/actions/assetActions';
import { setModal } from 'store/actions/modalActions';
import {
  dateHelper,
  getNumberWithDots,
  getNumber,
  optionsHelper,
  handleResponseError,
  translateHelper,
  config,
  useTranslation,
} from 'modules/common/helpers';
import { getFullAddress, useGetCountries } from 'modules/common/helpers';
import {
  getAssetProviderValue,
  getFormattedProviderAssetByKey,
} from 'modules/asset/helpers';

import {
  Modal,
  PdfDownloadLinkAsBeneficiary,
  PdfDownloadLink,
  Button,
} from 'modules/common/components';
import {
  AssetViewModalCombinedField,
  AssetViewModalMultipleInputs,
} from 'modules/asset/components';
import { EditZVRProcess } from 'modules/zvr/components';

import {
  ASSET_TYPE_KEYS,
  PLAN_CATEGORY_PAGES,
} from 'modules/estatePlan/constants';
import { PROVIDER_CONSTANT_TYPES } from 'modules/estatePlan/constants/enums';
import { FIELD_TYPE_KEYS as F } from 'modules/estatePlan/constants/fieldLibrary';
import { ERROR_CODES, MODALS } from 'modules/common/constants';

import { ReactComponent as IconEye } from 'images/eye.svg';
import { ReactComponent as ZvrLogo } from 'images/logo_bundesnotarkammer.svg';
import { getProvidersByType } from 'store/actions/providerActions';

const paymentMethodOptions = optionsHelper.getPaymentMethodOptions();
const paymentFrequencyOptions = optionsHelper.getPaymentFrequencyOptions();

const LINK_ASSET_TYPE_KEYS = [
  ASSET_TYPE_KEYS.BANK_ACCOUNT,
  ASSET_TYPE_KEYS.ONLINE_WALLET,
  ASSET_TYPE_KEYS.PHONE,
  ASSET_TYPE_KEYS.EMAIL,
];

interface AssetViewModalProps {
  asset: AssetDTO;
  assetType: AssetTypeDTO;
  relatedAsset?: AssetDTO;
  onClose: () => void;
  isEstatePlanPage?: boolean;
}

export default function AssetViewModal({
  asset,
  assetType,
  onClose,
  isEstatePlanPage,
}: AssetViewModalProps) {
  const { t, i18n } = useTranslation(['plan', 'common']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const countries = useGetCountries();

  if (isEmpty(asset)) return null;

  const isGlobalModal = useSelector((state: RootState) => !!state.modal.name);
  const isBeneficiary = useSelector(
    (state: RootState) => state.user.current.beneficiary
  );
  const assets: AssetsDTO = useSelector((state: RootState) => state.assets);

  const [fullAsset, setFullAsset] = React.useState(asset);
  const [currentAsset, setCurrentAsset] = React.useState(asset);
  const [viewHiddenFields, setViewHiddenFields] = React.useState({
    pin: false,
    puk: false,
    password: false,
  });

  const [showEditZVRProcess, setShowEditZVRProcess] = React.useState(false);
  const isSharedAssetsPage = location.pathname === '/shared';
  const isRegisteredZVRAsset =
    currentAsset?.zvr &&
    currentAsset?.assetTypeName === ASSET_TYPE_KEYS.HEALTH_CARE_PROXY;

  // for ongoing cost
  const onGoingCostsIncluded =
    !!currentAsset?.paymentMethod || !!currentAsset?.relatedAssetId;

  const allFetchedProviders: ProvidersDTO = useSelector(
    (state: RootState) => state.providers.current
  );

  const providers = LINK_ASSET_TYPE_KEYS.map(
    (assetTypeKey) => allFetchedProviders[assetTypeKey] || []
  ).flat();

  const assetsToLink = Object.values(assets)
    .flat()
    .filter((asset) =>
      LINK_ASSET_TYPE_KEYS.includes(asset.assetTypeName || '')
    );

  const relatedAsset = assetsToLink?.find(
    (linkAsset) => linkAsset.id === asset.relatedAssetId
  );
  const [linkedAsset, setLinkedAsset] = React.useState(
    relatedAsset || ({} as AssetDTO)
  );
  const displayedLinkedAssetValue = relatedAsset
    ? relatedAsset?.assetTypeName === ASSET_TYPE_KEYS.BANK_ACCOUNT
      ? t(`subtypes:${relatedAsset?.subtype}`)
      : relatedAsset.username
    : '';

  const displayedPaymentMethod = currentAsset?.paymentMethod
    ? paymentMethodOptions.find(
        (method) => method.value === asset.paymentMethod
      )?.label
    : '';

  const displayedPaymentFrequency = currentAsset?.paymentFrequency
    ? paymentFrequencyOptions.find(
        (frequency) => frequency.value === asset.paymentFrequency
      )?.label
    : '';
  // end, for ongoing cost

  React.useEffect(() => {
    if (!isBeneficiary && !isSharedAssetsPage) {
      getDetailAssetInfo();
    }

    if (relatedAsset) {
      getFormattedProviderRelatedAsset();
    }
  }, []);

  const getFormattedProviderRelatedAsset = async () => {
    const formattedLinkedAsset = await getFormattedProviderAssetByKey(
      relatedAsset || ({} as AssetDTO)
    );
    setLinkedAsset(formattedLinkedAsset);
  };

  const getDetailAssetInfo = async (isRelatedAsset = false) => {
    const related = !!isRelatedAsset && !!related2FAAsset?.id;
    const detailedAsset: any = await dispatch(
      getAsset(related ? related2FAAsset.id! : asset.id!, true, {
        returnError: true,
      })
    );
    const responseError = handleResponseError(detailedAsset);
    if (detailedAsset && !responseError) {
      setCurrentAsset(detailedAsset as AssetDTO);
      if (!isRelatedAsset) {
        setFullAsset(detailedAsset);
      }
    } else if (responseError === ERROR_CODES.NOT_ALLOWED) {
      dispatch(
        setModal({
          name: MODALS.PASSPHRASE,
          props: {
            cancelAction: related
              ? () => {
                  setLinkAssetView(false);
                }
              : onClose,
            successAction: () => getDetailAssetInfo(isRelatedAsset),
          },
        })
      );
    }
  };

  const onViewHiddenField = (fieldType) => {
    const hiddenFields = { ...viewHiddenFields };
    hiddenFields[fieldType] = !hiddenFields[fieldType];
    setViewHiddenFields(hiddenFields);
  };

  const planPage =
    find(
      PLAN_CATEGORY_PAGES,
      (page) => page.category === camelCase(currentAsset.category)
    ) || ({} as PlanCategoryPage);
  const planRoute = planPage.path || '';

  const dispatchAssetToEdit = async (asset: AssetDTO) => {
    await dispatch(setAssetToEdit(asset, assetType));
    await dispatch(setIsShowEditAssetForm(true));

    if (!isEstatePlanPage) {
      navigate(planRoute, {
        state: { assetId: asset.id, assetTypeId: assetType.id },
      });
    }
  };

  const onEditAsset = async () => {
    onClose();
    await dispatchAssetToEdit(currentAsset);
  };

  // handle display related 2FA asset
  const [related2FAAsset, setRelated2FAAsset] = React.useState({} as AssetDTO);
  const [linkedAssetView, setLinkAssetView] = React.useState(false);

  React.useEffect(() => {
    if (!isBeneficiary && !isSharedAssetsPage) {
      LINK_ASSET_TYPE_KEYS.forEach((key) => {
        if (!Object.keys(allFetchedProviders).includes(key)) {
          dispatch(getProvidersByType(key));
        }
      });
    }
  }, [allFetchedProviders]);

  React.useEffect(() => {
    if (asset?.related2FAAssetId) {
      const linkedAsset = assetsToLink.find(
        (item) => item.id === asset.related2FAAssetId
      );
      setRelated2FAAsset(linkedAsset || ({} as AssetDTO));
    }
  }, [asset]);

  const displayedFieldValue =
    related2FAAsset && providers.length
      ? find(providers, (item) => item.key === related2FAAsset.provider)
          ?.value || related2FAAsset.provider
      : '';

  const toggleLinked2FAAssetView = () => {
    if (linkedAssetView) {
      setCurrentAsset(fullAsset);
    } else {
      getDetailAssetInfo(true);
    }
    setLinkAssetView((prev) => !prev);
  };
  // related 2FA asset end!

  const getAssetField = (field, type) => {
    switch (type) {
      case F.EXPIRATION_DATE:
      case F.DATE:
        return dateHelper.convertDateFormat(
          currentAsset[type]!,
          config.format.uiDashboardDate
        );
      case F.PASSWORD:
      case F.PUK:
      case F.PIN:
        return (
          <span className="t-flex t-justify-between">
            <span>
              {viewHiddenFields[type]
                ? currentAsset[type]
                : '*'.repeat(currentAsset[type].length)}
            </span>
            <button type="button" onClick={() => onViewHiddenField(type)}>
              <IconEye
                className={
                  viewHiddenFields[type]
                    ? 't-text-alpha-600'
                    : 't-text-beta-200'
                }
              />
            </button>
          </span>
        );
      case F.TWO_AUTH:
        return `${currentAsset[type] ? t('common:yes') : t('common:no')}`;
      case F.SUBTYPE:
      case F.ACTION:
        return t(`subtypes:${currentAsset[type]}`);
      case F.CONTACTS:
        if (!currentAsset.contacts?.length) return null;
        return (
          <div key={field.order} className="t-mb-4">
            <p className="typo-zeta t-pb-2">
              {translateHelper.getAssignToLabel(assetType.name, t, i18n)}
            </p>
            <div className=" t-bg-beta-100 t-rounded-sm t-flex t-flex-wrap">
              {currentAsset.contacts.map((contact) => (
                <div key={contact.id} className="t-flex">
                  <p className="typo-delta t-bg-gamma-400 t-p-1 t-rounded-sm t-m-1">
                    {contact.name} {contact.surname}
                  </p>
                </div>
              ))}
            </div>
          </div>
        );
      case F.DOCUMENT:
        if (!currentAsset.documents?.length) return null;
        return (
          <div key={field.order} className="t-mb-4">
            <p className="typo-zeta t-pb-2">{t(field.label)}</p>
            {currentAsset.documents.map((document) =>
              isBeneficiary || isSharedAssetsPage ? (
                <PdfDownloadLinkAsBeneficiary
                  document={document}
                  key={document.id}
                />
              ) : (
                <PdfDownloadLink document={document} key={document.id} />
              )
            )}
          </div>
        );
      case F.ADDRESS:
        return getFullAddress(currentAsset[type], countries);
      case F.PROVIDER:
        return getAssetProviderValue(asset.provider, providers);
      case F.PERSON:
      case F.CREDIT_CARD_INFO:
      case F.FINANCIAL_DATA:
        return (
          <AssetViewModalCombinedField
            stringifiedField={currentAsset[type]}
            label={field.label}
          />
        );
      case F.EMAIL:
      case F.PHONE:
        return (
          <AssetViewModalMultipleInputs
            stringifiedField={currentAsset[type]}
            label={field.label}
            type={type}
          />
        );
      default:
        return currentAsset[type];
    }
  };

  const getFinancialFields = () => {
    if (!currentAsset?.financialData) return '';
    const {
      financialValue = '',
      estimated = false,
      estimatedDate = '',
    } = JSON.parse(currentAsset?.financialData || '{}');
    const formattedValue = financialValue
      ? getNumberWithDots(getNumber(financialValue).toFixed(2))
      : '';
    return (
      <div className="t-mb-4" key={`${financialValue} ${estimatedDate}`}>
        {financialValue && (
          <>
            <p className="typo-zeta t-pb-2">
              {t('plan:financial_data_input_label')}
            </p>
            <p className="typo-delta t-p-2 t-bg-beta-100 t-rounded-sm">
              <span>{formattedValue} €</span>
              <span className="t-lowercase">
                {estimated
                  ? ` (${t('plan:financial_data_estimated_checkbox_label')})`
                  : ''}
              </span>
            </p>
          </>
        )}

        {estimatedDate && (
          <>
            <p className="typo-zeta t-pb-2 t-mt-3.5">
              {t('plan:financial_data_estimated_date_label')}
            </p>
            <p className="typo-delta t-p-2 t-bg-beta-100 t-rounded-sm">
              {dateHelper.convertDateFormat(
                estimatedDate,
                config.format.uiDashboardDate
              )}
            </p>
          </>
        )}
      </div>
    );
  };

  const handleShowEditZVRProcess = () => {
    setShowEditZVRProcess(true);
  };

  const handleEditZVRProcessClose = () => {
    setShowEditZVRProcess(false);
  };

  return (
    <>
      {showEditZVRProcess && (
        <EditZVRProcess
          onCancelProcess={handleEditZVRProcessClose}
          isManageRegistration
        />
      )}
      {!isGlobalModal && !showEditZVRProcess && (
        <Modal show onHide={onClose} hasCloseCross dataTestid="AssetViewModal">
          {!!linkedAssetView && (
            <Button
              category="secondary"
              className="t-mb-4"
              onClick={toggleLinked2FAAssetView}
            >
              {t('common:back')}
            </Button>
          )}
          {isRegisteredZVRAsset ? (
            <div className="t-flex t-flex-col t-items-start sm:t-flex-row sm:t-items-center t-mb-6">
              <p className="typo-kappa t-text-alpha-600 t-bg-alpha-100 t-pt-1.5 t-pb-1 t-px-1 t-rounded-sm t-uppercase">
                {t('zvrRegistration:officially_registered')}
              </p>
              <ZvrLogo
                className="t-mt-2 sm:t-mt-0 sm:t-ml-4"
                style={{ maxWidth: '232px' }}
              />
            </div>
          ) : (
            ''
          )}
          <div className="t-flex t-flex-col">
            <h1 className="typo-alpha t-mb-6">
              {t(`${currentAsset.assetTypeLabel}`)}
            </h1>
            {assetType?.structure.map((field) => {
              const type = field.type as F;
              if (
                currentAsset[type] &&
                ![F.PERSON, F.CREDIT_CARD_INFO, F.EMAIL, F.PHONE].includes(type)
              ) {
                if (type === F.FINANCIAL_DATA) {
                  return getFinancialFields();
                }

                return (
                  <div key={`${field.order} ${type}`} className="t-mb-4">
                    <p className="typo-zeta t-pb-2">
                      {assetType?.name === PROVIDER_CONSTANT_TYPES.PHONE &&
                      type === F.PASSWORD
                        ? t('common:unlock_passcode_label')
                        : t(field.label)}
                    </p>
                    <div className="typo-delta t-p-2 t-bg-beta-100 t-rounded-sm">
                      {getAssetField(field, type)}
                    </div>
                    {type === F.TWO_AUTH &&
                      related2FAAsset?.id &&
                      !!displayedFieldValue &&
                      !isSharedAssetsPage && (
                        <div className="t-pb-4 t-pt-2">
                          <p className="typo-epsilon t-text-beta-500">
                            {t('plan:2fa_linked_asset_field_title')}
                          </p>
                          <div className="t-flex t-items-center t-justify-between t-py-3 t-pl-5 t-pr-2 t-border t-border-solid t-border-beta-200 t-rounded t-mt-1">
                            <div className="t-flex t-items-center">
                              <h2 className="typo-beta">
                                {displayedFieldValue}
                              </h2>
                            </div>
                            <button
                              className="t-text-alpha-600 t-outline-none t-uppercase"
                              onClick={toggleLinked2FAAssetView}
                            >
                              {t('common:view')}
                            </button>
                          </div>
                        </div>
                      )}
                  </div>
                );
              }

              if (
                [
                  F.CONTACTS,
                  F.DOCUMENT,
                  F.PERSON,
                  F.CREDIT_CARD_INFO,
                  F.EMAIL,
                  F.PHONE,
                ].includes(type)
              ) {
                return getAssetField(field, type);
              }
              return '';
            })}

            {onGoingCostsIncluded && (
              <div>
                <p className="typo-epsilon t-text-beta-500 t-mb-2">
                  {t('plan:on_going_costs_component_payment_method')}
                </p>
                {asset.relatedAssetId && (
                  <div className="t-flex t-items-center t-py-3 t-pl-5 t-pr-2 t-border t-border-solid t-border-beta-200 t-rounded">
                    <h2 className="typo-beta">{linkedAsset.provider}</h2>
                    <p className="typo-delta t-ml-3">
                      {displayedLinkedAssetValue}
                    </p>
                  </div>
                )}

                {!currentAsset.relatedAssetId && currentAsset.paymentMethod && (
                  <p className="typo-delta t-p-2 t-bg-beta-100 t-rounded-sm">
                    {displayedPaymentMethod}
                  </p>
                )}

                {currentAsset?.costAmount && (
                  <>
                    <p className="typo-epsilon t-mt-4 t-text-beta-500 t-mb-2">
                      {t('plan:on_going_costs_cost_amount_input_label')}
                    </p>
                    <p className="typo-delta t-p-2 t-bg-beta-100 t-rounded-sm">
                      € {currentAsset.costAmount}
                    </p>
                  </>
                )}

                {currentAsset?.paymentFrequency && (
                  <>
                    <p className="typo-epsilon t-mt-4 t-text-beta-500 t-mb-2">
                      {t('plan:on_going_costs_component_frequency_label')}
                    </p>
                    <p className="typo-delta t-p-2 t-bg-beta-100 t-rounded-sm">
                      {displayedPaymentFrequency}
                    </p>
                  </>
                )}
              </div>
            )}

            {!isBeneficiary && !isSharedAssetsPage && (
              <div className="t-flex t-flex-col t-items-end sm:t-flex-row sm:t-items-center sm:t-justify-end t-mt-3 xs:t-mt-6 t-mb-16 md:t-mb-0">
                {isRegisteredZVRAsset ? (
                  <Button
                    category="secondary"
                    className="t-mb-3 sm:t-mb-0"
                    onClick={handleShowEditZVRProcess}
                  >
                    {t('zvrRegistration:manage_registration_button')}
                  </Button>
                ) : (
                  ''
                )}
                {!linkedAssetView && (
                  <Button className="t-ml-2" onClick={onEditAsset}>
                    {t('common:edit')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
}
