import produce from 'immer';
import initialState from 'store/reducers/initialState';

import {
  SET_IS_SHOW_EDIT_ASSET_FORM,
  SET_ASSET_TO_EDIT,
  SET_IS_SHOW_SUBSCRIPTION_WIDGET,
  TOGGLE_ACCOUNT_PANEL,
} from 'store/types/layoutActionTypes';

const layoutReducer = (
  state = initialState.layout,
  { type, payload }: ReducerAction
) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_IS_SHOW_EDIT_ASSET_FORM: {
        draft.assetEdit = {
          ...state.assetEdit,
          isShowEditForm: payload.isShowEditForm,
        };
        return draft;
      }
      case SET_ASSET_TO_EDIT: {
        const { currentAsset, currentAssetType } = payload;
        draft.assetEdit = {
          ...state.assetEdit,
          ...(!!currentAssetType ? { currentAssetType } : {}),
          ...(!!currentAsset ? { currentAsset } : {}),
        };
        return draft;
      }

      case SET_IS_SHOW_SUBSCRIPTION_WIDGET: {
        draft.isShowSubscriptionWidget = payload.isShowSubscriptionWidget;
        return draft;
      }
      case TOGGLE_ACCOUNT_PANEL: {
        draft.isAccountPanelOpen = !draft.isAccountPanelOpen;
        return draft;
      }
      default:
        return draft;
    }
  });

export default layoutReducer;
