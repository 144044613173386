import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';

import { useTranslation } from 'modules/common/helpers';
import usePortalUrl from 'modules/subscription/utils/usePortalUrl';

import { ManageSubscriptionButton } from 'modules/subscription/components';

import { USER_PLAN, PAYMENT_STATUS } from 'modules/common/constants/enums';

import { ReactComponent as IconCheck } from 'images/icon-pricing-check.svg';
import { ReactComponent as ChevronIcon } from 'images/icon-bold-chevron-up.svg';

interface PriceSectionProps {
  paymentStatus: PAYMENT_STATUS;
}

const PricingSection = ({ paymentStatus }: PriceSectionProps) => {
  const { t } = useTranslation(['profile', 'subscription']);

  const [selectedItem, setSelectedItem] = useState(false);
  const portalUrl = usePortalUrl();

  const currentPlan: USER_PLAN = USER_PLAN.PROFESSIONAL;
  const canceledSubscription = paymentStatus === PAYMENT_STATUS.CANCELED;
  const planTitle: string =
    paymentStatus === PAYMENT_STATUS.TRIAL
      ? t(`subscription:plan_${paymentStatus.toLowerCase()}`)
      : canceledSubscription
      ? t(`subscription:plan_canceled`).toLowerCase()
      : t(`subscription:plan_${currentPlan.toLowerCase()}`);

  const planDescriptionItems = t('profile:plan_description_items', {
    returnObjects: true,
  }) as any as string[];

  const toggleShowPricing = () => {
    setSelectedItem((prevState) => !prevState);
  };

  return (
    <div data-testid="pricing-section">
      <h2 className="Typography Typography--subTitle u-weight-600 u-pb-10">
        <span className="Profile-title">{t('profile:pricing_title')}</span>
      </h2>

      <div className="t-shadow-lg t-rounded t-pt-5 t-pl-6 t-pb-6 t-pr-7 t-bg-gamma-400 ">
        <h2 className="Typography Typography--subTitle u-weight-400 u-pb-10">
          {t('profile:pricing_current_plan')}: {planTitle}
        </h2>

        {currentPlan === USER_PLAN.PROFESSIONAL && (
          <Accordion className="Accordion" onSelect={toggleShowPricing}>
            <Accordion.Item eventKey="planDescription">
              <Accordion.Button
                as="div"
                className={`${
                  selectedItem ? 't-pb-7' : ''
                } t-text-alpha-600 t-cursor-pointer t-flex t-items-center`}
              >
                <span className="t-mr-2.5">
                  {t('profile:pricing_plan_description_toggle')}
                </span>
                <ChevronIcon
                  role="presentation"
                  className={`${
                    selectedItem ? '' : 'PricingAccordion--chevronDown'
                  } t-text-alpha-600`}
                />
              </Accordion.Button>

              <Accordion.Collapse eventKey="planDescription">
                <div data-testid="planDescription">
                  {Array.isArray(planDescriptionItems) &&
                    planDescriptionItems?.map((item) => (
                      <div key={item} className="t-pb-5">
                        <div className="t-flex t-items-start">
                          <IconCheck className="t-text-alpha-600 t-mr-2.5 t-mt-1" />

                          <p className="typo-epsilon">{item}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </Accordion.Collapse>
            </Accordion.Item>
          </Accordion>
        )}

        {portalUrl && currentPlan !== USER_PLAN.PROFESSIONAL && (
          <a
            href={portalUrl}
            className="button t-bg-delta-700 hover:t-bg-delta-900 hover:t-shadow-none t-text-gamma-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('profile:pricing_plan_upgrade')}
          </a>
        )}
      </div>

      {portalUrl && (
        <div className="t-flex t-pt-5" data-testid="ManageSubscriptionButton">
          <ManageSubscriptionButton />
        </div>
      )}
    </div>
  );
};

export default PricingSection;
