import React from 'react';
import { LANGUAGES, LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';

interface TextWithHyphenSpanProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

const TextWithHyphenSpan = ({
  children,
  className,
}: TextWithHyphenSpanProps) => {
  const browserLang =
    localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE) || LANGUAGES.DE;
  return (
    <span className={className} lang={browserLang} style={{ hyphens: 'auto' }}>
      {children}
    </span>
  );
};

export default TextWithHyphenSpan;
