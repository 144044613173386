import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { camelCase } from 'lodash';

import { RootState } from 'store/reducers';
import { downloadDocument } from 'store/actions/assetActions';
import { useTranslation } from 'modules/common/helpers';

import {
  EditZVRProcess,
  RegistrationZVRProcess,
  RevokeHCPRegistrationModal,
} from 'modules/zvr/components';
import { Button } from 'modules/common/components';

import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';

import { ReactComponent as RevokeIcon } from 'images/icon-person-revoke.svg';

interface ZvrButtonsProps {
  asset: AssetDTO;
  type: string;
}

export default function ZvrButtons({ asset, type }: ZvrButtonsProps) {
  const { t } = useTranslation(['plan']);
  const dispatch = useDispatch();

  const assistantProgress = useSelector(
    (state: RootState) => state.assistant.progress
  );
  const [openEditZVRProcess, setOpenEditZVRProcess] = React.useState(false);
  const [openRevokeRegistrationModal, setOpenRevokeRegistrationModal] =
    React.useState(false);
  const [openRegistrationProcess, setOpenRegistrationProcess] =
    React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const isRegistered = asset.zvr === 1;

  const handleRegister = (event) => {
    event.stopPropagation();
    setOpenRegistrationProcess(true);
  };

  const handleUpdateTrustedPersons = () => {
    setOpenEditZVRProcess(true);
  };

  const handleRevoke = () => {
    setOpenRevokeRegistrationModal(true);
  };
  const handleEditZVRProcessClose = () => {
    setOpenEditZVRProcess(false);
  };

  const handleRevokeRegistrationModalClose = () => {
    setOpenRevokeRegistrationModal(false);
  };

  const handleCloseRegisterProcess = () => {
    setOpenRegistrationProcess(false);
  };

  const handleDocumentDownload = async () => {
    setIsDownloading(true);
    if (asset?.documents.length) {
      await dispatch(downloadDocument(asset?.documents[0]));
    }
    setIsDownloading(false);
  };

  const questionnaire = camelCase(type) as QUESTIONNAIRE_KEYS;
  const isPD = questionnaire === QUESTIONNAIRE_KEYS.PATIENT_DECREE;

  const registrationAllowed =
    assistantProgress[
      isPD
        ? QUESTIONNAIRE_KEYS.PATIENT_DECREE
        : QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY
    ]?.status === 1;

  const getChildren = () => {
    // if the document is registered -> show ZVR HCP card with Edit and Revoke features
    if (isRegistered) {
      return (
        <>
          {!isPD && (
            <Button
              className={`t-mx-auto t-w-full t-my-4`}
              onClick={handleUpdateTrustedPersons}
            >
              {t('zvrRegistration:hcp_card_update_btn')}
            </Button>
          )}
          <button
            className={`button t-mx-auto t-w-full t-text-zeta-600 t-bg-eta-100 ${
              isPD ? 't-my-4' : 't-mb-4'
            }`}
            onClick={handleRevoke}
          >
            <RevokeIcon className="t-mr-2" />
            {t('zvrRegistration:hcp_card_revoke_btn')}
          </button>
        </>
      );
      // HCP exist but not registered ->  open register modal
    } else if (registrationAllowed) {
      return (
        <>
          <Button
            className={`t-mx-auto t-w-full t-my-4`}
            onClick={handleRegister}
          >
            {t('zvrRegistration:hcp_card_register_btn')}
          </Button>
        </>
      );
    }
  };

  const children = getChildren();

  return (
    <>
      {openEditZVRProcess && (
        <EditZVRProcess onCancelProcess={handleEditZVRProcessClose} />
      )}
      {openRevokeRegistrationModal && (
        <RevokeHCPRegistrationModal
          onClose={handleRevokeRegistrationModalClose}
          questionnaire={questionnaire}
        />
      )}
      {openRegistrationProcess && (
        <RegistrationZVRProcess
          onCancelProcess={handleCloseRegisterProcess}
          handleDocumentDownload={handleDocumentDownload}
          downloading={isDownloading}
          questionnaire={questionnaire}
          isFromLegacyPlan
        />
      )}
      {children}
    </>
  );
}
