import React from 'react';
import { format, subDays } from 'date-fns';
import Select from 'react-select';

import { dateHelper, useTranslation } from 'modules/common/helpers';

import { Modal, DatePicker, Button } from 'modules/common/components';

import { LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';
import { ReactComponent as ReminderImg } from 'images/icon-reminder.svg';

interface ReminderModalProps {
  quitQuestionnaire: (string) => void;
  continueQuestionnaire: () => void;
  questionnaire: QUESTIONNAIRE_KEYS;
}

const daysInMonth = dateHelper.getDaysInMonth();
const twoDays = dateHelper.getFutureDate(2);
const oneWeek = dateHelper.getFutureDate(7);
const twoWeeks = dateHelper.getFutureDate(14);
const oneMonth = dateHelper.getFutureDate(daysInMonth);

export default function ReminderModal({
  quitQuestionnaire,
  continueQuestionnaire,
  questionnaire,
}: ReminderModalProps) {
  const { t } = useTranslation(['assistant']);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [reminderToSubmit, setReminderToSubmit] = React.useState('notNow');

  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value === 'custom') {
      handleReminderToggle();
    } else {
      setReminderToSubmit(selectedOption.value);
    }
  };

  const handleReminderSubmit = () => {
    if (reminderToSubmit === 'notNow') {
      const tomorrow = dateHelper.getFutureDate(1);
      const currentLocalStorage = localStorage.getItem(
        LOCAL_STORAGE_VARS.SNOOZE_REMIND_QUESTIONNAIRE
      );
      const savedData = currentLocalStorage
        ? { ...JSON.parse(currentLocalStorage), [questionnaire]: tomorrow }
        : { [questionnaire]: tomorrow };
      localStorage.setItem(
        LOCAL_STORAGE_VARS.SNOOZE_REMIND_QUESTIONNAIRE,
        JSON.stringify(savedData)
      );
      quitQuestionnaire('');
    } else {
      quitQuestionnaire(reminderToSubmit);
    }
  };

  const handleReminderChange = (val) => {
    const formattedVal: string = format(val, 'MM-dd-yyyy');
    const formattedDate = format(
      new Date(formattedVal?.replace(/-/g, '/')),
      'yyyy-MM-dd'
    );
    setReminderToSubmit(formattedDate);
  };

  const handleReminderToggle = () => {
    setShowCalendar((prevState) => !prevState);
  };

  const makeReminderOptions = () => {
    return [
      { value: 'notNow', label: t('assistant:reminder_option_not_now') },
      { value: twoDays, label: t('assistant:reminder_option_two_days') },
      { value: oneWeek, label: t('assistant:reminder_option_week') },
      { value: twoWeeks, label: t('assistant:reminder_option_two_weeks') },
      { value: oneMonth, label: t('assistant:reminder_option_month') },
      { value: 'custom', label: t('assistant:reminder_option_custom') },
    ];
  };

  const reminderOptions = makeReminderOptions();

  const customDateValue =
    reminderToSubmit && showCalendar && reminderToSubmit !== 'notNow'
      ? reminderToSubmit
      : new Date();

  return (
    <Modal show onHide={continueQuestionnaire}>
      <div className="t-flex t-justify-center">
        <ReminderImg className="t-h-44" />
      </div>
      <h1 className="typo-alpha t-my-6">
        {t('assistant:reminder_modal_title')}
      </h1>
      <p className="typo-delta t-mb-2.5">
        {t('assistant:reminder_modal_description')}
      </p>
      <div className="t-flex t-items-baseline t-flex-wrap">
        <p className="typo-delta t-mb-2.5 t-mr-2">
          {t('assistant:reminder_modal_select_label')}
        </p>
        <Select
          id="reminder"
          name="reminder"
          options={reminderOptions}
          defaultValue={reminderOptions[0]}
          onChange={handleSelectChange}
          className="Select t-w-1/2 xl:t-w-1/3"
          classNamePrefix="Select"
        />
      </div>
      {showCalendar && (
        <DatePicker
          date={customDateValue}
          minDate={subDays(new Date(), -1)}
          onSubmit={handleReminderSubmit}
          onChange={(val) => handleReminderChange(val)}
          onClose={handleReminderToggle}
          disabled={!reminderToSubmit}
          showTrash={false}
        />
      )}
      <div className="t-flex t-justify-end t-mt-9 t-mb-14 sm:t-mb-0">
        <Button
          category="secondary"
          onClick={continueQuestionnaire}
          className="t-mr-2.5"
        >
          {t('assistant:reminder_modal_btn_secondary')}
        </Button>
        <Button onClick={handleReminderSubmit}>
          {t('assistant:reminder_modal_btn_primary')}
        </Button>
      </div>
    </Modal>
  );
}
