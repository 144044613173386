import React from 'react';
import Carousel from 'react-elastic-carousel';
import 'modules/common/components/Carousel/Carousel.scss';
import { ArrowButton } from 'modules/elements/components';

interface CarouselProps {
  children: React.ReactNode | React.ReactNode[];
  itemsToShow?: number;
  breakPoints?: Record<
    | 'width'
    | 'itemsToShow'
    | 'itemsToScroll'
    | 'initialActiveIndex'
    | 'pagination',
    number | any
  >[];
  initialActiveIndex?: number;
}

export default function NinebarcCarousel({
  children,
  itemsToShow,
  breakPoints,
  initialActiveIndex,
}: CarouselProps) {
  const defaultArrow = ({ type, onClick, isEdge }) => {
    const isPrev = type === 'PREV';
    return (
      <ArrowButton
        onClick={onClick}
        disabled={isEdge}
        isRight={!isPrev}
        extraClass="carousel-arrow t-absolute t-z-10 t-self-center"
      />
    );
  };

  return (
    <Carousel
      isRTL={false}
      renderArrow={defaultArrow}
      initialActiveIndex={initialActiveIndex}
      itemsToShow={itemsToShow}
      breakPoints={breakPoints}
    >
      {children}
    </Carousel>
  );
}
