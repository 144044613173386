import React from 'react';
import DOMPurify from 'dompurify';

import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';

const ImprintPage = () => {
  const { t } = useTranslation(['imprint']);

  return (
    <Row className="t-h-100">
      <Col
        md={{ span: 12 }}
        xl={{ span: 10, offset: 2 }}
        style={{ paddingRight: 0 }}
      >
        <div
          className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7"
          data-testid="ImprintPage"
        >
          <h1 className="Typography Typography--title u-weight-600 u-pb-30">
            {t('imprint:page_title')}
          </h1>
          <p className="Typography Typography--subTitle u-weight-600 u-pb-25">
            {t('imprint:according_title')}
          </p>
          <p className="Typography Typography--main u-pb-25">
            {t('imprint:according_description')}
          </p>

          <p className="Typography Typography--subTitle u-weight-600">
            {t('imprint:represented_by_title')}
          </p>
          <p className="Typography Typography--main u-pb-25">
            {t('imprint:represented_by_description')}
          </p>

          <p className="Typography Typography--subTitle u-weight-600">
            {t('imprint:contact_title')}
          </p>
          <p className="Typography Typography--main u-pb-25">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(t('imprint:contact_description')),
              }}
            />
          </p>

          <p className="Typography Typography--subTitle u-weight-600">
            {t('imprint:commercial_register_number_title')}
          </p>
          <p className="Typography Typography--main u-pb-25">
            {t('imprint:commercial_register_number_description')}
          </p>

          <p className="Typography Typography--subTitle u-weight-600">
            {t('imprint:eu_dispute_settlement_title')}
          </p>
          <p className="Typography Typography--main u-pb-25">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  t('imprint:eu_dispute_settlement_description')
                ),
              }}
            />
          </p>

          <p className="Typography Typography--subTitle u-weight-600">
            {t('imprint:Consumer_dispute_settlement_title')}
          </p>
          <p className="Typography Typography--main u-pb-25">
            {t('imprint:Consumer_dispute_settlement_description')}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default ImprintPage;
