import React from 'react';

import { Button, Modal } from 'modules/common/components';

import { releaseNotes } from 'modules/common/constants';
import { hideRelease } from 'store/actions/userActions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ReleaseNoteModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'releaseNotes']);

  const { title, description, image, refreshButtonText, redirectButtonText } =
    releaseNotes[0];

  const onRefresh = async () => {
    await dispatch(hideRelease());
    window.location.reload();
  };

  return (
    <Modal show={true}>
      {!!image && (
        <div className="t-mb-8 t-flex t-justify-center">
          <img
            src={image}
            className="t-max-w-64"
            alt="Ninebarc's Release Notes"
          />
        </div>
      )}

      <h1 className="typo-alpha">{t(title)}</h1>

      {!!description && <p className="typo-epsilon t-mt-4">{t(description)}</p>}

      <div className="t-flex t-justify-end t-items-end t-mt-8 t-flex-col sm:t-flex-row">
        {!!redirectButtonText && (
          <div>
            <a
              href="https://ninebarc.de/release-notes"
              className="button-secondary t-mr-0 t-py-2 t-mb-3 sm:t-mb-0 sm:t-mr-3"
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              {t(redirectButtonText)}
            </a>
          </div>
        )}

        {!!refreshButtonText && (
          <Button className="t-py-2" onClick={onRefresh}>
            {t(refreshButtonText)}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ReleaseNoteModal;
