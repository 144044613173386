import React from 'react';
import { useDispatch } from 'react-redux';
import { find } from 'lodash';

import { revokeContactFromZVRDocument } from 'store/actions/zvrActions';
import { setModal } from 'store/actions/modalActions';
import {
  useGetCountries,
  dateHelper,
  config,
  getFullName,
  useTranslation,
} from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';
import {
  HowDoesItWorkModal,
  RevokeHCPRegistrationModal,
} from 'modules/zvr/components';

import { ZVR_EDIT_STEPS } from 'modules/zvr/constants';
import { MODALS } from 'modules/common/constants';

import { ReactComponent as IconRevoke } from 'images/icon-person-revoke.svg';
import { ReactComponent as IconEdit } from 'images/icon-edit.svg';
import { ReactComponent as IconQuestionCircle } from 'images/icon-question-with-circle.svg';
import { ReactComponent as IconPlus } from 'images/icon-plus.svg';
import { ReactComponent as RevokeIcon } from 'images/icon-person-revoke.svg';

interface TrustedPersonsOverviewModalProps {
  handleNextStep: (step: number) => void;
  setEditContact: (contact: ContactDTO) => void;
  onCancelProcess: () => void;
  addedContacts: ContactDTO[];
  isManageRegistration?: boolean;
}

export default function TrustedPersonsOverviewModal({
  handleNextStep,
  setEditContact,
  onCancelProcess,
  addedContacts,
  isManageRegistration,
}: TrustedPersonsOverviewModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['zvrRegistration']);
  const countries = useGetCountries();

  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
  const [revokePersonId, setRevokePersonId] = React.useState('');
  const [openRevokeRegistrationModal, setOpenRevokeRegistrationModal] =
    React.useState(false);

  React.useEffect(() => {
    if (revokePersonId) {
      handleOpenRevokeConfirmModal();
    }
  }, [revokePersonId]);

  const handleOpenRevokeConfirmModal = () => {
    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: t('zvrRegistration:delete_trusted_person_title'),
          description: t('zvrRegistration:delete_trusted_person_description'),
          primaryButtonText: t(
            'zvrRegistration:delete_trusted_person_revoke_button'
          ),
          successAsyncAction: async () => {
            await handleRevokePerson();
          },
          cancelAction: handleCloseDeletePersonModal,
        },
      })
    );
  };

  const handleEditContact = (contact: ContactDTO) => {
    setEditContact(contact);
    handleNextStep(ZVR_EDIT_STEPS.STEP_EDIT_REGISTERED_TRUSTED_PERSON);
  };

  const handleRevokePerson = async () => {
    await dispatch(revokeContactFromZVRDocument(revokePersonId));
    setRevokePersonId('');
  };

  const getContactName = (contact: ContactDTO) => {
    return getFullName(contact);
  };

  const getCountryLabel = (countryCode: string) =>
    find(countries, (item) => item.value === countryCode)?.label;

  const handleCloseDeletePersonModal = () => {
    setRevokePersonId('');
  };

  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  const handleRevoke = () => {
    setOpenRevokeRegistrationModal(true);
  };

  const handleRevokeRegistrationModalClose = () => {
    setOpenRevokeRegistrationModal(false);
  };

  return (
    <>
      {openRevokeRegistrationModal && (
        <RevokeHCPRegistrationModal
          onClose={handleRevokeRegistrationModalClose}
          onRevokeSuccess={onCancelProcess}
        />
      )}

      {isInfoModalOpen && <HowDoesItWorkModal onClose={handleInfoModalClose} />}
      {!isInfoModalOpen && !revokePersonId && !openRevokeRegistrationModal && (
        <Modal
          show
          onHide={onCancelProcess}
          dataTestid="TrustedPersonsOverviewModal"
        >
          <div className="t-flex t-justify-between t-items-center">
            <h1 className="typo-alpha">
              {isManageRegistration
                ? t('zvrRegistration:manage_registration_title')
                : t('zvrRegistration:trusted_persons_overview_title')}
            </h1>
            <Button
              category="ghost"
              className="t-items-center"
              onClick={() =>
                handleNextStep(ZVR_EDIT_STEPS.STEP_ADD_TRUSTED_PERSON)
              }
            >
              <span>
                <IconPlus
                  role="presentation"
                  className="t-inline t-text-alpha-600 t-mr-1 button-icon"
                />
              </span>

              <span className="xs:t-hidden sm:t-inline t-whitespace-nowrap">
                {t(
                  'zvrRegistration:trusted_persons_overview_add_person_button'
                )}
              </span>
            </Button>
          </div>

          <p className="typo-delta t-rounded t-bg-delta-100 t-p-3 t-text-delta-500 t-mt-5">
            {t('zvrRegistration:trusted_persons_overview_description')}
          </p>

          <div className="t-mt-4">
            {addedContacts.length ? (
              addedContacts.map((contact) => {
                return (
                  <div key={contact.id} className="card t-p-6 t-mb-4">
                    <h2 className="typo-beta t-mb-1">
                      {getContactName(contact)}
                    </h2>
                    {!!contact.phone && (
                      <p className="typo-epsilon t-mb-1">+{contact.phone}</p>
                    )}
                    {!!contact.address && (
                      <p className="typo-epsilon t-mb-1">{`${
                        contact.address
                      }, ${contact.zip} ${contact.city}, ${getCountryLabel(
                        contact.country!
                      )}`}</p>
                    )}
                    <p className="typo-epsilon t-mb-1">{contact.email}</p>
                    <p className="typo-epsilon">
                      {dateHelper.convertDateFormat(
                        contact.birthday || '',
                        config.format.uiDate
                      )}
                    </p>

                    <div className="t-flex t-mt-4">
                      <button
                        type="button"
                        className="button t-bg-eta-100 t-text-zeta-600 t-flex t-items-center"
                        onClick={() => setRevokePersonId(contact.id)}
                      >
                        <IconRevoke className="t-mr-3" />
                        <span className="t-whitespace-nowrap">
                          {t(
                            'zvrRegistration:trusted_persons_overview_revoke_button'
                          )}
                        </span>
                      </button>
                      <Button
                        category="ghost"
                        className="t-whitespace-nowrap t-items-center t-ml-2"
                        onClick={() => handleEditContact(contact)}
                      >
                        <IconEdit className="t-mr-2" />
                        <span>{t('common:edit')}</span>
                      </Button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="t-flex t-flex-col t-items-center t-text-center t-py-10 t-bg-beta-50">
                <div className="t-w-11/12 sm:t-w-8/12">
                  <h1 className="typo-alpha t-mb-5">
                    {t(
                      'zvrRegistration:empty_overview_authorized_persons_label'
                    )}
                  </h1>
                  <p className="typo-epsilon t-mb-8">
                    {t(
                      'zvrRegistration:empty_overview_authorized_persons_cta_text'
                    )}
                  </p>
                </div>
                <div>
                  <Button
                    className="t-items-center"
                    onClick={() =>
                      handleNextStep(ZVR_EDIT_STEPS.STEP_ADD_TRUSTED_PERSON)
                    }
                  >
                    <IconPlus className="t-inline t-text-gamma-400 t-mr-1 button-icon" />
                    <span className="xs:t-hidden sm:t-inline t-whitespace-nowrap">
                      {t(
                        'zvrRegistration:trusted_persons_overview_add_person_button'
                      )}
                    </span>
                  </Button>
                </div>
              </div>
            )}
          </div>

          <button
            onClick={() => setIsInfoModalOpen(true)}
            type="button"
            className="t-flex t-items-center t-w-full t-justify-end t-mt-8"
          >
            <IconQuestionCircle className="t-text-alpha-600 t-mr-2" />
            <h4 className="typo-eta t-text-alpha-600">
              {t('zvrRegistration:what_does_that_mean')}
            </h4>
          </button>

          <div className="t-flex t-flex-col t-items-end sm:t-flex-row sm:t-items-center sm:t-justify-end t-mt-8 t-mb-16 md:t-mb-0">
            {isManageRegistration && (
              <Button
                category="secondary"
                className="t-text-zeta-600 t-bg-eta-100"
                onClick={handleRevoke}
              >
                <RevokeIcon className="t-mr-2" />
                {t('zvrRegistration:revoke_zvr_registration_button')}
              </Button>
            )}
            <Button
              className="t-py-2 t-ml-4 t-mt-3 sm:t-mt-0"
              onClick={onCancelProcess}
            >
              {t('zvrRegistration:done')}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}
