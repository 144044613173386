import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { RootState } from 'store/reducers';
import {
  deleteContact,
  generateMultipleContactAccessDocument,
} from 'store/actions/contactActions';
import { setModal } from 'store/actions/modalActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import {
  highlightElement,
  isAllowedToken,
  reactToast,
  scrollToElementId,
  useTranslation,
} from 'modules/common/helpers';

import { Button, Modal, PostalServiceWrapper } from 'modules/common/components';
import { ContactCard, ContactOfCard } from 'modules/myContacts/components';
import { PostalContactFormWrapper } from 'modules/contact/components';
import { ProfileIncompleteProcess } from 'modules/dashboard/components';

import { NOTIFICATION_CODES as NC } from 'modules/notification/constants';
import { MODALS } from 'modules/common/constants';
import {
  LOCAL_STORAGE_VARS,
  POSTAL_TYPES,
  URL_PARAMS,
  URL_SEARCH_CODES,
} from 'modules/common/constants/enums';
import { REDIRECT_ACTIONS } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';

import { ReactComponent as IconPlus } from 'images/icon-plus.svg';
import { ReactComponent as IconInfo } from 'images/icon-info.svg';
import { ReactComponent as IconEmpty } from 'images/icon_empty.svg';

interface MyContactsProps {
  switchToMyFamilyTab: () => void;
}

function MyContacts({ switchToMyFamilyTab }: MyContactsProps) {
  const { t } = useTranslation(['common', 'mcontacts']);
  const location = useLocation();
  const dispatch = useDispatch();

  const previousRouteData = (location.state || {}) as Record<string, string>;

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const selectContactOfData: ContactOfDTO[] =
    useSelector((state: RootState) => state.contactOf.list) || [];
  const contactsData =
    useSelector((state: RootState) => state.contacts.list) || [];
  const dataOnRedirect = useSelector(
    (state: RootState) => state.temporaryField.value
  );

  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [contactToEdit, setContactToEdit] = useState({} as ContactDTO);

  const [showProfileForm, setShowProfileForm] = React.useState(false);
  const contactsWithNoDocument = contactsData.filter(
    (contact) => !contact.documentId && !!contact.gender && !!contact.birthday
  );
  const [isDocumentsGenerating, setIsDocumentsGenerating] = useState(false);

  // opens contact form if user comes from notification
  useEffect(() => {
    if (previousRouteData?.notificationCode === NC.ADD_BENEFICIARIES) {
      handleShowForm();
      scrollToElementId('beneForm');
    }
  }, [previousRouteData]);

  // useRef does not work on re-rendering or re-assigning ref to an element on map method
  // easiest way is to use getElementById on a setTimeout
  const [highlightedCardId, setHighlightedCardId] = React.useState('');
  useEffect(() => {
    if (
      dataOnRedirect?.actionType === REDIRECT_ACTIONS.GO_TO_CONTACT_CARD &&
      dataOnRedirect?.contactId &&
      contactsData.length
    ) {
      setHighlightedCardId(dataOnRedirect.contactId);
      scrollToElementId(dataOnRedirect.contactId);
      dispatch(temporaryFieldSaver(null));
      highlightElement.stop(() => {
        setHighlightedCardId('');
      });
    }
  }, [contactsData, dataOnRedirect]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (contactsWithNoDocument.length) {
      generateMissingDocuments(contactsWithNoDocument);
    } else {
      setIsDocumentsGenerating(false);
    }
  }, []);

  const generateMissingDocuments = (contactsWithoutDocument: ContactDTO[]) => {
    setIsDocumentsGenerating(true);
    if (!currentUser.passphraseOptOut && !isAllowedToken()) {
      reactToast.showWarning(
        t('mcontacts:contact_access_document_on_passphrase')
      );
      dispatch(
        setModal({
          name: MODALS.PASSPHRASE,
          props: {
            successAction: handlePassPhraseSuccess,
            cancelAction: handlePassPhraseCancel,
          },
        })
      );
    } else {
      reactToast.showWarning(t('mcontacts:contact_access_document_generating'));
      dispatch(generateMultipleContactAccessDocument(contactsWithoutDocument));
    }
  };

  const handleCloseAddForm = () => {
    setShowAddForm(false);
  };

  const handleShowForm = () => {
    if (!currentUser.profile) {
      setShowProfileForm(true);
      return undefined;
    }

    setShowAddForm(true);
  };

  const onEditContact = async (contactData: ContactDTO) => {
    setContactToEdit(contactData);
    handleCloseAddForm();
    setShowEditForm(true);
  };

  const handleCloseEditForm = () => {
    setShowEditForm(false);
  };

  const onDeleteContact = async (id: string, title: string) => {
    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: title,
          description: t('mcontacts:delete_contact_confirm_text'),
          primaryButtonText: t('common:confirm'),
          successAsyncAction: async () => {
            await dispatch(deleteContact(id));
          },
        },
      })
    );
  };

  const handleSuccessfulProfileUpdate = () => {
    setShowAddForm(true);
  };

  const handleHideProfileModal = () => {
    setShowProfileForm(false);
  };

  const handlePassPhraseSuccess = async () => {
    if (isDocumentsGenerating) {
      generateMissingDocuments(contactsWithNoDocument);
    }
  };

  const handlePassPhraseCancel = () => {
    setIsDocumentsGenerating(false);
  };

  // Postal service
  const [searchParams, setSearchParams] = useSearchParams();
  const postalPaymentStatus = searchParams.get(URL_PARAMS.STATUS);
  const isFromSuccessfulPostalPayment =
    postalPaymentStatus === URL_SEARCH_CODES.POSTAL_PAYMENT_SUCCESSFUL;

  const onRemovePostalParams = () => {
    searchParams.delete(URL_PARAMS.STATUS);
    searchParams.delete(URL_PARAMS.PAYMENT_TOKEN);
    searchParams.delete(URL_PARAMS.LETTER_ID);
    searchParams.delete(URL_PARAMS.CONTACT_ID);
    setSearchParams(searchParams);
  };

  // in case the user is back from the Stripe payment by clicking the browser "Back" button
  // We should open the postal service again. This is used to avoid the unpredicted behaviors of users
  const {
    contactId = '',
    contactBy = '',
    actionType = '',
  } = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_VARS.DATA_ON_REDIRECT) || `{}`
  );
  const isNotifyAction = actionType === REDIRECT_ACTIONS.NOTIFY_CONTACT;
  const contactToNotify =
    contactId && isNotifyAction && contactBy
      ? contactsData.find((contact) => contact.id === contactId)
      : {};

  React.useEffect(() => {
    if (
      contactToNotify?.id &&
      !isFromSuccessfulPostalPayment &&
      !contactToEdit?.id
    ) {
      setShowEditForm(true);
      setContactToEdit(contactToNotify);
    }
  }, [contactToNotify, isFromSuccessfulPostalPayment]);
  // end of rerendering the postal service

  return (
    <PostalServiceWrapper
      searchParams={searchParams}
      type={POSTAL_TYPES.CONTACT}
      onRemovePostalParams={onRemovePostalParams}
    >
      {showProfileForm && (
        <Modal show onHide={handleHideProfileModal} hasCloseCross>
          <ProfileIncompleteProcess
            isProfilePage={false}
            onClose={handleHideProfileModal}
            onSuccess={handleSuccessfulProfileUpdate}
            profile={currentUser}
            profileFormTitle={t(
              `mcontacts:profile_not_complete_contact_form_title`
            )}
          />
        </Modal>
      )}

      {(showEditForm || isFromSuccessfulPostalPayment) && (
        <PostalContactFormWrapper
          onClose={handleCloseEditForm}
          contactToEdit={contactToEdit}
          isSuccessfulPayment={isFromSuccessfulPostalPayment}
          onRemovePostalParams={onRemovePostalParams}
          isContactModal
          reloadPostal={!!contactToNotify?.id}
        />
      )}

      {currentUser.regular && (
        <>
          <div className="t-flex t-justify-between t-items-center t-pb-2.5">
            <h2 className="typo-alpha">
              <span className="t-mr-2.5">{t('mcontacts:contact_title')}</span>
              <IconInfo
                role="presentation"
                className="t-inline t-text-delta-700"
                data-tip={t('mcontacts:contact_tooltip')}
              />
            </h2>

            <Button category="secondary" onClick={handleShowForm}>
              <IconPlus
                role="presentation"
                className="t-inline t-text-alpha-600 t-mr-2"
              />
              <span className="xs:t-hidden sm:t-inline" id="beneForm">
                {t('common:add_new')}
              </span>
            </Button>
          </div>
          <p className="t-mb-10">{t('mcontacts:description')}</p>

          {showAddForm && (
            <>
              <div className="card u-pt-30 u-pr-30 u-pb-40 u-pl-30 u-bg-white">
                <PostalContactFormWrapper onClose={handleCloseAddForm} />
              </div>
              <div className="u-pb-10" />
            </>
          )}

          {contactsData &&
            contactsData.map((contactsListItem) => {
              const isHighlighted = highlightedCardId === contactsListItem.id;
              return (
                <div key={contactsListItem.id} id={contactsListItem.id}>
                  <ContactCard
                    contactData={contactsListItem}
                    onDelete={onDeleteContact}
                    onEdit={onEditContact}
                    isViewAssetsAllowed
                    isMyContactsPage
                    isDocumentsGenerating={isDocumentsGenerating}
                    switchToMyFamilyTab={switchToMyFamilyTab}
                    highlight={isHighlighted}
                  />
                </div>
              );
            })}

          {!contactsData.length && (
            <div>
              <div className="t-flex t-justify-center t-mt-4">
                <IconEmpty />
              </div>
              <p className="typo-epsilon t-text-beta-200 t-text-center t-mt-2.5">
                {t('mcontacts:empty_contact_text')}
              </p>
            </div>
          )}

          <div className="u-pb-40" />
        </>
      )}

      {selectContactOfData?.length > 0 && (
        <div className="t-mt-10">
          <h2 className="typo-beta t-mb-3">
            {t('mcontacts:contact_of_title')}
          </h2>
          {selectContactOfData?.map((contactOf) => (
            <ContactOfCard key={contactOf.id} contactOf={contactOf} />
          ))}
        </div>
      )}

      <div className="u-pb-40" />
    </PostalServiceWrapper>
  );
}

export default MyContacts;
