import React from 'react';

import { ContactCard } from 'modules/myContacts/components';

export interface HeirContactEndProps {
  heirs: ContactDTO[];
}

export default function HeirContactEnd({ heirs }: HeirContactEndProps) {
  return (
    <div>
      {heirs.map((heir) => (
        <ContactCard
          key={heir.id}
          contactData={heir}
          className="card t-py-3 t-px-0 xl:t-py-6 xl:t-px-7 xl:t-max-w-sm"
          isDropdownMenuHidden
        />
      ))}
    </div>
  );
}
