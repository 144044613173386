import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { checkDateInThePast, useTranslation } from 'modules/common/helpers';

import { CommonModal } from 'modules/common/components';
import { ManageSubscriptionButton } from 'modules/subscription/components';

import { PAYMENT_STATUS } from 'modules/common/constants/enums';

type SubscriptionRequiredModalProps = {
  onClose: () => void;
};

const SubscriptionRequiredModal = ({
  onClose,
}: SubscriptionRequiredModalProps) => {
  const { t } = useTranslation(['common', 'subscription']);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = React.useState(false);

  const currentUser = useSelector((state: RootState) => state.user.current);
  const isDateInThePast = checkDateInThePast(currentUser.subscriptionEnd);
  const titleText =
    currentUser.paymentStatus === PAYMENT_STATUS.TRIAL && isDateInThePast
      ? t('subscription:trial_has_ended')
      : t('common:modal_subscription_required_title');

  return (
    <>
      {isPaymentModalOpen && (
        <ManageSubscriptionButton
          isFromSubscriptionModal={true}
          extraClassName="t-hidden"
        />
      )}
      {!isPaymentModalOpen && (
        <CommonModal
          title={titleText}
          description={t('common:modal_subscription_required_description')}
          primaryButtonText={t('subscription:trial_period_upgrade_button')}
          secondaryButtonText={t('common:got_it')}
          onSuccess={() => {
            setIsPaymentModalOpen(true);
          }}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default SubscriptionRequiredModal;
