import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { endsWith } from 'lodash';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconDashboard } from 'images/icon-dashboard.svg';
import { ReactComponent as IconVault } from 'images/icon-vault.svg';
import { ReactComponent as IconBeneficiaries } from 'images/icon-beneficiaries.svg';
import { ReactComponent as IconAssistant } from 'images/icon-assistant.svg';
import { ReactComponent as IconProfile } from 'images/icon-profile.svg';
import { ReactComponent as IconFile } from 'images/icon-shared-file.svg';
import { ReactComponent as IconAgent } from 'images/icon-my-agent.svg';
interface PrimaryNavigationProps {
  user: UserProfileDTO;
  toggleMenu: () => void;
}

export default function PrimaryNavigation({
  user,
  toggleMenu,
}: PrimaryNavigationProps) {
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const curPath = location.pathname;

  const isBeneficiaryPage = endsWith(window.location.href, '/beneficiary');

  const profileMenuText =
    user.name && user.surname
      ? `${user.name.toUpperCase()} ${user.surname.charAt(0).toUpperCase()}.`
      : (t('my_profile') as string);

  const links = [
    // Beneficiary only
    {
      show: isBeneficiaryPage || user?.beneficiary,
      path: '/beneficiary',
      isActive: curPath === '/beneficiary',
      text: t('common:page_dashboard'),
      icon: (
        <IconDashboard
          className="SiteNavigation-linkIcon t-text-alpha-700"
          aria-labelledby="/beneficiary"
        />
      ),
    },
    // User
    {
      show: user.regular,
      path: '/dashboard',
      isActive: curPath === '/dashboard' || curPath === '/',
      text: t('common:page_dashboard'),
      icon: (
        <IconDashboard
          className="SiteNavigation-linkIcon t-text-alpha-700"
          aria-labelledby="/dashboard"
        />
      ),
    },
    {
      show: user.regular,
      path: '/plan',
      isActive: curPath.includes('/plan'),
      text: t('common:page_estate_plan'),
      icon: (
        <IconVault
          className="SiteNavigation-linkIcon t-text-alpha-700 t-left-3"
          aria-labelledby="/plan"
        />
      ),
    },
    {
      show: user.regular,
      path: '/assistant',
      isActive: curPath.includes('/assistant'),
      text: t('common:page_assistant'),
      icon: (
        <IconAssistant
          className="SiteNavigation-linkIcon t-text-alpha-700"
          aria-labelledby="/assistant"
        />
      ),
    },
    {
      show: user.regular,
      path: '/my-contacts',
      isActive: curPath === '/my-contacts',
      text: t('common:page_my_contacts'),
      icon: (
        <IconBeneficiaries
          className="SiteNavigation-linkIcon t-text-alpha-700"
          aria-labelledby="/my-contacts"
        />
      ),
    },
    // User relates to an agent
    {
      show: user.regular && !!user.agent,
      path: '/my-agent',
      isActive: curPath === '/my-agent',
      text: t('common:page_my-agent'),
      icon: (
        <IconAgent
          className="SiteNavigation-linkIcon t-text-alpha-700"
          aria-labelledby="/my-agent"
        />
      ),
    },
    {
      show: !isBeneficiaryPage && user.viewer,
      path: '/shared',
      isActive: curPath === '/shared',
      text: t('common:page_shared'),
      icon: (
        <IconFile
          className="SiteNavigation-linkIcon t-text-alpha-700"
          aria-labelledby="/shared"
        />
      ),
    },
    {
      show: !isBeneficiaryPage && !user?.beneficiary && !user.regular,
      path: '/profile',
      isActive: curPath === '/profile',
      text: profileMenuText,
      icon: (
        <IconProfile
          className="SiteNavigation-linkIcon t-text-alpha-700"
          aria-labelledby="/profile"
        />
      ),
    },
  ];

  return (
    <div data-tour="dashboard_navigationBar">
      {links.map(
        ({ path, isActive, icon, text, show }) =>
          show && (
            <li
              key={path}
              className={`SiteNavigation-listItem ${
                isActive ? 'isActive' : ''
              }`}
            >
              <Link
                id={path}
                to={path}
                className="SiteNavigation-listLink SiteNavigation-listLink--withPadding"
                onClick={toggleMenu}
              >
                {icon}
                {text}
              </Link>
            </li>
          )
      )}
    </div>
  );
}
