export const ZVR_REGISTRATION_STEPS = {
  STEP_REGISTRATION_CANCEL: 0,
  STEP_REGISTRATION: 1,
  STEP_NO_PATIENT_DECREE: 2,
  STEP_NO_HCP: 3,
  STEP_PROFILE_NOT_COMPLETE: 4,
  STEP_TRUSTED_PERSON: 5,
  STEP_PAYMENT_CHECKOUT: 6,
};

export const ZVR_EDIT_STEPS = {
  STEP_TRUSTED_OVERVIEW: 0,
  STEP_EDIT_REGISTERED_TRUSTED_PERSON: 1,
  STEP_DELETE_TRUSTED_PERSON_CONFIRM: 2,
  STEP_ADD_TRUSTED_PERSON: 3,
};
