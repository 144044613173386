import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { camelCase } from 'lodash';

import { RootState } from 'store/reducers';
import {
  setIsShowEditAssetForm,
  setAssetToEdit,
} from 'store/actions/layoutActions';
import { getAsset, deleteAsset } from 'store/actions/assetActions';
import { revokeEntireZVRDocument } from 'store/actions/zvrActions';
import { getProvidersByType } from 'store/actions/providerActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { setModal } from 'store/actions/modalActions';
import { getAssetName } from 'modules/asset/helpers';
import {
  getFullAddress,
  handleResponseError,
  isAllowedToken,
  useGetCountries,
  reactToast,
  useTranslation,
} from 'modules/common/helpers';
import { getProviderType } from 'modules/estatePlan/helpers';

import {
  DotsDropdown,
  TextWithHyphenSpan,
  ToastUndo,
} from 'modules/common/components';
import { AssetViewModal, ZvrButtons } from 'modules/asset/components';
import { AssetReminder } from 'modules/asset/components/AssetCard';

import {
  ASSET_CATEGORIES,
  ASSET_TYPE_KEYS,
  FIELD_TYPE_KEYS,
} from 'modules/estatePlan/constants';
import { ERROR_CODES, MODALS } from 'modules/common/constants';

import { ReactComponent as IconAvatar } from 'images/icon_filled_profile.svg';
import { ReactComponent as ZvrLogo } from 'images/logo_bundesnotarkammer.svg';

interface AssetCardProps {
  asset: AssetDTO;
  assetCategory: string;
  hideDots?: boolean;
  unassignAssetsFocused?: boolean;
  isNotEstatePlanPage?: boolean;
}

export default function AssetCard({
  asset,
  assetCategory,
  hideDots,
  unassignAssetsFocused,
  isNotEstatePlanPage,
}: AssetCardProps) {
  const category = camelCase(assetCategory);
  const dispatch = useDispatch();
  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const { t } = useTranslation(['plan', 'common']);
  const countries = useGetCountries();
  const assetTypes: AssetTypesDTO = useSelector(
    (state: RootState) => state.assetTypes
  );
  const categorizedAssetTypes: AssetTypeDTO[] = assetTypes[category];
  const assetType: AssetTypeDTO =
    categorizedAssetTypes?.find((type) => type.id === asset.assetTypeId) ||
    ({} as AssetTypeDTO);
  const assistantProgress = useSelector(
    (state: RootState) => state.assistant.progress
  );

  const temporaryField = useSelector(
    (state: RootState) => state.temporaryField?.value
  );
  const assetIdsToRemove =
    temporaryField?.type === 'AssetToRemove' ? temporaryField.assetIds : [];

  const [showAssetViewModal, setShowAssetViewModal] = React.useState(false);

  const providerType = getProviderType(assetType);
  const allFetchedProviders: ProvidersDTO = useSelector(
    (state: RootState) => state.providers.current
  );
  const providers: ProviderDTO[] = allFetchedProviders[providerType] || [];

  React.useEffect(() => {
    if (!!asset.provider && providerType && !providers.length) {
      dispatch(getProvidersByType(providerType));
    }
  }, []);

  const copiedAsset = {
    ...asset,
    address: asset?.address
      ? getFullAddress(asset?.address, countries)
      : undefined,
  };
  const formattedContacts =
    copiedAsset.contacts?.map(
      (contact: ContactDTO) => `${contact.name} ${contact.surname}`
    ) || [];
  const focused = unassignAssetsFocused && !formattedContacts.length;

  const isRegistered = asset.zvr === 1;
  const isArchived = asset.archived;
  const isZVRRegisteredWithOtherAsset = user.zvr && !isRegistered;
  const isAssetDocumentGeneratedWithNinebarc = !!asset.generated;
  const notAllowedToRegisterZVR = isRegistered
    ? false
    : assistantProgress[camelCase(asset.assetTypeName || '')]?.status !== 1;

  const showZVRDetails =
    isAssetDocumentGeneratedWithNinebarc &&
    !isZVRRegisteredWithOtherAsset &&
    !notAllowedToRegisterZVR;

  const displayedAsNormalAsset =
    !isAssetDocumentGeneratedWithNinebarc || !showZVRDetails;

  const assetName = getAssetName(assetType, copiedAsset, providers, t);

  const handleShowAssetToEditForm = async (assetToEdit) => {
    await dispatch(setAssetToEdit(assetToEdit, assetType));
    await dispatch(setIsShowEditAssetForm(true));
  };

  const onEditAsset = async () => {
    const assetFullData = (await dispatch(
      getAsset(asset.id || '', !isAllowedToken(), { returnError: true })
    )) as unknown as AssetDTO;
    const responseError = handleResponseError(assetFullData);

    if (!responseError) {
      await handleShowAssetToEditForm(assetFullData);
    } else if (responseError === ERROR_CODES.NOT_ALLOWED) {
      dispatch(
        setModal({
          name: MODALS.PASSPHRASE,
          props: {
            successAction: handlePassPhraseSuccess,
          },
        })
      );
    }
  };

  const onDeleteAsset = async (id: string, category: string) => {
    const camelizedCategory = camelCase(category);

    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: t('delete_asset_confirm_title', { assetName: assetName }),
          description: t('plan:delete_asset_confirm_text'),
          primaryButtonText: t('common:confirm'),
          successAction: () => {
            let deletionConfirmed = true;
            dispatch(
              temporaryFieldSaver({
                type: 'AssetToRemove',
                assetIds: [...assetIdsToRemove, id],
              })
            );

            const updatedAssetIdsToRemove = assetIdsToRemove.filter(
              (assetId) => assetId !== id
            );
            const updatedTempField = updatedAssetIdsToRemove.length
              ? {
                  type: 'AssetToRemove',
                  assetIds: updatedAssetIdsToRemove,
                }
              : null;

            reactToast.showInfo({
              text: (
                <ToastUndo
                  onUndo={() => {
                    deletionConfirmed = false;
                    return dispatch(temporaryFieldSaver(updatedTempField));
                  }}
                  text={t('plan:delete_asset_undo_text')}
                />
              ),
              options: {
                onClose: async () => {
                  if (deletionConfirmed) {
                    await dispatch(deleteAsset(id, camelizedCategory));
                    if (
                      asset.zvr &&
                      asset.assetTypeName === ASSET_TYPE_KEYS.HEALTH_CARE_PROXY
                    ) {
                      await dispatch(revokeEntireZVRDocument());
                    }
                    dispatch(temporaryFieldSaver(updatedTempField));
                  }
                },
              },
            });
          },
        },
      })
    );
  };

  const handleDelete = async () => {
    onDeleteAsset(asset.id || '', assetType.category);
  };

  const handlePassPhraseSuccess = async () => {
    const assetFullData = (await dispatch(
      getAsset(asset.id || '', false, { returnError: true })
    )) as unknown as AssetDTO;
    const responseError = handleResponseError(assetFullData);
    if (!responseError) {
      await handleShowAssetToEditForm(assetFullData);
    }
  };

  const zvrDocument =
    [
      ASSET_TYPE_KEYS.PATIENT_DECREE,
      ASSET_TYPE_KEYS.HEALTH_CARE_PROXY,
    ].includes(assetType.name) &&
    !displayedAsNormalAsset &&
    !!asset.generated;

  const handleAssetViewModalClose = () => {
    setShowAssetViewModal(false);
  };

  const openAssetViewModal = () => {
    setShowAssetViewModal(true);
  };

  const showReminderButton =
    !!assetType?.structure?.find(
      (field) => field.type === FIELD_TYPE_KEYS.PASSWORD
    ) && category === ASSET_CATEGORIES.DIGITAL;

  return (
    <>
      {showAssetViewModal && (
        <AssetViewModal
          asset={copiedAsset}
          assetType={assetType}
          onClose={handleAssetViewModalClose}
          isEstatePlanPage={!isNotEstatePlanPage}
        />
      )}
      <div
        className={`card t-h-full t-py-5 t-px-5 t-flex t-flex-col t-justify-between t-cursor-pointer ${
          focused ? 't-border t-border-solid t-border-epsilon-400' : ''
        }`}
        onClick={openAssetViewModal}
        data-testid="AssetCard"
      >
        <div>
          {!hideDots && (
            <div onClick={(e) => e.stopPropagation()}>
              <DotsDropdown onEdit={onEditAsset} onDelete={handleDelete} />
            </div>
          )}
          {showReminderButton && (
            <div
              onClick={(e) => e.stopPropagation()}
              className="t-absolute t-right-9 t-top-3"
            >
              <AssetReminder
                assetId={asset?.id || ''}
                reminder={asset?.reminder || ''}
              />
            </div>
          )}
          <div className="t-mr-7">
            {!zvrDocument && (
              <div className="t-flex t-mb-2">
                <div
                  className={`t-px-1 t-pt-2 t-pb-1.5 t-rounded-sm ${
                    isArchived ? 't-bg-beta-100' : 't-bg-delta-100'
                  }`}
                >
                  <p
                    className={`typo-kappa t-uppercase ${
                      isArchived ? 't-text-beta-400' : 't-text-delta-700'
                    }`}
                  >
                    <TextWithHyphenSpan>
                      {isArchived ? t('common:archived') : t(assetType.label)}
                    </TextWithHyphenSpan>
                  </p>
                </div>
              </div>
            )}
            {zvrDocument && !isArchived && (
              <ZvrLogo
                className="t-mb-3 sm:t-w-1/2 xl:t-w-full"
                style={{ maxWidth: '232px' }}
              />
            )}
            <h2
              className={`typo-beta ${
                isArchived ? 't-text-beta-400' : 't-text-delta-700'
              }`}
            >
              <TextWithHyphenSpan>{assetName}</TextWithHyphenSpan>
            </h2>
            {isRegistered && (
              <p className="t-text-alpha-600 t-text-xs t-mt-1">
                {t('zvrRegistration:officially_registered')}
              </p>
            )}
          </div>
        </div>
        <div className="t-mt-5">
          {formattedContacts?.length ? (
            formattedContacts.map((contactName, idx) => (
              <p
                className={`typo-delta t-flex t-items-center t-mb-1.5 ${
                  isArchived ? 't-text-beta-400' : 't-text-beta-700'
                }`}
                key={`${contactName}_${idx}`}
              >
                <IconAvatar />
                <span className="t-ml-1.5">{contactName}</span>
              </p>
            ))
          ) : (
            <div
              className={`t-flex t-items-center ${
                asset.status !== 1 ? 't-border t-border-solid' : ''
              } t-w-full t-p-1 t-rounded-sm ${
                isArchived ? 't-text-epsilon-100' : 't-text-epsilon-400'
              }`}
            >
              {asset.status !== 1 && (
                <div
                  className={`t-w-2.5 t-h-2.5 t-mr-2 t-rounded-full ${
                    isArchived ? 't-bg-epsilon-100' : 't-bg-epsilon-600'
                  }`}
                />
              )}
              <p
                className={`typo-delta ${
                  isArchived ? 't-text-beta-400' : 't-text-beta-700'
                }`}
              >
                {t('plan:no_contact')}
              </p>
            </div>
          )}
        </div>
        {!isArchived &&
          !!zvrDocument &&
          [
            ASSET_TYPE_KEYS.HEALTH_CARE_PROXY,
            ASSET_TYPE_KEYS.PATIENT_DECREE,
          ].includes(assetType.name) && (
            <div onClick={(e) => e.stopPropagation()} data-testid="ZvrButtons">
              <ZvrButtons asset={asset} type={assetType.name} />
            </div>
          )}
      </div>
    </>
  );
}
