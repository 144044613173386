export function getSelectOptions(
  contacts: ContactDTO[] | undefined
): SelectOption[] {
  if (!contacts) return [] as SelectOption[];

  return contacts.map((item) => ({
    value: item.id,
    label: `${item.name} ${item.surname}`,
  })) as SelectOption[];
}

export function getSelectOptionsContacts(
  contacts: ContactDTO[] | undefined
): SelectOption[] {
  if (!contacts) return [] as SelectOption[];

  return contacts.map((item) => {
    const missingInfo = { birthday: !item.birthday, gender: !item.gender };
    return {
      value: item.id,
      label: `${item.name} ${item.surname}`,
      missingInfo,
      backgroundColor:
        missingInfo.birthday || missingInfo.gender
          ? 'var(--color-eta-200) !important'
          : '',
    };
  }) as SelectOption[];
}

export function getAliveContactsData(
  contacts: ContactDTO[],
  familyAccounts: FamilyAccount[]
) {
  const deadFamilyAccountIds = familyAccounts
    .filter((account) => !!account.deceased)
    .map((account) => account.id);
  return contacts.filter((contact) =>
    !!contact.secondaryUserId
      ? !deadFamilyAccountIds.includes(contact.secondaryUserId)
      : true
  );
}
