import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { getRelativePath } from 'modules/common/helpers';

import { CONTENT_QUESTIONNAIRE_PAGES } from 'modules/assistant/constants/contentQuestionnaires';
import { QUESTIONNAIRE_PAGES } from 'modules/assistant/constants/questionnaires';
import { QuestionnairePage } from 'modules/assistant/components/Questionnaire';
import { ContentQuestionnaire } from 'modules/assistant/components/ContentQuestionnaire';

export default function QuestionnaireRoutes() {
  const baseRoute = '/assistant';
  return (
    <Routes>
      {Object.values(QUESTIONNAIRE_PAGES).map((page) => {
        return (
          <Route
            path={getRelativePath(page.path, baseRoute)}
            key={page.path}
            element={<QuestionnairePage questionnaire={page.questionnaire} />}
          />
        );
      })}
      {Object.values(CONTENT_QUESTIONNAIRE_PAGES).map((page) => (
        <Route
          path={getRelativePath(page.path, baseRoute)}
          key={page.path}
          element={
            <ContentQuestionnaire
              contentQuestionnaire={page.contentQuestionnaire}
            />
          }
        />
      ))}
      <Route path="*" element={<Navigate to={baseRoute} />} />
    </Routes>
  );
}
