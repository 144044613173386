import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { getFullName, useTranslation } from 'modules/common/helpers';

import { REDIRECT_ACTIONS } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';

import { ReactComponent as CheckIcon } from 'images/check.svg';
import { ReactComponent as IconPeople } from 'images/icon-people.svg';
import { ReactComponent as EmailIcon } from 'images/mail-icon.svg';

interface MyContactsWidgetContactCardProps {
  contact: ContactDTO;
}

const MyContactsWidgetContactCard = ({
  contact,
}: MyContactsWidgetContactCardProps) => {
  const { t } = useTranslation(['common', 'mcontacts']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { email, contactedDate } = contact;

  const fullName = getFullName(contact);

  const handleCardClick = async () => {
    await dispatch(
      temporaryFieldSaver({
        actionType: REDIRECT_ACTIONS.GO_TO_CONTACT_CARD,
        contactId: contact.id,
      })
    );
    navigate('/my-contacts');
  };

  return (
    <button
      className="card t-bg-gamma-400 t-py-3 t-px-4 t-mb-3 t-w-full"
      onClick={handleCardClick}
    >
      <div className="t-flex t-flex-col t-items-start">
        {contactedDate && (
          <div className="t-mr-5 t-flex t-items-center t-bg-alpha-100 t-p-1 t-rounded-sm">
            <CheckIcon className="t-w-3 t-h-3 t-mr-1 t-text-alpha-600" />
            <p className="typo-kappa t-text-alpha-600">
              {t('mcontacts:contacted_label')}
            </p>
          </div>
        )}
        <p className="typo-delta t-mt-1 t-flex">
          <IconPeople className="t-mr-2 t-text-alpha-600 t-mt-0.5" />
          <span className="t-text-left">{fullName}</span>
        </p>

        <div className="t-flex t-mt-1">
          <div>
            <EmailIcon className="t-mr-2 t-text-alpha-600 t-mt-1" />
          </div>

          <p className="typo-epsilon t-text-left">{email}</p>
        </div>
      </div>
    </button>
  );
};

export default MyContactsWidgetContactCard;
