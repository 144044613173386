import React from 'react';
import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

import germanLawValidationImg from 'images/img_german_law_validation.png';

interface UserResidenceAboardWarningModalProps {
  openProfileForm: () => void;
  onClose: () => void;
}

const UserResidenceAboardWarningModal = ({
  openProfileForm,
  onClose,
}: UserResidenceAboardWarningModalProps) => {
  const { t } = useTranslation(['common', 'subscription']);

  return (
    <Modal show onHide={onClose}>
      <img
        src={germanLawValidationImg}
        alt="only valid according to German law"
        className="t-text-alpha-600 t-mb-7 t-block t-mx-auto t-max-w-40"
      />
      <h1 className="typo-alpha">
        {t('assistant:residence_abroad_warning_modal_title')}
      </h1>
      <p className="typo-epsilon t-mt-4">
        {t('assistant:residence_abroad_warning_modal_description')}
      </p>

      <div className="t-flex t-justify-end t-items-end t-mt-8 t-flex-col sm:t-flex-row">
        <div>
          <Button
            category="secondary"
            className="t-mr-0 t-py-2 t-mb-3 sm:t-mb-0 sm:t-mr-3"
            onClick={openProfileForm}
          >
            {t('common:change_address')}
          </Button>
        </div>

        <div>
          <Button className="t-py-2" onClick={onClose}>
            {t('common:continue')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UserResidenceAboardWarningModal;
