import { i18n } from 'i18next';

export default {
  getAssignToLabel,
};

function getAssignToLabel(
  assetType: string | undefined,
  t: (key: string) => string,
  i18n: i18n
): string {
  const labelName = `assign_to_label_${assetType}`;
  let assignToLabel = t('common:assign_to_label');
  if (i18n.exists(t(`common:${labelName}`))) {
    assignToLabel = t(`common:${labelName}`);
  }

  return assignToLabel;
}
