import React from 'react';
import { useDispatch } from 'react-redux';

import { generateConfirmationToken } from 'store/actions/userActions';

import {
  ProfileDeleteModal,
  ProfileDeleteConfirmationModal,
} from 'modules/profile/components';

import { DELETE_PROFILE_TYPES } from 'modules/common/constants/enums';

export interface ProfileDeleteProcessProps {
  onProcessClose: () => void;
}

function ProfileDeleteProcess({ onProcessClose }: ProfileDeleteProcessProps) {
  const dispatch = useDispatch();

  const [isProfileDeleteModal, setIsProfileDeleteModal] = React.useState(true);
  const [deleteProfileType, setDeleteProfileType] = React.useState(
    '' as DELETE_PROFILE_TYPES
  );

  const handleDeleteType = async (deleteType: DELETE_PROFILE_TYPES) => {
    await dispatch(generateConfirmationToken());
    setDeleteProfileType(deleteType);
    setIsProfileDeleteModal(false);
  };

  const handleClose = () => {
    setDeleteProfileType('' as DELETE_PROFILE_TYPES);
    onProcessClose();
  };

  return (
    <>
      {isProfileDeleteModal && (
        <ProfileDeleteModal
          onCancel={handleClose}
          handleDeleteType={handleDeleteType}
        />
      )}
      {!!deleteProfileType && (
        <ProfileDeleteConfirmationModal
          deleteType={deleteProfileType}
          onCancel={handleClose}
        />
      )}
    </>
  );
}

export default ProfileDeleteProcess;
