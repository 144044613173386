import React from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import ReactTooltip from 'react-tooltip';

import {
  getNumberWithDots,
  optionsHelper,
  validationHelper,
  useTranslation,
} from 'modules/common/helpers';
import { inheritanceCalculator } from 'modules/estatePlan/helpers';
import { getNumber } from 'modules/common/helpers';

import {
  Button,
  SelectController,
  TextInputRef,
} from 'modules/common/components';
import { Col, Row } from 'modules/bootstrap/components';
import { Checkbox } from 'modules/elements/components';

import { INHERITANCE_RELATIONSHIP } from 'modules/common/constants/enums';

import { ReactComponent as Cross } from 'images/cross.svg';
import { ReactComponent as IconInfo } from 'images/icon-info.svg';

enum INPUTS {
  INHERITANCE_TYPE = 'inheritanceType',
  RELATIONSHIP = 'relationship',
  WORTH = 'worth',
  CHILDREN_AGE = 'childrenAge',
}

interface InputTypes {
  inheritanceType: string;
  relationship: string;
  worth: number;
  childrenAge?: number;
}

const inheritanceTypeOptions = optionsHelper.getInheritanceTypeOptions();
const inheritanceRelationOptions =
  optionsHelper.getInheritanceRelationOptions();
const inheritanceChildrenAgeOptions =
  optionsHelper.getInheritanceChildrenAgeOptions();

const InheritanceTaxWidget = () => {
  const { t } = useTranslation(['common', 'inheritanceTaxCal']);
  const {
    register,
    handleSubmit,
    control,
    watch,

    formState: { errors },
  } = useForm();
  const [inputData, setInputData] = React.useState({} as InputTypes);
  const [pensionAllowanceIncluded, setPensionAllowanceIncluded] =
    React.useState(true);

  const submit = (formData) => {
    const processedData = Object.keys(formData).reduce((acc, key) => {
      switch (key) {
        case INPUTS.INHERITANCE_TYPE:
          acc[INPUTS.INHERITANCE_TYPE] =
            formData[INPUTS.INHERITANCE_TYPE].value;
          break;
        case INPUTS.RELATIONSHIP:
          acc[INPUTS.RELATIONSHIP] = formData[INPUTS.RELATIONSHIP].value;
          break;
        case INPUTS.CHILDREN_AGE:
          acc[INPUTS.CHILDREN_AGE] = Number(
            formData[INPUTS.CHILDREN_AGE].value
          );
          break;
        case INPUTS.WORTH:
          acc[INPUTS.WORTH] = formData[INPUTS.WORTH]
            ? getNumber(formData[INPUTS.WORTH])
            : 0;
          break;
        default:
          break;
      }
      return acc;
    }, {} as InputTypes);
    setInputData(processedData);
  };

  const errorWorth = errors[INPUTS.WORTH]?.type
    ? t('common:invalid_number')
    : '';

  const result = inheritanceCalculator(inputData, !pensionAllowanceIncluded);

  // open the age input if the selected relationship is children or step children or grand children, and inheritance type is inheritance
  const relationInput = watch(INPUTS.RELATIONSHIP);
  const inheritanceTypeInput = watch(INPUTS.INHERITANCE_TYPE);
  const { CHILD, STEP_CHILD, GRANDCHILD } = INHERITANCE_RELATIONSHIP;
  const isChildren = [CHILD, STEP_CHILD, GRANDCHILD].includes(
    relationInput?.value
  );
  const isInheritanceType = inheritanceTypeInput?.value === 'inheritance';

  const handleCloseResultCard = () => {
    setInputData({} as InputTypes);
  };
  const handleTogglePensionAllowance = () => {
    setPensionAllowanceIncluded((prev) => !prev);
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, [result]);

  return (
    <div>
      <div className="t-flex">
        <h1 className="typo-beta t-mr-3">
          {t('inheritanceTaxCal:widget_title')}
        </h1>
        <div className="t-px-3 t-bg-epsilon-600 t-rounded-sm t-flex t-justify-center t-items-center">
          <p className="typo-theta t-text-gamma-400">
            {t('inheritanceTaxCal:widget_title_text_new')}
          </p>
        </div>
      </div>
      <p className="typo-epsilon t-mt-3">
        {t('inheritanceTaxCal:widget_description')}
      </p>

      <form onSubmit={handleSubmit(submit)}>
        <div className="card t-p-4 t-mt-6">
          <Row className="t-mt-2 t-mx-1">
            <Col sm={6}>
              <p className="typo-epsilon t-text-beta-500">
                {t('inheritanceTaxCal:inheritance_type_input_label')}
              </p>
            </Col>
            <Col
              sm={6}
              className="Form-group t-px-0 t-mt-1 sm:t-mt-0 t-flex t-items-center"
            >
              <SelectController
                id="inheritanceType"
                name="inheritanceType"
                options={inheritanceTypeOptions}
                control={control}
                defaultValue={inheritanceTypeOptions[0]}
                className={`Select t-w-full ${errors.type ? 'isErrored' : ''}`}
              />
              <div>
                <IconInfo
                  role="presentation"
                  data-tip={t(
                    'inheritanceTaxCal:inheritance_type_input_tooltip'
                  )}
                  className="t-inline t-text-delta-700 t-ml-3"
                />
              </div>
            </Col>
          </Row>
          <Row className="t-mt-2 sm:t-mt-0 t-mx-1">
            <Col sm={6}>
              <p className="typo-epsilon t-text-beta-500">
                {t('inheritanceTaxCal:inheritance_worth_input_label')}
              </p>
            </Col>
            <Col sm={6} className="t-px-0 t-mt-1 sm:t-mt-0 t-flex">
              <div className="t-w-full">
                <TextInputRef
                  {...register('worth', {
                    required: false,
                    validate: (value) => {
                      if (!value) return true;
                      return validationHelper.isValidNumberNonInteger(value);
                    },
                  })}
                  type="number"
                  error={errorWorth}
                  placeholder="0,00"
                  iconLeft="€"
                />
              </div>
              <div className="t-flex t-items-center t-pb-3">
                <IconInfo
                  role="presentation"
                  data-tip={t(
                    'inheritanceTaxCal:inheritance_worth_input_tooltip'
                  )}
                  className="t-inline t-text-delta-700 t-ml-3"
                />
              </div>
            </Col>
          </Row>

          <Row className="t-mt-2 sm:t-mt-0 t-mx-1">
            <Col sm={6}>
              <p className="typo-epsilon t-text-beta-500">
                {t('inheritanceTaxCal:inheritance_pension_allowance_input')}
              </p>
            </Col>
            <Col
              sm={6}
              className="Form-group t-px-0 t-mt-1 sm:t-mt-0 t-flex t-justify-end t-items-center"
            >
              <Checkbox
                checked={pensionAllowanceIncluded}
                id="pensionAllowanceIncluded"
                onChange={handleTogglePensionAllowance}
                textClassName="typo-epsilon t-text-beta-500"
                isBlueBg
              />
              <div>
                <IconInfo
                  role="presentation"
                  data-tip={t(
                    'inheritanceTaxCal:inheritance_pension_allowance_input_tooltip'
                  )}
                  className="t-inline t-text-delta-700 t-ml-3"
                />
              </div>
            </Col>
          </Row>

          <Row className="t-mt-2 sm:t-mt-0 t-mx-1">
            <Col sm={6}>
              <p className="typo-epsilon t-text-beta-500">
                {t('inheritanceTaxCal:inheritance_relationship_input_label')}
              </p>
            </Col>
            <Col
              sm={6}
              className="Form-group t-px-0 t-mt-1 sm:t-mt-0 t-flex t-items-center"
            >
              <SelectController
                id="relationship"
                name="relationship"
                options={inheritanceRelationOptions}
                control={control}
                defaultValue={inheritanceRelationOptions[0]}
                className={`Select t-w-full ${errors.type ? 'isErrored' : ''}`}
              />
              <div>
                <IconInfo
                  role="presentation"
                  data-tip={t(
                    'inheritanceTaxCal:inheritance_relationship_input_label_tooltip'
                  )}
                  className="t-inline t-text-delta-700 t-ml-3"
                />
              </div>
            </Col>
          </Row>

          {isInheritanceType && isChildren && (
            <Row className="t-mt-2 sm:t-mt-0 t-mx-1">
              <Col sm={6}>
                <p className="typo-epsilon t-text-beta-500">
                  {t('inheritanceTaxCal:inheritance_age_input_label')}
                </p>
              </Col>
              <Col sm={6} className="Form-group t-px-0 t-mt-1 sm:t-mt-0 t-pr-6">
                <SelectController
                  id="childrenAge"
                  name="childrenAge"
                  options={inheritanceChildrenAgeOptions}
                  control={control}
                  defaultValue={inheritanceChildrenAgeOptions[0]}
                  className={`Select t-w-full ${
                    errors.type ? 'isErrored' : ''
                  }`}
                />
              </Col>
            </Row>
          )}
        </div>
        <div className="t-flex t-justify-end">
          <Button type="submit" className="t-mt-6">
            {t('inheritanceTaxCal:calculate_btn')}
          </Button>
        </div>
      </form>

      {!isEmpty(result) && (
        <div className="card t-py-4 t-px-6 t-mt-6">
          <div className="t-flex t-justify-between t-items-center">
            <h2 className="typo-beta t-mt-3">
              {t('inheritanceTaxCal:result_card_title')}
            </h2>
            <button
              className="t-flex t-items-center"
              onClick={handleCloseResultCard}
            >
              <Cross className="t-text-beta-200 t-min-w-5" />
              <span className="typo-eta t-text-beta-200">
                {t('common:close')}
              </span>
            </button>
          </div>

          <div>
            <div className="t-flex t-justify-between t-items-center t-mt-3">
              <p className="typo-epsilon t-text-beta-500">
                {t('inheritanceTaxCal:result_inheritance_input_worth')}
              </p>
              <p>
                <span>
                  {getNumberWithDots((inputData?.worth || 0).toFixed(2))} €
                </span>
              </p>
            </div>

            <div className="t-flex t-justify-between t-items-center t-mt-3">
              <p className="typo-epsilon t-text-beta-500">
                {t(
                  `inheritanceTaxCal:${
                    isInheritanceType
                      ? 'result_card_inheritance_tax_class'
                      : 'result_card_gift_tax_class'
                  }`
                )}
              </p>
              <p>
                <span>{result.taxClass}</span>
                <IconInfo
                  role="presentation"
                  data-tip={t(
                    `inheritanceTaxCal:${
                      isInheritanceType
                        ? 'result_card_inheritance_tax_class_tooltip'
                        : 'result_card_gift_tax_class_tooltip'
                    }`
                  )}
                  className="t-inline t-text-delta-700 t-ml-3"
                />
              </p>
            </div>

            <div className="t-flex t-justify-between t-items-center t-mt-3">
              <p className="typo-epsilon t-text-beta-500">
                {t('inheritanceTaxCal:result_card_personal_allowance')}
              </p>
              <p>
                <span>{result.personalFreeAllowance} €</span>
                <IconInfo
                  role="presentation"
                  data-tip={t(
                    'inheritanceTaxCal:result_card_personal_allowance_tooltip'
                  )}
                  className="t-inline t-text-delta-700 t-ml-3"
                />
              </p>
            </div>

            <div className="t-flex t-justify-between t-items-center t-mt-3">
              <p className="typo-epsilon t-text-beta-500">
                {t('inheritanceTaxCal:result_card_pension_allowance')}
              </p>
              <div className="t-flex t-ml-2">
                <p className="t-whitespace-nowrap">
                  {result.nonTaxableAllowance} €
                </p>
                <div
                  data-tip={t(
                    'inheritanceTaxCal:result_card_pension_allowance_tooltip'
                  )}
                  data-for="nonTaxableAllowance"
                  className="t-ml-3 t-mt-0.5"
                  role="presentation"
                >
                  <IconInfo className="t-text-delta-700" />
                  <ReactTooltip
                    id="nonTaxableAllowance"
                    effect="solid"
                    multiline
                    className="md:t-max-w-sm"
                  />
                </div>
              </div>
            </div>

            <div className="t-flex t-justify-between t-items-center t-mt-3">
              <p className="typo-epsilon t-text-beta-500">
                {t('inheritanceTaxCal:result_card_taxable_amount')}
              </p>
              <p>
                <span>{result.taxableAmount} €</span>
                <IconInfo
                  role="presentation"
                  data-tip={t(
                    'inheritanceTaxCal:result_card_taxable_amount_tooltip'
                  )}
                  className="t-inline t-text-delta-700 t-ml-3"
                />
              </p>
            </div>

            <div className="t-flex t-justify-between t-items-center t-mt-3">
              <p className="typo-epsilon t-text-beta-500">
                {t('inheritanceTaxCal:result_card_tax_percent')}
              </p>
              <p>
                <span>{result.taxRate} %</span>
                <IconInfo
                  role="presentation"
                  data-tip={t(
                    'inheritanceTaxCal:result_card_tax_percent_tooltip'
                  )}
                  className="t-inline t-text-delta-700 t-ml-3"
                />
              </p>
            </div>

            <div className="t-flex t-justify-between t-items-center t-mt-3 t-text-zeta-600">
              <p className="typo-epsilon t-text-zeta-600">
                {t(
                  `inheritanceTaxCal:${
                    isInheritanceType
                      ? 'result_card_inheritance_tax_amount'
                      : 'result_card_gift_tax_amount'
                  }`
                )}
              </p>
              <p>
                <span>{result.inheritanceTax} €</span>
                <IconInfo
                  role="presentation"
                  data-tip={t(
                    `inheritanceTaxCal:${
                      isInheritanceType
                        ? 'result_card_inheritance_tax_amount_tooltip'
                        : 'result_card_gift_tax_amount_tooltip'
                    }`
                  )}
                  className="t-inline t-text-delta-700 t-ml-3"
                />
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InheritanceTaxWidget;
