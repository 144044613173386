import React from 'react';

import { useTranslation } from 'modules/common/helpers';

const ErrorPage = () => {
  const { t } = useTranslation(['common']);
  return (
    <div style={{ margin: '30px' }}>
      <h1 style={{ fontSize: '20px', marginBottom: '20px' }}>
        {t('common:error_page_title')}
      </h1>
      <a href="/">{t('common:error_page_reset_button')}</a>
    </div>
  );
};

export default ErrorPage;
