import React from 'react';
import { Accordion, AccordionContext } from 'react-bootstrap';

import { ReactComponent as ArrowDown } from 'images/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'images/arrow-up.svg';

type ToggleProps = {
  eventKey: string;
  title: string;
  content: string;
};

const Toggle = ({ eventKey, title, content }: ToggleProps) => {
  const currentEventKey = React.useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Button
        as="div"
        className="t-flex t-justify-between t-items-center t-cursor-pointer"
      >
        <span className="typo-delta t-text-left t-text-alpha-600">{title}</span>
        {isCurrentEventKey ? (
          <ArrowUp className="t-text-alpha-600 t-mr-3" />
        ) : (
          <ArrowDown className="t-text-alpha-600 t-mr-3" />
        )}
      </Accordion.Button>

      <Accordion.Collapse eventKey={eventKey}>
        <p className="t-mt-2 t-pl-5 t-border-l-4 t-border-solid t-border-beta-400">
          {content}
        </p>
      </Accordion.Collapse>
    </Accordion.Item>
  );
};

export default Toggle;
