import { camelCase } from 'lodash';
import {
  LOAD_DOCUMENTS_LIST,
  DELETE_ASSET,
  DELETE_DOCUMENT,
  ADD_ASSET,
  EDIT_ASSET,
  NOT_RELEVANT_ASSET,
} from 'store/types/documentActionTypes';
import { DELETE_CONTACT } from 'store/types/contactActionTypes';
import { UNNESESSARY_METHOD_getProperAssetType } from 'modules/estatePlan/helpers';
import helper from 'store/reducers/reducerHelper';
import initialState from 'store/reducers/initialState';

const documentReducer = (state = initialState.documents, action) => {
  return helper.handleActions(state, action, {
    [LOAD_DOCUMENTS_LIST](state, payload) {
      state.list = payload.documents as AssetsDTO;
    },

    [DELETE_DOCUMENT]: deleteDocument,

    [DELETE_ASSET]: deleteAsset,

    [ADD_ASSET]: addAsset,
    [NOT_RELEVANT_ASSET]: addAsset,

    [EDIT_ASSET]: editAsset,

    [DELETE_CONTACT]: deleteContact,
  });
};

export default documentReducer;

function addAsset(state, { assetData }) {
  const camelAssetType = camelCase(assetData.category);

  let newData = [...(state.list[camelAssetType] || []), assetData as AssetDTO];

  if (!assetData.notProvided) {
    newData = newData.filter(
      (item) => !(item.notProvided && item.type === assetData.type)
    );
  }

  state.list = { ...state.list, [camelAssetType]: newData };
}

function deleteAsset(state, payload) {
  const { id, assetCategory } = payload;
  // TODO: this is done because GET /assets returns camel cased keys (e.g. 'afterLife'),
  // but POST /asset accepts e.g. 'after life'. To be refactored in the future
  const camelAssetType = UNNESESSARY_METHOD_getProperAssetType(assetCategory);

  const newData = [
    ...state.list[camelAssetType].filter((item) => item.id !== id),
  ];
  state.list = { ...state.list, [camelAssetType]: newData };
}

function deleteDocument(state, payload) {
  const { id, assetId, assetCategory } = payload;
  // TODO: this is done because GET /assets returns camel cased keys (e.g. 'afterLife'),
  // but POST /asset accepts e.g. 'after life'. To be refactored in the future
  const camelAssetType = UNNESESSARY_METHOD_getProperAssetType(assetCategory);
  const currentAsset = state.list[camelAssetType].filter(
    (item) => item.id === assetId
  )[0];
  const newData = currentAsset.documents.filter((item) => item.id !== id);

  state.list[camelAssetType].filter(
    (item) => item.id === assetId
  )[0].documents = [...newData];
}

function editAsset(state, { assetData }) {
  const { id, category } = assetData;
  const camelAssetType = UNNESESSARY_METHOD_getProperAssetType(category);

  const newData = state.list[camelAssetType].map((item) => {
    if (item.id !== id) return { ...item };

    return { ...assetData };
  });
  state.list = { ...state.list, [camelAssetType]: newData };
}

const updateContacts = (contacts: ContactDTO[], contactId: string) =>
  contacts.reduce((acc, contact) => {
    if (contact.id === contactId) return acc;
    acc.push(contact);
    return acc;
  }, [] as ContactDTO[]);

function deleteContact(state, { id }) {
  const newList = {};

  Object.entries<AssetDTO[]>(state.list).forEach(([assetType, assetData]) => {
    newList[assetType] = assetData.map((asset) => {
      const newAsset = { ...asset };
      newAsset.contacts = updateContacts(newAsset.contacts || [], id);
      return newAsset;
    });
  });

  state.list = newList;
}
