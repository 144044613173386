import React from 'react';
import { Step, TooltipRenderProps } from 'react-joyride';
import DOMPurify from 'dompurify';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as ArrowRightIcon } from 'images/icon-arrow-right.svg';
import { ReactComponent as IconCheck } from 'images/icon_check.svg';

interface CustomStepProps extends Step {
  image?: string;
  currentStep?: number;
  totalSteps?: number;
  containerClassName?: string;
}

interface CustomTooltipProps extends TooltipRenderProps {
  step: CustomStepProps;
}

const TutorialTooltip = ({
  step,
  closeProps,
  primaryProps,
  skipProps,
  tooltipProps,
}: CustomTooltipProps) => {
  const { t } = useTranslation(['common', 'tutorial']);

  const endStep = step.currentStep === step.totalSteps;
  return (
    <div
      {...tooltipProps}
      className={`card t-px-6 sm:t-px-10 t-pt-10 t-pb-8 t-relative ${
        step.containerClassName ? step.containerClassName : ''
      }`}
    >
      {step?.image && (
        <div className="t-flex t-justify-center">
          <img src={step.image} alt="tutorial" className="t-mb-4" />
        </div>
      )}

      <h1 className="typo-beta t-text-center">{step.title}</h1>
      <p
        className="t-mt-7 t-text-center"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(step.content),
        }}
      />

      <div className="t-mt-8">
        {!endStep && (
          <div className="t-flex t-justify-end">
            <button
              {...{ ...skipProps, title: '' }}
              className="button-secondary t-mr-3 t-whitespace-nowrap"
            >
              {t('tutorial:button_skip_tutorial')}
            </button>
            <button
              {...{ ...primaryProps, title: '' }}
              className="button-primary t-whitespace-nowrap"
            >
              <span>{t('common:next')}</span>
              <span>
                <ArrowRightIcon className="t-w-5 t-h-5 t-text-alpha-600 t-ml-2" />
              </span>
            </button>
          </div>
        )}

        {endStep && (
          <div className="t-flex t-justify-center">
            <button
              {...{ ...closeProps, title: '' }}
              className="button-primary t-flex t-items-center"
            >
              <IconCheck className="t-mr-2" />
              {t('common:finish')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TutorialTooltip;
