import React, { useContext } from 'react';
import { Accordion, AccordionContext } from 'react-bootstrap';

import {
  AnswersAccordionQuestion,
  AnswersAccordionAnswer,
} from 'modules/estatePlan/components';

type AnswersAccordionItemProps = {
  item: AccordionAnswer;
  itemClassName?: string;
};

const AnswersAccordionItem = ({
  item,
  itemClassName,
}: AnswersAccordionItemProps) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey.activeEventKey === item.question;
  return (
    <div key={item.question} className={itemClassName || 't-pb-5'}>
      <Accordion.Item eventKey={item.question}>
        <Accordion.Button as="div">
          <AnswersAccordionQuestion selected={isCurrentEventKey}>
            {item.question}
          </AnswersAccordionQuestion>
        </Accordion.Button>

        <Accordion.Collapse eventKey={item.question}>
          <AnswersAccordionAnswer>{item.answer}</AnswersAccordionAnswer>
        </Accordion.Collapse>
      </Accordion.Item>
    </div>
  );
};

export default AnswersAccordionItem;
