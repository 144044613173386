import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';

import { RootState } from 'store/reducers';
import { setModal } from 'store/actions/modalActions';
import { getProvidersByType } from 'store/actions/providerActions';
import { useTranslation } from 'modules/common/helpers';
import { getSelectOptionsContacts } from 'modules/contact/helpers';

import { LinkCurrentAssetModal } from 'modules/asset/components';
import { Button, SwitchInput } from 'modules/common/components';

import {
  FormGetValues,
  FormRegister,
  FormSetValue,
  FormWatch,
} from 'modules/common/types';

import { ASSET_TYPE_KEYS, FIELD_TYPE_KEYS } from 'modules/estatePlan/constants';
import { MODALS } from 'modules/common/constants';

const LINK_ASSET_TYPE_KEYS = [ASSET_TYPE_KEYS.PHONE, ASSET_TYPE_KEYS.EMAIL];

interface TFAAssetFormFieldProps {
  defaultAsset: AssetDTO;
  register: FormRegister;
  watch: FormWatch;
  setValue: FormSetValue;
  getValues: FormGetValues;
  label: string;
  tfaEnabled?: boolean;
  handleToggleAssetForm: (isHidden: boolean) => void;
}

const TFAAssetFormField = ({
  defaultAsset,
  register,
  watch,
  setValue,
  getValues,
  tfaEnabled,
  label,
  handleToggleAssetForm,
}: TFAAssetFormFieldProps) => {
  const { t } = useTranslation(['common', 'plan']);
  const dispatch = useDispatch();

  const isGlobalModalOpen = useSelector(
    (state: RootState) => !!state.modal.name
  );

  const assetsData: AssetsDTO = useSelector((state: RootState) => state.assets);
  const assets: AssetDTO[] = Object.values(assetsData).flat();

  const assetsToLink: AssetDTO[] =
    assets?.filter(
      (asset) =>
        LINK_ASSET_TYPE_KEYS.includes(asset.assetTypeName || '') &&
        defaultAsset?.id !== asset.id
    ) || [];

  const [openLinkCurrentAssetModal, setOpenLinkCurrentAssetModal] =
    React.useState(false);

  const [linkedAsset, setLinkedAsset] = React.useState<AssetDTO>();

  const allFetchedProviders: ProvidersDTO = useSelector(
    (state: RootState) => state.providers.current
  );

  const providers = LINK_ASSET_TYPE_KEYS.map(
    (assetTypeKey) => allFetchedProviders[assetTypeKey] || []
  ).flat();

  const linkedAssetId = watch(FIELD_TYPE_KEYS.RELATED_2FA_ASSET_ID);
  const twoFAEnabled = watch(FIELD_TYPE_KEYS.TWO_AUTH);

  React.useEffect(() => {
    LINK_ASSET_TYPE_KEYS.forEach((key) => {
      if (!Object.keys(allFetchedProviders).includes(key)) {
        dispatch(getProvidersByType(key));
      }
    });
  }, [allFetchedProviders]);

  React.useEffect(() => {
    if (defaultAsset?.related2FAAssetId) {
      setValue(
        FIELD_TYPE_KEYS.RELATED_2FA_ASSET_ID,
        defaultAsset?.related2FAAssetId
      );
    }
  }, [defaultAsset]);

  React.useEffect(() => {
    if (linkedAssetId) {
      const asset = assetsToLink.find((item) => item.id === linkedAssetId);
      setLinkedAsset(asset);
    }
  }, [linkedAssetId]);

  React.useEffect(() => {
    if (openLinkCurrentAssetModal || isGlobalModalOpen) {
      handleToggleAssetForm(true);
    } else {
      handleToggleAssetForm(false);
    }
  }, [openLinkCurrentAssetModal, isGlobalModalOpen]);

  // if the 2FA is switched off and the linked asset exists, open the waring
  React.useEffect(() => {
    handleTfaDisabledWarning();
  }, [linkedAssetId, twoFAEnabled]);

  const handleTfaDisabledWarning = () => {
    if (!!linkedAssetId && !twoFAEnabled) {
      setValue(FIELD_TYPE_KEYS.TWO_AUTH, true);
      handleWarningModals(false);
    }
  };

  const displayedFieldValue =
    linkedAsset && providers.length
      ? find(providers, (item) => item.key === linkedAsset.provider)?.value ||
        linkedAsset.provider
      : '';

  const handleOpenLinkCurrentAssetModal = (event) => {
    event?.preventDefault();
    setOpenLinkCurrentAssetModal(true);
  };

  const onRelatedAssetSelect = (asset?: AssetDTO) => {
    // save the asset id to the react-hook-form
    if (asset?.id) {
      setValue(FIELD_TYPE_KEYS.RELATED_2FA_ASSET_ID, asset.id);
      handleCloseModals();
      // open a modal to ask if the user wants to transfer the linked asset's contacts to the form
      handleOpenContactTransferModal(asset);
    }
    handleCloseModals();
  };

  const handleRelatedAssetRemoveWarning = (event) => {
    event?.preventDefault();
    handleWarningModals(true);
  };

  const handleWarningModals = (removeLinkedAsset: boolean) => {
    const title = removeLinkedAsset
      ? t('plan:2fa_link_removal_confirm_modal_title')
      : t('plan:2fa_disable_with_liked_asset_warning_modal_title');
    const description = removeLinkedAsset
      ? t('plan:2fa_link_removal_confirm_modal_desc')
      : '';
    const successAction = () => {
      removeLinkedAsset
        ? setValue(FIELD_TYPE_KEYS.RELATED_2FA_ASSET_ID, '')
        : handleRelatedAssetDelete();
    };
    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title,
          description,
          successAction,
          primaryButtonText: t('plan:remove'),
        },
      })
    );
  };

  const handleRelatedAssetDelete = () => {
    setValue(FIELD_TYPE_KEYS.RELATED_2FA_ASSET_ID, '');
    setLinkedAsset(undefined);
    setValue(FIELD_TYPE_KEYS.TWO_AUTH, false);
    handleCloseModals();
  };

  const handleOpenContactTransferModal = (asset: AssetDTO) => {
    if (!asset?.contacts?.length) {
      return;
    }
    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: t('plan:2fa_contact_transfer_confirm_modal_title'),
          description: t('plan:2fa_contact_transfer_confirm_modal_description'),
          successAction: () => {
            handleAddContactsOfLinkedAsset(asset);
          },
          primaryButtonText: t('common:yes'),
          secondaryButtonText: t('common:no'),
        },
      })
    );
  };

  const handleAddContactsOfLinkedAsset = (asset: AssetDTO) => {
    const linkedContactOptions = getSelectOptionsContacts(asset.contacts || []);
    const currentSelectedOptions = [...getValues(FIELD_TYPE_KEYS.CONTACTS)];
    linkedContactOptions.forEach((linkedOption) => {
      if (
        !currentSelectedOptions.find(
          (currentOption) => currentOption.value === linkedOption.value
        )
      ) {
        currentSelectedOptions.push(linkedOption);
      }
    });
    setValue(FIELD_TYPE_KEYS.CONTACTS, currentSelectedOptions);
  };

  const handleCloseModals = () => {
    setOpenLinkCurrentAssetModal(false);
    handleToggleAssetForm(false);
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      {openLinkCurrentAssetModal && (
        <LinkCurrentAssetModal
          assets={assetsToLink}
          onClose={handleCloseModals}
          onSave={onRelatedAssetSelect}
          customTitle={t('plan:2fa_link_asset_modal_title')}
          customDescription={t('plan:2fa_link_asset_modal_description')}
        />
      )}

      <div>
        <SwitchInput
          register={register}
          label={label}
          name={FIELD_TYPE_KEYS.TWO_AUTH}
          defaultValue={!!tfaEnabled}
          labelClassName="typo-epsilon t-text-beta-500"
        />

        {twoFAEnabled && !linkedAssetId && !!assetsToLink.length && (
          <div className="t-pb-4">
            <Button
              onClick={handleOpenLinkCurrentAssetModal}
              testId="linkExistingAssetButton"
            >
              {t('plan:2fa_link_current_asset_btn')}
            </Button>
          </div>
        )}

        {linkedAsset?.id && !!linkedAssetId && (
          <div className="t-pb-4">
            <p className="typo-epsilon t-text-beta-500">
              {t('plan:2fa_linked_asset_field_title')}
            </p>
            <div className="t-flex t-items-center t-justify-between t-py-3 t-pl-5 t-pr-2 t-border t-border-solid t-border-beta-200 t-rounded t-mt-1">
              <div className="t-flex t-items-center">
                <h2 className="typo-beta">{displayedFieldValue}</h2>
              </div>
              <button
                className="t-text-alpha-600 t-outline-none"
                onClick={handleRelatedAssetRemoveWarning}
              >
                {t('plan:remove')}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TFAAssetFormField;
