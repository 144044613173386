import React from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { differenceInCalendarDays, format, isSameDay } from 'date-fns';
import { enGB, de } from 'date-fns/locale';

import { RootState } from 'store/reducers';
import { getCouponInfo } from 'store/actions/userActions';
import {
  checkDateInThePast,
  config,
  localizeNumber,
  useTranslation,
} from 'modules/common/helpers';

import {
  FormRegister,
  FormWatch,
  FormSetError,
  FormClearErrors,
} from 'modules/common/types';
import { Spinner, TextInputRef } from 'modules/common/components';
import { PricesInfo } from 'modules/payment/components/Payment';

import { BILLING_OPTIONS } from 'modules/payment/constants';
import { LANGUAGES, LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';

const { LIFETIME } = BILLING_OPTIONS;

export interface SubscriptionDetailProps {
  register: FormRegister;
  watch: FormWatch;
  billingOption: string;
  pricesInfo: PricesInfo;
  setError: FormSetError;
  clearErrors: FormClearErrors;
}

const SubscriptionDetail = ({
  register,
  billingOption,
  pricesInfo,
  watch,
  setError,
  clearErrors,
}: SubscriptionDetailProps) => {
  const { t } = useTranslation(['payment', 'common']);
  const dispatch = useDispatch();

  // check trial information
  const subscriptionEnd = useSelector(
    (state: RootState) => state.user.current.subscriptionEnd
  );
  const today = new Date();
  const unparsedSubscriptionEnd = new Date(subscriptionEnd);
  const correctedSubscriptionEnd = format(
    unparsedSubscriptionEnd.setDate(unparsedSubscriptionEnd.getDate() - 1),
    config.format.serverDate
  );
  const isDateInFuture =
    !checkDateInThePast(correctedSubscriptionEnd) &&
    !isSameDay(new Date(correctedSubscriptionEnd), today);
  const numberOfDaysLeft = isDateInFuture
    ? differenceInCalendarDays(new Date(correctedSubscriptionEnd), today)
    : 0;

  const { yearlyPrice, lifetimePrice } = pricesInfo;
  const lng =
    localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE) || LANGUAGES.DE;
  const lang = lng === LANGUAGES.DE ? de : enGB;

  const isLifetime = billingOption === LIFETIME;
  const price = isLifetime ? lifetimePrice : yearlyPrice;

  const coupon = watch('coupon');
  const doneTypingInterval = 1500;
  const [couponInfo, setCouponInfo] = React.useState<undefined | CouponInfoDTO>(
    undefined
  );
  const [isCouponChecking, setIsCouponChecking] = React.useState(false);
  const isCodeInvalid = couponInfo && isEmpty(couponInfo);
  const couponInputLabel = t('payment:step_sepa_coupon_name');
  const couponError = isCodeInvalid
    ? t('payment:step_sepa_coupon_invalid')
    : '';
  const validCouponMessage = !isEmpty(couponInfo || {})
    ? t('payment:step_sepa_coupon_valid')
    : '';

  // The prices should be in cents 100 = 1€
  const getNextDueDateLabels = (discountedPrice, originalPrice) => {
    // we don't need next due date for lifetime subscription
    if (isLifetime) {
      return { nextDueDateLabel: '', nextDueWithoutDiscountLabel: '' };
    }

    const flooredDuration = Math.floor(discountedPrice / originalPrice);
    const nextDueDate = new Date(
      today.getFullYear() + flooredDuration,
      today.getMonth(),
      today.getDate() + numberOfDaysLeft
    );
    const nextDuePrice = localizeNumber(
      ((originalPrice - (discountedPrice % originalPrice)) / 100).toFixed(2),
      lng
    );
    let nextDueDateWithoutDiscount;
    if (discountedPrice % originalPrice !== 0) {
      nextDueDateWithoutDiscount = new Date(
        today.getFullYear() + flooredDuration + 1,
        today.getMonth(),
        today.getDate() + numberOfDaysLeft
      );
    }

    const nextDueDateLabel = t(
      `payment:step_sepa_next_due_with_discount_${billingOption}`,
      {
        nextDueDate: format(nextDueDate, config.format.uiDashboardDate, {
          locale: lang,
        }),
        nextDuePrice,
      }
    );
    const nextDueWithoutDiscountLabel = nextDueDateWithoutDiscount
      ? t(`payment:step_sepa_next_due_date_without_discount_${billingOption}`, {
          nextDueDateWithoutDiscount: format(
            nextDueDateWithoutDiscount,
            config.format.uiDashboardDate,
            { locale: lang }
          ),
          price: localizeNumber((originalPrice / 100).toFixed(2), lng),
        })
      : '';

    return { nextDueDateLabel, nextDueWithoutDiscountLabel };
  };

  let couponInfoLabel = '';
  let discountedPrice = 0;
  let nextDueDateLabel = '';
  let nextDueWithoutDiscountLabel = '';

  switch (true) {
    case !!validCouponMessage && !!couponInfo?.percentOff:
      couponInfoLabel = t('payment:step_sepa_coupon_info_off_percent', {
        percent: couponInfo!.percentOff,
      });
      const nextDueDateWithoutDiscount = new Date(
        isLifetime ? today.getFullYear() : today.getFullYear() + 1,
        isLifetime ? today.getMonth() + 1 : today.getMonth(),
        today.getDate() + numberOfDaysLeft
      );
      discountedPrice = Number(price) * (couponInfo!.percentOff / 100);
      nextDueWithoutDiscountLabel = t(
        `payment:step_sepa_next_due_date_without_discount_percent_off_${billingOption}`,
        {
          nextDueDateWithoutDiscount: format(
            nextDueDateWithoutDiscount,
            config.format.uiDashboardDate,
            { locale: lang }
          ),
          price: localizeNumber(price.toFixed(2), lng),
        }
      );
      break;
    case !!validCouponMessage && !!couponInfo?.amountOff:
      // note that the amountOff is in cents
      discountedPrice = Number(couponInfo!.amountOff / 100);
      const formattedDiscountAmount = localizeNumber(
        discountedPrice.toFixed(2),
        lng
      );
      couponInfoLabel = t('payment:step_sepa_coupon_info_off_amount', {
        amount: formattedDiscountAmount,
      });
      const nextDueDateLabels = getNextDueDateLabels(
        discountedPrice * 100,
        price * 100
      );
      nextDueDateLabel = nextDueDateLabels.nextDueDateLabel;
      nextDueWithoutDiscountLabel =
        nextDueDateLabels.nextDueWithoutDiscountLabel;
      break;
    default:
      couponInfoLabel = '';
      break;
  }

  React.useEffect(() => {
    setCouponInfo(undefined);
    setIsCouponChecking(true);
    if (!coupon) {
      setIsCouponChecking(false);
    }

    const delayDebounceFn = setTimeout(async () => {
      if (coupon) {
        const response: any = await dispatch(getCouponInfo(coupon));
        if (response) {
          setCouponInfo(response);
          setIsCouponChecking(false);
        }
      }
    }, doneTypingInterval);

    return () => clearTimeout(delayDebounceFn);
  }, [coupon]);

  React.useEffect(() => {
    if (couponError) {
      setError('coupon', { type: 'invalid' });
    } else {
      clearErrors('coupon');
    }
  }, [couponError]);

  const finalPrice = price - discountedPrice >= 0 ? price - discountedPrice : 0;
  const amountVAT = ((0.19 * finalPrice) / 1.19).toFixed(2);

  const formattedPrice = localizeNumber(price.toFixed(2), lng);
  const formattedDiscountedPrice = localizeNumber(
    discountedPrice.toFixed(2),
    lng
  );
  const formattedVAT = localizeNumber(amountVAT, lng);
  const formattedFinalPrice = localizeNumber(finalPrice.toFixed(2), lng);

  return (
    <div className="t-mt-6" data-testid="SubscriptionDetail">
      <h2 className="typo-beta">
        {t('payment:step_sepa_subscription_detail_title')}
      </h2>

      <div className="t-mt-5">
        <TextInputRef
          {...register('coupon', {
            validate: () => {
              if (couponError) return couponError;
              return true;
            },
          })}
          label={couponInputLabel}
          type="text"
          autoComplete={false}
          error={couponError}
          wrapperClassName="t-pb-0"
          iconRight={
            isCouponChecking ? (
              <Spinner className="t-w-4 t-text-alpha-600" />
            ) : null
          }
        />
        {validCouponMessage && (
          <p className="typo-epsilon t-text-alpha-600 t-mt-1">
            {validCouponMessage}
          </p>
        )}
      </div>

      <div className="t-rounded t-bg-beta-100 t-p-2.5 t-mt-5">
        <div className="t-flex t-justify-between">
          <p className="typo-delta">
            {t(`payment:step_billing_${billingOption}_title`)}
          </p>
          <p className="typo-delta">
            {t(`payment:step_billing_total`, {
              price: formattedPrice,
            })}
          </p>
        </div>
        {couponInfoLabel && (
          <div className="t-flex t-justify-between t-mt-1.5">
            <p className="typo-epsilon t-text-alpha-600">{couponInfoLabel}</p>
            <p className="typo-epsilon t-text-alpha-600">
              {t(`payment:step_sepa_subscription_detail_price`, {
                price: formattedDiscountedPrice,
              })}
            </p>
          </div>
        )}
        <div className="t-flex t-justify-between t-mt-2.5">
          <p className="typo-zeta">
            {t('payment:step_sepa_subscription_detail_vat')}
          </p>
          <p className="typo-zeta">
            {t(`payment:step_billing_total`, {
              price: formattedVAT,
            })}
          </p>
        </div>

        <hr className="t-text-beta-200 t-my-2.5" />

        <div className="t-flex t-justify-between">
          <p className="typo-delta">
            {isDateInFuture
              ? t('payment:step_sepa_due_after_trial')
              : t('payment:step_sepa_subscription_detail_final')}
          </p>
          <p className="typo-delta">
            {t(`payment:step_billing_total`, {
              price: formattedFinalPrice,
            })}
          </p>
        </div>
        {!!nextDueDateLabel && discountedPrice >= price && (
          <p className="typo-zeta t-mt-1 mr-2">{nextDueDateLabel}</p>
        )}
        {!!nextDueWithoutDiscountLabel && !isLifetime && (
          <p className="typo-zeta t-mt-1 mr-2">{nextDueWithoutDiscountLabel}</p>
        )}
        <p></p>
      </div>
    </div>
  );
};

export default SubscriptionDetail;
