import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { FormRegister } from 'modules/common/types';
import { Button, RadioCard } from 'modules/common/components';

import { PAYMENT_METHODS } from 'modules/payment/constants';

import sepaImage from 'images/sepa-payment-method.svg';
import creditCardImage from 'images/credit-card-payment-method.svg';

const { CREDIT_CARD, SEPA } = PAYMENT_METHODS;

export interface StepMethodProps {
  register: FormRegister;
  handleNextStepMethod: () => void;
  methodOption?: string;
  loading?: boolean;
}

export default function StepMethod({
  register,
  handleNextStepMethod,
  methodOption = '',
  loading,
}: StepMethodProps) {
  const { t } = useTranslation(['payment']);
  return (
    <div data-testid="StepMethod">
      <h2 className="typo-alpha t-mb-4">{t('payment:step_method_title')}</h2>
      <h3 className="typo-gamma t-mb-5">
        {t('payment:step_method_selection_subtitle')}
      </h3>
      <RadioCard
        register={register}
        name="method"
        value={SEPA}
        id={SEPA}
        className="t-mb-5 t-items-start"
        classNameRadioBtn="t-mt-3"
        withBorder={methodOption === SEPA}
      >
        <>
          <div className="t-flex t-items-center">
            <img src={sepaImage} className="t-w-10 t-h-14" alt="sepa-payment" />
            <h2 className="typo-beta t-ml-8">
              {t('payment:step_method_sepa')}
            </h2>
          </div>
          {methodOption === SEPA && (
            <div className="t-mt-6">
              <h2 className="typo-beta">
                {t('payment:step_method_sepa_feature_name')}
              </h2>
              <div className="t-mt-2">
                <p className="typo-theta">
                  {t('payment:step_method_sepa_feature_description_first')}
                </p>
                <p className="typo-theta">
                  {t('payment:step_method_sepa_feature_description_second')}
                </p>
                <p className="typo-theta t-mb-2">
                  {t('payment:step_method_sepa_feature_description_third')}
                </p>
              </div>
            </div>
          )}
        </>
      </RadioCard>
      <RadioCard
        register={register}
        name="method"
        value={CREDIT_CARD}
        id={CREDIT_CARD}
        className="t-items-start"
        classNameRadioBtn="t-mt-2"
        withBorder={methodOption === CREDIT_CARD}
      >
        <>
          <div className="t-flex t-items-center">
            <img src={creditCardImage} alt="credit-payment" />
            <h2 className="typo-beta t-ml-8">
              {t('payment:step_method_credit_card')}
            </h2>
          </div>
          {methodOption === CREDIT_CARD && (
            <div className="t-mt-6">
              <h2 className="typo-beta">
                {t('payment:step_method_credit_card_feature_name')}
              </h2>
              <div className="t-mt-2">
                <p className="typo-theta">
                  {t(
                    'payment:step_method_credit_card_feature_description_first'
                  )}
                </p>
                <p className="typo-theta">
                  {t(
                    'payment:step_method_credit_card_feature_description_second'
                  )}
                </p>
                <p className="typo-theta t-mb-2">
                  {t(
                    'payment:step_method_credit_card_feature_description_third'
                  )}
                </p>
              </div>
            </div>
          )}
        </>
      </RadioCard>
      <div className="t-flex t-justify-end">
        <Button
          onClick={handleNextStepMethod}
          disabled={!methodOption}
          className="t-mt-6"
          loading={loading}
        >
          {t('payment:step_method_pay_and_proceed')}
        </Button>
      </div>
    </div>
  );
}
