import React, { ReactNode } from 'react';

import { ReactComponent as IconExclamation } from 'images/exclamation-mark-with-circle.svg';

interface WarningMessageProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export default function WarningMessage({
  children,
  className,
}: WarningMessageProps) {
  return (
    <div
      className={`t-bg-eta-100 t-rounded-sm t-py-1 t-pr-3 t-flex t-justify-between ${className}`}
    >
      <div>
        <IconExclamation className="t-text-zeta-600 t-ml-4 t-mr-2 t-mt-0.5" />
      </div>

      <p className="typo-epsilon t-text-zeta-600 t-flex-grow">{children}</p>
    </div>
  );
}
