import { values } from 'lodash';

import { dateHelper, config } from 'modules/common/helpers';
import { ASSET_CATEGORIES } from 'modules/estatePlan/constants';
import {
  AFTERLIFE_SUBTYPES,
  DIGITAL_SUBTYPES,
  FINANCIAL_SUBTYPES,
  HEALTH_SUBTYPES,
  INSURANCES_SUBTYPES,
  LEGAL_SUBTYPES,
} from 'modules/estatePlan/constants/enums';
import {
  ADVISOR_TYPES,
  ATTORNEY_TYPES,
  BANK_ACCOUNT,
  CREDIT_CARD_PROVIDERS,
  HEALTH_INSURANCE,
  INSURANCES,
  LIST_OF_CONTACTS,
  LONG_TERM_ASSETS,
  ONGOING_CONTRACTS,
  OTHER_HEALTH_INSURANCE,
  PASSWORD,
  PERSONAL_DOCUMENTS,
} from 'modules/common/constants/subtypes';
import { POWER_OF_ATTORNEY } from 'modules/estatePlan/constants/subtypes';

function createBequestOptions(
  assetsData: AssetsDTO,
  category: string,
  t: (key: string, variables?: TransVariables) => string
) {
  const assetsOptions = assetsData[category]
    .filter((item) => !item.notProvided)
    .map((item) => ({
      value: item.id,
      label: findAvailableLabel(item, category, t),
    }));
  return assetsOptions;
}

function findAvailableLabel(
  asset: AssetDTO,
  category: string,
  t: (key: string, variables?: TransVariables) => string
) {
  const {
    provider,
    assetTypeLabel,
    name,
    date,
    subtype,
    documents,
    assetTypeName,
  } = asset;

  const formattedSubtypeLabel = (assetTypeName, subtype) =>
    getSubtypeOptions(assetTypeName, t)?.find((item) => item.value === subtype)
      ?.label;

  switch (category) {
    case ASSET_CATEGORIES.DEVICES:
      if (provider && assetTypeLabel)
        return `${t(assetTypeLabel)} - ${provider}`;
      return `${t(assetTypeLabel!)}`;
    case ASSET_CATEGORIES.HEALTH:
      if (documents.length) return documents[0].documentTitle;
      if (date && assetTypeLabel) return `${t(assetTypeLabel)} - ${asset.date}`;
      return t(assetTypeLabel!);
    case ASSET_CATEGORIES.LEGAL:
      if (subtype)
        return formattedSubtypeLabel(assetTypeName, subtype) || subtype;
      if (documents.length) return documents[0].documentTitle;
      if (date) return dateHelper.convertDateFormat(date, config.format.uiDate);
      return t(assetTypeLabel!);
    case ASSET_CATEGORIES.DIGITAL:
      if (name) return name;
      if (subtype)
        return formattedSubtypeLabel(assetTypeName, subtype) || subtype;
      if (provider) return provider;
      return t(assetTypeLabel!);
    case ASSET_CATEGORIES.FINANCIAL:
      if (name) return name;
      if (provider) return provider;
      if (subtype)
        return formattedSubtypeLabel(assetTypeName, subtype) || subtype;
      if (documents.length) return documents[0].documentTitle;
      return t(assetTypeLabel!);
    case ASSET_CATEGORIES.AFTERLIFE:
      if (documents.length)
        return `${t(assetTypeLabel!)} - ${documents[0].documentTitle}`;
      if (subtype)
        return `${t(assetTypeLabel!)} - ${
          formattedSubtypeLabel(assetTypeName, subtype) || subtype
        }`;
      return t(assetTypeLabel!);
    case ASSET_CATEGORIES.INSURANCE:
      if (subtype)
        return `${t(assetTypeLabel!)} - ${
          formattedSubtypeLabel(assetTypeName, subtype) || subtype
        }`;
      if (provider) return `${t(assetTypeLabel!)} - ${provider}`;
      if (documents.length)
        return `${t(assetTypeLabel!)} - ${documents[0].documentTitle}`;
      return t(assetTypeLabel!);
    default:
      return '';
  }
}

const getSubtypeOptions = (
  fieldSubtype,
  t: (key: string, variables?: TransVariables) => string
) => {
  const formatOptions = (subtype) =>
    values(subtype).map((item) => ({
      value: item,
      label: t(`subtypes:${item}`),
    }));
  switch (fieldSubtype) {
    case HEALTH_SUBTYPES.HEALTH_INSURANCE:
      return formatOptions(HEALTH_INSURANCE);
    case HEALTH_SUBTYPES.OTHER_HEALTH_INSURANCE:
      return formatOptions(OTHER_HEALTH_INSURANCE);
    case INSURANCES_SUBTYPES.OTHER_INSURANCES:
      return formatOptions(INSURANCES);
    case LEGAL_SUBTYPES.PERSONAL_DOCUMENTS:
      return formatOptions(PERSONAL_DOCUMENTS);
    case LEGAL_SUBTYPES.POWER_OF_ATTORNEY:
      return formatOptions(POWER_OF_ATTORNEY);
    case FINANCIAL_SUBTYPES.LONG_TERM_ASSETS:
      return formatOptions(LONG_TERM_ASSETS);
    case FINANCIAL_SUBTYPES.ONGOING_CONTRACTS:
      return formatOptions(ONGOING_CONTRACTS);
    case DIGITAL_SUBTYPES.PASSWORD:
      return formatOptions(PASSWORD);
    case FINANCIAL_SUBTYPES.BANK:
      return formatOptions(BANK_ACCOUNT);
    case AFTERLIFE_SUBTYPES.LIST_OF_CONTACTS:
      return formatOptions(LIST_OF_CONTACTS);
    case FINANCIAL_SUBTYPES.CREDIT_CARD:
      return formatOptions(CREDIT_CARD_PROVIDERS);
    case FINANCIAL_SUBTYPES.FINANCIAL_ADVISOR:
      return formatOptions(ADVISOR_TYPES);
    case LEGAL_SUBTYPES.ATTORNEY:
      return formatOptions(ATTORNEY_TYPES);
    default:
      return null;
  }
};

export { createBequestOptions };
