const securePhoneNumber = (phone: string) => {
  if (!phone) return '';
  const isPlusAvailable = phone[0] === '+';
  const completePhone = isPlusAvailable ? phone : '+' + phone;
  const phoneLength = completePhone.length;
  if (phoneLength < 6) return completePhone;
  return completePhone.replace(
    /(\d{2})(.*)(\d{3})/,
    `$1${'X'.repeat(phoneLength - 6)}$3`
  );
};

export default securePhoneNumber;
