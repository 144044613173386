import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { getCurrentUser, resetPassword } from 'store/actions/userActions';
import { authHelper } from 'modules/auth/helpers';
import { config, useTranslation } from 'modules/common/helpers';

import { FormConditionsNew } from 'modules/profile/components';
import { TextInput, Spinner } from 'modules/common/components';
import {
  SignUpLogoAndLanguage,
  SetPasswordHeader,
} from 'modules/auth/components';

interface SetPasswordFormProps {
  customization: ClientCustomization;
  token: string;
  client?: string;
  fullName?: string;
}

export default function SetPasswordForm({
  customization,
  token,
  client,
  fullName = '',
}: SetPasswordFormProps) {
  const { t } = useTranslation(['common', 'auth']);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [pwValidity, setPwValidity] = useState({} as PasswordValidity);
  const [isPasswordTyping, setIsPasswordTyping] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setPwValidity(authHelper.getPasswordValidity(password));
  }, [password]);

  const onChange = (_, value) => {
    setPassword(value);
  };

  const isValidResetForm = () => {
    setError(authHelper.getPasswordErrorMessages(password, t));

    return setError;
  };

  const onSetPassword = async (e) => {
    if (e) e.preventDefault();

    if (!isValidResetForm()) return;

    setLoading(true);
    const response: any = await dispatch(resetPassword({ password }, token));

    if (response) {
      await dispatch(getCurrentUser());
      navigate('/register-wizard');
    }
    setLoading(false);
  };

  const onPasswordInputBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setIsPasswordTyping(false);
    }
  };

  const isPasswordInvalid = Object.values(pwValidity).some((el) => !el);

  return (
    <div
      className={
        customization?.withoutBgImg
          ? 't-min-h-screen t-flex t-items-center'
          : ''
      }
    >
      <form
        onSubmit={onSetPassword}
        className="Form t-pb-12 t-pt-5 md:t-py-12 md:t-px-16 md:t-max-w-sm t-bg-transparent"
      >
        <div>
          {customization?.partnershipLogo && (
            <div className="t-mt-12 t-hidden lg:t-block" />
          )}
          <div className={customization?.withoutBgImg ? `lg:t-hidden` : ''}>
            <SignUpLogoAndLanguage customization={customization} />
            <SetPasswordHeader
              customization={customization}
              client={client}
              fullName={fullName}
            />
          </div>

          <div
            className={`card t-shadow-lg t-bg-beta-50 t-p-6 t-mt-6 ${
              customization?.cardFormClassName || ''
            }`}
          >
            <h2 className="typo-beta t-mb-5">
              {t(
                customization?.customizedFormLabel
                  ? `auth:${client}_set_password_form_label`
                  : 'auth:set_password_form_label'
              )}
            </h2>
            <p className="typo-epsilon t-mb-5 t-text-beta-400">
              {customization?.formDescription
                ? t(`auth:${client}_set_password_form_description`)
                : t('auth:reset_page_create_password_description')}
            </p>
            <fieldset disabled={isLoading}>
              <TextInput
                name="password"
                label={t('common:password_label')}
                type="password"
                value={password}
                onChange={onChange}
                onBlur={onPasswordInputBlur}
                onFocus={() => {
                  setIsPasswordTyping(true);
                }}
                placeholder={t('auth:password_placeholder')}
                error={error}
              />
            </fieldset>
            <div className="t-relative">
              {isPasswordTyping && isPasswordInvalid && (
                <div className="t-absolute -t-top-2 t-bg-beta-50 t-animate-fade-in-100">
                  <FormConditionsNew pwValidity={pwValidity} />
                </div>
              )}
            </div>
            <div className="t-mt-5">
              <p className="typo-theta t-text-beta-700">
                {t('auth:signup_click_text')}&nbsp;
                <a
                  href={`${config.websiteUrl}/terms`}
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                  className={`t-no-underline t-text-alpha-600 ${
                    customization?.customizedPrimaryButton
                      ? `${client}-link-color`
                      : ''
                  }`}
                >
                  {t('auth:signup_agreement')}
                </a>
                <span>{` ${t('common:and')} `}</span>
                <a
                  href={
                    client === 'vbbb'
                      ? `${config.websiteUrl}/privacy-vbbb`
                      : `${config.websiteUrl}/privacy`
                  }
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                  className={`t-no-underline t-text-alpha-600 ${
                    customization?.customizedPrimaryButton
                      ? `${client}-link-color`
                      : ''
                  }`}
                >
                  {t('auth:signup_privacy')}
                </a>
              </p>
            </div>
            <button
              type="submit"
              disabled={isLoading}
              onClick={onSetPassword}
              className={`button t-mt-6 ${
                customization?.customizedPrimaryButton
                  ? `${client}-set-password-button-primary`
                  : 'button-primary'
              }`}
            >
              {isLoading && <Spinner className="t-h-5 t-w-5 t-mr-2" />}
              {t(
                customization?.customizedRegisterButtonText
                  ? `auth:${client}_set_password_button_text`
                  : 'auth:save_and_login'
              )}
            </button>
          </div>

          <div className="t-mt-4">
            <p className="typo-theta t-pb-4">
              {t('auth:signup_have_acc')}
              <Link
                className={`t-font-semibold t-ml-1 ${
                  customization?.customizedPrimaryButton
                    ? `${client}-link-color-dark-bg`
                    : 't-text-alpha-600'
                }`}
                to="/login"
              >
                {t(
                  customization?.customizedLoginButtonText
                    ? `auth:${client}_login_button_text`
                    : 'common:sign_in'
                )}
              </Link>
            </p>
            <div className="sm:t-flex t-justify-end t-items-end t-flex-col sm:t-flex-row">
              {customization?.bottomPartnership && (
                <div className="t-mt-2">
                  <p
                    className={`typo-eta t-text-beta-700 t-font-semibold sm:t-mt-0 ${
                      customization?.darkBackground
                        ? 'lg:t-text-beta-700 md:t-text-gamma-400'
                        : 't-text-beta-700'
                    }`}
                  >
                    {t('common:in_partnership_with')}
                  </p>
                  <div className="t-mt-2">
                    <img
                      className="t-h-7"
                      src={customization?.partnershipLogo}
                      alt="partnership"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
