import config from 'modules/common/helpers/configHelper';
import httpHelper from 'modules/common/helpers/httpHelper';
import utilHelper from 'modules/common/helpers/caseConvertHelper';

const BASE_URL = config.beUrl;

function getAssetTypes() {
  return httpHelper.get(`${BASE_URL}/api/asset-types`, {});
}

function getBeneficiaryAssetTypes() {
  return httpHelper.get(`${BASE_URL}/beneficiary/asset-types`, {});
}

function getAssetTypesByCategory(category: string) {
  return httpHelper.get(`${BASE_URL}/api/asset-types`, {
    category: utilHelper.toLowerStartCase(category),
  });
}

function getAssetType(id: string) {
  return httpHelper.get(`${BASE_URL}/api/asset-types`, { id });
}

function addAssetType(assetType: AssetTypeDTO) {
  return httpHelper.post(`${BASE_URL}/api/asset-type`, { ...assetType });
}

function deleteAssetType(id: string) {
  return httpHelper.delete(`${BASE_URL}/api/asset-type`, { id });
}

function getOrCreateAssetTypes(id: string) {
  return httpHelper.get(`${BASE_URL}/api/asset-types/${id}`, {});
}

export default {
  getAssetTypes,
  getAssetTypesByCategory,
  getAssetType,
  deleteAssetType,
  addAssetType,
  getBeneficiaryAssetTypes,
  getOrCreateAssetTypes,
};
