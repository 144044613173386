import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { UpgradeProfileInfoModal } from 'modules/menu/components';
import { Button } from 'modules/common/components';

const UpgradeProfile = () => {
  const { t } = useTranslation(['common']);

  const [showInfoModal, setShowInfoModal] = React.useState(false);

  const upgradeProfileInfoModalOpen = () => {
    setShowInfoModal(true);
  };

  const upgradeProfileInfoModalClose = () => {
    setShowInfoModal(false);
  };

  return (
    <>
      {showInfoModal && (
        <UpgradeProfileInfoModal onClose={upgradeProfileInfoModalClose} />
      )}
      <li data-testid="UpgradeProfile">
        <Button onClick={upgradeProfileInfoModalOpen} className="t-mr-2.5">
          {t('common:upgrade_profile')}
        </Button>
      </li>
    </>
  );
};

export default UpgradeProfile;
