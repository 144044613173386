import React from 'react';
import ReactTooltip from 'react-tooltip';

import { useTranslation } from 'modules/common/helpers';

import { Button, TextWithHyphenSpan } from 'modules/common/components';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';

interface AssetTypeTemplateCardProps {
  addTemplate: (assetType: AssetTypeDTO) => void;
  template: AssetTypeDTO;
}

export default function AssetTypeTemplateCard({
  template,
  addTemplate,
}: AssetTypeTemplateCardProps) {
  const { t } = useTranslation(['common', 'plan']);

  const absoluteCenteredElementClassNames =
    't-absolute t-top-0 t-left-0 t-flex t-justify-center t-items-center t-opacity-0 group-hover:t-opacity-100';

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  return (
    <div className="card t-p-7 t-group t-relative t-min-h-28 hover:t-bg-beta-100 t-border t-border-solid t-border-gamma-400 hover:t-border-alpha-600">
      {template.custom && (
        <div className="t-absolute t-top-2.5 t-right-2.5 t-flex t-z-50">
          <IconInfo
            role="presentation"
            className="t-text-delta-700"
            data-tip={t('plan:custom_asset_type_card_info')}
          />
        </div>
      )}
      <div
        className={`t-w-full t-h-full t-z-20 ${absoluteCenteredElementClassNames}`}
      >
        <Button
          onClick={() => addTemplate(template)}
          className="t-shadow-none t-p-3"
        >
          {t('plan:start_adding_asset')}
        </Button>
      </div>

      <div className="group-hover:t-opacity-60">
        <h1 className="typo-beta t-text-delta-700 t-mb-2">
          <TextWithHyphenSpan>{t(template.label)}</TextWithHyphenSpan>
        </h1>
        <p className="t-text-beta-400">
          {t(template.description || 'plan:default_asset_type_description')}
        </p>
      </div>
    </div>
  );
}
