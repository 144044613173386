import { assetTypeService } from 'modules/estatePlan/services';
import {
  ADD_ASSET_TYPE,
  GET_ASSET_TYPES,
  DELETE_ASSET_TYPE,
  GET_ASSET_TYPES_BY_CATEGORY,
} from 'store/types/assetTypeTypes';
import helper from 'store/actions/actionHelper';

export const getAssetTypesList = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const assetTypes = await assetTypeService.getAssetTypes();

    dispatch(helper.successAction(GET_ASSET_TYPES, { assetTypes }));
  }, actionOptions);
};

export const getBeneficiaryAssetTypesList = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const assetTypes = await assetTypeService.getBeneficiaryAssetTypes();

    dispatch(helper.successAction(GET_ASSET_TYPES, { assetTypes }));
  }, actionOptions);
};

export const getAssetType = (id: string, actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await assetTypeService.getAssetType(id);
    return response;
  }, actionOptions);
};

export const addAssetType = (
  assetType: AssetTypeDTO,
  category: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const newAssetType = await assetTypeService.addAssetType(assetType);
    dispatch(
      helper.successAction(ADD_ASSET_TYPE, {
        newAssetType,
        assetCategory: category,
      })
    );
    return newAssetType;
  }, actionOptions);
};

export const deleteAssetType = (
  assetTypeId: string,
  category: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    await assetTypeService.deleteAssetType(assetTypeId);
    dispatch(
      helper.successAction(DELETE_ASSET_TYPE, {
        assetTypeId,
        assetCategory: category,
      })
    );
  }, actionOptions);
};

export const listAssetTypesByCategory = (
  category: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const assetTypesByCategory = await assetTypeService.getAssetTypesByCategory(
      category
    );
    dispatch(
      helper.successAction(GET_ASSET_TYPES_BY_CATEGORY, {
        assetTypes: assetTypesByCategory,
        assetCategory: category,
      })
    );
  }, actionOptions);
};

export const getOrCreateAssetTypes = (
  id: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const assetTypeOverview = await assetTypeService.getOrCreateAssetTypes(id);
    if (assetTypeOverview) {
      await dispatch(getAssetTypesList());
    }

    return assetTypeOverview;
  }, actionOptions);
};
