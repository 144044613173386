import React from 'react';
import { useDispatch } from 'react-redux';

import { downloadDocument } from 'store/actions/assetActions';
import { getCurrentUser } from 'store/actions/userActions';
import { useTranslation } from 'modules/common/helpers';

import { Modal } from 'modules/common/components';
import { ProfileForm } from 'modules/profile/components';
import { RegistrationZVRProcess } from 'modules/zvr/components';
import {
  FinalizeStepModal,
  UserResidenceAboardWarningModal,
} from 'modules/assistant/components/Questionnaire';

import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';

interface FinalizeStepSwitcherProps {
  questionnaire: QUESTIONNAIRE_KEYS;
  isFinalizeStep: boolean;
  handleFinalizeStep: (germanLawWarningPassed?: boolean) => void;
  handleFinalizeStepCancel: () => void;
  isUserResidenceAbroadStep: boolean;
  setUserResidenceAbroadStep: (warning: boolean) => void;
  showProfileForm: boolean;
  setShowProfileForm: (show: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const FinalizeStepSwitcher = ({
  questionnaire,
  isFinalizeStep,
  handleFinalizeStep,
  handleFinalizeStepCancel,
  isUserResidenceAbroadStep,
  setUserResidenceAbroadStep,
  showProfileForm,
  setShowProfileForm,

  loading,
  setLoading,
}: FinalizeStepSwitcherProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'assistant']);

  const [isZVRProcess, setIsZVRProcess] = React.useState(false);
  const [document, setDocument] = React.useState({} as DocumentDTO);

  const handleCancelZVRProcess = () => {
    setIsZVRProcess(false);
  };
  const handleDocumentDownload = async () => {
    setLoading(true);
    await dispatch(downloadDocument(document));
    setLoading(false);
  };

  const handleCloseProfileForm = async () => {
    await dispatch(getCurrentUser());
    setShowProfileForm(false);
  };

  const handleEnterZVRProcess = (document: DocumentDTO) => {
    setDocument(document);
    setIsZVRProcess(true);
  };

  const handleHideModal = () => {
    setLoading(false);
    setIsZVRProcess(false);
    setShowProfileForm(false);
    setUserResidenceAbroadStep(false);
  };

  switch (true) {
    case !!isUserResidenceAbroadStep:
      return (
        <UserResidenceAboardWarningModal
          onClose={() => {
            setUserResidenceAbroadStep(false);
            handleFinalizeStep(true);
          }}
          openProfileForm={() => {
            setUserResidenceAbroadStep(false);
            setShowProfileForm(true);
          }}
        />
      );
    case !!showProfileForm:
      return (
        <Modal show onHide={handleHideModal} hasCloseCross>
          <h1 className="Typography Typography--title u-weight-600 t-pb-5">
            {t('assistant:profile_not_complete_form_title')}
          </h1>
          <div className="d-flex">
            <ProfileForm
              isProfilePage={false}
              onClose={handleHideModal}
              onSuccess={handleCloseProfileForm}
            />
          </div>
        </Modal>
      );
    case !!isZVRProcess:
      return (
        <RegistrationZVRProcess
          downloading={loading}
          onCancelProcess={handleCancelZVRProcess}
          handleDocumentDownload={handleDocumentDownload}
          questionnaire={questionnaire}
        />
      );
    case !!isFinalizeStep:
      return (
        <FinalizeStepModal
          questionnaire={questionnaire}
          onClose={handleFinalizeStepCancel}
          handleZVRProcess={handleEnterZVRProcess}
        />
      );
    default:
      return null;
  }
};

export default FinalizeStepSwitcher;
