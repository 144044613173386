import React from 'react';

import { assetService } from 'modules/estatePlan/services';
import { useTranslation } from 'modules/common/helpers';

import { AccountAssetCard } from 'modules/asset/components';
import { Button, Modal } from 'modules/common/components';
import { Col, Row } from 'modules/bootstrap/components';

import { PROVIDER_CONSTANT_TYPES } from 'modules/estatePlan/constants/enums';

interface LinkCurrentAssetModalProps {
  assets: AssetDTO[];
  onClose: () => void;
  onSave: (asset: AssetDTO | undefined) => void;
  customTitle?: string;
  customDescription?: string;
}

const LinkCurrentAssetModal = ({
  assets,
  onClose,
  onSave,
  customTitle,
  customDescription,
}: LinkCurrentAssetModalProps) => {
  const { t } = useTranslation(['plan', 'common']);
  const [displayedAssets, setDisplayedAssets] =
    React.useState<AssetDTO[]>(assets);
  const [selectedAsset, setSelectedAsset] = React.useState<AssetDTO | null>(
    null
  );
  const [providersLoading, setProvidersLoading] = React.useState(false);

  React.useEffect(() => {
    if (assets.length > 0) {
      getAssetsWithAllFormattedProviders();
    }
  }, []);

  const getAssetsWithAllFormattedProviders = async () => {
    setProvidersLoading(true);
    const formattedAssets = await Promise.all(
      assets.map(async (asset) => {
        if (asset?.provider) {
          const formattedProvider = await assetService.getProviderByKey(
            asset.assetTypeName as PROVIDER_CONSTANT_TYPES,
            asset.provider
          );
          if (formattedProvider)
            return { ...asset, provider: formattedProvider.value };
        }
        return asset;
      })
    );
    setProvidersLoading(false);

    setDisplayedAssets(formattedAssets);
  };

  const handleAssetSelect = (assetId: string) => {
    const asset = displayedAssets.find((asset) => asset.id === assetId);
    setSelectedAsset(asset || null);
  };

  const handleSelectedAssetSave = () => {
    if (selectedAsset) {
      onSave(selectedAsset);
      onClose();
    }
  };

  return (
    <Modal show onHide={onClose} dataTestid="LinkCurrentAssetModal">
      <h1 className="typo-alpha">
        {customTitle || t('plan:on_going_costs_link_current_asset_modal_title')}
      </h1>
      <p className="typo-epsilon t-mt-4">
        {customDescription ||
          t('plan:on_going_costs_link_current_asset_modal_desc')}
      </p>

      <Row className="t-mt-7">
        {displayedAssets?.map((asset) => (
          <Col sm={6} key={asset.id} className="t-pb-5">
            <AccountAssetCard
              asset={asset}
              selectedAssetId={
                asset.id === selectedAsset?.id ? asset.id || '' : ''
              }
              handleAssetSelect={handleAssetSelect}
            />
          </Col>
        ))}
      </Row>

      <div className="t-flex t-justify-end t-items-end t-mt-4 t-flex-col sm:t-flex-row">
        <div>
          <Button
            category="secondary"
            className="t-mr-0 sm:t-mr-3 t-py-2 t-mb-3 sm:t-mb-0"
            onClick={onClose}
          >
            {t('common:cancel')}
          </Button>
        </div>

        <div>
          <Button
            className="t-py-2"
            onClick={handleSelectedAssetSave}
            loading={providersLoading}
            disabled={!selectedAsset}
          >
            {t('common:save_and_continue')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LinkCurrentAssetModal;
