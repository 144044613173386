import imgBenefit1 from 'images/img-auth-benefit-1.png';
import imgBenefit2 from 'images/img-auth-benefit-2.png';
import imgBenefit3 from 'images/img-auth-benefit-3.png';

import imgBeneSupportive1 from 'images/img-bene-supportive-1.png';
import imgBeneSupportive2 from 'images/img-bene-supportive-2.png';
import imgBeneSupportive3 from 'images/img-bene-supportive-3.png';

import imgKeyHolderSupportive1 from 'images/img-key-holder-info-1.png';
import imgKeyHolderSupportive2 from 'images/img-key-holder-info-2.png';
import imgKeyHolderSupportive3 from 'images/img-key-holder-info-3.png';

export const commonFeatures = [
  {
    id: 1,
    title: 'auth:feature_section_label_1',
    description: 'auth:feature_section_description_1',
    img: imgBenefit1,
  },
  {
    id: 2,
    title: 'auth:feature_section_label_2',
    description: 'auth:feature_section_description_2',
    img: imgBenefit2,
  },
  {
    id: 3,
    title: 'auth:feature_section_label_3',
    description: 'auth:feature_section_description_3',
    img: imgBenefit3,
  },
];

export const beneFeatures = [
  {
    id: 1,
    title: 'auth:bene_access_feature_section_label_1',
    description: 'auth:bene_access_feature_section_description_1',
    img: imgBeneSupportive1,
  },
  {
    id: 2,
    title: 'auth:bene_access_feature_section_label_2',
    description: 'auth:bene_access_feature_section_description_2',
    img: imgBeneSupportive2,
  },
  {
    id: 3,
    title: 'auth:bene_access_feature_section_label_3',
    description: 'auth:bene_access_feature_section_description_3',
    img: imgBeneSupportive3,
  },
];

export const keyHolderFeatures = [
  {
    id: 1,
    title: 'afterDeath:key_holder_page_info_section_label_1',
    description: 'afterDeath:key_holder_page_info_section_description_1',
    img: imgKeyHolderSupportive1,
  },
  {
    id: 2,
    title: 'afterDeath:key_holder_page_info_section_label_2',
    description: 'afterDeath:key_holder_page_info_section_description_2',
    img: imgKeyHolderSupportive2,
  },
  {
    id: 3,
    title: 'afterDeath:key_holder_page_info_section_label_3',
    description: 'afterDeath:key_holder_page_info_section_description_3',
    img: imgKeyHolderSupportive3,
  },
];
