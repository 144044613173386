export default function getParsedAddress(formData) {
  const { streetAddress, zip, city, country } = formData || {};
  if (!streetAddress || !zip || !city || !country.value) return undefined;
  const addressData = {
    address: streetAddress,
    zip,
    city,
    country: country.value,
  };
  return JSON.stringify(addressData);
}
