import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { camelCase, groupBy } from 'lodash';

import { RootState } from 'store/reducers';
import { handleAssetsSearching } from 'store/actions/assetSearchActions';
import { getStringifiedAssetsValues } from 'modules/dashboard/helpers';
import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconCross } from 'images/cross.svg';
import { ReactComponent as IconSearch } from 'images/icon-search.svg';

interface AssetsSearchingProps {
  searchPlaceholder?: string;
  containerClassName?: string;
  innerContainerClassName?: string;
  inputFieldClassName?: string;
  buttonClassName?: string;
}

export default function AssetsSearching({
  searchPlaceholder,
  containerClassName,
  inputFieldClassName,
  buttonClassName,
  innerContainerClassName,
}: AssetsSearchingProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'plan', 'dashboard']);
  const location = useLocation();
  const currentLocation = location.pathname;

  const assetsData: AssetsDTO = useSelector((state: RootState) => state.assets);
  const assets: AssetDTO[] = Object.values(assetsData).flat();

  const [searching, setSearching] = React.useState(false);
  const [searchKeywords, setSearchKeywords] = React.useState('');
  const [searchedCategAssets, setSearchedCategAssets] =
    React.useState(assetsData);
  const [searchedOrFilteredCategAssets, setSearchedOrFilteredCategAssets] =
    React.useState(searchedCategAssets);

  // reset search input when navigating to another page
  React.useEffect(() => {
    setSearchKeywords('');
  }, [currentLocation]);

  React.useEffect(() => {
    dispatch(
      handleAssetsSearching({
        searching,
        result: searchedOrFilteredCategAssets,
      })
    );
  }, [searching, searchedOrFilteredCategAssets]);

  const stringifiedAssetsValues = getStringifiedAssetsValues(assets);

  const searchHandler = (keywords: string) => {
    if (!keywords) return assetsData;
    const listOfKeywords = keywords.split(' ').filter((keyword) => keyword);
    const searchedAssets = stringifiedAssetsValues
      .filter((assetValuesStr) => {
        for (const keyword of listOfKeywords) {
          if (!assetValuesStr.includes(keyword.toLowerCase())) return false;
        }
        return true;
      })
      .map(
        (assetValuesStr) =>
          assets[stringifiedAssetsValues.indexOf(assetValuesStr)]
      )
      .map((asset) => {
        const copiedAsset = { ...asset };
        copiedAsset.category = camelCase(copiedAsset.category);
        return copiedAsset;
      });

    return groupBy(searchedAssets, (asset) => asset.category);
  };

  const onSearch = (event) => {
    setSearching(true);
    const keywords = event.target.value;
    setSearchKeywords(keywords);
    const searchResults = searchHandler(keywords);
    setSearchedCategAssets(searchResults);
    setSearchedOrFilteredCategAssets(searchResults);
  };

  const onSearchFieldBlur = () => {
    if (!searchKeywords) {
      setSearching(false);
    }
  };

  const handleSearchCancel = () => {
    setSearching(false);
    setSearchKeywords('');
  };

  return (
    <div className={containerClassName || ''}>
      <div
        className={`t-flex t-items-center t-rounded-full ${innerContainerClassName}`}
      >
        <input
          className={`t-rounded-l-full t-w-full t-py-2 t-px-6 t-text-gamma-200 t-placeholder-gamma-200 t-leading-tight focus:t-outline-none ${
            inputFieldClassName || ''
          }`}
          id="search"
          type="text"
          placeholder={searchPlaceholder || t('dashboard:search')}
          name="search"
          value={searchKeywords}
          onChange={(event) => onSearch(event)}
          onBlur={onSearchFieldBlur}
        />
        <button
          className={`t-rounded-full t-p-2 focus:t-outline-none t-w-10 t-h-10 t-flex t-items-center t-justify-center ${
            !!searchKeywords
              ? 't-text-epsilon-600 hover:t-bg-alpha-200'
              : 't-text-alpha-600 t-cursor-default'
          } ${buttonClassName || ''}`}
          disabled={!searchKeywords}
          onClick={handleSearchCancel}
        >
          {!searchKeywords ? <IconSearch /> : <IconCross />}
        </button>
      </div>
    </div>
  );
}
