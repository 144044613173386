import styled, { css } from 'styled-components';

const topStyle = css`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const bottomStyle = css`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const isAlmostReadyStyle = css`
  border: 1px solid rgba($cerise-red, 0.4);
  box-shadow: 0px 4px 20px rgba($ship-gray, 0.1);
`;

const Card = styled.div`
  border-radius: var(--border-radius);
  position: relative;
  padding: ${({ p }) => p || '30px'};
  margin: ${({ m }) => m || '0'};
  width: ${({ width }) => width || '100%'};
  background-color: ${({ bg }) => bg || 'var(--color-gamma-400)'};
  box-shadow: ${({ shadow }) =>
    shadow && `0 4px 20px rgba(var(--color-beta-700), 0.1)`};
  ${(props) => props.top && topStyle};
  ${(props) => props.bottom && bottomStyle};
  ${(props) => props.isAlmostReady && isAlmostReadyStyle};
`;

export default Card;
