import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';

import { RootState } from 'store/reducers';
import { getPaymentSession } from 'store/actions/userActions';
import { getStripePromise } from 'modules/payment/helpers';

import {
  StepBilling,
  ProgressBar,
  StepMethod,
  StepSepa,
  StepSuccess,
} from 'modules/payment/components';

import {
  BILLING_OPTIONS,
  PAYMENT_METHODS,
  PAYMENT_PRICES,
  PAYMENT_STEPS,
} from 'modules/payment/constants';
import { getFullName, localizeNumber } from 'modules/common/helpers';
import { LANGUAGES, LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';
import { EMAIL } from 'modules/common/constants/generic';

import logo from 'images/logo.svg';

const { CREDIT_CARD, SEPA } = PAYMENT_METHODS;
const {
  STEP_CHOOSE_BILLING,
  STEP_PAYMENT_METHOD,
  STEP_SEPA_FORM,
  STEP_SUCCESS,
} = PAYMENT_STEPS;
const { LIFETIME, YEARLY } = BILLING_OPTIONS;

interface PaymentProps {
  handlePaymentToggle: () => void;
}

export interface PricesInfo {
  originalYearlyPrice: number;
  yearlyPrice: number;
  yearlyDiscount: number;
  lifetimePrice: number;
  lifetimeDiscount: number;
  originalLifetimePrice: string;
  formattedLifetimePrice: string;
}

export default function Payment({ handlePaymentToggle }: PaymentProps) {
  const dispatch = useDispatch();
  const { register, watch } = useForm({ shouldUnregister: false });
  const [step, setStep] = useState<number>(STEP_CHOOSE_BILLING);
  const [loading, setLoading] = useState(false);
  // avoid strip Elements props to be changed after setting it
  const [stripePromise] = useState(() => getStripePromise());

  const billingOption = watch('billing');
  const methodOption = watch('method');

  const user = useSelector((state: RootState) => state.user.current);
  const prices = user?.prices || {};
  const agent = user?.agent || {};
  const agentName =
    agent.name || agent.surname
      ? getFullName(agent, false)
      : agent.company
      ? agent.company
      : '';

  const lng =
    localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE) || LANGUAGES.DE;
  // For lifetime billing option
  const originalLifetimePrice = localizeNumber(
    PAYMENT_PRICES[LIFETIME].original,
    lng
  );
  const originalYearlyPrice = PAYMENT_PRICES[YEARLY].original;

  const lifetimePrice =
    prices[LIFETIME]?.price || PAYMENT_PRICES[LIFETIME].price;
  const lifetimeDiscount =
    (prices[LIFETIME]?.discount || PAYMENT_PRICES[LIFETIME]?.discount || 0) *
    100;

  // For yearly billing option
  const yearlyPrice = prices[YEARLY]?.price || PAYMENT_PRICES[YEARLY].price;
  const yearlyDiscount =
    (prices[YEARLY]?.discount || PAYMENT_PRICES[YEARLY]?.discount || 0) * 100;

  const formattedLifetimePrice = localizeNumber(lifetimePrice.toFixed(2), lng);

  const pricesInfo = {
    originalYearlyPrice,
    yearlyPrice,
    yearlyDiscount,
    lifetimePrice,
    lifetimeDiscount,
    originalLifetimePrice,
    formattedLifetimePrice,
  } as PricesInfo;

  const stepComponents = {
    [STEP_CHOOSE_BILLING]: (
      <StepBilling
        billingOption={billingOption}
        register={register}
        handleNextStepBilling={handleNextStepBilling}
        pricesInfo={pricesInfo}
        agentName={agentName}
        loading={loading}
      />
    ),
    [STEP_PAYMENT_METHOD]: (
      <StepMethod
        register={register}
        handleNextStepMethod={handleNextStepMethod}
        methodOption={methodOption}
        loading={loading}
      />
    ),
    [STEP_SEPA_FORM]: (
      <StepSepa
        handleGoToSuccessScreen={handleGoToSuccessScreen}
        billingOption={billingOption}
        pricesInfo={pricesInfo}
      />
    ),
    [STEP_SUCCESS]: <StepSuccess handleCloseModal={handlePaymentToggle} />,
  };

  const isLifetimePayment = billingOption === BILLING_OPTIONS.LIFETIME;

  function handleNextStepBilling() {
    if (isLifetimePayment) {
      handleNextStepMethod();
    } else {
      setStep(STEP_PAYMENT_METHOD);
    }
  }

  function handleGoToSuccessScreen() {
    setStep(STEP_SUCCESS);
  }

  async function handleNextStepMethod() {
    setLoading(true);
    if (methodOption === CREDIT_CARD || isLifetimePayment) {
      const sessionRes: any = await dispatch(getPaymentSession(billingOption));
      if (sessionRes) {
        const { sessionId } = sessionRes;
        getStripePromise().then((res) => {
          // save email to local storage to allow login back using access token
          localStorage.setItem(EMAIL, user.email);
          res.redirectToCheckout({ sessionId });
        });
      }
    } else if (methodOption === SEPA) {
      setStep(STEP_SEPA_FORM);
    }
    setLoading(false);
  }

  return (
    <div
      className="t-w-full md:t-w-1/2 t-h-full t-flex t-flex-col t-pb-24 xl:t-items-center"
      data-testid="Payment"
    >
      <div className="t-mx-6 sm:t-mx-12 md:t-mx-8 lg:t-mx-16 t-mt-12 xl:t-w-2/3">
        <img src={logo} alt="ninebarc-logo" className="t-mb-12" />
        <ProgressBar
          currentStep={step}
          handleStepChange={setStep}
          billingOption={billingOption}
          methodOption={methodOption}
        />
        <div className="t-mb-16" />
        <Elements stripe={stripePromise}>{stepComponents[step]}</Elements>
      </div>
    </div>
  );
}
