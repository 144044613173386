import familyAccountImage from 'images/releases/img-family-accounts.png';

// The top (releaseNotes[0]) is the newest release note
export const releaseNotes: ReleaseNote[] = [
  // family account features
  {
    image: familyAccountImage,
    title: 'releases:family_accounts_title',
    description: 'releases:family_accounts_desc',
    refreshButtonText: 'common:refresh',
  },
];
