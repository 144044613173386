import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { SuggestedAssetTypeCard } from 'modules/assetType/components';
import { Col, Row } from 'modules/bootstrap/components';

import { ReactComponent as IconChevroneDown } from 'images/v-shaped-down.svg';
import { ReactComponent as IconChevroneUp } from 'images/v-shaped-up.svg';

const ITEMS_TO_SHOW_WHEN_COLLAPSED = 2;

interface AssetTypeRecommendationsProps {
  category: string;
  recommendations: RecommendationDTO[];
}

export default function AssetTypeRecommendations({
  category,
  recommendations,
}: AssetTypeRecommendationsProps) {
  const { t } = useTranslation(['plan', 'recommendations']);

  // new react and react-router libraries won't re-initialize the component if only the category is changed
  // use useEffect to set the showMore variable to false when the category prop changes
  React.useEffect(() => {
    setShowMore(false);
  }, [category]);

  const [showMore, setShowMore] = React.useState(false);

  const isPossibleToShowMore =
    recommendations.length > ITEMS_TO_SHOW_WHEN_COLLAPSED;

  const shownRecommendations =
    showMore && isPossibleToShowMore
      ? recommendations
      : recommendations.slice(0, ITEMS_TO_SHOW_WHEN_COLLAPSED);

  const toggleShowMore = () => {
    setShowMore((prevState) => !prevState);
  };

  if (!shownRecommendations.length) {
    return null;
  }

  return (
    <div data-tour="vault_recommendation">
      <h2 className="typo-beta t-mb-5">
        {t('plan:suggestions_section_title')}
      </h2>
      <Row>
        {shownRecommendations?.map((recommendation, idx) => {
          return (
            <Col
              key={`${recommendation?.assetType}_${idx}`}
              sm={{ span: 6 }}
              className="t-pb-5"
            >
              <SuggestedAssetTypeCard recommendation={recommendation} />
            </Col>
          );
        })}
      </Row>
      {isPossibleToShowMore && (
        <div className="t-flex t-justify-center">
          <button
            onClick={toggleShowMore}
            className="typo-epsilon t-text-beta-500 t-mx-auto t-outline-none t-px-4 t-py-1.5 t-flex t-items-center"
          >
            {showMore
              ? t('recommendations:show_less')
              : t('recommendations:show_more')}
            {showMore ? (
              <IconChevroneUp className="t-ml-2" />
            ) : (
              <IconChevroneDown className="t-ml-2" />
            )}
          </button>
        </div>
      )}
    </div>
  );
}
