import React from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';

import { getRelativePath } from 'modules/common/helpers';

import { EpIntroduction, EpTransmortal } from 'modules/estatePlan/components';
import { AssetTypePage } from 'modules/assetType/components';

import {
  ASSET_CATEGORIES,
  PLAN_CATEGORY_PAGES,
  PLAN_STEPS,
} from 'modules/estatePlan/constants';
import { PLAN_STEP_KEYS } from 'modules/estatePlan/constants/constants';

interface EstatePlanPageSwitchProps {
  nextPlanStep: PlanStep;
  categorizedRecos: RecommendationDTO[];
  isVaultTutorial?: boolean;
}

export default function EstatePlanPageSwitch({
  nextPlanStep,
  categorizedRecos,
  isVaultTutorial,
}: EstatePlanPageSwitchProps) {
  const baseRoute = '/plan';

  // this only makes sense and work when the path is ":category/:assetTypeId/:assetId" and the category exists in PLAN_CATEGORY_PAGES;
  const params = useParams();
  const possibleParams = params['*']?.split('/') || [''];
  // take and remove the first element (the category)
  const categoryPath = possibleParams.shift() || '';
  const currentCategoryPage = PLAN_CATEGORY_PAGES.find((page) =>
    page.path.includes(categoryPath)
  );

  return (
    <Routes>
      <Route
        path="/"
        element={<EpIntroduction nextPlanStep={nextPlanStep} />}
      />
      <Route
        path={getRelativePath(
          PLAN_STEPS[PLAN_STEP_KEYS.INTRODUCTION].path,
          baseRoute
        )}
        element={<EpIntroduction nextPlanStep={nextPlanStep} />}
      />
      <Route
        path={getRelativePath(
          PLAN_STEPS[PLAN_STEP_KEYS.TRANSMORTAL].path,
          baseRoute
        )}
        element={<EpTransmortal />}
      />

      <Route
        path=":category/:assetTypeId/:assetId"
        element={
          <AssetTypePage
            assetCategory={
              currentCategoryPage?.category || ASSET_CATEGORIES.DEVICES
            }
            categorizedRecos={categorizedRecos}
            possibleParams={possibleParams}
            isVaultTutorial={isVaultTutorial}
          />
        }
      />

      {PLAN_CATEGORY_PAGES.map((page) => (
        <Route
          path={getRelativePath(page.path, baseRoute)}
          key={page.path}
          element={
            <AssetTypePage
              assetCategory={page.category}
              categorizedRecos={categorizedRecos}
              isVaultTutorial={isVaultTutorial}
            />
          }
        />
      ))}
      <Route
        path="*"
        element={<Navigate to={PLAN_STEPS[PLAN_STEP_KEYS.INTRODUCTION].path} />}
      />
    </Routes>
  );
}
