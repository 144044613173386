import React from 'react';

export type SwitcherProps = {
  name: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
  textPosition?: 'left' | 'right';
  textOnChecked?: string;
  textOnUnchecked?: string;
  textClassName?: string;
  isSubmitting?: boolean;
  customTextSpan?: React.ReactNode;
};

const Switcher = ({
  name,
  onChange,
  checked,
  textPosition = 'left',
  textOnChecked = '',
  textOnUnchecked = '',
  textClassName = '',
  isSubmitting = false,
  customTextSpan = undefined,
}: SwitcherProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  const switcherText = checked
    ? textOnChecked
    : textOnUnchecked || textOnChecked;

  const switcherTextSpan = (
    <span className={textClassName} id="switcherLabel">
      {switcherText}
    </span>
  );

  return (
    <>
      <label
        htmlFor={name}
        className={`Switcher ${isSubmitting ? 't-cursor-wait' : ''}`}
        aria-checked="false"
        aria-labelledby="switcherLabel"
        data-testid="switcher"
      >
        {textPosition === 'left' && (customTextSpan || switcherTextSpan)}
        <input
          disabled={isSubmitting}
          data-testid={name}
          onChange={handleChange}
          checked={checked}
          id={name}
          type="checkbox"
          className="jsCeckboxSource Switcher-source"
        />
        <span className="Switcher-result" />
        {textPosition === 'right' && (customTextSpan || switcherTextSpan)}
      </label>
    </>
  );
};

export default Switcher;
