import React from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import { Form } from 'modules/bootstrap/components';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';

interface TextAreaRefProps {
  name: string;
  label: string;
  defaultValue?: string;
  error?: string | false | null;
  disabled?: boolean;
  textareaClass?: string;
  maxLength?: number;
  placeholder?: string;
  rows?: number;
  withoutErrorMessage?: boolean;
  info?: string;
}

const TextAreaRef = React.forwardRef<HTMLTextAreaElement, TextAreaRefProps>(
  (
    {
      name,
      label,
      error,
      textareaClass,
      withoutErrorMessage,
      info,
      ...restProps
    },
    ref
  ) => {
    const wrapperClass = classnames({
      'Form-group': true,
      isErrored: error && error.length > 0,
    });

    const compProps = {
      ...restProps,
      'data-testid': name,
      id: name,
    };

    return (
      <div className={wrapperClass}>
        <div className="t-flex">
          <Form.Label
            htmlFor={name}
            className={`Form-label ${error ? 'isErrored' : ''}`}
          >
            {label}
          </Form.Label>

          {!!info && (
            <div>
              <IconInfo
                role="presentation"
                className="t-inline t-text-delta-700 t-ml-2"
                data-tip={info}
                data-for={label || 'textInputRef'}
              />
              <ReactTooltip
                id={label || 'textInputRef'}
                effect="solid"
                place="bottom"
                multiline
              />
            </div>
          )}
        </div>

        <textarea
          ref={ref}
          name={name}
          {...compProps}
          className={`Field Field--textarea ${textareaClass} ${
            error ? 'isErrored' : ''
          }`}
        />

        {!withoutErrorMessage && error && (
          <div className="Form-alert">
            <span className="text-main-sm t-text-epsilon-600">{error}</span>
          </div>
        )}
      </div>
    );
  }
);

TextAreaRef.displayName = 'TextAreaRef';

export default TextAreaRef;
