import {
  asyncActionStart,
  asyncActionWithSpinnerStart,
  asyncActionEnd,
} from 'store/actions/commonActions';
import { getPlanProgress } from 'store/actions/planActions';

export default {
  dispatchAction,
  dispatchAsyncAction,
  dispatchAsyncActionWithSpinner,
  dispatchAsyncActionWithProgress,
  getAction,
  successAction,
};

const defaultOptions = {
  throwError: false,
  showOverlay: true,
  showOverlayWithSpinner: true,
  returnError: false,
};

const getOptions = (options) => {
  if (!options) return defaultOptions;

  return {
    ...defaultOptions,
    ...options,
  };
};

function successAction(type: string, value: any): ReducerAction {
  return { type, payload: value };
}

// performes async action
// action is function which has (dispatch, getState) arguments like redux thunk
function dispatchAsyncAction(action, options) {
  const reqOptions = getOptions(options);

  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart(reqOptions.showOverlay));

      const result = await action(dispatch, getState);

      dispatch(asyncActionEnd());

      if (!result) return null;

      return result;
    } catch (error) {
      dispatch(asyncActionEnd());
      if (reqOptions.returnError) return error;
      if (reqOptions.throwError) throw error;
      return false;
    }
  };
}

// performes async action
// action is function which has (dispatch, getState) arguments like redux thunk with overlay spinner
function dispatchAsyncActionWithSpinner(action, options) {
  const reqOptions = getOptions(options);

  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionWithSpinnerStart(reqOptions.showOverlayWithSpinner));

      const result = await action(dispatch, getState);

      dispatch(asyncActionEnd());

      if (!result) return null;

      return result;
    } catch (error) {
      dispatch(asyncActionEnd());
      if (reqOptions.returnError) return error;
      if (reqOptions.throwError) throw error;
      return false;
    }
  };
}

/**
 * async action with updating progress
 */
function dispatchAsyncActionWithProgress(action, options) {
  const reqOptions = getOptions(options);

  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart(reqOptions.showOverlay));

      const result = await action(dispatch, getState);

      await dispatch(getPlanProgress());

      dispatch(asyncActionEnd());

      if (!result) return null;

      return result;
    } catch (error) {
      dispatch(asyncActionEnd());
      if (reqOptions.returnError) return error;
      if (reqOptions.throwError) throw error;
      return false;
    }
  };
}

// performes sync action
function dispatchAction(action, options) {
  const reqOptions = getOptions(options);
  return async (dispatch, getState) => {
    try {
      const result = await action(dispatch, getState);

      if (!result) return null;

      return result;
    } catch (error) {
      if (reqOptions.returnError) return error;
      if (reqOptions.throwError) throw error;
      return false;
    }
  };
}
function getAction(
  type: string,
  payload: Record<string, unknown> | string | CommonModal | null
) {
  if (!type) throw new Error('Specify action type');

  return {
    type,
    payload,
  };
}
