import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { RootState } from 'store/reducers';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { useTranslation } from 'modules/common/helpers';

import {
  AddCustomAssetTypeModal,
  AssetTypeSelectionModal,
} from 'modules/assetType/components';
import { Button, Modal } from 'modules/common/components';
import { AssetForm } from 'modules/asset/components';

import { REDIRECT_ACTIONS } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';
import { LANGUAGES, LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';

import { ReactComponent as IconPlus } from 'images/icon-plus.svg';
import { ReactComponent as ArrowLeftIcon } from 'images/icon-arrow-left.svg';

interface AddAssetProcessProps {
  assetCategory: string;
}

const AddAssetProcess = ({ assetCategory }: AddAssetProcessProps) => {
  const { t } = useTranslation(['plan']);
  const dispatch = useDispatch();

  const assetTypes: AssetTypesDTO = useSelector(
    (state: RootState) => state.assetTypes
  );
  const categoryAssetTypes: AssetTypeDTO[] = assetTypes[assetCategory];
  const dataOnRedirect = useSelector(
    (state: RootState) => state.temporaryField.value
  );

  const [visibleAssetTypeSelectionModal, setVisibleAssetTypeSelectionModal] =
    React.useState(false);
  const [visibleCreateAssetTypeModal, setVisibleCreateAssetTypeModal] =
    React.useState(false);
  const [selectedAssetType, setSelectedAssetType] = React.useState(
    {} as AssetTypeDTO
  );
  const [visibleAssetFormModal, setVisibleAssetFormModal] =
    React.useState(false);
  const [preselectedSubtype, setPreselectedSubtype] = React.useState('');

  React.useEffect(() => {
    if (dataOnRedirect) {
      handleOpenAssetFormOnRedirect();
    }
  }, [dataOnRedirect]);

  const startAddingAsset = () => {
    setVisibleAssetTypeSelectionModal(true);
  };

  const handleAddTemplate = (assetType: AssetTypeDTO) => {
    setSelectedAssetType(assetType);
    setVisibleAssetFormModal(true);
    setVisibleAssetTypeSelectionModal(false);
    setVisibleCreateAssetTypeModal(false);
  };

  const handleAddNewAssetTypeClick = () => {
    setVisibleCreateAssetTypeModal(true);
  };

  const handleBackToAssetTypeSelection = () => {
    setVisibleAssetFormModal(false);
    setVisibleCreateAssetTypeModal(false);
    setSelectedAssetType({} as AssetTypeDTO);
    setVisibleAssetTypeSelectionModal(true);
  };

  const handleOpenAssetFormOnRedirect = () => {
    const currentAssetType = categoryAssetTypes.find(
      (assetType) => assetType.name === dataOnRedirect.assetType
    );

    if (
      dataOnRedirect?.actionType === REDIRECT_ACTIONS.UPLOAD_DOCUMENT &&
      !!currentAssetType
    ) {
      dispatch(temporaryFieldSaver(null));
      if (dataOnRedirect?.subtype)
        setPreselectedSubtype(dataOnRedirect?.subtype);
      setSelectedAssetType(currentAssetType);
      setVisibleAssetFormModal(true);
    }
  };

  const handleCloseAssetFormModal = () => {
    setVisibleAssetFormModal(false);
    setPreselectedSubtype('');
  };

  const currentLanguage =
    localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE) || LANGUAGES.DE;

  const customAddAssetLabelEn = `${t(
    'plan:add_prefix_asset_form_modal_title'
  )} ${t(selectedAssetType?.label).toLowerCase() || ''}`;

  const customAddAssetLabelDe = `${t(selectedAssetType?.label) || ''} ${t(
    'common:add_new'
  ).toLowerCase()}`;

  return (
    <>
      {visibleAssetTypeSelectionModal && (
        <AssetTypeSelectionModal
          assetCategory={assetCategory}
          closeModal={() => setVisibleAssetTypeSelectionModal(false)}
          addTemplate={handleAddTemplate}
          handleAddNewAssetTypeClick={handleAddNewAssetTypeClick}
          assetTypes={categoryAssetTypes}
        />
      )}
      {visibleCreateAssetTypeModal && (
        <AddCustomAssetTypeModal
          isVisible={true}
          assetCategory={assetCategory}
          onClose={() => setVisibleCreateAssetTypeModal(false)}
          categorizedAssetTypes={categoryAssetTypes}
          handleSave={handleAddTemplate}
          handleBackToAssetTypeSelection={handleBackToAssetTypeSelection}
        />
      )}
      {visibleAssetFormModal && !isEmpty(selectedAssetType) && (
        <Modal show>
          {!dataOnRedirect?.assetType && (
            <Button
              category="ghost"
              className="t-pl-1"
              onClick={handleBackToAssetTypeSelection}
            >
              <ArrowLeftIcon className="t-mr-3 t-text-alpha-600" />
              <span>{t('common:back')}</span>
            </Button>
          )}
          <h1 className="typo-alpha t-mt-5 t-mb-3">
            {currentLanguage === LANGUAGES.DE
              ? customAddAssetLabelDe
              : customAddAssetLabelEn}
          </h1>
          <AssetForm
            assetType={selectedAssetType}
            assetCategory={assetCategory}
            onHideForm={handleCloseAssetFormModal}
            preselectedSubtype={preselectedSubtype}
          />
        </Modal>
      )}
      <Button
        category="secondary"
        className="t-tracking-widest"
        onClick={startAddingAsset}
      >
        <IconPlus
          role="presentation"
          className="t-inline t-text-delta-600 t-align-bottom t-mr-2"
        />
        <span>{t('plan:add_asset')}</span>
      </Button>
    </>
  );
};

export default AddAssetProcess;
