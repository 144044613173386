import { Placement } from 'react-joyride';
import imgWelcome from 'images/tutorial/img_welcome.png';

export const getVaultTourSteps = (t: (key: string) => string) => [
  {
    target: 'body',
    title: t('tutorial:vault_welcome_title'),
    content: t('tutorial:vault_welcome_content'),
    placement: 'center' as Placement,
    containerClassName: 't-max-w-sm',
    disableBeacon: true,
    image: imgWelcome,
    currentStep: 1,
    totalSteps: 5,
  },
  {
    target: '[data-tour="vault_navbar"]',
    title: t('tutorial:vault_navbar_title'),
    content: t('tutorial:vault_navbar_content'),
    disableBeacon: true,
    containerClassName: 't-max-w-96',
    placement: 'right-start',
    currentStep: 2,
    totalSteps: 5,
  },
  {
    target: '[data-tour="vault_recommendation"]',
    title: t('tutorial:vault_recommendation_title'),
    content: t('tutorial:vault_recommendation_content'),
    disableBeacon: true,
    containerClassName: 't-max-w-96',
    placement: 'left-start',
    currentStep: 3,
    totalSteps: 5,
  },
  {
    target: '[data-tour="vault_assets"]',
    title: t('tutorial:vault_assets_title'),
    content: t('tutorial:vault_assets_content'),
    disableBeacon: true,
    containerClassName: 't-max-w-96',
    placement: 'auto',
    currentStep: 4,
    totalSteps: 5,
  },
  {
    target: '[data-tour="vault_markAsCompleted"]',
    title: t('tutorial:vault_markAsCompleted_title'),
    content: t('tutorial:vault_markAsCompleted_content'),
    disableBeacon: true,
    containerClassName: 't-max-w-96',
    placement: 'auto',
    currentStep: 5,
    totalSteps: 5,
  },
];
