import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import achievementImg from 'images/task-achievement.png';

interface EndScreenViewProps {
  openCompletedRecosView: () => void;
}

const EndScreenView = ({ openCompletedRecosView }: EndScreenViewProps) => {
  const { t } = useTranslation(['recommendations']);
  return (
    <div className="t-flex t-flex-col xl:t-flex-row t-items-center t-py-4 md:t-py-8 xl:t-pt-4 xl:t-pb-1 xl:t-px-8">
      <div className="t-order-2 xl:t-order-1 t-text-center xl:t-text-left t-mt-3">
        <h1 className="t-text-xl t-font-semibold t-text-alpha-600 t-mb-5 t-whitespace-nowrap">
          {t('recommendations:all_tasks_completed_congratulations')}
        </h1>
        <h1 className="t-text-xl t-font-semibold t-mb-4">
          {t('recommendations:all_tasks_completed_title')}
        </h1>
        <p className="typo-epsilon t-mb-3">
          {t('recommendations:all_tasks_completed_new_avail_recos')}
        </p>
        <div className="t-flex t-justify-center xl:t-justify-start">
          <button
            onClick={openCompletedRecosView}
            className="typo-theta t-font-semibold t-text-delta-700 t-block t-outline-none"
          >
            {t('recommendations:show_completed_tasks_btn')}
          </button>
        </div>
      </div>
      <div className="t-max-w-sm t-order-1 xl:t-order-2">
        <img
          src={achievementImg}
          alt="Congratulations on completing all tasks"
        />
      </div>
    </div>
  );
};

export default EndScreenView;
