import React from 'react';

export default function useOutsideAlerter(
  ref,
  onOutsideClick,
  excludedElementId = ''
) {
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  function handleClickOutside(event) {
    const elementToExclude = excludedElementId
      ? document.getElementById(excludedElementId)
      : null;
    const isRefContainsExcludedEl = elementToExclude
      ? elementToExclude.contains(event.target)
      : false;
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !isRefContainsExcludedEl
    ) {
      onOutsideClick();
    }
  }
}
