import React from 'react';
import { Accordion } from 'react-bootstrap';

import { useTranslation } from 'modules/common/helpers';

import {
  CONTENT_QUESTIONNAIRE_KEYS,
  getContentQuestionnaire,
} from 'modules/assistant/constants/contentQuestionnaires';

import { TopicsContextAwareToggle } from 'modules/assistant/components/ContentQuestionnaire';

export interface TopicsProps {
  contentQuestionnaire: CONTENT_QUESTIONNAIRE_KEYS;
}

const Topics = ({ contentQuestionnaire }: TopicsProps) => {
  const { t } = useTranslation([contentQuestionnaire, 'common']);

  const contentAccordions =
    getContentQuestionnaire(contentQuestionnaire, t) || [];

  return (
    <div className="t-mt-6">
      <Accordion
        className="Accordion"
        defaultActiveKey={contentAccordions[0].title}
      >
        {contentAccordions.map((topic) => (
          <TopicsContextAwareToggle
            eventKey={topic.title}
            topic={topic}
            key={topic.title}
          />
        ))}
      </Accordion>
    </div>
  );
};

export default Topics;
