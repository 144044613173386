import React from 'react';
import { useDispatch } from 'react-redux';

import { validateEmail } from 'store/actions/userActions';
import { useCountdownTimer } from 'modules/twoAuth/utils';
import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

interface EmailVerificationProps {
  onClose: () => void;
  title?: string;
  description?: string;
}

const EmailVerification = ({
  onClose,
  title,
  description,
}: EmailVerificationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    start: startTimer,
    started: timerStarted,
    reset: timerReset,
  } = useCountdownTimer(120);

  const [currentTime, setCurrentTime] = React.useState(119);

  React.useEffect(() => {
    const myInterval = setInterval(() => {
      if (currentTime > 0 && timerStarted) {
        setCurrentTime(currentTime - 1);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  const onValidateEmail = async () => {
    await dispatch(validateEmail());
    timerReset();
    setCurrentTime(119);
    startTimer();
  };

  const verifyButtonDisabled = timerStarted && !!currentTime;
  return (
    <div>
      <h1 className="typo-alpha t-pb-3">
        {title || t(`mcontacts:email_not_verify_modal_title`)}
      </h1>
      <p className="typo-epsilon t-text-beta-500">
        {description || t(`mcontacts:email_not_verify_modal_description`)}
      </p>

      <div className="t-flex xs:t-flex-wrap sm:t-flex-nowrap t-justify-end lg:t-pt-6">
        <Button
          category="secondary"
          onClick={onValidateEmail}
          className={`t-bg-beta-100 typo-eta t-whitespace-nowrap t-outline-none t-mr-4 xs:t-mt-5 ${
            verifyButtonDisabled
              ? 't-text-beta-500 t-pointer-events-none t-select-none'
              : 't-text-zeta-600'
          }`}
          disabled={verifyButtonDisabled}
        >
          {`${t('common:email_verify_button_label')}${
            verifyButtonDisabled ? ` (${currentTime})` : ''
          }`}
        </Button>
        <Button category="secondary" className="xs:t-mt-5" onClick={onClose}>
          {t('common:cancel')}
        </Button>
      </div>
    </div>
  );
};

export default EmailVerification;
