import React, { useMemo } from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { Clause } from 'modules/termsAndConditions/components';

type ClauseType = {
  item: string;
  description: string;
};

function TermsAndConditionsPage() {
  const { t } = useTranslation(['termsAndConditions']);
  const clausesOfAgreement = useMemo(
    () =>
      t('termsAndConditions:terms_and_conditions', {
        returnObjects: true,
      }) as any as ClauseType[],
    [t]
  );

  return (
    <Row className="t-h-100">
      <Col
        md={{ span: 12 }}
        xl={{ span: 10, offset: 2 }}
        style={{ paddingRight: 0 }}
      >
        <div className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7">
          <h1 className="Typography Typography--title u-weight-600 u-pb-30">
            {t('termsAndConditions:terms_and_conditions_title')}
          </h1>
          {clausesOfAgreement.map((clause) => {
            const { item, description } = clause;
            return <Clause key={item} item={item} description={description} />;
          })}
        </div>
      </Col>
    </Row>
  );
}

export default TermsAndConditionsPage;
