import { assetTemplateService } from 'modules/estatePlan/services';
import { GET_ASSET_TEMPLATES_BY_CATEGORY } from 'store/types/assetTemplateTypes';
import helper from 'store/actions/actionHelper';

export const success = (type, value) => ({ type: type, payload: value });

export const listAssetTemplatesByCategory = (
  category: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const assetTemplatesByCategory: AssetTypeDTO[] =
      await assetTemplateService.getAssetTemplatesByCategory(category);

    dispatch(
      helper.successAction(GET_ASSET_TEMPLATES_BY_CATEGORY, {
        assetTemplates: assetTemplatesByCategory,
        assetCategory: category,
      })
    );
  }, actionOptions);
};

export const getAssetTypeByAssetTypeName = (
  category: string,
  assetType?: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const assetTypeRes: AssetTypeDTO =
      await assetTemplateService.getAssetTemplatesByCategory(
        category,
        assetType
      );

    return assetTypeRes;
  }, actionOptions);
};
