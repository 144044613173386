import { loadStripe } from '@stripe/stripe-js';
import i18n from 'i18next';
import { reactToast } from 'modules/common/helpers';
import { dataService } from 'modules/common/services';

let stripePromise: any = null;

const getStripePromise = async () => {
  if (stripePromise) return stripePromise;

  try {
    const res = await dataService.getPaymentApiKey();

    stripePromise = loadStripe(res.publishableKey);
  } catch (err) {
    reactToast.showError(i18n.t('toastr:cannot_load_stripe_key'));
  }

  return stripePromise;
};

export default getStripePromise;
