import React from 'react';

interface AdditionalInfoBoxProps {
  children: React.ReactNode | React.ReactNode[];
}

export default function AdditionalInfoBox({
  children,
}: AdditionalInfoBoxProps) {
  return (
    <div className="t-bg-delta-100 t-p-2.5 t-rounded-sm">
      <div className="typo-epsilon t-text-delta-500">{children}</div>
    </div>
  );
}
