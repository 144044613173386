import React from 'react';
import validator from 'validator';

import { dateHelper, useTranslation } from 'modules/common/helpers';
import {
  TextInputRef,
  TextAreaRef,
  DateInput,
  SwitchInput,
  Iban,
  ProviderSelect,
  FullAddressInputs,
  StaticFile,
  MultiField,
  PersonInput,
  CreditCardInfoInput,
  PasswordInputRef,
} from 'modules/common/components';
import { ActionSelect, SubtypeSelect } from 'modules/estatePlan/components';
import { UploadDocument } from 'modules/document/components';
import {
  FinancialDataField,
  TFAAssetFormField,
} from 'modules/asset/components';

import {
  CommonFormInputProps,
  FormErrors,
  FormGetValues,
} from 'modules/common/types';

import { FIELD_TYPE_KEYS as F } from 'modules/estatePlan/constants/fieldLibrary';
import { PROVIDER_CONSTANT_TYPES } from 'modules/estatePlan/constants/enums';
import {
  ASSET_CATEGORIES,
  ASSET_TYPE_KEYS,
} from 'modules/estatePlan/constants';

export interface AssetFormFieldSelectorProps extends CommonFormInputProps {
  field: AssetFieldDTO;
  getValues: FormGetValues;
  errors: FormErrors;
  assetCategory: string;
  assetType: AssetTypeDTO;
  handleToggleAssetForm: (isHidden: boolean) => void;
  onDocumentUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  documentFileName?: string;
  questionnaireStatus: number | undefined;
  asset?: AssetDTO;
  preselectedSubtype?: string;
}

export default function AssetFormFieldSelector({
  field,
  register,
  errors,
  assetCategory,
  assetType,
  control,
  setValue,
  getValues,
  onDocumentUpload,
  documentFileName,
  watch,
  questionnaireStatus,
  handleToggleAssetForm,
  asset = {} as AssetDTO,
  preselectedSubtype = '',
}: AssetFormFieldSelectorProps) {
  const { t, i18n } = useTranslation(['common']);

  const fieldErr = !errors[field.type]
    ? ''
    : (errors[field.type]?.message as string) ||
      `${t(field.label)} ${t('common:required')}`;

  const commentPlaceholder = i18n.exists(field?.placeholder || '')
    ? t(field?.placeholder || '')
    : t('common:default_comment_placeholder');

  const commonLabel =
    assetType?.name === PROVIDER_CONSTANT_TYPES.PHONE &&
    field.type === F.PASSWORD
      ? t('common:unlock_passcode_label')
      : t(field.label);

  const fieldLabel = `${commonLabel}${
    !field.required ? ` ${t('common:optional_label')}` : ''
  }`;

  const commonProps = {
    error: fieldErr,
    defaultValue: asset[field.type],
    name: field.type,
    autoComplete: undefined,
    placeholder:
      field.type === F.COMMENTS
        ? commentPlaceholder
        : t(field?.placeholder || ''),
    label: fieldLabel,
  };

  const isEmergencyAccess = assetType.name === ASSET_TYPE_KEYS.EMERGENCY_PASS;
  const info = isEmergencyAccess ? t('plan:emergency_access_code_tooltip') : '';

  switch (field.type) {
    case F.NAME:
    case F.INSURANCE_NUMBER:
    case F.LOCATION:
    case F.LICENSE:
    case F.OTHER_ADDRESS:
    case F.REGULATION:
      return (
        <TextInputRef
          {...register(commonProps.name, { required: field.required })}
          type="text"
          {...commonProps}
        />
      );
    case F.ADDRESS:
      return (
        <FullAddressInputs
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          required={!!field?.required}
          stringifiedAddress={asset[field.type]}
          label={fieldLabel}
        />
      );
    case F.NUMBERS:
      return (
        <TextInputRef
          {...register(commonProps.name, {
            required: field.required,
            validate: (value) => {
              if (!value) return true;
              return validator.isInt(value);
            },
          })}
          type="number"
          maxLength={300}
          {...commonProps}
        />
      );
    case F.LINK:
      return (
        <TextInputRef
          {...register(commonProps.name, {
            required: field.required,
            validate: (value) => {
              if (!value) return true;
              return validator.isURL(value);
            },
          })}
          type="text"
          {...commonProps}
        />
      );
    case F.EMAIL:
    case F.PHONE:
      return (
        <MultiField
          control={control}
          errors={errors}
          stringifiedValues={asset[field.type]}
          register={register}
          watch={watch}
          setParentValue={setValue}
          field={field}
          setValue={setValue}
          customLabel={fieldLabel}
          trimmed
        />
      );
    case F.EXPIRATION_DATE:
      return (
        <DateInput
          error={fieldErr}
          label={fieldLabel}
          required={field.required}
          control={control}
          name={field.type}
          defaultValue={asset[field.type]}
          extraValidation={(value) => {
            if (!value && !field.required) return true;
            if (!value) return `${fieldLabel} ${t('common:required')}`;
            if (!dateHelper.isValidDate(value))
              return `${t('common:invalid_expiration_date')}`;
            if (dateHelper.checkIsNotFutureDate(value))
              return `${t('common:already_expired_date')}`;
            return true;
          }}
        />
      );
    case F.DATE:
      return (
        <DateInput
          error={fieldErr}
          label={fieldLabel}
          required={field.required}
          control={control}
          name={field.type}
          defaultValue={asset[field.type] || ''}
          extraValidation={(value) => {
            if (!value && !field.required) return true;
            if (!value) return `${fieldLabel} ${t('common:required')}`;
            if (!dateHelper.isValidDate(value))
              return `${t('common:invalid_date')}`;
            return true;
          }}
        />
      );
    case F.DOCUMENT:
      return (
        <>
          <UploadDocument
            isPrimaryColor={questionnaireStatus === 1}
            assetItem={asset}
            assetCategory={assetCategory}
            onDocumentUpload={onDocumentUpload}
            optional={!field.required}
          />
          {documentFileName && !(asset?.documents?.length > 0) && (
            <div className="t-mb-2 5">
              <StaticFile documentTitle={documentFileName} />
            </div>
          )}
        </>
      );
    case F.PASSWORD:
      return (
        <PasswordInputRef
          {...register(commonProps.name, { required: field.required })}
          {...commonProps}
          info={info}
          setValue={setValue}
          watch={watch}
          withPasswordGenerator={assetCategory === ASSET_CATEGORIES.DIGITAL}
        />
      );
    case F.PUK:
    case F.PIN:
      return (
        <TextInputRef
          {...register(commonProps.name, { required: field.required })}
          type="password"
          {...commonProps}
          info={info}
        />
      );
    case F.COMMENTS:
      return (
        <TextAreaRef
          {...register(commonProps.name, { required: field.required })}
          maxLength={2000}
          rows={3}
          {...commonProps}
        />
      );
    case F.PROVIDER:
      return (
        <ProviderSelect
          type={field.providerType as PROVIDER_CONSTANT_TYPES}
          control={control}
          rules={{ required: field.required }}
          setValue={setValue}
          watch={watch}
          register={register}
          {...commonProps}
        />
      );
    case F.USERNAME:
      return (
        <TextInputRef
          {...register(commonProps.name, { required: field.required })}
          type="text"
          {...commonProps}
        />
      );
    case F.PERSON:
      return (
        <PersonInput
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          watch={watch}
          required={!!field?.required}
          stringifiedPersonInput={asset[field.type]}
          label={fieldLabel}
        />
      );
    case F.ONLINE:
      return (
        <SwitchInput
          label={commonLabel}
          name={field.type}
          register={register}
          required={field.required}
          defaultValue={asset[field.type]}
        />
      );
    case F.IBAN:
      return (
        <Iban
          error={fieldErr}
          label={fieldLabel}
          required={field.required}
          control={control}
          name={field.type}
          defaultValue={asset[field.type]}
        />
      );

    case F.ACTION:
      return (
        <ActionSelect
          label={fieldLabel}
          error={fieldErr}
          control={control}
          field={field}
          assetCategory={assetCategory}
          assetType={assetType}
          asset={asset}
        />
      );

    case F.SUBTYPE:
      return (
        <SubtypeSelect
          label={fieldLabel}
          error={fieldErr}
          control={control}
          register={register}
          field={field}
          assetCategory={assetCategory}
          assetType={assetType}
          asset={asset}
          watch={watch}
          setValue={setValue}
          preselectedSubtype={preselectedSubtype}
        />
      );

    case F.CREDIT_CARD_INFO:
      return (
        <CreditCardInfoInput
          register={register}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          required={!!field?.required}
          stringifiedCardInfo={asset[field.type]}
          label={fieldLabel}
        />
      );

    case F.FINANCIAL_DATA:
      return (
        <FinancialDataField
          register={register}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          required={!!field?.required}
          stringifiedFinancialData={asset[field.type]}
          label={fieldLabel}
        />
      );

    case F.TWO_AUTH:
      return (
        <TFAAssetFormField
          defaultAsset={asset}
          register={register}
          watch={watch}
          setValue={setValue}
          tfaEnabled={asset[field.type]}
          handleToggleAssetForm={handleToggleAssetForm}
          getValues={getValues}
          label={fieldLabel}
        />
      );

    case F.LINK_TO:
    case F.CONTACTS:
    default:
      return null;
  }
}
