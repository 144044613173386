import React from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';
import { HowDoesItWorkModal, PersonalInfoForm } from 'modules/zvr/components';
import { Button, Modal } from 'modules/common/components';

import { ZVR_REGISTRATION_STEPS } from 'modules/zvr/constants';

import { ReactComponent as IconPerson } from 'images/icon_person.svg';
import { ReactComponent as IconExclamation } from 'images/exclamation-mark-with-circle.svg';
import { ReactComponent as IconQuestionCircle } from 'images/icon-question-with-circle.svg';

interface TrustedPersonRegisterModalProps {
  handleNextStep: (step: number) => void;
}

export default function TrustedPersonRegisterModal({
  handleNextStep,
}: TrustedPersonRegisterModalProps) {
  const { t } = useTranslation(['zvrRegistration', 'mcontacts']);

  const healthCareProxy = useSelector(
    (state: RootState) => state.questionnaires.healthCareProxy
  );
  const contactsData: ContactDTO[] =
    useSelector((state: RootState) => state.contacts.list) || [];
  const [currentTrustedPersonIdx, setCurrentTrustedPersonIdx] =
    React.useState(0);
  const [isSubmitForm, setIsSubmitForm] = React.useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
  const [isEndStepTrustedPerson, setIsEndStepTrustedPerson] =
    React.useState(false);

  const selectedContacts =
    healthCareProxy?.contactSelection?.map((contactId) =>
      contactsData.find((contact) => contact.id === contactId)
    ) || [];

  React.useEffect(() => {
    if (currentTrustedPersonIdx === selectedContacts.length - 1) {
      setIsEndStepTrustedPerson(true);
    } else {
      setIsEndStepTrustedPerson(false);
    }
  }, [currentTrustedPersonIdx, selectedContacts]);

  const handleSubmitSuccess = () => {
    setIsSubmitForm(false);
    if (isEndStepTrustedPerson) {
      handleNextStep(ZVR_REGISTRATION_STEPS.STEP_PAYMENT_CHECKOUT);
    } else {
      setCurrentTrustedPersonIdx(currentTrustedPersonIdx + 1);
    }
  };

  const getDataTip = (contact: ContactDTO) => {
    const isMissingInfo = !contact.gender || !contact.birthday;
    const tip = `${
      !contact.birthday ? t('mcontacts:asset_form_date_of_birth') : ''
    }${
      !contact.birthday && !contact.gender ? t('mcontacts:form_and_text') : ''
    }${!contact.gender ? t('mcontacts:asset_form_gender') : ''} ${t(
      'mcontacts:asset_form_is_missing'
    )}`;

    return { isMissingInfo, tip };
  };

  const handleSubmitFormFalse = () => {
    setIsSubmitForm(false);
  };

  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  const onClose = () => {
    handleNextStep(ZVR_REGISTRATION_STEPS.STEP_REGISTRATION_CANCEL);
  };

  return (
    <>
      {isInfoModalOpen && <HowDoesItWorkModal onClose={handleInfoModalClose} />}
      <Modal
        show
        onHide={onClose}
        innerCardClassName={`${isInfoModalOpen ? 't-hidden' : ''}`}
      >
        <h1 className="typo-alpha">
          {t('zvrRegistration:register_trusted_person_title')}
        </h1>
        <div className="t-flex t-flex-wrap t-mt-6">
          {selectedContacts?.map((trustedPerson, idx) => {
            return (
              <div
                key={trustedPerson.id}
                className={`t-flex t-items-center t-p-2 t-mr-2 t-outline-none ${
                  idx === currentTrustedPersonIdx
                    ? 't-border t-border-solid t-rounded t-text-beta-400'
                    : 't-text-alpha-600'
                } `}
              >
                <IconPerson className="t-mr-2" />
                <span
                  className={`t-uppercase typo-eta ${
                    idx === currentTrustedPersonIdx
                      ? 't-text-beta-400'
                      : 't-text-alpha-600'
                  }`}
                >
                  {trustedPerson.name}
                </span>
                {getDataTip(trustedPerson).isMissingInfo && (
                  <>
                    <IconExclamation
                      className="t-text-zeta-600 t-ml-1"
                      data-tip={getDataTip(trustedPerson).tip}
                      data-for={trustedPerson.id}
                    />
                    <ReactTooltip
                      id={trustedPerson.id}
                      effect="solid"
                      place="bottom"
                      multiline
                      className="t-bg-eta-100 typo-epsilon t-text-zeta-600"
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>

        {!!selectedContacts?.length &&
          selectedContacts.map((contact, idx) => {
            return (
              <PersonalInfoForm
                key={contact.id}
                personalObject={contact}
                isHide={idx !== currentTrustedPersonIdx}
                isTrustedPerson={true}
                isSubmitForm={isSubmitForm && idx === currentTrustedPersonIdx}
                handleSubmitSuccess={handleSubmitSuccess}
                submitFormFalse={handleSubmitFormFalse}
              />
            );
          })}

        <button
          onClick={() => setIsInfoModalOpen(true)}
          type="button"
          className="t-flex t-items-center t-w-full t-justify-end t-mt-5 t-outline-none"
        >
          <IconQuestionCircle className="t-text-alpha-600 t-mr-2" />
          <h4 className="typo-eta t-text-alpha-600">
            {t('zvrRegistration:what_does_that_mean')}
          </h4>
        </button>

        <div className="t-flex t-justify-end t-items-end t-mt-6 t-flex-col sm:t-flex-row t-pb-16 sm:t-pb-0">
          <div>
            <Button
              category="secondary"
              className="t-mr-0 sm:t-mr-3 t-py-2 t-mb-3 sm:t-mb-0"
              disabled={isSubmitForm}
              onClick={onClose}
            >
              {t('zvrRegistration:cancel')}
            </Button>
          </div>

          <div>
            <Button
              className="t-py-2"
              onClick={() => setIsSubmitForm(true)}
              disabled={!selectedContacts?.length}
              loading={isSubmitForm}
            >
              {isEndStepTrustedPerson
                ? t('zvrRegistration:save_continue')
                : t('zvrRegistration:save_and_to_next_person')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
