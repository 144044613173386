export const NEXT_STEPS_CODES = {
  COMPLETE_PROFILE: 'completeProfile',
  ADD_CONTACT: 'addContact',
  ADD_SECURITY_KEY: 'passphrase',
  ADD_TWO_FACTOR_AUTH: 'twoFactorAuth',
  CONTACT_NOW: 'contactNow',
  ADD_BACKUP_CONTACT: 'backupContact',
};

const NC = NEXT_STEPS_CODES;

export const NEXT_STEPS = {
  [NC.COMPLETE_PROFILE]: {
    title: `dashboard:next_steps_widget_${NC.COMPLETE_PROFILE}_title`,
    description: `dashboard:next_steps_widget_${NC.COMPLETE_PROFILE}_description`,
    primaryCTA: `dashboard:next_steps_widget_start`,
  },
  [NC.ADD_CONTACT]: {
    title: `dashboard:next_steps_widget_${NC.ADD_CONTACT}_title`,
    description: `dashboard:next_steps_widget_${NC.ADD_CONTACT}_description`,
    primaryCTA: `dashboard:next_steps_widget_start`,
  },
  [NC.ADD_SECURITY_KEY]: {
    title: `dashboard:next_steps_widget_${NC.ADD_SECURITY_KEY}_title`,
    description: `dashboard:next_steps_widget_${NC.ADD_SECURITY_KEY}_description`,
    primaryCTA: `dashboard:next_steps_widget_start`,
    secondaryCTA: 'dashboard:next_steps_widget_discard',
  },
  [NC.ADD_TWO_FACTOR_AUTH]: {
    title: `dashboard:next_steps_widget_${NC.ADD_TWO_FACTOR_AUTH}_title`,
    description: `dashboard:next_steps_widget_${NC.ADD_TWO_FACTOR_AUTH}_description`,
    primaryCTA: `dashboard:next_steps_widget_start`,
    secondaryCTA: 'dashboard:next_steps_widget_discard',
  },
  [NC.CONTACT_NOW]: {
    title: `dashboard:next_steps_widget_${NC.CONTACT_NOW}_title`,
    description: `dashboard:next_steps_widget_${NC.CONTACT_NOW}_description`,
    primaryCTA: `dashboard:next_steps_widget_start`,
    secondaryCTA: 'dashboard:next_steps_widget_discard',
  },
  [NC.ADD_BACKUP_CONTACT]: {
    title: `dashboard:next_steps_widget_${NC.ADD_BACKUP_CONTACT}_title`,
    description: `dashboard:next_steps_widget_${NC.ADD_BACKUP_CONTACT}_description`,
    primaryCTA: `dashboard:next_steps_widget_start`,
    secondaryCTA: 'dashboard:next_steps_widget_discard',
  },
};

export const SECONDARY_USER_UNAVAILABLE_STEPS = [
  NEXT_STEPS_CODES.ADD_SECURITY_KEY,
  NEXT_STEPS_CODES.ADD_BACKUP_CONTACT,
  NEXT_STEPS_CODES.ADD_TWO_FACTOR_AUTH,
  NEXT_STEPS_CODES.CONTACT_NOW,
];
