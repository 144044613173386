import React from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';
import { getSelectOptions } from 'modules/contact/helpers';

import { Button, Modal } from 'modules/common/components';
import { StyledOr } from 'modules/elements/components';

import { ZVR_EDIT_STEPS } from 'modules/zvr/constants';

import { ReactComponent as IconPlus } from 'images/icon-plus.svg';

interface ZVRAddExistingContactModalProps {
  handleAddExistingContactModal: (contactID: string) => void;
  handleNextStep: (step: number) => void;
  setEditContact: (contact: ContactDTO) => void;
  addedContacts: ContactDTO[];
}

export default function ZVRAddExistingContactModal({
  handleNextStep,
  handleAddExistingContactModal,
  setEditContact,
  addedContacts,
}: ZVRAddExistingContactModalProps) {
  const { t } = useTranslation(['zvrRegistration']);

  const [selectedContactId, setSelectedContactId] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const contactsData: ContactDTO[] =
    useSelector((state: RootState) => state.contacts.list) || [];

  const contactOptions = getSelectOptions(contactsData).filter((option) => {
    return !addedContacts.find(
      (selectedContact) => selectedContact.id === option.value
    );
  });

  const handleChange = (selectedOption) => {
    const option = selectedOption as SelectOption;
    setSelectedContactId(option.value);
  };

  const handleAddNewPerson = () => {
    setEditContact({} as ContactDTO);
    handleNextStep(ZVR_EDIT_STEPS.STEP_EDIT_REGISTERED_TRUSTED_PERSON);
  };

  const onAddExistingContactModal = async () => {
    setLoading(true);
    await handleAddExistingContactModal(selectedContactId);
    setLoading(false);
  };

  const onClose = () => {
    handleNextStep(ZVR_EDIT_STEPS.STEP_TRUSTED_OVERVIEW);
  };

  return (
    <Modal show onHide={onClose}>
      <h1 className="typo-alpha">
        {t('zvrRegistration:add_trusted_person_modal_title')}
      </h1>
      <div className="Form-group t-mt-7 ">
        <label className="Form-label" htmlFor="gender">
          {t('common:existing_contact')}
        </label>

        <Select
          id="contact"
          name="contact"
          onChange={handleChange}
          options={contactOptions}
          className="Select"
          classNamePrefix="Select"
        />
      </div>

      <div className="d-flex flex-column align-items-center">
        <StyledOr>{t('common:or')}</StyledOr>

        <Button
          category="secondary"
          onClick={handleAddNewPerson}
          disabled={loading}
        >
          <div className="t-flex t-justify-around">
            <IconPlus className="t-text-alpha-600 t-mr-1.5" />
            <p>{t('common:add_new_contact')}</p>
          </div>
        </Button>
      </div>

      <div className="t-flex t-justify-end t-items-end t-mt-16 t-flex-col sm:t-flex-row t-pb-16 sm:t-pb-0">
        <div>
          <Button
            category="secondary"
            className="t-mr-0 sm:t-mr-3 t-py-2 t-mb-3 sm:t-mb-0"
            onClick={onClose}
            disabled={loading}
          >
            {t('zvrRegistration:cancel')}
          </Button>
        </div>

        <div>
          <Button
            className="t-py-2"
            onClick={onAddExistingContactModal}
            loading={loading}
            disabled={!selectedContactId}
          >
            {t('zvrRegistration:save_continue')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
