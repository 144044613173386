import React from 'react';
import { useParams } from 'react-router-dom';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import { SignUpPage, SignUpPageV2 } from 'modules/auth/components';

import { CLIENT_CUSTOMIZATIONS, CLIENT_KEYS } from 'modules/common/constants';

export default function SignUpPageManager() {
  const { token = '', client = '' } = useParams();
  const { pushInstruction } = useMatomo();
  const customization: ClientCustomization =
    CLIENT_CUSTOMIZATIONS[client || 'default']?.signup || {};

  React.useEffect(() => {
    // consider all client visitor as a unique user
    pushInstruction('setUserId', client || 'Ninebarc');
  }, [client]);

  const [validToken, setValidToken] = React.useState('');
  React.useEffect(() => {
    if (token.length === 40) {
      setValidToken(token);
    }
  }, [token]);

  switch (client) {
    case CLIENT_KEYS.SUEDVERS:
      return <SignUpPage client={client} token={validToken} />;
    default:
      return (
        <SignUpPageV2
          client={client}
          token={validToken}
          customization={customization}
        />
      );
  }
}
