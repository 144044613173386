import React from 'react';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import {
  changePrimaryEmail,
  checkEmailAvailability,
} from 'store/actions/userActions';
import {
  getSuccessMessage,
  reactToast,
  useTranslation,
  validationHelper,
} from 'modules/common/helpers';
import { getConfirmationCodeErr, getEmailErr } from 'modules/contact/helpers';

import { Button, Modal, TextInputRef } from 'modules/common/components';
import { Col, Row } from 'modules/bootstrap/components';

import { EMAIL_ERROR_TYPES } from 'modules/common/constants/enums';
import { ERROR_CODES } from 'modules/common/constants';

interface ChangingEmailModalProps {
  onClose: () => void;
}

const ChangingEmailModal = ({ onClose }: ChangingEmailModalProps) => {
  const { t } = useTranslation(['profile', 'common']);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    setError,

    formState: { errors },
  } = useForm();

  const [loading, setLoading] = React.useState(false);

  const onsubmit = async (formData) => {
    const { email, code } = formData;
    // Check if the email already exist
    const isValid = await dispatch(checkEmailAvailability(email, true));

    if (!isValid) {
      setError('email', { type: EMAIL_ERROR_TYPES.EXISTING_EMAIL });
      setLoading(false);
      return;
    }

    const response: any = await dispatch(
      changePrimaryEmail(email, code, {
        returnError: true,
      })
    );

    setLoading(false);

    if (response?.errorCode === ERROR_CODES.INVALID_TOKEN) {
      setError('code', { type: 'invalidToken' });
    } else {
      reactToast.showMessage(getSuccessMessage(response.message));
      onClose();
    }
  };

  const userData: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const emailError = getEmailErr(errors, t);
  const codeErr = getConfirmationCodeErr(errors, t);

  return (
    <Modal show>
      <h1 className="typo-alpha t-mb-4">
        {t('profile:changing_email_modal_title')}
      </h1>

      <p className="typo-epsilon t-mb-6">
        {t('profile:changing_email_modal_desc')}
      </p>

      <form className="t-w-full" onSubmit={handleSubmit(onsubmit)}>
        <fieldset disabled={loading}>
          <Row>
            <Col xl={12}>
              <TextInputRef
                {...register('email', {
                  required: true,
                  validate: (value) => isEmail(value),
                })}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue('email', e.target.value.trim())
                }
                label={t('profile:new_email_address_label')}
                placeholder={t('fieldLibrary:label_email')}
                type="email"
                autoComplete={false}
                defaultValue={userData.backupEmail}
                error={emailError}
                dataTestId="email-input"
              />
            </Col>
          </Row>

          <div className="t-flex t-items-center">
            <TextInputRef
              inputClass="t-w-1/2"
              {...register('code', {
                required: true,
                validate: (value) => validationHelper.validateCode(4, value),
              })}
              label={t('auth:confirmation_code')}
              type="tel"
              placeholder="XXXX"
              maxLength={4}
              autoComplete={false}
              error={codeErr}
            />
          </div>
        </fieldset>

        <div className="t-flex t-flex-wrap t-justify-end xs:t-mt-5 sm:t-mt-0 t-z-0 t-relative">
          <Button
            category="secondary"
            className="xs:t-mt-5 xs:t-order-2 sm:t-order-1 xs:t-ml-4"
            onClick={onClose}
            disabled={loading}
          >
            {t('common:cancel')}
          </Button>
          <div className="t-ml-5 t-self-end sm:t-mb-0 xs:t-order-1 sm:t-order-2">
            <Button type="submit" loading={loading}>
              {t('common:change')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ChangingEmailModal;
