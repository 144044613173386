import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from 'store/reducers';
import {
  downloadBeneficiaryDocument,
  downloadSharedDocument,
} from 'store/actions/documentActions';
import { useTranslation } from 'modules/common/helpers';

import { Spinner } from 'modules/common/components';

import { ReactComponent as IconFile } from 'images/icon-file.svg';

type PdfDownloadLinkAsBeneficiaryProps = {
  document?: DocumentDTO;
  isEndScreen?: boolean;
};

function PdfDownloadLinkAsBeneficiary(
  props: PdfDownloadLinkAsBeneficiaryProps
) {
  const { isEndScreen } = props;
  const location = useLocation();
  const [document, setDocument] = React.useState<DocumentDTO>(
    (props.document || {}) as DocumentDTO
  );
  const [isDownloading, setIsDownloading] = React.useState(false);
  const userProfile: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const isViewerPage = userProfile.viewer && location.pathname === '/shared';

  const { documentTitle } = document;
  const fileExtension = documentTitle?.split('.').pop()?.toUpperCase();

  const { t } = useTranslation(['plan']);
  const dispatch = useDispatch();

  const onDownloadDocument = async (value) => {
    setIsDownloading(true);
    setDocument(value);
    if (!isViewerPage) {
      await dispatch(downloadBeneficiaryDocument(document));
    } else {
      await dispatch(downloadSharedDocument(document));
    }
    setIsDownloading(false);
  };

  return (
    <div
      className="Character-iconWrapper t-flex t-items-center"
      data-tip={documentTitle || t('plan:no_download')}
    >
      {documentTitle ? (
        <>
          <button
            className="t-flex t-items-center t-outline-none t-bg-delta-100 t-rounded-sm t-p-1.5"
            onClick={() => onDownloadDocument(document)}
            disabled={isDownloading}
          >
            <span>
              {isDownloading ? (
                <Spinner className="t-h-3 t-w-3 t-text-delta-500" />
              ) : (
                <IconFile className="t-h-3 t-w-3 t-text-delta-500" />
              )}
            </span>
            <span className="t-whitespace-nowrap typo-kappa t-text-delta-500 t-ml-1.5">
              {fileExtension}
            </span>
          </button>
          <button
            className={`${
              !isEndScreen ? 'u-truncateLine' : null
            } t-outline-none t-text-left t-pl-2.5 t-text-delta-500`}
          >
            {documentTitle}
          </button>
        </>
      ) : (
        <span className="FileIcon no-download">PDF</span>
      )}
    </div>
  );
}

PdfDownloadLinkAsBeneficiary.defaultProps = {
  document: {
    id: '',
    documentTitle: '',
  },
};

export default PdfDownloadLinkAsBeneficiary;
