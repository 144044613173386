import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import {
  optOutTwoAuth,
  turnOffGoogleTwoAuth,
  turnOffSmsTwoAuth,
  turnOffSmsTwoAuthConfirm,
  turnOnSmsTwoAuth,
  updateCurrentUserSuccess,
} from 'store/actions/userActions';

import {
  TwoAuthChoiceModal,
  TwoAuthEnableSuccessModal,
  TwoAuthEnterGoogleValidationCodeModal,
  TwoAuthEnterSMSConfirmationCodeModal,
  TwoAuthGoogleModal,
  TwoAuthSMSModal,
  TwoFactorAuthSelectionModal,
} from 'modules/twoAuth/components';

import { TWO_AUTH_TYPES } from 'modules/common/constants/enums';

interface TwoFactorAuthProcessProps {
  handleShow2FAProcess: (isShow: boolean) => void;
}

export default function TwoFactorAuthProcess({
  handleShow2FAProcess,
}: TwoFactorAuthProcessProps) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.current);
  const [phone, setPhone] = React.useState<string | null>(currentUser.phone);

  const [show2FASelectionModal, setShow2FASelectionModal] =
    React.useState(true);
  const [showChoiceModal, setShowChoiceModal] = React.useState(false);
  const [showSMSModal, setShowSMSModal] = React.useState(false);
  const [showGoogleModal, setShowGoogleModal] = React.useState(false);
  const [showTwoAuthSuccessModal, setShowTwoAuthSuccessModal] =
    React.useState(false);
  const [
    showEnterGoogleValidationCodeModal,
    setShowEnterGoogleValidationCodeModal,
  ] = React.useState(false);
  const [showSMSConfirmationCodeOnModal, setShowSMSConfirmationCodeOnModal] =
    React.useState(false);
  const [showSMSConfirmationCodeOffModal, setShowSMSConfirmationCodeOffModal] =
    React.useState(false);
  const [isChange2FA, setIsChange2FA] = React.useState(false);

  const isTwoFactorAuthDisabled =
    !currentUser.tfaEnabled && !currentUser.smsTfaEnabled;

  React.useEffect(() => {
    if (isTwoFactorAuthDisabled) {
      setShow2FASelectionModal(false);
      setShowChoiceModal(true);
    }
  }, [isTwoFactorAuthDisabled]);

  const { register, getValues, watch } = useForm({
    defaultValues: {
      twoFa:
        currentUser.tfaEnabled || currentUser.smsTfaEnabled
          ? 'enable'
          : currentUser.tfaOptOut && 'disable',
    },
  });

  const updateCurrentUser = (data: UserProfileDTO) => {
    dispatch(
      updateCurrentUserSuccess({
        ...currentUser,
        ...data,
      })
    );
  };

  const handleChoice = (type: TWO_AUTH_TYPES) => {
    setShowChoiceModal(false);
    if (type === TWO_AUTH_TYPES.SMS) setShowSMSModal(true);
    if (type === TWO_AUTH_TYPES.GOOGLE) setShowGoogleModal(true);
  };

  const handleSuccessTurnOnGoogleAuth = () => {
    updateCurrentUser({ tfaEnabled: true, tfaOptOut: false });
    setShowGoogleModal(false);
    setShowTwoAuthSuccessModal(true);
  };

  const handleSuccessEnterGoogleValidationCodeModal = () => {
    setShowEnterGoogleValidationCodeModal(false);
    if (isChange2FA) {
      setShowChoiceModal(true);
      setIsChange2FA(false);
    } else {
      dispatch(optOutTwoAuth());
      updateCurrentUser({ tfaEnabled: false, tfaOptOut: true });
      handleCancel();
    }
    updateCurrentUser({ tfaEnabled: false, tfaOptOut: true });
  };

  const handleSMSConfirmationSuccess = (smsTfaEnabled: boolean) => {
    if (isChange2FA) {
      setShowChoiceModal(true);
      setIsChange2FA(false);
      setShowSMSConfirmationCodeOffModal(false);
    } else if (smsTfaEnabled) {
      setShowTwoAuthSuccessModal(true);
      setShowSMSConfirmationCodeOnModal(false);
    } else {
      setShowSMSConfirmationCodeOffModal(false);
      handleCancel();
    }

    updateCurrentUser({ smsTfaEnabled, tfaOptOut: false, phone: phone! });
  };

  const handleHideSMSConfirmationCodeOffModal = () => {
    setShowSMSConfirmationCodeOffModal(false);
    dispatch(optOutTwoAuth());
    updateCurrentUser({ tfaOptOut: true });
    handleShow2FAProcess(false);
  };

  const handleSMSCodeGenerated = (phoneNumber: string) => {
    setPhone(phoneNumber);
    setShowSMSModal(false);
    setShowSMSConfirmationCodeOnModal(true);
  };

  const handleSave2FASelection = async () => {
    const { twoFa } = getValues();

    if (twoFa === 'enable') {
      setShow2FASelectionModal(false);
      if (currentUser.smsTfaEnabled) {
        setIsChange2FA(true);
        setShowSMSConfirmationCodeOffModal(true);
        await dispatch(turnOffSmsTwoAuth());
      } else if (currentUser.tfaEnabled) {
        setIsChange2FA(true);
        setShowEnterGoogleValidationCodeModal(true);
      } else {
        setShowChoiceModal(true);
      }
    } else if (
      twoFa === 'disable' &&
      !currentUser.smsTfaEnabled &&
      !currentUser.tfaEnabled
    ) {
      await dispatch(optOutTwoAuth());
      updateCurrentUser({ tfaOptOut: true });
      handleCancel();
    } else if (currentUser.smsTfaEnabled && twoFa === 'disable') {
      await dispatch(turnOffSmsTwoAuth());
      setShow2FASelectionModal(false);
      setShowSMSConfirmationCodeOffModal(true);
    } else if (currentUser.tfaEnabled && twoFa === 'disable') {
      setShow2FASelectionModal(false);
      setShowEnterGoogleValidationCodeModal(true);
    }
  };

  const handleCancel = () => {
    setShow2FASelectionModal(false);
    setShowChoiceModal(false);
    setShowGoogleModal(false);
    setShowEnterGoogleValidationCodeModal(false);
    setShowSMSModal(false);
    setShowTwoAuthSuccessModal(false);
    handleShow2FAProcess(false);
  };

  return (
    <>
      {show2FASelectionModal && (
        <TwoFactorAuthSelectionModal
          register={register}
          watch={watch}
          handleSave2FASelection={handleSave2FASelection}
          onCancel={handleCancel}
        />
      )}

      {showChoiceModal && (
        <TwoAuthChoiceModal onContinue={handleChoice} onCancel={handleCancel} />
      )}
      {showGoogleModal && (
        <TwoAuthGoogleModal
          onCancel={handleCancel}
          onSuccess={handleSuccessTurnOnGoogleAuth}
        />
      )}
      {showEnterGoogleValidationCodeModal && (
        <TwoAuthEnterGoogleValidationCodeModal
          onCancel={handleCancel}
          onSuccess={handleSuccessEnterGoogleValidationCodeModal}
          requestActionFn={turnOffGoogleTwoAuth}
          is2FAChange={isChange2FA}
        />
      )}
      {showSMSConfirmationCodeOnModal && (
        <TwoAuthEnterSMSConfirmationCodeModal
          onCancel={handleCancel}
          onSuccess={() => handleSMSConfirmationSuccess(true)}
          phone={phone}
          requestActionFn={turnOnSmsTwoAuth}
          is2FAChange={isChange2FA}
        />
      )}
      {showSMSConfirmationCodeOffModal && (
        <TwoAuthEnterSMSConfirmationCodeModal
          onCancel={handleHideSMSConfirmationCodeOffModal}
          onSuccess={() => handleSMSConfirmationSuccess(false)}
          phone={phone}
          requestActionFn={turnOffSmsTwoAuthConfirm}
          is2FAChange={isChange2FA}
        />
      )}
      {showSMSModal && (
        <TwoAuthSMSModal
          onCancel={handleCancel}
          onCodeGenerated={handleSMSCodeGenerated}
          defaultPhone={currentUser.phone}
        />
      )}
      {showTwoAuthSuccessModal && (
        <TwoAuthEnableSuccessModal onSuccess={handleCancel} />
      )}
    </>
  );
}
