import helper from 'store/reducers/reducerHelper';
import {
  LOAD_CURRENT_USER,
  UPDATE_CURRENT_USER,
  SET_USER_LANGUAGE,
} from 'store/types/userActionTypes';
import initialState from 'store/reducers/initialState';

const userReducer = (state = initialState.user, action) => {
  return helper.handleActions(state, action, {
    [LOAD_CURRENT_USER](state, payload) {
      state.current = payload.user;
    },

    [UPDATE_CURRENT_USER](state, payload) {
      state.current = { ...state.current, ...payload.userData };
    },

    [SET_USER_LANGUAGE](state, payload) {
      state.current = { ...state.current, language: payload.language };
    },
  });
};

export default userReducer;
