import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useCongratsText } from 'modules/estatePlan/helpers';
import { useTranslation } from 'modules/common/helpers';

import plant from 'images/plant.svg';

interface PlanProgressWidgetProps {
  percentValue: number;
}

function PlanProgressWidget({ percentValue }: PlanProgressWidgetProps) {
  const { t } = useTranslation(['common', 'plan']);
  const congratsText = useCongratsText();
  const navigate = useNavigate();

  const handleVaultPageRedirect = () => {
    navigate('/plan');
  };

  return (
    <div className="t-flex t-pt-5 t-px-6 t-pb-6">
      <div className="xs:t-hidden lg:t-block ">
        <img src={plant} className="t-mr-5" alt="plant" />
      </div>

      <div
        className="t-flex-grow t-cursor-pointer"
        onClick={handleVaultPageRedirect}
      >
        <div className="Percentage-header">
          <div className="t-flex t-justify-between">
            <h2 className="Typography Typography--title t-text-gamma-400 u-weight-600 xs:t-mb-5 lg:t-mb-3">
              {t('plan:my_estate_plan')}
            </h2>
            <span className="Percentage-value">{percentValue}%</span>
          </div>

          <div className="Percentage-line">
            <div
              className="Percentage-lineValue"
              style={{ width: `${percentValue}%` }}
            />
          </div>
        </div>

        <div className="Percentage-text">
          <p className="Typography Typography--main t-text-gamma-400">
            {congratsText}
          </p>
        </div>

        <p className="text-main-sm t-text-delta-400">
          {t('plan:we_guide_you')}
        </p>
      </div>
    </div>
  );
}

export default PlanProgressWidget;
