import React from 'react';
import { Dropdown } from 'react-bootstrap';

import {
  config,
  dateHelper,
  getFullName,
  optionsHelper,
  useTranslation,
} from 'modules/common/helpers';

import { ReactComponent as DeleteIcon } from 'images/icon-delete.svg';
import { ReactComponent as MenuIcon } from 'images/icon-menu.svg';
import { ReactComponent as IconEdit } from 'images/icon-edit.svg';
import { ReactComponent as IconBirthday } from 'images/icon-birthday-cake.svg';

interface BequestCardProps {
  bequestData: BequestDTO;
  onDelete?: (id: string) => void;
  onEdit?: (assetItem: BequestDTO) => void;
  className?: string;
  isDropdownMenuHidden?: boolean;
}

function BequestCard(props: BequestCardProps) {
  const { onDelete, onEdit, bequestData, className, isDropdownMenuHidden } =
    props;
  const { t } = useTranslation(['common', 'will', 'dashboard']);

  const bequestFromContactOptions = optionsHelper.getBequestFromOptions();
  const bequestFrom = bequestFromContactOptions.find(
    (item) => item.value === bequestData.from
  );

  return (
    <>
      <div className={className || 'card t-p-6'}>
        <div className="Character Character--human">
          <div className="Character-menu">
            <Dropdown className="dropdown--settings">
              {!isDropdownMenuHidden && (
                <Dropdown.Toggle variant="success" id="dropdown-settings">
                  <MenuIcon className="t-text-beta-200" />
                </Dropdown.Toggle>
              )}

              <Dropdown.Menu className="t-m-0">
                {onEdit && (
                  <Dropdown.Item onClick={() => onEdit(bequestData)}>
                    <IconEdit className="dropdown-icon t-text-beta-200" />
                    {t('common:edit_label')}
                  </Dropdown.Item>
                )}

                {onDelete && (
                  <Dropdown.Item onClick={() => onDelete(bequestData.id || '')}>
                    <DeleteIcon className="dropdown-icon dropdown-icon--delete t-text-epsilon-400" />
                    {t('common:delete_label')}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div>
            <p className="typo-gamma">{getFullName(bequestData)}</p>
            <div className="t-flex t-mt-1 t-mb-1">
              {bequestData?.birthday && (
                <p className="typo-zeta t-text-alpha-600 t-flex">
                  <IconBirthday className="t-mr-1" />
                  {dateHelper.convertDateFormat(
                    bequestData.birthday,
                    config.format.uiDate
                  )}
                  ,
                </p>
              )}
              <p className="typo-zeta t-text-alpha-600 t-ml-2">
                {bequestData?.placeOfBirth || ''}
              </p>
            </div>
            <p className="typo-gamma t-mt-3">
              {bequestData?.description || ''}
            </p>

            {bequestFrom && (
              <div className="t-pt-2.5 t-pb-3">
                <p className="typo-zeta">{t('will:bequested_by')}</p>
                <p className="typo-beta">{bequestFrom.label}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="u-pb-10" />
    </>
  );
}

export default BequestCard;
