import { ASSET_CATEGORIES } from 'modules/estatePlan/constants';
import { camelCase } from 'lodash';

// FIXME: remove when aligned asset types!!!!!
export default function UNNESESSARY_METHOD_getProperAssetType(
  wrongAssetType: string
): ASSET_CATEGORIES {
  let assetToEditType: ASSET_CATEGORIES;

  switch (wrongAssetType) {
    case 'electronic device':
      assetToEditType = ASSET_CATEGORIES.DEVICES;
      break;

    case 'digital asset':
      assetToEditType = ASSET_CATEGORIES.DIGITAL;
      break;

    default:
      assetToEditType = camelCase(wrongAssetType) as ASSET_CATEGORIES;
      break;
  }

  return assetToEditType;
}
