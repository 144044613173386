import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { format, parseISO } from 'date-fns';

import { RootState } from 'store/reducers';
import { TransmortalAssetSelector } from 'store/selectors/selectors';
import {
  dateHelper,
  config,
  useGetCountries,
  useGetCountryOptionByCode,
  getFullName,
  useTranslation,
} from 'modules/common/helpers';
import useGetLocalizationForDate from 'localization/utils/useGetLocalizationForDate';

import { ReactComponent as DeleteIcon } from 'images/icon-delete.svg';
import { ReactComponent as IconEye } from 'images/eye.svg';
import { ReactComponent as MenuIcon } from 'images/icon-menu.svg';
import { ReactComponent as IconDownload } from 'images/icon-download.svg';
import { ReactComponent as LocationIcon } from 'images/location-icon.svg';
import { ReactComponent as CheckIcon } from 'images/check.svg';

interface TransmortalCardProps {
  onDownload: (document: DocumentDTO) => void;
  onDelete: (
    id: string,
    title: string,
    assetCategory: string,
    assetType: string
  ) => void;
  onView: () => void;
}

function TransmortalCard({
  onDelete,
  onDownload,
  onView,
}: TransmortalCardProps) {
  const { t } = useTranslation(['common', 'plan']);
  const countries = useGetCountries();
  const locale = useGetLocalizationForDate();

  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const transmortalAsset: AssetDTO = useSelector((state: RootState) =>
    TransmortalAssetSelector(state)
  );

  const { birthday, address, country, zip, city } = user;
  const formattedCountry = useGetCountryOptionByCode(country, countries);
  const formattedBirthday = dateHelper.convertDateFormat(
    birthday || '',
    config.format.uiDate
  );
  const hasLocation = !!zip && !!city && !!formattedCountry.label;
  const transmortalAssetExist = !!transmortalAsset?.id;
  const signedDate = transmortalAsset?.updatedAt
    ? format(parseISO(transmortalAsset.updatedAt), config.format.uiDate, {
        locale,
      })
    : '';

  const fullName = getFullName(user);

  const lastDocument: DocumentDTO =
    transmortalAsset?.documents[transmortalAsset.documents.length - 1] ||
    ({} as DocumentDTO);

  return (
    <>
      <div className="card u-pt-25 u-pr-30 u-pb-25 u-pl-30">
        <div className="Character Character--human">
          <div className="Character-menu">
            <Dropdown className="dropdown--settings">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-settings"
                className="t-mt-0.5"
              >
                <MenuIcon className="t-text-beta-200" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="t-m-0">
                <Dropdown.Item
                  className="u-noWrap"
                  onClick={() => onDownload(lastDocument)}
                >
                  <IconDownload className="t-text-beta-500 t-absolute t-left-4 t-w-4" />
                  {t('common:download_label')}
                </Dropdown.Item>

                <Dropdown.Item onClick={onView}>
                  <IconEye className="t-text-beta-200 dropdown-icon" />
                  {t('common:view')}
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() =>
                    onDelete(
                      transmortalAsset.id || '',
                      lastDocument.documentTitle || '',
                      transmortalAsset.category || '',
                      transmortalAsset.type || ''
                    )
                  }
                >
                  <DeleteIcon className="dropdown-icon dropdown-icon--delete t-text-epsilon-400" />
                  {t('common:delete_label')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="Character-footer">
            <div>
              <div className="t-flex t-justify-between t-flex-col sm:t-flex-row">
                <p className="typo-delta t-order-2 sm:t-order-1">{fullName}</p>
                {transmortalAssetExist && (
                  <div className="t-flex t-justify-end t-order-1 sm:t-order-2">
                    <div className="t-mr-5 t-flex t-items-center t-bg-alpha-100 t-p-1 t-rounded-sm t-flex-nowrap">
                      <CheckIcon className="t-w-3 t-h-3 t-mr-2 t-text-alpha-600" />
                      <p className="typo-kappa t-text-beta-400">
                        {t('plan:transmortal_authorization_signed')}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <p className="typo-epsilon t-mt-1">{formattedBirthday}</p>
              <p className="typo-theta t-mt-3 md:t-w-11/12">
                {t('plan:transmortal_authorization_card_description', {
                  signedDate,
                })}
              </p>
              <div className="t-flex t-mt-6">
                {hasLocation && (
                  <div className="t-mr-2">
                    <LocationIcon className="t-text-beta-500" />
                  </div>
                )}
                <div>
                  {address && <p className="typo-epsilon">{address}</p>}
                  <p className="typo-epsilon">{`${zip || ''} ${city || ''}${
                    !!zip || !!city ? ',' : ''
                  } ${formattedCountry.label || ''}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="u-pb-10" />
    </>
  );
}

export default TransmortalCard;
