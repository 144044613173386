import { Placement } from 'react-joyride';

import imgWelcome from 'images/tutorial/img_welcome.png';
import imgContacts from 'images/tutorial/img_contacts.png';
import imgNextSteps from 'images/tutorial/img_next_steps.png';
import imgMyAssets from 'images/tutorial/img_my_assets.png';
import imgNavbar from 'images/tutorial/img_navbar.png';
import imgMyAgent from 'images/tutorial/img_my_agent.png';

export const getDashboardSteps = (
  gender: string,
  t: (key: string) => string
) => [
  {
    target: 'body',
    title: t('tutorial:dashboard_welcome_title'),
    content: t('tutorial:dashboard_welcome_content'),
    placement: 'center' as Placement,
    containerClassName: 't-max-w-sm',
    disableBeacon: true,
    image: imgWelcome,
    currentStep: 1,
    totalSteps: 5,
  },
  {
    target: '[data-tour="dashboard_nextSteps"]',
    title: t('tutorial:dashboard_nextSteps_title'),
    content: t('tutorial:dashboard_nextSteps_content'),
    disableBeacon: true,
    image: imgNextSteps,
    containerClassName: 't-max-w-96',
    placement: 'right',
    currentStep: 2,
    totalSteps: 5,
  },
  {
    target: '[data-tour="dashboard_contacts"]',
    title: t('tutorial:dashboard_contacts_title'),
    content: t('tutorial:dashboard_contacts_content'),
    disableBeacon: true,
    image: imgContacts,
    containerClassName: 't-max-w-96',
    placement: 'left-start',
    currentStep: 3,
    totalSteps: 5,
  },
  {
    target: '[data-tour="dashboard_assetsWidget"]',
    title: t('tutorial:dashboard_assetsWidget_title'),
    content: t('tutorial:dashboard_assetsWidget_content'),
    disableBeacon: true,
    image: imgMyAssets,
    containerClassName: 't-max-w-96',
    placement: 'auto',
    currentStep: 4,
    totalSteps: 5,
    skipIfAgent: true,
  },
  {
    target: '[data-tour="dashboard_agentWidget"]',
    title: t(`tutorial:dashboard_agentWidget_title_${gender}`),
    content: t(`tutorial:dashboard_agentWidget_content_${gender}`),
    disableBeacon: true,
    image: imgMyAgent,
    containerClassName: 't-max-w-96',
    placement: 'auto',
    currentStep: 4,
    totalSteps: 5,
  },
  {
    target: '[data-tour="dashboard_navigationBar"]',
    title: t('tutorial:dashboard_navigationBar_title'),
    content: t('tutorial:dashboard_navigationBar_content'),
    disableBeacon: true,
    image: imgNavbar,
    containerClassName: 't-max-w-96',
    placement: 'right-start',
    currentStep: 5,
    totalSteps: 5,
  },
];
