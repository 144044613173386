import React, { useEffect } from 'react';

import { generateSMSCode, loginUser } from 'store/actions/userActions';
import { useAsyncRequest, useTranslation } from 'modules/common/helpers';
import useCountdownTimer from 'modules/twoAuth/utils/useCountdownTimer';

import { Button } from 'modules/common/components';

export interface GenerateNewCodeButtonProps {
  phone: string;
  loginData?: UserCredentials;
  renewAccessToken?: (accessToken: string) => void;
}

const GenerateNewCodeButton = ({
  phone,
  loginData,
  renewAccessToken,
}: GenerateNewCodeButtonProps) => {
  const { t } = useTranslation(['auth']);
  const {
    start: startTimer,
    started: timerStarted,
    reset: timerReset,
  } = useCountdownTimer(60);
  const [currentTime, setCurrentTime] = React.useState(59);

  React.useEffect(() => {
    const myInterval = setInterval(() => {
      if (
        currentTime > 0 &&
        timerStarted &&
        !(generateLoading || loginUserLoading)
      ) {
        setCurrentTime(currentTime - 1);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  const {
    request: generateSMSCodeRequest,
    loading: generateLoading,
    error: generateError,
  } = useAsyncRequest(generateSMSCode);
  const {
    request: loginUserRequest,
    loading: loginUserLoading,
    error: loginUserError,
  } = useAsyncRequest(loginUser);

  useEffect(() => {
    if (generateError || loginUserError) {
      timerReset();
    } else {
      startTimer();
    }
  }, [generateError, loginUserError, startTimer, timerReset]);

  const handleGenerateCode = async () => {
    timerReset();
    setCurrentTime(59);
    if (loginData) {
      const response = await loginUserRequest(loginData, true, false);
      if (response?.accessToken && !!renewAccessToken) {
        renewAccessToken(response.accessToken);
      }
    } else {
      generateSMSCodeRequest(phone);
    }
    startTimer();
  };

  const buttonDisabled = generateLoading || loginUserLoading || timerStarted;
  const showTimeout =
    timerStarted && !!currentTime && !(generateLoading || loginUserLoading);

  return (
    <Button
      disabled={buttonDisabled}
      category="secondary"
      className="t-self-end"
      onClick={handleGenerateCode}
      testId="generate-code-button"
    >
      {t('auth:two_auth_sms_confirmation_get_new_code', {
        timeout: showTimeout ? ` (${currentTime})` : '',
      })}
    </Button>
  );
};

export default GenerateNewCodeButton;
