import i18n from 'localization/i18n';
import { GENDERS } from 'modules/common/constants/enums';

export const getFullName = (
  contactInfo: Record<string, any>,
  withTitle = true
): string => {
  if (!contactInfo) return '';

  const GENDER_TYPES = {
    male: i18n.t('common:male'),
    female: i18n.t('common:female'),
  };

  const title =
    withTitle && contactInfo?.gender && contactInfo.gender !== GENDERS.OTHER
      ? GENDER_TYPES[contactInfo.gender]
      : '';

  return `${title}${withTitle && contactInfo?.gender ? ' ' : ''}${
    contactInfo?.name || ''
  } ${contactInfo?.surname || ''}`;
};
