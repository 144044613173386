import { Button, Modal } from 'modules/common/components';
import { useTranslation } from 'modules/common/helpers';
import { AnswersAccordion } from 'modules/estatePlan/components';
import { PAGES } from 'modules/estatePlan/constants/enums';
import React from 'react';

interface FamilyAccountLearnMoreModalProps {
  onClose: () => void;
}

const FamilyAccountLearnMoreModal = ({
  onClose,
}: FamilyAccountLearnMoreModalProps) => {
  const { t } = useTranslation(['familyAccounts']);

  return (
    <Modal show onHide={onClose}>
      <h1 className="typo-alpha t-mb-6">
        {t('familyAccounts:how_does_this_work_info_section_title')}
      </h1>
      <AnswersAccordion
        page={PAGES.MY_FAMILY}
        itemClassName="t-pb-2"
        firstItemActive
      />

      <div className="t-mt-4 t-flex t-justify-end">
        <Button
          category="secondary"
          className="t-mr-0 t-py-2 t-mb-3 sm:t-mb-0 sm:t-mr-3"
          onClick={onClose}
        >
          {t('common:close')}
        </Button>
      </div>
    </Modal>
  );
};

export default FamilyAccountLearnMoreModal;
