import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { RootState } from 'store/reducers';
import { updateTutorial } from 'store/actions/userActions';
import { formatPlanPercentage } from 'modules/estatePlan/helpers';
import { useViewport } from 'modules/common/helpers/viewportHelper';

import { Col, Row } from 'modules/bootstrap/components';
import {
  NextStepsWidget,
  DocumentsWidget,
  EmptyDocumentsWidget,
  PlanProgressWidget,
  MyContactsWidget,
} from 'modules/dashboard/components';
import { useQuery, useTranslation } from 'modules/common/helpers';
import { AgentWidgetDashboard } from 'modules/agent/components';
import { Tutorial } from 'modules/common/components';

import { getDashboardSteps } from 'modules/dashboard/constants';
import { GENDERS, URL_PARAMS } from 'modules/common/constants/enums';

function DashboardPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useViewport();
  const { t } = useTranslation(['tutorial', 'common']);
  const tutorialParam = useQuery().get(URL_PARAMS.TUTORIAL);

  const planProgress: PlanProgressDTO = useSelector(
    (state: RootState) => state.plan.progress
  );
  const assetsData: AssetsDTO = useSelector((state: RootState) => state.assets);
  const nextSteps = useSelector((state: RootState) => state.nextSteps.list);

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const tutorial = currentUser?.tutorial || ({} as TutorialField);
  const globalModal = useSelector((state: RootState) => state.modal.name);

  React.useEffect(() => {
    if (tutorialParam === '1') {
      handleResetTutorialFromURL();
    }
  }, []);

  const handleResetTutorialFromURL = async () => {
    await dispatch(updateTutorial({ ...tutorial, dashboard: true }));
    navigate('/dashboard', { replace: true });
  };

  const getOverallProgressValue = () =>
    formatPlanPercentage(planProgress?.overall);

  const [showOnBoardingTour, setShowOnBoardingTour] = React.useState(false);

  const renderDocumentsWidget = () => {
    if (isEmpty(assetsData)) return <EmptyDocumentsWidget />;

    return (
      <div className="Documents">
        <DocumentsWidget assetsData={assetsData} />
      </div>
    );
  };

  if (currentUser.viewer && !currentUser.regular) {
    navigate('/shared');
  }

  if (!currentUser.viewer && !currentUser.regular) {
    navigate('/help');
  }

  const handleCloseOnBoardingTour = async () => {
    setShowOnBoardingTour(false);
    await dispatch(updateTutorial({ ...tutorial, dashboard: false }));
  };

  React.useLayoutEffect(() => {
    if (
      nextSteps?.length &&
      !!tutorial.dashboard &&
      (width || 0) >= 1200 &&
      !currentUser.secondary
    ) {
      setShowOnBoardingTour(true);
    } else {
      setShowOnBoardingTour(false);
    }
  }, [currentUser, nextSteps, width]);

  const dashboardSteps = getDashboardSteps(
    currentUser.agent?.gender || GENDERS.OTHER,
    t
  );

  const steps = dashboardSteps.filter(
    (step) => !(currentUser.agent && step.skipIfAgent)
  );

  return (
    <>
      {showOnBoardingTour && currentUser.regular && !globalModal && (
        <Tutorial onClose={handleCloseOnBoardingTour} steps={steps} />
      )}
      <Row>
        <Col xl={{ span: 7, offset: 2 }} className="xl:t-pt-0">
          <div className="card t-bg-delta-900">
            <PlanProgressWidget percentValue={getOverallProgressValue()} />
          </div>

          <div className="t-pb-5" />

          <div data-tour="dashboard_nextSteps">
            <NextStepsWidget />
          </div>
        </Col>

        <Col
          xl="3"
          className="t-pt-2.5 xl:t-pt-0"
          data-tour="dashboard_contacts"
        >
          <MyContactsWidget />
        </Col>
      </Row>
      {currentUser.agent ? (
        <Row>
          <Col
            xl={{ span: 10, offset: 2 }}
            className="t-mt-5 t-overflow-hidden"
          >
            <AgentWidgetDashboard />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xl={{ span: 10, offset: 2 }} className="u-pt-20">
            <div className="card" data-tour="dashboard_assetsWidget">
              {renderDocumentsWidget()}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}

export default DashboardPage;
