import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { validateKeyHolder } from 'store/actions/afterDeathActions';
import { handleResponseError, useTranslation } from 'modules/common/helpers';
import { reactToast } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import {
  SiteLanguage,
  TextInputRef,
  PlatformFeatureInfoSection,
  Button,
} from 'modules/common/components';
import {
  AuthFooter,
  ClickableLogo,
  LoginFooter,
} from 'modules/auth/components';

import { ERROR_CODES } from 'modules/common/constants';
import { keyHolderFeatures } from 'modules/auth/constants';

import imgRectangle from 'images/img-auth-rectangle.png';
import imgSuccess from 'images/img-key-holder-page-success.png';
import imgError from 'images/img-key-holder-page-error.png';
import { ReactComponent as IconWarning } from 'images/icon-warning.svg';
import { ReactComponent as IconCurvedArrow } from 'images/curved_arrow.svg';

const STEPS = {
  STEP_INPUT: 'stepInput',
  STEP_SUCCESS: 'stepSuccess',
};

type Inputs = {
  passphrase: string;
};

function KeyHolderPage() {
  const { t } = useTranslation(['auth']);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,

    formState: { errors, isSubmitSuccessful },
  } = useForm<Inputs>({
    defaultValues: {
      passphrase: '',
    },
  });

  const { token = '' } = useParams();

  const [isAccessLoading, setIsAccessLoading] = React.useState(false);
  const [step, setStep] = React.useState(STEPS.STEP_INPUT);
  const [pageError, setPageError] = React.useState(false);

  const handleSecurityKeySubmit = async (formData) => {
    setIsAccessLoading(true);

    const submitData = { token, key: formData.passphrase };

    const response: any = await dispatch(
      validateKeyHolder(submitData, true, {
        returnError: true,
      })
    );

    const error = handleResponseError(response);
    if (!error) {
      setStep(STEPS.STEP_SUCCESS);
    } else if (error === ERROR_CODES.PARSING_ERROR) {
      reactToast.showError(t(`errors:${error}`));
    } else {
      setPageError(true);
      setStep(STEPS.STEP_INPUT);
    }

    setIsAccessLoading(false);
  };

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const fieldRequired = `${t(
    'afterDeath:key_holder_page_passphrase_label'
  )} ${t('common:required')}`;

  return (
    <>
      <Row
        className="lg:t-p-0 t-min-h-screen t-w-full t-m-0 t-relative t-bg-delta-200"
        data-testid="KeyHolderPage"
      >
        <Col
          md={{ span: 6, offset: 3 }}
          lg={{ span: 10, offset: 1 }}
          className="t-p-0 t-z-40"
        >
          <div className="t-hidden md:t-flex t-justify-between t-mt-6 lg:t-mt-10 t-px-3">
            <ClickableLogo />
            <div className="t-flex t-items-center">
              <div className="t-hidden lg:t-inline-block">
                <AuthFooter />
              </div>
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>
          </div>

          <div className="md:t-flex t-flex-col t-items-center t-mt-4 lg:t-mt-16 t-px-3 t-mb-16">
            <div className="md:t-hidden t-flex t-justify-between">
              <ClickableLogo />
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>

            <div className="card t-shadow-lg t-bg-beta-50 t-p-6 lg:t-px-12 lg:t-pt-12 t-w-full lg:t-w-120">
              {step === STEPS.STEP_INPUT && !pageError && (
                <>
                  <h2 className="typo-beta t-mb-5">
                    {t('afterDeath:key_holder_page_card_title')}
                  </h2>
                  <p className="typo-epsilon t-mt-5">
                    {t('afterDeath:key_holder_page_card_description')}
                  </p>
                </>
              )}

              {step === STEPS.STEP_INPUT && !!pageError && (
                <div>
                  <div className="t-flex t-justify-center t-items-start">
                    <img
                      src={imgError}
                      alt="incorrect security key"
                      className="t-max-w-40"
                    />
                  </div>
                  <h2 className="typo-beta t-text-center t-my-5">
                    {t('afterDeath:key_holder_page_incorrect_key_title')}
                  </h2>
                  <p className="typo-epsilon t-text-center">
                    {t('afterDeath:key_holder_page_incorrect_key_description')}
                  </p>
                </div>
              )}

              {step === STEPS.STEP_SUCCESS && (
                <div className="t-mb-11 t-w-full">
                  <div className="t-flex t-justify-center t-items-start">
                    <img
                      src={imgSuccess}
                      alt="accepted security key"
                      className="t-max-w-40"
                    />
                  </div>
                  <h2 className="typo-beta t-text-center t-my-5">
                    {t('afterDeath:key_holder_page_success_title')}
                  </h2>
                  <p className="typo-epsilon t-text-center t-text-beta-500">
                    {t('afterDeath:key_holder_page_success_description')}
                  </p>
                </div>
              )}

              {step === STEPS.STEP_INPUT && (
                <form
                  onSubmit={handleSubmit(handleSecurityKeySubmit)}
                  className="Form t-w-full t-bg-transparent t-mt-2"
                  data-testid="key-holder-form"
                >
                  <div className="t-py-3 t-w-full t-mb-5">
                    <div className="t-rounded t-bg-delta-100 t-p-4">
                      <div className="t-flex t-items-center">
                        <IconWarning className="t-text-delta-700 t-mr-2" />
                        <h2 className="typo-epsilon t-text-beta-700 t-font-semibold">
                          {t('afterDeath:security_example_title')}
                        </h2>
                      </div>
                      <p className="typo-theta t-text-beta-700 t-mt-2">
                        {t('afterDeath:security_example_description')}
                      </p>
                      <div className="t-flex t-mt-6 t-items-center">
                        <div className="t-rounded-sm t-p-2 t-bg-gamma-400 t-relative t-whitespace-nowrap">
                          <p className="typo-beta t-text-beta-700">XXXXXXXX</p>
                          <IconCurvedArrow className="t-text-delta-700 t-absolute t--top-6 sx:t--top-5 sm:t--top-4 t--right-7" />
                        </div>
                        <p className="typo-epsilon t-text-delta-700 t-ml-2">
                          {t('passPhraseModal:security_example_code_text')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <fieldset disabled={isAccessLoading}>
                    <div>
                      <TextInputRef
                        {...register('passphrase', {
                          required: true,
                        })}
                        label={t('afterDeath:key_holder_page_passphrase_label')}
                        maxLength={8}
                        type="text"
                        error={errors.passphrase && fieldRequired}
                        defaultValue=""
                        showLockIcon
                      />
                    </div>
                  </fieldset>

                  <div className="t-flex t-justify-end t-mb-5">
                    <Button
                      type="submit"
                      loading={isAccessLoading}
                      className="t-mt-4 t-bg-delta-700 hover:t-shadow-delta-700"
                    >
                      {t('common:submit')}
                    </Button>
                  </div>
                </form>
              )}
            </div>

            <div className="t-my-5 lg:t-mt-16 t-flex t-justify-center md:t-inline">
              <PlatformFeatureInfoSection features={keyHolderFeatures} />
            </div>

            <div className="lg:t-hidden t-mt-16">
              <AuthFooter />
            </div>
          </div>

          <div className="t-absolute t-bottom-0 t-w-full t-flex t-justify-center md:t-inline">
            <LoginFooter />
          </div>
        </Col>
      </Row>
      <div className="t-fixed t-w-screen t-object-cover t-h-3/4 t-bottom-0">
        <img
          src={imgRectangle}
          className="t-w-full t-h-full"
          alt="Rectangle background"
        />
      </div>
    </>
  );
}

export default KeyHolderPage;
