import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { RootState } from 'store/reducers';
import { validateEmail } from 'store/actions/userActions';
import { setModal } from 'store/actions/modalActions';
import { getKeyHolders } from 'store/actions/keyHolderActions';
import {
  useTranslation,
  scrollToElementId,
  getFullName,
} from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { ReferralSectionProfile } from 'modules/referral/components';
import {
  AccountCard,
  BackupContactEmptySection,
  BackupContactSection,
  PricingSection,
  ProfileCard,
  ProfileDeleteProcess,
  ProfileForm,
} from 'modules/profile/components';
import { SubscriptionRequiredModal } from 'modules/subscription/components';
import { Button } from 'modules/common/components';

import { ROUTER_STATE_STATUS } from 'modules/common/constants/enums';
import { NOTIFICATION_CODES as NC } from 'modules/notification/constants';
import { MODALS } from 'modules/common/constants';

import imgReferral from 'images/img-referral.png';
import { ReactComponent as ArrowRightIcon } from 'images/icon-arrow-right.svg';

function Profile() {
  const { t } = useTranslation(['auth', 'common', 'profile', 'securityKey']);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userData: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const contactsData = useSelector((state: RootState) => state.contacts.list);

  const isSubAccount = userData.secondary;
  const fullName = getFullName(userData, false);

  const previousRouteData = (location.state || {}) as Record<string, string>;
  const fromVerifyEmailNotification =
    previousRouteData?.notificationCode === NC.VERIFY_EMAIL_ADDRESS;
  const fromCompleteProfileNotification = [
    NC.REQUIRED_PROFILE_INFO,
    NC.VOLUNTARY_PROFILE_INFO,
  ].includes(previousRouteData?.notificationCode || '');

  const [isFormVisible, setIsFormVisible] = useState(
    fromCompleteProfileNotification
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSubscriptionRequiredModal, setShowSubscriptionRequiredModal] =
    useState(
      previousRouteData?.status === ROUTER_STATE_STATUS.PAYMENT_REQUIRED
    );

  React.useEffect(() => {
    if (fromCompleteProfileNotification) scrollToElementId('profileForm');
    if (fromVerifyEmailNotification) scrollToElementId('accountCard');
    if (previousRouteData.elementId)
      scrollToElementId(previousRouteData.elementId);
  }, [previousRouteData]);

  React.useEffect(() => {
    if (userData.regular) {
      dispatch(getKeyHolders());
    }
  }, [userData]);

  const handleSubscriptionRequiredModalClose = () => {
    setShowSubscriptionRequiredModal(false);
    navigate(location.pathname, { replace: true });
  };

  const onValidateEmail = async () => {
    await dispatch(validateEmail());
  };

  const onShowPaymentModal = () => {
    dispatch(setModal(MODALS.PAYMENT_MODAL));
  };

  return (
    <>
      {showDeleteModal && (
        <ProfileDeleteProcess
          onProcessClose={() => setShowDeleteModal(false)}
        />
      )}

      {showSubscriptionRequiredModal && (
        <SubscriptionRequiredModal
          onClose={handleSubscriptionRequiredModalClose}
        />
      )}

      <Row className="t-h-100" data-testid="profile">
        <Col
          md={{ span: 12 }}
          xl={{ span: 10, offset: 2 }}
          style={{ paddingRight: 0 }}
        >
          <div className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-6 xs:t-py-10 t-mb-7">
            <div className="Profile xl:t-w-1/2">
              <div className="t-flex t-justify-between t-items-start">
                <h1 className="typo-alpha t-pb-3">
                  {t(
                    isSubAccount
                      ? 'familyAccounts:sub_account_profile_page_title'
                      : 'profile:title',
                    { name: fullName }
                  )}
                </h1>
                <p
                  className={`t-text-xs t-py-1 t-px-2 t-rounded-sm t-flex t-whitespace-nowrap ${
                    isSubAccount
                      ? 't-bg-delta-100 t-text-delta-700'
                      : 't-bg-delta-900 t-text-beta-50'
                  }`}
                >
                  {t(
                    `familyAccounts:${
                      isSubAccount ? 'sub_account_badge' : 'main_account_badge'
                    }`
                  )}
                </p>
              </div>

              <p className="typo-gamma t-pb-8">
                {t(
                  isSubAccount
                    ? 'familyAccounts:sub_account_profile_page_description'
                    : 'profile:description'
                )}
              </p>

              {!!userData?.referralOrigin && !isSubAccount && (
                <div className="card t-flex t-items-center t-justify-between t-w-full t-py-5 t-px-4 t-mb-5">
                  <div className="t-flex t-items-center">
                    <img
                      src={imgReferral}
                      alt="Referral Benefits"
                      className="t-max-h-14"
                    />
                    <div className="t-ml-2">
                      <p className="typo-beta">
                        {t(
                          'referral:profile_page_referral_profit_cta_card_title'
                        )}
                      </p>
                      <p className="typo-epsilon t-text-beta-500 ">
                        {t(
                          'referral:profile_page_referral_profit_cta_card_description'
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="t-ml-2">
                    <button
                      className="t-w-10 t-h-10 t-p-0 t-bg-alpha-600 t-rounded t-flex t-items-center t-justify-center"
                      onClick={onShowPaymentModal}
                    >
                      <ArrowRightIcon className="t-w-5 t-h-5 t-text-alpha-600" />
                    </button>
                  </div>
                </div>
              )}

              <h2 className="typo-beta t-pb-3" id="profileForm">
                {t('profile:info_title')}
              </h2>

              <div className="t-rounded t-shadow t-p-6 t-bg-gamma-400 t-pb-10">
                <div className="t-flex">
                  {isFormVisible && (
                    <ProfileForm
                      onClose={() => setIsFormVisible(false)}
                      isProfilePage={true}
                    />
                  )}

                  {!isFormVisible && (
                    <ProfileCard
                      handleShowInfo={() => setIsFormVisible(true)}
                      userData={userData}
                    />
                  )}
                </div>
              </div>

              {!isSubAccount && (
                <>
                  <h2 className="typo-beta t-pb-2.5 t-pt-10" id="accountCard">
                    {t('profile:account_title')}
                  </h2>
                  <AccountCard
                    userData={userData}
                    onValidateEmail={onValidateEmail}
                  />

                  {userData.regular && (
                    <PricingSection paymentStatus={userData.paymentStatus} />
                  )}

                  {userData.regular &&
                    (!!contactsData.length ? (
                      <BackupContactSection contactsData={contactsData} />
                    ) : (
                      <BackupContactEmptySection />
                    ))}

                  <ReferralSectionProfile />

                  <div className="t-flex t-flex-wrap align-items-center justify-content-between t-mt-3">
                    <Button
                      category="dangerous"
                      className="xs:t-mt-5 "
                      onClick={() => setShowDeleteModal(true)}
                    >
                      {t('profile:delete_account')}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Profile;
