import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { downloadDocument } from 'store/actions/assetActions';
import { setModal } from 'store/actions/modalActions';
import {
  handleResponseError,
  reactToast,
  useTranslation,
} from 'modules/common/helpers';

import { Spinner } from 'modules/common/components';

import { ERROR_CODES, MODALS } from 'modules/common/constants';

import { ReactComponent as IconFile } from 'images/icon-file.svg';

type PdfDownloadLinkProps = {
  document?: DocumentDTO;
  isEndScreen?: boolean;
  isArchived?: boolean;
  file?: File;
  tooltip?: string;
};

function PdfDownloadLink(props: PdfDownloadLinkProps) {
  const { isEndScreen, isArchived, file, tooltip } = props;
  const [document, setDocument] = useState<DocumentDTO>(
    (props.document || {}) as DocumentDTO
  );
  const { documentTitle } = document;
  const fileExtension = documentTitle.split('.').pop()?.toUpperCase();
  const uploadedFileExtension = file?.name?.split('.').pop()?.toUpperCase();

  const { t } = useTranslation(['plan']);
  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(false);

  const onDownloadDocument = async (value) => {
    setIsDownloading(true);
    setDocument(value);

    const result = await dispatch(
      downloadDocument(document, { returnError: true })
    );
    const responseError = handleResponseError(result);

    if (responseError === ERROR_CODES.NOT_ALLOWED) {
      dispatch(
        setModal({
          name: MODALS.PASSPHRASE,
          props: {
            successAction: handlePassPhraseSuccess,
          },
        })
      );
    } else if (responseError) {
      reactToast.showError(t(`errors:${responseError}`));
    }

    setIsDownloading(false);
  };

  const handlePassPhraseSuccess = () => {
    onDownloadDocument(document);
  };

  return (
    <>
      <div
        className="Character-iconWrapper t-flex t-items-center"
        data-tip={tooltip || documentTitle || t('plan:no_download')}
        data-testid="PdfDownloadLink"
      >
        {documentTitle ? (
          <>
            <button
              className={`t-flex t-items-center t-outline-none ${
                isArchived ? 't-bg-beta-100' : 't-bg-delta-100'
              } t-rounded-sm t-p-1.5`}
              onClick={() => {
                onDownloadDocument(document);
              }}
              disabled={isDownloading}
            >
              <span>
                {isDownloading ? (
                  <Spinner className="t-h-3 t-w-3 t-text-delta-500" />
                ) : (
                  <IconFile
                    className={`t-h-3 t-w-3 ${
                      isArchived ? 't-text-beta-400' : 't-text-delta-500'
                    }`}
                  />
                )}
              </span>
              <span
                className={`t-whitespace-nowrap typo-kappa ${
                  isArchived ? 't-text-beta-400' : 't-text-delta-500'
                } t-ml-1.5`}
              >
                {fileExtension}
              </span>
            </button>
            <button
              className={`${
                !isEndScreen ? 'u-truncateLine' : null
              } t-outline-none t-text-left t-pl-2.5 ${
                isArchived ? 't-text-beta-400' : 't-text-delta-500'
              }`}
              onClick={() => onDownloadDocument(document)}
              disabled={isDownloading}
            >
              {documentTitle}
            </button>
          </>
        ) : (
          <>
            <span className="FileIcon no-download">
              {uploadedFileExtension || 'PDF'}
            </span>
            {!!file && (
              <span className="t-outline-none t-text-left t-pl-2.5 t-text-delta-500">
                {file.name}
              </span>
            )}
          </>
        )}
      </div>
    </>
  );
}

PdfDownloadLink.defaultProps = {
  document: {
    id: '',
    documentTitle: '',
  },
};

export default PdfDownloadLink;
