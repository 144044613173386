import { assetService } from 'modules/estatePlan/services';
import { getProviderType } from 'modules/estatePlan/helpers';
import { PROVIDER_CONSTANT_TYPES } from 'modules/estatePlan/constants/enums';

export const getFormattedProviderAssetByKey = async (asset: AssetDTO) => {
  if (asset?.provider) {
    const formattedProvider = await assetService.getProviderByKey(
      asset.assetTypeName as PROVIDER_CONSTANT_TYPES,
      asset.provider
    );
    if (formattedProvider)
      return { ...asset, provider: formattedProvider.value } as AssetDTO;
  }
  return asset;
};

export const getAssetTypeProvides = (
  allAvailableProviders: ProvidersDTO,
  asset: AssetDTO,
  categorizedAssetTypes: AssetTypeDTO[]
) => {
  const assetType: AssetTypeDTO =
    categorizedAssetTypes?.find((type) => type.id === asset.assetTypeId) ||
    ({} as AssetTypeDTO);
  const providerType = getProviderType(assetType);
  return allAvailableProviders[providerType] || ([] as ProviderDTO[]);
};
