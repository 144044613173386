import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { camelCase } from 'lodash';

import { getOrCreateAssetTypes } from 'store/actions/assetTypeActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

import {
  QUESTIONNAIRES_TO_ASSET_TYPES,
  REDIRECT_ACTIONS,
} from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';

interface UploadButtonProps {
  questionnaire: string;
  className?: string;
}

const UploadButton = ({ questionnaire, className }: UploadButtonProps) => {
  const { t } = useTranslation(['common', 'assistant']);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);

  const {
    path,
    category,
    assetType,
    subtype = '',
  } = QUESTIONNAIRES_TO_ASSET_TYPES[camelCase(questionnaire)] || {};

  const handleUploadRedirect = async () => {
    setLoading(true);
    if (assetType && path) {
      await dispatch(getOrCreateAssetTypes(assetType));
      await dispatch(
        temporaryFieldSaver({
          actionType: REDIRECT_ACTIONS.UPLOAD_DOCUMENT,
          category,
          assetType,
          subtype,
        })
      );
      navigate(path);
    } else if (!assetType && path) {
      navigate(path);
    }
  };
  return (
    <Button
      className={`t-whitespace-nowrap ${className}`}
      onClick={handleUploadRedirect}
      loading={loading}
    >
      {t('assistant:upload')}
    </Button>
  );
};

export default UploadButton;
