import React from 'react';
import ReactTooltip from 'react-tooltip';

import { useCongratsText } from 'modules/estatePlan/helpers';
import { useTranslation } from 'modules/common/helpers';

interface PlanProgressProps {
  percentValue: number;
}

function PlanProgress({ percentValue }: PlanProgressProps) {
  const { t } = useTranslation(['common', 'plan']);
  const congratsText = useCongratsText();
  const restPercent = 100 - percentValue;
  const tooltip =
    restPercent === 0
      ? ''
      : t('plan:congrats_tooltip', { percentValue: `${restPercent}` });

  return (
    <div className="Card Card--top t-bg-delta-900">
      <ReactTooltip
        type="dark"
        id="planProgressWidget"
        effect="solid"
        place="top"
        multiline={true}
        className="u-text-center u-zindex-max"
      />
      <div className="Percentage Percentage--small">
        <div className="Percentage-header">
          <div className="d-block d-lg-flex align-items-baseline xs:t-mb-4 md:t-mb-2">
            <span className="Percentage-value t-mr-2.5">{percentValue}%</span>
            <span className="Percentage-valueText">{t('plan:ready')}</span>
          </div>

          <div
            className="Percentage-line"
            data-for="planProgressWidget"
            data-tip={tooltip}
          >
            <div
              className="Percentage-lineValue"
              style={{ width: `${percentValue}%` }}
            />
          </div>
        </div>

        <p className="text-main-sm u-pt-10 t-text-gamma-400">{congratsText}</p>
      </div>
    </div>
  );
}

export default PlanProgress;
