import React from 'react';

import { config, useTranslation } from 'modules/common/helpers';

import { Modal } from 'modules/common/components';
import { SecurityInfo } from 'modules/menu/components';

const LoginFooter = () => {
  const { t } = useTranslation(['common']);

  const [securityInfoModalOpen, setSecurityInfoModalOpen] =
    React.useState(false);

  const openSecurityInfoModal = () => {
    setSecurityInfoModalOpen(true);
  };

  const closeSecurityInfoModal = () => {
    setSecurityInfoModalOpen(false);
  };

  return (
    <>
      {securityInfoModalOpen && (
        <Modal show onHide={closeSecurityInfoModal}>
          <SecurityInfo onClose={closeSecurityInfoModal} />
        </Modal>
      )}

      <div className="t-flex t-justify-center t-pb-4" data-testid="LoginFooter">
        <a
          href={`${config.websiteUrl}/imprint`}
          className="SiteNavigation-listLink t-pr-4"
          target="_blank"
          rel="nofollow noreferrer noopener"
        >
          {t('common:footer_imprint')}
        </a>

        <a
          href={`${config.websiteUrl}/privacy`}
          className="SiteNavigation-listLink t-pr-4"
          target="_blank"
          rel="nofollow noreferrer noopener"
        >
          {t('common:footer_privacy')}
        </a>

        <button
          onClick={openSecurityInfoModal}
          className="SiteNavigation-listLink"
        >
          {t('common:footer_security_info')}
        </button>
      </div>
    </>
  );
};

export default LoginFooter;
