import React from 'react';

import { Col, Row } from 'modules/bootstrap/components';
import { MyAgent } from 'modules/agent/components';

function MyAgentPage() {
  return (
    <Row className="t-h-100" data-testid="MyAgentPage">
      <Col
        md={{ span: 12 }}
        xl={{ span: 10, offset: 2 }}
        style={{ paddingRight: 0 }}
      >
        <div className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7">
          <div className="t-max-w-sm">
            <MyAgent />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default MyAgentPage;
