import React from 'react';
import { useDispatch } from 'react-redux';

import { revokeKeyHolder } from 'store/actions/keyHolderActions';
import { useTranslation } from 'modules/common/helpers';

import { Button } from 'modules/common/components';

interface RevokeKeyHoldersProcessProps {
  onCancel: () => void;
  revokeId: string;
}

const RevokeKeyHoldersProcess = ({
  onCancel,
  revokeId,
}: RevokeKeyHoldersProcessProps) => {
  const { t } = useTranslation(['securityKey', 'common']);
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);

  const onConfirm = async () => {
    setLoading(true);
    await dispatch(revokeKeyHolder(revokeId));
    onCancel();
    setLoading(false);
  };

  return (
    <div>
      <h1 className="typo-alpha t-pb-4">
        {t('securityKey:revoke_a_key_holder_confirm_title')}
      </h1>

      <p className="typo-gamma t-mb-8">
        {t('securityKey:revoke_a_key_holder_confirm_description')}
      </p>

      <div className="t-flex t-justify-end">
        <Button
          category="secondary"
          className="t-mr-2.5"
          onClick={onCancel}
          disabled={loading}
        >
          {t('common:cancel')}
        </Button>
        <Button
          className={
            loading ? '' : 't-bg-epsilon-600 hover:t-shadow-epsilon-600'
          }
          onClick={onConfirm}
          loading={loading}
        >
          {t('common:delete')}
        </Button>
      </div>
    </div>
  );
};

export default RevokeKeyHoldersProcess;
