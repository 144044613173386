import React, { memo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  useAsyncRequest,
  useTranslation,
  validationHelper,
} from 'modules/common/helpers';
import { generateQRCode, turnOnGoogleTwoAuth } from 'store/actions/userActions';

import { Button, Modal, TextInputRef } from 'modules/common/components';
import { QrCodePlaceholder } from 'modules/twoAuth/components';

import { LANGUAGES, LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';

import googleAuthenticatorAndroidImage1x from 'images/google-authenticator-android@1x.jpg';
import googleAuthenticatorAndroidImage2x from 'images/google-authenticator-android@2x.jpg';
import googleAuthenticatorAndroidImage3x from 'images/google-authenticator-android@3x.jpg';

import googleAuthenticatorAppleImage1x from 'images/google-authenticator-apple@1x.jpg';
import googleAuthenticatorAppleImage2x from 'images/google-authenticator-apple@2x.jpg';
import googleAuthenticatorAppleImage3x from 'images/google-authenticator-apple@3x.jpg';

type Inputs = {
  tfaCode: number;
};

type TwoAuthGoogleModalProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

const TwoAuthGoogleModal = ({
  onCancel,
  onSuccess,
}: TwoAuthGoogleModalProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const storageLang = localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE);
  const lang = storageLang === LANGUAGES.EN ? 'us' : LANGUAGES.DE;
  const {
    register,
    handleSubmit,
    setError,

    formState: { errors },
  } = useForm<Inputs>();

  const {
    request: generateCode,
    response: code,
    loading: codeLoading,
    error: codeError,
  } = useAsyncRequest(generateQRCode);
  const { request: turnOn, loading: loadingTurnOn } =
    useAsyncRequest(turnOnGoogleTwoAuth);

  useEffect(() => {
    if (!code) {
      generateCode();
    }
  }, [code]);

  const onSubmit = useCallback(
    async ({ tfaCode }: Inputs) => {
      const response = await turnOn(tfaCode);

      if (response) {
        onSuccess();
      } else {
        setError('tfaCode', {
          message: t('auth:two_auth_google_invalid_code'),
          type: 'invalidCode',
        });
      }
    },
    [onSuccess, setError, t, turnOn]
  );

  let error: string | undefined;
  if (errors?.tfaCode?.type === 'required') {
    error = t('common:field_required');
  }
  if (errors?.tfaCode?.type === 'invalidCode') {
    error = errors.tfaCode.message;
  }
  if (errors?.tfaCode?.type === 'validate') {
    error = `${t('auth:validation_code')} ${t('common:invalid')}`;
  }

  return (
    <Modal show onHide={onCancel}>
      <form onSubmit={handleSubmit(onSubmit)} className="Form">
        <h1 className="Typography Typography--title u-weight-600 u-pb-25">
          {t('auth:two_auth_title')}
        </h1>

        <div className="u-pb-40">
          <p className="u-pb-15">1. {t('auth:two_auth_google_step1')}</p>
          <div>
            <a
              className="t-mr-2.5"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://apps.apple.com/${lang}/app/google-authenticator/id388497605`}
            >
              <img
                src={googleAuthenticatorAppleImage1x}
                srcSet={`
                    ${googleAuthenticatorAppleImage2x} 2x,
                    ${googleAuthenticatorAppleImage3x} 3x
                  `}
                alt={t('auth:two_auth_google_authenticator_app_store')}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            >
              <img
                src={googleAuthenticatorAndroidImage1x}
                srcSet={`
                    ${googleAuthenticatorAndroidImage2x} 2x,
                    ${googleAuthenticatorAndroidImage3x} 3x
                  `}
                alt={t('auth:two_auth_google_authenticator_play_store')}
              />
            </a>
          </div>
        </div>

        <div className="u-pb-30">
          <p className="u-pb-10">2. {t('auth:two_auth_google_step2')}</p>
          {codeError && (
            <p className="d-flex align-items-center justify-content-between">
              <span className="t-text-epsilon-600">{codeError}</span>
              <Button category="secondary" onClick={generateCode}>
                {t('common:try_again')}
              </Button>
            </p>
          )}
          {codeLoading && <QrCodePlaceholder />}
          {!codeLoading && !!code && (
            <div
              style={{ width: '180px' }}
              dangerouslySetInnerHTML={{ __html: code! }}
            />
          )}
        </div>

        <div>
          <p className="u-pb-15">3. {t('auth:two_auth_google_step3')}</p>
          <div className="u-pb-25">
            <TextInputRef
              inputClass="Field--confirmCode"
              {...register('tfaCode', {
                required: true,
                validate: (value) => validationHelper.validateCode(6, value),
              })}
              label={t('auth:validation_code')}
              type="tel"
              placeholder="XXXXXX"
              maxLength={6}
              autoComplete={false}
              error={error}
            />
          </div>
        </div>

        <div className="t-flex t-flex-wrap t-justify-end t-mb-16 md:t-mb-0">
          <Button
            category="secondary"
            className="t-self-end"
            onClick={onCancel}
            disabled={loadingTurnOn}
          >
            {t('common:cancel')}
          </Button>
          <Button
            testId="submitCode"
            loading={loadingTurnOn}
            type="submit"
            className="t-ml-5 xs:t-mt-5"
          >
            {t('common:submit_code')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default memo(TwoAuthGoogleModal);
