import React from 'react';
import { isEqual } from 'lodash';

import {
  DateInputPicker,
  SwitchInput,
  TextInputRef,
} from 'modules/common/components';
import {
  checkEmptyValueObject,
  dateHelper,
  isJsonString,
  validationHelper,
  config,
  useTranslation,
} from 'modules/common/helpers';

import {
  FormControl,
  FormErrors,
  FormRegister,
  FormSetValue,
  FormWatch,
} from 'modules/common/types';

import { FINANCIAL_DATA_FIELD_INPUTS as FDFI } from 'modules/common/constants/enums';
import { FIELD_TYPE_KEYS as F } from 'modules/estatePlan/constants/fieldLibrary';
import { VALIDATE } from 'modules/common/constants/generic';

interface FinancialDataFieldPros {
  register: FormRegister;
  control: FormControl;
  errors: FormErrors;
  watch: FormWatch;
  setValue: FormSetValue;
  required: boolean;
  stringifiedFinancialData?: string;
  label?: string;
}

const FinancialDataField = ({
  register,
  control,
  errors,
  watch,
  required,
  stringifiedFinancialData = '',
  setValue,
  label = '',
}: FinancialDataFieldPros) => {
  const { t } = useTranslation(['auth', 'common']);

  const defaultValues =
    stringifiedFinancialData && isJsonString(stringifiedFinancialData)
      ? JSON.parse(stringifiedFinancialData)
      : {};

  const {
    financialValue = '',
    estimatedDate = '',
    estimated = '',
  } = defaultValues;

  // this will be updated when value of a field is changed
  // to update the field value in the parent component
  // also avoid react-hook-form save and object as string '[object Object]'
  const [updatedInputs, setUpdatedInputs] = React.useState(defaultValues);

  const currentInputs = Object.keys(FDFI).reduce((inputs, key) => {
    inputs[FDFI[key]] = watch(FDFI[key]) || '';
    return inputs;
  }, {});

  React.useEffect(() => {
    const valuesDifferent = !isEqual(updatedInputs, currentInputs);
    if (valuesDifferent) {
      setUpdatedInputs(currentInputs);
    }
  }, [currentInputs]);

  React.useEffect(() => {
    const formattedInputs = { ...updatedInputs };
    if (updatedInputs.estimatedDate) {
      formattedInputs.estimatedDate = dateHelper.convertDateFormat(
        formattedInputs.estimatedDate,
        config.format.serverDate
      );
    }
    setValue(
      F.FINANCIAL_DATA,
      checkEmptyValueObject(updatedInputs)
        ? ''
        : JSON.stringify(formattedInputs)
    );
  }, [updatedInputs]);

  const errFinancialValue =
    errors[FDFI.FINANCIAL_VALUE] &&
    errors[FDFI.FINANCIAL_VALUE]?.type === VALIDATE
      ? t('common:invalid_number')
      : errors[FDFI.FINANCIAL_VALUE]
      ? `${t(`common:${FDFI.FINANCIAL_VALUE}_label`)} ${t('common:required')}`
      : '';

  const errBalanceDate = !errors[FDFI.ESTIMATED_DATE]
    ? ''
    : (errors[FDFI.ESTIMATED_DATE]?.message as string);

  const isError = !checkEmptyValueObject({ errFinancialValue, errBalanceDate });

  return (
    <div
      className={`t-border t-border-solid t-border-beta-200 t-rounded t-relative t-pt-1.5 t-px-2 t-mt-5 t-mb-3 ${
        isError ? 't-border-epsilon-600' : ''
      }`}
    >
      {label && (
        <p
          className={`Form-label t-absolute t--top-3 t-left-5 t-px-2 t-bg-gamma-400 ${
            isError ? 'isErrored' : ''
          }`}
        >
          {t(label)}
        </p>
      )}

      <div className="t-w-full t-mt-3">
        <TextInputRef
          {...register(FDFI.FINANCIAL_VALUE, {
            required: required,
            validate: (value) => {
              return validationHelper.isValidNumberNonInteger(value);
            },
          })}
          type="number"
          maxLength={300}
          name={FDFI.FINANCIAL_VALUE}
          error={errFinancialValue}
          label={t(`common:${FDFI.FINANCIAL_VALUE}_label`)}
          defaultValue={financialValue}
          placeholder={t(`common:${FDFI.FINANCIAL_VALUE}_placeholder`)}
        />
      </div>

      <DateInputPicker
        error={errBalanceDate}
        label={t(`common:${FDFI.ESTIMATED_DATE}_label`)}
        required={required}
        control={control}
        name={FDFI.ESTIMATED_DATE}
        defaultValue={estimatedDate}
        setValue={setValue}
        watch={watch}
        extraValidation={(value) => {
          if (!value && !required) return true;
          if (!value)
            return `${t(`common:${FDFI.ESTIMATED_DATE}_label`)} ${t(
              'common:required'
            )}`;
          if (!dateHelper.isValidDate(value))
            return `${t('common:invalid_expiration_date')}`;
          if (dateHelper.checkIsNotPastDate(value))
            return dateHelper.checkIsNotPastDate(value) || '';
          return true;
        }}
      />

      <div className="t-mt-5">
        <SwitchInput
          register={register}
          label={t(`common:${FDFI.ESTIMATED}_label`)}
          name={FDFI.ESTIMATED}
          defaultValue={estimated}
          labelClassName="typo-epsilon t-text-beta-500"
        />
      </div>
      <input
        {...register(F.FINANCIAL_DATA)}
        name={F.FINANCIAL_DATA}
        type="hidden"
      />
    </div>
  );
};

export default FinancialDataField;
