import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import i18n from 'localization/i18n';
import { format, subDays } from 'date-fns';

import { config, dateHelper, useTranslation } from 'modules/common/helpers';

import { FormControl, FormSetValue, FormWatch } from 'modules/common/types';
import { DatePicker } from 'modules/common/components';

import { ReactComponent as IconCalendar } from 'images/icon-calendar.svg';

export interface DateInputPickerProps {
  error: string;
  label: string;
  name: string;
  control: FormControl;
  watch: FormWatch;
  setValue: FormSetValue;
  defaultValue: string;
  required?: boolean;
  extraValidation?: (value: string) => boolean | string;
}

export default function DateInputPicker({
  error,
  label,
  name,
  control,
  defaultValue,
  required,
  extraValidation,
  setValue,
  watch,
}: DateInputPickerProps) {
  const { t } = useTranslation(['common']);

  const [datePickerOpened, setDatePickerOpened] = React.useState(false);
  const [newDate, setNewDate] = React.useState(defaultValue || new Date());
  const [dateToSubmit, setDateToSubmit] = React.useState('');

  const currentInputValue = watch(name);

  React.useEffect(() => {
    if (!currentInputValue) {
      setNewDate(new Date());
    }
    if (dateHelper.isValidDate(currentInputValue)) {
      const date = dateHelper.convertDateFormat(
        currentInputValue,
        config.format.serverDate
      );
      setNewDate(new Date(date));
    }
  }, [currentInputValue]);

  const openDatePicker = () => {
    setDatePickerOpened(true);
  };

  const closeDatePicker = () => {
    setDatePickerOpened(false);
  };

  const handleDateInput = () => {
    setValue(name, dateToSubmit);
    setDatePickerOpened(false);
  };

  const handleDateChange = (date) => {
    setNewDate(date);
    const formattedDate = format(new Date(date), 'dd.MM.yyyy');
    setDateToSubmit(formattedDate);
  };

  return (
    <div className="t-mb-3.5 t-relative">
      {datePickerOpened && (
        <div className="t-absolute t-bottom-10 t-right-0">
          <DatePicker
            date={newDate}
            maxDate={subDays(new Date(), 0)}
            onSubmit={handleDateInput}
            onChange={(date) => handleDateChange(date)}
            onClose={closeDatePicker}
            showTrash={!!defaultValue}
            hideInfo={true}
          />
        </div>
      )}
      <span
        className={`t-inline-block t-mb-2 t-text-beta-500 ${
          error ? 't-text-epsilon-600' : ''
        }`}
      >
        {label}
      </span>
      <Controller
        name={name}
        render={({ field }) => (
          <InputMask
            placeholder={t('common:date_placeholder')}
            mask="99.99.9999"
            className={`Field ${error ? 'isErrored' : ''}`}
            {...field}
          />
        )}
        control={control}
        defaultValue={dateHelper.convertDateFormat(
          defaultValue || '',
          config.format.uiDate
        )}
        rules={{
          required: required,
          validate: (value) => {
            if (!value && !required) return true;
            if (!dateHelper.isValidDate(value))
              return `${i18n.t('common:invalid_date')}`;

            if (extraValidation) {
              return extraValidation(value);
            }
            return true;
          },
        }}
      />

      <div className="t-relative">
        <div className="t-w-11 t-absolute t-right-0 t--top-9 t-h-9 t-flex t-justify-center t-items-center">
          <IconCalendar
            className="t-text-beta-200 t-cursor-pointer"
            onClick={openDatePicker}
          />
        </div>
      </div>

      {error && (
        <div className="t-pt-0.5">
          <span className="text-main-sm t-text-epsilon-600">{error}</span>
        </div>
      )}
    </div>
  );
}
