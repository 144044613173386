import _ from 'lodash';
import React from 'react';
import { Navigate } from 'react-router-dom';

import authService from 'modules/auth/services/authService';

interface PublicPageProps {
  children: Record<string, unknown>;
}

function PublicPage(props: PublicPageProps) {
  const token = authService.getToken();
  const isAccessPage =
    _.endsWith(window.location.href, '/access') ||
    _.endsWith(window.location.href, '/zugang');

  const isAuthenticated = (): boolean => !!token;

  return (
    <>
      {isAuthenticated() && !isAccessPage && <Navigate to="/" />}

      {props.children}
    </>
  );
}

export default PublicPage;
