import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { LogoutButton } from 'modules/common/components';

import { ReactComponent as IconFacebook } from 'images/facebook.svg';
import { ReactComponent as IconLinkedin } from 'images/linkedin.svg';
import { ReactComponent as IconInstagram } from 'images/instagram.svg';

export default function KeepInTouch() {
  const { t } = useTranslation(['auth', 'common', 'help']);
  return (
    <div className="t-h-full t-flex t-flex-col t-justify-between">
      <div>
        <h2 className="typo-alpha t-pb-3 t-mt-3">{t('help:touch_title')}</h2>

        <p className="typo-gamma t-pb-10">{t('help:touch_description')}</p>

        <a
          href="mailto:info@ninebarc.com"
          className="typo-gamma t-font-semibold t-no-underline t-text-delta-700"
        >
          info@ninebarc.com
        </a>

        <p className="typo-gamma t-font-semibold t-pt-5">{t('help:tel')}</p>

        <p className="typo-gamma t-font-semibold t-pt-5">{t('help:adr1')}</p>

        <p className="typo-gamma t-font-semibold">{t('help:adr2')}</p>

        <p className="typo-gamma t-font-semibold t-pb-8">{t('help:adr3')}</p>

        <ul className="t-flex">
          <li className="t-mr-5">
            <a
              href="https://www.facebook.com/Ninebarc/"
              target="_blank"
              rel="noopener noreferrer"
              className="t-no-underline"
            >
              <IconFacebook />
            </a>
          </li>

          <li className="t-mr-5">
            <a
              href="https://www.instagram.com/ninebarc/"
              target="_blank"
              rel="noopener noreferrer"
              className="t-no-underline"
            >
              <IconInstagram />
            </a>
          </li>

          <li>
            <a
              href="https://www.linkedin.com/company/ninebarc"
              target="_blank"
              rel="noopener noreferrer"
              className="t-no-underline"
            >
              <IconLinkedin />
            </a>
          </li>
        </ul>
      </div>
      <div className="t-mt-16">
        <ul>
          <li>
            <a
              href="/imprint"
              target="_blank"
              rel="noopener noreferrer"
              className="t-no-underline t-text-alpha-700 typo-epsilon t-uppercase"
            >
              {t('common:footer_imprint')}
            </a>
          </li>

          <li className="t-mt-6">
            <a
              href="/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="t-no-underline t-text-alpha-700 typo-epsilon t-uppercase"
            >
              {t('common:footer_privacy')}
            </a>
          </li>
          <li className="t-mt-4">
            <LogoutButton className="t-border-none t-bg-transparent t-text-alpha-700 t-p-0 t-m-0 typo-epsilon" />
          </li>
        </ul>
        <p className="t-mt-8 t-text-beta-400">
          {t('shareAssets:copyright1')}
          <span className="t-text-epsilon-600 typo-alpha"> ♥ </span>
          {t('shareAssets:copyright2')}
        </p>
      </div>
    </div>
  );
}
