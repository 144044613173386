import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { customHooks, useTranslation } from 'modules/common/helpers';
import { Button } from 'modules/common/components';

import { acceptedFileExtensions } from 'modules/common/constants/generic';

import { ReactComponent as IconUpload } from 'images/upload.svg';

export interface UploadDocumentButtonProps {
  isDisabled?: boolean;
  onDocumentUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
  isPrimaryColor?: boolean;
}

function UploadDocumentButton(props: UploadDocumentButtonProps) {
  const { isDisabled, onDocumentUpload, title, isPrimaryColor } = props;
  const { t } = useTranslation(['plan']);
  const isMounted = customHooks.useIsMounted();

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  let documentInput: HTMLInputElement;

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    await onDocumentUpload(event);
    if (isMounted.current) {
      setLoading(false);
    }
  };

  return (
    <>
      {!isDisabled ? (
        <Button
          onClick={(): void => documentInput.click()}
          category={isPrimaryColor ? 'primary' : 'secondary'}
          testId="UploadDocumentButton"
          loading={loading}
        >
          {!loading && (
            <IconUpload
              className={`${
                isPrimaryColor ? 't-text-gamma-400' : 't-text-alpha-600'
              } t-mr-1.5`}
            />
          )}

          {title}
          <input
            type="file"
            accept={acceptedFileExtensions}
            ref={(ref: HTMLInputElement) => {
              documentInput = ref;
            }}
            id="upload"
            data-testid="upload"
            onChange={handleUpload}
            style={{ display: 'none' }}
          />
        </Button>
      ) : (
        <div
          data-tip={t('common:warning_unverified_email')}
          data-for="verificationRequired"
          data-testid="verificationRequired"
        >
          <Button category="secondary" className="isDisabled">
            <IconUpload className="t-text-alpha-600 t-mr-1.5" />
            {title}
          </Button>
        </div>
      )}
      <ReactTooltip
        id="verificationRequired"
        effect="solid"
        place="bottom"
        multiline
        className="t-bg-eta-100 typo-epsilon t-text-zeta-600"
      />
    </>
  );
}

export default UploadDocumentButton;
