const PLAN_CATEGORY_PAGES: PlanCategoryPage[] = [
  {
    category: 'healthCare',
    path: '/plan/health-care',
  },
  {
    category: 'contacts',
    path: '/plan/contacts',
  },
  {
    category: 'devices',
    path: '/plan/devices',
  },
  {
    category: 'legal',
    path: '/plan/legal',
  },
  {
    category: 'digitalEstate',
    path: '/plan/digital-estate',
  },
  {
    category: 'financial',
    path: '/plan/financial',
  },
  {
    category: 'insurance',
    path: '/plan/insurance',
  },
  {
    category: 'afterLife',
    path: '/plan/after-life',
  },
];

export default PLAN_CATEGORY_PAGES;
