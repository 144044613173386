import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { sendFeedback } from 'store/actions/userActions';
import { Modal } from 'modules/bootstrap/components';
import { Button } from 'modules/common/components';
import { useTranslation } from 'modules/common/helpers';

const Styles = styled.div`
  .buttonRow {
    margin-top: 30px;
  }
  .feedbackCard {
    display: flex;
    align-items: center;
    p {
      margin: 0 auto;
      text-align: center;
      padding: 0 10px;
    }
  }
`;

interface RequestAdviceFeedbackModalProps {
  onCancel: () => void;
  subject: string;
}

const RequestAdviceFeedbackModal = ({
  onCancel,
  subject,
}: RequestAdviceFeedbackModalProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'will']);

  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [loading, setLoading] = React.useState(false);

  const handleSubmitFeedback = async () => {
    setLoading(true);
    await dispatch(sendFeedback({ subject: subject, body: selectedAnswer }));
    onCancel();
    setLoading(false);
  };

  const toggleAnswer = (answerCode) => {
    setSelectedAnswer(answerCode);
  };

  const handleCancel = () => {
    setSelectedAnswer('');
    onCancel();
  };

  const answers = [
    {
      content: t('will:feedback_modal_button_1'),
      code: 'notProbable',
      color: 't-text-epsilon-600',
    },
    {
      content: t('will:feedback_modal_button_2'),
      code: 'probable',
      color: 't-text-delta-700',
    },
    {
      content: t('will:feedback_modal_button_3'),
      code: 'veryProbable',
      color: 't-text-alpha-700',
    },
  ];

  const handlePressSpace = (e, answer) => {
    if (e && e.keyCode === 32) {
      toggleAnswer(answer);
    }
  };

  return (
    <Modal show onCancel={handleCancel} onHide={handleCancel}>
      <div className="t-bg-gamma-400 t-p-10 t-rounded">
        <Styles>
          <h1 className="t-mb-5 typo-alpha">
            {t('will:feedback_modal_title')}
          </h1>
          <p className="typo-delta t-mb-10">
            {t('will:feedback_modal_description_1')}
          </p>
          <p className="typo-epsilon t-font-semibold t-mb-10">
            {t('will:feedback_modal_description_2')}
          </p>
          <Row>
            {answers.map((answer) => {
              const isSelected = answer.code === selectedAnswer;
              return (
                <Col key={answer.code} sm={12} md={4}>
                  <div
                    className={`AnswerCard t-bg-gamma-400 ${
                      isSelected && ' isSelected'
                    } feedbackCard`}
                    onClick={() => toggleAnswer(answer.code)}
                    onKeyUp={(e) => handlePressSpace(e, answer.code)}
                    role="button"
                    tabIndex={0}
                  >
                    <p
                      className={`t-font-semibold t-font-secondary ${answer.color}`}
                    >
                      {answer.content}
                    </p>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row className="buttonRow flex justify-content-end">
            <Button
              category="secondary"
              className="t-mr-5"
              onClick={handleCancel}
              disabled={loading}
            >
              {t('common:previous')}
            </Button>
            <Button
              onClick={() => handleSubmitFeedback()}
              loading={loading}
              disabled={!selectedAnswer}
            >
              {t('will:feedback_modal_send_button')}
            </Button>
          </Row>
        </Styles>
      </div>
    </Modal>
  );
};

export default RequestAdviceFeedbackModal;
