import React from 'react';
import { useDispatch } from 'react-redux';
import { format, isSameDay, subDays } from 'date-fns';

import { addQuestionnaireReminder } from 'store/actions/remindActions';
import { getRecommendations } from 'store/actions/recommendationsActions';

import { ReminderButton } from 'modules/assistant/components/Assistant';
import { DatePicker } from 'modules/common/components';

interface RecoReminderProps {
  recommendation: string;
  reminder: string | undefined;
}

const RecoReminder = ({ recommendation, reminder }: RecoReminderProps) => {
  const dispatch = useDispatch();
  const [isReminderOpen, setIsReminderOpen] = React.useState(false);
  const [newReminder, setNewReminder] = React.useState(reminder || new Date());
  const [dateToSubmit, setDateToSubmit] = React.useState('');

  const handleReminderToggle = () => {
    setIsReminderOpen((prevState) => !prevState);
  };

  const handleReminderChange = (val) => {
    setNewReminder(val);
    const formattedDate = format(new Date(val), 'yyyy-MM-dd');
    setDateToSubmit(formattedDate);
  };

  const handleReminderSubmit = async () => {
    await dispatch(addQuestionnaireReminder(dateToSubmit, recommendation));
    dispatch(getRecommendations());
    handleReminderToggle();
  };

  const handleReminderDelete = async () => {
    await dispatch(addQuestionnaireReminder('', recommendation));
    dispatch(getRecommendations());
    setNewReminder(new Date());
    handleReminderToggle();
  };
  return (
    <>
      <ReminderButton
        reminder={reminder}
        handleClick={handleReminderToggle}
        isReminderOpen={isReminderOpen}
      />
      {isReminderOpen && (
        <DatePicker
          date={newReminder}
          minDate={subDays(new Date(), -1)}
          onSubmit={handleReminderSubmit}
          onChange={(val) => handleReminderChange(val)}
          onClose={handleReminderToggle}
          disabled={isSameDay(new Date(newReminder), new Date())}
          onDelete={handleReminderDelete}
          showTrash={!!reminder}
        />
      )}
    </>
  );
};

export default RecoReminder;
