const getRandomCharFromSet = (charSet: string): string => {
  const randomIndex = Math.floor(Math.random() * charSet.length);
  return charSet.charAt(randomIndex);
};

const shuffleString = (str: string): string => {
  const array = str.split('');
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array.join('');
};

export const generateStrongPassword = (length: number): string => {
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const numericChars = '0123456789';
  const specialChars = '!@#$%^&*()-_=+[{]}\\|;:\'",<.>/?';

  let password = '';

  // Generate at least one character from each character set
  password += getRandomCharFromSet(uppercaseChars);
  password += getRandomCharFromSet(lowercaseChars);
  password += getRandomCharFromSet(numericChars);
  password += getRandomCharFromSet(specialChars);

  // Generate the remaining characters randomly
  const remainingLength = length - 4;
  for (let i = 0; i < remainingLength; i++) {
    const charSet =
      uppercaseChars + lowercaseChars + numericChars + specialChars;
    password += getRandomCharFromSet(charSet);
  }

  // Shuffle the password characters
  password = shuffleString(password);

  return password;
};
