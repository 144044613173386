import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { ProfileForm } from 'modules/profile/components';

import { STEP_LABELS } from 'modules/onBoardingWizard/constants';

interface OnBoardingWizardPersonalInfoProps {
  handleStepChange: (step: number) => void;
}

const OnBoardingWizardPersonalInfo = ({
  handleStepChange,
}: OnBoardingWizardPersonalInfoProps) => {
  const { t } = useTranslation(['onBoardingWizard']);

  const handleNextStep = () => {
    handleStepChange(STEP_LABELS.dataSecurity.step);
  };

  return (
    <div className="t-mt-8" data-testid="OnBoardingWizardPersonalInfo">
      <h1 className="typo-alpha">
        {t('onBoardingWizard:personal_info_page_title')}
      </h1>
      <p className="typo-gamma t-mt-2.5 t-mb-5">
        {t('onBoardingWizard:personal_info_page_desc')}
      </p>

      <ProfileForm
        isProfilePage={false}
        onClose={handleNextStep}
        onSuccess={handleNextStep}
        isOnBoarding={true}
      />
    </div>
  );
};

export default OnBoardingWizardPersonalInfo;
