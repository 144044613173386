import React, { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';

import { ReactComponent as Cross } from 'images/cross.svg';

interface BootstrapModalProps {
  children: ReactNode | ReactNode[];
  onHide?: () => void;
  show: boolean;
  withBackdrop?: boolean;
  className?: string;
  hasCloseCross?: boolean;
  innerCardClassName?: string;
  dataTestid?: string;
  onFullScreen?: boolean;
  noPadding?: boolean;
}

export default function BootstrapModal({
  children,
  onHide,
  show = true,
  className = 'small',
  hasCloseCross,
  withBackdrop = false,
  innerCardClassName = '',
  dataTestid,
  onFullScreen = false,
  noPadding,
}: BootstrapModalProps) {
  const backdrop = withBackdrop ? 'static' : true;

  return (
    <Modal
      data-testid={dataTestid}
      show={show}
      onHide={onHide}
      className={className}
      backdrop={backdrop}
    >
      <section
        className={`t-whitespace-pre-line ${
          !onFullScreen ? 'card' : ''
        } ${innerCardClassName}`}
        data-testid="inner-modal"
      >
        {hasCloseCross && onHide && (
          <button
            onClick={onHide}
            className="t-float-right t-mt-6 t-mr-6"
            type="button"
            data-testid="cross-button"
          >
            <Cross />
          </button>
        )}
        <div
          className={
            onFullScreen || noPadding ? '' : 't-px-6 sm:t-px-10 t-pt-10 t-pb-8'
          }
        >
          {children}
        </div>
      </section>
    </Modal>
  );
}
