import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { camelCase } from 'lodash';

import { RootState } from 'store/reducers';
import { getNextPlanStep, getPlanStepByPath } from 'modules/estatePlan/helpers';
import { useViewport } from 'modules/common/helpers/viewportHelper';
import { assetTypeHelpers } from 'modules/assetType/helpers';
import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import {
  EstatePlanNavigationColumn,
  EstatePlanPageSwitch,
  EstateExportButton,
  SearchResultPage,
} from 'modules/estatePlan/components';

import { Tutorial } from 'modules/common/components';
import { getVaultTourSteps } from 'modules/estatePlan/constants';
import {
  PLAN_STEPS,
  PLAN_STEP_KEYS,
} from 'modules/estatePlan/constants/constants';
import { updateTutorial } from 'store/actions/userActions';

const scrollToRef = (ref) => window.scrollTo(0, ref.current?.offsetTop);

function EstatePlanPage() {
  const myRef = useRef(null);
  const { width } = useViewport();
  const desktopWidth = 1200;
  const isDesktop = width >= desktopWidth;
  const location = useLocation();
  const curPath = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['plan']);

  const currentStep = getPlanStepByPath(curPath);

  useEffect(() => {
    if (!isDesktop) scrollToRef(myRef);
  }, [curPath, isDesktop]);

  const planProgress: PlanProgressDTO = useSelector(
    (state: RootState) => state.plan.progress
  );

  const nextPlanStep = getNextPlanStep(currentStep.stepNo);

  const { searching, result } = useSelector(
    (state: RootState) => state.assetSearch
  );

  const userData: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const tutorial = userData?.tutorial || ({} as TutorialField);
  const [showVaultTour, setShowVaultTour] = React.useState(false);
  const tourSteps = getVaultTourSteps(t);
  const recommendations: RecommendationsDTO = useSelector(
    (state: RootState) => state.recommendations
  );
  const estatePlanRecos: RecommendationDTO[] =
    recommendations?.estatePlan || [];
  const assets: AssetsDTO = useSelector((state: RootState) => state.assets);
  const categoryAssets: AssetDTO[] =
    assets[camelCase(currentStep.dtoKey)] || [];

  // filter to get only recommendation for a particular asset category
  // if there is one or more assets that correspond to the recommendation, remove the recommendation
  const availableRecos: RecommendationDTO[] = estatePlanRecos.filter(
    (reco) =>
      reco.visible &&
      !assetTypeHelpers.checkIfCorrespondingAssetExists(reco, categoryAssets)
  );
  const categorizedRecos: RecommendationDTO[] = availableRecos.filter(
    (reco) => camelCase(reco.category) === currentStep.dtoKey
  );

  const [currentPath, setCurrentPath] = React.useState('');
  React.useEffect(() => {
    const currentPath = window.location.pathname.split('/')[2] || '';
    setCurrentPath(currentPath);
  }, []);

  const planSteps = Object.values(PLAN_STEP_KEYS);
  const currentPathContainRecos = planSteps.find(
    (step) =>
      !!availableRecos.find((reco) => reco.category === currentPath) &&
      step === camelCase(currentPath)
  );
  const stepContainRecos =
    currentPathContainRecos ||
    planSteps.find((step) =>
      availableRecos.find((reco) => camelCase(reco.category) === step)
    );

  const pathPlanStepContainRecos = !!stepContainRecos
    ? PLAN_STEPS[stepContainRecos]?.path
    : '';

  React.useLayoutEffect(() => {
    const shouldRenderVaultTour =
      !!userData?.initialAssessment &&
      !!tutorial.plan &&
      // if one of the categories has recommendations
      !!estatePlanRecos.length &&
      !userData.secondary;
    if (shouldRenderVaultTour) {
      setShowVaultTour(true);
    } else {
      setShowVaultTour(false);
    }
  }, [userData, categorizedRecos, currentStep]);

  const handleCloseTour = async () => {
    setShowVaultTour(false);
    await dispatch(updateTutorial({ ...tutorial, plan: false }));
  };

  // step when the action happens when the user click next button
  const extraTutorialInfo: TutorialAction[] = [
    {
      step: 0,
      action: () => {
        if (!!stepContainRecos) {
          navigate(pathPlanStepContainRecos);
        }
      },
    },
    // at the end of the tutorial, bring the user back to introduction page
    {
      step: tourSteps.length - 1,
      action: () => {
        if (!currentPathContainRecos) {
          navigate(PLAN_STEPS[`${planSteps[0]}`].path);
        }
      },
    },
  ];

  return isDesktop ? (
    <>
      {showVaultTour && (
        <Tutorial
          onClose={handleCloseTour}
          steps={tourSteps}
          extraInfo={extraTutorialInfo}
        />
      )}
      <Row>
        <Col xl={{ span: 10, offset: 2 }} className="t-pr-0 t-flex">
          <div className="t-bg-beta-50 t-p-12 t-w-5/12 t-rounded-l t-shadow">
            <EstatePlanNavigationColumn
              overallProgress={planProgress.overall}
            />
            <EstateExportButton />
          </div>
          <div className="t-bg-beta-100 t-p-12 t-w-8/12 t-rounded-r t-shadow">
            {!searching && (
              <EstatePlanPageSwitch
                nextPlanStep={nextPlanStep}
                categorizedRecos={categorizedRecos}
                isVaultTutorial={showVaultTour}
              />
            )}
            {searching && <SearchResultPage searchedResult={result} />}
          </div>
        </Col>
      </Row>
    </>
  ) : (
    <Row>
      <Col xs="12" className="t-px-0">
        <div className="t-bg-beta-50 t-rounded t-shadow-lg t-px-8 t-py-10 t-mb-7">
          <EstatePlanNavigationColumn
            overallProgress={planProgress.overall}
            searching={searching}
          />
          {!searching && <EstateExportButton />}
        </div>
      </Col>
      <Col xs="12" ref={myRef} className="t-px-0 t-mb-10">
        <div className="t-bg-beta-50 t-rounded t-shadow-lg t-px-8 t-py-10 t-mb-7 t-h-full">
          {!searching && (
            <EstatePlanPageSwitch
              nextPlanStep={nextPlanStep}
              categorizedRecos={categorizedRecos}
            />
          )}
          {searching && <SearchResultPage searchedResult={result} />}
        </div>
      </Col>
    </Row>
  );
}

export default EstatePlanPage;
