import React from 'react';
import { useDispatch } from 'react-redux';

import { generateConfirmationToken } from 'store/actions/userActions';

import {
  SecurityKeyEmailConfirmModal,
  KeyHolderSelectionModal,
  SecurityKeyForm,
  SetKeyHoldersModal,
} from 'modules/securityKeyWizard/components';
import { Modal } from 'modules/common/components';

import { SET_SECURITY_KEY_STEPS } from 'modules/securityKeyWizard/constants';

interface SetSecurityKeyProcessProps {
  handleProcessComplete: (noKeyHolder?: boolean) => void;
  onCancelSetKeysStep: () => void;
  keyHolders: KeyHolder[];
  chosenStep?: SET_SECURITY_KEY_STEPS;
}

const SetSecurityKeyProcess = ({
  handleProcessComplete,
  onCancelSetKeysStep,
  keyHolders,
  chosenStep,
}: SetSecurityKeyProcessProps) => {
  const dispatch = useDispatch();

  const [step, setStep] = React.useState(
    chosenStep || SET_SECURITY_KEY_STEPS.SECURITY_KEY_FORM
  );
  const [passphrase, setPassphrase] = React.useState('');

  const handleSavePassphrase = async ({
    passphrase,
  }: Record<string, string>) => {
    setPassphrase(passphrase);
    await dispatch(generateConfirmationToken());
    setStep(SET_SECURITY_KEY_STEPS.CONFIRMATION);
  };

  const handleContinue = () => {
    handleProcessComplete(!keyHolders.length);
  };

  const onContinueWithKeyHolders = (withKeyHolders: boolean) => {
    if (withKeyHolders) setStep(SET_SECURITY_KEY_STEPS.SET_KEY_HOLDERS);
    else handleProcessComplete(true);
  };

  const goToSelectionStep = () => {
    setStep(SET_SECURITY_KEY_STEPS.KEY_HOLDER_SELECTION);
  };

  const renderSetSecurityKeyComponent = () => {
    switch (step) {
      case SET_SECURITY_KEY_STEPS.CONFIRMATION:
        return (
          <SecurityKeyEmailConfirmModal
            passphrase={passphrase}
            onCancel={() => {
              setStep(SET_SECURITY_KEY_STEPS.SECURITY_KEY_FORM);
            }}
            goToSelectionStep={goToSelectionStep}
          />
        );
      case SET_SECURITY_KEY_STEPS.SET_KEY_HOLDERS:
        return (
          <SetKeyHoldersModal
            onSubmit={handleContinue}
            onBack={chosenStep ? handleProcessComplete : goToSelectionStep}
            keyHolders={keyHolders}
          />
        );
      case SET_SECURITY_KEY_STEPS.KEY_HOLDER_SELECTION:
        return (
          <KeyHolderSelectionModal onContinue={onContinueWithKeyHolders} />
        );
      default:
        return (
          <Modal show onHide={onCancelSetKeysStep}>
            <SecurityKeyForm
              onSubmit={handleSavePassphrase}
              onCancel={
                chosenStep ? handleProcessComplete : onCancelSetKeysStep
              }
              setting={true}
            />
          </Modal>
        );
    }
  };
  return renderSetSecurityKeyComponent();
};

export default SetSecurityKeyProcess;
