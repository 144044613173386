import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from 'store/reducers';
import { updateTutorial } from 'store/actions/userActions';
import { useTranslation, config } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { ContactUsForm, Faq } from 'modules/contactUs/components';
import { Button } from 'modules/common/components';

import { ReactComponent as BugIcon } from 'images/icon_bug.svg';
import facebook from 'images/facebook.svg';
import linkedin from 'images/linkedin.svg';
import instagram from 'images/instagram.svg';

function ContactUs() {
  const { t } = useTranslation(['auth', 'common', 'help']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user.current);

  const [redirecting, setRedirecting] = React.useState(false);

  const startTutorial = async () => {
    setRedirecting(true);
    await dispatch(
      updateTutorial({ ...(user?.tutorial || {}), dashboard: true })
    );
    navigate('/dashboard');
  };

  return (
    <>
      <Row className="t-h-100" data-testid="ContactUs">
        <Col xl={{ span: 6, offset: 2 }}>
          <div className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7">
            <div className="Contacts">
              <h1 className="Typography Typography--title u-weight-600 u-pb-10">
                {t('help:title')}
              </h1>
              <p className="Typography Typography--subTitle u-pb-40">
                {t('help:description')}
              </p>
              <div className="card t-pt-7 t-px-7 t-pb-5">
                <ContactUsForm />
              </div>
            </div>
            <Faq />
          </div>
        </Col>
        <Col xl={{ span: 4 }} className="sm:t-py-0">
          <div className="card md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-5">
            <h2 className="Typography Typography--title u-weight-600 u-pb-10">
              {t('help:touch_title')}
            </h2>
            <p className="Typography Typography--subTitle u-pb-40">
              {t('help:touch_description')}
            </p>
            <a
              href="mailto:info@ninebarc.com"
              className="Typography Typography--subTitle u-weight-600 u-noUnderline u-color-jelly-bean"
            >
              info@ninebarc.com
            </a>
            <p className="Typography Typography--subTitle u-weight-600 u-pt-20">
              {t('help:tel')}
            </p>
            <p className="Typography Typography--subTitle u-weight-600 u-pt-20">
              {t('help:adr1')}
            </p>
            <p className="Typography Typography--subTitle u-weight-600">
              {t('help:adr2')}
            </p>
            <p className="Typography Typography--subTitle u-weight-600 u-pb-30">
              {t('help:adr3')}
            </p>
            <ul className="d-flex">
              <li className="u-mr-20">
                <a
                  href="https://www.facebook.com/Ninebarc/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-noUnderline"
                >
                  <img src={facebook} className="d-block" alt="facebook" />
                </a>
              </li>
              <li className="u-mr-20">
                <a
                  href="https://www.instagram.com/ninebarc/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-noUnderline"
                >
                  <img src={instagram} className="d-block" alt="instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/ninebarc"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-noUnderline"
                >
                  <img src={linkedin} className="d-block" alt="linkedin" />
                </a>
              </li>
            </ul>
          </div>
          {user?.regular && !user.secondary && (
            <div className="card md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7 t-hidden xl:t-inline-block">
              <h1 className="typo-alpha">
                {t('help:dashboard_tour_widget_title')}
              </h1>
              <p className="typo-gamma t-mt-3">
                {t('help:dashboard_tour_widget_description')}
              </p>
              <Button
                category="secondary"
                className="t-mt-3"
                onClick={startTutorial}
                loading={redirecting}
              >
                {t('help:dashboard_tour_widget_button')}
              </Button>
            </div>
          )}

          <div className="card md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7 t-hidden xl:t-inline-block">
            <h1 className="typo-alpha">
              {t('help:customer_service_portal_title')}
            </h1>
            <p className="typo-gamma t-mt-3">
              {t('help:customer_service_portal_description')}
            </p>
            <div className="t-flex">
              <a
                href={config.customerServicePortalUrl}
                className="button-secondary t-mt-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BugIcon className="t-text-alpha-600 t-mr-1" />
                {t('help:customer_service_portal_button')}
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ContactUs;
