import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { FormRegister } from 'modules/common/types';
import { Button, RadioCard } from 'modules/common/components';
import { PricesInfo } from 'modules/payment/components/Payment';

import { BILLING_OPTIONS } from 'modules/payment/constants';

const { LIFETIME, YEARLY } = BILLING_OPTIONS;

export interface StepBillingProps {
  register: FormRegister;
  handleNextStepBilling: () => void;
  billingOption: string;
  pricesInfo: PricesInfo;
  agentName?: string;
  loading?: boolean;
}

export default function StepBilling({
  register,
  handleNextStepBilling,
  billingOption = '',
  pricesInfo,
  agentName,
  loading,
}: StepBillingProps) {
  const { t } = useTranslation(['payment']);

  const {
    originalYearlyPrice,
    yearlyPrice,
    yearlyDiscount,
    lifetimeDiscount,
    originalLifetimePrice,
    formattedLifetimePrice,
  } = pricesInfo;

  const customizedTitle =
    lifetimeDiscount && agentName
      ? 'payment:agent_exclusive_step_billing_title'
      : lifetimeDiscount
      ? 'payment:exclusive_step_billing_title'
      : '';

  return (
    <div data-testid="StepBilling">
      <h2 className="typo-alpha t-mb-4">
        {t(customizedTitle || 'payment:step_billing_title', { agentName })}
      </h2>
      <h3 className="typo-gamma t-mb-5">
        {t('payment:step_billing_selection_subtitle')}
      </h3>

      <RadioCard
        register={register}
        name="billing"
        value={YEARLY}
        id={YEARLY}
        className="t-mb-5"
        withBorder={billingOption === YEARLY}
      >
        <div className="t-flex t-flex-col-reverse sm:t-flex-row sm:t-justify-between md:t-flex-col-reverse lg:t-flex-row t-items-start">
          <div>
            <p className="typo-beta t-mb-1">
              {t(`payment:step_billing_${YEARLY}_title`)}
            </p>
            <p className="typo-delta t-mt-3">
              {t(`payment:step_billing_${YEARLY}_price`, {
                price: yearlyPrice,
              })}
              {yearlyDiscount > 0 && (
                <span className="typo-delta t-text-beta-500 t-inline sm:t-hidden md:t-inline lg:t-hidden">
                  {' '}
                  (
                  {t(`payment:step_billing_${YEARLY}_original_price`, {
                    price: originalYearlyPrice,
                  })}
                  )
                </span>
              )}
            </p>
          </div>
          {yearlyDiscount > 0 && (
            <div>
              <p className="typo-eta t-bg-delta-100 t-text-delta-500 t-py-1 t-mb-2 t-px-2 t-rounded-sm">
                {t(`payment:step_billing_${YEARLY}_discount`, {
                  discount: yearlyDiscount,
                })}
              </p>
              <div className="t-hidden sm:t-inline md:t-hidden lg:t-inline">
                <p className="typo-delta t-text-beta-500 t-text-center">
                  {t(`payment:step_billing_${YEARLY}_original_price`, {
                    price: originalYearlyPrice,
                  })}
                </p>
              </div>
            </div>
          )}
        </div>
      </RadioCard>

      <RadioCard
        register={register}
        name="billing"
        value={LIFETIME}
        id={LIFETIME}
        withBorder={billingOption === LIFETIME}
      >
        <div className="t-flex t-flex-col-reverse sm:t-flex-row sm:t-justify-between md:t-flex-col-reverse lg:t-flex-row t-items-start">
          <div>
            <p className="typo-beta t-mb-1">
              {t(`payment:step_billing_${LIFETIME}_title`)}
            </p>
            {lifetimeDiscount > 0 && (
              <p className="typo-delta">
                {t(`payment:step_billing_${LIFETIME}_price`, {
                  price: formattedLifetimePrice,
                })}

                <span className="typo-delta t-text-beta-500 t-inline sm:t-hidden md:t-inline lg:t-hidden">
                  {' '}
                  (
                  {t(`payment:step_billing_${LIFETIME}_original_price`, {
                    price: originalLifetimePrice,
                  })}
                  )
                </span>
              </p>
            )}
          </div>
          {lifetimeDiscount > 0 ? (
            <div>
              <p className="typo-eta t-bg-delta-100 t-text-delta-500 t-py-1 t-mb-1 t-px-2 t-rounded-sm">
                {t(`payment:step_billing_${LIFETIME}_discount`, {
                  discount: lifetimeDiscount,
                })}
              </p>
              <div className="t-hidden sm:t-inline md:t-hidden lg:t-inline">
                <p className="typo-delta t-text-beta-500 t-text-center">
                  {t(`payment:step_billing_${LIFETIME}_original_price`, {
                    price: originalLifetimePrice,
                  })}
                </p>
              </div>
            </div>
          ) : (
            <p className="typo-beta">
              {t(`payment:step_billing_${LIFETIME}_price`, {
                price: originalLifetimePrice,
              })}
            </p>
          )}
        </div>
        <p className="typo-delta t-mt-1.5">
          {t(`payment:step_billing_${LIFETIME}_description`)}
        </p>
      </RadioCard>

      {billingOption && (
        <>
          <p className="typo-gamma t-mt-10 t-mb-5">
            {t('payment:step_billing_order_details')}
          </p>
          <div className="card t-p-4 t-pl-7 t-flex t-justify-between">
            <div>
              <p className="typo-beta t-mb-1">
                {t(`payment:step_billing_${billingOption}_title`)}
              </p>
              <p className="typo-delta">{t('payment:step_billing_vat')}</p>
            </div>
            <div>
              <p className="typo-beta t-mb-1 t-text-right">
                {t(`payment:step_billing_total`, {
                  price:
                    billingOption === LIFETIME
                      ? formattedLifetimePrice
                      : yearlyPrice,
                })}
              </p>
              <p className="typo-zeta t-text-right">
                {t(`payment:step_billing_${billingOption}_total_description`)}
              </p>
            </div>
          </div>
        </>
      )}

      <Button
        onClick={handleNextStepBilling}
        disabled={!billingOption}
        loading={loading}
        className="t-mt-5 t-float-right"
      >
        {t(
          billingOption === LIFETIME
            ? 'payment:step_method_pay_and_proceed'
            : 'payment:step_billing_continue_button'
        )}
      </Button>
    </div>
  );
}
