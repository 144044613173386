import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { CLIENT_CUSTOMIZATIONS, CLIENT_KEYS } from 'modules/common/constants';

import { ReactComponent as IconCheck } from 'images/icon_check.svg';

interface SignUpHeaderProps {
  customization: ClientCustomization;
  client?: string;
  fullName?: string;
  customFont?: string;
}

export default function SignUpHeader({
  customization,
  client,
  fullName = '',
  customFont = '',
}: SignUpHeaderProps) {
  const { t } = useTranslation(['common', 'auth']);

  const {
    labelClassName = '',
    customizedHeaderTitle,
    customizedHeaderDescription,
    exclusiveOffersText,
    offerSmallText,
    customHeaderTextStyles,
    customOfferTextStyles,
  } = customization || {};

  const defaultOffersText =
    CLIENT_CUSTOMIZATIONS[CLIENT_KEYS.DEFAULT].signup!.exclusiveOffersText;

  const offersText = exclusiveOffersText || defaultOffersText;
  const headerTextStyles = customHeaderTextStyles
    ? `${client}-header-text`
    : '';
  const headerOfferTextStyles = customOfferTextStyles
    ? `${client}-header-offer-text`
    : '';

  return (
    <>
      <h1
        className={`typo-alpha t-pb-2 ${labelClassName} ${headerTextStyles} ${customFont}`}
      >
        {t(
          `auth:${
            customizedHeaderTitle ? client : CLIENT_KEYS.DEFAULT
          }_signup_title`,
          { fullName }
        )}
      </h1>

      <p className={`typo-gamma ${headerTextStyles} ${customFont}`}>
        {t(
          `auth:${
            customizedHeaderDescription ? client : CLIENT_KEYS.DEFAULT
          }_signup_description`
        )}
      </p>

      <div className="t-mt-6">
        {offersText?.map((offer) => (
          <div className="t-flex t-mt-1" key={offer}>
            <div className="t-pt-1">
              <IconCheck
                className={`t-text-alpha-600 t-mr-2 ${headerOfferTextStyles}`}
              />
            </div>

            <p
              className={`typo-delta t-font-semibold t-text-alpha-600 ${headerOfferTextStyles} ${customFont}`}
            >
              {t(offer)}
            </p>
          </div>
        ))}
      </div>

      {offerSmallText && (
        <p
          className={`typo-theta t-text-alpha-600 t-font-semibold t-ml-6 t-mt-2 ${headerOfferTextStyles} ${customFont}`}
        >
          {t(`auth:${client}_signup_small_text`)}
        </p>
      )}
    </>
  );
}
