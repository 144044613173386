import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export interface SliderProps {
  onAfterChange?: (any) => void;
  onChange?: (any) => void;
  value: number;
  marks: Record<
    number,
    | React.ReactNode
    | {
        style?: React.CSSProperties | undefined;
        label?: string | undefined;
      }
  >;
  step: number;
}

const DistributionSlider = ({
  onAfterChange,
  onChange,
  value,
  marks,
  step,
}: SliderProps) => {
  return (
    <Slider
      step={step}
      onChange={onChange}
      onAfterChange={onAfterChange}
      value={value}
      className="Slider"
      marks={marks}
      handleStyle={{
        height: 32,
        width: 32,
        background: 'var(--color-alpha-600)',
        border: `3px solid var(--color-alpha-600)`,
        boxShadow: ' 0px 4px 8px rgba(var(--color-beta-700), 0.2)',
        top: '-4px',
      }}
      railStyle={{
        height: '16px',
        borderRadius: '4px',
        width: '100%',
        border: `1px solid var(--color-alpha-150)`,
        backgroundColor: 'var(--color-gamma-400)',
        top: '0',
      }}
      trackStyle={{
        height: '16px',
        borderRadius: '4px',
        width: '100%',
        border: `1px solid var(--color-alpha-600)`,
        backgroundColor: 'var(--color-alpha-600)',
        top: '0',
      }}
    />
  );
};

export default DistributionSlider;
