export enum MODALS {
  COMMON_MODAL = 'commonModal',
  LOGOUT_WARNING_MODAL = 'logoutWarningModal',
  SECURITY_KEY_MODAL = 'securityKeyModal',
  ASSET_VIEW_MODAL = 'assetViewModal',
  NOTIFICATION_PANEL = 'notificationPanel',
  PAYMENT_MODAL = 'paymentModal',
  PAYMENT_SUCCESSFUL = 'paymentSuccessful',
  PASSPHRASE = 'passphrase',
  SUB_ACCOUNT_SELECTION = 'subAccountSelection',
  ACCOUNT_SWITCHING_INFO = 'accountSwitchingInfo',
  FAMILY_ACCOUNT_LEARN_MORE = 'familyAccountLearnMore',
  ADD_FAMILY_ACCOUNT_PROCESS = 'addFamilyAccountProcess',
}
