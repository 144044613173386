import authService from 'modules/auth/services/authService';
import helper from 'store/actions/actionHelper';

import { EMAIL } from 'modules/common/constants/generic';

export const updateAuthTokens = (accessToken: string, refreshToken: string) => {
  authService.saveToken(accessToken);
  authService.saveRefreshToken(refreshToken);
};

export const refreshToken = (
  refreshToken: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (): Promise<SuccessAuthResponse> => {
    const response = await authService.refreshToken(refreshToken);

    if (response?.accessToken) {
      updateAuthTokens(response.accessToken, response.refreshToken);
    }

    return response;
  }, actionOptions);
};

export const loginUsingRefreshToken = (
  refreshToken: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const email = localStorage.getItem(EMAIL);
    if (!email || !refreshToken) return false;
    const response = await authService.loginUsingRefreshToken(
      refreshToken,
      email
    );

    if (response?.accessToken) {
      updateAuthTokens(response.accessToken, response.refreshToken);
    }

    // remove email from local storage
    localStorage.removeItem(EMAIL);

    return response;
  }, actionOptions);
};

export const verifyAndLogin = (
  token: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    if (!token) return false;
    const response = await authService.verifyAndLogin(token);

    if (response?.accessToken && response.accessToken) {
      updateAuthTokens(response.accessToken, response.refreshToken);
    }

    return response;
  }, actionOptions);
};
