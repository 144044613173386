import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { RootState } from 'store/reducers';
import {
  navigateToStep,
  populateQuestionnaire,
} from 'store/actions/questionnaireActions';
import { dataProcessingHelper } from 'modules/assistant/helpers';
import { useTranslation } from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import {
  AssistantSkeleton,
  RecoCard,
  RecoTotalCard,
  CompletedRecoCard,
  EndScreenView,
} from 'modules/assistant/components/Assistant';
import { Button } from 'modules/common/components';

import { RECO_TYPE } from 'modules/assistant/constants/questionnaires';
import {
  QUESTIONNAIRE_PAGES,
  QUESTIONNAIRE_KEYS,
} from 'modules/assistant/constants/questionnaires';
import { STEP_KEYS as IA_STEP_KEYS } from 'modules/assistant/constants/questionnaires/initialAssessment';

import { ReactComponent as IconChevroneDown } from 'images/v-shaped-down.svg';
import { ReactComponent as IconChevroneUp } from 'images/v-shaped-up.svg';
import imgCompletedDocument from 'images/img-completed-document.png';

const ITEMS_TO_SHOW_WHEN_COLLAPSED = 3;
const { getFilteredRecosArray, isRecoCompleted, getRecosWithExtraOptions } =
  dataProcessingHelper;

const Recommendations = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['recommendations']);
  const navigate = useNavigate();

  const recommendations: RecommendationsDTO = useSelector(
    (state: RootState) => state.recommendations
  );
  const planProgress: PlanProgressDTO = useSelector(
    (state: RootState) => state.plan.progress
  );
  const [isCompletedRecosView, setIsCompletedRecosView] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    // we need to load Initial Assessment to the store because we reset it's currentStep in handleFooterClick() method
    dispatch(populateQuestionnaire(QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT));
  }, []);

  const estateRecosArr = getRecosWithExtraOptions(
    recommendations?.manageEstate
  ).filter((reco) => reco.priority !== 0);
  const careRecosArr = getRecosWithExtraOptions(
    recommendations?.precautionCare
  ).filter((reco) => reco.priority !== 0);

  const estateRecosTotal = estateRecosArr.filter((rec) => rec.isAvailable);
  const careRecosTotal = careRecosArr.filter((rec) => rec.isAvailable);

  const isNoCompletedReco = !getFilteredRecosArray(
    [...estateRecosArr, ...careRecosArr],
    true,
    planProgress
  )?.length;

  const handleFooterClick = () => {
    dispatch(
      navigateToStep(
        QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT,
        IA_STEP_KEYS.STEP_START
      )
    );
    navigate(QUESTIONNAIRE_PAGES[QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT].path);
  };

  const toggleViewCompletedRecos = () =>
    setIsCompletedRecosView((prevState) => !prevState);
  const filteredEstateRecos = getFilteredRecosArray(
    estateRecosArr,
    isCompletedRecosView,
    planProgress
  );
  const filteredCareRecos = getFilteredRecosArray(
    careRecosArr,
    isCompletedRecosView,
    planProgress
  );
  const isEndScreenView =
    !isCompletedRecosView &&
    !filteredEstateRecos.length &&
    !filteredCareRecos.length;

  const isPossibleToShowMore =
    filteredEstateRecos.length > ITEMS_TO_SHOW_WHEN_COLLAPSED ||
    filteredCareRecos.length > ITEMS_TO_SHOW_WHEN_COLLAPSED;
  const shownEstateRecos =
    showMore && isPossibleToShowMore
      ? filteredEstateRecos
      : filteredEstateRecos.slice(0, ITEMS_TO_SHOW_WHEN_COLLAPSED);
  const shownCareRecos =
    showMore && isPossibleToShowMore
      ? filteredCareRecos
      : filteredCareRecos.slice(0, ITEMS_TO_SHOW_WHEN_COLLAPSED);

  const toggleShowMore = () => {
    setShowMore((prevState) => !prevState);
  };

  return (
    <>
      {!isCompletedRecosView && (
        <Row className="t-mb-5">
          <Col
            md={{ span: 12 }}
            xl={{ span: 10, offset: 2 }}
            style={{ paddingRight: 0 }}
          >
            <div className="t-bg-gamma-400 t-rounded t-shadow-lg t-py-8 t-pr-8 t-pl-6 sm:t-pl-8">
              <h1 className="title t-font-semibold t-mb-3">
                {t('recommendations:title')}
              </h1>
              <p className="subtitle t-mb-3">
                {t('recommendations:description')}
              </p>
              <Row xs={1} xl={2}>
                <Col>
                  <RecoTotalCard recos={careRecosTotal} type={RECO_TYPE.CARE} />
                </Col>
                <Col>
                  <RecoTotalCard
                    recos={estateRecosTotal}
                    type={RECO_TYPE.ESTATE}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col
          md={{ span: 12 }}
          xl={{ span: 10, offset: 2 }}
          style={{ paddingRight: 0 }}
        >
          <div className="t-bg-gamma-400 t-rounded t-shadow-lg t-py-8 t-pr-8 t-pl-6 sm:t-pl-8">
            {isCompletedRecosView && (
              <Button
                category="secondary"
                className="t-mb-4"
                onClick={toggleViewCompletedRecos}
              >
                {t('common:back')}
              </Button>
            )}

            {isEmpty(recommendations) ? (
              <AssistantSkeleton />
            ) : isEndScreenView ? (
              <EndScreenView
                openCompletedRecosView={toggleViewCompletedRecos}
              />
            ) : (
              <Row xs={1} xl={2}>
                <Col style={{ marginBottom: 20 }}>
                  <div className="t-flex t-items-center t-mt-5 xl:t-mt-0">
                    <div className="t-px-0.5 t-h-7 t-bg-alpha-600 t-mr-2.5" />
                    <h1 className="typo-alpha">
                      {t('recommendations:totals_prevention_header')}
                    </h1>
                  </div>
                  {shownCareRecos.map((item) =>
                    !!item.relatedAssetId ||
                    isRecoCompleted(item, planProgress) ? (
                      <CompletedRecoCard
                        card={item}
                        key={item.name}
                        type={RECO_TYPE.CARE}
                      />
                    ) : (
                      <RecoCard
                        card={item}
                        type={RECO_TYPE.CARE}
                        key={item.name}
                      />
                    )
                  )}
                </Col>
                <Col style={{ marginBottom: 20 }}>
                  <div className="t-flex t-items-center">
                    <div className="t-px-0.5 t-h-7 t-bg-beta-700 t-mr-2.5" />
                    <h1 className="typo-alpha">
                      {t('recommendations:totals_estate_header')}
                    </h1>
                  </div>
                  {shownEstateRecos.map((item) =>
                    !!item.relatedAssetId ||
                    isRecoCompleted(item, planProgress) ? (
                      <CompletedRecoCard
                        key={item.name}
                        card={item}
                        type={RECO_TYPE.ESTATE}
                      />
                    ) : (
                      <RecoCard
                        key={item.name}
                        card={item}
                        type={RECO_TYPE.ESTATE}
                      />
                    )
                  )}
                </Col>
              </Row>
            )}
            {isPossibleToShowMore && (
              <div className="t-flex t-justify-center">
                <button
                  onClick={toggleShowMore}
                  className="typo-beta t-text-delta-700 t-mx-auto t-outline-none t-p-4 t-flex t-items-center"
                >
                  {showMore ? t('show_less') : t('show_more')}
                  {showMore ? (
                    <IconChevroneUp className="t-ml-2" />
                  ) : (
                    <IconChevroneDown className="t-ml-2" />
                  )}
                </button>
              </div>
            )}
          </div>
        </Col>
      </Row>

      {!isCompletedRecosView && !isEndScreenView && !isNoCompletedReco && (
        <Row>
          <Col
            md={{ span: 12 }}
            xl={{ span: 10, offset: 2 }}
            style={{ paddingRight: 0 }}
          >
            <div className="card t-overflow-hidden t-mt-5">
              <div className="t-flex t-items-center t-bg-delta-900">
                <div className="t-hidden sm:t-flex sm:t-items-start xl:t-w-44 t-pt-2.5 ">
                  <img
                    src={imgCompletedDocument}
                    className="t-h-full"
                    alt="Go to your completed recommendations"
                  />
                </div>
                <div className="t-p-3 xl:t-mr-6 t-flex t-flex-grow t-justify-center t-items-center t-flex-col xl:t-flex-row xl:t-items-center xl:t-justify-between">
                  <p className="typo-beta t-text-gamma-400 t-mb-2 xl:t-mb-0 t-text-center sm:t-text-left">
                    {t('recommendations:show_completed_recos_cta_title')}
                  </p>
                  <button
                    className="t-py-2.5 t-px-5 t-bg-gamma-400 t-rounded t-text-delta-900 t-uppercase"
                    onClick={toggleViewCompletedRecos}
                  >
                    {t('recommendations:show_completed_tasks_btn')}
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col
          md={{ span: 12 }}
          xl={{ span: 10, offset: 2 }}
          style={{ paddingRight: 0, marginBottom: 20 }}
        >
          <div className="card t-mt-5 t-px-2">
            <div className="t-flex t-justify-center t-py-4 t-flex-col t-items-center sm:t-flex-row">
              <span className="t-text-lg t-leading-md t-mr-2.5 t-text-beta-700">
                {t('footer_annotation')}
              </span>
              <span
                onClick={handleFooterClick}
                onKeyDown={handleFooterClick}
                role="button"
                tabIndex={0}
                className="t-text-lg t-leading-md t-font-semibold t-text-delta-900"
              >
                {`${t('footer_annotation_link')} >`}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Recommendations;
