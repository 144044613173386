import { useTranslation } from 'modules/common/helpers';

import { PAGES } from 'modules/estatePlan/constants/enums';

const useGetAnswers = (page: PAGES) => {
  const { t } = useTranslation(['answers']);

  const result = t(page, { returnObjects: true }) as any as AccordionAnswer[];

  if (Array.isArray(result)) return result;

  return [];
};

export default useGetAnswers;
