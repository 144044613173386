import React from 'react';
import { useDispatch } from 'react-redux';

import { createFamilyMember } from 'store/actions/familyAccountActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { reactToast, useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

import { REDIRECT_ACTIONS } from 'modules/assistant/constants/questionnaires/questionnairesToAssetTypes';

import imgAddMember from 'images/img-add-family-member.png';

interface AddContactAsFamilyMemberConfirmModalProps {
  contact: ContactDTO;
  onClose: () => void;
  switchToMyFamilyTab?: () => void;
}

const AddContactAsFamilyMemberConfirmModal = ({
  contact,
  onClose,
  switchToMyFamilyTab,
}: AddContactAsFamilyMemberConfirmModalProps) => {
  const { t } = useTranslation(['plan']);
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);

  const handleAddFamilyMember = async () => {
    setLoading(true);
    const response: any = await dispatch(createFamilyMember(contact.id));
    if (!!response) {
      reactToast.showMessage(
        t('familyAccounts:family_account_added_successfully')
      );
    }
    setLoading(false);
    if (switchToMyFamilyTab && response?.secondaryUserId) {
      await dispatch(
        temporaryFieldSaver({
          actionType: REDIRECT_ACTIONS.GO_TO_FAMILY_ACCOUNT_CARD,
          familyAccountId: response.secondaryUserId,
        })
      );
      switchToMyFamilyTab();
    }
    onClose();
  };

  return (
    <Modal show onHide={onClose}>
      <div className="t-flex t-justify-center">
        <img
          src={imgAddMember}
          className="t-max-h-36"
          alt="Add as a family member"
        />
      </div>

      <h1 className="typo-alpha t-mt-6">
        {t('familyAccounts:add_as_family_account_modal_title')}
      </h1>
      <p className="typo-epsilon t-mt-4 t-mb-8">
        {t('familyAccounts:add_as_family_account_modal_desc')}
      </p>

      <div className="t-flex t-justify-end t-items-end t-mt-8 t-flex-col sm:t-flex-row">
        <Button
          category="secondary"
          className="t-mr-0 t-py-2 t-mb-3 sm:t-mb-0 sm:t-mr-3"
          onClick={onClose}
          disabled={loading}
        >
          {t('common:cancel')}
        </Button>

        <Button
          className="t-py-2"
          onClick={handleAddFamilyMember}
          loading={loading}
        >
          {t('familyAccounts:add_family_account_confirm_btn')}
        </Button>
      </div>
    </Modal>
  );
};

export default AddContactAsFamilyMemberConfirmModal;
