import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';
import { CONTENT_QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/contentQuestionnaires';

// three call-to-actions: upload, create and viewDetail
export const QUESTIONNAIRES_CTAS = {
  [QUESTIONNAIRE_KEYS.PATIENT_DECREE]: {
    upload: true,
    create: true,
    viewDetail: true,
  },
  [QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD]: {
    upload: true,
    create: true,
    viewDetail: true,
  },
  [QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY]: {
    upload: true,
    create: true,
    viewDetail: true,
  },
  [QUESTIONNAIRE_KEYS.WILL_GENERATOR]: {
    upload: true,
    create: true,
    viewDetail: true,
  },
  [QUESTIONNAIRE_KEYS.PET_WATCHING_AGREEMENT]: {
    upload: true,
    create: true,
    viewDetail: false,
  },
  [QUESTIONNAIRE_KEYS.CUSTODY_ORDER]: {
    upload: true,
    create: true,
    viewDetail: true,
  },
  [QUESTIONNAIRE_KEYS.FUNERAL_PROVISION]: {
    upload: true,
    create: true,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.EMERGENCY_PASS]: {
    upload: true,
    create: false,
    viewDetail: true,
  },
  [QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER]: {
    upload: true,
    create: true,
    viewDetail: true,
  },
  // No asset type - all asset types in digital estate
  [CONTENT_QUESTIONNAIRE_KEYS.DIGITAL_ESTATE]: {
    upload: true,
    create: false,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.POSTAL_POWER_OF_ATTORNEY]: {
    upload: true,
    create: false,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CARE_OCCUPATIONAL_DISABILITY]: {
    upload: true,
    create: false,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_CARE]: {
    upload: true,
    create: false,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CARE_DIRECTIVE]: {
    upload: true,
    create: false,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.VEHICLE_DOCUMENTS]: {
    upload: true,
    create: false,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.PURCHASE_LOANS_RIGHTS]: {
    upload: true,
    create: false,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.LANDLORD_RENTAL_AGREEMENT]: {
    upload: true,
    create: false,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CUSTODY_ORDER]: {
    upload: true,
    create: false,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.INHERITANCE_TAX]: {
    upload: false,
    create: false,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_AGED]: {
    upload: false,
    create: false,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.EMERGENCY_PASS_KIDS]: {
    upload: true,
    create: false,
    viewDetail: true,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CARE]: {
    upload: true,
    create: false,
    viewDetail: true,
  },
};
