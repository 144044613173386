import React from 'react';
import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';

import { config } from 'modules/common/helpers';

interface MatomoWrapperProps {
  siteId: number;
  children: React.ReactNode | React.ReactNode[];
}

function MatomoWrapper({ siteId, children }: MatomoWrapperProps) {
  // Create Matomo instance
  const matomoInstance = createInstance({
    urlBase: config.matomoTrackerBaseUrl || '',
    siteId: siteId,
    trackerUrl: `${config.matomoTrackerBaseUrl || ''}/matomo.php`,
    srcUrl: `${config.matomoTrackerBaseUrl || ''}/matomo.js`,
    linkTracking: true,
    disabled: !config.matomoTrackerBaseUrl,
  });

  return <MatomoProvider value={matomoInstance}>{children}</MatomoProvider>;
}

export default MatomoWrapper;
