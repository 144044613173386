import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Button, WarningMessage } from 'modules/common/components';

interface ContactInfoWarningProps {
  contactData: ContactDTO;
  onEdit: (assetItem: ContactDTO) => void;
}

export default function ContactInfoWarning({
  contactData,
  onEdit,
}: ContactInfoWarningProps) {
  const { t } = useTranslation(['mcontacts']);

  if (!onEdit) return null;

  return (
    <div
      className="t-bg-eta-100 t-rounded-sm t-mt-3 t-mx-3 t-pt-3 t-pb-4 t-pr-3"
      data-testid="ContactInfoWarning"
    >
      <WarningMessage>
        <span>
          {!contactData.birthday ? t('mcontacts:asset_form_date_of_birth') : ''}
        </span>
        <span>
          {!contactData.birthday && !contactData.gender
            ? t('mcontacts:form_and_text')
            : ''}
        </span>
        <span>
          {!contactData.gender ? t('mcontacts:asset_form_gender') : ''}
        </span>
        <span>{t('mcontacts:asset_form_is_missing')}</span>
        <span>{t('mcontacts:contact_card_without_word')}</span>
        <span>
          {!contactData.birthday ? t('mcontacts:asset_form_date_of_birth') : ''}
        </span>
        <span>
          {!contactData.birthday && !contactData.gender
            ? t('mcontacts:form_and_text')
            : ''}
        </span>
        <span>
          {!contactData.gender ? t('mcontacts:asset_form_gender') : ''}
        </span>
        <span>{t('mcontacts:contact_card_cannot_access_description')}</span>
      </WarningMessage>
      <Button className="t-ml-10 t-mt-2" onClick={() => onEdit(contactData)}>
        <span className="typo-eta t-text-gamma-400">
          {t('mcontacts:contact_card_update_info_button')}
        </span>
      </Button>
    </div>
  );
}
