import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { find, isEmpty } from 'lodash';

import { RootState } from 'store/reducers';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { getFullName, useTranslation } from 'modules/common/helpers';
import {
  getAliveContactsData,
  getSelectOptions,
} from 'modules/contact/helpers';

import { ContactForm } from 'modules/contact/components';
import { StyledOr } from 'modules/elements/components';
import { Button, Modal } from 'modules/common/components';
import { ContactCard } from 'modules/myContacts/components';

import { ReactComponent as IconPlus } from 'images/icon-plus.svg';

export interface ContactProps {
  previousAnswer?: string;
}

const Contact = ({ previousAnswer }: ContactProps) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();

  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedContact, setSelectedContact] = useState<SelectOption>(
    {} as SelectOption
  );
  const [selectOption, setSelectOption] = useState<SelectOption | undefined>();

  const contactsData: ContactDTO[] =
    useSelector((state: RootState) => state.contacts.list) || [];
  const familyAccounts = useSelector(
    (state: RootState) => state.familyAccounts.list
  );
  const availableContacts = getAliveContactsData(contactsData, familyAccounts);

  const contactsOptions = getSelectOptions(availableContacts);
  const contactData: ContactDTO =
    find(contactsData, (item) => item.id === selectedContact.value) ||
    ({} as ContactDTO);
  const defaultValue: SelectOption | undefined = previousAnswer
    ? find(contactsOptions, (item) => item.value === previousAnswer)
    : undefined;

  // show previous input val, if entered before
  useEffect(() => {
    if (previousAnswer) {
      const doesPrevAnswerContactStillExist = !!contactsData?.find(
        (c) => c.id === previousAnswer
      );
      if (doesPrevAnswerContactStillExist)
        dispatch(temporaryFieldSaver(previousAnswer));
    }
  }, [previousAnswer]);

  useEffect(() => {
    setSelectOption(defaultValue);
  }, []);

  const handleCloseEditForm = () => {
    setShowEditForm(false);
  };

  const handleCreateNewContact = (newContact: ContactDTO) => {
    if (newContact?.id) {
      setSelectedContact({
        value: newContact.id,
        label: getFullName(newContact, false),
      });
      dispatch(temporaryFieldSaver(newContact.id));
    }
  };

  const handleChange = (selectedOption) => {
    const option = selectedOption as SelectOption;

    setSelectedContact(option);
    dispatch(temporaryFieldSaver(option?.value));
  };

  const handleRemove = () => {
    setSelectedContact({} as SelectOption);
    dispatch(temporaryFieldSaver(null));
    setSelectOption(undefined);
  };

  return (
    <>
      {showEditForm && (
        <Modal show onHide={handleCloseEditForm}>
          <ContactForm
            onClose={handleCloseEditForm}
            onSuccess={handleCreateNewContact}
            limited
          />
        </Modal>
      )}

      {isEmpty(selectedContact) && (
        <div className="card xs:t-w-full md:t-w-4/5 xs:t-p-4 md:t-p-7">
          <div className="Form-group">
            <label className="Form-label" htmlFor="gender">
              {t('common:existing_contact')}
            </label>

            <Select
              id="contact"
              name="contact"
              value={selectOption}
              onChange={handleChange}
              options={contactsOptions}
              className="Select"
              classNamePrefix="Select"
            />
          </div>

          <div className="d-flex flex-column align-items-center">
            <StyledOr>{t('common:or')}</StyledOr>

            <Button category="secondary" onClick={() => setShowEditForm(true)}>
              <div className="t-flex t-justify-around">
                <IconPlus className="t-text-alpha-600 t-mr-1.5" />
                <p>{t('common:add_new_contact')}</p>
              </div>
            </Button>
          </div>
        </div>
      )}

      {!isEmpty(selectedContact) && (
        <ContactCard
          contactData={contactData}
          onDelete={handleRemove}
          className="card xl:t-py-6 xl:t-px-7 xs:t-py-3 xs:t-px-3.5 t-min-w-80 xl:t-min-w-sm"
        />
      )}
    </>
  );
};

export default Contact;
