import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import { H1, P } from 'modules/elements/components';
import { RequestAdviceFeedbackModal } from 'modules/assistant/components/Answers/RequestAdvice';
import { Button } from 'modules/common/components';

import leaf from 'images/single_leaf.png';
import { useTranslation } from 'modules/common/helpers';

interface RequestAdviceProps {
  handleGoToPrevQuestion: () => void;
}

export default function RequestAdvice({
  handleGoToPrevQuestion,
}: RequestAdviceProps) {
  const { t } = useTranslation(['common', 'will']);
  const location = useLocation();
  const [feedbackModalVisibility, setFeedbackModalVisibility] =
    useState<boolean>(false);

  return (
    <>
      <Row style={{ marginTop: 30, marginBottom: 50 }}>
        <Col xs={12} md={4}>
          <img src={leaf} alt="Green leaf from ninebarc tree" />
        </Col>
        <Col xs={12} md={8}>
          <H1 mb="15px">{t('will:request_advice_title')}</H1>
          <P>{t('will:request_advice_description')}</P>
          <div className="t-mt-6 t-flex t-flex-wrap md:t-justify-start xs:t-justify-end">
            <Button
              category="secondary"
              className="t-self-end"
              onClick={handleGoToPrevQuestion}
            >
              {t('common:previous')}
            </Button>
            <Button
              className="t-ml-3 xs:t-mt-5"
              onClick={() => setFeedbackModalVisibility(true)}
            >
              {t('will:request_advice_button')}
            </Button>
          </div>
        </Col>
      </Row>
      {feedbackModalVisibility && (
        <RequestAdviceFeedbackModal
          onCancel={() => setFeedbackModalVisibility(false)}
          subject={location.pathname}
        />
      )}
    </>
  );
}
