import React from 'react';
import { useDispatch } from 'react-redux';

import { switchAccount } from 'store/actions/userActions';
import { getFullName, useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

import { ReactComponent as MainToSubImage } from 'images/switch-account-1.svg';
import { ReactComponent as SubToMainImage } from 'images/switch-account-2.svg';

interface AccountSwitchingInfoModalProps {
  data: { to: FamilyAccount };
  onClose: () => void;
  onSuccess?: () => void;
}

const AccountSwitchingInfoModal = ({
  data: { to },
  onClose,
  onSuccess,
}: AccountSwitchingInfoModalProps) => {
  const { t } = useTranslation(['familyAccounts']);
  const dispatch = useDispatch();

  const toAccountName = getFullName(to, false);

  const [loading, setLoading] = React.useState(false);

  const handleSwitch = async () => {
    setLoading(true);
    await dispatch(switchAccount(to.id));
    if (onSuccess) {
      onSuccess();
    } else {
      onClose();
    }
    onClose();
  };

  return (
    <Modal show>
      <div className="t-flex t-justify-center">
        {to.primary ? (
          <SubToMainImage className="t-max-h-36" />
        ) : (
          <MainToSubImage className="t-max-h-36" />
        )}
      </div>

      <h1 className="typo-alpha t-mt-5">
        {to.primary
          ? t('familyAccounts:switch_to_main_account_title')
          : t('familyAccounts:switch_to_sub_account_title', {
              name: toAccountName,
            })}
      </h1>

      <p className="typo-epsilon t-mt-5">
        {t('familyAccounts:switch_account_info_desc_1', {
          name: toAccountName,
        })}
      </p>

      <p className="typo-epsilon t-mt-3">
        {to.primary
          ? t('familyAccounts:switch_account_info_to_main_desc_2')
          : t('familyAccounts:switch_account_info_to_sub_desc_2')}
      </p>

      <div className="t-flex t-justify-end t-items-end t-mt-8 t-flex-col sm:t-flex-row">
        <Button
          category="secondary"
          className="t-mr-0 t-py-2 t-mb-3 sm:t-mb-0 sm:t-mr-3"
          onClick={onClose}
          disabled={loading}
        >
          {t('common:cancel')}
        </Button>

        <Button
          className="t-py-2 t-uppercase"
          loading={loading}
          onClick={handleSwitch}
        >
          {t('common:switch')}
        </Button>
      </div>
    </Modal>
  );
};

export default AccountSwitchingInfoModal;
