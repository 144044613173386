import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

interface CommonModalProps {
  title?: string;
  description?: string | string[] | React.ReactNode | React.ReactNode[];
  secondaryButtonText?: string;
  primaryButtonText?: string;
  hasCross?: boolean;
  withBackdrop?: boolean;
  onClose: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
  noCloseBtn?: boolean;
  noSuccessBtn?: boolean;
  disableClickOutside?: boolean;
}

export default function CommonModal({
  title,
  description,
  secondaryButtonText,
  primaryButtonText,
  hasCross,
  withBackdrop,
  onClose,
  onSuccess,
  noCloseBtn,
  noSuccessBtn,
  disableClickOutside,
}: CommonModalProps) {
  const { t } = useTranslation(['plan']);

  const [loading, setLoading] = React.useState(false);

  const handleSuccessAction = async () => {
    setLoading(true);
    await onSuccess();
  };

  const renderDescription = () => {
    if (!description) return;
    let descriptionArray;
    if (!Array.isArray(description)) {
      descriptionArray = [description];
    } else {
      descriptionArray = description;
    }

    return (
      <div data-testid="description-text">
        {descriptionArray.map((element) => (
          <React.Fragment key={element}>
            {React.isValidElement(element) ? (
              element
            ) : (
              <p className="typo-epsilon t-mt-4">{element}</p>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <Modal
      show
      onHide={disableClickOutside ? undefined : onClose}
      hasCloseCross={hasCross}
      withBackdrop={withBackdrop}
      dataTestid="CommonModal"
    >
      {title && (
        <h1 className="typo-alpha" data-testid="title-text">
          {title}
        </h1>
      )}
      {renderDescription()}

      <div className="t-flex t-justify-end t-items-end t-mt-8 t-flex-col sm:t-flex-row">
        {!noCloseBtn && (
          <div>
            <Button
              category="secondary"
              className={`t-mr-0 t-py-2 t-mb-3 sm:t-mb-0 ${
                !noCloseBtn ? 'sm:t-mr-3' : ''
              }`}
              onClick={onClose}
              disabled={loading}
              testId="secondary-button"
            >
              {secondaryButtonText || t('common:cancel')}
            </Button>
          </div>
        )}

        {!noSuccessBtn && (
          <div>
            <Button
              className="t-py-2"
              onClick={handleSuccessAction}
              loading={loading}
              testId="primary-button"
            >
              {primaryButtonText || t('common:submit')}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}
