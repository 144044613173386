import React from 'react';
import { useDispatch } from 'react-redux';

import { deleteLetter, sendLetter } from 'store/actions/planActions';
import { sendContactNotification } from 'store/actions/contactActions';

import {
  LOCAL_STORAGE_VARS,
  POSTAL_TYPES,
  URL_PARAMS,
  URL_SEARCH_CODES,
} from 'modules/common/constants/enums';

interface PostalServiceWrapperProps {
  searchParams: URLSearchParams;
  onRemovePostalParams?: () => void;
  type: POSTAL_TYPES;
  children: React.ReactNode | React.ReactNode[];
}

const PostalServiceWrapper = ({
  searchParams,
  onRemovePostalParams,
  type,
  children,
}: PostalServiceWrapperProps) => {
  const dispatch = useDispatch();

  // Postal service params
  const postalPaymentStatus = searchParams.get(URL_PARAMS.STATUS);
  const isFromSuccessfulPostalPayment =
    postalPaymentStatus === URL_SEARCH_CODES.POSTAL_PAYMENT_SUCCESSFUL;
  const isUnsuccessfulPostalPayment =
    postalPaymentStatus === URL_SEARCH_CODES.POSTAL_PAYMENT_CANCELED;
  const token = searchParams.get(URL_PARAMS.PAYMENT_TOKEN);
  const letterId = searchParams.get(URL_PARAMS.LETTER_ID) || '';
  const contactId = searchParams.get(URL_PARAMS.CONTACT_ID) || '';

  React.useEffect(() => {
    // send the paymentToken to the backend if the payment succeeded
    // the /contact/notify API will handle sending the document by post
    if (isFromSuccessfulPostalPayment) {
      if (type === POSTAL_TYPES.CONTACT) {
        handleNotifyContact();
      } else {
        handleSendLetter();
      }
    }

    // if the payment is canceled or unsuccessful, remove the letter
    if (isUnsuccessfulPostalPayment && !!letterId) {
      deleteLetterData();
    }
  }, [
    isFromSuccessfulPostalPayment,
    isUnsuccessfulPostalPayment,
    token,
    letterId,
    contactId,
  ]);

  const handleNotifyContact = async () => {
    const localData = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_VARS.DATA_ON_REDIRECT) || `{}`
    );

    if (token?.length === 40 && !!letterId && !!contactId) {
      await dispatch(
        sendContactNotification(
          contactId,
          letterId,
          token,
          localData?.contactBy
        )
      );
    }
    localStorage.removeItem(LOCAL_STORAGE_VARS.DATA_ON_REDIRECT);
  };

  const handleSendLetter = async () => {
    if (token?.length === 40 && !!letterId) {
      await dispatch(sendLetter(token, letterId));
    }
  };

  const deleteLetterData = async () => {
    await dispatch(deleteLetter(letterId));
    if (onRemovePostalParams) {
      onRemovePostalParams();
    }
  };

  return <React.Fragment>{children}</React.Fragment>;
};

export default PostalServiceWrapper;
