import { httpHelper, config } from 'modules/common/helpers';

const BASE_URL = config.beUrl;

export default {
  addWillFeedback,
  sendFeedback,
};

function addWillFeedback(feedback) {
  return httpHelper.post(`${BASE_URL}/api/email/will-advice-service`, {
    feedback,
  });
}

function sendFeedback(data: FeedbackDTO) {
  return httpHelper.post(`${BASE_URL}/api/email/feedback`, data);
}
