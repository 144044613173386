import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useParams } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import { RootState } from 'store/reducers';
import { getContactById } from 'store/actions/overviewContact';
import { useTranslation } from 'modules/common/helpers';

import { ContactAccordionItem } from 'modules/myContacts/components';
import { Col, Row } from 'modules/bootstrap/components';

import { ReactComponent as ArrowLeftIcon } from 'images/icon-arrow-left.svg';

export default function ContactAssetViewPage() {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const { id = '' } = useParams();
  const { t } = useTranslation(['mcontacts']);

  const contact: ContactDTO =
    useSelector((state: RootState) => state.overviewContact.item) || {};

  React.useEffect(() => {
    if (id) {
      dispatch(getContactById(id));
    }
  }, [id]);

  const getAssetStructure = (): Record<string, AssetDTO[]> => {
    const structure = {};
    const flattenedAssets = Object.values(contact.assets || {}).flat();

    flattenedAssets.forEach((asset: AssetDTO) => {
      const assetTypeName = asset.assetTypeName || '';
      if (structure[assetTypeName]) {
        structure[assetTypeName] = [asset, ...structure[assetTypeName]];
      } else {
        structure[assetTypeName] = [asset];
      }
    });
    return structure;
  };

  const assets = getAssetStructure();
  const previousPageUrl = location?.state?.fromPage || '/my-contacts';
  const GENDER_TYPES = {
    male: t('common:male'),
    female: t('common:female'),
  };

  return (
    <>
      <Row className="t-h-100" data-testid="ContactAssetViewPage">
        <Col
          md={{ span: 12 }}
          xl={{ span: 10, offset: 2 }}
          style={{ paddingRight: 0 }}
        >
          <div className="t-bg-beta-50 t-rounded t-shadow-lg md:t-p-12 xs:t-px-8 xs:t-py-10 t-mb-7">
            <div className="Contacts">
              <div className="d-inline-block u-pb-30">
                <Link to={previousPageUrl} className="button-secondary">
                  <ArrowLeftIcon className="t-mr-2" />
                  {t('mcontacts:contact_card_back_to_contacts')}
                </Link>
              </div>
              <h1 className="typo-alpha t-pb-2.5">
                {t('mcontacts:contact_card_title', {
                  name: contact.name,
                  surname: contact.surname,
                  greeting: GENDER_TYPES[contact.gender!] || null,
                })}
              </h1>

              <p className="typo-gamma t-pb-6">
                {t('mcontacts:contact_card_description')}
              </p>
            </div>
            <div className="t-pt-14 u-pt-md-0">
              {!isEmpty(assets) ? (
                <Accordion className="Accordion">
                  {Object.values(assets).map((assetTypeAssets: AssetDTO[]) => {
                    return (
                      <ContactAccordionItem
                        key={assetTypeAssets[0].assetTypeLabel}
                        itemTitle={assetTypeAssets[0].assetTypeLabel || ''}
                        assets={assetTypeAssets}
                      />
                    );
                  })}
                </Accordion>
              ) : (
                <h3 className="typo-alpha t-text-center t-mt-5">
                  {t('mcontacts:no_assets_for_contact')}
                </h3>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
