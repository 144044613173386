import {
  LOAD_CONTACTS_LIST,
  ADD_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  UPDATE_MULTIPLE_CONTACTS,
  GET_BACKUP_CONTACTS,
} from 'store/types/contactActionTypes';
import helper from 'store/reducers/reducerHelper';
import initialState from 'store/reducers/initialState';

const contactReducer = (state = initialState.contacts, action) => {
  return helper.handleActions(state, action, {
    [LOAD_CONTACTS_LIST](state, payload) {
      state.list = payload.contacts as ContactDTO[];
    },

    [ADD_CONTACT](state, payload) {
      state.list = [...state.list, payload.contactData as ContactDTO];
    },

    [UPDATE_CONTACT]: updateContact,

    [DELETE_CONTACT]: deleteContact,

    [UPDATE_MULTIPLE_CONTACTS]: updateMultipleContacts,

    [GET_BACKUP_CONTACTS](state, payload) {
      state.backup = payload.backupContacts as ContactDTO[];
    },
  });
};

export default contactReducer;

function deleteContact(state, payload) {
  const { id } = payload;
  const newData = [...state.list.filter((item) => item.id !== id)];
  state.list = [...newData];
}

function updateContact(state, { contactData }) {
  const { id } = contactData;

  const newData = state.list.map((item) => {
    if (item.id !== id) return { ...item };

    return { ...contactData };
  });
  state.list = [...newData];
}

function updateMultipleContacts(state, { contactsData }) {
  const newData = state.list.map((item) => {
    const updatedContact = contactsData.find(
      (contact) => contact.id === item.id
    );
    if (!updatedContact) return { ...item };

    return { ...updatedContact };
  });
  state.list = [...newData];
}
