import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { kebabCase, camelCase } from 'lodash';

import {
  editNotification,
  toggleNotificationPanel,
} from 'store/actions/notificationActions';
import { setModal } from 'store/actions/modalActions';
import { useTranslation } from 'modules/common/helpers';

import { PLAN_CATEGORY_PAGES } from 'modules/estatePlan/constants';
import { NOTIFICATION_CODES as NC } from 'modules/notification/constants';
import { MODALS } from 'modules/common/constants';
import { Button, Spinner } from 'modules/common/components';

interface NotificationActionsProps {
  notification: NotificationStructure;
}

export default function NotificationActions({
  notification,
}: NotificationActionsProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['notification']);
  const [loading, setLoading] = React.useState(false);

  const handleActionClick = async (notification) => {
    setLoading(true);
    await dispatch(editNotification({ ...notification, read: true }));
    if (notification.code === NC.REFERRAL_BONUS) {
      dispatch(setModal(MODALS.PAYMENT_MODAL));
    }
    dispatch(toggleNotificationPanel());
    setLoading(false);
  };

  const notificationsWithoutActions = [
    NC.ZVR_REGISTERED,
    NC.BENEFICIARY_ADDED,
    NC.NEW_LOGIN,
  ];
  if (notificationsWithoutActions.includes(notification.code)) return null;

  const questionnairePath = notification?.extraInfo?.document
    ? kebabCase(notification?.extraInfo?.document)
    : undefined;

  const assetCategoryPath = notification?.extraInfo?.category
    ? PLAN_CATEGORY_PAGES.find((page) => {
        return page.category === camelCase(notification?.extraInfo?.category);
      })?.path?.replace('/plan/', '')
    : undefined;

  const extraPath = questionnairePath
    ? questionnairePath
    : assetCategoryPath || '';

  return (
    <>
      {notification.code === NC.REFERRAL_BONUS ? (
        <Button
          category="secondary"
          className="t-w-max"
          onClick={() => handleActionClick(notification)}
          loading={loading}
        >
          {t(`notification:${notification.code}_action`)}
        </Button>
      ) : (
        <Link
          to={`${notification?.link}/${extraPath}`}
          state={{
            notificationCode: notification.code,
            notificationData: { ...notification?.extraInfo },
          }}
          onClick={() => handleActionClick(notification)}
          className={`button-secondary t-w-max ${
            loading ? 'button-disabled' : ''
          }`}
        >
          {loading && <Spinner className="t-h-5 t-w-5 t-mr-2" />}
          {t(`notification:${notification.code}_action`)}
        </Link>
      )}
    </>
  );
}
