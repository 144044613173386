import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as CheckmarkIcon } from 'images/check.svg';

interface StepsData {
  [dataKey: string]: {
    label: string;
    step: number;
  };
}

interface ProgressBarProps {
  currentStep: number;
  stepsData: StepsData;
  handleStepChange: (step: number) => void;
}

export default function ProgressBar({
  currentStep,
  stepsData,
  handleStepChange,
}: ProgressBarProps) {
  const { t } = useTranslation(['common']);
  const stepData = Object.values(stepsData);

  return (
    <div
      className="t-w-full t-mt-2 t-flex t-justify-center"
      data-testid="ProgressBar"
    >
      <div
        className={`t-flex t-justify-between t-w-full ${
          stepData.length < 3 ? 't-w-2/3' : 't-w-full'
        }`}
      >
        {stepData.map((data) => (
          <React.Fragment key={data.label}>
            <div className="t-flex t-flex-col t-justify-center t-items-center t-z-50">
              <button
                type="button"
                data-testid={`ProgressBarStep${data.step}`}
                className={`t-w-10 t-h-10 t-z-50 t-rounded-full t-text-lg t-flex t-items-center t-justify-center focus:t-outline-none ${
                  data.step <= currentStep
                    ? 't-bg-alpha-600'
                    : 't-bg-beta-50 t-border-2 t-border-solid t-border-alpha-600'
                }`}
                onClick={() => handleStepChange(data.step)}
              >
                {data.step < currentStep ? (
                  <CheckmarkIcon className="t-h-3 t-w-4 t-text-beta-50 t-text-xl" />
                ) : (
                  <div
                    className={`t-text-xl ${
                      data.step <= currentStep
                        ? 't-text-beta-50'
                        : 't-text-alpha-600'
                    }`}
                  >
                    {data.step}
                  </div>
                )}
              </button>

              <p className="typo-epsilon t-mt-2">{t(data.label)}</p>
            </div>
            {data.step < stepData.length && (
              <div className="t-flex-grow t-h-1.5 t-bg-alpha-600 t-relative t-top-4 t--mx-12 t-overflow-hidden" />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
