import React, { useState } from 'react';

import { Row, Col } from 'modules/bootstrap/components';
import { Modal } from 'modules/common/components';
import {
  EpTransmortalWizardStep1,
  EpTransmortalWizardStep2,
} from 'modules/estatePlan/components';

interface EpTransmortalWizardProps {
  initialStep: number;
  handleClose: () => void;
  onDownload: (document: DocumentDTO) => void;
}

export default function EpTransmortalWizard({
  handleClose,
  onDownload,
  initialStep,
}: EpTransmortalWizardProps) {
  const [step, setStep] = useState(initialStep);

  React.useEffect(() => {
    setStep(initialStep);
  }, [initialStep]);

  const setNextStep = (stepNo: number): void => {
    setStep(stepNo);
  };

  const renderStepComponent = () => {
    switch (step) {
      case 2:
        return (
          <EpTransmortalWizardStep2
            handleClose={handleClose}
            onDownload={onDownload}
          />
        );
      case 1:
      default:
        return (
          <EpTransmortalWizardStep1
            setNextStep={setNextStep}
            handleClose={handleClose}
          />
        );
    }
  };

  return (
    <Modal show onHide={handleClose} className="">
      <Row>
        <Col lg={{ span: 12 }} md={{ span: 12 }}>
          {renderStepComponent()}
        </Col>
      </Row>
    </Modal>
  );
}
