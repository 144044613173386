import React, { useState } from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Button, Modal, Radio } from 'modules/common/components';

import { TWO_AUTH_TYPES } from 'modules/common/constants/enums';

type TwoAuthChoiceModalProps = {
  onCancel: () => void;
  onContinue: (type: TWO_AUTH_TYPES) => void;
};

const TwoAuthChoiceModal = ({
  onCancel,
  onContinue,
}: TwoAuthChoiceModalProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const [type, setType] = useState(TWO_AUTH_TYPES.SMS);

  const handleChange = (e) => {
    setType(e.target.value);
  };

  const handleSubmit = () => {
    onContinue(type);
  };

  return (
    <Modal dataTestid="twoAuthChoiceModal" show onHide={onCancel}>
      <div role="radiogroup">
        <h1 className="Typography Typography--title u-weight-600">
          {t('auth:two_auth_title')}
        </h1>

        <p className="Modal-description">{t('auth:two_auth_description')}</p>

        <div className="u-pb-10">
          <Radio
            label={t('auth:two_auth_sms')}
            name="type"
            value={TWO_AUTH_TYPES.SMS}
            id="sms"
            checked={type === TWO_AUTH_TYPES.SMS}
            onChange={handleChange}
            info={t('auth:two_auth_sms_tooltip')}
          />
        </div>

        <div>
          <Radio
            label={t('auth:two_auth_google')}
            name="type"
            value={TWO_AUTH_TYPES.GOOGLE}
            id="google"
            checked={type === TWO_AUTH_TYPES.GOOGLE}
            onChange={handleChange}
            info={t('auth:two_auth_google_tooltip')}
          />
        </div>

        <div className="t-flex t-justify-end t-flex-wrap u-pt-20">
          <Button
            testId="twoAuthChoiceModalCancel"
            category="secondary"
            onClick={onCancel}
            className="t-self-end"
          >
            {t('common:cancel')}
          </Button>
          <Button
            testId="twoAuthChoiceModalContinue"
            onClick={handleSubmit}
            className="t-ml-5 xs:t-mt-5"
          >
            {t('common:continue')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TwoAuthChoiceModal;
