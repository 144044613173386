import React, { FormEvent } from 'react';
import { useForm } from 'react-hook-form';
import { capitalize } from 'lodash';

import { Button, Modal, SelectController } from 'modules/common/components';
import { useTranslation } from 'modules/common/helpers';

import { PROVIDER_CONSTANT_TYPES as P } from 'modules/estatePlan/constants/enums';
import { ASSET_CATEGORIES as C } from 'modules/estatePlan/constants';

const providersByCategory = {
  [C.DEVICES]: [P.PHONE, P.COMPUTER, P.TABLET, P.OTHER_ELECTRONIC_DEVICE],
  [C.HEALTH]: [
    P.ALL_HEALTH_INSURANCE,
    P.CARE_INSURANCE,
    P.OTHER_HEALTH_INSURANCE,
  ],
  [C.LEGAL]: [P.INSURANCE],
  [C.DIGITAL]: [P.PASSWORD, P.EMAIL, P.SOCIAL, P.CLOUD_STORAGE, P.SUBSCRIPTION],
  [C.FINANCIAL]: [
    P.DEATH_BENEFIT_INSURANCE,
    P.BANK_ACCOUNT,
    P.ONLINE_WALLET,
    P.LIFE_INSURANCE,
  ],
};

type Inputs = {
  id?: string;
  assetCategory?: SelectOption;
  providerType: SelectOption;
};

interface ProviderInputFormProps {
  assetItem?: AssetDTO;
  isVisible: boolean;
  onCancel: () => void;
  onSuccess: (data: { providerType: string }) => void;
}

function ProviderInputForm({
  onCancel,
  onSuccess,
  isVisible,
  assetItem,
}: ProviderInputFormProps) {
  const asset = (assetItem || {}) as any;

  const { t } = useTranslation([
    'auth',
    'common',
    'contacts',
    'will',
    'dashboard',
  ]);
  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,

    formState: { errors },
  } = useForm<Inputs>();
  watch('assetCategory');

  const categoryOptions: SelectOption[] = Object.values(C)
    .filter((item) => Array.isArray(providersByCategory[item]))
    .map((item) => ({
      value: item,
      label: capitalize(t(`dashboard:assets_${item}_tab`)),
    }));
  const defaultCategoryOptions = categoryOptions.find(
    (item) => item.value === asset.assetCategory
  );

  let providersOptions = [] as SelectOption[];
  const categorySelected = getValues('assetCategory');
  if (categorySelected) {
    providersOptions = providersByCategory[categorySelected.value].map(
      (item: P) => ({
        label: t(`common:provider_type_${item}`),
        value: item,
      })
    );
  }
  const defaultAssetsOptions = providersOptions.find(
    (item) => item.value === asset.id
  );

  const isNewItem = !asset.id;

  const onSubmit = async (formData: Inputs) => {
    const submitData = {
      providerType: formData.providerType.value,
    };

    onSuccess(submitData);
    onCancel();
  };

  const submit = (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    handleSubmit(() => {
      onSubmit(getValues());
    })();
  };

  const textFieldRequired = t('common:field_required');

  if (!isVisible) return null;

  return (
    <Modal show onHide={onCancel}>
      <form onSubmit={submit}>
        {!isNewItem && (
          <input type="hidden" id="id" {...register('id')} value={asset.id} />
        )}
        <h1 className="t-text-xl t-font-secondary t-mb-6 t-font-semibold">
          {t('plan:provider_modal_title')}
        </h1>

        <div className="t-mb-3.5">
          <label
            className={`Form-label ${errors.assetCategory ? 'isErrored' : ''}`}
            htmlFor="assetCategory"
          >
            {t('will:asset_type_label')}
          </label>
          <SelectController
            id="assetCategory"
            name="assetCategory"
            options={categoryOptions}
            control={control}
            defaultValue={defaultCategoryOptions}
            className={`Select ${errors.assetCategory ? 'isErrored' : ''}`}
            rules={{ required: true }}
          />
          {errors.assetCategory && (
            <div className="t-mt-0.5">
              <span className="t-text-xs t-text-epsilon-600">
                {textFieldRequired}
              </span>
            </div>
          )}
        </div>
        <p className="t-text-beta-500 t-mb-5">
          {t('plan:input_lib_provider_modal_context')}
        </p>
        {categorySelected && (
          <div className="t-mb-3.5">
            <label
              className={`Form-label ${errors.providerType ? 'isErrored' : ''}`}
              htmlFor="providerType"
            >
              {t('plan:provider_choose_label')}
            </label>
            <SelectController
              id="providerType"
              name="providerType"
              disabled
              options={providersOptions}
              control={control}
              defaultValue={defaultAssetsOptions}
              className={`Select ${errors.providerType ? 'isErrored' : ''}`}
              rules={{ required: true }}
            />
            {errors.providerType && (
              <div className="t-mt-0.5">
                <span className="t-text-xs t-text-epsilon-600">
                  {textFieldRequired}
                </span>
              </div>
            )}
          </div>
        )}

        <div className="t-flex t-justify-end t-mt-5">
          <Button category="secondary" className="t-mr-2.5" onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button type="submit">{t('common:save_and_continue')}</Button>
        </div>
      </form>
    </Modal>
  );
}

export default ProviderInputForm;
