import { camelCase } from 'lodash';

export function checkIfCorrespondingAssetExists(
  reco: RecommendationDTO,
  categoryAssets: AssetDTO[]
) {
  return !!categoryAssets.find((asset) => {
    let check = true;
    if (camelCase(asset?.assetTypeName) !== camelCase(reco.assetType)) {
      check = false;
    }

    if (reco?.subtype && asset?.assetTypeName !== reco?.subtype) {
      check = false;
    }
    return check;
  });
}
