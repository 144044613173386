import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'store/reducers';
import { loadHeirs } from 'store/actions/heirActions';
import { useTranslation } from 'modules/common/helpers';

import { Questionnaire } from 'modules/assistant/components/Questionnaire';

import { Row, Col } from 'modules/bootstrap/components';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';
import { STEP_KEYS as WILL_STEP_KEYS } from 'modules/assistant/constants/questionnaires/will';

export interface QuestionnairePageProps {
  questionnaire: QUESTIONNAIRE_KEYS;
}

const QuestionnairePage = ({ questionnaire }: QuestionnairePageProps) => {
  const { t } = useTranslation([questionnaire, 'assistant']);
  const dispatch = useDispatch();

  const isInitialAssessment =
    questionnaire === QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT;

  const questionnaireData: QuestionnaireDTO = useSelector(
    (state: RootState) => state.questionnaires[questionnaire]
  );

  React.useEffect(() => {
    dispatchHeirs();
  }, [questionnaireData]);

  const dispatchHeirs = () => {
    // if the questionnaire is will, populate the heirs to store
    // currently will generator has either STEP_3, STEP_13_1, or STEP_20
    // that contains heirs' information
    if (questionnaire === QUESTIONNAIRE_KEYS.WILL_GENERATOR) {
      const heirs =
        questionnaireData[WILL_STEP_KEYS.STEP_3] ||
        questionnaireData[WILL_STEP_KEYS.STEP_13_1] ||
        questionnaireData[WILL_STEP_KEYS.STEP_20] ||
        ([] as ContactDTO[]);

      dispatch(loadHeirs(heirs as ContactDTO[]));
    }
  };
  return (
    <>
      <Row className="t-h-100">
        <Col
          md={{ span: 12 }}
          xl={{ span: 10, offset: 2 }}
          style={{ paddingRight: 0 }}
        >
          <div className="t-bg-beta-50 t-rounded t-shadow-lg xl:t-p-12 t-px-8 t-py-10 t-mb-7">
            <h1 className="t-text-xl t-font-semibold t-font-secondary t-mb-3">
              {t(`${questionnaire}:page_title`)}
            </h1>
            {isInitialAssessment && (
              <p className="typo-gamma">
                {t(`${questionnaire}:page_header_description`)}
              </p>
            )}
            <Questionnaire questionnaire={questionnaire} />
            <p className="t-text-xxs t-mt-2.5 t-text-center t-text-beta-200">
              {t('assistant:legal_note')}
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default QuestionnairePage;
