import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { RootState } from 'store/reducers';
import { setModal } from 'store/actions/modalActions';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import { useTranslation, utilHelper } from 'modules/common/helpers';

import { BequestCard, BequestForm } from 'modules/asset/components';
import { Button, Modal } from 'modules/common/components';

import { MODALS } from 'modules/common/constants';
import {
  STEP_KEYS,
  WILL_GENERATOR_ANSWER_KEYS,
} from 'modules/assistant/constants/questionnaires/will';

import iconPlus from 'images/icon-plus.svg';

export interface BequestProps {
  previousAnswer?: string;
  questionnaireData: QuestionnaireDTO;
}

const Bequest = ({ previousAnswer, questionnaireData }: BequestProps) => {
  const { t } = useTranslation(['common', 'assistant', 'will']);
  const dispatch = useDispatch();

  const [showEditForm, setShowEditForm] = useState(false);
  const [bequestToEdit, setBequestToEdit] = useState({} as BequestDTO);

  const tempFieldValue =
    useSelector((state: RootState) => state.temporaryField?.value) || [];

  // show previous input val, if entered before
  useEffect(() => {
    if (previousAnswer) {
      dispatch(temporaryFieldSaver(previousAnswer));
    }
  }, [previousAnswer]);

  const handleCloseForm = () => {
    setBequestToEdit({} as BequestDTO);
    setShowEditForm(false);
  };

  const handleFormSubmit = (bequestFormData): void => {
    const isNewBequest = isEmpty(bequestToEdit);
    let updTempFieldValue = [...tempFieldValue];

    if (isNewBequest) {
      const id = utilHelper.generateId();
      const newBequest = { id, ...bequestFormData };

      updTempFieldValue = [...tempFieldValue, newBequest];
    } else {
      updTempFieldValue = tempFieldValue.map((item) => {
        if (item.id === bequestFormData.id) {
          return { ...bequestFormData };
        }
        return item;
      });
    }

    dispatch(temporaryFieldSaver(updTempFieldValue));
  };

  const handleEditBequest = (bequestData: BequestDTO) => {
    setBequestToEdit(bequestData);
    setShowEditForm(true);
  };

  const handleDeleteBequest = (id: string) => {
    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title: t('will:delete_bequest_confirm_text'),
          primaryButtonText: t('common:confirm'),
          successAction: () => {
            const updTempFieldValue = tempFieldValue.filter(
              (item) => item.id !== id
            );
            dispatch(temporaryFieldSaver(updTempFieldValue));
          },
        },
      })
    );
  };

  // TODO: IMPORTANT! In case STEP#8 "typeOfWill" is moved, reworked or deleted,
  // this line and related code to be refactored!
  // const "typeOfWill" relies on exact step "typeOfWill", and in case any
  // related change it must be changed accordingly!
  const typeOfWill = questionnaireData[STEP_KEYS.STEP_8];

  const isSpousalWill = typeOfWill === WILL_GENERATOR_ANSWER_KEYS.SPOUSAL;

  return (
    <>
      {showEditForm && (
        <Modal show onHide={handleCloseForm}>
          <BequestForm
            onCancel={handleCloseForm}
            onSuccess={handleFormSubmit}
            bequestItem={bequestToEdit}
            isSpousalWill={isSpousalWill}
          />
        </Modal>
      )}

      {isEmpty(tempFieldValue) ? (
        <div className="d-flex flex-column align-items-center">
          <Button category="secondary" onClick={() => setShowEditForm(true)}>
            <img
              src={iconPlus}
              className="button-icon-amazon"
              alt={t('assistant:add_information')}
              role="presentation"
            />
            {t('assistant:add_information')}
          </Button>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center">
          <div className="t-flex t-flex-col t-w-full">
            {tempFieldValue.map((item) => (
              <BequestCard
                key={item.bequest}
                bequestData={item}
                onDelete={handleDeleteBequest}
                onEdit={handleEditBequest}
                className="card t-shadow bequest t-bg-gamma-400 t-py-6 t-px-7 t-mt-2 t-mb-4 sm:t-mr-3 xl:t-min-w-96"
              />
            ))}
          </div>

          <Button category="secondary" onClick={() => setShowEditForm(true)}>
            <img
              src={iconPlus}
              className="button-icon-amazon"
              alt={t('common:add_new')}
              role="presentation"
            />
            {t('common:add_new')}
          </Button>
        </div>
      )}
    </>
  );
};

export default Bequest;
