import { afterDeathService } from 'modules/afterDeathManagement/services';
import helper from 'store/actions/actionHelper';
import i18n from 'i18next';

import { reactToast } from 'modules/common/helpers';

import {
  GET_ALL_KEY_HOLDERS,
  ADD_KEY_HOLDER,
  DELETE_KEY_HOLDER,
} from 'store/types/keyHolderTypes';

export const getKeyHolders = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const keyHolders = await afterDeathService.getKeyHolders();

    dispatch(helper.successAction(GET_ALL_KEY_HOLDERS, { keyHolders }));

    return keyHolders;
  }, actionOptions);
};

export const createKeyHolder = (
  keyHolderData: KeyHolder,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const keyHolder = await afterDeathService.createKeyHolder(
      keyHolderData,
      true
    );

    dispatch(helper.successAction(ADD_KEY_HOLDER, { keyHolder }));

    reactToast.showMessage(i18n.t('toastr:key_holder_created_success'));

    return keyHolder;
  }, actionOptions);
};

export const editKeyHolder = (
  keyHolderData: KeyHolder,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const keyHolder = await afterDeathService.editKeyHolder(
      keyHolderData,
      true
    );

    await dispatch(getKeyHolders());

    reactToast.showMessage(i18n.t('toastr:key_holder_edited_success'));

    return keyHolder;
  }, actionOptions);
};

export const revokeKeyHolder = (id: string, actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    await afterDeathService.deleteKeyHolder(id);

    dispatch(helper.successAction(DELETE_KEY_HOLDER, { id }));
    reactToast.showMessage(i18n.t('toastr:key_holder_revoked_success'));

    return true;
  }, actionOptions);
};
