import dataService from 'modules/common/services/dataService';
import { LOAD_OVERVIEW_CONTACT } from 'store/types/overviewContactTypes';
import helper from 'store/actions/actionHelper';

const getOverviewContactSuccess = (overviewContact: ContactDTO) =>
  helper.getAction(LOAD_OVERVIEW_CONTACT, { overviewContact });

export const getContactById = (id: string, actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch): Promise<ContactDTO> => {
    const overviewContact = await dataService.getContactById(id);

    if (overviewContact) {
      dispatch(getOverviewContactSuccess(overviewContact));
    }

    return overviewContact;
  }, actionOptions);
};
