import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getCurrentUser, resetPassword } from 'store/actions/userActions';
import { authHelper } from 'modules/auth/helpers';
import { reactToast, config, useTranslation } from 'modules/common/helpers';

import {
  Spinner,
  TextInput,
  SiteLanguage,
  PlatformFeatureInfoSection,
} from 'modules/common/components';
import { Col, Row } from 'modules/bootstrap/components';
import {
  AuthFooter,
  ClickableLogo,
  LoginFooter,
} from 'modules/auth/components';
import { FormConditionsNew } from 'modules/profile/components';

import { commonFeatures } from 'modules/auth/constants';
import { CLIENT_CUSTOMIZATIONS } from 'modules/common/constants';

import imgRectangle from 'images/img-auth-rectangle.png';

interface SetPasswordPageProps {
  token: string;
  client: string;
}

const SetPasswordPage = ({ token, client }: SetPasswordPageProps) => {
  const { t } = useTranslation(['common', 'auth']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customization = CLIENT_CUSTOMIZATIONS[client || '']?.setPassword;
  const pathToRedirect = location.pathname.split('/').slice(0, 2).join('/');

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [pwValidity, setPwValidity] = useState({} as PasswordValidity);
  const [isPasswordTyping, setIsPasswordTyping] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setPwValidity(authHelper.getPasswordValidity(password));
  }, [password]);

  const onChange = (_, value) => {
    setPassword(value);
  };

  const isValidResetForm = () => {
    const checkedError = authHelper.getPasswordErrorMessages(password, t);
    setError(checkedError);

    return !checkedError;
  };

  const onSetPassword = async (e) => {
    if (e) e.preventDefault();

    if (!isValidResetForm()) return;

    setLoading(true);
    const response: any = await dispatch(resetPassword({ password }, token));

    if (response) {
      await dispatch(getCurrentUser());

      if (pathToRedirect === '/shared') navigate(pathToRedirect);
      else navigate('/register-wizard');

      if (response?.message) {
        reactToast.showMessage(response.message);
      }
    }
    setLoading(false);
  };

  const onPasswordInputBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setIsPasswordTyping(false);
    }
  };

  const isPasswordInvalid = Object.values(pwValidity).some((el) => !el);

  return (
    <>
      <Row className="lg:t-p-0 t-min-h-screen t-w-full t-m-0 t-relative">
        <Col
          md={{ span: 6, offset: 3 }}
          lg={{ span: 10, offset: 1 }}
          className="t-p-0 t-z-50"
        >
          <div className="t-hidden md:t-flex t-justify-between t-mt-6 lg:t-mt-10 t-px-3">
            <ClickableLogo />
            <div className="t-flex t-items-center">
              <div className="t-hidden lg:t-inline-block">
                <AuthFooter />
              </div>
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>
          </div>

          <div className="md:t-flex t-flex-col t-items-center t-mt-4 lg:t-mt-16 t-px-3 t-mb-16">
            <div className="t-flex t-justify-between md:t-hidden">
              <ClickableLogo />
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>

            <div className="card t-shadow-lg t-bg-beta-50 t-p-6 lg:t-px-12 lg:t-pt-12 t-w-full lg:t-w-auto t-mt-5">
              <form
                onSubmit={onSetPassword}
                className="Form t-w-full lg:t-w-96 t-bg-transparent"
              >
                <h2 className="typo-beta t-mb-5">
                  {t(
                    customization?.customizedFormLabel
                      ? `auth:${client}_set_password_form_label`
                      : 'auth:set_password_form_label'
                  )}
                </h2>
                <p className="typo-epsilon t-mb-5 t-text-beta-400">
                  {customization?.formDescription
                    ? t(`auth:${client}_set_password_form_description`)
                    : t('auth:set_password_form_description')}
                </p>

                <fieldset disabled={isLoading}>
                  <TextInput
                    name="password"
                    label={t('common:password_label')}
                    type="password"
                    value={password}
                    onChange={onChange}
                    onBlur={onPasswordInputBlur}
                    onFocus={() => {
                      setIsPasswordTyping(true);
                    }}
                    placeholder={t('auth:password_placeholder')}
                    error={error}
                  />
                </fieldset>

                <div className="t-relative">
                  {isPasswordTyping && isPasswordInvalid && (
                    <div className="t-absolute -t-top-2 t-bg-beta-50 t-animate-fade-in-100">
                      <FormConditionsNew pwValidity={pwValidity} />
                    </div>
                  )}
                </div>

                <div className="t-mt-5">
                  <p className="typo-theta t-text-beta-700">
                    {t('auth:signup_click_text')}&nbsp;
                    <a
                      href={`${config.websiteUrl}/terms`}
                      target="_blank"
                      rel="nofollow noreferrer noopener"
                      className={`t-no-underline t-text-alpha-600 ${
                        customization?.customizedPrimaryButton
                          ? `${client}-link-color`
                          : ''
                      }`}
                    >
                      {t('auth:signup_agreement')}
                    </a>
                    <span>{` ${t('common:and')} `}</span>
                    <a
                      href={
                        client === 'vbbb'
                          ? `${config.websiteUrl}/privacy-vbbb`
                          : `${config.websiteUrl}/privacy`
                      }
                      target="_blank"
                      rel="nofollow noreferrer noopener"
                      className={`t-no-underline t-text-alpha-600 ${
                        customization?.customizedPrimaryButton
                          ? `${client}-link-color`
                          : ''
                      }`}
                    >
                      {t('auth:signup_privacy')}
                    </a>
                    .
                  </p>
                </div>

                <div className="t-flex t-justify-end">
                  <button
                    type="submit"
                    disabled={isLoading}
                    onClick={onSetPassword}
                    className={`button t-mt-6 ${
                      customization?.customizedPrimaryButton
                        ? `${client}-set-password-button-primary`
                        : 'button-primary'
                    }`}
                  >
                    {isLoading && <Spinner className="t-h-5 t-w-5 t-mr-2" />}
                    {t(
                      customization?.customizedRegisterButtonText
                        ? `auth:${client}_set_password_button_text`
                        : 'auth:set_password_button_text'
                    )}
                  </button>
                </div>
              </form>
            </div>

            <div className="t-my-5 lg:t-mt-16 t-flex t-justify-center md:t-inline">
              <PlatformFeatureInfoSection features={commonFeatures} />
            </div>

            <div className="lg:t-hidden t-mt-16">
              <AuthFooter />
            </div>
          </div>

          <div className="t-absolute t-bottom-0 t-w-full t-flex t-justify-center md:t-inline">
            <LoginFooter />
          </div>
        </Col>
      </Row>
      <div className="t-fixed t-w-screen t-object-cover t-h-3/4 t-bottom-0">
        <img
          src={imgRectangle}
          className="t-w-full t-h-full"
          alt="Rectangle background"
        />
      </div>
    </>
  );
};

export default SetPasswordPage;
