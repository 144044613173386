import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { isEmpty, keys, values, groupBy, find } from 'lodash';

import { RootState } from 'store/reducers';
import { AssetsNotAssignedSelector } from 'store/selectors/selectors';
import { useTranslation } from 'modules/common/helpers';

import {
  AssetsByTypeWidget,
  NoDocumentsHere,
  SearchedAssetsWidget,
} from 'modules/dashboard/components';

import {
  ASSET_CATEGORIES,
  PLAN_CATEGORY_PAGES,
} from 'modules/estatePlan/constants';
import { AssetsSearching } from 'modules/estatePlan/components';

interface DocumentsWidgetProps {
  assetsData: AssetsDTO;
}

export default function DocumentsWidget({ assetsData }: DocumentsWidgetProps) {
  const { t } = useTranslation(['common', 'plan', 'dashboard']);
  const assetsDataNotAssigned = useSelector((state: RootState) =>
    AssetsNotAssignedSelector(state)
  );
  const { searching, result = {} as AssetsDTO } = useSelector(
    (state: RootState) => state.assetSearch
  );

  // filter all assets in the removal queue
  const temporaryField = useSelector(
    (state: RootState) => state.temporaryField?.value
  );
  const assetIdsToRemove =
    temporaryField?.type === 'AssetToRemove' ? temporaryField.assetIds : [];

  const [path, setPath] = useState<string | undefined>(ASSET_CATEGORIES.HEALTH);

  const [searchedCategAssets, setSearchedCategAssets] = useState(assetsData);

  React.useEffect(() => {
    if (searching) {
      setSearchedCategAssets(result);
    } else {
      setSearchedCategAssets(assetsData);
    }
  }, [result, searching]);

  return (
    <>
      <div className="t-flex t-flex-col sm:t-flex-row t-items-end sm:t-items-center t-justify-between t-px-5 t-pb-3">
        <h2 className="typo-alpha t-whitespace-nowrap t-mr-2 t-w-full sm:t-w-auto">
          {t('dashboard:assets_title')}
        </h2>
        <div className="t-flex t-justify-self-end md:t-w-2/5 xl:t-w-56 t-mt-3 sm:t-mt-0">
          <AssetsSearching
            innerContainerClassName="t-bg-beta-100"
            inputFieldClassName="t-bg-beta-100"
            buttonClassName="t-bg-beta-100"
          />
        </div>
      </div>

      {!searching && (
        <Tabs
          id="DocumentTabs"
          onSelect={(k: any) => setPath(k)}
          activeKey={path}
          className="t-min-w-full"
        >
          {values(ASSET_CATEGORIES).map((assetCategory) => {
            const assetData = assetsData[assetCategory];
            const assetDataByType = groupBy(
              assetData,
              (item) => item.assetTypeName
            );
            const planPage =
              find(
                PLAN_CATEGORY_PAGES,
                (page) => page.category === assetCategory
              ) || ({} as PlanCategoryPage);
            const planRoute = planPage.path || '';

            return (
              <Tab
                key={assetCategory}
                eventKey={assetCategory}
                title={t(`dashboard:assets_${assetCategory}_tab`)}
                tabClassName={`t-whitespace-nowrap ${
                  assetsDataNotAssigned[assetCategory]
                    ? 'Documents-notAssignedTab'
                    : ''
                }`}
              >
                {isEmpty(assetData) && (
                  <NoDocumentsHere planRoute={planRoute} />
                )}
                {!isEmpty(assetData) &&
                  assetCategory === planPage.category &&
                  keys(assetDataByType).map((assetTypeName) => {
                    const assetTypeAssets = assetDataByType[assetTypeName];

                    const displayedAssets = assetTypeAssets.filter(
                      (asset) => !assetIdsToRemove.includes(asset.id)
                    );
                    return (
                      <AssetsByTypeWidget
                        key={assetTypeName}
                        assetCategory={assetCategory}
                        assetTypeName={assetTypeName}
                        assetTypeLabel={assetTypeAssets[0].assetTypeLabel}
                        assetTypeAssets={displayedAssets}
                        planRoute={planRoute}
                        assetIdsToRemove={assetIdsToRemove}
                      />
                    );
                  })}
              </Tab>
            );
          })}
        </Tabs>
      )}

      {!!searching && (
        <SearchedAssetsWidget
          categorizedAssets={searchedCategAssets}
          assetIdsToRemove={assetIdsToRemove}
        />
      )}
    </>
  );
}
