import { FIELD_TYPE_KEYS } from 'modules/estatePlan/constants';

const getProviderType = (assetType: AssetTypeDTO) => {
  return (
    assetType?.structure?.find(
      (field) => field.type === FIELD_TYPE_KEYS.PROVIDER
    )?.providerType || ''
  );
};

export default getProviderType;
