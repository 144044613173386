import icons from 'modules/assistant/constants/questionnaires/icons';

import { STEP_COMPONENT_TYPE as C } from 'modules/assistant/constants/questionnaires';
import { QUESTIONNAIRE_FORM_FIELD_TYPES as F } from 'modules/assistant/constants/questionnaires/questionnaireFormFieldTypes';

export enum STEP_KEYS {
  // ids of steps (questions)
  // NOTE: we should probably make the below keys as descriptive as we did for the answers
  // because we will store "keyStep: keyAnswer" in the backend
  STEP_START = 'start',
  STEP_1 = 'authorizedPerson',
  STEP_2 = 'fallback',
  STEP_2_1 = 'substituteAuthorizedPerson',
  STEP_2_2 = 'shelter',
  STEP_3 = 'petInformation',
  STEP_4 = 'isExistingVet',
  STEP_4_1 = 'vetInformation',
  STEP_5 = 'illnessEuthanasiaPermission',
  STEP_6 = 'bindingTerminationPermissionOfConfidentiality',
  STEP_7 = 'isExistingFuneralContract',
  STEP_7_1 = 'funeralContractInformation',
  STEP_8 = 'areExistingInsurances',
  STEP_8_1 = 'insurances',
  STEP_9 = 'specifyEatingHabits',
  STEP_9_1 = 'eatingHabits',
  STEP_10 = 'movingPetPermission',
  STEP_11 = 'treatmentOfSameVet',
  STEP_12 = 'medicalTreatmentCosts',
  STEP_13 = 'deathCosts',
  STEP_14 = 'remuneration',
  STEP_14_1 = 'remunerationAmount',
  STEP_15 = 'locationOfInformationFolder',
  STEP_16 = 'vaccinationCertificate',
  STEP_16_1 = 'certificateStoredPlace',
  STEP_17 = 'petPersonality',
  STEP_18 = 'isAddingOtherInformation',
  STEP_18_1 = 'otherInformation',
  STEP_END = 'end',
}
const S = STEP_KEYS;

export enum PET_AGREEMENT_ANSWER_KEYS {
  SUBSTITUTE_AUTHORIZED_PERSON = 'substituteAuthorizedPerson',
  SHELTER = 'shelter',
  NONE = 'none',
  YES = 'true',
  NO = 'false',
  LIABILITY_INSURANCE = 'liabilityInsurance',
  HEALTH_INSURANCE = 'healthInsurance',
  SURGERY_COST_INSURANCE = 'surgeryCostInsurance',
  OTHER_INSURANCE = 'otherInsurance',
  FREE_ROAMING = 'freeRoaming',
  LIKE_CHILDREN = 'likeChildren',
  LIKE_OLD_PEOPLE = 'likeOldPeople',
  LIKE_TO_BE_IN_CARS = 'likeToBeInCars',
  CAN_BE_TEMPORARILY_AT_HOME = 'canBeTemporarilyAtHome',
  NOTHING_OF_ABOVE = 'nothingOfTheAbove',
}

const A = PET_AGREEMENT_ANSWER_KEYS;

export const getPetWatchingAgreementQuestionnaire = (
  questionnaire,
  i18nTFunc
) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    [S.STEP_START]: {
      content: t('s_start'),
      type: C.START,
      linksTo: S.STEP_1,
    },
    [S.STEP_1]: {
      content: t('s_1'),
      current: 1,
      total: 27,
      type: C.MULTI_CONTACTS,
      linksTo: S.STEP_2,
    },
    [S.STEP_2]: {
      content: t('s_2'),
      current: 2,
      total: 27,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.SUBSTITUTE_AUTHORIZED_PERSON, linksTo: S.STEP_2_1 },
        { id: A.SHELTER, linksTo: S.STEP_2_2 },
        { id: A.NONE, linksTo: S.STEP_3 },
      ],
    },
    [S.STEP_2_1]: {
      content: t('s_2_1'),
      current: 3,
      total: 27,
      type: C.CONTACT,
      linksTo: S.STEP_3,
    },
    [S.STEP_2_2]: {
      content: t('s_2_2'),
      current: 4,
      total: 27,
      type: C.FREE_TEXT,
      linksTo: S.STEP_3,
    },
    [S.STEP_3]: {
      content: t('s_3'),
      current: 5,
      total: 27,
      type: C.FORM,
      linksTo: S.STEP_4,
      customNavigationButtons: true,
      formElements: [
        {
          type: F.NAME,
          label: t('s_3_form_field_label_1'),
          required: false,
          span: 6,
        },
        {
          type: F.TYPE,
          label: t('s_3_form_field_label_2'),
          required: true,
          span: 6,
        },
        {
          type: F.DESCRIPTION,
          label: t('s_3_form_field_label_3'),
          required: false,
          span: 6,
        },
        {
          type: F.BIRTHDAY,
          label: t('s_3_form_field_label_4'),
          required: false,
          span: 6,
        },
        {
          type: F.GENDER,
          label: t('s_3_form_field_label_5'),
          required: false,
          span: 6,
        },
        {
          type: F.REGISTRATION_NO,
          label: t('s_3_form_field_label_6'),
          required: false,
          span: 6,
        },
        {
          type: F.CHIP_NO,
          label: t('s_3_form_field_label_7'),
          required: false,
          span: 6,
        },
      ],
    },
    [S.STEP_4]: {
      content: t('s_4'),
      current: 6,
      total: 27,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_4_1 },
        { id: A.NO, linksTo: S.STEP_5 },
      ],
    },
    [S.STEP_4_1]: {
      content: t('s_4_1'),
      current: 7,
      total: 27,
      type: C.FORM,
      linksTo: S.STEP_5,
      customNavigationButtons: true,
      smallSize: true,
      formElements: [
        {
          type: F.NAME,
          label: t('s_4_1_form_field_label_1'),
          required: true,
          span: 12,
        },
        {
          type: F.ADDRESS,
          label: t('s_4_1_form_field_label_2'),
          required: false,
          span: 12,
        },
        {
          type: F.PHONE,
          label: t('s_4_1_form_field_label_3'),
          required: false,
          span: 12,
        },
        {
          type: F.EMAIL,
          label: t('s_4_1_form_field_label_4'),
          required: false,
          span: 12,
        },
      ],
    },
    [S.STEP_5]: {
      content: t('s_5'),
      current: 8,
      total: 27,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_6 },
        { id: A.NO, linksTo: S.STEP_6 },
      ],
    },
    [S.STEP_6]: {
      content: t('s_6'),
      current: 9,
      total: 27,
      // infoContent: t('s_info_4'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_7 },
        { id: A.NO, linksTo: S.STEP_7 },
      ],
    },
    [S.STEP_7]: {
      content: t('s_7'),
      current: 10,
      total: 27,
      // infoContent: t('s_info_4'),
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_7_1 },
        { id: A.NO, linksTo: S.STEP_8 },
      ],
    },
    [S.STEP_7_1]: {
      content: t('s_7_1'),
      current: 11,
      total: 27,
      type: C.FORM,
      linksTo: S.STEP_8,
      customNavigationButtons: true,
      smallSize: true,
      formElements: [
        {
          type: F.CONTRACT_NO,
          label: t('s_7_1_form_field_label_1'),
          required: true,
          span: 12,
        },
        {
          type: F.CEMETERY,
          label: t('s_7_1_form_field_label_2'),
          required: false,
          span: 12,
        },
        {
          type: F.ADDRESS,
          label: t('s_7_1_form_field_label_3'),
          required: false,
          span: 12,
        },
      ],
    },
    [S.STEP_8]: {
      content: t('s_8'),
      current: 12,
      total: 27,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_8_1 },
        { id: A.NO, linksTo: S.STEP_9 },
      ],
    },
    [S.STEP_8_1]: {
      content: t('s_8_1'),
      current: 13,
      total: 27,
      type: C.ADD_INFORMATION,
      linksTo: S.STEP_9,
      formElements: [
        {
          type: F.INSURANCE_TYPE,
          label: t('s_8_1_form_field_label_1'),
          required: true,
          options: [
            { label: t('a_liabilityInsurance'), value: A.LIABILITY_INSURANCE },
            { label: t('a_healthInsurance'), value: A.HEALTH_INSURANCE },
            {
              label: t('a_surgeryCostInsurance'),
              value: A.SURGERY_COST_INSURANCE,
            },
            { label: t('a_otherInsurance'), value: 'other' },
          ],
          other: {
            label: t('a_otherInsurance_extra_field_label'),
          },
        },
        {
          type: F.PROVIDER,
          label: t('s_8_1_form_field_label_2'),
          required: true,
        },
        {
          type: F.INSURANCE_NUMBER,
          label: t('s_8_1_form_field_label_3'),
          required: false,
        },
      ],
    },
    [S.STEP_9]: {
      content: t('s_9'),
      current: 14,
      total: 27,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_9_1 },
        { id: A.NO, linksTo: S.STEP_10 },
      ],
    },
    [S.STEP_9_1]: {
      content: t('s_9_1'),
      current: 15,
      total: 27,
      type: C.FREE_TEXT,
      linksTo: S.STEP_10,
    },
    [S.STEP_10]: {
      content: t('s_10'),
      current: 16,
      total: 27,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_11 },
        { id: A.NO, linksTo: S.STEP_11 },
      ],
    },
    [S.STEP_11]: {
      content: t('s_11'),
      current: 17,
      total: 27,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_12 },
        { id: A.NO, linksTo: S.STEP_12 },
      ],
    },
    [S.STEP_12]: {
      content: t('s_12'),
      current: 18,
      total: 27,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_13 },
        { id: A.NO, linksTo: S.STEP_13 },
      ],
    },
    [S.STEP_13]: {
      content: t('s_13'),
      current: 19,
      total: 27,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_14 },
        { id: A.NO, linksTo: S.STEP_14 },
      ],
    },
    [S.STEP_14]: {
      content: t('s_14'),
      current: 20,
      total: 27,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_14_1 },
        { id: A.NO, linksTo: S.STEP_15 },
      ],
      qa: [{ question: t('s_14_qa_q_1'), answer: t('s_14_qa_a_1') }],
    },
    [S.STEP_14_1]: {
      content: t('s_14_1'),
      current: 21,
      total: 27,
      type: C.FORM,
      linksTo: S.STEP_15,
      customNavigationButtons: true,
      smallSize: true,
      noFormBorder: true,
      formElements: [
        {
          type: F.NUMBERS,
          label: t('s_14_1_form_field_label_1'),
          required: true,
          span: 12,
          labelHidden: true,
        },
      ],
      qa: [{ question: t('s_14_qa_q_1'), answer: t('s_14_qa_a_1') }],
    },
    [S.STEP_15]: {
      content: t('s_15'),
      current: 22,
      total: 27,
      linksTo: S.STEP_16,
      type: C.FREE_TEXT,
    },
    [S.STEP_16]: {
      content: t('s_16'),
      current: 23,
      total: 27,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_16_1 },
        { id: A.NO, linksTo: S.STEP_17 },
      ],
    },
    [S.STEP_16_1]: {
      content: t('s_16_1'),
      current: 24,
      total: 27,
      linksTo: S.STEP_17,
      type: C.FREE_TEXT,
    },
    [S.STEP_17]: {
      content: t('s_17'),
      current: 25,
      total: 27,
      type: C.SINGLEANDMULTIPLE,
      linksTo: S.STEP_18,
      possibleAnswers: [
        { id: A.FREE_ROAMING },
        { id: A.LIKE_CHILDREN },
        { id: A.LIKE_OLD_PEOPLE },
        { id: A.LIKE_TO_BE_IN_CARS },
        { id: A.CAN_BE_TEMPORARILY_AT_HOME },
        {
          id: A.NOTHING_OF_ABOVE,
          isNormalChoice: true,
          isFamilyFailure: true,
        },
      ],
      qa: [{ question: t('s_17_qa_q_1'), answer: t('s_17_qa_a_1') }],
    },
    [S.STEP_18]: {
      content: t('s_18'),
      current: 26,
      total: 27,
      type: C.SINGLE,
      possibleAnswers: [
        { id: A.YES, linksTo: S.STEP_18_1 },
        { id: A.NO, linksTo: S.STEP_END },
      ],
    },
    [S.STEP_18_1]: {
      content: t('s_18_1'),
      current: 27,
      total: 27,
      type: C.FREE_TEXT,
      linksTo: S.STEP_END,
    },
    [S.STEP_END]: {
      content: t('s_end'),
      type: C.END,
    },
  };
};

export const getPetWatchingAgreementAnswers = (questionnaire, i18nTFunc) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);

  return {
    [A.SUBSTITUTE_AUTHORIZED_PERSON]: {
      content: t('a_substituteAuthorizedPerson'),
      image: icons.imgSubstituteAuthorizedPerson,
    },
    [A.SHELTER]: {
      content: t('a_shelter'),
      image: icons.imgShelter,
    },
    [A.LIABILITY_INSURANCE]: {
      content: t('a_liabilityInsurance'),
      image: icons.imgLiabilityInsurance,
    },
    [A.HEALTH_INSURANCE]: {
      content: t('a_healthInsurance'),
      image: icons.imgHealthInsurance,
    },
    [A.SURGERY_COST_INSURANCE]: {
      content: t('a_surgeryCostInsurance'),
      image: icons.imgSurgeryCostInsurance,
    },
    [A.OTHER_INSURANCE]: {
      content: t('a_otherInsurance'),
      image: icons.imgOtherInsurance,
    },
    [A.NOTHING_OF_ABOVE]: {
      content: t('a_nothingOfTheAbove'),
      image: icons.imgNothing,
    },
    [A.FREE_ROAMING]: {
      content: t('a_freeRoaming'),
      image: icons.imgFreeRoaming,
    },
    [A.LIKE_CHILDREN]: {
      content: t('a_likeChildren'),
      image: icons.imgLikeChildren,
    },
    [A.LIKE_OLD_PEOPLE]: {
      content: t('a_likeOldPeople'),
      image: icons.imgLikeOldPeople,
    },
    [A.LIKE_TO_BE_IN_CARS]: {
      content: t('a_likeToBeInCars'),
      image: icons.imgLikeToBeInCars,
    },
    [A.CAN_BE_TEMPORARILY_AT_HOME]: {
      content: t('a_canBeTemporarilyAtHome'),
      image: icons.imgCanBeTemporarilyAtHome,
    },
    [A.YES]: {
      content: t('a_true'),
      image: icons.imgYes,
    },
    [A.NO]: {
      content: t('a_false'),
      image: icons.imgNo,
    },
    [A.NONE]: {
      content: t('a_none'),
      image: icons.imgNo,
    },
  };
};

export const getPetWatchingAgreementStartScreen = (
  questionnaire,
  i18nTFunc
) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    listItems: [
      t('start_screen_list_1'),
      t('start_screen_list_2'),
      t('start_screen_list_3'),
    ],
  };
};
