import React from 'react';
import styled from 'styled-components';

import {
  dateHelper,
  getFullName,
  config,
  useTranslation,
} from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import { Checkbox } from 'modules/elements/components';

interface PartnerProfileEndProps {
  partner: Record<string, string>;
  className?: string;
}

PartnerProfileEnd.defaultProps = {
  className: undefined,
};

function PartnerProfileEnd(props: PartnerProfileEndProps) {
  const { partner, className } = props;
  const { t } = useTranslation(['common', 'mcontacts']);

  const contactName = getFullName(partner);
  if (!partner) return null;

  return (
    <Row style={{ marginTop: 10 }}>
      <Col>
        <div
          className={
            className ||
            'Card Card--shadow u-bg-white u-pt-25 u-pr-30 u-pb-25 u-pl-30'
          }
        >
          <div className="Character Character--human">
            <div className="Character-footer">
              <div className="u-w-100">
                <div className="Character-labelWrapper">
                  <p className="Typography Typography--subTitleH">
                    {contactName}
                  </p>
                </div>
                <p>
                  {dateHelper.convertDateFormat(
                    partner.birthday || '',
                    config.format.uiDashboardDate
                  )}
                </p>
                {partner.isSameAddress ? (
                  <StyledWrapper>
                    <Checkbox
                      text={t('will:partner_step_is_same_address')}
                      checked
                      onChange={() => null}
                    />
                  </StyledWrapper>
                ) : (
                  <>
                    <p>{partner.address}</p>
                    <p>{partner.city}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="u-pb-10" />
      </Col>
    </Row>
  );
}

const StyledWrapper = styled.div`
  margin-top: 5px;
  .Checkbox-caption {
    font-size: var(--font-size-md);
  }
`;

export default PartnerProfileEnd;
