import styled from 'styled-components';

const Input = styled.input.attrs((props) => ({ type: props.type || 'text' }))`
  background-color: ${({ bg }) => bg || 'white'};
  border-radius: ${({ borderRadius }) => borderRadius || '6px'};
  border: ${({ border }) => border || 'inherit'};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  height: 36px;
  line-height: 18px;
  margin: ${({ m }) => m || '0'};
  padding: ${({ p }) => p || '10px'};
  outline: none;
  width: ${({ width }) => width || '100%'};
  &:focus {
    outline: none;
  }
`;

export default Input;
