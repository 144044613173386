import { httpHelper, config } from 'modules/common/helpers';

import { CONSTANT_TYPES } from 'modules/common/constants/enums';

export default {
  getApiConstants,

  getCurrentUser,
  createTransmortalAuthorization,
  updateCurrentUser,
  setUserLanguage,
  passphraseOptOut,
  passphraseOptIn,
  generateSelfKeyDocument,
  getEstatePlanInfo,

  getPaymentApiKey,
  getSepaClientSecret,
  getPaymentSession,
  getBillingPortalUrl,
  addSepaSubscription,

  getContactsList,
  getContactById,
  addContact,
  editContact,
  deleteContact,

  updateTutorial,
  hideRelease,
  reportDeath,
  validateKeyHolder,

  generateContactAccessDocument,
  getCurrentBeneficiary,
  sendContactNotification,

  generateConfirmationToken,

  getContactOfList,
  getContactOf,
  getBackupContacts,
  updateBackupContact,
  removeBackupContact,
  getCouponInfo,

  getFamilyAccounts,
  createFamilyMember,
  reportDeathOfFamilyAccount,
  deleteFamilyAccount,

  checkEmailAvailability,
  changePrimaryEmail,
};

const BASE_URL = config.beUrl;
const GENERATOR_URL = config.documentGeneratorUrl;

function getApiConstants(type: CONSTANT_TYPES) {
  return httpHelper.get(`${BASE_URL}/api/constants?type=${type}`, {});
}

// * user services

function getCurrentUser() {
  return httpHelper.get(`${BASE_URL}/api/profile`, {});
}

function updateCurrentUser(userData: UserProfileDTO) {
  return httpHelper.put(`${BASE_URL}/api/profile`, { ...userData });
}

function setUserLanguage(language: string) {
  return httpHelper.put(`${BASE_URL}/api/profile/language`, { language });
}

function createTransmortalAuthorization() {
  return httpHelper.post(
    `${GENERATOR_URL}/generator/api/transmortal-authorization`,
    {}
  );
}

function passphraseOptOut(passphrase: string) {
  return httpHelper.put(`${BASE_URL}/api/user/passphrase-opt-out`, {
    passphrase,
  });
}

function passphraseOptIn(passphrase: string, code: string) {
  return httpHelper.put(`${BASE_URL}/api/user/passphrase-opt-in`, {
    passphrase,
    code,
  });
}

function generateSelfKeyDocument() {
  return httpHelper.post(`${GENERATOR_URL}/generator/api/security-key`, {});
}

function getEstatePlanInfo() {
  return httpHelper.post(`${GENERATOR_URL}/generator/api/estate-plan`, {});
}

// * payment services

function getPaymentApiKey() {
  return httpHelper.get(`${BASE_URL}/api/payment/api-key`, {});
}

function getPaymentSession(plan) {
  return httpHelper.post(`${BASE_URL}/api/payment/checkout`, { plan });
}

function addSepaSubscription(plan, coupon) {
  return httpHelper.post(`${BASE_URL}/api/payment/sepa-subscription`, {
    plan,
    coupon,
  });
}

function getSepaClientSecret() {
  return httpHelper.get(`${BASE_URL}/api/payment/sepa-setup-intent`, {});
}

function getBillingPortalUrl() {
  return httpHelper.get(`${BASE_URL}/api/payment/billing-portal`, {});
}

function updateTutorial(tutorial: TutorialField) {
  return httpHelper.put(`${BASE_URL}/api/tutorial`, { tutorial });
}

function hideRelease() {
  return httpHelper.put(`${BASE_URL}/api/hide-release`, {});
}

// * contacts services

function getContactsList() {
  return httpHelper.get(`${BASE_URL}/api/contacts`, {});
}

function getContactById(id: string) {
  return httpHelper.get(`${BASE_URL}/api/contact`, { id });
}

function addContact(contactData: ContactDTO, hideErrors = false) {
  return httpHelper.post(
    `${BASE_URL}/api/contact`,
    { ...contactData },
    undefined,
    hideErrors
  );
}

function editContact(contactData: ContactDTO, hideErrors = false) {
  return httpHelper.put(
    `${BASE_URL}/api/contact`,
    { ...contactData },
    undefined,
    hideErrors
  );
}

function deleteContact(id: string, code = '') {
  return httpHelper.delete(`${BASE_URL}/api/contact`, { id, code });
}

function reportDeath(data: FormData, hideErrors: boolean) {
  const headers = { 'Content-Type': 'multipart/form-data' };

  return httpHelper.post(`${BASE_URL}/incident`, data, { headers }, hideErrors);
}

function validateKeyHolder(
  data: { token: string; key: string },
  hideErrors: boolean
) {
  return httpHelper.post(
    `${BASE_URL}/key-holder/validate-key`,
    data,
    undefined,
    hideErrors
  );
}

function generateContactAccessDocument(id: string) {
  return httpHelper.post(`${GENERATOR_URL}/generator/api/beneficiary-access`, {
    id,
  });
}

function getCurrentBeneficiary() {
  return httpHelper.get(`${BASE_URL}/beneficiary/profile`, {});
}

function sendContactNotification(
  id: string,
  letterId = '',
  paymentToken = '',
  contactBy = '',
  hideErrors = false
) {
  return httpHelper.post(
    `${BASE_URL}/api/contact/notify`,
    { id, letterId, paymentToken, contactBy },
    undefined,
    hideErrors
  );
}

function generateConfirmationToken() {
  return httpHelper.get(`${BASE_URL}/api/user/action-confirmation`, {});
}

function getContactOfList() {
  return httpHelper.get(`${BASE_URL}/api/beneficiary-of`, {});
}

// get contactOf object with immediate access code
function getContactOf(code: number, id: string) {
  return httpHelper.post(`${BASE_URL}/api/beneficiary-of`, { code, id });
}

function getBackupContacts() {
  return httpHelper.get(`${BASE_URL}/api/backup-contacts`, {});
}

function updateBackupContact(id: string, period: number) {
  return httpHelper.put(`${BASE_URL}/api/backup-contact`, { id, period });
}

function removeBackupContact(id: string) {
  return httpHelper.delete(`${BASE_URL}/api/backup-contact`, { id });
}

function getCouponInfo(coupon: string) {
  return httpHelper.post(`${BASE_URL}/api/payment/coupon`, { coupon });
}

function getFamilyAccounts(hideErrors = false) {
  return httpHelper.get(
    `${BASE_URL}/api/contact/family`,
    {},
    undefined,
    hideErrors
  );
}

function createFamilyMember(id: string) {
  return httpHelper.post(`${BASE_URL}/api/contact/family`, { id });
}

function reportDeathOfFamilyAccount(id: string, code: string) {
  return httpHelper.post(`${BASE_URL}/api/contact/family/incident`, {
    id,
    code,
  });
}

function deleteFamilyAccount(id: string, code: string) {
  return httpHelper.delete(`${BASE_URL}/api/contact/family`, {
    id,
    code,
  });
}

function checkEmailAvailability(email: string, hideErrors = false) {
  return httpHelper.post(
    `${BASE_URL}/api/user/email-availability`,
    {
      email,
    },
    undefined,
    hideErrors
  );
}

function changePrimaryEmail(email: string, code: string) {
  return httpHelper.put(`${BASE_URL}/api/user/primary-email`, {
    email,
    code,
  });
}
