import React from 'react';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'modules/common/helpers';

import { Button, RadioCard } from 'modules/common/components';
import {
  SECURITY_SETTING_OPTIONS,
  SECURITY_SETTING_STEPS,
} from 'modules/securityKeyWizard/constants';

type Inputs = {
  selectSecuritySetting: SECURITY_SETTING_OPTIONS;
};

export interface SecuritySettingSelectionProps {
  onSelectSettingType: (settingType: string) => void;
}

function SecuritySettingSelection({
  onSelectSettingType,
}: SecuritySettingSelectionProps) {
  const { t } = useTranslation(['common', 'auth', 'securityKey']);
  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      selectSecuritySetting: SECURITY_SETTING_OPTIONS.STANDARD,
    },
  });

  const onSubmit = (formData) => {
    onSelectSettingType(
      formData[SECURITY_SETTING_STEPS.SELECT_SECURITY_SETTING]
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="t-p-0"
      data-testid="SecuritySettingSelection"
    >
      <h1 className="title t-font-semibold t-pb-7">
        {t('securityKey:security_choice_title')}
      </h1>

      <p>{t('securityKey:security_choice_description1')}</p>
      <br />
      <p className="t-pb-5">
        {t('securityKey:security_choice_description2')}
        <a
          href="mailto:info@ninebarc.com"
          className="hover:t-underline t-text-delta-700"
        >
          info@ninebarc.com
        </a>
      </p>

      <RadioCard
        register={register}
        name={SECURITY_SETTING_STEPS.SELECT_SECURITY_SETTING}
        value={SECURITY_SETTING_OPTIONS.STANDARD}
        id={SECURITY_SETTING_OPTIONS.STANDARD}
        className="t-px-8 t-py-6 t-mb-5"
      >
        <div>
          <div className="t-flex t-flex-col sm:t-flex-row sm:t-justify-between">
            <p className="typo-beta t-order-2 sm:t-order-1">
              {t('securityKey:security_standard_choice_title')}
            </p>
            <div className="t-flex t-justify-end t-order-1 sm:t-order-2">
              <span className="Character-label Character-label--accepted">
                {t('common:recommended')}
              </span>
            </div>
          </div>
          <p className="typo-epsilon t-text-beta-500 t-mt-2">
            {t('securityKey:security_standard_choice_description')}
          </p>
        </div>
      </RadioCard>

      <RadioCard
        register={register}
        name={SECURITY_SETTING_STEPS.SELECT_SECURITY_SETTING}
        value={SECURITY_SETTING_OPTIONS.SET_SECURITY_KEY}
        id={SECURITY_SETTING_OPTIONS.SET_SECURITY_KEY}
        className="t-px-8 t-py-6 t-mb-5"
      >
        <div>
          <p className="typo-beta">
            {t('securityKey:security_key_choice_title')}
          </p>
          <p className="typo-epsilon t-text-beta-500 t-mt-2">
            {t('securityKey:security_key_choice_description')}
          </p>
        </div>
      </RadioCard>

      <div className="t-flex t-justify-end t-pt-5 t-pb-16 sm:t-pb-0">
        <Button type="submit">
          <span className="t-px-3">{t('save_and_continue')}</span>
        </Button>
      </div>
    </form>
  );
}

export default SecuritySettingSelection;
