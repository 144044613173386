import React from 'react';
import { Trans } from 'react-i18next';

interface ClauseProps {
  item: string;
  description: string;
}

function Clause({ item, description }: ClauseProps) {
  return (
    <>
      <p className="Typography Typography--landingMain u-pb-10">{item}</p>
      <p className="Typography Typography--main u-pb-25">
        <Trans i18nKey={description} />
      </p>
    </>
  );
}

export default Clause;
