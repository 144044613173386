import React, { useState } from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import {
  generateStrongPassword,
  reactToast,
  useTranslation,
} from 'modules/common/helpers';

import { Form } from 'modules/bootstrap/components';
import { Button } from 'modules/common/components';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';

interface PasswordInputRefProps {
  name: string;
  setValue: any;
  watch: any;
  label?: string;
  defaultValue?: string;
  error?: string | false | null;
  disabled?: boolean;
  inputClass?: string;
  maxLength?: number;
  placeholder?: string;
  autoComplete?: boolean;
  id?: string;
  info?: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  labelHidden?: boolean;
  dataTestId?: string;
  wrapperClassName?: string;
  withPasswordGenerator?: boolean;
}

const PasswordInputRef = React.forwardRef<
  HTMLInputElement,
  PasswordInputRefProps
>(
  (
    {
      name,
      setValue,
      watch,
      label,
      defaultValue,
      error,
      disabled,
      inputClass,
      maxLength,
      placeholder,
      autoComplete,
      id,
      info,
      onBlur,
      labelHidden,
      wrapperClassName,
      dataTestId,
      withPasswordGenerator,
      ...restProps
    },
    ref
  ) => {
    const { t } = useTranslation(['common']);

    const [isPwVisible, setIsPwVisible] = useState(false);
    const [generatedPassword, setGeneratedPassword] = useState('');

    const wrapperClass = classnames({
      'Form-group': true,
      isErrored: error && error.length > 0,
    });

    const inputType = isPwVisible ? 'text' : 'password';

    const compProps = {
      id: id || name,
      name,
      defaultValue,
      placeholder,
      onBlur,
      'data-testid': dataTestId || name,
      className: `Field ${inputClass} ${
        error ? 'isErrored' : ''
      } Field--password`,
      readOnly: disabled,
      autoComplete: autoComplete ? '' : 'new-password',
      ...restProps,
    };

    const currentInput = watch('password') || '';

    React.useEffect(() => {
      if (!!generatedPassword && currentInput !== generatedPassword) {
        setGeneratedPassword('');
      }
    }, [currentInput, generatedPassword]);

    const generatePassword = () => {
      const generatedPassword = generateStrongPassword(15);
      setValue(name, generatedPassword);
      setIsPwVisible(true);
      setGeneratedPassword(generatedPassword);
      navigator.clipboard.writeText(generatedPassword);
      reactToast.showMessage(
        t('common:generated_password_copied_to_clipboard'),
        generatedPassword
      );
    };

    return (
      <div className={`${wrapperClass} ${wrapperClassName || ''}`}>
        <div className="t-flex">
          {label && !labelHidden && (
            <Form.Label
              htmlFor={name}
              className={`Form-label ${error ? 'isErrored' : ''}`}
            >
              {label}
            </Form.Label>
          )}

          {!!info && (
            <div>
              <IconInfo
                role="presentation"
                className="t-inline t-text-delta-700 t-ml-2"
                data-tip={info}
                data-for={label || 'PasswordInputRef'}
              />
              <ReactTooltip
                id={label || 'PasswordInputRef'}
                effect="solid"
                place="bottom"
                multiline
              />
            </div>
          )}
        </div>

        <input
          ref={ref}
          {...compProps}
          maxLength={maxLength}
          type={inputType}
        />

        <div className="Form-passwordIcon">
          <button
            type="button"
            className="Form-passwordIconButton"
            onClick={() => {
              setIsPwVisible((prev) => !prev);
            }}
          >
            <span className="ScreenReaderOnly">Show password</span>
          </button>
        </div>

        {error && (
          <div className="Form-alert">
            <span className="text-main-sm t-text-epsilon-600">{error}</span>
          </div>
        )}

        {withPasswordGenerator && (
          <div className="t-mt-1.5">
            <p className="typo-epsilon t-text-delta-700">
              {generatedPassword
                ? t('common:password_generated_successfully_text')
                : t('common:password_generation_text')}
            </p>

            <Button
              category="secondary"
              className="t-mt-1.5"
              onClick={generatePassword}
            >
              {t('common:password_generation_btn')}
            </Button>
          </div>
        )}
      </div>
    );
  }
);

PasswordInputRef.displayName = 'PasswordInputRef';

export default PasswordInputRef;
