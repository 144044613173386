import React from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { format, subDays } from 'date-fns';

import { getAssetTypeByAssetTypeName } from 'store/actions/assetTemplateActions';
import { updateRecommendation } from 'store/actions/recommendationsActions';

import {
  Button,
  DatePicker,
  Modal,
  TextWithHyphenSpan,
} from 'modules/common/components';
import { Dropdown } from 'modules/bootstrap/components';
import { AssetForm } from 'modules/asset/components';
import { useTranslation } from 'modules/common/helpers';

import { LANGUAGES, LOCAL_STORAGE_VARS } from 'modules/common/constants/enums';

import { ReactComponent as IconCross } from 'images/cross.svg';
import { ReactComponent as IconBell } from 'images/icon_notification_bell.svg';
import { ReactComponent as IconDelete } from 'images/icon-delete.svg';

interface SuggestedAssetTypeCardProps {
  recommendation: RecommendationDTO;
}

const SuggestedAssetTypeCard = ({
  recommendation,
}: SuggestedAssetTypeCardProps) => {
  const { t } = useTranslation(['common', 'plan']);
  const dispatch = useDispatch();

  const [reminderOpen, setReminderOpen] = React.useState(false);
  const [newReminder, setNewReminder] = React.useState(new Date());
  const [dateToSubmit, setDateToSubmit] = React.useState('');
  const [savedAssetType, setSavedAssetType] = React.useState(
    {} as AssetTypeDTO
  );
  const [loading, setLoading] = React.useState(false);

  const onDismiss = async () => {
    await dispatch(updateRecommendation({ ...recommendation, visible: false }));
  };

  const onSetReminder = () => {
    setReminderOpen(true);
  };

  const handleReminderChange = (val) => {
    setNewReminder(val);
    const formattedDate = format(new Date(val), 'yyyy-MM-dd');
    setDateToSubmit(formattedDate);
  };

  const handleReminderSubmit = async () => {
    await dispatch(
      updateRecommendation({ ...recommendation, reminder: dateToSubmit })
    );
    setReminderOpen(false);
  };

  const handleAddAsset = async () => {
    setLoading(true);
    const assetType: any = await dispatch(
      getAssetTypeByAssetTypeName(
        recommendation.category!,
        recommendation.assetType!
      )
    );
    if (assetType && !isEmpty(assetType)) {
      setSavedAssetType(assetType as AssetTypeDTO);
    }
    setLoading(false);
  };

  const handleCloseAssetFormModal = () => {
    setSavedAssetType({} as AssetTypeDTO);
  };

  const currentLanguage =
    localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE) || LANGUAGES.DE;

  const customAddAssetLabelEn = `${t(
    'plan:add_prefix_asset_form_modal_title'
  )} ${t(savedAssetType?.label).toLowerCase() || ''}`;

  const customAddAssetLabelDe = `${t(savedAssetType?.label) || ''} ${t(
    'common:add_new'
  ).toLowerCase()}`;

  return (
    <>
      {!isEmpty(savedAssetType) && (
        <Modal show>
          <h1 className="typo-alpha t-mt-5 t-mb-3">
            {currentLanguage === LANGUAGES.DE
              ? customAddAssetLabelDe
              : customAddAssetLabelEn}
          </h1>
          <AssetForm
            assetType={savedAssetType}
            assetCategory={recommendation.category || ''}
            onHideForm={handleCloseAssetFormModal}
            preselectedSubtype={recommendation.subtype || ''}
            recommendation={recommendation}
          />
        </Modal>
      )}
      <div className="card t-p-5 t-h-full">
        <div className="Character-menu t-relative">
          <Dropdown className={`dropdown--settings`}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-settings"
              disabled={reminderOpen}
              className="t-py-1"
            >
              <IconCross className="t-text-beta-400" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="t-m-0">
              <Dropdown.Item onClick={onSetReminder} disabled={reminderOpen}>
                <IconBell className="dropdown-icon t-text-alpha-200 t-left-4" />
                <span className="typo-epsilon">{t('plan:set_a_reminder')}</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={onDismiss}>
                <IconDelete className="dropdown-icon t-text-epsilon-400 t-left-3" />
                <span className="typo-epsilon">{t('common:delete_label')}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {reminderOpen && (
            <DatePicker
              date={newReminder}
              minDate={subDays(new Date(), -1)}
              onSubmit={handleReminderSubmit}
              onChange={(val) => handleReminderChange(val)}
              onClose={() => setReminderOpen(false)}
            />
          )}
        </div>

        <div className="t-h-full t-flex t-flex-col t-justify-between">
          <div>
            <h2 className="typo-beta t-text-delta-700 t-mr-7">
              <TextWithHyphenSpan>
                {t(`plan:${recommendation.assetType || ''}_name`)}
              </TextWithHyphenSpan>
            </h2>
            {recommendation.subtype && (
              <h3 className="typo-delta t-text-delta-700 t-mt-1">
                <TextWithHyphenSpan>
                  {t(`subtypes:${recommendation.subtype}`)}
                </TextWithHyphenSpan>
              </h3>
            )}
            <p className="typo-epsilon t-mt-4">
              {t(`plan:${recommendation.assetType || ''}_description`)}
            </p>
          </div>
          <div className="t-mt-5 t-flex t-justify-end">
            <Button onClick={handleAddAsset} loading={loading}>
              {t('common:add')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuggestedAssetTypeCard;
