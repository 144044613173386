import helper from 'store/actions/actionHelper';
import { nextStepService } from 'modules/dashboard/services';
import { GET_NEXT_STEPS } from 'store/types/nextStepTypes';

export const getNextSteps = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const nextSteps = await nextStepService.getNextSteps();
    dispatch(helper.successAction(GET_NEXT_STEPS, { nextSteps }));
  }, actionOptions);
};

export const skipStep = (step: string, actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const modifiedNextSteps = await nextStepService.skipStep(step);
    dispatch(
      helper.successAction(GET_NEXT_STEPS, { nextSteps: modifiedNextSteps })
    );
  }, actionOptions);
};
