import { httpHelper, config } from 'modules/common/helpers';

const BASE_URL = config.beUrl;

function getPlanProgress() {
  return httpHelper.get(`${BASE_URL}/api/progress`, {});
}

function setPlanIntroDone() {
  return httpHelper.post(`${BASE_URL}/api/introduction`, {});
}

function togglePlanStepStatus(category: string, status: number) {
  return httpHelper.put(`${BASE_URL}/api/progress`, { category, status });
}

function sendLetter(paymentToken: string, id: string) {
  return httpHelper.post(`${BASE_URL}/api/letter/send`, {
    paymentToken,
    id,
  });
}

function createLetter(data: PostalInfo) {
  return httpHelper.post(`${BASE_URL}/api/letter`, data);
}

function deleteLetter(id: string) {
  return httpHelper.delete(`${BASE_URL}/api/letter`, { id });
}

export default {
  getPlanProgress,
  setPlanIntroDone,
  togglePlanStepStatus,
  sendLetter,
  createLetter,
  deleteLetter,
};
