import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { Modal } from 'modules/common/components';
import { PostalContactFormWrapper } from 'modules/contact/components';
import {
  AddContactInfoModal,
  ProfileIncompleteProcess,
} from 'modules/dashboard/components';

const STEP_INFO = 'stepInfo';
const STEP_FORM = 'stepForm';

interface AddContactsProcessProps {
  onCancel: () => void;
  onSuccess?: () => void;
  limited?: boolean;
}

const AddContactsProcess = ({
  onCancel,
  onSuccess,
  limited,
}: AddContactsProcessProps) => {
  const { t } = useTranslation();

  const [step, setStep] = React.useState(STEP_INFO);
  const [isModalOverlaid, setIsModalOverlaid] = React.useState(false);
  const [showProfileForm, setShowProfileForm] = React.useState(false);

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const handleNextStep = () => {
    if (step === STEP_INFO && !currentUser.profile) {
      setShowProfileForm(true);
      return undefined;
    }
    setStep(STEP_FORM);
  };

  const handleSuccess = () => {
    if (onSuccess) onSuccess();
    onCancel();
  };

  // this handles the case when there is another modal opened on top of this one - eg. passphrase modal
  const handleModalOverlay = (overlaid: boolean) => {
    setIsModalOverlaid(overlaid);
  };

  const handleSuccessfulProfileUpdate = () => {
    setStep(STEP_FORM);
  };

  const handleHideProfileModal = () => {
    setShowProfileForm(false);
  };

  return (
    <>
      {showProfileForm && (
        <Modal show onHide={handleHideProfileModal} hasCloseCross>
          <ProfileIncompleteProcess
            isProfilePage={false}
            onClose={handleHideProfileModal}
            onSuccess={handleSuccessfulProfileUpdate}
            profile={currentUser}
            profileFormTitle={t(
              `mcontacts:profile_not_complete_contact_form_title`
            )}
          />
        </Modal>
      )}

      {step === STEP_INFO && !showProfileForm && (
        <AddContactInfoModal
          onCancel={onCancel}
          handleNextStep={handleNextStep}
        />
      )}

      {step === STEP_FORM && (
        <PostalContactFormWrapper
          onClose={onCancel}
          isContactModal
          isModalOverlaid={isModalOverlaid}
          handleModalOverlay={handleModalOverlay}
          onSuccess={handleSuccess}
          limited={limited}
        >
          {!isModalOverlaid && (
            <h1 className="typo-alpha t-mb-5">
              {t('dashboard:my_contacts_widget_contact_modal_title')}
            </h1>
          )}
        </PostalContactFormWrapper>
      )}
    </>
  );
};

export default AddContactsProcess;
