import { saveAs } from 'file-saver';
import i18n from 'i18next';

import dataService from 'modules/common/services/dataService';
import { assetService } from 'modules/estatePlan/services';
import {
  LOAD_DOCUMENTS_LIST,
  DELETE_ASSET,
  DOWNLOAD_ASSET,
  DELETE_DOCUMENT,
  ADD_ASSET,
  EDIT_ASSET,
  NOT_RELEVANT_ASSET,
} from 'store/types/documentActionTypes';
import {
  config,
  getBlobObj,
  getSuccessMessage,
  reactToast,
} from 'modules/common/helpers';
import helper from 'store/actions/actionHelper';
import stubbedDocuments from 'stubs/documents.json';
import { CONSTANT_TYPES } from 'modules/common/constants/enums';

export const getDocumentsListSuccess = (documents: AssetsDTO) =>
  helper.getAction(LOAD_DOCUMENTS_LIST, { documents });

const deleteAssetSuccess = (
  id: string,
  assetCategory: string,
  assetType: string
) => helper.getAction(DELETE_ASSET, { id, assetCategory, assetType });

const deleteDocumentSuccess = (
  id: string,
  assetId: string,
  assetCategory: string
) => helper.getAction(DELETE_DOCUMENT, { id, assetId, assetCategory });
const downloadAssetSuccess = (id: string) =>
  helper.getAction(DOWNLOAD_ASSET, { id });
const addCommonAssetSuccess = (assetData: AssetDTO) =>
  helper.getAction(ADD_ASSET, { assetData });
const editCommonAssetSuccess = (assetData: AssetDTO) =>
  helper.getAction(EDIT_ASSET, { assetData });
const doneCommonAssetSuccess = (assetData: AssetDTO) =>
  helper.getAction(NOT_RELEVANT_ASSET, { assetData });

// * Side-effects

export const getApiConstants = (
  type: CONSTANT_TYPES,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const constantsData = await dataService.getApiConstants(type);

    return constantsData;
  }, actionOptions);
};

export const getAssetsList = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const documents = await assetService.getAssetsList();

    if (config.isStubsData) {
      dispatch(getDocumentsListSuccess(stubbedDocuments));
    } else {
      dispatch(getDocumentsListSuccess(documents));
    }
  }, actionOptions);
};

export const getAsset = (
  id: string,
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await assetService.getAsset(id, hideErrors);

    return response;
  }, actionOptions);
};

export const deleteAsset = (
  id: string,
  assetCategory: string,
  assetType: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const response = await assetService.deleteAsset(id);
    dispatch(deleteAssetSuccess(id, assetCategory, assetType));
    reactToast.showMessage(getSuccessMessage(response.message));

    return true;
  }, actionOptions);
};

export const downloadAsset = (
  document: DocumentDTO,
  actionOptions?: ActionOptions
) => {
  const { id, documentTitle } = document;

  if (!id) return false;

  return helper.dispatchAsyncAction(async (dispatch) => {
    const data: { id: string; passphrase?: string } = { id };

    const response: any = await assetService.downloadAsset(data);

    dispatch(downloadAssetSuccess(id));
    reactToast.showMessage(
      `${documentTitle} ${i18n.t('common:download_successful')}`
    );

    const blobObj = getBlobObj(response);

    const fileUrl = URL.createObjectURL(blobObj);

    saveAs(blobObj, documentTitle);

    window.open(fileUrl);

    return response;
  }, actionOptions);
};

export const deleteDocument = (
  id: string,
  assetId,
  assetCategory: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const response = await assetService.deleteDocument(id);
    dispatch(deleteDocumentSuccess(id, assetId, assetCategory));
    reactToast.showMessage(getSuccessMessage(response.message));

    return true;
  }, actionOptions);
};

export const addAsset = (
  assetData: AssetDTO,
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const newAsset = await assetService.addAsset(assetData, hideErrors);
    dispatch(addCommonAssetSuccess(newAsset));
    reactToast.showMessage(i18n.t('toastr:asset_added_success'));

    return true;
  }, actionOptions);
};

export const uploadToNewAsset = (
  data: FormData,
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const newAsset = await assetService.uploadToNewAsset(data, hideErrors);
    await dispatch(addCommonAssetSuccess(newAsset));

    reactToast.showMessage(i18n.t('toastr:asset_added_success'));

    return newAsset;
  }, actionOptions);
};

export const editAsset = (
  assetData: AssetDTO,
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const alteredAsset = await assetService.editAsset(assetData, hideErrors);

    reactToast.showMessage(i18n.t('toastr:asset_updated_success'));

    dispatch(editCommonAssetSuccess(alteredAsset));

    return true;
  }, actionOptions);
};

export const uploadToAsset = (
  data: FormData,
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const alteredAsset = await assetService.uploadToAsset(data, hideErrors);
    await dispatch(editCommonAssetSuccess(alteredAsset));
    reactToast.showMessage(
      i18n.t('toastr:entity_attached_success', {
        entity:
          alteredAsset.documents[alteredAsset.documents.length - 1]
            .documentTitle,
      })
    );

    return alteredAsset;
  }, actionOptions);
};

export const doneAsset = (
  category: string,
  type: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncActionWithProgress(async (dispatch) => {
    const notRelevantAsset = await assetService.doneAsset(category, type);
    dispatch(doneCommonAssetSuccess(notRelevantAsset));
    reactToast.showMessage(i18n.t('toastr:not_relevant_asset_success'));

    return true;
  }, actionOptions);
};

export const downloadBeneficiaryDocument = (
  document: DocumentDTO,
  actionOptions?: ActionOptions
) => {
  const { id, documentTitle } = document;

  if (!id) return false;

  return helper.dispatchAsyncAction(async () => {
    const data: { id: string } = { id };
    const response: any = await assetService.downloadBeneficiaryDocument(data);

    reactToast.showMessage(
      `${documentTitle} ${i18n.t('common:download_successful')}`
    );

    const blobObj = getBlobObj(response);

    const fileUrl = URL.createObjectURL(blobObj);

    saveAs(blobObj, documentTitle);

    window.open(fileUrl);

    return response;
  }, actionOptions);
};

export const downloadSharedDocument = (
  { id, documentTitle }: DocumentDTO,
  actionOptions?: ActionOptions
) => {
  if (!id) return false;

  return helper.dispatchAsyncAction(async () => {
    const data: { id: string } = { id };
    const response: any = await assetService.downloadSharedDocument(data);

    reactToast.showMessage(
      `${documentTitle} ${i18n.t('common:download_successful')}`
    );

    const blobObj = getBlobObj(response);

    const fileUrl = URL.createObjectURL(blobObj);

    saveAs(blobObj, documentTitle);

    window.open(fileUrl);

    return response;
  }, actionOptions);
};
