import Cookies from 'js-cookie';

import { config, httpHelper, getPhoneWithPlus } from 'modules/common/helpers';

export default {
  signUp,
  login,
  logout,
  passwordForgot,
  resetPasswordTokenCheck,
  resetPassword,
  validateEmail,
  getToken,
  saveToken,
  changePassword,
  upgradeProfile,
  deleteProfile,
  generateSMSCode,
  turnOnSmsTwoAuth,
  turnOffSmsTwoAuth,
  optOutTwoAuth,
  turnOffSmsTwoAuthConfirm,
  smsTwoAuthAuthenticate,
  generateQRCode,
  turnOnGoogleTwoAuth,
  turnOffGoogleTwoAuth,
  googleTwoAuthAuthenticate,
  getAllowedToken,
  changePassphrase,
  access,
  refreshToken,
  getRefreshToken,
  saveRefreshToken,
  loginUsingRefreshToken,
  verifyAndLogin,
  switchAccount,
};

const BASE_URL = config.beUrl;

function signUp(user) {
  return httpHelper.post(`${BASE_URL}/auth/register`, user, undefined, true);
}

function changePassword(passwordData) {
  return httpHelper.put(
    `${BASE_URL}/api/user/password`,
    passwordData,
    undefined,
    true
  );
}

function login(user) {
  return httpHelper.post(`${BASE_URL}/auth/login`, user);
}

function logout() {
  return httpHelper.post(`${BASE_URL}/auth/logout`, {});
}

function passwordForgot(email: string) {
  return httpHelper.post(`${BASE_URL}/auth/recover`, { email });
}

function resetPasswordTokenCheck(token: string) {
  return httpHelper.get(`${BASE_URL}/auth/reset/${token}`, {});
}

function resetPassword(userData, token: string) {
  return httpHelper.post(`${BASE_URL}/auth/reset/${token}`, userData);
}

function validateEmail() {
  return httpHelper.get(`${BASE_URL}/api/validate-email`, {});
}

function upgradeProfile() {
  return httpHelper.post(`${BASE_URL}/api/user/upgrade`, {});
}

function deleteProfile(hard: boolean, code: number) {
  return httpHelper.delete(`${BASE_URL}/api/user`, { hard, code });
}

function refreshToken(refreshToken: string) {
  return httpHelper.post(`${BASE_URL}/auth/refresh`, { refreshToken });
}

function getToken() {
  const token = Cookies.get('jwt_token');
  return token;
}

function saveToken(jwt: string) {
  Cookies.set('jwt_token', jwt);
}

function getRefreshToken() {
  const token = Cookies.get('jwt_refresh_token');
  return token;
}

function saveRefreshToken(jwt: string) {
  Cookies.set('jwt_refresh_token', jwt);
}

function generateSMSCode(phone: string) {
  return httpHelper.post(`${BASE_URL}/auth/tfa/sms/generate`, {
    phone: getPhoneWithPlus(phone),
  });
}

function turnOnSmsTwoAuth(smsTfaCode: number | string) {
  return httpHelper.post<TwoFASuccessResponse>(
    `${BASE_URL}/auth/tfa/sms/turn-on`,
    { smsTfaCode }
  );
}

function turnOffSmsTwoAuth() {
  return httpHelper.post(`${BASE_URL}/auth/tfa/sms/turn-off`, {});
}

function turnOffSmsTwoAuthConfirm(smsTfaCode: number | string) {
  return httpHelper.post(`${BASE_URL}/auth/tfa/sms/turn-off/confirm`, {
    smsTfaCode,
  });
}

function optOutTwoAuth() {
  return httpHelper.post(`${BASE_URL}/api/tfa-opt-out`, {});
}

function smsTwoAuthAuthenticate(
  smsTfaCode: number | string,
  token: string | undefined
) {
  const headerModifier = getHttpHeaderWithToken(token);

  return httpHelper.post<TwoFASuccessResponse>(
    `${BASE_URL}/auth/tfa/sms/authenticate`,
    {
      smsTfaCode,
      extendedLogin: true,
    },
    headerModifier
  );
}

function generateQRCode() {
  return httpHelper.post(`${BASE_URL}/auth/tfa/generate`, {});
}

function turnOnGoogleTwoAuth(tfaCode: string | number) {
  return httpHelper.post<TwoFASuccessResponse>(`${BASE_URL}/auth/tfa/turn-on`, {
    tfaCode,
  });
}

function turnOffGoogleTwoAuth(tfaCode: string | number) {
  return httpHelper.post(`${BASE_URL}/auth/tfa/turn-off`, { tfaCode });
}

function googleTwoAuthAuthenticate(
  tfaCode: number | string,
  token: string | undefined
) {
  const headerModifier = getHttpHeaderWithToken(token);

  return httpHelper.post<TwoFASuccessResponse>(
    `${BASE_URL}/auth/tfa/authenticate`,
    { tfaCode, extendedLogin: true },
    headerModifier
  );
}

function getAllowedToken(passphrase: string) {
  return httpHelper.post<TwoFASuccessResponse>(`${BASE_URL}/api/user/allow`, {
    passphrase,
  });
}

function changePassphrase(oldPassphrase: string, newPassphrase: string) {
  return httpHelper.put(`${BASE_URL}/api/user/passphrase`, {
    oldPassphrase,
    newPassphrase,
  });
}

type HttpHeaderWithToken = {
  headers?: {
    Authorization?: string;
  };
};

function getHttpHeaderWithToken(
  token: string | undefined
): HttpHeaderWithToken {
  return token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : {};
}

function access(beneficiary) {
  return httpHelper.post(`${BASE_URL}/auth/beneficiary/login`, beneficiary);
}

function loginUsingRefreshToken(refreshToken: string, email: string) {
  return httpHelper.post(`${BASE_URL}/auth/login/token`, {
    token: refreshToken,
    email,
  });
}

function verifyAndLogin(token: string) {
  return httpHelper.post(`${BASE_URL}/auth/verify-login/token`, {
    token,
  });
}

function switchAccount(id: string) {
  return httpHelper.post(`${BASE_URL}/auth/family/login`, {
    id,
  });
}
