import produce from 'immer';

import initialState from 'store/reducers/initialState';
import { GET_SHARED_ASSETS } from 'store/types/assetTypes';

const immediateAssetsReducer = (
  state = initialState.immediateAssets,
  action: ReducerAction
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_SHARED_ASSETS: {
        const {
          payload: { sharedAssets },
        } = action;
        draft = sharedAssets;

        return draft;
      }
      default:
        return draft;
    }
  });

export default immediateAssetsReducer;
