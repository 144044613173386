import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

import { getFullName, useTranslation } from 'modules/common/helpers';
import { Card, P } from 'modules/elements/components';

export interface SubstituteHeirCardProps {
  heir: ContactDTO;
  handleInputChange: (Event, number) => void;
  previousValue: string;
}

const Styles = styled.div`
  img {
    border-radius: 50%;
    width: 56px;
    height: 56px;
  }
  .nameCol {
    align-self: center;
    text-align: left;
    font-family: var(--font-family-primary);
  }
`;

const SubstituteHeirCard = ({
  heir,
  handleInputChange,
  previousValue,
}: SubstituteHeirCardProps) => {
  const { t } = useTranslation(['common', 'will']);

  const fullName = getFullName(heir);

  return (
    <Styles>
      <Card p="20px 30px">
        <Row>
          <Col md={5} className="nameCol xs:t-mb-5 md:t-mb-0">
            <p className="t-text-lg">{fullName}</p>
            <p>{heir.birthday}</p>
          </Col>
          <Col xs={12} md={7}>
            <div>
              <label htmlFor={`${heir.id} substituteInput`}>
                <P
                  small
                  fontFamily="var(--font-family-primary)"
                  color="var(--color-beta-500)"
                >
                  {t('will:substitute_heir_input_label')}
                </P>
              </label>
            </div>
            <div>
              <input
                className="Field"
                onChange={(e) => handleInputChange(e, heir.id)}
                id={`${heir.id} substituteInput`}
                defaultValue={previousValue}
                placeholder={`${t(
                  'will:substitute_heir_input_placeholder'
                )} ${fullName}`}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </Styles>
  );
};

export default SubstituteHeirCard;
