import React from 'react';

import { getFullName, useTranslation } from 'modules/common/helpers';
import { KeyHolderForm } from 'modules/securityKeyWizard/components';

import { ReactComponent as RevokeIcon } from 'images/icon-person-revoke.svg';
import { ReactComponent as EmailIcon } from 'images/mail-icon.svg';
import { ReactComponent as PhoneIcon } from 'images/phone-icon.svg';

interface KeyHolderCardProps {
  keyHolders: KeyHolder[];
  keyHolder: KeyHolder;
  onDelete: (id: string) => void;
  onToggleParent: (hide: boolean) => void;
  whileEditing: (editing: boolean) => void;
}

const KeyHolderCard = ({
  keyHolders,
  keyHolder,
  onDelete,
  onToggleParent,
  whileEditing,
}: KeyHolderCardProps) => {
  const { t } = useTranslation(['securityKey', 'common']);

  const { email, name, surname, phone, gender, id } = keyHolder;
  const [editing, setEditing] = React.useState(false);

  const onEditKeyHolder = () => {
    setEditing(true);
    whileEditing(true);
  };

  const onUpdateSuccess = () => {
    handleEditingClose();
  };

  const handleEditingClose = () => {
    setEditing(false);
    whileEditing(false);
  };

  return (
    <>
      {editing && (
        <KeyHolderForm
          onSubmitSuccess={onUpdateSuccess}
          keyHolders={keyHolders}
          data={keyHolder}
          onToggleParent={onToggleParent}
          onClose={handleEditingClose}
        />
      )}
      {!editing && (
        <div className="card t-p-6 t-mb-4 t-relative">
          <p className="typo-beta">{getFullName({ name, surname, gender })}</p>
          <p className="typo-delta t-mt-3 t-text-delta-700 t-flex t-items-center">
            <EmailIcon className="t-mr-2 t-text-beta-500" />
            {email}
          </p>
          {phone && (
            <p className="t-mt-2.5">
              <div className="t-flex t-items-center t-mb-1">
                <PhoneIcon className="t-mr-3 t-text-beta-500" />
                <p className="typo-epsilon">+{phone.replace('+', '')}</p>
              </div>
            </p>
          )}

          <div className="t-flex t-mt-4">
            <button
              className="button-tertiary t-whitespace-nowrap t-mr-4"
              onClick={onEditKeyHolder}
            >
              {t('common:edit')}
            </button>
            <button
              type="button"
              className="button t-bg-eta-100 t-text-zeta-600 t-flex t-items-center"
              onClick={() => {
                onDelete(id!);
              }}
            >
              <RevokeIcon className="t-mr-3" />
              <span className="t-whitespace-nowrap">
                {t('securityKey:key_holder_card_revoke_button')}
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default KeyHolderCard;
