import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { SiteLanguage } from 'modules/common/components';
import { Col, Row } from 'modules/bootstrap/components';
import {
  AuthFooter,
  ClickableLogo,
  LoginFooter,
} from 'modules/auth/components';
import {
  IncidentReportForm,
  IncidentReportSuccess,
} from 'modules/afterDeathManagement/components';

import imgRectangle from 'images/img-auth-rectangle.png';

const DeathReportPage = () => {
  const [reported, setReported] = useState(false);

  const onSuccess = () => {
    setReported(true);
  };

  return (
    <>
      <ReactTooltip effect="solid" place="top" />
      <Row
        className="lg:t-p-0 t-min-h-screen t-w-full t-bg-alpha-200 t-m-0 t-relative"
        data-testid="DeathReportPage"
      >
        <Col
          md={{ span: 6, offset: 3 }}
          lg={{ span: 10, offset: 1 }}
          className="t-p-0 t-z-40"
        >
          <div className="t-hidden md:t-flex t-justify-between t-mt-6 lg:t-mt-10 t-px-3">
            <ClickableLogo />
            <div className="t-flex t-items-center">
              <div className="t-hidden lg:t-inline-block">
                <AuthFooter />
              </div>
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>
          </div>

          <div className="md:t-flex t-flex-col t-items-center t-mt-4 lg:t-mt-16 t-px-3 t-mb-16">
            <div className="md:t-hidden t-flex t-justify-between">
              <ClickableLogo />
              <SiteLanguage additionalClass="t-pb-0 t-ml-6" />
            </div>

            {reported ? (
              <IncidentReportSuccess />
            ) : (
              <IncidentReportForm onSuccess={onSuccess} />
            )}

            <div className="lg:t-hidden t-mt-16">
              <AuthFooter />
            </div>
          </div>

          <div className="t-absolute t-bottom-0 t-w-full t-flex t-justify-center md:t-inline">
            <LoginFooter />
          </div>
        </Col>
      </Row>
      <div className="t-fixed t-w-screen t-object-cover t-h-3/4 t-bottom-0">
        <img
          src={imgRectangle}
          className="t-w-full t-h-full"
          alt="Rectangle background"
        />
      </div>
    </>
  );
};

export default DeathReportPage;
