import React, { ReactNode } from 'react';

import { ReactComponent as ArrowDown } from 'images/chevrone-down.svg';

type AnswersAccordionQuestionProps = {
  children: string | ReactNode;
  selected: boolean;
};

const AnswersAccordionQuestion = ({
  children,
  selected,
}: AnswersAccordionQuestionProps) => {
  return (
    <div className="AnswersAccordion-question d-flex align-items-center justify-content-between u-pb-10">
      <span>{children}</span>
      <ArrowDown
        className={`t-text-alpha-600 ${
          selected ? 'AnswersAccordion-question--chevroneUp' : ''
        }`}
      />
    </div>
  );
};

export default AnswersAccordionQuestion;
