import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { RootState } from 'store/reducers';
import { getFullName, useTranslation } from 'modules/common/helpers';
import { Col, Row } from 'modules/bootstrap/components';

interface SubstituteHeirEndProps {
  substituteHeirs: Record<string, string>[];
}

function SubstituteHeirEnd({ substituteHeirs }: SubstituteHeirEndProps) {
  const { t } = useTranslation(['common', 'will']);
  const heirs = useSelector((state: RootState) => state.heirs.list) || [];

  return (
    <Row style={{ marginTop: 10 }}>
      {substituteHeirs.map((subHeir) => {
        const heir = _.find(heirs, (h) => h.id === subHeir.heirId);
        const contactName = getFullName(heir);
        return (
          <Col sm={10} md={5} key={subHeir.heirId}>
            <div className="Card Card--shadow u-bg-white u-pt-25 u-pr-30 u-pb-25 u-pl-30">
              <div className="Character Character--human">
                <div className="Character-footer">
                  <div className="u-w-100">
                    <div className="Character-labelWrapper">
                      <p className="Typography Typography--subTitleH">
                        {contactName}
                      </p>
                    </div>
                    <p className="t-text-xs t-mb-1">
                      {t('will:endScreenSubstituteHeirs')}
                    </p>
                    <p className="Typography Typography--subTitleH">
                      {subHeir.substituteHeir}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="u-pb-10" />
          </Col>
        );
      })}
    </Row>
  );
}

export default SubstituteHeirEnd;
