import { assetService } from 'modules/estatePlan/services';
import { FETCH_PROVIDER_BY_TYPE } from 'store/types/assetTypes';

import helper from 'store/actions/actionHelper';
import { PROVIDER_CONSTANT_TYPES } from 'modules/estatePlan/constants/enums';

export const getProvidersByType = (
  providerType: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const providers = await assetService.getProvidersByType(
      providerType as PROVIDER_CONSTANT_TYPES
    );

    dispatch(
      helper.successAction(FETCH_PROVIDER_BY_TYPE, { providers, providerType })
    );

    return providers;
  }, actionOptions);
};
