import { notificationService } from 'modules/notification/services';
import helper from 'store/actions/actionHelper';
import {
  GET_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  EDIT_NOTIFICATION,
  TOGGLE_NOTIFICATION_PANEL,
  MARK_ALL_AS_READ,
} from 'store/types/notificationTypes';

export const toggleNotificationPanel = () => ({
  type: TOGGLE_NOTIFICATION_PANEL,
});

export const getNotifications = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const notifications = await notificationService.getNotifications();
    dispatch(helper.successAction(GET_NOTIFICATIONS, { notifications }));
  }, actionOptions);
};

export const editNotification = (
  notification,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const modifiedNotification = await notificationService.editNotification(
      notification
    );
    dispatch(helper.successAction(EDIT_NOTIFICATION, { modifiedNotification }));
  }, actionOptions);
};

export const markAllAsRead = (actionOptions?: ActionOptions) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const modifiedNotifications = await notificationService.markAllAsRead();
    dispatch(helper.successAction(MARK_ALL_AS_READ, { modifiedNotifications }));
  }, actionOptions);
};

export const deleteNotification = (
  id: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    await notificationService.deleteNotification(id);
    dispatch(helper.successAction(DELETE_NOTIFICATION, { id }));
  }, actionOptions);
};
