import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';
import { CONTENT_QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/contentQuestionnaires';

export const RECOMMENDATION_INFO = {
  [QUESTIONNAIRE_KEYS.PATIENT_DECREE]: {
    timeToComplete: '3',
    // preparationHint: `recommendations:preparation_hint_tooltip_${QUESTIONNAIRE_KEYS.PATIENT_DECREE}`,
  },
  [QUESTIONNAIRE_KEYS.WILL_GENERATOR]: {
    timeToComplete: '20',
    // preparationHint: `recommendations:preparation_hint_tooltip_${QUESTIONNAIRE_KEYS.WILL_GENERATOR}`,
  },
  [QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD]: {
    timeToComplete: '5',
    // preparationHint: `recommendations:preparation_hint_tooltip_${QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD}`,
  },
  [QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY]: {
    timeToComplete: '20',
    // preparationHint: `recommendations:preparation_hint_tooltip_${QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY}`,
  },
  [QUESTIONNAIRE_KEYS.INHERITANCE_MANAGEMENT]: {
    timeToComplete: '',
    // preparationHint: `recommendations:preparation_hint_tooltip_${QUESTIONNAIRE_KEYS.INHERITANCE_MANAGEMENT}`,
  },
  [QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT]: {
    timeToComplete: '',
    // preparationHint: `recommendations:preparation_hint_tooltip_${QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.FUNERAL_PROVISION]: {
    timeToComplete: '7',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.FUNERAL_PROVISION}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_CARE]: {
    timeToComplete: '5',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_CARE}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_AGED]: {
    timeToComplete: '6',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.FINANCIAL_SUPPLY_AGED}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CARE_OCCUPATIONAL_DISABILITY]: {
    timeToComplete: '7',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.CARE_OCCUPATIONAL_DISABILITY}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.EMERGENCY_PASS]: {
    timeToComplete: '6',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.EMERGENCY_PASS}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.EMERGENCY_PASS_KIDS]: {
    timeToComplete: '',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.EMERGENCY_PASS_KIDS}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER]: {
    timeToComplete: '7',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CARE_DIRECTIVE]: {
    timeToComplete: '',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.CARE_DIRECTIVE}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.POSTAL_POWER_OF_ATTORNEY]: {
    timeToComplete: '7',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.POSTAL_POWER_OF_ATTORNEY}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.CUSTODY_ORDER]: {
    timeToComplete: '6',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.CUSTODY_ORDER}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.DIGITAL_ESTATE]: {
    timeToComplete: '7',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.DIGITAL_ESTATE}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.INHERITANCE_TAX]: {
    timeToComplete: '8',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.INHERITANCE_TAX}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.PURCHASE_LOANS_RIGHTS]: {
    timeToComplete: '',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.PURCHASE_LOANS_RIGHTS}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.LANDLORD_RENTAL_AGREEMENT]: {
    timeToComplete: '',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.LANDLORD_RENTAL_AGREEMENT}`,
  },
  [CONTENT_QUESTIONNAIRE_KEYS.VEHICLE_DOCUMENTS]: {
    timeToComplete: '',
    // preparationHint: `recommendations:preparation_hint_tooltip_${CONTENT_QUESTIONNAIRE_KEYS.VEHICLE_DOCUMENTS}`,
  },
};
