import React from 'react';

import { useTranslation } from 'modules/common/helpers';

type Feature = {
  id: number;
  title: string;
  description: string;
  img: string;
};

interface PlatformFeatureInfoSectionProps {
  features: Feature[];
  titleClassName?: string;
}

const PlatformFeatureInfoSection = ({
  features,
  titleClassName,
}: PlatformFeatureInfoSectionProps) => {
  const { t } = useTranslation(['auth']);

  return (
    <div
      className="t-flex t-flex-col t-items-center lg:t-flex-none lg:t-grid lg:t-grid-cols-3 lg:t-gap-16"
      data-testid="PlatformFeatureInfoSection"
    >
      {features.map((feature) => (
        <div className="t-h-full" key={feature.id}>
          <div className="t-flex t-flex-col t-items-center t-max-w-80 md:t-max-w-64 t-mt-7 lg:t-mt-0">
            <img
              src={feature.img}
              className="t-max-h-20"
              alt={t(feature.title)}
            />
            <h2
              className={`typo-beta t-text-center t-mt-3 ${
                titleClassName || ''
              }`}
            >
              {t(feature.title)}
            </h2>
            <p className="typo-epsilon t-text-beta-500 t-text-center t-mt-2.5">
              {t(feature.description)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PlatformFeatureInfoSection;
