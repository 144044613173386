import React from 'react';
import { Transition } from 'react-transition-group';

export interface FadeProps {
  animate: boolean;
  children: React.ReactNode | React.ReactNode[];
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const defaultStyle = {
  transition: 'opacity 500ms ease-in-out',
  opacity: 0,
};

const Fade = (props: FadeProps) => (
  <Transition
    in={props.animate}
    timeout={{
      appear: 100,
      enter: 500,
      exit: 500,
    }}
  >
    {(state) => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {props.children}
      </div>
    )}
  </Transition>
);

export default Fade;
