import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { find, isEmpty, isEqual } from 'lodash';
import ReactTooltip from 'react-tooltip';
import isEmail from 'validator/lib/isEmail';

import { RootState } from 'store/reducers';
import {
  addContact,
  editContact,
  generateContactAccessDocument,
} from 'store/actions/contactActions';
import { updateCurrentUser } from 'store/actions/userActions';
import { setModal } from 'store/actions/modalActions';
import {
  optionsHelper,
  config,
  dateHelper,
  isAllowedToken,
  useTranslation,
} from 'modules/common/helpers';

import {
  BirthdayInput,
  TextInputRef,
  SelectController,
  PhoneInput,
  CountryInput,
} from 'modules/common/components';

import { MODALS } from 'modules/common/constants';

import { ReactComponent as IconInfo } from 'images/icon-info.svg';

type Inputs = {
  name: string;
  email: string;
  surname: string;
  birthday: string;
  phone: string;
  placeOfBirth: string;
  address: string;
  zip: string;
  city: string;
  country: SelectOption;
  gender: SelectOption;
};

interface PersonalInfoFormProps {
  personalObject: UserProfileDTO | ContactDTO;
  isTrustedPerson: boolean;
  isSubmitForm: boolean;
  handleSubmitSuccess: () => void;
  submitFormFalse: () => void;
  isHide?: boolean;
  isEditZVR?: boolean;
  handleAddExistingContactModal?: (contactId: string) => void;
}

export default function PersonalInfoForm({
  personalObject,
  isTrustedPerson = false,
  isSubmitForm,
  handleSubmitSuccess,
  submitFormFalse,
  handleAddExistingContactModal,
  isHide = false,
  isEditZVR = false,
}: PersonalInfoFormProps) {
  const { t } = useTranslation(['zvrRegistration', 'auth']);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,

    formState: { errors },
  } = useForm<Inputs>();
  const dispatch = useDispatch();

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const isGlobalModal = useSelector(
    (state: RootState) => state.modal.name === MODALS.PASSPHRASE
  );

  const isAddNewPerson = isEmpty(personalObject);
  const isContactInfoComplete =
    !isAddNewPerson &&
    !(
      !personalObject.address ||
      !personalObject.zip ||
      !personalObject.city ||
      !personalObject.country
    );

  React.useEffect(() => {
    reset();
  }, [isHide]);

  const isError = !isEmpty(errors);

  React.useEffect(() => {
    if (isError) {
      submitFormFalse();
    }
  }, [isError, isSubmitForm]);

  React.useEffect(() => {
    if (isSubmitForm && !isGlobalModal) {
      dispatch(handleSubmit(onSubmit));
    }
  }, [isSubmitForm]);

  // handle passphrase requirement
  const [passPhraseRequired, setPassPhraseRequired] = React.useState(false);

  React.useEffect(() => {
    if (!!passPhraseRequired) {
      dispatch(
        setModal({
          name: MODALS.PASSPHRASE,
          props: {
            successAction: handlePassPhraseSuccess,
          },
        })
      );
    }
  }, [passPhraseRequired]);

  const onSubmit = async (formData) => {
    const submitData = {
      ...personalObject,
      ...formData,
      birthday:
        formData.birthday &&
        dateHelper.convertDateFormat(
          formData.birthday,
          config.format.serverDate
        ),
      country: (formData.country && (formData.country.value as string)) || '',
      gender: (formData.gender && (formData.gender.value as string)) || '',
    } as ContactDTO | UserProfileDTO;

    if (isAddNewPerson) {
      if (!currentUser.passphraseOptOut && !isAllowedToken()) {
        submitFormFalse();
        setPassPhraseRequired(true);
      } else {
        const newContact: any = await dispatch(
          addContact(submitData as ContactDTO)
        );
        if (newContact?.id) {
          await dispatch(generateContactAccessDocument(newContact.id));
          if (handleAddExistingContactModal)
            handleAddExistingContactModal(newContact.id);
        }
        handleSubmitSuccess();
      }
    } else if (
      isTrustedPerson &&
      !isEqual(submitData, { ...personalObject, phone: personalObject.phone })
    ) {
      const isDocumentGenerationNeeded =
        submitData.gender !== personalObject.gender ||
        submitData.birthday !== personalObject.birthday ||
        submitData.name !== personalObject.name ||
        submitData.surname !== personalObject.surname;

      if (!currentUser.passphraseOptOut && !isAllowedToken()) {
        submitFormFalse();
        setPassPhraseRequired(true);
      } else {
        const editedContact: any = await dispatch(
          editContact(submitData as ContactDTO)
        );
        if (isDocumentGenerationNeeded && editedContact.id) {
          await dispatch(
            generateContactAccessDocument(editedContact.id, false)
          );
        }
        if (!editedContact) {
          submitFormFalse();
        } else {
          handleSubmitSuccess();
        }
      }
      // This helps to handle the case if the personalObject.phone = undefined while submitData.phone = ""
    } else if (
      !isEqual(submitData, { ...personalObject, phone: personalObject.phone })
    ) {
      await dispatch(updateCurrentUser(submitData as UserProfileDTO));
      handleSubmitSuccess();
    } else {
      handleSubmitSuccess();
    }
  };

  let errName,
    errSurname,
    errPhone,
    errPlaceOfBirth,
    errAddress,
    errZip,
    errCity;

  if (errors.name) errName = `${t('auth:name_f')} ${t('common:required')}`;
  if (errors.surname)
    errSurname = `${t('auth:name_l')} ${t('common:required')}`;
  if (errors.phone)
    errPhone = `${t('auth:phone_label')} ${t('common:not_valid')}`;
  if (errors.placeOfBirth)
    errPlaceOfBirth = `${t('auth:birthplace_label')} ${t('common:required')}`;
  if (errors.address)
    errAddress = `${t('auth:address_label')} ${t('common:required')}`;
  if (errors.zip) errZip = `${t('auth:postal_label')} ${t('common:required')}`;
  if (errors.city) errCity = `${t('auth:city_label')} ${t('common:required')}`;

  const errEmail = () => {
    if (errors.email) {
      if (errors.email.type === 'required')
        return `${t('auth:email_required')}`;
      if (errors.email.type === 'yourselfEmail') return errors.email.message;
      return `${t('auth:email_not_valid')}`;
    }
    return null;
  };

  const genderOptions = optionsHelper.getGenderOptions();

  const handlePassPhraseSuccess = () => {
    dispatch(handleSubmit(onSubmit));
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <form
      className={`Form sm:t-p-2 t-mt-6 ${
        isHide || isGlobalModal ? 't-hidden' : ''
      }`}
    >
      <div className="sm:t-flex">
        <div className="Form-group sm:t-mr-2 sm:t-w-32">
          <label
            className={`Form-label ${errors.gender ? 'isErrored' : ''}`}
            htmlFor="gender"
          >
            {t('auth:title')}
          </label>

          <SelectController
            id={`gender${personalObject?.id ? personalObject.id : ''}`}
            name="gender"
            options={genderOptions}
            control={control}
            rules={{ required: true }}
            defaultValue={
              find(
                genderOptions,
                (item) => item.value === personalObject.gender
              ) as SelectOption
            }
            className={`Select ${errors.gender ? 'isErrored' : ''}`}
          />
          {errors.gender && (
            <div className="Form-alert">
              <span className="text-main-sm t-text-epsilon-600">{`${t(
                'auth:title'
              )} ${t('common:required')}`}</span>
            </div>
          )}
        </div>

        <div className="sm:t-mr-2">
          <TextInputRef
            id={`name${personalObject?.id ? personalObject.id : ''}`}
            {...register('name', { required: true })}
            label={t('auth:name_f')}
            type="text"
            defaultValue={personalObject.name}
            error={errName}
          />
        </div>

        <TextInputRef
          id={`surname${personalObject?.id ? personalObject.id : ''}`}
          {...register('surname', { required: true })}
          label={t('auth:name_l')}
          type="text"
          defaultValue={personalObject.surname}
          error={errSurname}
        />
      </div>

      <div className="sm:t-flex t-mb-5">
        <div className="sm:t-mr-2 sm:t-w-2/5">
          <BirthdayInput
            control={control}
            error={errors?.birthday?.message || ''}
            defaultValue={dateHelper.convertDateFormat(
              personalObject.birthday || '',
              config.format.uiDate
            )}
            isRequired={true}
          />
        </div>

        <div className="sm:t-w-3/5">
          {isTrustedPerson ? (
            <TextInputRef
              id={`email${personalObject?.id ? personalObject.id : ''}`}
              disabled={!isAddNewPerson}
              {...register('email', {
                required: true,
                validate: (value) => isEmail(value),
              })}
              label={t('auth:email_label')}
              type="text"
              defaultValue={personalObject.email || ''}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('email', e.target.value.trim())
              }
              autoComplete={false}
              error={errEmail()}
            />
          ) : (
            <TextInputRef
              id={`placeOfBirth${personalObject?.id ? personalObject.id : ''}`}
              {...register('placeOfBirth', { required: true })}
              label={t('auth:birthplace_label')}
              type="text"
              error={errPlaceOfBirth}
              defaultValue={personalObject.placeOfBirth}
            />
          )}
        </div>
      </div>

      {isTrustedPerson && (
        <div>
          {!isEditZVR && (
            <h2 className="typo-beta t-mb-2">
              <span>
                {isContactInfoComplete
                  ? t('zvrRegistration:already_completed_trusted_person_data')
                  : t('zvrRegistration:complete_trusted_person_data')}
              </span>
              <span>
                <IconInfo
                  role="presentation"
                  data-tip={t(
                    'zvrRegistration:tooltip_trusted_person_data_complete'
                  )}
                  className="t-inline t-text-delta-700 t-ml-1"
                />
              </span>
            </h2>
          )}
          <p className="typo-epsilon t-mb-6 t-p-3 t-pl-4 t-bg-delta-100 t-text-delta-500 t-rounded">
            {t('zvrRegistration:trusted_person_address_uptodate')}
          </p>
        </div>
      )}

      <TextInputRef
        id={`address${personalObject?.id ? personalObject.id : ''}`}
        {...register('address', { required: true })}
        label={t('auth:address_label')}
        type="text"
        error={errAddress}
        defaultValue={personalObject.address}
      />

      <div className="sm:t-flex">
        <div className="sm:t-mr-2 sm:t-w-1/2">
          <TextInputRef
            id={`zip${personalObject?.id ? personalObject.id : ''}`}
            {...register('zip', { required: true })}
            label={t('auth:postal_label')}
            type="text"
            error={errZip}
            defaultValue={personalObject.zip}
          />
        </div>

        <div className="sm:t-w-1/2">
          <TextInputRef
            id={`city${personalObject?.id ? personalObject.id : ''}`}
            {...register('city', { required: true })}
            label={t('auth:city_label')}
            type="text"
            error={errCity}
            defaultValue={personalObject.city}
          />
        </div>
      </div>

      <CountryInput
        control={control}
        error={(errors.country?.type as string) || ''}
        defaultValue={personalObject.country || ''}
        isRequired={true}
        refSetValue={setValue}
        refGetValues={getValues}
      />

      <div className="t-mt-3">
        <PhoneInput
          control={control}
          error={errPhone}
          defaultValue={personalObject.phone}
          isRequired={false}
          isDefaultLabelVisible={true}
          label={`${t('common:phone_label_optional')}`}
        />
      </div>
    </form>
  );
}
