import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import isCreditCard from 'validator/lib/isCreditCard';

import { FormControl, FormErrors } from 'modules/common/types';
import { useTranslation } from 'modules/common/helpers';

import { VALIDATE } from 'modules/common/constants/generic';

export interface CreditCardNumberInputProps {
  control: FormControl;
  errors: FormErrors;
  label: string;
  name: string;
  defaultValue: string;
  required?: boolean;
  className?: string;
}

export default function CreditCardNumberInput({
  errors,
  label,
  name,
  control,
  defaultValue,
  required,
  className = '',
}: CreditCardNumberInputProps) {
  const { t } = useTranslation(['auth', 'common']);

  const error = !errors[name]
    ? ''
    : errors[name]?.type === VALIDATE
    ? 'Invalid card number'
    : `${t(`common:${name}_label`)} ${t('common:required')}`;

  return (
    <div className={`t-mb-3.5 ${className}`}>
      <span
        className={`t-inline-block t-mb-2 t-text-beta-500 ${
          error ? 't-text-epsilon-600' : ''
        }`}
      >
        {label}
      </span>
      <Controller
        name={name}
        render={({ field }) => (
          <InputMask
            mask="**** **** **** ****"
            className={`Field ${error ? 'isErrored' : ''}`}
            {...field}
          />
        )}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: required,
          validate: (value) => {
            if (!value) return true;
            return isCreditCard(value.replace(/\s/g, ''));
          },
        }}
      />
      {error && (
        <div className="t-pt-0.5">
          <span className="text-main-sm t-text-epsilon-600">{error}</span>
        </div>
      )}
    </div>
  );
}
