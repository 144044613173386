import { STEP_COMPONENT_TYPE as C } from 'modules/assistant/constants/questionnaires';
import { QUESTIONNAIRE_FORM_FIELD_TYPES as F } from 'modules/assistant/constants/questionnaires/questionnaireFormFieldTypes';

export enum STEP_KEYS {
  // ids of steps (questions)
  // NOTE: we should probably make the below keys as descriptive as we did for the answers
  // because we will store "keyStep: keyAnswer" in the backend
  STEP_START = 'start',
  STEP_1 = 'bankInformation',
  STEP_2 = 'contactInformation',
  STEP_END = 'end',
}
const S = STEP_KEYS;

export const getBankAuthorizationLetterQuestionnaire = (
  questionnaire,
  i18nTFunc
) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    [S.STEP_START]: {
      content: t('s_start'),
      type: C.START,
      linksTo: S.STEP_1,
    },
    [S.STEP_1]: {
      content: t('s_1'),
      current: 1,
      total: 2,
      type: C.FORM,
      linksTo: S.STEP_2,
      customNavigationButtons: true,
      smallSize: true,
      formElements: [
        {
          type: F.NAME,
          label: t('s_1_form_field_label_1'),
          required: true,
          span: 12,
        },
        {
          type: F.ADDRESS,
          label: t('s_1_form_field_label_2'),
          required: false,
          span: 12,
        },
      ],
    },
    [S.STEP_2]: {
      content: t('s_2'),
      current: 2,
      total: 2,
      type: C.BANK_POA_CONTACT,
      linksTo: S.STEP_END,
    },
    [S.STEP_END]: {
      content: t('s_end'),
      type: C.END,
    },
  };
};

export const getBankAuthorizationLetterStartScreen = (
  questionnaire,
  i18nTFunc
) => {
  const t = (content) => i18nTFunc(`${questionnaire}:${content}`);
  return {
    listItems: [
      t('start_screen_list_1'),
      t('start_screen_list_2'),
      t('start_screen_list_3'),
    ],
  };
};
