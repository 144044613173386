import routerHistory from 'routerHistory';
import { ROUTER_STATE_STATUS } from 'modules/common/constants/enums';
import { ERROR_CODES } from 'modules/common/constants';

const handleResponseError = (response) => {
  const { status, errorCode } = response || {};
  if (status === 403 && errorCode === ERROR_CODES.NOT_ALLOWED) {
    return errorCode;
  }

  if (status === 402) {
    routerHistory.push('/profile', {
      status: ROUTER_STATE_STATUS.PAYMENT_REQUIRED,
    });
    return errorCode;
  }

  if (status >= 400 && status <= 599) {
    return errorCode;
  }

  return false;
};

export default handleResponseError;
