import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * countdown timer
 * @param {number} time - time is seconds
 */
const useCountdownTimer = (time: number) => {
  const interval = useRef<any>();
  const lastTime = useRef(time);
  const [started, setStarted] = useState(false);

  const start = useCallback(() => {
    lastTime.current = time;
    setStarted(true);
  }, [time]);

  const reset = useCallback(() => {
    clearInterval(interval.current);
    lastTime.current = 0;
    setStarted(false);
  }, []);

  useEffect(() => {
    if (started) {
      interval.current = setInterval(() => {
        lastTime.current -= 1;

        if (lastTime.current <= 0) {
          setStarted(false);
          clearInterval(interval.current);
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [started]);

  return { start, started, reset };
};

export default useCountdownTimer;
