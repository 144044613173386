import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { useForm } from 'react-hook-form';

import { RootState } from 'store/reducers';
import {
  addBackupContact,
  createOrUpdateMultipleBackupContacts,
  removeBackupContact,
  removeBackupContacts,
} from 'store/actions/contactActions';
import { setModal } from 'store/actions/modalActions';
import { optionsHelper, useTranslation } from 'modules/common/helpers';
import { getSelectOptionsContacts } from 'modules/contact/helpers';

import { Button, SelectController, Switcher } from 'modules/common/components';

import { MODALS } from 'modules/common/constants';

const ACTION_TYPES = {
  CHANGE_PERIOD: 'changeInActivePeriod',
  ADD_CONTACT: 'addBackupContact',
  REMOVE_CONTACT: 'removeBackupContact',
  ADD_MULTIPLE_CONTACTS: 'addMultipleBackupContacts',
  REMOVE_ALL_CONTACTS: 'removeAllBackupContacts',
};
export interface BackupContactSectionProps {
  contactsData: ContactDTO[];
}

const BackupContactSection = ({ contactsData }: BackupContactSectionProps) => {
  const { t } = useTranslation(['profile', 'common']);
  const dispatch = useDispatch();
  const { control, setValue, watch } = useForm();

  const backupContacts = useSelector(
    (state: RootState) => state.contacts.backup
  );

  const defaultOptions = !!backupContacts.length
    ? getSelectOptionsContacts(backupContacts)
    : null;

  const contactsOptions = getSelectOptionsContacts(contactsData);

  // default inactivity period and options
  const inactivityPeriodOptions = optionsHelper.getInactivityPeriodOptions();
  const defaultInactivityPeriodOption = inactivityPeriodOptions.find(
    (item) => item.value === `${backupContacts[0]?.period}`
  );

  const selectedContactOptions = watch('backupContactIds');

  // only check the changes in selected backup contacts when the initial loading is complete
  const [initialLoadingCompleted, setInitialLoadingCompleted] =
    React.useState(false);
  React.useEffect(() => {
    if (initialLoadingCompleted) {
      handleAddMoreOrRemoveContact();
    }
    if (selectedContactOptions?.length) {
      setBackupContactInputError(false);
    }
  }, [selectedContactOptions]);

  const handleAddMoreOrRemoveContact = () => {
    // if backup contact feature is not enabled, ignore the changes
    if (!defaultOptions?.length) {
      return;
    }

    const selectedIds =
      selectedContactOptions?.map((option) => option.value) || [];
    const defaultIds = defaultOptions.map((option) => option.value);

    // detect if the user remove or add a backup contact
    // in case of removal
    if (selectedIds.length < defaultIds.length) {
      const removedId = defaultIds.find((id) => !selectedIds.includes(id));
      if (!removedId) return;

      handleDispatchAction(ACTION_TYPES.REMOVE_CONTACT, undefined, removedId);
    } else if (selectedIds.length > defaultIds.length + 1) {
      // multiple contacts are added through ADD ALL button
      const addedIds = selectedIds.filter((id) => !defaultIds.includes(id));
      if (!addedIds?.length) return;
      handleDispatchAction(ACTION_TYPES.ADD_MULTIPLE_CONTACTS);
    } else {
      const addedId = selectedIds.find((id) => !defaultIds.includes(id));
      if (!addedId) return;
      handleDispatchAction(ACTION_TYPES.ADD_CONTACT, undefined, addedId);
    }
  };

  // manage states
  const [isActive, setIsActive] = React.useState(!!defaultOptions?.length);
  const [inactivityPeriodOption, setInactivityPeriodOption] = React.useState(
    inactivityPeriodOptions[0]
  );

  const setSelectedContactOptions = (options) => {
    setValue(
      'backupContactIds',
      options?.length
        ? options.filter((option) => option?.label !== 'all')
        : null
    );
  };

  const [backupContactInputError, setBackupContactInputError] =
    React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (!!defaultOptions) {
      setSelectedContactOptions(defaultOptions);
    }
    if (defaultInactivityPeriodOption) {
      setInactivityPeriodOption(defaultInactivityPeriodOption);
    }
    if (!!defaultOptions) {
      setIsActive(true);
    } else {
      setIsActive(false);
      setSelectedContactOptions(null);
    }
    setInitialLoadingCompleted(true);
  }, [backupContacts]);

  // Handle changes
  const onStatusSwitching = async (active: boolean) => {
    setIsSubmitting(true);
    if (!!defaultOptions && !active) {
      // remove all default backup contacts
      handleDispatchAction(ACTION_TYPES.REMOVE_ALL_CONTACTS);
    } else if (!selectedContactOptions?.length) {
      await setBackupContactInputError(true);
    } else {
      handleDispatchAction(ACTION_TYPES.ADD_MULTIPLE_CONTACTS);
    }
    setIsSubmitting(false);
  };

  const handleCreateMultipleBackupContacts = async (inactivePeriod) => {
    if (!selectedContactOptions?.length) {
      return;
    }

    const contactIds = selectedContactOptions.map((option) => option.value);

    const isUpdating =
      inactivePeriod && defaultInactivityPeriodOption?.value !== inactivePeriod;
    const response = await dispatch(
      createOrUpdateMultipleBackupContacts(
        contactIds,
        Number(inactivePeriod),
        isUpdating
      )
    );

    if (!!response) {
      setIsActive(true);
    }
  };

  const handleInactivityPeriodChange = async (selectedOption) => {
    const option = selectedOption as SelectOption;

    if (option.value === defaultInactivityPeriodOption?.value) {
      return;
    }
    if (!defaultOptions?.length) {
      setInactivityPeriodOption(option);
      return;
    }

    handleDispatchAction(ACTION_TYPES.CHANGE_PERIOD, option);
  };

  const customStyles = {
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.backgroundColor,
      };
    },
    control: (styles) => {
      return {
        ...styles,
        height: 'auto !important',
        minHeight: '36px !important',
      };
    },
  };

  const addAllContactsAs = () => {
    setValue(
      'backupContactIds',
      contactsOptions.filter((option) => option?.label !== 'all')
    );
  };

  contactsOptions.length
    ? contactsOptions.push({ value: '-1', label: 'all' })
    : [];

  const allContactSelected =
    selectedContactOptions?.length === contactsOptions.length - 1;
  const displayedContactOptions = allContactSelected
    ? ([] as SelectOption[])
    : contactsOptions;

  const handleDispatchAction = (
    type: string,
    option?: SelectOption,
    id?: string
  ) => {
    const title = t(`profile:${type}_confirm_modal_title`);
    const description = t(`profile:${type}_confirm_modal_description`);

    let successAction;
    let cancelAction;
    switch (type) {
      case ACTION_TYPES.CHANGE_PERIOD:
        successAction = async () => {
          await handleCreateMultipleBackupContacts(option!.value);
        };
        break;
      case ACTION_TYPES.ADD_CONTACT:
        cancelAction = () => {
          setSelectedContactOptions(defaultOptions);
        };
        successAction = async () => {
          await dispatch(addBackupContact(id!, inactivityPeriodOption.value));
        };
        break;
      case ACTION_TYPES.ADD_MULTIPLE_CONTACTS:
        cancelAction = () => {
          setSelectedContactOptions(defaultOptions);
        };
        successAction = async () => {
          await handleCreateMultipleBackupContacts(
            inactivityPeriodOption.value
          );
        };
        break;
      case ACTION_TYPES.REMOVE_CONTACT:
        cancelAction = () => {
          setSelectedContactOptions(defaultOptions);
        };
        successAction = async () => {
          await dispatch(removeBackupContact(id!));
        };
        break;
      case ACTION_TYPES.REMOVE_ALL_CONTACTS:
        successAction = async () => {
          await dispatch(
            removeBackupContacts(defaultOptions!.map((option) => option.value))
          );
        };
        break;
      default:
        return;
    }

    dispatch(
      setModal({
        name: MODALS.COMMON_MODAL,
        props: {
          title,
          description,
          primaryButtonText: t('common:confirm'),
          ...(!!cancelAction ? { cancelAction } : {}),
          successAsyncAction: async () => {
            await successAction();
          },
        },
      })
    );
  };

  return (
    <form
      className="t-mt-12"
      data-testid="BackupContactSection"
      id="BackupContactSection"
    >
      <h2 className="typo-beta">{t('profile:backup_contact_section_title')}</h2>
      <div className="card t-mt-4 t-p-6">
        <div className="t-flex t-justify-between t-items-center">
          <p
            className={`typo-kappa t-mr-2.5 t-px-5 t-py-1.5 t-rounded-sm ${
              isActive
                ? 't-bg-alpha-100 t-text-alpha-600'
                : 't-bg-beta-100 t-text-zeta-600'
            }`}
          >
            {isActive
              ? t('profile:backup_contact_card_status_active')
              : t('profile:backup_contact_card_status_inactive')}
          </p>
          <Switcher
            name="backupContactStatus"
            onChange={onStatusSwitching}
            checked={isActive}
            isSubmitting={isSubmitting}
          />
        </div>
        <p className="typo-delta t-mt-4">
          {t('profile:backup_contact_card_description')}
        </p>

        <div className="Form-group t-mt-4">
          <label className="Form-label" htmlFor="inactivityPeriod">
            {t('profile:backup_contact_card_inactive_period_input_label')}
          </label>
          <Select
            aria-label="inactivityPeriod"
            id="inactivityPeriod"
            name="inactivityPeriod"
            value={inactivityPeriodOption || inactivityPeriodOptions[0]}
            onChange={handleInactivityPeriodChange}
            options={inactivityPeriodOptions}
            className="Select"
            classNamePrefix="Select"
            placeholder={t('common:default_select_placeholder')}
            isDisabled={isSubmitting}
          />
        </div>

        <div className="Form-group t-mt-3">
          <label
            className={`Form-label ${
              backupContactInputError ? 'isErrored' : ''
            }`}
            htmlFor="backupContactIds"
          >
            {t('profile:backup_contact_card_contact_person_select_label')}
          </label>
          <SelectController
            id="backupContactIds"
            name="backupContactIds"
            options={displayedContactOptions}
            control={control}
            isMulti
            styles={customStyles}
            disabled={isSubmitting}
            placeholder={t('common:default_select_placeholder')}
            components={
              allContactSelected
                ? null
                : {
                    Option: (selectProps) =>
                      selectProps?.data.value === '-1' &&
                      selectProps?.data.label === 'all' ? (
                        <div
                          className="t-flex t-pt-5"
                          ref={selectProps.innerRef}
                        >
                          <Button
                            category="secondary"
                            onClick={addAllContactsAs}
                          >
                            {t('profile:add_all_contacts')}
                          </Button>
                        </div>
                      ) : (
                        <components.Option {...selectProps}>
                          {selectProps.children}
                        </components.Option>
                      ),
                  }
            }
            defaultValue={defaultOptions}
            className={`Select ${backupContactInputError ? 'isErrored' : ''} `}
          />
          {backupContactInputError && (
            <div className="Form-alert">
              <span className="text-main-sm t-text-epsilon-600">
                {t(
                  'profile:backup_contact_card_contact_person_select_required'
                )}
              </span>
            </div>
          )}
        </div>

        {!!selectedContactOptions?.length && !isActive && (
          <div className="t-mt-2">
            <p className="typo-zeta t-text-delta-700">
              {t('profile:backup_contact_card_backup_info', {
                period: inactivityPeriodOption?.value || '',
              })}
            </p>
          </div>
        )}
      </div>
    </form>
  );
};

export default BackupContactSection;
