export const DEVICES_ASSET_TYPES = {
  PHONE: 'phone',
  COMPUTER: 'computer',
  TABLET: 'tablet',
  GAMING_CONSOLE: 'gaming console',
  SMART_WATCH: 'smart watch',
  EXTERNAL_DRIVE: 'external drive',
};

export const HEALTH_CARE_ASSET_TYPES = {
  PATIENT_DECREE: 'patient decree',
  ORGAN_DONOR_CARD: 'organ donor card',
  HEALTH_CARE_PROXY: 'health care proxy',
  EMERGENCY_PASS: 'emergency pass',
  DOCTOR: 'doctor',
};

export const LEGAL_ASSET_TYPES = {
  POWER_OF_ATTORNEY: 'power of attorney',
  WILL: 'will',
  PERSONAL_DOCUMENTS: 'personal document',
  RENTAL_CONTACT: 'rental contract',
  VEHICLE_DOCUMENT: 'vehicle documents',
  FORWARDING_ORDER: 'forwarding order',
  ATTORNEY: 'attorney',
  PET_WATCHING_AGREEMENT: 'pet watching agreement',
};

export const DIGITAL_ESTATE_ASSET_TYPES = {
  PASSWORD: 'password',
  PHYSICAL_PASSWORD: 'physical password',
  EMAIL: 'email',
  SOCIAL_MEDIA: 'social media',
  CLOUD_STORAGE: 'cloud storage',
  SUBSCRIPTION: 'subscription',
  SHOPPING: 'shopping',
  SOFTWARE_LICENSE: 'software license',
  WEB_HOSTING: 'web hosting',
};

export const AFTER_LIFE_ASSET_TYPES = {
  FUNERAL: 'funeral',
  LAST_MESSAGES: 'last message',
  OTHER_WISHES: 'other wishes',
  LIST_OF_CONTACTS: 'list of contacts',
};

export const FINANCIAL_ASSET_TYPES = {
  BANK_ACCOUNT: 'bank account',
  ONLINE_WALLET: 'online wallet',
  TRADING_PORTFOLIO: 'trading portfolio',
  SAFE_DEPOSIT: 'safe deposit',
  ONGOING_CONTRACT: 'ongoing contract',
  LONG_TERM_ASSETS: 'long term asset',
  CREDIT_CARD: 'credit card',
  FINANCIAL_ADVISOR: 'financial advisor',
};

export const INSURANCE_ASSET_TYPES = {
  DEATH_BENEFIT_INSURANCE: 'death benefit insurance',
  LIFE_INSURANCE: 'life insurance',
  OTHER_INSURANCE: 'other insurance',
  HEALTH_INSURANCE: 'health insurance',
  CARE_INSURANCE: 'care insurance',
  OCCUPATIONAL_DISABILITY_INSURANCE: 'occupational disability insurance',
  OTHER_HEALTH_INSURANCE: 'other health insurance',
};

export const DOCUMENT_ASSET_TYPES = {
  TRANSMORTAL_AUTHORIZATION: 'transmortal authorization',
  ESTATE_PLAN: 'estate plan',
};

const ASSET_TYPE_KEYS = {
  ...DEVICES_ASSET_TYPES,
  ...HEALTH_CARE_ASSET_TYPES,
  ...LEGAL_ASSET_TYPES,
  ...DIGITAL_ESTATE_ASSET_TYPES,
  ...AFTER_LIFE_ASSET_TYPES,
  ...FINANCIAL_ASSET_TYPES,
  ...INSURANCE_ASSET_TYPES,
};

export { ASSET_TYPE_KEYS };
