import React from 'react';
import { isEqual } from 'lodash';

import {
  checkEmptyValueObject,
  isJsonString,
  useTranslation,
} from 'modules/common/helpers';
import { CommonFormInputProps, FormErrors } from 'modules/common/types';

import {
  CreditCardExpDateInput,
  CreditCardNumberInput,
  TextInputRef,
} from 'modules/common/components';

import { CARD_FIELD_INPUTS as CFI } from 'modules/common/constants/enums';
import { FIELD_TYPE_KEYS as F } from 'modules/estatePlan/constants/fieldLibrary';

interface CreditCardInfoInputPros extends CommonFormInputProps {
  errors: FormErrors;
  required: boolean;
  stringifiedCardInfo?: string;
  label?: string;
}

const CreditCardInfoInput = ({
  register,
  control,
  errors,
  watch,
  required,
  stringifiedCardInfo = '',
  setValue,
  label = '',
}: CreditCardInfoInputPros) => {
  const { t } = useTranslation(['auth', 'common']);

  const defaultValues =
    stringifiedCardInfo && isJsonString(stringifiedCardInfo)
      ? JSON.parse(stringifiedCardInfo)
      : {};

  const {
    nameOnCard = '',
    cardNumber = '',
    cardExpirationDate = '',
    code = '',
    cardNumberShort = '',
  } = defaultValues;

  // this will be updated when value of a field is changed
  // to update the field value in the parent component
  // also avoid react-hook-form save and object as string '[object Object]'
  const [updatedInputs, setUpdatedInputs] = React.useState(defaultValues);

  // depending on the condition, either or both card number inputs are shown
  const showBothCardNumberInputs =
    !stringifiedCardInfo || (!cardNumberShort && !cardNumber);
  const [showCardNumber, setShowCardNumber] = React.useState(
    showBothCardNumberInputs || !!cardNumber
  );
  const [showCardNumberShort, setShowCardNumberShort] = React.useState(
    showBothCardNumberInputs || !!cardNumberShort
  );

  const currentInputs = Object.keys(CFI).reduce((inputs, key) => {
    inputs[CFI[key]] = watch(CFI[key]) || '';
    return inputs;
  }, {});

  React.useEffect(() => {
    const valuesDifferent = !isEqual(updatedInputs, currentInputs);
    if (valuesDifferent) {
      setUpdatedInputs(currentInputs);
    }
  }, [currentInputs]);

  React.useEffect(() => {
    setValue(
      F.CREDIT_CARD_INFO,
      checkEmptyValueObject(updatedInputs) ? '' : JSON.stringify(updatedInputs)
    );
  }, [updatedInputs]);

  const errNameOnCard = errors[CFI.NAME_ON_CARD]
    ? `${t(`common:${CFI.NAME_ON_CARD}_label`)} ${t('common:required')}`
    : '';
  const errCode = errors[CFI.CODE]
    ? `${t(`common:${CFI.CODE}_label`)} ${t('common:required')}`
    : '';

  const isError = !checkEmptyValueObject({ errNameOnCard, errCode });

  const cardNumberFormValue = watch(CFI.CARD_NUMBER);
  const cardNumberShortFormValue = watch(CFI.CARD_NUMBER_SHORT);

  React.useEffect(() => {
    if (cardNumberFormValue) {
      setValue(CFI.CARD_NUMBER_SHORT, '');
      setShowCardNumberShort(false);
    } else if (cardNumberShortFormValue) {
      setValue(CFI.CARD_NUMBER, '');
      setShowCardNumber(false);
    } else {
      setShowCardNumber(true);
      setShowCardNumberShort(true);
    }
  }, [cardNumberFormValue, cardNumberShortFormValue]);

  return (
    <div
      className={`t-border t-border-solid t-border-beta-200 t-rounded t-relative t-pt-1.5 t-px-2 t-mt-5 t-mb-3 ${
        isError ? 't-border-epsilon-600' : ''
      }`}
    >
      {label && (
        <p
          className={`Form-label t-absolute t--top-3 t-left-5 t-px-2 t-bg-gamma-400 ${
            isError ? 'isErrored' : ''
          }`}
        >
          {t(label)}
        </p>
      )}

      <div className="t-w-full t-mt-3">
        <TextInputRef
          {...register(CFI.NAME_ON_CARD, { required: required })}
          type="text"
          name={CFI.NAME_ON_CARD}
          label={t(`common:${CFI.NAME_ON_CARD}_label`)}
          defaultValue={nameOnCard}
          error={errNameOnCard}
        />
      </div>

      {showCardNumber && (
        <CreditCardNumberInput
          name={CFI.CARD_NUMBER}
          label={t(`common:${CFI.CARD_NUMBER}_label`)}
          control={control}
          required={required}
          defaultValue={cardNumber}
          errors={errors}
          className="t-w-full"
        />
      )}

      {showCardNumberShort && (
        <TextInputRef
          {...register(CFI.CARD_NUMBER_SHORT, { required: false })}
          type="text"
          name={CFI.CARD_NUMBER_SHORT}
          label={t(`common:${CFI.CARD_NUMBER_SHORT}_label`)}
          defaultValue={cardNumberShort}
          maxLength={4}
          error=""
        />
      )}

      <div className="t-flex">
        <CreditCardExpDateInput
          name={CFI.CARD_EXPIRATION_DATE}
          label={t(`common:${CFI.CARD_EXPIRATION_DATE}_label`)}
          control={control}
          required={required}
          defaultValue={cardExpirationDate}
          errors={errors}
          className="t-w-8/12 t-inline-block t-pr-3"
        />

        <div className="t-w-4/12 t-inline-block">
          <TextInputRef
            {...register(CFI.CODE, { required: required })}
            type="number"
            name={CFI.CODE}
            label={t(`common:${CFI.CODE}_label`)}
            defaultValue={code}
            maxLength={3}
            error={errCode}
          />

          <input
            {...register(F.CREDIT_CARD_INFO)}
            name={F.CREDIT_CARD_INFO}
            type="hidden"
          />
        </div>
      </div>
    </div>
  );
};

export default CreditCardInfoInput;
