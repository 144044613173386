import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { Button, Modal } from 'modules/common/components';

import { ReactComponent as IconShield } from 'images/icon_filled_shield.svg';

interface ImmediateAccessInfoModalProps {
  fullInfoContactOf: ContactOfDTO;
  onClose: () => void;
}

export default function ImmediateAccessInfoModal({
  fullInfoContactOf,
  onClose,
}: ImmediateAccessInfoModalProps) {
  const { t } = useTranslation(['profile', 'common']);
  const { token } = fullInfoContactOf;

  if (!token) {
    onClose();
    return null;
  }

  const calculateReadableToken = (token: string): string => {
    const division = token.match(/.{1,4}/g);
    return division!.join('-');
  };

  return (
    <Modal show onHide={onClose}>
      <h1 className="typo-alpha">
        {t('mcontacts:immediate_access_info_modal_title')}
      </h1>

      <div className="t-rounded t-border t-border-solid t-border-delta-700 t-p-5 t-mt-6">
        <p className="t-flex t-items-center">
          <IconShield className="t-text-delta-700" />
          <span className="t-ml-2 typo-gamma t-font-semibold">
            {t('mcontacts:immediate_access_info_modal_code_card_title')}
          </span>
        </p>
        <p className="typo-epsilon t-mt-3">
          {t('mcontacts:immediate_access_info_modal_code_card_description')}
        </p>
        <div className="t-flex t-mt-2.5">
          <p className="typo-beta t-p-1.5 t-bg-delta-100 t-rounded-sm">
            {calculateReadableToken(token)}
          </p>
        </div>
      </div>

      <p className="typo-epsilon t-mt-6">
        {t('mcontacts:immediate_access_info_modal_description')}
      </p>
      <div className="t-flex t-justify-end t-mt-7">
        <Button onClick={onClose}>{t('common:close')}</Button>
      </div>
    </Modal>
  );
}
