import React, { FormEvent, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import { v4 as uuidv4 } from 'uuid';

import {
  optionsHelper,
  dateHelper,
  config,
  useTranslation,
} from 'modules/common/helpers';

import { Col, Row } from 'modules/bootstrap/components';
import {
  TextInputRef,
  SelectController,
  BirthdayInput,
  CountryInput,
  PhoneInput,
  Button,
} from 'modules/common/components';

type Inputs = {
  id?: string;
  gender: SelectOption;
  name: string;
  surname: string;
  birthday: string;
  placeOfBirth: string;
  zip: string;
  address: string;
  city: string;
  country: SelectOption;
  email: string;
  phone: string;
  relation?: string;
};

export interface QuestionnaireContactFormProps {
  contactItem?: ContactDTO;
  onCancel: () => void;
  onSuccess: (isNewHeir: boolean, submitData) => void;
  birthPlaceRequired?: boolean;
  emailRequired?: boolean;
  phoneRequired?: boolean;
}

function QuestionnaireContactForm(props: QuestionnaireContactFormProps) {
  const {
    onCancel,
    onSuccess,
    birthPlaceRequired,
    emailRequired,
    phoneRequired,
  } = props;
  const contactItem = (props.contactItem || {}) as ContactDTO;

  const { t } = useTranslation(['auth', 'common', 'help']);
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,

    formState: { errors },
  } = useForm<Inputs>();
  const [loading, setLoading] = React.useState(false);

  const isNewContact = !contactItem.id;

  const onClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onSubmit = useCallback(
    async (formData: Inputs) => {
      setLoading(true);
      const submitData = {
        ...formData,
        id: contactItem.id || uuidv4(),
        gender: (formData?.gender?.value as string) || '',
        country: (formData.country && (formData.country.value as string)) || '',
        birthday:
          formData.birthday &&
          dateHelper.convertDateFormat(
            formData.birthday,
            config.format.serverDate
          ),
      } as ContactDTO;

      onSuccess(isNewContact, submitData);
    },
    [onClose, onSuccess]
  );

  const submit = (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    handleSubmit(() => {
      onSubmit(getValues());
    })();
  };

  const textFieldRequired = t('common:field_required');
  const textFieldNotValid = t('common:field_not_valid');
  const genderOptions = optionsHelper.getGenderOptions();
  const defaultGenderOptions =
    genderOptions.find((item) => item.value === contactItem.gender) ||
    genderOptions[0];

  return (
    <div data-testid="QuestionnaireContactForm">
      <form
        onSubmit={submit}
        className="Form"
        data-testid="questionnaire-contact-form"
      >
        {!isNewContact && (
          <input
            type="hidden"
            id="id"
            {...register('id')}
            value={contactItem.id}
          />
        )}

        <Row>
          <Col xs={6} xl={3}>
            <div className="Form-group">
              <label className="Form-label" htmlFor="gender">
                {t('auth:title')}
              </label>

              <SelectController
                id="gender"
                name="gender"
                options={genderOptions}
                control={control}
                defaultValue={defaultGenderOptions}
              />
            </div>
          </Col>
          <Col xl={{ span: 4 }}>
            <TextInputRef
              {...register('name', { required: true })}
              label={t('auth:name_f')}
              type="text"
              autoComplete={false}
              defaultValue={contactItem.name}
              error={errors.name && textFieldRequired}
            />
          </Col>

          <Col xl={{ span: 5 }}>
            <TextInputRef
              {...register('surname', { required: true })}
              label={t('auth:name_l')}
              type="text"
              autoComplete={false}
              defaultValue={contactItem.surname}
              error={errors.surname && textFieldRequired}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={{ span: 4 }}>
            <BirthdayInput
              control={control}
              error={errors?.birthday?.message || ''}
              defaultValue={dateHelper.convertDateFormat(
                contactItem.birthday || '',
                config.format.uiDate
              )}
              isRequired={true}
            />
          </Col>

          <Col xl={{ span: 8 }}>
            <TextInputRef
              {...register('placeOfBirth', { required: !!birthPlaceRequired })}
              label={`${t('auth:birthplace_label')}${
                !birthPlaceRequired ? ` (${t('common:optional')})` : ''
              }`}
              type="text"
              defaultValue={contactItem.placeOfBirth}
              error={errors.placeOfBirth && textFieldRequired}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={{ span: 7 }}>
            <TextInputRef
              {...register('relation', { required: true })}
              label={t('mcontacts:rel_field_label')}
              type="text"
              defaultValue=""
              error={errors.relation && textFieldRequired}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={{ span: 8 }}>
            <TextInputRef
              {...register('address', { required: true })}
              label={t('auth:address_label')}
              type="text"
              error={errors.address && textFieldRequired}
              defaultValue={contactItem.address}
            />
          </Col>
          <Col xl={{ span: 4 }}>
            <TextInputRef
              {...register('zip', { required: true })}
              label={t('auth:postal_label')}
              type="text"
              error={errors.zip && textFieldRequired}
              defaultValue={contactItem.zip}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={{ span: 6 }}>
            <TextInputRef
              {...register('city', { required: true })}
              label={t('auth:city_label')}
              type="text"
              error={errors.city && textFieldRequired}
              defaultValue={contactItem.city}
            />
          </Col>
          <Col xl={{ span: 6 }}>
            <CountryInput
              control={control}
              error={(errors.country?.type as string) || ''}
              defaultValue={contactItem.country}
              isRequired={true}
              refSetValue={setValue}
              refGetValues={getValues}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={{ span: 12 }}>
            <TextInputRef
              {...register('email', {
                required: !!emailRequired,
                validate: (value) => {
                  if (!value) return true;

                  return isEmail(value);
                },
              })}
              label={`${t('auth:email_label')}${
                !emailRequired ? ` (${t('common:optional')})` : ''
              }`}
              type="text"
              error={errors.email && textFieldNotValid}
              defaultValue={contactItem.email}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('email', e.target.value.trim())
              }
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <PhoneInput
              label={`${t('auth:phone_label')}${
                !phoneRequired ? ` (${t('common:optional')})` : ''
              }`}
              control={control}
              error={!!errors.phone ? textFieldNotValid : ''}
              defaultValue={contactItem.phone}
              isRequired={!!phoneRequired}
            />
          </Col>
        </Row>

        <div className="t-flex t-flex-wrap t-justify-end">
          <Button
            category="secondary"
            className="t-whitespace-nowrap t-self-end"
            onClick={onClose}
            disabled={loading}
          >
            {t('common:cancel')}
          </Button>
          <Button type="submit" className="t-ml-5 xs:t-mt-5" loading={loading}>
            {t('common:save_and_continue')}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default QuestionnaireContactForm;
