import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { useTranslation } from 'modules/common/helpers';

import { GENDERS } from 'modules/common/constants/enums';

import { ReactComponent as IconExternalLink } from 'images/external-link.svg';
import imgYourAgent from 'images/agents/img_your_agent.svg';

interface AgentSuggestionProps {
  onClick: () => void;
}

export default function AgentSuggestion({ onClick }: AgentSuggestionProps) {
  const { t } = useTranslation(['agent']);

  const currentUser: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );

  const agentInfo = currentUser.agent;

  if (!agentInfo) return null;

  return (
    <button
      type="button"
      onClick={onClick}
      className="card t-bg-gamma-400 t-w-full t-flex t-justify-between t-p-5 t-mb-4 t-mt-8 md:t-mx-3"
      data-testid="AgentSuggestion"
    >
      <div className="t-flex t-items-start">
        <img
          src={imgYourAgent}
          alt="Agent"
          className="t-mr-2 t-rounded-full t-hidden xl:t-inline-block"
        />
        <div className="t-ml-4 t-text-left">
          <h2 className="typo-beta">
            {t(
              `agents:assistant_suggestion_agent_title_${
                agentInfo.gender || GENDERS.OTHER
              }`
            )}
          </h2>
          <p className="typo-epsilon t-mt-3.5">
            {t(
              `agents:assistant_suggestion_agent_description_${
                agentInfo.gender || GENDERS.OTHER
              }`
            )}
          </p>
        </div>
      </div>
      <div className="t-flex t-justify-end t-w-20">
        <IconExternalLink className="t-text-delta-700 t-mr-5" />
      </div>
    </button>
  );
}
