import { values } from 'lodash';

import {
  DEVICE_SUBTYPES,
  HEALTH_SUBTYPES,
  LEGAL_SUBTYPES,
  AFTERLIFE_SUBTYPES,
  FINANCIAL_SUBTYPES,
  DIGITAL_SUBTYPES,
  INSURANCES_SUBTYPES,
} from 'modules/estatePlan/constants/enums';

export const PLAN_STEP_KEYS = {
  INTRODUCTION: 'introduction',
  HEALTH: 'healthCare',
  CONTACTS: 'contacts',
  DEVICES: 'devices',
  LEGAL: 'legal',
  DIGITAL: 'digitalEstate',
  FINANCIAL: 'financial',
  INSURANCE: 'insurance',
  AFTERLIFE: 'afterLife',
  TRANSMORTAL: 'transmortalAuthorization',
};

export const PLAN_STEPS: PlanSteps = {
  [PLAN_STEP_KEYS.INTRODUCTION]: {
    stepNo: 1,
    dtoKey: 'introduction',
    path: '/plan/introduction',
  },
  [PLAN_STEP_KEYS.HEALTH]: {
    stepNo: 2,
    dtoKey: 'healthCare',
    path: '/plan/health-care',
  },
  [PLAN_STEP_KEYS.CONTACTS]: {
    stepNo: 3,
    dtoKey: 'contacts',
    path: '/plan/contacts',
  },
  [PLAN_STEP_KEYS.DEVICES]: {
    stepNo: 4,
    dtoKey: 'devices',
    path: '/plan/devices',
  },
  [PLAN_STEP_KEYS.LEGAL]: {
    stepNo: 5,
    dtoKey: 'legal',
    path: '/plan/legal',
  },
  [PLAN_STEP_KEYS.DIGITAL]: {
    stepNo: 6,
    dtoKey: 'digitalEstate',
    path: '/plan/digital-estate',
  },
  [PLAN_STEP_KEYS.FINANCIAL]: {
    stepNo: 7,
    dtoKey: 'financial',
    path: '/plan/financial',
  },
  [PLAN_STEP_KEYS.INSURANCE]: {
    stepNo: 8,
    dtoKey: 'insurance',
    path: '/plan/insurance',
  },
  [PLAN_STEP_KEYS.AFTERLIFE]: {
    stepNo: 9,
    dtoKey: 'afterLife',
    path: '/plan/after-life',
  },
  [PLAN_STEP_KEYS.TRANSMORTAL]: {
    stepNo: 10,
    dtoKey: 'transmortalAuthorization',
    path: '/plan/transmortal',
  },
};

export const ASSETS_WITH_ATTACHMENTS: string[] = [
  ...values(HEALTH_SUBTYPES),
  ...values(LEGAL_SUBTYPES),
  ...values(AFTERLIFE_SUBTYPES),
  ...values(FINANCIAL_SUBTYPES),
].filter(
  (item) =>
    ![
      FINANCIAL_SUBTYPES.BANK as string,
      FINANCIAL_SUBTYPES.WALLETS as string,
    ].includes(item)
);

export const ASSETS_WITH_PROVIDER: string[] = [
  ...values(DEVICE_SUBTYPES),

  HEALTH_SUBTYPES.HEALTH_INSURANCE,
  HEALTH_SUBTYPES.CARE_INSURANCE,
  HEALTH_SUBTYPES.OTHER_HEALTH_INSURANCE,

  INSURANCES_SUBTYPES.OTHER_INSURANCES,

  DIGITAL_SUBTYPES.PASSWORD,
  DIGITAL_SUBTYPES.EMAIL,
  DIGITAL_SUBTYPES.SOCIAL,
  DIGITAL_SUBTYPES.CLOUD,

  AFTERLIFE_SUBTYPES.DEATH_BENEFIT_INSURANCE,

  FINANCIAL_SUBTYPES.BANK,
  FINANCIAL_SUBTYPES.WALLETS,
  FINANCIAL_SUBTYPES.LIFE_INSURANCE,
];
