import { createSelector } from 'reselect';
import { keys, isEmpty } from 'lodash';

import { RootState } from 'store/reducers';
import { DOCUMENT_ASSET_TYPES } from 'modules/estatePlan/constants/assetTypeKeys';

const stateAssets = (state: RootState): AssetsDTO => state.assets;

export const LayoutSelector = (state: RootState) => state.layout;

export const TransmortalAssetSelector = createSelector(
  [stateAssets],
  (state) => {
    const initObj = {
      id: '',
      documents: [] as DocumentDTO[],
      category: '',
      type: '',
    };

    const transmortalDocument = isEmpty(state)
      ? initObj
      : state?.['document']?.find(
          (doc) =>
            doc?.assetTypeName ===
            DOCUMENT_ASSET_TYPES.TRANSMORTAL_AUTHORIZATION
        );

    return isEmpty(state) ? initObj : transmortalDocument;
  }
);

export const AssetAssignmentsSelector = createSelector(
  [stateAssets],
  (state) => {
    const assetAssignments = {};

    keys(state).forEach((assetCategory) => {
      const categoryAssets: AssetDTO[] = state[assetCategory] || [];
      const isStepHasAllAssigned = !categoryAssets.some(
        (item) => isEmpty(item.contacts) && !item.notProvided
      );
      assetAssignments[assetCategory] = isStepHasAllAssigned;
    });

    return assetAssignments;
  }
);

export const AssetsNotAssignedSelector = createSelector(
  [stateAssets],
  (state) => {
    const assetAssignments = {};

    keys(state).forEach((assetCategory) => {
      const categoryAssets: AssetDTO[] = state[assetCategory] || [];
      const isHasNotAssigned = categoryAssets.some(
        (item) => isEmpty(item.contacts) && !item.notProvided
      );
      assetAssignments[assetCategory] = isHasNotAssigned;
    });

    return assetAssignments;
  }
);
