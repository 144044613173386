import i18n from 'i18next';

import { getContactsList } from 'store/actions/contactActions';
import { dataService } from 'modules/common/services';
import { reactToast } from 'modules/common/helpers';
import helper from 'store/actions/actionHelper';

import { GET_FAMILY_ACCOUNTS } from 'store/types/familyAccountTypes';

export const getFamilyAccounts = (
  hideErrors = false,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    hideErrors;
    const familyAccounts = await dataService.getFamilyAccounts(hideErrors);

    dispatch(helper.successAction(GET_FAMILY_ACCOUNTS, { familyAccounts }));

    return familyAccounts;
  }, actionOptions);
};

export const reportDeathOfFamilyAccount = (
  id: string,
  code = '',
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async () => {
    const response = await dataService.reportDeathOfFamilyAccount(id, code);

    return response;
  }, actionOptions);
};

export const createFamilyMember = (
  contactId: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const response = await dataService.createFamilyMember(contactId);

    await dispatch(getFamilyAccounts());
    await dispatch(getContactsList());

    return response;
  }, actionOptions);
};

export const deleteFamilyAccount = (
  familyAccountId: string,
  code: string,
  actionOptions?: ActionOptions
) => {
  return helper.dispatchAsyncAction(async (dispatch) => {
    const response = await dataService.deleteFamilyAccount(
      familyAccountId,
      code
    );

    reactToast.showMessage(
      i18n.t('toastr:family_account_deleted_successfully')
    );
    await dispatch(getFamilyAccounts());
    await dispatch(getContactsList());

    return response;
  }, actionOptions);
};
