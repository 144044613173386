import {
  getPatientDecreeQuestionnaire,
  getPatientDecreeStartScreen,
  STEP_KEYS as PD_STEP_KEYS,
  getPatientDecreeAnswers,
} from 'modules/assistant/constants/questionnaires/patientDecree';
import {
  getHealthCareProxyQuestionnaire,
  getHealthCareProxyStartScreen,
  STEP_KEYS as HCP_STEP_KEYS,
  getHealthCareProxyAnswers,
} from 'modules/assistant/constants/questionnaires/healthCareProxy';
import {
  getWillQuestionnaire,
  getWillStartScreen,
  STEP_KEYS as WG_STEP_KEYS,
  getWillAnswers,
} from 'modules/assistant/constants/questionnaires/will';
import {
  getOrganDonorCardQuestionnaire,
  getOrganDonorCardStartScreen,
  STEP_KEYS as ODC_STEP_KEYS,
  getOrganDonorCardAnswers,
} from 'modules/assistant/constants/questionnaires/organDonorCard';
import {
  getInitialAssessmentQuestionnaire,
  getInitialAssessmentStartScreen,
  STEP_KEYS as INIT_STEP_KEYS,
  getInitialAssessmentAnswers,
} from 'modules/assistant/constants/questionnaires/initialAssessment';
import {
  getPetWatchingAgreementQuestionnaire,
  getPetWatchingAgreementStartScreen,
  STEP_KEYS as PA_STEP_KEYS,
  getPetWatchingAgreementAnswers,
} from 'modules/assistant/constants/questionnaires/petWatchingAgreement';
import {
  getCustodyOrderQuestionnaire,
  getCustodyOrderStartScreen,
  STEP_KEYS as CO_STEP_KEYS,
  getCustodyOrderAnswers,
} from 'modules/assistant/constants/questionnaires/custodyOrder';

import {
  getFuneralProvisionQuestionnaire,
  getFuneralProvisionStartScreen,
  STEP_KEYS as FP_STEP_KEYS,
  getFuneralProvisionAnswers,
} from 'modules/assistant/constants/questionnaires/funeralProvision';
import {
  getBankAuthorizationLetterQuestionnaire,
  getBankAuthorizationLetterStartScreen,
} from 'modules/assistant/constants/questionnaires/bankAuthorizationLetter';

export enum QUESTIONNAIRE_KEYS {
  PATIENT_DECREE = 'patientDecree',
  WILL_GENERATOR = 'will',
  ORGAN_DONOR_CARD = 'organDonorCard',
  HEALTH_CARE_PROXY = 'healthCareProxy',
  INHERITANCE_MANAGEMENT = 'inheritanceManagement',
  INITIAL_ASSESSMENT = 'initialAssessment',
  PET_WATCHING_AGREEMENT = 'petWatchingAgreement',
  CUSTODY_ORDER = 'custodyOrder',
  FUNERAL_PROVISION = 'funeralProvision',
  BANK_AUTHORIZATION_LETTER = 'bankAuthorizationLetter',
}

export const QUESTIONNAIRE_KEYS_WITH_CONTENT = {
  FUNERAL_PROVISION: QUESTIONNAIRE_KEYS.FUNERAL_PROVISION,
  CUSTODY_ORDER: QUESTIONNAIRE_KEYS.CUSTODY_ORDER,
  HEALTH_CARE_PROXY: QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY,
  ORGAN_DONOR_CARD: QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD,
  PATIENT_DECREE: QUESTIONNAIRE_KEYS.PATIENT_DECREE,
  WILL: QUESTIONNAIRE_KEYS.WILL_GENERATOR,
  BANK_AUTHORIZATION_LETTER: QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER,
};

export enum RECO_TYPE {
  ESTATE = 'manageEstate',
  CARE = 'precautionCare',
}

export const QUESTIONNAIRE_PAGES = {
  [QUESTIONNAIRE_KEYS.PATIENT_DECREE]: {
    questionnaire: QUESTIONNAIRE_KEYS.PATIENT_DECREE,
    path: '/assistant/patient-decree',
  },
  [QUESTIONNAIRE_KEYS.WILL_GENERATOR]: {
    questionnaire: QUESTIONNAIRE_KEYS.WILL_GENERATOR,
    path: '/assistant/will',
  },
  [QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD]: {
    questionnaire: QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD,
    path: '/assistant/organ-donor-card',
  },
  [QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY]: {
    questionnaire: QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY,
    path: '/assistant/health-care-proxy',
  },
  [QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT]: {
    questionnaire: QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT,
    path: '/assistant/initial-assessment',
  },
  [QUESTIONNAIRE_KEYS.PET_WATCHING_AGREEMENT]: {
    questionnaire: QUESTIONNAIRE_KEYS.PET_WATCHING_AGREEMENT,
    path: '/assistant/pet-watching-agreement',
  },
  [QUESTIONNAIRE_KEYS.CUSTODY_ORDER]: {
    questionnaire: QUESTIONNAIRE_KEYS.CUSTODY_ORDER,
    path: '/assistant/custody-order',
  },
  [QUESTIONNAIRE_KEYS.FUNERAL_PROVISION]: {
    questionnaire: QUESTIONNAIRE_KEYS.FUNERAL_PROVISION,
    path: '/assistant/funeral-provision',
  },
  [QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER]: {
    questionnaire: QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER,
    path: '/assistant/bank-authorization-letter',
  },
};

export enum STEP_COMPONENT_TYPE {
  START = 'start',
  END = 'end',
  SUCCESS = 'success',
  SINGLE = 'choice',
  MULTIPLE = 'multipleChoice',
  FREE_TEXT = 'freeText',
  CONTACT = 'contact',
  EXPIRATION_DATE = 'expirationDate',
  SINGLEANDMULTIPLE = 'singleAndMultiple',
  PARTNER_PROFILE = 'partnerProfile',
  INHERITANCE_DISTRIBUTION = 'distribution',
  BEQUEST = 'bequest',
  REQUEST_ADVICE = 'requestAdvice',
  HEIR_CONTACTS = 'heirContacts',
  SUBSTITUTE_HEIR = 'substituteHeir',
  CONTENT = 'content',
  MULTI_CONTACTS = 'multipleContacts',
  FORM = 'form',
  ADD_INFORMATION = 'addInformation',
  BANK_POA_CONTACT = 'bankPOAContact',
}

export enum STEPS_BREAK_ANSWER_CHAIN {
  HEIR_CONTACTS = STEP_COMPONENT_TYPE.HEIR_CONTACTS,
  BANK_POA_CONTACT = STEP_COMPONENT_TYPE.BANK_POA_CONTACT,
}

export enum GENERAL_ANSWER_KEYS {
  DESCRIPTION = 'description',
  NOTHINGOFABOVE = 'nothingOfTheAbove',
}

export const getQuestionnaire = (questionnaire: QUESTIONNAIRE_KEYS, t) => {
  switch (questionnaire) {
    case QUESTIONNAIRE_KEYS.PATIENT_DECREE:
      return getPatientDecreeQuestionnaire(questionnaire, t);
    case QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY:
      return getHealthCareProxyQuestionnaire(questionnaire, t);
    case QUESTIONNAIRE_KEYS.WILL_GENERATOR:
      return getWillQuestionnaire(questionnaire, t);
    case QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD:
      return getOrganDonorCardQuestionnaire(questionnaire, t);
    case QUESTIONNAIRE_KEYS.PET_WATCHING_AGREEMENT:
      return getPetWatchingAgreementQuestionnaire(questionnaire, t);
    case QUESTIONNAIRE_KEYS.CUSTODY_ORDER:
      return getCustodyOrderQuestionnaire(questionnaire, t);
    case QUESTIONNAIRE_KEYS.FUNERAL_PROVISION:
      return getFuneralProvisionQuestionnaire(questionnaire, t);
    case QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER:
      return getBankAuthorizationLetterQuestionnaire(questionnaire, t);
    default:
      return getInitialAssessmentQuestionnaire(questionnaire, t);
  }
};

export const getStartScreenContent = (questionnaire: QUESTIONNAIRE_KEYS, t) => {
  switch (questionnaire) {
    case QUESTIONNAIRE_KEYS.PATIENT_DECREE:
      return getPatientDecreeStartScreen(questionnaire, t);
    case QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY:
      return getHealthCareProxyStartScreen(questionnaire, t);
    case QUESTIONNAIRE_KEYS.WILL_GENERATOR:
      return getWillStartScreen(questionnaire, t);
    case QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD:
      return getOrganDonorCardStartScreen(questionnaire, t);
    case QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT:
      return getInitialAssessmentStartScreen(questionnaire, t);
    case QUESTIONNAIRE_KEYS.PET_WATCHING_AGREEMENT:
      return getPetWatchingAgreementStartScreen(questionnaire, t);
    case QUESTIONNAIRE_KEYS.CUSTODY_ORDER:
      return getCustodyOrderStartScreen(questionnaire, t);
    case QUESTIONNAIRE_KEYS.FUNERAL_PROVISION:
      return getFuneralProvisionStartScreen(questionnaire, t);
    case QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER:
      return getBankAuthorizationLetterStartScreen(questionnaire, t);
    default:
      return null;
  }
};

export const getStepKeys = (questionnaire: QUESTIONNAIRE_KEYS) => {
  switch (questionnaire) {
    // Estate questionnaires
    case QUESTIONNAIRE_KEYS.PATIENT_DECREE:
      return PD_STEP_KEYS;
    case QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY:
      return HCP_STEP_KEYS;
    case QUESTIONNAIRE_KEYS.WILL_GENERATOR:
      return WG_STEP_KEYS;
    case QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD:
      return ODC_STEP_KEYS;
    case QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT:
      return INIT_STEP_KEYS;
    case QUESTIONNAIRE_KEYS.PET_WATCHING_AGREEMENT:
      return PA_STEP_KEYS;
    case QUESTIONNAIRE_KEYS.CUSTODY_ORDER:
      return CO_STEP_KEYS;
    case QUESTIONNAIRE_KEYS.FUNERAL_PROVISION:
      return FP_STEP_KEYS;
    default:
      return null;
  }
};

export const getAnswers = (questionnaire, t) => {
  switch (questionnaire) {
    case QUESTIONNAIRE_KEYS.PATIENT_DECREE:
      return getPatientDecreeAnswers(questionnaire, t);
    case QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY:
      return getHealthCareProxyAnswers(questionnaire, t);
    case QUESTIONNAIRE_KEYS.WILL_GENERATOR:
      return getWillAnswers(questionnaire, t);
    case QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD:
      return getOrganDonorCardAnswers(questionnaire, t);
    case QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT:
      return getInitialAssessmentAnswers(questionnaire, t);
    case QUESTIONNAIRE_KEYS.PET_WATCHING_AGREEMENT:
      return getPetWatchingAgreementAnswers(questionnaire, t);
    case QUESTIONNAIRE_KEYS.CUSTODY_ORDER:
      return getCustodyOrderAnswers(questionnaire, t);
    case QUESTIONNAIRE_KEYS.FUNERAL_PROVISION:
      return getFuneralProvisionAnswers(questionnaire, t);
    default:
      return null;
  }
};
