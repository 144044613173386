import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { ReactComponent as IconLink } from 'images/icon-outside-link.svg';

const NotarySearchWidget = () => {
  const { t } = useTranslation(['plan']);
  return (
    <div>
      <h1 className="typo-alpha">{t('plan:notary_widget_title')}</h1>
      <p className="typo-epsilon t-text-beta-700 t-mt-3">
        {t('plan:notary_widget_description')}
      </p>

      <a
        className="t-mt-7 t-flex t-items-center t-outline-none"
        href="https://www.notar.de/notarsuche/notarsuche"
        target="_blank"
        rel="nofollow noreferrer noopener"
      >
        <p className="typo-eta t-text-alpha-600">
          {t('plan:notary_widget_search_now')}
        </p>
        <IconLink className="t-ml-1 t-text-alpha-600 t-w-5 t-h-5 t-mb-1" />
      </a>
    </div>
  );
};

export default NotarySearchWidget;
