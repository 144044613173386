import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { RootState } from 'store/reducers';
import { temporaryFieldSaver } from 'store/actions/temporaryFieldSaverActions';
import {
  optionsHelper,
  dateHelper,
  config,
  useTranslation,
} from 'modules/common/helpers';

import { Checkbox } from 'modules/elements/components';
import { Row, Col } from 'modules/bootstrap/components';
import {
  TextInputRef,
  CountryInput,
  SelectController,
  BirthdayInput,
  Button,
} from 'modules/common/components';

export interface PartnerProfileProps {
  previousAnswer?: Record<string, boolean>;
  handleGoToPrevQuestion: () => void;
  handleSubmission: (arg: SelectedAnswer[] | SelectedAnswer | string) => void;
}

const PartnerProfile = ({
  previousAnswer,
  handleGoToPrevQuestion,
  handleSubmission,
}: PartnerProfileProps) => {
  const { t } = useTranslation(['assistant', 'auth']);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,

    formState: { errors },
  } = useForm();

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isSameAddress, setIsSameAddress] = useState(true);

  const temporaryField = useSelector(
    (state: RootState) => state.temporaryField
  );
  const tempPartnerInfo = temporaryField.value;

  useEffect(() => {
    if (previousAnswer) {
      dispatch(temporaryFieldSaver(previousAnswer));
      setIsSameAddress(previousAnswer.isSameAddress);
    }
    setIsFirstRender(false);
  }, [previousAnswer]);

  const onSubmit = async (formData) => {
    const submitData = {
      ...formData,
      gender: (formData?.gender?.value as string) || '',
      birthday:
        formData.birthday &&
        dateHelper.convertDateFormat(
          formData.birthday,
          config.format.serverDate
        ),
      isSameAddress,
    };
    if (formData.country) submitData.country = formData.country.value;

    handleSubmission(submitData);
  };

  const submit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleSubmit(() => {
      onSubmit(getValues());
    })();
  };

  const handleIsSameAddressChange = () => {
    setIsSameAddress((oldVal) => !oldVal);
  };

  const textFieldRequired = t('common:field_required');

  const disableNext = !isEmpty(errors);

  const genderOptions = optionsHelper.getGenderOptions();
  const defaultGenderOption = genderOptions.find(
    (item) => item.value === tempPartnerInfo?.gender
  );

  // NOTE: this hack skips first render in order to make Gender and Birthday inputs work properly
  // (otherwise they won't display default values). THis hack to be reworked if proper workaround found
  if (isFirstRender) return null;

  return (
    <div className="card xs:t-w-full md:t-w-4/5 xs:t-p-4 md:t-p-7 t-mb-20">
      <form onSubmit={submit} className="Form">
        <Row>
          <Col xs={12} md="5">
            <div className="Form-group">
              <label className="Form-label" htmlFor="gender">
                {t('auth:title')}
              </label>
              <SelectController
                rules={{ required: true }}
                id="gender"
                name="gender"
                options={genderOptions}
                control={control}
                defaultValue={defaultGenderOption}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md="5">
            <TextInputRef
              {...register('name', { required: true })}
              label={t('auth:name_f')}
              placeholder={t('auth:name_f')}
              type="text"
              autoComplete={false}
              defaultValue={tempPartnerInfo?.name}
              error={errors.name && textFieldRequired}
            />
          </Col>
          <Col xs={12} md="7">
            <TextInputRef
              {...register('surname', { required: true })}
              label={t('auth:name_l')}
              placeholder={t('auth:name_l')}
              type="text"
              autoComplete={false}
              defaultValue={tempPartnerInfo?.surname}
              error={errors.surname && textFieldRequired}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md="5">
            <BirthdayInput
              control={control}
              error={(errors?.birthday?.message as string) || ''}
              defaultValue={dateHelper.convertDateFormat(
                tempPartnerInfo?.birthday,
                config.format.uiDate
              )}
              isRequired={true}
            />
          </Col>
          <Col xs={12} md="7">
            <TextInputRef
              {...register('placeOfBirth', { required: true })}
              label={t('auth:birthplace_label')}
              placeholder={t('auth:birthplace_label')}
              type="text"
              defaultValue={tempPartnerInfo?.placeOfBirth}
              error={errors.placeOfBirth && textFieldRequired}
            />
          </Col>
        </Row>

        {!isSameAddress && (
          <>
            <Row>
              <Col md="5">
                <TextInputRef
                  {...register('zip', { required: true })}
                  label={t('auth:postal_label')}
                  placeholder={t('auth:postal_label')}
                  type="text"
                  error={errors.zip && textFieldRequired}
                  defaultValue={tempPartnerInfo?.zip}
                />
              </Col>
              <Col md="7">
                <CountryInput
                  control={control}
                  error={(errors.country?.type as string) || ''}
                  defaultValue={tempPartnerInfo?.country}
                  isRequired={true}
                  refSetValue={setValue}
                  refGetValues={getValues}
                />
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <TextInputRef
                  {...register('address', { required: true })}
                  label={t('auth:address_label')}
                  placeholder={t('auth:address_label')}
                  type="text"
                  error={errors.address && textFieldRequired}
                  defaultValue={tempPartnerInfo?.address}
                />
              </Col>
              <Col md="7">
                <TextInputRef
                  {...register('city', { required: true })}
                  label={t('auth:city_label')}
                  placeholder={t('auth:city_label')}
                  type="text"
                  error={errors.city && textFieldRequired}
                  defaultValue={tempPartnerInfo?.city}
                />
              </Col>
            </Row>
          </>
        )}

        <div className="t-flex t-justify-end t-mt-5 t-flex-wrap">
          <Col className="t-justify-self-start xs:t-w-full t-my-auto t-block t-whitespace-nowrap">
            <Checkbox
              text={t('will:partner_step_is_same_address')}
              checked={isSameAddress}
              id="isSameAddress"
              onChange={handleIsSameAddressChange}
            />
          </Col>
          <div className="t-flex xs:t-mt-5 sm:t-mt-0">
            <Button
              category="secondary"
              className="t-mr-5"
              onClick={handleGoToPrevQuestion}
            >
              {t('common:previous')}
            </Button>
            <Button onClick={(e) => submit(e)} disabled={disableNext}>
              {t('common:next')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PartnerProfile;
