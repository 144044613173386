import React from 'react';

import { formatPlanPercentage } from 'modules/estatePlan/helpers';
import { useTranslation } from 'modules/common/helpers';

import {
  PlanProgress,
  EstatePlanNavigation,
  AssetsSearching,
} from 'modules/estatePlan/components';

interface Props {
  overallProgress: number;
  searching?: boolean;
}

export default function EstatePlanNavigationColumn({
  overallProgress,
  searching,
}: Props) {
  const { t } = useTranslation(['plan']);

  return (
    <>
      <h1 className="Typography Typography--title u-weight-600 u-pb-10">
        {t('plan:my_estate_plan')}
      </h1>

      <p className="t-pb-5">{t('plan:we_guide_you')}</p>

      <PlanProgress percentValue={formatPlanPercentage(overallProgress)} />

      <div data-tour="vault_navbar">
        <AssetsSearching
          searchPlaceholder={t('plan:search_input_placeholder')}
          containerClassName={`t-bg-delta-100 t-pt-5 ${
            searching ? 't-pb-5' : ''
          }`}
          innerContainerClassName="t-bg-gamma-400 t-mx-2.5"
        />

        {!searching && <EstatePlanNavigation />}
      </div>
    </>
  );
}
