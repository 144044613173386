import produce from 'immer';

import initialState from 'store/reducers/initialState';
import {
  ADD_ASSET_TYPE,
  GET_ASSET_TYPES_BY_CATEGORY,
  DELETE_ASSET_TYPE,
  GET_ASSET_TYPES,
  EDIT_ASSET_TYPE,
} from 'store/types/assetTypeTypes';
import { ASSET_CATEGORIES } from 'modules/estatePlan/constants';

const assetTypeReducer = (state = initialState.assetTypes, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_ASSET_TYPE: {
        const {
          payload: { newAssetType, assetCategory },
        } = action;
        draft[assetCategory].push(newAssetType);
        return draft;
      }
      case EDIT_ASSET_TYPE: {
        const {
          payload: { modifiedAssetType, assetCategory },
        } = action;
        const index = draft[assetCategory].findIndex(
          (assetType) => assetType.id === modifiedAssetType.id
        );
        if (index !== -1) draft[assetCategory][index] = modifiedAssetType;
        return draft;
      }
      case GET_ASSET_TYPES: {
        const {
          payload: { assetTypes },
        } = action;
        Object.values(ASSET_CATEGORIES).forEach((category) => {
          draft[category] = assetTypes[category] || [];
        });

        return draft;
      }
      case GET_ASSET_TYPES_BY_CATEGORY: {
        const {
          payload: { assetTypes, assetCategory },
        } = action;

        const newAssetTypes = assetTypes
          .map((assetType) => {
            assetType.structure.sort((a, b) => a.order - b.order); // sort structure inside asset types by order
            return assetType;
          })
          .sort((a, b) => a.order - b.order); // sort assetTypes by order

        draft[assetCategory] = newAssetTypes;
        return draft;
      }
      case DELETE_ASSET_TYPE: {
        const {
          payload: { assetTypeId, assetCategory },
        } = action;
        const index = draft[assetCategory].findIndex(
          (assetType) => assetType.id === assetTypeId
        );
        if (index !== -1) draft[assetCategory].splice(index, 1);
        return draft;
      }
      default:
        return draft;
    }
  });

export default assetTypeReducer;
