import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';

export default {
  user: {
    current: {} as UserProfileDTO,
  },
  plan: {
    progress: {} as PlanProgressDTO,
  },
  documents: {
    list: {} as AssetsDTO,
  },
  modal: {
    name: null,
    props: {} as ModalProps,
  },
  contacts: {
    list: [] as ContactDTO[],
    backup: [] as ContactDTO[],
  },
  heirs: {
    list: [] as ContactDTO[],
  },
  overviewContact: {
    item: null as null | ContactDTO,
  },
  contactOf: {
    list: [] as ContactOfDTO[],
  },
  common: {
    asyncAction: null,
    pathname: '/',
  },
  loading: {
    value: false,
  },
  layout: {
    assetEdit: {
      currentAsset: {} as AssetDTO,
      currentAssetType: {} as AssetTypeDTO,
      isShowEditForm: false,
    },
    isShowSubscriptionWidget: true,
    isAccountPanelOpen: false,
  },
  questionnaires: {
    [QUESTIONNAIRE_KEYS.PATIENT_DECREE]: {} as QuestionnaireDTO,
    [QUESTIONNAIRE_KEYS.HEALTH_CARE_PROXY]: {} as QuestionnaireDTO,
    [QUESTIONNAIRE_KEYS.WILL_GENERATOR]: {} as QuestionnaireDTO,
    [QUESTIONNAIRE_KEYS.ORGAN_DONOR_CARD]: {} as QuestionnaireDTO,
    [QUESTIONNAIRE_KEYS.INITIAL_ASSESSMENT]: {} as QuestionnaireDTO,
    [QUESTIONNAIRE_KEYS.PET_WATCHING_AGREEMENT]: {} as QuestionnaireDTO,
    [QUESTIONNAIRE_KEYS.CUSTODY_ORDER]: {} as QuestionnaireDTO,
    [QUESTIONNAIRE_KEYS.FUNERAL_PROVISION]: {} as QuestionnaireDTO,
    [QUESTIONNAIRE_KEYS.BANK_AUTHORIZATION_LETTER]: {} as QuestionnaireDTO,
  },
  assistant: {
    progress: {} as AssistantProgressDTO,
  },
  recommendations: {} as RecommendationsDTO,
  temporaryField: { value: null, error: '' },
  assets: {} as AssetsDTO,
  assetTypes: {} as AssetTypesDTO,
  assetTemplates: {} as AssetTypesDTO,
  immediateAssets: {} as immediateAssetsDTO,
  notifications: {
    isPanelOpen: false,
    notifications: [] as NotificationDTO[],
  },
  nextSteps: {
    list: [] as NextStep[],
  },
  providers: {
    current: {} as ProvidersDTO,
  },

  assetSearch: {} as AssetsSearchDTO,

  keyHolders: {
    value: [] as KeyHolder[],
  },

  familyAccounts: {
    list: [] as FamilyAccount[],
  },
};
