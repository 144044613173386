export enum MODALS {
  LOGOUT_WARNING_MODAL = 'logoutWarningModal',
  SECURITY_KEY_MODAL = 'securityKeyModal',
  ASSET_VIEW_MODAL = 'assetViewModal',
}

export enum BADGE_STATUS {
  REGULAR = 'regular',
  WARNING = 'warning',
  SUCCESS = 'success',

  NEEDED = 4,
  HIGH = 3,
  MEDIUM = 2,
  LOW = 1,
  IRRELEVANT = 0,

  DISABLED = 'disabled',
}

export enum ESCALATED_BUTTON_STATUS {
  REGULAR = 'regular',
  GOOFY = 'goofy',
  DISABLED = 'disabled',
}

export const VALIDATE = 'validate';

export const acceptedImageExtensions = '.png, .jpg, .jpeg, .heic';
export const acceptedFileExtensions = `.pdf, .doc, .docx, .csv, .xls, .xlsx, ${acceptedImageExtensions}`;

export const EMAIL = 'email';
