import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { RootState } from 'store/reducers';
import { setModal } from 'store/actions/modalActions';
import { toggleAccountPanel } from 'store/actions/layoutActions';
import { logOut, switchAccount } from 'store/actions/userActions';
import { getFullName, useTranslation } from 'modules/common/helpers';

import { MODALS } from 'modules/common/constants';

import { ReactComponent as IconGear } from 'images/icon-gear.svg';
import { ReactComponent as IconSignOut } from 'images/icon-sign-out.svg';
import { ReactComponent as IconPlus } from 'images/icon-plus.svg';

export default function AccountManagementPanel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['notification']);
  const user: UserProfileDTO = useSelector(
    (state: RootState) => state.user.current
  );
  const familyAccounts = useSelector(
    (state: RootState) => state.familyAccounts.list
  );
  const mainAccount =
    familyAccounts.find((account) => !!account.primary) ||
    ({} as FamilyAccount);
  const subAccounts = familyAccounts.filter(
    (account) => !account.primary && !account.deceased
  );

  const handleAccountSwitching = async (account: FamilyAccount) => {
    if (account?.id === user.id) return;

    dispatch(
      setModal({
        name: MODALS.ACCOUNT_SWITCHING_INFO,
        props: {
          data: {
            to: account,
          },
        },
      })
    );
  };

  const goToMainProfileSettings = async () => {
    if (!!user?.secondary) {
      await dispatch(switchAccount(mainAccount.id));
    }

    navigate('/profile');
    dispatch(toggleAccountPanel());
  };

  const goToSubAccountSettings = (e) => {
    e.stopPropagation();
    navigate('/profile');
    dispatch(toggleAccountPanel());
  };

  const onLogOut = async () => {
    await dispatch(logOut());

    if (user.beneficiary) navigate('/access');
    navigate('/login');
  };

  const openLearnMoreModal = () => {
    dispatch(setModal(MODALS.FAMILY_ACCOUNT_LEARN_MORE));
  };

  const addNewFamilyAccounts = () => {
    dispatch(setModal(MODALS.ADD_FAMILY_ACCOUNT_PROCESS));
  };

  React.useEffect(() => {
    ReactTooltip.rebuild();

    return () => {
      ReactTooltip.hide();
    };
  }, []);

  return (
    <div
      className="card t-rounded-sm t-w-80 t-absolute t-z-50 t-right-0 t-top-32 xl:t-top-14 t-overflow-y-auto t-p-4"
      style={{ maxHeight: '90vh' }}
    >
      <h2 className="typo-beta t-mb-3 t-whitespace-nowrap">
        {t('familyAccounts:account_panel_title')}
      </h2>
      {[mainAccount, ...subAccounts].map((account: FamilyAccount) => {
        const { id, name, surname, primary, active } = account;
        const fullName = getFullName(account, false);
        const letterLabel = (name || ' ')[0] + (surname || ' ')[0];

        return (
          <div
            className={`t-flex t-items-center t-justify-between t-p-2.5 t-rounded ${
              active ? 't-bg-delta-100' : 'hover:t-bg-beta-100 t-cursor-pointer'
            }`}
            key={id}
            onClick={() => {
              active ? undefined : handleAccountSwitching(account);
            }}
          >
            <div className="t-flex t-items-center t-whitespace-nowrap">
              <div
                className={`t-w-9 t-h-9 t-rounded-full t-flex t-justify-center t-items-center t-bg-delta-${
                  primary ? 900 : 500
                }`}
              >
                <p className="t-uppercase t-text-gamma-400">{letterLabel}</p>
              </div>

              <p className="typo-epsilon t-text-beta-700 t-ml-2 t-max-w-36 t-truncate">
                {fullName}
              </p>
            </div>
            {primary && (
              <p className="typo-theta t-text-delta-900 t-whitespace-nowrap">
                {t('familyAccounts:main_account')}
              </p>
            )}
            {!primary && !!active && (
              <div
                data-tip={t('familyAccounts:edit_sub_account_profile_tooltip')}
                className="t-cursor-pointer"
                onClick={goToSubAccountSettings}
              >
                <IconGear className="t-text-beta-400" />
              </div>
            )}
          </div>
        );
      })}

      {!!user?.profile && (
        <>
          <hr className="t-w-full t-text-beta-100" />

          <div className="t-flex t-justify-between t-items-center t-mt-2">
            <button
              className="typo-epsilon t-pl-1 t-pr-2 t-py-2 t-mr-2 t-cursor-pointer hover:t-bg-beta-100 t-rounded t-flex t-items-center"
              onClick={addNewFamilyAccounts}
            >
              <IconPlus className="t-text-alpha-600 t-mr-1.5" />
              <span className="t-text-start">
                {t('familyAccounts:add_family_member_button')}
              </span>
            </button>

            <button
              className="typo-kappa t-text-delta-500 t-pl-2 t-whitespace-nowrap"
              onClick={openLearnMoreModal}
            >
              {t('familyAccounts:learn_more')}
            </button>
          </div>
        </>
      )}

      <hr className="t-w-full t-text-beta-100" />

      <div>
        <p
          className="typo-epsilon t-flex t-mt-2 t-px-1 t-py-2 t-cursor-pointer hover:t-bg-beta-100 t-rounded"
          onClick={goToMainProfileSettings}
        >
          <IconGear className="t-text-beta-400 t-mr-1.5" />
          {t('familyAccounts:go_to_main_profile_settings')}
        </p>
      </div>

      <div>
        <p
          className="typo-epsilon t-flex t-mt-1 t-px-1 t-py-2 t-cursor-pointer hover:t-bg-beta-100 t-rounded"
          onClick={onLogOut}
        >
          <IconSignOut className="t-text-epsilon-400 t-mr-1.5 t-ml-0.5" />
          {t('common:sign_out_capitalized')}
        </p>
      </div>
    </div>
  );
}
