import initialState from 'store/reducers/initialState';
import helper from 'store/reducers/reducerHelper';
import { SET_MODAL } from 'store/types/modalActionTypes';

const modalReducer = (state = initialState.modal, action) => {
  return helper.handleActions(state, action, {
    [SET_MODAL](state, payload) {
      state.name = payload?.name || payload;
      if (payload?.props) {
        state.props = payload?.props ? payload?.props : {};
        // with out this, the functions will not be saved in Redux store
        // Note: saving function is only to use temporarily, we should not save it for late use
        state.props.successAction = payload?.props?.successAction || null;
        state.props.cancelAction = payload?.props?.cancelAction || null;
      }
    },
  });
};

export default modalReducer;
