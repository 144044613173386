import React from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

import { navigateToStep } from 'store/actions/questionnaireActions';
import {
  dateHelper,
  getParsedAddress,
  config,
  useTranslation,
} from 'modules/common/helpers';
import { QuestionnaireFormFieldSelector } from 'modules/assistant/components/Answers';

import { Col, Row } from 'modules/bootstrap/components';
import { Button } from 'modules/common/components';

import { QUESTIONNAIRE_FORM_FIELD_TYPES as F } from 'modules/assistant/constants/questionnaires/questionnaireFormFieldTypes';
import { QUESTIONNAIRE_KEYS } from 'modules/assistant/constants/questionnaires';
import { STEP_KEYS } from 'modules/assistant/constants/questionnaires/petWatchingAgreement';

export interface QuestionnaireFormProps {
  currStepData: Record<string, boolean>;
  questionnaire: QUESTIONNAIRE_KEYS;
  formTypeData: QuestionnaireFormType[];
  handleSubmission: (arg: SelectedAnswer[] | SelectedAnswer | string) => void;
  handleGoToPrevQuestion: () => void;
  previousAnswer?: Record<string, any>[];
  isEndScreenAvailable?: boolean;
}

const removeUnusedFields = (formData) => {
  const copiedFormData = { ...formData };
  delete copiedFormData.streetAddress;
  delete copiedFormData.zip;
  delete copiedFormData.city;
  delete copiedFormData.country;

  // remove undefined values
  Object.keys(copiedFormData).forEach((key) =>
    copiedFormData[key] === undefined ? delete copiedFormData[key] : {}
  );
  return copiedFormData;
};

const QuestionnaireForm = ({
  questionnaire,
  currStepData,
  formTypeData,
  previousAnswer,
  handleSubmission,
  isEndScreenAvailable,
  handleGoToPrevQuestion,
}: QuestionnaireFormProps) => {
  const { t } = useTranslation(['petWatchingAgreement']);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,

    formState: { errors },
  } = useForm();

  const onSubmit = (formData) => {
    const tempSubmitData = {
      ...formData,
      [F.GENDER]: (formData?.gender?.value as string) || undefined,
      [F.BIRTHDAY]:
        formData.birthday &&
        dateHelper.convertDateFormat(
          formData.birthday,
          config.format.serverDate
        ),
      [F.DATE]:
        formData.date &&
        dateHelper.convertDateFormat(formData.date, config.format.serverDate),
      [F.ADDRESS]: getParsedAddress(formData),
    };

    // In questionnaire, we have to remove unused fields from the formData
    // because we need to compare the formData with the previousAnswer
    const submitData = removeUnusedFields(tempSubmitData);

    handleSubmission(submitData);
  };

  const handleGoToEndScreen = async () => {
    await handleSubmit(onSubmit)();
    if (isEmpty(errors))
      dispatch(navigateToStep(questionnaire, STEP_KEYS.STEP_END));
  };

  const formClassName = currStepData?.smallSize
    ? `xl:t-w-3/5 t-p-3 ${
        !currStepData?.noFormBorder
          ? 't-rounded t-border t-border-solid t-border-beta-200'
          : ''
      }`
    : '';

  return (
    <div className="card t-w-full t-p-7 t-pt-9 t-mb-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={currStepData?.smallSize ? 't-flex t-justify-center' : ''}
        >
          <Row className={formClassName}>
            {formTypeData.map((formTypeElement) => (
              <Col
                key={formTypeElement.type}
                xl={{ span: formTypeElement?.span || 12 }}
              >
                <QuestionnaireFormFieldSelector
                  formTypeElement={formTypeElement}
                  control={control}
                  register={register}
                  errors={errors}
                  getValues={getValues}
                  setValue={setValue}
                  watch={watch}
                  defaultValue={
                    previousAnswer ? previousAnswer[formTypeElement.type] : ''
                  }
                />
              </Col>
            ))}
          </Row>
        </div>
        <div
          className={`t-flex t-justify-between t-mt-5 ${
            isEndScreenAvailable ? 't-flex-col sm:t-flex-row' : ''
          }`}
        >
          <div>
            <Button
              category="secondary"
              className="t-mr-2.5"
              onClick={handleGoToPrevQuestion}
            >
              {t('common:previous')}
            </Button>
          </div>

          <div
            className={`t-flex t-ml-5 ${
              isEndScreenAvailable
                ? 't-mt-3 sm:t-mt-0 t-flex-col t-items-end sm:t-flex-row'
                : ''
            }`}
          >
            {isEndScreenAvailable && (
              <Button
                className="t-mr-2 t-mb-3 sm:t-mb-0"
                onClick={handleGoToEndScreen}
              >
                {t('assistant:go_to_end_screen')}
              </Button>
            )}
            <Button type="submit">{t('common:next')}</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default QuestionnaireForm;
