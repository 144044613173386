import React from 'react';

import { useTranslation } from 'modules/common/helpers';

import { TextWithHyphenSpan } from 'modules/common/components';

interface InputLibraryCardProps {
  field: AssetFieldDTO | any; // FIXME
  addField: (any) => void;
  isDisabled: boolean;
}

const absoluteCenteredElementClassNames =
  't-absolute t-top-1/2 t-left-1/2 t-transform t--translate-x-1/2 t--translate-y-1/2 t-flex t-justify-center t-items-center';
const commonButtonClassNames =
  't-opacity-0 group-hover:t-opacity-100 t-text-sm t-rounded t-p-3 t-tracking-widest';

export default function InputLibraryCard({
  field,
  addField,
  isDisabled,
}: InputLibraryCardProps) {
  const { t } = useTranslation();

  return (
    <div
      className={`t-px-7 t-py-5 t-rounded t-shadow hover:t-bg-beta-100 t-group t-relative t-min-h-28 ${
        isDisabled ? 't-cursor-not-allowed' : ''
      }`}
    >
      {/* this could be much prettier by using tailwind "disabled" variant but I wasn't able to set it up,
      even after following docs -  https://tailwindcss.com/docs/hover-focus-and-other-states#disabled */}
      <div className={`t-w-full ${absoluteCenteredElementClassNames}`}>
        {isDisabled ? (
          <button
            type="button"
            disabled={isDisabled}
            className={`${commonButtonClassNames} t-bg-beta-400 t-font-normal t-text-beta-700 t-pointer-events-none`}
          >
            {t('plan:category_already_added')}
          </button>
        ) : (
          <button
            type="button"
            onClick={() => addField(field)}
            className={`${commonButtonClassNames} t-bg-alpha-600 t-font-normal hover:t-bg-alpha-700 t-text-gamma-400`}
          >
            {t('plan:add_category')}
          </button>
        )}
      </div>

      <img
        src={field.image}
        alt={field.description}
        width="70"
        className="t-mx-auto t-mb-4"
      />
      <h1 className="t-text-lg t-mb-1 t-font-semibold">
        <TextWithHyphenSpan>{t(field.label)}</TextWithHyphenSpan>
      </h1>
      <p className="t-text-beta-400 t-mb-3">{t(field.description)}</p>
      <p className="t-tracking-widest t-text-beta-400 t-mb-1">
        {t('plan:input_lib_card_example')}
      </p>
      <p className="t-text-delta-500">{t(field.example)}</p>
    </div>
  );
}
