import {
  FIELD_SAVER,
  FIELD_ERROR,
} from 'store/types/temporaryFieldActionTypes';

export const temporaryFieldSaver = (value) => ({
  type: FIELD_SAVER,
  payload: value,
});
export const temporaryFieldError = (error) => ({
  type: FIELD_ERROR,
  payload: error,
});
