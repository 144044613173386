import React from 'react';

import { capitalizeWords, getFullName, useQuery } from 'modules/common/helpers';

import { Row, Col } from 'modules/bootstrap/components';
import {
  AuthFooter,
  ClickableLogo,
  SignUpForm,
  SignUpHeader,
} from 'modules/auth/components';
import { SiteLanguage } from 'modules/common/components';

import { ReactComponent as IconRectangleBg } from 'images/img_bg_rectangle_1.svg';
import { ReactComponent as IconRectangleSmallScreenBg } from 'images/img_bg_rectangle_2.svg';

interface SignUpPageV2Props {
  client?: string;
  token?: string;
  customization: ClientCustomization;
}

export default function SignUpPageV2({
  client,
  token,
  customization,
}: SignUpPageV2Props) {
  const searchParams = useQuery();
  const data = Array.from(searchParams.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: searchParams.get(val) }),
    {}
  );

  const fullName = capitalizeWords(getFullName(data));

  const customizedFontClass = customization?.customizedFontFamily
    ? `${client}-font-family`
    : '';

  return (
    <div className="t-relative">
      <IconRectangleBg
        className="t-fixed t-w-screen t-h-auto t-bottom-0 t-z-0 t-hidden lg:t-inline"
        style={{ color: customization?.rectangleBgColor || 'white' }}
      />
      <IconRectangleSmallScreenBg
        className="t-fixed t-w-screen t-h-auto t-bottom-0 t-z-0 md:t-hidden"
        style={{ color: customization?.rectangleBgColor || 'white' }}
      />

      {customization?.authFooter && (
        <div className="t-absolute t-z-50 t-h-full t-w-1/2 t-hidden lg:t-inline">
          <div className="t-max-w-sm t-w-full t-pl-6 t-absolute t-bottom-4 t-left-1/2 -t-translate-x-1/2 -t-translate-y-1/2">
            <AuthFooter customFont={customizedFontClass} />
          </div>
        </div>
      )}
      <div className="t-absolute t-top-0 t-h-full t-w-1/2 t-hidden lg:t-inline t-z-50">
        <div className="t-h-full t-w-full t-relative">
          <div className="t-max-w-sm t-w-10/12 t-px-6 t-absolute t-top-16 t-left-1/2 -t-translate-x-1/2 -t-translate-y-1/2">
            <ClickableLogo customization={customization} />
          </div>
          <div className="t-max-w-sm t-w-10/12 t-p-6 t-absolute t-top-1/2 t-left-1/2 -t-translate-x-1/2 -t-translate-y-1/2">
            <SignUpHeader
              customization={customization}
              client={client}
              fullName={fullName}
              customFont={customizedFontClass}
            />
          </div>
        </div>
      </div>

      <main className="t-bg-alpha-100 t-min-h-screen xs:t-p-3 lg:t-p-0">
        <Row
          className="t-min-h-screen t-w-full t-m-0 xs:t-p-3 lg:t-p-0"
          style={{
            backgroundColor: customization?.mainBgColor || 'inherit',
          }}
        >
          <Col
            lg={{ span: 6, offset: 6 }}
            md={{ span: 8, offset: 2 }}
            className="t-p-0 t-z-50"
          >
            {!customization?.noLanguageSwitcher && (
              <div className="t-h-0 md:t-px-16 md:t-max-w-sm t-relative">
                <div className="t-absolute t-z-50 t-top-10 t-right-0 md:t-px-16 md:t-max-w-sm t-hidden lg:t-inline">
                  <SiteLanguage additionalClass="t-pb-0" />
                </div>
              </div>
            )}

            <SignUpForm
              customization={customization}
              token={token}
              client={client}
              data={data}
              fullName={fullName}
              customFont={customizedFontClass}
              loginTextClassName={customization?.loginTextClassName}
            />
          </Col>
        </Row>
      </main>
    </div>
  );
}
