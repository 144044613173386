import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { checkResetToken } from 'store/actions/userActions';

import {
  ClientSetPasswordPage,
  SetPasswordPage,
} from 'modules/auth/components';

import { CLIENT_KEYS } from 'modules/common/constants';

export default function SetPasswordPageManager() {
  const { token = '', client = '' } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isBrokenToken, setIsBrokenToken] = React.useState(false);

  React.useEffect(() => {
    onCheckResetToken();
  }, []);

  React.useEffect(() => {
    if (isBrokenToken) {
      navigate('/invalid-token');
    }
  }, [isBrokenToken]);

  const onCheckResetToken = async () => {
    const data: any = await dispatch(checkResetToken(token));

    if (!data) {
      setIsBrokenToken(true);
    }
  };

  switch (client) {
    case CLIENT_KEYS.HDH:
      return <ClientSetPasswordPage client={client} token={token} />;
    default:
      return <SetPasswordPage client={client} token={token} />;
  }
}
