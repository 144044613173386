import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store/reducers';
import { formatPlanPercentage } from 'modules/estatePlan/helpers';
import { useTranslation } from 'modules/common/helpers';

const useCongratsText = () => {
  const { t } = useTranslation(['plan']);
  const planProgress: PlanProgressDTO = useSelector(
    (state: RootState) => state.plan.progress
  );
  const percentValue = formatPlanPercentage(planProgress?.overall);

  return useMemo(() => {
    if (planProgress?.overall === 0.1 && planProgress?.introduction === 1) {
      return t('plan:congrats_with_introduction_only');
    }

    if (percentValue === 0) {
      return t('plan:congrats_with_zero_percent');
    }

    return t('plan:congrats_with_percent', {
      percentValue: percentValue,
    });
  }, [percentValue, planProgress?.introduction, planProgress?.overall, t]);
};

export default useCongratsText;
